import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { noProjectIcon, plusIcon } from '../../../../constants/icon';
import DataNotFound from '../../../../components/dataNotFound';
import AddFilesModal from './addFilesModal';

function AudioComponent() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <AddFilesModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="assets-container">
        <div className="assets-content">
          <h2>All audio files</h2>
          <div className="assets-checkbox-holder">
            <Button className="add-new-file-btn" variant="default" onClick={() => setModalShow(true)}>
              {plusIcon}
              Add new file
            </Button>
          </div>
          <DataNotFound icon={noProjectIcon} title="No audio found" />
        </div>
      </div>
    </>
  );
}
export default AudioComponent;
