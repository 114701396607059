import React from 'react';
import { ProgressBar } from 'react-bootstrap';

const ProgressWithPercentage = props => {
  const { variant, now, percentage } = props;
  const getTextColor = () => {
    switch (variant) {
      case 'primary':
        return '#6750a4';
      case 'success':
        return '#00AA3A';
      case 'warning':
        return '#ff8a00';
      default:
        return '#79747e';
    }
  };
  const textColor = getTextColor();
  const textStyle = {
    color: textColor,
  };

  return (
    <div className={`progress-wrap ${props.className}`}>
      <ProgressBar variant={variant} now={now} />
      <div className="progress-value" style={textStyle}>
        {percentage}
      </div>
    </div>
  );
};

export default ProgressWithPercentage;
