import React, { useState } from "react";
import { arrowUpIcon, arrowDownIcon } from "../../constants/icon";

function LightAccordion(props) {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`light-accordion  ${props.className || ""}`}>
      <div className="light-accordion-header" onClick={toggleContent}>
        {children[0]}
        <span
          className={`arrow-icon ${isOpen ? "up" : "down"}`}
          aria-hidden="true"
        >
          {isOpen ? <>{arrowUpIcon}</> : <>{arrowDownIcon}</>}
        </span>
      </div>
      {isOpen && <div className="light-accordion-content">{children[1]}</div>}
    </div>
  );
}

export default LightAccordion;
