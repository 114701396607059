import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { checkAdmin } from '../services/helper';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const { token, client } = checkAdmin();
        const allowAdminAccess = token && client && rest.adminAccess;

        return (localStorage.getItem('_user') && localStorage.getItem('_olive_user')) ||
          allowAdminAccess ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default PrivateRoute;
