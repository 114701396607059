import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { createOrgnaizationUsers, inserttemplatePlan } from "../../../services/authService";
import { showToaster } from "../../../services/helper";
import Select from "react-select";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import timezones from "timezones-list";
import "react-phone-input-2/lib/bootstrap.css";
import { useStateWithCallbackLazy } from "use-state-with-callback";

export default function OrgCreation(props) {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [uploadProgress, setUploadProgress] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const history = useHistory();
  const [phone, setPhone] = useState("+91");
  const [fonts, setfonts] = useStateWithCallbackLazy();
  // const [tz, setTz] = useState(
  //   Intl.DateTimeFormat().resolvedOptions().timeZone
  // );
  const [listtimezone, setListtimezone] = useState({
    value: "Asia/Kathmandu",
    label: "Asia/Kathmandu (GMT+05:45)"
  });
  const handleChange = async (e) => {
    setListtimezone(e);
  };
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "gray",
      padding: 7
    }),
    control: () => ({
      display: "flex",
      backgroundColor: DisplayMode ? "#2e2e2e" : "#f0efef",
      color: "#fff",
      height: "32px"
    }),
    singleValue: () => ({
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "12px",
      lineHeight: "18px"
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      color: "#fff"
    }),
    indicatorSeparator: () => ({
      border: "none"
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px"
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff"
    })
  };
  useEffect(() => {
    let ddd;
    timezones.map((data) => {
      ddd = data;
    });
    setListtimezone({
      value:ddd.tzCode,
      label:ddd.label
    });
    // values = ""
  }, []);
  useEffect(() => {
    generateFonts();
  }, [listtimezone]);
  // const openRoleTable = () => {
  //   history.push("/user-roll");
  // };
  const generateFonts = () => {
    if (listtimezone != undefined) {
      let fontOption = [];
      timezones.map((font) => {
        fontOption.push({
          value: font.tzCode,
          label: font.label,
        });
        return true;
      });
      setfonts(fontOption);
      // handleChange(fontOption[0])
      // setfonts(fontOption);
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      userName: "",
      lastName: "",
      phonenumber: "",
      confirmPassword: "",
      organizationName: "",
      timeZone: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().trim().email("Email is invalid").min(2, "Too Short!").max(50, "Too Long!").required("Email is required"),
      password: Yup.string()
        .trim()
        .matches(
          // "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$",
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .required("Password is required"),
      userName: Yup.string().trim().max(20, "Must be exactly 20 characters").min(1, "Minimum 1 characters are required").required("Name is required"),
      organizationName: Yup.string()
        .trim()
        .max(20, "Must be exactly 20 characters")
        .min(1, "Minimum 5 characters are required")
        .required("Organization Name is required"),
      lastName: Yup.string()
        .trim()
        .max(20, "Must be exactly 20 characters")
        .min(1, "Minimum 1 characters are required")
        .required("Last Name is required"),
      phonenumber: Yup.string().trim().required("Phone Number is required").matches(phoneRegExp, "Phone number is not valid"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required")
    }),
    onSubmit: (values) => {
      values.Added_by = "SuperAdmin";
      values.orgId = "AdminRole";
      values.childUserId = "AdminRole";
      values.role = 0;
      values.countrycode = phone;
      values.timeZone = listtimezone.value;
      //   values.name = values.userName + " " + values.lastName;
      setUploadProgress(true);
      try {
        createOrgnaizationUsers(values).then((response) => {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            setUploadProgress(false);
            if (response.data.result !== "" && response.data.result !== undefined && response.data.result !== null) {
              try {
                setPageLoad(true);

                // role.giveActionData.orgId = role._id;
                // role.giveActionData.orgName = role.email;
                // role.giveActionData.manualTemplate=role.manualTemplate;
                // role.giveActionData.autoTemplate=role.autoTemplate;
                // // if (role.giveActionData.editProject || role.giveActionData.viewProject || role.giveActionData.deleteProject) {
                // roleArray.push(role.giveActionData);
                // }

                let roleArray = [
                  {
                    autoTemplate: true,
                    manualTemplate: false,
                    orgId: response.data.result.data._id,
                    orgName: values.organizationName,
                    private: false,
                    public: true,
                    action: "createUser"
                  }
                ];
                inserttemplatePlan(roleArray).then((response) => {
                  try {
                    if (
                      response !== "" &&
                      response !== undefined &&
                      response !== null &&
                      response.data !== "" &&
                      response.data !== undefined &&
                      response.data !== null
                    ) {
                      values.userName = "";
                      values.email = "";
                      values.password = "";
                      values.lastName = "";
                      values.organizationName = "";
                      setPageLoad(false);
                      showToaster("Plan updated successfully", "success");

                      if (response.data.result != undefined) {
                      }
                    } else {
                      setPageLoad(false);
                      showToaster("Something went wrong. Please Try Again1", "warning");
                    }
                  } catch (error) {
                    setPageLoad(false);
                    showToaster("Something went wrong. Please Try Again", "warning");
                  }
                });
              } catch (error) {
                setPageLoad(false);
                showToaster("Something went wrong. Please Try Again", "warning");
              }
              showToaster("Organization user created successfully", "success");
              history.push(`/payment-methods/${response.data.result.data._id}`);
            } else {
            }
          } else {
            showToaster(response.message, "warning");
            setUploadProgress(false);
          }
        });
      } catch (e) {
        setUploadProgress(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    }
  });

  return (
    <div className="theme dark">
      <div className="d-flex login-wrapper h-100">
        <div className="login-left-part">
          <div className="login-logo">
            <svg width="133" height="32" viewBox="0 0 133 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.8532 11.1445H11.1445V15.1613V20.8532H20.8532V11.1445Z" fill="" />
              <path
                d="M23.3492 0H0V23.3514C0 27.0439 2.31479 30.1951 5.57339 31.4364V22.0475V15.1613V5.57339H22.0475C24.463 5.57339 26.4266 7.53704 26.4266 9.95247V13.2133H32V8.64859C32 3.8714 28.1264 0 23.3492 0Z"
                fill=""
              />
              <path d="M26.4266 26.4266H11.1445V32H32V18.7867H26.4266V26.4266Z" fill="" />
              <path
                d="M52.19 17C50.6232 17 49.2147 16.6497 47.9644 15.9492C46.73 15.2331 45.7567 14.2367 45.0445 12.9602C44.3482 11.668 44 10.1813 44 8.5C44 6.81868 44.3482 5.33974 45.0445 4.06319C45.7567 2.77106 46.73 1.77473 47.9644 1.07418C49.2147 0.358059 50.6232 0 52.19 0C53.7567 0 55.1573 0.358059 56.3918 1.07418C57.642 1.77473 58.6153 2.77106 59.3117 4.06319C60.0238 5.33974 60.3799 6.81868 60.3799 8.5C60.3799 10.1813 60.0238 11.668 59.3117 12.9602C58.6153 14.2367 57.642 15.2331 56.3918 15.9492C55.1573 16.6497 53.7567 17 52.19 17ZM52.19 14.1044C53.8042 14.1044 55.0386 13.5907 55.8932 12.5632C56.7637 11.5357 57.1989 10.1813 57.1989 8.5C57.1989 6.81868 56.7637 5.46429 55.8932 4.43681C55.0386 3.40934 53.8042 2.8956 52.19 2.8956C50.5757 2.8956 49.3334 3.40934 48.4629 4.43681C47.6083 5.46429 47.181 6.81868 47.181 8.5C47.181 10.1813 47.6083 11.5357 48.4629 12.5632C49.3334 13.5907 50.5757 14.1044 52.19 14.1044Z"
                fill=""
              />
              <path d="M63.0331 0.326923H66.0717V14.011H72.9086V16.6731H63.0331V0.326923Z" fill="" />
              <path d="M74.9026 0.326923H77.9412V16.6731H74.9026V0.326923Z" fill="" />
              <path d="M94.469 0.326923L88.558 16.6731H85.6855L79.7745 0.326923H82.8369L87.1099 12.353L91.4066 0.326923H94.469Z" fill="" />
              <path d="M96.3002 0.326923H106.935V3.22253H99.41V6.70192H105.25V9.59753H99.41V13.7775H107.173V16.6731H96.3002V0.326923Z" fill="" />
              <path
                d="M123.742 12.7967H117.688L116.311 16.6731H113.368L119.279 0.326923H122.151L128.062 16.6731H125.119L123.742 12.7967ZM122.792 10.1813L120.727 4.34341L118.638 10.1813H122.792Z"
                fill=""
              />
              <path d="M129.961 0.326923H133V16.6731H129.961V0.326923Z" fill="" />
              <path
                d="M48.8484 32C47.9068 32 47.0693 31.794 46.3359 31.3819C45.6026 30.9698 45.0276 30.3883 44.6111 29.6374C44.2037 28.8773 44 27.9982 44 27C44 26.0018 44.2082 25.1273 44.6247 24.3764C45.0503 23.6163 45.6342 23.0302 46.3767 22.6181C47.1191 22.206 47.9611 22 48.9027 22C49.3735 22 49.8217 22.0504 50.2472 22.1511C50.6728 22.2518 51.053 22.3892 51.388 22.5632L51.2251 24.0742C50.4374 23.6987 49.6587 23.511 48.8891 23.511C48.2191 23.511 47.6442 23.6621 47.1644 23.9643C46.6936 24.2573 46.3359 24.6694 46.0915 25.2005C45.847 25.7225 45.7248 26.3223 45.7248 27C45.7248 27.6777 45.8425 28.2821 46.0779 28.8132C46.3223 29.3352 46.68 29.7473 47.1508 30.0494C47.6216 30.3425 48.192 30.489 48.862 30.489C49.2875 30.489 49.6859 30.4432 50.0571 30.3516C50.4374 30.2601 50.8267 30.1227 51.2251 29.9396L51.388 31.4368C50.6275 31.8123 49.781 32 48.8484 32Z"
                fill=""
              />
              <path
                d="M56.8688 32C55.9634 32 55.1576 31.794 54.4514 31.3819C53.7452 30.9698 53.1884 30.3883 52.7809 29.6374C52.3825 28.8773 52.1834 27.9982 52.1834 27C52.1834 26.0018 52.3825 25.1273 52.7809 24.3764C53.1884 23.6163 53.7452 23.0302 54.4514 22.6181C55.1576 22.206 55.9634 22 56.8688 22C57.7742 22 58.58 22.206 59.2862 22.6181C59.9924 23.0302 60.5447 23.6163 60.9431 24.3764C61.3505 25.1273 61.5542 26.0018 61.5542 27C61.5542 27.9982 61.3505 28.8773 60.9431 29.6374C60.5447 30.3883 59.9924 30.9698 59.2862 31.3819C58.58 31.794 57.7742 32 56.8688 32ZM56.8688 30.4615C57.8376 30.4615 58.58 30.141 59.0961 29.5C59.6212 28.859 59.8838 28.0256 59.8838 27C59.8838 25.9744 59.6212 25.141 59.0961 24.5C58.58 23.859 57.8376 23.5385 56.8688 23.5385C55.9 23.5385 55.1531 23.859 54.6279 24.5C54.1028 25.141 53.8402 25.9744 53.8402 27C53.8402 28.0256 54.1028 28.859 54.6279 29.5C55.1531 30.141 55.9 30.4615 56.8688 30.4615Z"
                fill=""
              />
              <path d="M63.1116 22.1923H64.7141L68.9921 28.7582V22.1923H70.5811V31.8077H69.2773L64.6869 24.8434V31.8077H63.1116V22.1923Z" fill="" />
              <path d="M74.561 23.7033H71.8448V22.1923H78.8526V23.7033H76.1364V31.8077H74.561V23.7033Z" fill="" />
              <path d="M80.1143 22.1923H86.0899V23.7033H81.7168V26.0385H85.1528V27.5495H81.7168V30.2967H86.2257V31.8077H80.1143V22.1923Z" fill="" />
              <path d="M87.7668 22.1923H89.3693L93.6473 28.7582V22.1923H95.2363V31.8077H93.9325L89.3422 24.8434V31.8077H87.7668V22.1923Z" fill="" />
              <path d="M99.2163 23.7033H96.5001V22.1923H103.508V23.7033H100.792V31.8077H99.2163V23.7033Z" fill="" />
              <path
                d="M107.82 22.1923H111.12C113.329 22.1923 114.434 23.2271 114.434 25.2967C114.434 26.359 114.149 27.1511 113.578 27.6731C113.008 28.1951 112.193 28.456 111.134 28.456H109.422V31.8077H107.82V22.1923ZM110.944 27.0962C111.577 27.0962 112.048 26.9542 112.356 26.6703C112.673 26.3773 112.831 25.9286 112.831 25.3242C112.831 24.7106 112.673 24.2619 112.356 23.978C112.048 23.6941 111.568 23.5522 110.916 23.5522H109.422V27.0962H110.944Z"
                fill=""
              />
              <path
                d="M115.791 22.1923H119.132C120.155 22.1923 120.956 22.435 121.535 22.9203C122.115 23.4057 122.405 24.1337 122.405 25.1044C122.405 25.7729 122.264 26.3223 121.984 26.7527C121.703 27.1831 121.3 27.5266 120.775 27.783L122.907 31.8077H121.101L119.24 28.1676H119.172H117.393V31.8077H115.791V22.1923ZM118.833 26.8077C119.494 26.8077 119.978 26.6658 120.286 26.3819C120.603 26.0888 120.761 25.6859 120.761 25.1731C120.761 24.0925 120.132 23.5522 118.874 23.5522H117.393V26.8077H118.833Z"
                fill=""
              />
              <path
                d="M128.315 32C127.409 32 126.603 31.794 125.897 31.3819C125.191 30.9698 124.634 30.3883 124.227 29.6374C123.828 28.8773 123.629 27.9982 123.629 27C123.629 26.0018 123.828 25.1273 124.227 24.3764C124.634 23.6163 125.191 23.0302 125.897 22.6181C126.603 22.206 127.409 22 128.315 22C129.22 22 130.026 22.206 130.732 22.6181C131.438 23.0302 131.99 23.6163 132.389 24.3764C132.796 25.1273 133 26.0018 133 27C133 27.9982 132.796 28.8773 132.389 29.6374C131.99 30.3883 131.438 30.9698 130.732 31.3819C130.026 31.794 129.22 32 128.315 32ZM128.315 30.4615C129.283 30.4615 130.026 30.141 130.542 29.5C131.067 28.859 131.33 28.0256 131.33 27C131.33 25.9744 131.067 25.141 130.542 24.5C130.026 23.859 129.283 23.5385 128.315 23.5385C127.346 23.5385 126.599 23.859 126.074 24.5C125.549 25.141 125.286 25.9744 125.286 27C125.286 28.0256 125.549 28.859 126.074 29.5C126.599 30.141 127.346 30.4615 128.315 30.4615Z"
                fill=""
              />
            </svg>
          </div>
          <div className="login-infog"></div>
        </div>
        <div className=" login-right-part login-right-sec">
          <div className="login-container">
            <div className="login-cap">Create Your Account</div>
            <div className="login-para mb-0">Fill the details below and create your account</div>

            <form onSubmit={formik.handleSubmit} id="login-form">
              <div className="login-inp-wrapper">
                <div className="custom-input-holder">
                  <div className="custom-label">First Name</div>
                  <div className="custom-input-container position-relative">
                    <input
                      id="userName"
                      name="userName"
                      type="userName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.userName}
                      className="custom-input"
                      placeholder="Enter your First Name"
                      disabled={uploadProgress}
                    />{" "}
                  </div>
                  {formik.touched.userName && formik.errors.userName ? <div className="input-error-msg">{formik.errors.userName}</div> : null}
                  {/* <div className="input-error-msg">Invalid username</div> */}
                </div>
                <div className="custom-input-holder">
                  <div className="custom-label">Last Name</div>
                  <div className="custom-input-container position-relative">
                    <input
                      id="lastName"
                      name="lastName"
                      type="lastName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      className="custom-input"
                      placeholder="Enter your Last Name"
                      disabled={uploadProgress}
                    />{" "}
                  </div>
                  {formik.touched.lastName && formik.errors.lastName ? <div className="input-error-msg">{formik.errors.lastName}</div> : null}
                  {/* <div className="input-error-msg">Invalid username</div> */}
                </div>
                <div className="custom-input-holder">
                  <div className="custom-label">Organization Name</div>
                  <div className="custom-input-container position-relative">
                    <input
                      id="organizationName"
                      name="organizationName"
                      type="organizationName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.organizationName}
                      className="custom-input"
                      placeholder="Enter your Organization Name"
                      disabled={uploadProgress}
                    />{" "}
                  </div>
                  {formik.touched.organizationName && formik.errors.organizationName ? (
                    <div className="input-error-msg">{formik.errors.organizationName}</div>
                  ) : null}
                  {/* <div className="input-error-msg">Invalid username</div> */}
                </div>
                <div className="custom-input-holder">
                  <div className="custom-label">Email ID</div>
                  <div className="custom-input-container position-relative">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="custom-input"
                      placeholder="Enter your Email"
                      disabled={uploadProgress}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? <div className="input-error-msg">{formik.errors.email}</div> : null}
                </div>
                <div className="custom-input-holder">
                  <div className="custom-label">Phone Number</div>

                  <div className="custom-input-container position-relative">
                    <div className="d-flex">
                      <PhoneInput
                        //   containerclassName={customStyles}
                        country={"eg"}
                        enableSearch={true}
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                        
                      />
                      <input
                        id="phonenumber"
                        name="phonenumber"
                        type="phonenumber"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phonenumber}
                        className="custom-input"
                        placeholder="Enter your Phone Number"
                        disabled={uploadProgress}
                      />
                    </div>
                  </div>
                  {formik.touched.phonenumber && formik.errors.phonenumber ? (
                    <div className="input-error-msg">{formik.errors.phonenumber}</div>
                  ) : null}
                </div>
                <div className="custom-input-holder">
                  <div className="custom-label">Select TimeZone</div>
                  <div className="custom-input-container position-relative">
                    <Select
                      className="custom-input rolluser w-100 org-rolleruser "
                      styles={customStyles}
                      value={listtimezone}
                      onChange={(e) => handleChange(e)}
                      options={fonts}
                    />
                  </div>
                </div>
                <div className="custom-input-holder">
                  <div className="custom-label">Password</div>
                  <div className="custom-input-container position-relative">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      className="custom-input password-input"
                      placeholder="Enter your Password"
                      disabled={uploadProgress}
                    />
                  </div>
                  {formik.touched.password && formik.errors.password ? <div className="input-error-msg">{formik.errors.password}</div> : null}
                </div>

                <div className="custom-input-holder">
                  <div className="custom-label">Confirm Password</div>
                  <div className="custom-input-container position-relative">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                      className="custom-input password-input"
                      placeholder="Enter Confirm Password"
                      disabled={uploadProgress}
                    />
                  </div>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <div className="input-error-msg">{formik.errors.confirmPassword}</div>
                  ) : null}
                </div>

                <div className="login-btn-holder mt-2">
                  <button type="submit" className="btn btn-primary login-btn" value="submit">
                    CREATE ACCOUNT
                  </button>
                </div>
                <p className="mb-3">
                  Already have an account?
                  <Link to="/" className="forgot-password">
                    Sign In
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
