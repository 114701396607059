import { Modal, Button, Row, Col } from 'react-bootstrap';

// Icons
import { longRightArrowIcon, uploadCloudIcon } from '../../../constants/icon';

import DropdownCommon from '../../../components/form/dropdownCommon';
import CustomFileUpload from '../../../components/form/customFileUpload';
import LabelCheckbox from '../../../components/form/labelCheckbox';
import FormTextarea from '../../../components/form/formTextarea';

function AddTemplateModal(props) {
  const { ...rest } = props;

  const organizationName = [
    {
      id: 1,
      title: 'All',
    },
    {
      id: 2,
      title: 'organization 1',
    },
    {
      id: 3,
      title: 'organization 2',
    },
    {
      id: 4,
      title: 'organization 3',
    },
  ];

  const templateType = [
    {
      id: 1,
      title: 'Motion Graphics',
    },
    {
      id: 2,
      title: 'Template Type 1',
    },
    {
      id: 3,
      title: 'Template Type 2',
    },
    {
      id: 4,
      title: 'Template Type 3',
    },
  ];

  return (
    <Modal
      {...rest}
      className="add-template-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Row>
          <Col lg={5} >
            <h2>Upload template</h2>
            <CustomFileUpload
              className="mb-30"
              icon={uploadCloudIcon}
              fileName="Upload your template"
              description="File format allowed (Zip)"
            />

            <DropdownCommon className="mb-30" label={'Organization Name'} items={organizationName} />
            <DropdownCommon className="mb-30" label={'Template Type'} items={templateType} />
            <LabelCheckbox labelText="Only validate the template" className="mb-30" />

            <div className="fw-btn mb-30">
              <Button className="bg-btn-primary" type="submit">
                Upload now
              </Button>
            </div>

            <div className="skip-btn-wrap">
              <Button variant="default" onClick={props.onHide}>
                Skip {longRightArrowIcon}
              </Button>
            </div>
          </Col>
          <Col lg={7} className="ps-40">
            <FormTextarea
              id="responseData"
              placeholder=""
              label="Response Data"
              className="textarea-full-height"
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
export default AddTemplateModal;
