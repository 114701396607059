import { Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import ReactImageFallback from 'react-image-fallback';

import ViewModal from './viewModal';
import PageLoader from '../../services/pageLoader';
import useDebounce from '../../hooks/debounceHook';
import { capitalizeFirstLetter, showToaster } from '../../services/helper';
import { checkIcon, eyeOpenIcon, resetIcon, searchIcon } from '../../constants/icon';
import { publicSearchV1, publicShutterStockSearchV1 } from '../../services/api.service';

const PEXELS = 12;
const PIXABAY = 11;
const UNSPLASH = 10;
const SHUTTERSTOCK = 13;

export default function StockImages({ selectImage }) {
  const { debounce, debouncedValue } = useDebounce();

  const [pageNo, setPageNo] = useState(1);
  const [keyWord, setKeyWord] = useState('');
  const [endPageNo, setEndPageNo] = useState(20);
  const [totalImage, setTotalImage] = useState(0);
  const [imagesRepo, setImagesRepo] = useState([]);
  const [startPageNo, setStartPageNo] = useState(1);
  const [tempKeyWord, setTempKeyWord] = useState('');
  const [imageErrorMsg, setImageErrorMsg] = useState('');
  const [openImagePreview, setOpenImagePreview] = useState('');
  const [getShutterImage, setGetShuterImage] = useState(false);
  const [imageRepoLoader, setImageRepoLoader] = useState(true);
  const [searchOption, setSearchOption] = useState(SHUTTERSTOCK);

  useEffect(() => {
    function getPublicStockImages() {
      setImageRepoLoader(true);

      const request = {
        page: pageNo,
        end: endPageNo,
        media_type: 'IMAGE',
        start: startPageNo,
        search_term: keyWord,
        site_option: searchOption,
      };
      publicSearchV1(request)
        .then(response => {
          if (response.data?.length) {
            if (response.total_count) {
              setTotalImage(response.total_count);
            }

            setImageRepoLoader(false);
            setImagesRepo(response.data);
          }
        })
        .catch(error => {
          console.log({ getPublicStockImagesError: error });

          setImagesRepo([]);
          setImageRepoLoader(false);
          setImageErrorMsg('No images available');
        });
    }

    getPublicStockImages();
  }, [endPageNo, keyWord, pageNo, searchOption, startPageNo]);

  useEffect(() => {
    if (debouncedValue !== null) {
      setKeyWord(debouncedValue);
    }
  }, [debouncedValue]);

  const onChangeSearchBox = e => {
    const value = e.target.value;
    setInputValue(value);
  };

  const setInputValue = value => {
    setTempKeyWord(value);
    debounce(value);
  };

  const onChangeRadio = value => {
    setPageNo(1);
    setEndPageNo(20);
    setStartPageNo(1);
    setImagesRepo([]);
    setSearchOption(value);
  };

  const selectAsset = image => {
    console.log(image);

    const request = {
      imageid: [image.imageid],
    };
    if (searchOption === SHUTTERSTOCK) {
      setGetShuterImage(true);
      publicShutterStockSearchV1(request)
        .then(response => {
          console.log({ response });

          if (response.result) {
            setGetShuterImage(false);
            selectImage(response.result.Images[0].mediaurl);
          }
        })
        .catch(error => {
          console.log({ selectAssetError: error });

          setGetShuterImage(false);
          showToaster(error.message, 'warning');
        });
    } else {
      selectImage(image.mediaurl);
    }
  };

  return (
    <>
      <PageLoader showLoader={getShutterImage} />
      <div className="d-flex align-items-center position-relative flex-wrap">
        <div className="srch-temp-wrapper">
          <div className="srch-holder">
            <div className="srch-icon">{searchIcon}</div>
            <div className="srch-inpt-holder">
              <input
                required
                type="text"
                name="focus"
                id="searchBtn1"
                value={tempKeyWord}
                onChange={onChangeSearchBox}
                placeholder="Search stock images"
                className="form-control srch-input"
              />
              <button type="reset" className="search-close" onClick={() => setInputValue('')}>
                {resetIcon}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="stok-selct">
        <label className="templt-radio-holder">
          <label className="custom-radio">
            <input
              name="q1"
              type="radio"
              disabled={imageRepoLoader}
              checked={searchOption === SHUTTERSTOCK}
              onChange={() => onChangeRadio(SHUTTERSTOCK)}
            />
            <span className="checkmark"></span>
          </label>
          <span className="templt-label">Shutterstock</span>
        </label>
        <label className="templt-radio-holder">
          <label className="custom-radio">
            <input
              name="q1"
              type="radio"
              disabled={imageRepoLoader}
              checked={searchOption === PEXELS}
              onChange={() => onChangeRadio(PEXELS)}
            />
            <span className="checkmark"></span>
          </label>
          <span className="templt-label">Pexels</span>
        </label>
        <label className="templt-radio-holder">
          <label className="custom-radio">
            <input
              name="q1"
              type="radio"
              disabled={imageRepoLoader}
              checked={searchOption === UNSPLASH}
              onChange={() => onChangeRadio(UNSPLASH)}
            />
            <span className="checkmark"></span>
          </label>
          <span className="templt-label">Unsplash</span>
        </label>
        <label className="templt-radio-holder">
          <label className="custom-radio">
            <input
              name="q1"
              type="radio"
              disabled={imageRepoLoader}
              checked={searchOption === PIXABAY}
              onChange={() => setSearchOption(PIXABAY)}
            />
            <span className="checkmark"></span>
          </label>
          <span className="templt-label">Pixabay</span>
        </label>
      </div>

      <div className="cm-st-gallery stock-repo">
        {!imageRepoLoader ? (
          imagesRepo.length ? (
            <div className="cm-gallery-wrapper m-0">
              {imagesRepo.map((image, index) => {
                let mediaurl = '';

                if (image.thumbnailurl) {
                  mediaurl = image.thumbnailurl;
                } else if (image.thumbnailurl === null) {
                  mediaurl = image.mediaurl;
                } else {
                  mediaurl = image;
                }

                return (
                  <div className="cm-gallery-container replace-img">
                    <div className="icon-wrap">
                      {image.thumbnailurl ? (
                        <span
                          data-bs-toggle="modal"
                          data-bs-target="#view-img"
                          className="img-view text-white"
                          onClick={() => setOpenImagePreview(image.mediaurl)}
                        >
                          {eyeOpenIcon}
                        </span>
                      ) : (
                        <span
                          data-bs-toggle="modal"
                          data-bs-target="#view-img"
                          className="img-view text-white"
                          onClick={() => setOpenImagePreview(image.mediaurl)}
                        >
                          {eyeOpenIcon}
                        </span>
                      )}

                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#edit-img"
                        className="img-edit text-white"
                        onClick={() => selectAsset(image)}
                      >
                        {checkIcon}
                      </span>
                    </div>
                    <ReactImageFallback
                      src={mediaurl}
                      className="img-fluid"
                      fallbackImage="../../../assets/img/fallbackImage.jpg"
                      initialImage="../../../assets/img/fallbackImage.jpg"
                      alt="templates"
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="v-gal-empty-blk">
              <div className="no-txt-selected">{capitalizeFirstLetter(imageErrorMsg)}</div>
            </div>
          )
        ) : (
          <Spinner
            variant="primary"
            animation="border"
            className="position-absolute position-center"
          />
        )}
      </div>
      <div className="d-flex justify-content-between mt-3 position-relative control-btns">
        {(startPageNo >= 2 || pageNo >= 2) && (
          <button
            onClick={() => {
              setPageNo(pageNo - 1);
              setEndPageNo(endPageNo - 19);
              setStartPageNo(endPageNo - 38);
            }}
            className="btn btn-primary rounded-0 me-3"
          >
            Previous
          </button>
        )}
        {(imagesRepo.length >= 19 || pageNo * 20 < totalImage) && (
          <button
            onClick={() => {
              setPageNo(pageNo + 1);
              setStartPageNo(endPageNo + 1);
              setEndPageNo(endPageNo + 19);
            }}
            className="btn btn-primary rounded-0 next-btn"
          >
            Next
          </button>
        )}
      </div>

      <ViewModal size="lg" open={!!openImagePreview} onHide={() => setOpenImagePreview('')}>
        <img src={openImagePreview} alt="image_view" className="w-100" />
      </ViewModal>
    </>
  );
}
