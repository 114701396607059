import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { showToaster } from '../../../services/helper';
import { changePassword } from '../../../services/authService';
import { useSelector } from 'react-redux';
import CustomModal from '../../../services/CustomModal';
export default function Forgetpassword() {
  const history = useHistory();
  const [errorStatus, setErrorStatus] = useState();
  const [uploadProgress, setUploadProgress] = useState(false);
  let DisplayMode = useSelector(state => state.project.DisplayMode)
  const [openModal, setopenModal] = useState(false);
  const [token, settoken] = useState(undefined);
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .trim()
        .email('Email is invalid')
        .required('Email is required'),
    }),
    onSubmit: (values) => {
      setUploadProgress(true);
      try {
        changePassword(values).then((response) => {
          if (
            response !== '' &&
            response !== undefined &&
            response !== null &&
            response.data !== '' &&
            response.data !== undefined &&
            response.data !== null
          ) {
            setUploadProgress(false);
            settoken(response.data.token)
            setopenModal(true)
          } else if (
            response !== '' &&
            response !== undefined &&
            response !== null &&
            response.status !== ''
          ) {
            if (
              response.message !== '' &&
              response.message !== undefined &&
              response.message !== null
            ) {
              setErrorStatus(response.message);
            } else {
              setUploadProgress(false);
              showToaster('No response from the server', 'warning');
            }
            setUploadProgress(false);
          } else {
            setUploadProgress(false);

            showToaster('No response from the server', 'warning');
          }
        });
      } catch (e) {
        setUploadProgress(false);
        setErrorStatus(e.response.data.errors);
        // this.setState({ errorStatus: e.response.data.errors })
      }
    },
  });

  const HandleOKBtn = () => {
    window.localStorage.setItem(
      '_user',
      JSON.stringify(token)
    );
    setopenModal(false)
    history.push('/');
  }

  return (
    // <form onSubmit={formik.handleSubmit}>
    <div className={ DisplayMode ? "theme dark" : "theme"}>
      <div className="d-flex login-wrapper h-100">
        <div className="login-left-part">
          <div className="login-logo">
            <img src="assets/img/Logo.svg" className="img-fluid" />
          </div>
          <div className="login-infog"></div>
        </div>
        <div className="d-flex align-items-center justify-content-center login-right-part">
          <div className="login-container w-100">
            {/* <div className="login-cap">Send mail to change your password</div> */}
            <div className="login-para fs-20">
              Welcome to production automation
            </div>
            <form onSubmit={formik.handleSubmit} id="login-form">
              <div className="login-inp-wrapper">
                <div className="custom-input-holder">
                  <div className="custom-label">User Name</div>
                  <div className="custom-input-container position-relative">
                    <span className="user-avatar-inpt">
                      <img
                        src="assets/img/user-icon.png"
                        className="img-fluid"
                      />
                    </span>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="custom-input"
                      placeholder="Enter your Email"
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="input-error-msg">{formik.errors.email}</div>
                  ) : null}
                  <div className="input-error-msg">{errorStatus}</div>
                </div>

                <div className="login-btn-holder mt-2">
                  <button type="submit" className="btn btn-primary login-btn">
                    Submit
                    <span className="login-arrow">
                      <svg
                        enable-background="new 0 0 96 96"
                        height="96px"
                        id="arrow_right"
                        version="1.1"
                        viewBox="0 0 96 96"
                        width="96px"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                      >
                        <path d="M12,52h62.344L52.888,73.456c-1.562,1.562-1.562,4.095-0.001,5.656c1.562,1.562,4.096,1.562,5.658,0l28.283-28.284l0,0  c0.186-0.186,0.352-0.391,0.498-0.609c0.067-0.101,0.114-0.21,0.172-0.315c0.066-0.124,0.142-0.242,0.195-0.373  c0.057-0.135,0.089-0.275,0.129-0.415c0.033-0.111,0.076-0.217,0.099-0.331C87.973,48.525,88,48.263,88,48l0,0  c0-0.003-0.001-0.006-0.001-0.009c-0.001-0.259-0.027-0.519-0.078-0.774c-0.024-0.12-0.069-0.231-0.104-0.349  c-0.039-0.133-0.069-0.268-0.123-0.397c-0.058-0.139-0.136-0.265-0.208-0.396c-0.054-0.098-0.097-0.198-0.159-0.292  c-0.146-0.221-0.314-0.427-0.501-0.614L58.544,16.888c-1.562-1.562-4.095-1.562-5.657-0.001c-1.562,1.562-1.562,4.095,0,5.658  L74.343,44L12,44c-2.209,0-4,1.791-4,4C8,50.209,9.791,52,12,52z" />
                      </svg>
                    </span>
                  </button>
                </div>
                <p>BACK TO
                  <Link to="/" className="forgot-password">
                    LOGIN
                  </Link>
                  {/* <span>Recover Password</span> */}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CustomModal isOpen={openModal} Closemodal={setopenModal} Content="You will receive a password recovery link at your email address in few minutes" Header="" Buttonlabel="Ok" Buttonclick={HandleOKBtn} />
    </div>
    // </form>
  );
}

// import "./styles.css";

// Login.propTypes = {

// };
