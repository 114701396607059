import { Tooltip, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getOSTConfigsList } from "../../services/authService";
import { showToaster } from "../../services/helper";
import Header from "../components/header/header";
import EditSettings from "./EditSettings";
function ConfigSettings() {
  const history = useHistory();
  const location = useLocation();
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [loading, setloading] = useState(false);
  const [OSTConfigsList, setOSTConfigsList] = useState([]);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [selectedConfigData, setSelectedConfigData] = useState(null);
  const [configsCount, setConfigsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const searchRef = useRef("");

  // useEffect(() => {
  //   if(location.search) {
  //     const searchParams = new URLSearchParams(location.search);
  //     let searchVal = searchParams.get('search');
  //     searchVal = searchVal ? searchVal.trim() : '';
  //     if(searchVal) {
  //       setSearch(searchVal);
  //       searchRef.current = searchVal;
  //     }
  //   }
  // }, []);

  useEffect(() => {
    const getOSTConfigs = async () => {
      try {
        setloading(true);
        const response = await getOSTConfigsList({
          search: search || searchRef.current,
          pageNo: currentPage,
          limit: perPage
        });
        if (response.data && response.data.result) {
          setOSTConfigsList(response.data.result);
          setConfigsCount(response.data.totalCount);
        }
      } catch (error) {
        console.log(error);
      }
      searchRef.current = "";
      setloading(false);
    };
    getOSTConfigs();
  }, [currentPage, isSearch, perPage]);

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader-inner">
          <img src="../../../assets/img/loader.gif"></img>
        </div>
      </div>
    );
  };

  const paginate = (e, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onEditClick = (configData, e) => {
    setSelectedConfigData(configData);
  };

  const clearSelectedConfigData = () => {
    setSelectedConfigData(null);
  };

  const updateConfigData = (configData) => {
    setOSTConfigsList((configs) => {
      let newConfigs = [...configs];
      const idx = newConfigs.findIndex((n) => n._id === configData._id);
      if (idx > -1) {
        newConfigs[idx] = { ...configData };
      }
      return newConfigs;
    });
  };

  const ListOSTConfigs = (data) => {
    return (
      <div className="table-wraper">
        <div className="usage-list-warper template-listing settings-listing">
          <div className="usage-block mb-3">
            <div className="usage-head-label text-center">Module Limit</div>
            <div className="usage-head-label text-center">Slide Limit</div>
            <div className="usage-head-label text-center">Screen Limit</div>
            <div className="usage-head-label text-center">
              Heading OST Limit
            </div>
            <div className="usage-head-label text-center">
              Paragraph OST Limit
            </div>
            <div className="usage-head-label text-center">Bullet OST Limit</div>
            <div className="usage-head-label text-center">
              Send Template Render Email
            </div>
            <div className="usage-head-label text-center">
              Max Render Attempt
            </div>
            <div className="usage-head-label text-center">Options</div>
          </div>
          {data != undefined &&
            data.map((configData, i) => {
              return (
                <div key={i} className="usage-block usg-sub-block mb-3">
                  <div className="usage-head-label text-center">
                    {configData.moduleLimit}
                  </div>
                  <div className="usage-head-label text-center">
                    {configData.slideLimit}
                  </div>
                  <div className="usage-head-label text-center">
                    {configData.screenLimit}
                  </div>
                  <div className="usage-head-label text-center">
                    {configData.headingLimit}
                  </div>
                  <div className="usage-head-label text-center">
                    {configData.paragraphLimit}
                  </div>
                  <div className="usage-head-label text-center">
                    {configData.bulletLimit}
                  </div>
                  <div className="usage-head-label text-center">
                    {configData.sendTemplateRenderEmail ? "Yes" : "No"}
                  </div>
                  <div className="usage-head-label text-center">
                    {configData.maxRenderAttempt}
                  </div>
                  <div className="usage-head-label text-center">
                    <button
                      className="btn btn-sm btn-secondary d-inline-block m-auto"
                      onClick={onEditClick.bind(null, configData)}
                      type="button"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  // const onSearch = (e) => {
  //   const value = e.target.value;
  //   setSearch(value);
  // }

  // const onSearchSubmit = (e) => {
  //   e.preventDefault();
  //   if(search && search.trim()) {
  //     setIsSearch(is => !is);
  //     const params = new URLSearchParams(location.search);
  //     params.set('search', search.trim());
  //     history.push({
  //       pathname: '/templatelist',
  //       search: params.toString(),
  //     });
  //   }
  // }

  // const onSearchReset = (e) => {
  //   e.preventDefault();
  //   setSearch('');
  //   setIsSearch(is => !is);
  //   const params = new URLSearchParams(location.search);
  //   params.delete('search');
  //   history.push({
  //     pathname: '/templatelist',
  //     search: params.toString(),
  //   });
  // }

  return (
    <div className={DisplayMode ? "theme dark" : "theme"}>
      {loading && <Loader />}
      <div className="main-wrapper">
        <Header />
        <div className="template-wrapper pb-wrapper">
          <div className="d-flex">
            <div className="p-2 flex-fill total-template-count">
              <h2 className="h4">Config Settings</h2>
            </div>
            {/* <div className="mb-2 flex-fill text-center">
                <div className="pb-srch-customiser w-100 me-2 mb-0 me-md-3">
                  <div className="custom-search srch-holder w-100 h-100">
                    <div className="srch-icon">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21.9243 21.925L27.9994 28.0001"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="srch-inpt-holder h-100">
                      <form onSubmit={onSearchSubmit} onReset={onSearchReset} className="h-100">
                        <input
                          type="text"
                          name="focus"
                          value={search}
                          className="pe-0 form-control srch-input h-100 "
                          placeholder="Press enter to search by template name or id"
                          onChange={onSearch}
                        />
                        <button className="search-close" type="reset">
                          <svg
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                          >
                            <g>
                              <g>
                                <path
                                  d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M327.115,365.904
                          L256,294.789l-71.115,71.115l-38.789-38.789L217.211,256l-71.115-71.115l38.789-38.789L256,217.211l71.115-71.115l38.789,38.789
                          L294.789,256l71.115,71.115L327.115,365.904z"
                                />
                              </g>
                            </g>
                          </svg>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div> 
              <div className="p-2 flex-fill text-right total-template-count">
                Total Items Count : {configsCount}
              </div>*/}
          </div>
          <EditSettings
            selectedConfigs={selectedConfigData}
            updateConfigData={updateConfigData}
            clearSelectedConfig={clearSelectedConfigData}
          />
          {OSTConfigsList.length > 0 ? (
            ListOSTConfigs(OSTConfigsList)
          ) : (
            <div className="no-data">
              <p>No data available</p>
            </div>
          )}
          <div className="row pagination-holder mb-0 justify-content-center">
            <div className="col-auto">
              <Pagination
                onChange={paginate}
                page={currentPage}
                shape="rounded"
                variant="outlined"
                color="primary"
                count={Math.ceil(configsCount / perPage)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfigSettings;
