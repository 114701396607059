import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../../store/index";
import { HEADERFONT, PARAGRAPHFONT, BULLETFONT, FONT_URL } from "../../../constants";
import { showToaster } from "../../../services/helper";
import CustomModal from "../../../services/CustomModal";
import Tooltip from "@mui/material/Tooltip";
import { getStyles, getOstLimit } from "../../../services/authService";

const AddOst = (props) => {
  let project = useSelector((state) => state.project);
  let ActiveModule = useSelector((state) => state.project.ActiveModule);
  let ActiveSlide = useSelector((state) => state.project.ActiveSlide);
  let ActiveScreen = useSelector((state) => state.project.ActiveScreen);
  let ActiveOst = useSelector((state) => state.project.ActiveOst);
  let projectID = useSelector((state) => state.project.ProjectId);
  let FontUrl = useSelector((state) => state.project.FontUrl);
  let FontHSize = useSelector((state) => state.project.FontHSize);
  let FontPSize = useSelector((state) => state.project.FontPSize);
  let TextColor = useSelector((state) => state.project.TextColor);

  const dispatch = useDispatch();

  let { AddFeature, SelectOst, EditOst, DeleteFeature } = bindActionCreators(actionCreator, dispatch);

  const [checked, setChecked] = useState(true);
  const [ostValidate, setOstValidate] = useState(false);
  const [ostValidate1, setOstValidate1] = useState(false);
  const [projects, setProjects] = useState("");
  const [projectLoaded, setProjectLoaded] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [ostValue, setOstValue] = useState("");
  const [selectedTag, setSelectedTag] = useState(HEADERFONT);
  const [openUpdateModal, setopenUpdateModal] = useState(false);
  const [deleteOstId, setdeleteOstId] = useState(undefined);
  const [headingOST, setheadingOST] = useState([]);
  const [paragraphOST, setparagraphOST] = useState([]);
  const [bulletOST, setbulletOST] = useState([]);
  const [headinglimit, setheadinglimit] = useState("");
  const [paragraphlimit, setparagraphlimit] = useState("");
  const [bulletlimit, setbulletlimit] = useState("");
  const [ostCountValidate, setostCountValidate] = useState([]);
  const [deleteOSTValidate, setDeleteOSTValidate] = useState(undefined);
  // const ArrayvalueP = []
  // const ArrayvalueH = []
  // const ArrayvalueB = []
  useEffect(() => {
    setheadingOST([]);
    setparagraphOST([]);
    setbulletOST([]);
    setProjectId(projectID);
    setProjects(project);
    if (project.createdProject[projectID] !== undefined) {
      setProjectLoaded(true);
      listOst();
    } else {
      setProjectLoaded(false);
    }
    // setSelectedTag(HEADERFONT)
  }, [project, projectID, ActiveScreen]);

  const setOstValueFromProject = () => {
    if (projectLoaded) {
      if (projects.createdProject[projectId].Modules[ActiveModule] !== undefined) {
        if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined) {
          if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined) {
            if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen][ActiveOst] !== undefined) {
              Object.entries(projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen][ActiveOst]).map(
                (item, key) => {
                  // if (item[0].Contenttype == "OST") {
                  let fontTag = item[1].fontTag;
                  let data = item[1].contentdescription;
                  if (data !== undefined) {
                    setOstValue(data);
                    if (selectedTag !== undefined && selectedTag !== "") {
                      setSelectedTag((prev) => fontTag || prev);
                    }
                  } else {
                    setOstValue("");
                  }
                  // }
                }
              );
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    setOstValueFromProject();
  }, []);

  useEffect(() => {
    setOstValueFromProject();
  }, [ActiveOst]);

  useEffect(() => {
    if (projectLoaded) {
      listOst();
    }
  }, [projectLoaded]);
  useEffect(() => {
    getOSTvalue();
  }, []);

  useEffect(() => {
    if (ostValidate) {
      setTimeout(() => {
        setOstValidate(false);
      }, 3000);
    }
  }, [ostValidate]);
  useEffect(() => {
    if (ostValidate1) {
      setTimeout(() => {
        setOstValidate1(false);
      }, 3000);
    }
  }, [ostValidate1]);

  const handleOstClick = (ostId) => {
    SelectOst(ostId);
  };
  const getOSTvalue = async () => {
    let response = await getOstLimit();
    setheadinglimit(response.data.result.data[0].headingLimit);
    setparagraphlimit(response.data.result.data[0].paragraphLimit);
    setbulletlimit(response.data.result.data[0].bulletLimit);
    props.setScreenlimit(response.data.result.data[0].screenLimit);
    props.setSlideLimit(response.data.result.data[0].slideLimit);
    props.setModuleLimit(response.data.result.data[0].moduleLimit);
  };
  const DeleteOST = (ostid) => {
    let _arr = [];
    ostCountValidate.map((ostData) => {
      if (deleteOSTValidate === ostData) {
        // debugger
        let i = ostCountValidate.indexOf(ostData);
        _arr = ostCountValidate;
        _arr.splice(1, i);
      }
    });
    setostCountValidate(_arr);
    // }
    props.setostCountValidate(ostCountValidate);
    // let message = "you want to delete the Ost?";
    // let confirmBtn = "delete";
    // Swal.fire({
    //   title: "Are you sure ",
    //   html: message,
    //   icon: "warning",
    //   showCancelButton: true,
    //   cancelButtonText: "Cancel",
    //   confirmButtonText: "Yes, " + confirmBtn + " it!",
    //   confirmButtonColor: "#d33",
    //   focusConfirm: false,
    //   focusCancel: true,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    DeleteFeature(ostid, "OS");
    setdeleteOstId(undefined);
    setopenUpdateModal(false);
    showToaster("OST Deleted Successfully", "success");
    //   }
    // });
  };

  const listOst1 = () => {
    let slidesArray = [];
    if (projectLoaded) {
      if (project.createdProject[projectId].Modules[ActiveModule] !== undefined) {
        if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined) {
          if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined) {
            const OST = project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen];
            let OSTArray = Object.fromEntries(
              Object.entries(project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen]).filter(([key]) =>
                key.includes("OS")
              )
            );
            let OSTArrayLength = Object.keys(OSTArray).length;
            let FontfamilyURL = Object.entries(OSTArray).map((i, item) => {
              return i[1].Contentparams.FontFamily;
            });
            //setFontFamily(FontfamilyURL[0])
            Object.entries(OSTArray).map((i, item) => {
              if (i[1].Contenttype === "OST") {
                let OSTtype = i[1].Contentparams.fontTag;
                const ostName = i[0].replace("OS0", "");
                var array = ostName.split("OS");
                FontfamilyURL = i[1].Contentparams.FontFamily;
                let ostID = i[0];
                let OST = i[1];
                const classNames =
                  ostID == ActiveOst ? "panel-label cm-gen-spec cm-full-lst-container active" : "panel-label cm-gen-spec cm-full-lst-container";
                slidesArray.push(
                  <div className={classNames} id={ostID} key={ostID}>
                    <p onClick={() => handleOstClick(ostID)}>
                      {OSTtype === HEADERFONT ? (
                        <svg className="fill-i me-1" width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0 11.6364H2.46023V6.82955H7.45455V11.6364H9.90909V0H7.45455V4.80114H2.46023V0H0V11.6364Z" />
                        </svg>
                      ) : OSTtype === PARAGRAPHFONT ? (
                        <svg className="outline-i  me-1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.8125 9H13.5" stroke="" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M2.8125 4.5H15.1875" stroke="" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M2.8125 13.5H9" stroke="" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      ) : (
                        <svg className="fill-i  me-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.38462 2.76923C0.618462 2.76923 0 2.15077 0 1.38462C0 0.627692 0.618462 0 1.38462 0C2.14154 0 2.76923 0.627692 2.76923 1.38462C2.76923 2.15077 2.14154 2.76923 1.38462 2.76923ZM3.69231 0.923077H12V1.84615H3.69231V0.923077ZM1.38462 7.38462C0.618462 7.38462 0 6.76615 0 6C0 5.24308 0.618462 4.61538 1.38462 4.61538C2.14154 4.61538 2.76923 5.24308 2.76923 6C2.76923 6.76615 2.14154 7.38462 1.38462 7.38462ZM3.69231 5.53846H12V6.46154H3.69231V5.53846ZM1.38462 12C0.618462 12 0 11.3815 0 10.6154C0 9.85846 0.618462 9.23077 1.38462 9.23077C2.14154 9.23077 2.76923 9.85846 2.76923 10.6154C2.76923 11.3815 2.14154 12 1.38462 12ZM3.69231 10.1538H12V11.0769H3.69231V10.1538Z" />
                        </svg>
                      )}{" "}
                      OST {array}
                    </p>
                    {ostID == ActiveOst &&
                      (OSTArrayLength === 1 ? (
                        ""
                      ) : (
                        <Tooltip arrow>
                          <div
                            //
                            className="cm-del-icon"
                            onClick={() => {
                              setdeleteOstId(ostID);
                              setopenUpdateModal(true);
                            }}>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M10.1041 9.8187C10.1979 9.91178 10.2507 10.0385 10.2507 10.1707C10.2507 10.3028 10.1979 10.4295 10.1041 10.5226C10.011 10.6165 9.88436 10.6692 9.75223 10.6692C9.6201 10.6692 9.49344 10.6165 9.40039 10.5226L5.29232 6.40817L1.18424 10.5226C1.09119 10.6165 0.964535 10.6692 0.832404 10.6692C0.700273 10.6692 0.573613 10.6165 0.480566 10.5226C0.386753 10.4295 0.333984 10.3028 0.333984 10.1707C0.333984 10.0385 0.386753 9.91178 0.480566 9.8187L4.5936 5.70921L0.480566 1.59972C0.354867 1.47397 0.305775 1.2907 0.351785 1.11893C0.397794 0.947163 0.531913 0.812997 0.703622 0.766972C0.875331 0.720947 1.05854 0.770055 1.18424 0.895797L5.29232 5.01025L9.40039 0.895797C9.59471 0.701416 9.90975 0.701416 10.1041 0.895797C10.2984 1.09018 10.2984 1.40533 10.1041 1.59972L5.99104 5.70921L10.1041 9.8187Z"
                                fill=""
                              />
                            </svg>
                          </div>
                        </Tooltip>
                      ))}
                  </div>
                );
              }
            });
            return slidesArray;
          }
        }
      }
    }
  };

  const listOst = () => {
    let slidesArray = [];
    if (projectLoaded) {
      if (project.createdProject[projectId].Modules[ActiveModule] !== undefined) {
        if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined) {
          if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined) {
            const OST = project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen];
            let OSTArray = Object.fromEntries(
              Object.entries(project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen]).filter(([key]) =>
                key.includes("OS")
              )
            );
            let OSTArrayLength = Object.keys(OSTArray).length;
            let FontfamilyURL = Object.entries(OSTArray).map((i, item) => {
              return i[1].Contentparams.FontFamily;
            });
            //setFontFamily(FontfamilyURL[0])
            Object.entries(OSTArray).map((i, item) => {
              if (i[1].Contenttype === "OST") {
                const ostName = i[0].replace("OS0", "");
                var array = ostName.split("OS");
                FontfamilyURL = i[1].Contentparams.FontFamily;
                let ostID = i[0];
                let OST = i[1];
                let OSTtype = i[1].Contentparams.fontTag;
                const classNames =
                  ostID == ActiveOst ? "panel-label cm-gen-spec cm-full-lst-container active" : "panel-label cm-gen-spec cm-full-lst-container";
                if (OSTtype === HEADERFONT) {
                  setheadingOST((state) => [
                    ...state,
                    <>
                      <div className={classNames} id={ostID} key={ostID}>
                        <p onClick={() => handleOstClick(ostID)}>OST {array}</p>
                        {ostID == ActiveOst &&
                          (OSTArrayLength === 1 ? (
                            ""
                          ) : (
                            <Tooltip title="Delete" arrow>
                              <div
                                //
                                className="cm-del-icon"
                                onClick={() => {
                                  setdeleteOstId(ostID);
                                  setopenUpdateModal(true);
                                  setDeleteOSTValidate(ActiveModule + "_" + ActiveSlide + "_" + ActiveScreen + "_" + ostID);
                                }}>
                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M10.1041 9.8187C10.1979 9.91178 10.2507 10.0385 10.2507 10.1707C10.2507 10.3028 10.1979 10.4295 10.1041 10.5226C10.011 10.6165 9.88436 10.6692 9.75223 10.6692C9.6201 10.6692 9.49344 10.6165 9.40039 10.5226L5.29232 6.40817L1.18424 10.5226C1.09119 10.6165 0.964535 10.6692 0.832404 10.6692C0.700273 10.6692 0.573613 10.6165 0.480566 10.5226C0.386753 10.4295 0.333984 10.3028 0.333984 10.1707C0.333984 10.0385 0.386753 9.91178 0.480566 9.8187L4.5936 5.70921L0.480566 1.59972C0.354867 1.47397 0.305775 1.2907 0.351785 1.11893C0.397794 0.947163 0.531913 0.812997 0.703622 0.766972C0.875331 0.720947 1.05854 0.770055 1.18424 0.895797L5.29232 5.01025L9.40039 0.895797C9.59471 0.701416 9.90975 0.701416 10.1041 0.895797C10.2984 1.09018 10.2984 1.40533 10.1041 1.59972L5.99104 5.70921L10.1041 9.8187Z"
                                    fill=""
                                  />
                                </svg>
                              </div>
                            </Tooltip>
                          ))}
                      </div>
                    </>
                  ]);
                } else if (OSTtype === PARAGRAPHFONT) {
                  setparagraphOST((state) => [
                    ...state,
                    <>
                      {/* <div className="d-flex justify-content-between cm-full-lst-blk mb-3">
                      <div className="d-flex cm-full-lst-wrapper"> */}
                      <div className={classNames} id={ostID} key={ostID}>
                        <p onClick={() => handleOstClick(ostID)}>OST {array}</p>
                        {ostID == ActiveOst &&
                          (OSTArrayLength === 1 ? (
                            ""
                          ) : (
                            <Tooltip title="Delete" arrow>
                              <div
                                //
                                className="cm-del-icon"
                                onClick={() => {
                                  setdeleteOstId(ostID);
                                  setopenUpdateModal(true);
                                  setDeleteOSTValidate(ActiveModule + "_" + ActiveSlide + "_" + ActiveScreen + "_" + ostID);
                                }}>
                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M10.1041 9.8187C10.1979 9.91178 10.2507 10.0385 10.2507 10.1707C10.2507 10.3028 10.1979 10.4295 10.1041 10.5226C10.011 10.6165 9.88436 10.6692 9.75223 10.6692C9.6201 10.6692 9.49344 10.6165 9.40039 10.5226L5.29232 6.40817L1.18424 10.5226C1.09119 10.6165 0.964535 10.6692 0.832404 10.6692C0.700273 10.6692 0.573613 10.6165 0.480566 10.5226C0.386753 10.4295 0.333984 10.3028 0.333984 10.1707C0.333984 10.0385 0.386753 9.91178 0.480566 9.8187L4.5936 5.70921L0.480566 1.59972C0.354867 1.47397 0.305775 1.2907 0.351785 1.11893C0.397794 0.947163 0.531913 0.812997 0.703622 0.766972C0.875331 0.720947 1.05854 0.770055 1.18424 0.895797L5.29232 5.01025L9.40039 0.895797C9.59471 0.701416 9.90975 0.701416 10.1041 0.895797C10.2984 1.09018 10.2984 1.40533 10.1041 1.59972L5.99104 5.70921L10.1041 9.8187Z"
                                    fill=""
                                  />
                                </svg>
                              </div>
                            </Tooltip>
                          ))}
                        {/* </div>
                      </div> */}
                      </div>
                      {/* <div className="panel-txtarea-blk ost-txt">
                      <textarea
                        value={i[1].Contentparams.contentdescription}
                        onChange={editOstValue}
                        placeholder="Enter OST"
                        className="panel-txtarea"
                      ></textarea>
                    </div> */}
                    </>
                  ]);
                } else if (OSTtype === BULLETFONT) {
                  setbulletOST((state) => [
                    ...state,

                    <>
                      <div className={classNames} id={ostID} key={ostID}>
                        <p onClick={() => handleOstClick(ostID)}>OST {array}</p>
                        {ostID == ActiveOst &&
                          (OSTArrayLength === 1 ? (
                            ""
                          ) : (
                            <Tooltip title="Delete" arrow>
                              <div
                                //
                                className="cm-del-icon"
                                onClick={() => {
                                  setdeleteOstId(ostID);
                                  setopenUpdateModal(true);
                                  setDeleteOSTValidate(ActiveModule + "_" + ActiveSlide + "_" + ActiveScreen + "_" + ostID);
                                }}>
                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M10.1041 9.8187C10.1979 9.91178 10.2507 10.0385 10.2507 10.1707C10.2507 10.3028 10.1979 10.4295 10.1041 10.5226C10.011 10.6165 9.88436 10.6692 9.75223 10.6692C9.6201 10.6692 9.49344 10.6165 9.40039 10.5226L5.29232 6.40817L1.18424 10.5226C1.09119 10.6165 0.964535 10.6692 0.832404 10.6692C0.700273 10.6692 0.573613 10.6165 0.480566 10.5226C0.386753 10.4295 0.333984 10.3028 0.333984 10.1707C0.333984 10.0385 0.386753 9.91178 0.480566 9.8187L4.5936 5.70921L0.480566 1.59972C0.354867 1.47397 0.305775 1.2907 0.351785 1.11893C0.397794 0.947163 0.531913 0.812997 0.703622 0.766972C0.875331 0.720947 1.05854 0.770055 1.18424 0.895797L5.29232 5.01025L9.40039 0.895797C9.59471 0.701416 9.90975 0.701416 10.1041 0.895797C10.2984 1.09018 10.2984 1.40533 10.1041 1.59972L5.99104 5.70921L10.1041 9.8187Z"
                                    fill=""
                                  />
                                </svg>
                              </div>
                            </Tooltip>
                          ))}
                      </div>

                      {/* <div className="panel-txtarea-blk ost-txt">
                      <textarea
                        value={i[1].Contentparams.contentdescription}
                        onChange={editOstValue}
                        placeholder="Enter OST"
                        className="panel-txtarea"
                      ></textarea>
                    </div> */}
                    </>
                  ]);
                }
              }
            });
            // return slidesArray;
          }
        }
      }
    }
  };

  const addOst = async (fonttag) => {
    let newOst = "";
    let ostArray = [];
    var lastItem;
    var OstNo;
    var a;
    var b;
    var array;
    let OSTLength = [];
    let response = await getStyles(projectID);
    const OST = projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen];

    Object.entries(OST).map((item, key) => {
      item.map((data) => {
        if (data.Contenttype === "OST" && item[0] !== "") {
          ostArray.push(item[0]);
          lastItem = ostArray.pop();
          OstNo = lastItem.replace("OS0", "");
          a = parseInt(OstNo);
          array = OstNo.split("OS");
          b = parseInt(array[1]);
          if (array[1] !== undefined && array[1] !== null && array[1] !== "") {
            data = b + 1;
          } else {
            data = a + 1;
          }
          if (data !== undefined && data !== null) {
            newOst = data <= 9 ? "OS0" + data : "OS" + data;
          }
        }
      });
    });
    let styleData = await response.data.result;

    AddFeature({
      [newOst]: {
        Contenttype: "OST",
        Contentparams: {
          sequenceid: "4",
          contentdescription: "",
          contenttag: "",
          FontFamily: FONT_URL + styleData.fontFamily + "/" + styleData.fontStyle,
          FontSizeH: styleData.headingSize,
          FontSizeP: styleData.paragraphSize,
          FontColour: styleData.textColor,
          fontTag: fonttag
        }
      }
    });
    // if (EditMode) {
    let data = [];
    data.push(ActiveModule + "_" + ActiveSlide + "_" + ActiveScreen + "_" + newOst);
    let projectList1 = [...ostCountValidate, ...data];
    setostCountValidate(projectList1);
    // props.setostCountValidate(projectList1)
    // }
  };

  useEffect(() => {
    props.setostCountValidate(ostCountValidate);
  }, [ostCountValidate]);

  useEffect(() => {
    if (projectLoaded) {
      if (projects.createdProject[projectId].Modules[ActiveModule] !== undefined) {
        if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined) {
          if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined) {
            if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen][ActiveOst] !== undefined) {
              Object.entries(projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen][ActiveOst]).map(
                (item, key) => {
                  // if (item[0].Contenttype == "OST") {
                  let fontTag = item[1].fontTag;
                  let data = item[1].contentdescription;
                  if (data !== undefined) {
                    setOstValue(data);
                    if (selectedTag !== undefined && selectedTag !== "") {
                      setSelectedTag((prev) => fontTag || prev);
                    }
                  } else {
                    setOstValue("");
                  }
                  // }
                }
              );
            }
          }
        }
      }
    }
  }, [ActiveOst, projects]);

  const editOstValue = (event, fonttag) => {
    let value = event.target.value;
    const regMatch = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};:\\|,.0-9<>\/?'"`]*$/.test(value);
    // const regMatch = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};:\\|,.0-9<>\/?]*$/.test(value);
    if (regMatch === false) {
      setOstValidate1(true);
    } else {
      if (event.target.value.length > 1500) {
        setOstValidate(true);
      } else {
        setOstValidate(false);
        EditOst({
          sequenceid: "4",
          contentdescription: event.target.value,
          contenttag: "",
          FontFamily: FontUrl,
          FontSizeH: FontHSize,
          FontSizeP: FontPSize,
          FontColour: TextColor,
          fontTag: fonttag
        });
        setOstValue(event.target.value);
      }
    }
  };

  useEffect(() => {
    if (selectedTag == HEADERFONT && headingOST.length > 0) {
      SelectOst(headingOST[0].props.children.key);
    } else if (selectedTag == PARAGRAPHFONT && paragraphOST.length > 0) {
      SelectOst(paragraphOST[0].props.children.key);
    } else if (selectedTag == BULLETFONT && bulletOST.length > 0) {
      SelectOst(bulletOST[0].props.children.key);
    }
  }, [selectedTag]);
  //dependency is removed

  // useEffect(() => {
  //   if (selectedTag == HEADERFONT && headingOST.length > 0) {
  //     SelectOst(headingOST[0].props.children.key);
  //   }
  //   else if (selectedTag == PARAGRAPHFONT && paragraphOST.length > 0) {
  //     SelectOst(paragraphOST[0].props.children.key);
  //   } else if (selectedTag == BULLETFONT && bulletOST.length > 0) {
  //     SelectOst(bulletOST[0].props.children.key);
  //   }
  // }, [checked])

  const SetFont = (font) => {
    setSelectedTag(font);
    // EditOst({
    //   sequenceid: "4",
    //   contentdescription: ostValue,
    //   contenttag: "",
    //   FontFamily: FontUrl,
    //   FontSizeH: FontHSize,
    //   FontSizeP: FontPSize,
    //   FontColour: TextColor,
    //   fontTag: font,
    // });
  };

  return (
    <div className="cm-ost-blk">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center panel-label">
          OST
          <div className="info-icon ps-2">
            <Tooltip title="Five heading OST,Five paragraph OST and Five bullet OST is allowed for a particular screen" arrow>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14ZM7.81375 5.7645L6.93875 9.88138C6.8775 10.1789 6.96413 10.3477 7.20475 10.3477C7.3745 10.3477 7.63088 10.2865 7.805 10.1325L7.728 10.4965C7.47687 10.7993 6.923 11.0197 6.44613 11.0197C5.831 11.0197 5.56938 10.6505 5.73913 9.86562L6.38487 6.83113C6.44088 6.57475 6.39013 6.482 6.13375 6.41988L5.73913 6.349L5.81087 6.01562L7.81462 5.7645H7.81375ZM7 4.8125C6.76794 4.8125 6.54538 4.72031 6.38128 4.55622C6.21719 4.39212 6.125 4.16956 6.125 3.9375C6.125 3.70544 6.21719 3.48288 6.38128 3.31878C6.54538 3.15469 6.76794 3.0625 7 3.0625C7.23206 3.0625 7.45462 3.15469 7.61872 3.31878C7.78281 3.48288 7.875 3.70544 7.875 3.9375C7.875 4.16956 7.78281 4.39212 7.61872 4.55622C7.45462 4.72031 7.23206 4.8125 7 4.8125Z"
                  fill="#C0C2C4"
                />
              </svg>
            </Tooltip>
          </div>
        </div>

        {projectLoaded && selectedTag == HEADERFONT && (
          // item.length
          <div className="d-flex align-items-center">
            <div
              onClick={() => {
                if (headingOST.length < headinglimit) {
                  addOst(HEADERFONT);
                }
              }}
              className={
                headingOST.length < headinglimit
                  ? "d-flex align-items-center cm-add-label cm-a-space justify-content-end"
                  : "d-flex align-items-center cm-add-label cm-a-space justify-content-end not-allowed"
              }>
              <svg className="me-2" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.47266 3.17188H9.52734C9.62109 3.17188 9.66797 3.21875 9.66797 3.3125V15.6875C9.66797 15.7812 9.62109 15.8281 9.52734 15.8281H8.47266C8.37891 15.8281 8.33203 15.7812 8.33203 15.6875V3.3125C8.33203 3.21875 8.37891 3.17188 8.47266 3.17188Z"
                  fill=""
                />
                <path
                  d="M3.09375 8.83203H14.9062C15 8.83203 15.0469 8.87891 15.0469 8.97266V10.0273C15.0469 10.1211 15 10.168 14.9062 10.168H3.09375C3 10.168 2.95312 10.1211 2.95312 10.0273V8.97266C2.95312 8.87891 3 8.83203 3.09375 8.83203Z"
                  fill=""
                />
              </svg>
              <Tooltip title={headingOST.length < headinglimit ? "Add Heading OST" : "5 Heading OST is allowed"} arrow>
                <span>Add OST</span>
              </Tooltip>
            </div>
          </div>
        )}
        {projectLoaded && selectedTag == PARAGRAPHFONT && (
          // item.length
          <div className="d-flex align-items-center">
            {checked && (
              <div
                onClick={() => {
                  if (paragraphOST.length < paragraphlimit) {
                    addOst(PARAGRAPHFONT);
                  }
                }}
                className={
                  paragraphOST.length < paragraphlimit
                    ? "d-flex align-items-center cm-add-label cm-a-space justify-content-end"
                    : "d-flex align-items-center cm-add-label cm-a-space justify-content-end not-allowed"
                }>
                <svg className="me-2" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.47266 3.17188H9.52734C9.62109 3.17188 9.66797 3.21875 9.66797 3.3125V15.6875C9.66797 15.7812 9.62109 15.8281 9.52734 15.8281H8.47266C8.37891 15.8281 8.33203 15.7812 8.33203 15.6875V3.3125C8.33203 3.21875 8.37891 3.17188 8.47266 3.17188Z"
                    fill=""
                  />
                  <path
                    d="M3.09375 8.83203H14.9062C15 8.83203 15.0469 8.87891 15.0469 8.97266V10.0273C15.0469 10.1211 15 10.168 14.9062 10.168H3.09375C3 10.168 2.95312 10.1211 2.95312 10.0273V8.97266C2.95312 8.87891 3 8.83203 3.09375 8.83203Z"
                    fill=""
                  />
                </svg>
                <Tooltip title={paragraphOST.length < paragraphlimit ? "Add Paragraph OST" : "5 Paragraph OST is allowed"} arrow>
                  <span>Add OST</span>
                </Tooltip>
              </div>
            )}
          </div>
        )}

        {projectLoaded && selectedTag == BULLETFONT && (
          // item.length
          <div className="d-flex align-items-center">
            {/* {checked && */}
            <div
              onClick={() => {
                if (bulletOST.length < bulletlimit) {
                  addOst(BULLETFONT);
                }
              }}
              className={
                bulletOST.length < bulletlimit
                  ? "d-flex align-items-center cm-add-label cm-a-space justify-content-end"
                  : "d-flex align-items-center cm-add-label cm-a-space justify-content-end not-allowed"
              }>
              <svg className="me-2" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.47266 3.17188H9.52734C9.62109 3.17188 9.66797 3.21875 9.66797 3.3125V15.6875C9.66797 15.7812 9.62109 15.8281 9.52734 15.8281H8.47266C8.37891 15.8281 8.33203 15.7812 8.33203 15.6875V3.3125C8.33203 3.21875 8.37891 3.17188 8.47266 3.17188Z"
                  fill=""
                />
                <path
                  d="M3.09375 8.83203H14.9062C15 8.83203 15.0469 8.87891 15.0469 8.97266V10.0273C15.0469 10.1211 15 10.168 14.9062 10.168H3.09375C3 10.168 2.95312 10.1211 2.95312 10.0273V8.97266C2.95312 8.87891 3 8.83203 3.09375 8.83203Z"
                  fill=""
                />
              </svg>
              <Tooltip title={bulletOST.length < bulletlimit ? "Add Bullet OST" : "5 Bullet OST is allowed"} arrow>
                <span>Add OST</span>
              </Tooltip>
            </div>
            {/* } */}
          </div>
        )}

        {/* {!checked &&

          <div className="d-flex align-items-center ost-wraper dropdown">

            <div className="d-flex align-items-center cm-add-label cm-a-space justify-content-end" data-bs-toggle="dropdown" aria-expanded="false">
              <svg className="me-2" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.47266 3.17188H9.52734C9.62109 3.17188 9.66797 3.21875 9.66797 3.3125V15.6875C9.66797 15.7812 9.62109 15.8281 9.52734 15.8281H8.47266C8.37891 15.8281 8.33203 15.7812 8.33203 15.6875V3.3125C8.33203 3.21875 8.37891 3.17188 8.47266 3.17188Z" fill="" />
                <path d="M3.09375 8.83203H14.9062C15 8.83203 15.0469 8.87891 15.0469 8.97266V10.0273C15.0469 10.1211 15 10.168 14.9062 10.168H3.09375C3 10.168 2.95312 10.1211 2.95312 10.0273V8.97266C2.95312 8.87891 3 8.83203 3.09375 8.83203Z" fill="" />
              </svg>
              <span>Add OST</span>
            </div>

            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton1">
              <ul>

                <li
                  onClick={() => {
                    if (headingOST.length < headinglimit) {
                      addOst(HEADERFONT)
                    }
                  }
                  }
                >
                  <a className={headingOST.length < headinglimit ? "dropdown-item cursor-pointer" : "dropdown-item not-allowed"}>
                    <Tooltip title={headingOST.length < headinglimit ? "Add Heading OST" : "5 Heading OST is allowed"} arrow>
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.25 5.25V18.75" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.75 12H5.25" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.75 5.25V18.75" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg></Tooltip>
                  </a>



                </li>
                <li onClick={() => {
                  if (paragraphOST.length < paragraphlimit) {
                    addOst(PARAGRAPHFONT)
                  }
                }
                }>
                  <a className={paragraphOST.length < paragraphlimit ? "dropdown-item cursor-pointer" : "dropdown-item not-allowed"} >
                    <Tooltip title={paragraphOST.length < paragraphlimit ? "Add Paragraph OST" : "5 Paragraph OST is allowed"} arrow>
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.75 12H18" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M3.75 6H20.25" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M3.75 18H12" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg></Tooltip>

                  </a>
                </li>

                <li onClick={() => {
                  if (bulletOST.length < bulletlimit) {
                    addOst(BULLETFONT)
                  }
                }
                }>
                  <a className={bulletOST.length < bulletlimit ? "dropdown-item cursor-pointer" : "dropdown-item not-allowed"}
                    data-bs-toggle="modal" data-bs-target="#delete-modal">
                    <Tooltip title={bulletOST.length < bulletlimit ? "Add Bullet OST" : "5 Bullet OST is allowed"} arrow>
                      <svg className="fill-svg" width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.07692 4.15385C0.927692 4.15385 0 3.22615 0 2.07692C0 0.941539 0.927692 0 2.07692 0C3.21231 0 4.15385 0.941539 4.15385 2.07692C4.15385 3.22615 3.21231 4.15385 2.07692 4.15385ZM5.53846 1.38462H18V2.76923H5.53846V1.38462ZM2.07692 11.0769C0.927692 11.0769 0 10.1492 0 9C0 7.86462 0.927692 6.92308 2.07692 6.92308C3.21231 6.92308 4.15385 7.86462 4.15385 9C4.15385 10.1492 3.21231 11.0769 2.07692 11.0769ZM5.53846 8.30769H18V9.69231H5.53846V8.30769ZM2.07692 18C0.927692 18 0 17.0723 0 15.9231C0 14.7877 0.927692 13.8462 2.07692 13.8462C3.21231 13.8462 4.15385 14.7877 4.15385 15.9231C4.15385 17.0723 3.21231 18 2.07692 18ZM5.53846 15.2308H18V16.6154H5.53846V15.2308Z" fill="" />
                      </svg></Tooltip>

                  </a>
                </li>
              </ul>
            </div>
          </div>
        } */}
      </div>
      <div className="cm-ost-division">
        <div className="navtab-selector">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <button
                className={selectedTag === HEADERFONT ? "nav-link active" : "nav-link"}
                id="heading-tab"
                data-bs-toggle="tab"
                data-bs-target="#cm-heading"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                onClick={() => SetFont(HEADERFONT)}>
                <div className="cm-vs-container">
                  <div className="cm-vs-icon-holder cm-asset-icon mb-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.25 5.25V18.75" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M18.75 12H5.25" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M18.75 5.25V18.75" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                  <div className="cm-asset-name">Heading</div>
                  <div className="tick-active">
                    <img src="../../../assets/img/asset-tick.svg" />
                  </div>
                </div>
              </button>
            </li>
            <li className="nav-item">
              <button
                className={selectedTag === PARAGRAPHFONT ? "nav-link active" : "nav-link"}
                id="paragraph-tab"
                data-bs-toggle="tab"
                data-bs-target="#cm-paragraph"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                onClick={() => SetFont(PARAGRAPHFONT)}>
                <div className="cm-vs-container">
                  <div className="cm-vs-icon-holder cm-asset-icon mb-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.75 12H18" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M3.75 6H20.25" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M3.75 18H12" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                  <div className="cm-asset-name">Paragraph</div>
                  <div className="tick-active">
                    <img src="../../../assets/img/asset-tick.svg" />
                  </div>
                </div>
              </button>
            </li>
            <li className="nav-item">
              <button
                className={selectedTag === BULLETFONT ? "nav-link active" : "nav-link"}
                id="bullet-tab"
                data-bs-toggle="tab"
                data-bs-target="#cm-bullet"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                onClick={() => SetFont(BULLETFONT)}>
                <div className="cm-vs-container">
                  <div className="cm-vs-icon-holder cm-asset-icon mb-2">
                    <svg className="fill-svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.07692 4.15385C0.927692 4.15385 0 3.22615 0 2.07692C0 0.941539 0.927692 0 2.07692 0C3.21231 0 4.15385 0.941539 4.15385 2.07692C4.15385 3.22615 3.21231 4.15385 2.07692 4.15385ZM5.53846 1.38462H18V2.76923H5.53846V1.38462ZM2.07692 11.0769C0.927692 11.0769 0 10.1492 0 9C0 7.86462 0.927692 6.92308 2.07692 6.92308C3.21231 6.92308 4.15385 7.86462 4.15385 9C4.15385 10.1492 3.21231 11.0769 2.07692 11.0769ZM5.53846 8.30769H18V9.69231H5.53846V8.30769ZM2.07692 18C0.927692 18 0 17.0723 0 15.9231C0 14.7877 0.927692 13.8462 2.07692 13.8462C3.21231 13.8462 4.15385 14.7877 4.15385 15.9231C4.15385 17.0723 3.21231 18 2.07692 18ZM5.53846 15.2308H18V16.6154H5.53846V15.2308Z"
                        fill=""
                      />
                    </svg>
                  </div>
                  <div className="cm-asset-name">Bullet</div>
                  <div className="tick-active">
                    <img src="../../../assets/img/asset-tick.svg" />
                  </div>
                </div>
              </button>
            </li>
          </ul>
        </div>
        <div className="tab-pane-block">
          <div className="tab-content">
            <div className="tab-pane  show active" id="cm-heading" role="tabpanel" aria-labelledby="heading-tab">
              {/* {checked ? */}
              <div className="d-flex justify-content-between cm-full-lst-blk mb-3">
                <div className="d-flex cm-full-lst-wrapper">
                  {projectLoaded &&
                    selectedTag == HEADERFONT &&
                    headingOST.map((item) => {
                      return item;
                    })}

                  {projectLoaded &&
                    selectedTag == PARAGRAPHFONT &&
                    paragraphOST.map((item) => {
                      return item;
                    })}
                  {projectLoaded &&
                    selectedTag == BULLETFONT &&
                    bulletOST.map((item) => {
                      return item;
                    })}
                </div>
              </div>
              {/* :
               ((selectedTag === PARAGRAPHFONT && paragraphOST.length > 0) ||
                 (selectedTag === BULLETFONT && bulletOST.length > 0) || (selectedTag === HEADERFONT
                   && headingOST.length > 0)) ?
                 <div className="d-flex justify-content-between cm-full-lst-blk mb-3">
                   <div className="d-flex cm-full-lst-wrapper">
                     {listOst1()}
                   </div>
                 </div>
                 : ""} */}

              {selectedTag === HEADERFONT && headingOST.length > 0 ? (
                <div className="panel-txtarea-blk ost-txt">
                  <textarea
                    value={ostValue}
                    onChange={(e) => editOstValue(e, HEADERFONT)}
                    placeholder="Enter OST"
                    className="panel-txtarea"></textarea>
                  <div className="d-flex justify-content-between">
                    <p className="panel-count d-flex justify-content-start">{ostValue.length} characters</p>
                    {props?.invalidOstScreens?.some(
                      (item) =>
                        item?.activeModule === ActiveModule &&
                        item?.activeSlide === ActiveSlide &&
                        item?.activeScreen === ActiveScreen &&
                        item?.ostName === ActiveOst
                    ) && <p className="input-error-msg">OST is required </p>}
                  </div>
                </div>
              ) : selectedTag === HEADERFONT ? (
                <div className="v-gal-empty-blk">
                  <div className="no-txt-selected blink">No Heading OST</div>
                </div>
              ) : (
                ""
              )}
              {selectedTag === PARAGRAPHFONT && paragraphOST.length > 0 ? (
                <div className="panel-txtarea-blk ost-txt">
                  <textarea
                    value={ostValue}
                    onChange={(e) => editOstValue(e, PARAGRAPHFONT)}
                    placeholder="Enter OST"
                    className="panel-txtarea"></textarea>
                  <div className="d-flex justify-content-between">
                    <p className=" panel-count d-flex justify-content-start">{ostValue.length} characters</p>
                    {props?.invalidOstScreens?.some(
                      (item) =>
                        item?.activeModule === ActiveModule &&
                        item?.activeSlide === ActiveSlide &&
                        item?.activeScreen === ActiveScreen &&
                        item?.ostName === ActiveOst
                    ) && <p className="input-error-msg">OST is required </p>}
                  </div>
                </div>
              ) : selectedTag === PARAGRAPHFONT ? (
                <div className="v-gal-empty-blk">
                  <div className="no-txt-selected blink">No Paragraph OST</div>
                </div>
              ) : (
                ""
              )}
              {selectedTag === BULLETFONT && bulletOST.length > 0 ? (
                <div className="panel-txtarea-blk ost-txt">
                  <textarea
                    value={ostValue}
                    onChange={(e) => editOstValue(e, BULLETFONT)}
                    placeholder="Enter OST"
                    className="panel-txtarea"></textarea>
                  <div>
                    <p className="panel-count d-flex justify-content-start">{ostValue.length} characters</p>
                    {props?.invalidOstScreens?.some(
                      (item) =>
                        item?.activeModule === ActiveModule &&
                        item?.activeSlide === ActiveSlide &&
                        item?.activecreen === ActiveScreen &&
                        item?.ostName === ActiveOst
                    ) && <p className="input-error-msg">OST is required </p>}
                  </div>
                </div>
              ) : selectedTag === BULLETFONT ? (
                <div className="v-gal-empty-blk">
                  <div className="no-txt-selected blink">No Bullet OST</div>
                </div>
              ) : (
                ""
              )}
              {ostValidate ? (
                <div name="courseName" component="div" className="input-error-msg">
                  Must be less than 1500 characters
                </div>
              ) : null}
              
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={openUpdateModal}
        Closemodal={setopenUpdateModal}
        Content="Do you want to delete the ost?"
        Header="Are you sure"
        Buttonlabel="Yes, Delete it!"
        Buttonclick={DeleteOST}
        del_id={deleteOstId}
        del_Validateid={deleteOSTValidate}
      />
    </div>
  );
};
export default AddOst;
