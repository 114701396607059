import React, { useState, useEffect } from 'react';

// Icons
import { pageFirstIcon, pageLastIcon, pagePrevIcon, pageNextIcon } from '../../constants/icon';

function PaginationComponent(props) {
  const { currentPage, totalPages, onPageChange, onFirst, onPrevious, onNext, onLast } = props;

  const [inputPage, setInputPage] = useState(currentPage);

  const handleInputChange = e => {
    const value = e.target.value;
    if (value >= 1 && value <= totalPages) {
      setInputPage(value);
    }
  };

  const handleInputBlur = () => {
    onPageChange(inputPage);
  };

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  useEffect(() => {
    setInputPage(currentPage);
  }, [currentPage]);

  return (
    <ul className={`custom-pagination ${props.className || ''}`}>
      <li className={isFirstPage ? 'disabled' : ''}>
        <div className="custom-pagination-control" onClick={onFirst}>
          {pageFirstIcon}
        </div>
      </li>
      <li className={isFirstPage ? 'disabled' : ''}>
        <div className="custom-pagination-control" onClick={onPrevious}>
          {pagePrevIcon} Previous
        </div>
      </li>
      <li>
        <input
          type="number"
          value={inputPage}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          min="1"
          max={totalPages}
          disabled
        />
        <span>of {totalPages}</span>
      </li>
      <li className={isLastPage ? 'disabled' : ''}>
        <div className="custom-pagination-control" onClick={onNext}>
          Next {pageNextIcon}
        </div>
      </li>
      <li className={isLastPage ? 'disabled' : ''}>
        <div className="custom-pagination-control" onClick={onLast}>
          {pageLastIcon}
        </div>
      </li>
    </ul>
  );
}

export default PaginationComponent;
