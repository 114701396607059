import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actionCreator } from '../../../store';
import { IMAGE_LIMIT } from '../../../constants';
import TagInput from '../../../components/tagInput';
import { showToaster } from '../../../services/helper';
import CustomModal from '../../../services/CustomModal';
import FormTextarea from '../../../components/form/formTextarea';
import FormInputGroup from '../../login/components/FormInputGroup';
import CarouselItem from '../../../components/carousel/carouselItem';
import {
  backArrowIcon,
  plusIcon,
  searchIcon,
  crossIcon,
  eyeOpenIcon,
  editIcon,
} from '../../../constants/icon';
import ViewModal from '../../../components/editProject/viewModal';
import EditAssets from '../../../components/editProject/editAsset';

// import SampleImage from '../../../assets/img/image-01.png';
// import SampleImage2 from '../../../assets/img/image-02.png';
// import SampleImage3 from '../../../assets/img/image-03.png';
// import SampleImage4 from '../../../assets/img/image-04.png';
// import SampleImage5 from '../../../assets/img/image-05.png';
// import SampleImage6 from '../../../assets/img/image-06.png';
// import SampleImage7 from '../../../assets/img/image-07.png';
// import SampleImage8 from '../../../assets/img/image-08.png';
// import SampleImage9 from '../../../assets/img/image-09.png';
// import SampleImage10 from '../../../assets/img/image-10.png';
// import SampleImage11 from '../../../assets/img/image-11.png';
// import SampleImage12 from '../../../assets/img/image-12.png';
// import SampleImage13 from '../../../assets/img/image-13.png';
// import SampleImage14 from '../../../assets/img/image-14.png';
// import SampleImage15 from '../../../assets/img/image-15.png';

export default function ImageComponent({ goBack, imageList, manipulateTemplateArray }) {
  const dispatch = useDispatch();
  const { AddFeature, EditAsset, DeleteFeature, SelectImage } = bindActionCreators(
    actionCreator,
    dispatch
  );

  const [openImageView, setOpenImageView] = useState('');
  const [openImageEdit, setOpenImageEdit] = useState('');
  const [deleteImageData, setDeleteImageData] = useState('');

  const onChangeImageDescription = value => {
    manipulateTemplateArray();
    EditAsset({ searchstring: value }, 'image');
  };

  const handleTagChange = tags => {
    manipulateTemplateArray();
    EditAsset({ contenttag: tags.toString() }, 'image');
  };

  // const handleAddImage = () => {
  //   if (imageList.length >= IMAGE_LIMIT) return;

  //   const lastImageId = imageList[imageList.length - 1].imageId;
  //   const imageNumber = lastImageId.replace('IM0', '');
  //   const imageNumberSplit = imageNumber.split('IM')[1];

  //   let data;
  //   if (imageNumberSplit) data = parseInt(imageNumberSplit) + 1;
  //   else data = parseInt(imageNumber) + 1;

  //   const newImage = data < 10 ? 'IM0' + data : 'IM' + data;

  //   AddFeature({
  //     [newImage]: {
  //       Contenttype: 'Image',
  //       Contentparams: {
  //         contenttag: '',
  //         sequenceid: '3',
  //         searchstring: '',
  //         contentdescription: '',
  //       },
  //     },
  //   });
  // };

  const removeImage = () => {
    if (imageList.length > 1) {
      manipulateTemplateArray();
      DeleteFeature(deleteImageData, 'IM');
      showToaster(`${deleteImageData} Deleted Successfully`, 'success');
      setDeleteImageData('');
    }
  };

  const handleImageFocus = imageId => SelectImage(imageId);
  const handleRemoveDescription = () =>
    EditAsset({ contentdescription: '', contenttag: '', searchstring: '' }, 'image');

  return (
    <>
      <div className="screen-sidebar-header screen-header-back">
        <div className="sticky-head">
          <span role="button" onClick={goBack}>
            {backArrowIcon} Images
          </span>
          <p>Upload, or add to your images</p>
        </div>
      </div>
      <div className="screen-sidebar-body screen-audio-wrap pe-2">
        {/* <FormInputGroup
          leftIcon={searchIcon}
          type="search"
          id="list"
          placeholder=""
          label="Search Images"
          className="custom-search-form"
        /> */}

        <>
          {imageList.map(image => (
            <div className="tag-input-content" key={image.imageId}>
              <h4>{image.label}</h4>
              <div className="tag-input-holder">
                <div className="tag-input-wrapper">
                  <FormTextarea
                    id="message"
                    onChange={onChangeImageDescription}
                    inputValue={image.contentParams.searchstring}
                    onFocus={() => handleImageFocus(image.imageId)}
                    // label="Write script for screen"
                    disabled={image.contentParams.contentdescription ? true : false}
                    label="Add Description for Asset Generation/ Uploaded Asset"
                  />
                  <div>
                    <TagInput
                      label="Add Tag"
                      onChange={handleTagChange}
                      tags={image.contentParams.contenttag}
                      placeholder="Add new tag and press enter"
                      handleFocus={() => handleImageFocus(image.imageId)}
                      disabled={image.contentParams.contentdescription ? true : false}
                    />
                  </div>
                </div>
                {imageList.length > 1 && (
                  <span role="button" onClick={() => setDeleteImageData(image.imageId)}>
                    {crossIcon}
                  </span>
                )}
              </div>
              {image.contentParams.contentdescription && (
                <CarouselItem
                  className="mb-4"
                  crossBtn={crossIcon}
                  handleRemove={handleRemoveDescription}
                  carouselImage={image.contentParams.contentdescription}
                >
                  <div
                    className="carousel-icon-wrap"
                    onClick={() => handleImageFocus(image.imageId)}
                  >
                    <span
                      role="button"
                      className="image-view"
                      onClick={() => setOpenImageView(image.contentParams.contentdescription)}
                    >
                      {eyeOpenIcon}
                    </span>
                    <span
                      role="button"
                      className="image-edit"
                      onClick={() => setOpenImageEdit(image.contentParams.contentdescription)}
                    >
                      {editIcon}
                    </span>
                  </div>
                </CarouselItem>
              )}
            </div>
          ))}
        </>

        {/* {IMAGE_LIMIT > imageList.length ? (
          <button className="triiger-btn tag-content-btn" role="button" onClick={handleAddImage}>
            <span className="upload-icon">{plusIcon}</span>
            <span>Add New Image</span>
          </button>
        ) : (
          <div className="screen-thumbnail-items-wrap">
            <span>Max. image limit reached</span>
          </div>
        )} */}

        {/* <div className="add-media-assests">
          <button className="triiger-btn" role="button">
            <span className="upload-icon">{plusIcon}</span>
          </button>
          <CarouselItem carouselImage={SampleImage} />
          <CarouselItem carouselImage={SampleImage2} />
          <CarouselItem carouselImage={SampleImage3} />
          <CarouselItem carouselImage={SampleImage4} />
          <CarouselItem carouselImage={SampleImage5} />
          <CarouselItem carouselImage={SampleImage6} />
          <CarouselItem carouselImage={SampleImage7} />
          <CarouselItem carouselImage={SampleImage8} />
          <CarouselItem carouselImage={SampleImage9} />
          <CarouselItem carouselImage={SampleImage10} />
          <CarouselItem carouselImage={SampleImage11} />
          <CarouselItem carouselImage={SampleImage12} />
          <CarouselItem carouselImage={SampleImage13} />
          <CarouselItem carouselImage={SampleImage14} />
          <CarouselItem carouselImage={SampleImage15} />
          <CarouselItem carouselImage={SampleImage} />
          <CarouselItem carouselImage={SampleImage2} />
          <CarouselItem carouselImage={SampleImage3} />
          <CarouselItem carouselImage={SampleImage4} />
          <CarouselItem carouselImage={SampleImage5} />
          <CarouselItem carouselImage={SampleImage6} />
          <CarouselItem carouselImage={SampleImage7} />
          <CarouselItem carouselImage={SampleImage} />
          <CarouselItem carouselImage={SampleImage2} />
          <CarouselItem carouselImage={SampleImage3} />
          <CarouselItem carouselImage={SampleImage4} />
          <CarouselItem carouselImage={SampleImage5} />
          <CarouselItem carouselImage={SampleImage6} />
          <CarouselItem carouselImage={SampleImage7} />
          <CarouselItem carouselImage={SampleImage8} />
          <CarouselItem carouselImage={SampleImage9} />
          <CarouselItem carouselImage={SampleImage10} />
          <CarouselItem carouselImage={SampleImage11} />
          <CarouselItem carouselImage={SampleImage12} />
          <CarouselItem carouselImage={SampleImage13} />
          <CarouselItem carouselImage={SampleImage14} />
          <CarouselItem carouselImage={SampleImage15} />
        </div> */}
      </div>
      <CustomModal
        Header="Are you sure"
        Buttonclick={removeImage}
        isOpen={!!deleteImageData}
        Buttonlabel="Yes, Delete it!"
        Closemodal={setDeleteImageData}
        Content={`Do you want to delete ${deleteImageData}?`}
      />
      <ViewModal open={!!openImageView} onHide={() => setOpenImageView('')}>
        <img src={openImageView} alt="image_view" className="w-100 h-100" />
      </ViewModal>
      <EditAssets asset={openImageEdit} onHide={() => setOpenImageEdit('')} type="image" />
    </>
  );
}
