import React, { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/debounceHook';

const FormInputGroup = props => {
  const {
    id,
    type,
    label,
    onClick,
    onChange,
    leftIcon,
    rightIcon,
    inputValue,
    showInputLength,
    placeholder = '',
    onFocus = () => {},
  } = props;

  const { debounce, debouncedValue } = useDebounce();
  const [selectedValue, setSelectedValue] = useState(inputValue);

  /**
   * This component is also used in register and login page which uses formik.
   * The formik library expects React event. So, we can't send just debounceValue from here.
   * To cover this issue, event is stored and the value will be overwritten while calling onChange method
   */
  const [event, setEvent] = useState('');

  useEffect(() => {
    if (event) {
      const eventData = event;
      eventData.target.value = debouncedValue;
      onChange(eventData);
    }
  }, [debouncedValue]);

  useEffect(() => {
    // if (inputValue) setSelectedValue(inputValue);
    setSelectedValue(inputValue);
  }, [inputValue]);

  const handleChange = e => {
    const value = e.target.value;
    setSelectedValue(value);
    setEvent(e);
    debounce(value);
  };

  return (
    <div className={`custom-form-floating ${props.className || ''}`}>
      <span className="form-icon form-left-icon">{leftIcon}</span>
      <input
        id={id}
        type={type}
        onFocus={onFocus}
        value={selectedValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
      <label htmlFor={id}>
        {label}
        <span className="label-with-asterisk"></span>
      </label>
      <span className="form-icon form-right-icon" onClick={onClick}>
        {rightIcon}
      </span>
      {showInputLength && (
        <div className="screen-thumbnail-items-wrap mb-2">
          <span className="position-absolute end-0 mt-0">{selectedValue.length} characters</span>
        </div>
      )}
    </div>
  );
};

export default FormInputGroup;
