import React from 'react';
import ReactPlayer from 'react-player';

export default function VideoPreview(props) {
  const { src, alt, activeMediaUrl } = props;

  return (
    <div className={`video-preview-content ${props.className || ''}`}>
      <div className="video-wrap">
        {activeMediaUrl ? (
          <ReactPlayer
            width="100%"
            height="100%"
            muted={true}
            playing={false}
            controls={true}
            url={[activeMediaUrl]}
            // url={'https://youtu.be/zSPa2vh4IEo'}
            className="react-player"
          />
        ) : (
          <img src={src} alt={alt} />
        )}
      </div>
    </div>
  );
}
