import React, {
  useState,
  lazy,
  Suspense,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useHistory, useRouteMatch, Switch, Route } from "react-router-dom";
import {
  getAdminProjects,
  projectDeleteOrRestore,
  renderPreview,
  getProjectPreview,
  getDashboard,
  getCategory,
  getUsageProjectDetails,
  getAdminDashboard,
  getAdminProjectStatus,
} from "../../services/api.service";
import {
  getEnableWelcomeMsg,
  getUsersProjectRole,
  deleteUsersProjectRole,
  getUsersGlobalRole,
  getProjectById,
  getRenderErrorDetails,
  getUsersList,
} from "../../services/authService";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import PageLoader from "../../services/pageLoader";
import Tooltip from "@mui/material/Tooltip";
import { Modal, ModalBody } from "reactstrap";
import Header from "../components/header/header";
import ProgressBar from "../container/projectlist/ProgressBar";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { debounce } from "debounce";
import SaveChangeModal from "../../services/SaveChangeModal";
import SimpleBackdrop from "../container/pageLoader";
import Swal from "sweetalert2";
import { showToaster, ShowAlertBox, getUserItem } from "../../services/helper";
import {
  updateActiveProject,
  editStatus,
  selectedProject,
  updateProjectList,
  getNewProjectStatus,
  updateJson,
  updateActiveRecord,
  createdProject,
  updatePageNo,
} from "../../store/action";
import RenderVideo from "./RenderProject";
import NavigatorOnline from "react-navigator-online";
import CustomModal from "../../services/CustomModal";
import WelcomeModal from "./WelcomeModal";
import ErrorModal from "./Errormodal";
import { actionCreator } from "../../store";
import { bindActionCreators } from "redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { ALLCATEGORY, LISTSTATUSALL } from "../../constants";

const defaultSelectListValue = {
  value: "All Users",
  label: "All Users",
  id: 0,
};

const _loadSuggestions = (inputValue, callback) => {
  let usersList = [defaultSelectListValue];
  getUsersList({ search: inputValue })
    .then((resp) => {
      if (resp.data && resp.data.result) {
        usersList = [
          ...usersList,
          ...resp.data.result.map((n) => ({
            value: n.email,
            label: `${n.name !== undefined ? n.name : n.firstName} <${
              n.email
            }>`,
            id: n._id,
          })),
        ];
        callback(usersList);
      }
    })
    .catch((e) => callback(usersList));
};

const loadSuggestions = debounce(_loadSuggestions, 1000);

export default function ProjectListing() {
  const dispatch = useDispatch();
  const { EmptyPreview } = bindActionCreators(actionCreator, dispatch);
  const history = useHistory();
  const [projectList, setprojectList] = useState([]);
  const [keyWord, setKeyWord] = useState("");
  const [keyWordNew, setKeyWordNew] = useState("");
  const [includeDelete, setIncludeDelete] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [limit, setLimit] = useState(7);
  const [loadMoreFetching, setLoadMoreFetching] = useState(false);
  const [loadMoreEnable, setLoadMoreEnable] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [SearchBoxErrorMsg, setSearchBoxErrorMsg] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [EditProject, setEditProject] = useState(false);
  const [SelectedProject, setSelectedProject] = useState();
  const [renderMessage, setRenderMessage] = useState();
  const [priviewPageLoad, setPriviewPageLoad] = useState(false);
  const [openRenderVideo, setOpenRenderVideo] = useState(false);
  const [getRenderVideo, setGetRenderVideo] = useState(false);
  const [priviewrenderUrl, setPriviewrenderUrl] = useState("");
  let { path, url } = useRouteMatch();
  const userEditStatus = useSelector((state) => state.userEditStatus);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [openRenderErrorModal, setOpenRenderErrorModal] = useState(false);
  const [showWelcomeModal, setshowWelcomeModal] = useState(false);
  // const [openRenderModal, setopenRenderModal] = useState(false);
  const [messageOnline, setmessageOnline] = useState(
    "now! you have an internet connection."
  );
  const [messageOffline, setmessageOffline] = useState(
    "now! you have no internet connection."
  );
  const [deletedProjects, setdeletedProjects] = useState([]);
  const [trashedProject, settrashedProject] = useState(false);
  const [openUpdateModal, setopenUpdateModal] = useState(false);
  const [openRenderModal, setopenRenderModal] = useState(false);
  const [deleteProjectid, setdeleteProjectid] = useState(undefined);
  const [renderProjectId, setrenderProject] = useState(undefined);
  const [confirmmsg, setconfirmmsg] = useState("");
  const [confirmbtnlabel, setconfirmbtnlabel] = useState("");
  const [dashboardDetails, setDashboardDetails] = useState({});
  let getEnableWelcomeMsg = getUserItem("welcomemessage");
  const [downloadID, setdownloadID] = useState(undefined);
  const [listCategory, setListCategory] = useState({
    value: "All Category",
    label: "All Category",
    id: 0,
  });
  const [category, setCategory] = useState([]);
  const [fonts, setfonts] = useStateWithCallbackLazy();
  const [listFilterStatus, setListFilterStatus] = useState([]);
  const [listStatus, setListStatus] = useState({
    value: "All",
    status: 0,
  });
  const [projectRoleProjectId, setProjectRoleProjectId] = useState(undefined);
  const [statusOption, setStatusOption] = useStateWithCallbackLazy();
  const [createProjecteEnable, setCreateProjecteEnable] = useState(false);
  const [adduserEnable, setAdduserEnable] = useState(false);
  const [refreshEnable, setRefreshEnable] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [projectsReload, setProjectsReload] = useState(false);
  let userId = getUserItem("id");
  let AdminRole = getUserItem("role");
  const timerRef = useRef(null);

  useEffect(() => {
    setPageNo(0);
    setprojectList([]);
  }, [keyWord, listStatus, trashedProject, selectedUser]);

  useEffect(async () => {
    settrashedProject(false);
    // getAllProjects(1, 0, "", listCategory, listStatus);
    // getDashboardDetails();
    // getCategoryDetails();
    setshowWelcomeModal(true);
    FilterByStatus();
    console.log(listStatus);
  }, []);

  useEffect(async () => {
    getDashboardDetails();
  }, [selectedUser]);

  const FilterByStatus = () => {
    let filerStatus = [
      {
        value: "All",
        status: 0,
      },
      {
        value: "Draft",
        status: 1,
      },
      {
        value: "Rendering",
        status: 2,
      },
      {
        value: "Completed",
        status: 3,
      },
      {
        value: "Render Error",
        status: 4,
      },
      {
        value: "Invalid Project",
        status: 5,
      },
    ];

    setListFilterStatus(filerStatus);
  };

  const onSelectUser = (d) => {
    console.log(d);
    setSelectedUser(d.id);
  };

  const getCategoryDetails = () => {
    let userId = getUserItem("id");
    let body = {
      user_id: userId,
      list_id: 1,
    };
    let data = [];
    getCategory(body).then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          let ddd = response.data.list;
          if (response.data.list != undefined) {
            let projectData = {
              category: "All Category",
              id: 0,
            };
            let projectList1 = [...response.data.list, projectData];
            setCategory(projectList1);

            console.log(projectList1, "response");
          }
          // setCategory(ddd)
          // setListCategory({
          //   value: response.data.list[0].category,
          //   label: response.data.list[0].category,
          //   id: response.data.list[0].id
          // });

          // setListCatagory(
          //   ddd
          // );
          //  let data1=data.push(response.data.list[(Object.keys(response.data.list)])
        } else {
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      } catch (error) {
        console.log(error);

        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };

  const getDashboardDetails = () => {
    try {
      //console.log(filter);

      let filter = {
        projectRole: AdminRole,
        userId: selectedUser,
      };
      getAdminDashboard(filter).then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            let projectData = response.data;
            setDashboardDetails(response.data);
          } else if (
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ""
          ) {
            showToaster(response.message, "warning");
          } else {
            showToaster("No response from the server", "warning");
          }
        } catch (error) {
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      });
    } catch (error) {
      showToaster("Something went wrong. Please Try Again", "warning");
    }
  };
  // const getEnableWelcomeMsgs = () => {
  //   getAllProjects().then((response) => {
  //     try {

  //       console.log(response, "response");

  //     } catch (error) {

  //     }
  //   })
  // }
  const openDetailModal = (ProjectId, projectName, render_status) => {
    let fullDatas = {
      project_name: projectName,
      render_status: render_status,
    };
    let filter = {
      project_id: ProjectId,
    };
    console.log(filter);
    // setPageLoad(true);
    try {
      getUsageProjectDetails(filter).then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            let projectData = response.data;

            // let projectList1 = [...projectList, ...projectData];
            // let projectList = projectData;
            // setprojectList(projectData);
            if (projectData.length > 0) {
              console.log(projectData);
              history.push(`/admin/render-details`, {
                projectId: ProjectId,
                projectName: projectName,
                type: "dashboard",
                fullData: fullDatas,
              });
            } else {
              showToaster("No Render data is available", "warning");
            }

            setPageLoad(false);
          } else {
            showToaster("No response from the server", "warning");
            // setprojectList([]);
            setPageLoad(false);
          }
        } catch (error) {
          showToaster("Something went wrong1. Please Try Again", "warning");
          //   setprojectList([]);
          setPageLoad(false);
        }
      });
    } catch (error) {
      showToaster("Something went wrong2. Please Try Again", "warning");
      //   setprojectList([]);
      setPageLoad(false);
    }
    // setProjectID(projectId)
    // setOpenProjectPreview(true)
    // setfullData(data)
  };

  const downloadPath = (ProjectId, projectName) => {
    history.push(`/admin/project-list/download/`, {
      projectId: ProjectId,
      projectName: projectName,
    });
    setdownloadID(ProjectId);
  };
  const getAllProjects = async (
    flag,
    PageNo,
    KeyWord,
    category,
    ListStatus
  ) => {
    console.log(category);
    console.log(listStatus);
    let filter;
    setPageLoad(true);
    try {
      // settrashedProject(false);
      // setLoadMoreEnable(false);
      // useDispatch(updatePageNo(pageNo))
      if (category.value === ALLCATEGORY && category.id === 0) {
        if (ListStatus.value === LISTSTATUSALL && ListStatus.status === 0) {
          filter = {
            page_no: PageNo, //page_no is working as start in fetching data
            limit: limit,
            keyword: KeyWord.trim(), //keyword is used for search functionality
            inc_delete: 0,
          };
          console.log("1", filter);
        } else {
          filter = {
            page_no: PageNo, //page_no is working as start in fetching data
            limit: limit,
            keyword: KeyWord.trim(), //keyword is used for search functionality
            inc_delete: 0,
            // category_id: category.id,
            status: ListStatus.status,
          };
          console.log("2", filter);
        }
      } else {
        if (ListStatus.value === LISTSTATUSALL && ListStatus.status === 0) {
          filter = {
            page_no: PageNo, //page_no is working as start in fetching data
            limit: limit,
            keyword: KeyWord.trim(), //keyword is used for search functionality
            inc_delete: 0,
            category_id: category.id,
            // status: ListStatus.status,
          };
        } else {
          filter = {
            page_no: PageNo, //page_no is working as start in fetching data
            limit: limit,
            keyword: KeyWord.trim(), //keyword is used for search functionality
            inc_delete: 0,
            category_id: category.id,
            status: ListStatus.status,
          };
        }
        console.log("3", filter);
      }

      filter.projectRole = AdminRole;
      filter.userId = selectedUser;
      await getAdminProjects(filter).then((response) => {
        try {
          console.log(projectRoleProjectId, "projectId");
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            let projectData = response.data;
            setLoadMoreEnable(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
            if (projectData.length > 0) {
              if (projectData.length < limit) {
                setLoadMoreEnable(false);
              } else {
                setLoadMoreEnable(true);
              }
              if (PageNo === 0) {
                //console.log("page 00000000");
                let projectList1 = projectData;
                //console.log(projectData);
                let sortedList = projectList1.sort((a, b) =>
                  a.Deleted > b.Deleted ? 1 : b.Deleted > a.Deleted ? -1 : 0
                );
                setprojectList(sortedList);
                // console.log(projectList1, "projectList");
              } else {
                let projectList1 = [...projectList, ...projectData];
                // let projectList = projectData;
                let sortedList = projectList1.sort((a, b) =>
                  a.Deleted > b.Deleted ? 1 : b.Deleted > a.Deleted ? -1 : 0
                );
                setprojectList(sortedList);
              }
              setFetching(false);

              //setLoadMoreEnable(true);
            }
            setPageLoad(false);
          } else if (
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ""
          ) {
            showToaster(response.message, "warning");
            setprojectList([]);
            setFetching(false);
            setLoadMoreEnable(false);
            setPageLoad(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
          } else {
            showToaster("No response from the server", "warning");
            setprojectList([]);
            setFetching(false);
            setLoadMoreEnable(false);
            setPageLoad(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
          }
        } catch (error) {
          showToaster("Something went wrong. Please Try Again", "warning");
          setprojectList([]);
          setFetching(false);
          setLoadMoreEnable(false);
          setLoadMoreFetching(false);
          setPageLoad(false);
        }
      });
    } catch (error) {
      showToaster("Something went wrong. Please Try Again", "warning");
      setprojectList([]);
      setFetching(false);
      setLoadMoreEnable(false);
      setLoadMoreFetching(false);
      setPageLoad(false);
    }
  };
  const moveToNextPage = () => {
    setFetching(true);
    setLoadMoreFetching(true);
    // if (trashedProject) {
    //   ViewTrashedProject(1, pageNo + 1, keyWord, listCategory);
    // } else {
    //   getAllProjects(1, pageNo + 1, keyWord, listCategory, listStatus);
    // }
    setPageNo(pageNo + 1);
  };
  const deleteOrRestoreProject = async (project) => {
    let successMsg = "Deleted";
    if (project.Deleted === 1) {
      successMsg = "Restored";
    }
    setPageLoad(true);
    let filter = {
      project_id: project.ProjectID,
      operation: project.Deleted === 1 ? "RESTORE" : "DELETE",
    };
    await projectDeleteOrRestore(filter).then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          if (response.data.StatusCode && response.data.StatusCode === 200) {
            setPageLoad(false);
            if (
              response.data.Status !== undefined &&
              response.data.Status !== null &&
              response.data.Status === "Success"
            ) {
              
              showToaster(successMsg + " successfully", "success");
              deleteUsersProjectRole(project.ProjectID).then((response) => {
                try {
                  if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null &&
                    response.data !== "" &&
                    response.data !== undefined &&
                    response.data !== null
                  ) {
                    setPageLoad(false);
                    getInitialProjectList("");
                  }
                } catch (error) {
                  console.log(error);
                  setPageLoad(false);
                  ShowAlertBox(
                    "Oops...",
                    "Something went wrong. Please Try Again",
                    "error"
                  );
                }
              });

              getInitialProjectList("");
              setconfirmbtnlabel("");
              setconfirmmsg("");
              setopenUpdateModal(false);
              setdeleteProjectid("");
            } else {
              setconfirmbtnlabel("");
              setconfirmmsg("");
              setopenUpdateModal(false);
              setdeleteProjectid("");
              ShowAlertBox(
                "Oops...",
                "Something went wrong. Please Try Again",
                "error"
              );
            }
          } else {
            setconfirmbtnlabel("");
            setconfirmmsg("");
            setopenUpdateModal(false);
            setdeleteProjectid("");
            setPageLoad(false);
            ShowAlertBox(
              "Oops...",
              "Something went wrong. Please Try Again",
              "error"
            );
          }
        } else {
          setconfirmbtnlabel("");
          setconfirmmsg("");
          setopenUpdateModal(false);
          setdeleteProjectid("");
          setPageLoad(false);
          ShowAlertBox(
            "Oops...",
            "Something went wrong. Please Try Again",
            "error"
          );
        }
      } catch (error) {
        console.log(error);

        setconfirmbtnlabel("");
        setconfirmmsg("");
        setopenUpdateModal(false);
        setdeleteProjectid("");
        setPageLoad(false);
        ShowAlertBox(
          "Oops...",
          "Something went wrong. Please Try Again",
          "error"
        );
      }
    });
  };
  // useEffect(() => {
  //   getInitialProjectList();
  // }, [includeDelete]);
  const changeDeleteOption = (event) => {
    setIncludeDelete(event.target.checked === true ? 1 : 0);
  };

  const __serachbox = (val) => {
    try {
      //console.log(val.target.value);
      setSearchBoxErrorMsg(false);
      //setKeyWord(val.target.value);

      if (keyWord !== val.target.value.trim()) {
        setKeyWord(val.target.value);
        // getInitialProjectList(val.target.value.trim());;
        getSearchBoxResult(val.target.value);
      } else {
        if (keyWord.trim() !== "") {
          setKeyWord(val.target.value);
        } else {
          setKeyWord("");
        }
      }
    } catch (error) {
      showToaster(
        "Something went wrong. Please Refresh page and Try Again",
        "warning"
      );
    }
  };
  const getSearchBoxResult = useCallback(
    debounce((value) => {
      try {
        console.log(trashedProject, "trashedProject");
        //setKeyWordNew(keyWord.trim());
        // KeyWordNew = keyWord.trim()
        // getInitialProjectList(value.trim());
      } catch (error) {
        showToaster(
          "Something went wrong. Please Refresh page and Try Again",
          "warning"
        );
      }
    }, 1000),
    [keyWord]
  );

  const __searchByClick = () => {
    setSearchBoxErrorMsg(false);
    if (keyWord.trim() !== "") {
      console.log(trashedProject, "trashedProject");
      // getInitialProjectList(keyWord.trim());
    } else {
      setSearchBoxErrorMsg(true, () => {
        setTimeout(() => {
          setSearchBoxErrorMsg(false);
        }, 10000);
      });
    }
  };

  const getInitialProjectList = (keyword) => {
    setprojectList([]);
    setFetching(true);
    setPageNo(0);
    setLimit(7);
    setSearchBoxErrorMsg(false);
    console.log(listCategory);

    // if (trashedProject) {
    //   ViewTrashedProject(0, 0, keyword, listCategory);
    // } else {
    //   getAllProjects(0, 0, keyword, listCategory, listStatus);
    // }

    // this.props.updateJson({})
  };

  const ViewTrashedProject = (flag, PageNo, KeyWord, category) => {
    try {
      setPageLoad(true);

      let filter;
      // useDispatch(updatePageNo(pageNo))
      if (category.value === ALLCATEGORY && category.id === 0) {
        filter = {
          page_no: PageNo, //page_no is working as start in fetching data
          limit: limit,
          keyword: KeyWord.trim(), //keyword is used for search functionality
          inc_delete: 1,
        };
        console.log("1", filter);
      } else {
        filter = {
          page_no: PageNo, //page_no is working as start in fetching data
          limit: limit,
          keyword: KeyWord.trim(), //keyword is used for search functionality
          inc_delete: 1,
          category_id: category.id,
          // status: ListStatus.status,
        };
        console.log("3", filter);
      }
      filter.projectRole = AdminRole;
      //console.log(filter);
      filter.userId = selectedUser;
      getAdminProjects(filter).then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            setPageLoad(false);
            setLoadMoreEnable(false);
            let projectData = response.data;
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
            if (projectData.length > 0) {
              if (projectData.length < limit) {
                setLoadMoreEnable(false);
              } else {
                setLoadMoreEnable(true);
              }
              if (PageNo === 0) {
                //console.log("page 00000000");
                let projectList1 = projectData;
                //console.log(projectData);
                setdeletedProjects(projectData);
                // console.log(projectList1, "projectList");
              } else {
                let projectList1 = [...deletedProjects, ...projectData];
                // let projectList = projectData;
                //console.log(projectList1);
                setdeletedProjects(projectList1);
              }
              setFetching(false);
              //setLoadMoreEnable(true);
            } else {
              if (pageNo === 0) {
                setdeletedProjects([]);
                setFetching(false);
                setLoadMoreEnable(false);
              }
            }
          } else if (
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ""
          ) {
            showToaster(response.message, "warning");
            setdeletedProjects([]);
            setFetching(false);
            setLoadMoreEnable(false);
            setPageLoad(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
          } else {
            showToaster("No response from the server", "warning");
            setdeletedProjects([]);
            setFetching(false);
            setLoadMoreEnable(false);
            setPageLoad(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
          }
        } catch (error) {
          showToaster("Something went wrong. Please Try Again", "warning");
          setdeletedProjects([]);
          setFetching(false);
          setLoadMoreEnable(false);
          setLoadMoreFetching(false);
          setPageLoad(false);
        }
      });
    } catch (error) {
      showToaster("Something went wrong. Please Try Again", "warning");
      setdeletedProjects([]);
      setFetching(false);
      setLoadMoreEnable(false);
      setLoadMoreFetching(false);
      setPageLoad(false);
    }
  };

  useEffect(() => {
    dispatch(editStatus(true));
  }, [EditProject]);

  const showMessage = (status) => {
    if (status) {
      showToaster('"now! you have an internet connection.', "success");
    } else {
      showToaster("now! you have no internet connection", "warning");
    }
  };

  const notDownloadVideos = () => {
    showToaster("Render is not completed!!", "warning");
  };
  const permanentDelete = (project) => {
    let filter = {
      project_id: project.ProjectID,
      operation: "REMOVE",
    };
    projectDeleteOrRestore(filter).then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          if (response.data.StatusCode && response.data.StatusCode === 200) {
            setPageLoad(false);
            if (
              response.data.Status !== undefined &&
              response.data.Status !== null &&
              response.data.Status === "Success"
            ) {
              showToaster("Deleted successfully", "success");
              // getInitialProjectList("");
              // ViewTrashedProject(0, 0, keyWord, listCategory);
              setProjectsReload((r) => !r);

              setconfirmbtnlabel("");
              setconfirmmsg("");
              setopenDeleteModal(false);
              setdeleteProjectid("");
            } else {
              setconfirmbtnlabel("");
              setconfirmmsg("");
              setopenDeleteModal(false);
              setdeleteProjectid("");
              ShowAlertBox(
                "Oops...",
                "Something went wrong. Please Try Again",
                "error"
              );
            }
          } else {
            setconfirmbtnlabel("");
            setconfirmmsg("");
            setopenDeleteModal(false);
            setdeleteProjectid("");
            setPageLoad(false);
            ShowAlertBox(
              "Oops...",
              "Something went wrong. Please Try Again",
              "error"
            );
          }
        } else {
          setconfirmbtnlabel("");
          setconfirmmsg("");
          setopenDeleteModal(false);
          setdeleteProjectid("");
          setPageLoad(false);
          ShowAlertBox(
            "Oops...",
            "Something went wrong. Please Try Again",
            "error"
          );
        }
      } catch (error) {
        setconfirmbtnlabel("");
        setconfirmmsg("");
        setopenDeleteModal(false);
        setdeleteProjectid("");
        setPageLoad(false);
        ShowAlertBox(
          "Oops...",
          "Something went wrong. Please Try Again",
          "error"
        );
      }
    });
  };

  const checkTemplateAvailable = async (modules, ProjectID) => {
    // this.projectID = this.props.activeProject.ProjectID
    var templateStatus = true;
    if (
      modules !== undefined &&
      modules !== null &&
      modules[ProjectID] !== undefined
    ) {
      var activeProjectData = modules[ProjectID]["Modules"];
      await Object.entries(activeProjectData).map((modules, moduleIndex) => {
        if (templateStatus === true) {
          let moduleData = modules[1];
          if (
            moduleData.slides !== undefined &&
            moduleData.slides !== null &&
            moduleData.slides !== ""
          ) {
            Object.entries(moduleData.slides).map((slideData, slideIndex) => {
              if (templateStatus === true) {
                if (typeof slideData[1] === "object") {
                  let screens = slideData[1].Screens;
                  if (
                    screens !== undefined &&
                    screens !== null &&
                    screens !== "" &&
                    typeof screens === "object"
                  ) {
                    if (templateStatus === true) {
                      Object.entries(screens).map((screen, index) => {
                        let itemsKey = screen[0];
                        Object.entries(screens[itemsKey]).map((item, index) => {
                          if (
                            item[1] !== undefined &&
                            item[1].Contenttype !== undefined &&
                            item[1].Contenttype === "TemplateID"
                          ) {
                            if (
                              item[1] !== undefined &&
                              item[1].Contentparams !== undefined
                            ) {
                              let Contentparams = item[1].Contentparams;
                              if (
                                Contentparams.contentdescription !==
                                  undefined &&
                                Contentparams.contentdescription !== null &&
                                Contentparams.contentdescription !== "" &&
                                Contentparams.contentdescription !== "Null"
                              ) {
                              } else {
                                templateStatus = false;
                              }
                            }
                          }
                          return true;
                        });
                        return true;
                      });
                    }
                  }
                }
              }
              return true;
            });
          }
        }
        return true;
      });
    }
    return templateStatus;
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "gray",
      padding: 7,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      height: "26px",
    }),
    singleValue: () => ({
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "10px",
      lineHeight: "15px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
    }),
    indicatorSeparator: () => ({
      border: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
  };

  const handleChangeStatus = (e) => {
    // alert()
    setListStatus(e);
    console.log(e, "listtt");
  };
  // useEffect(() => {
  //   getAllProjects(0, 0, keyWord, listCategory, listStatus);
  // }, [listStatus]);

  const generateStatus = () => {
    if (listFilterStatus != undefined) {
      let statusOption = [];
      listFilterStatus.map((filter) => {
        console.log(filter);
        statusOption.push({
          value: filter.value,
          label: filter.value,
          status: filter.status,
        });
        return true;
      });
      setStatusOption(statusOption);
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };

  useEffect(() => {
    generateStatus();
    setListStatus({
      value: "All",
      label: "All",
      status: 0,
    });
  }, [listFilterStatus]);

  const handleChange = (e) => {
    // alert()
    setListCategory(e);

    console.log(e, "listtt");
  };
  // useEffect(() => {
  //   if (!trashedProject) {
  //     getAllProjects(0, 0, keyWord, listCategory, listStatus);
  //   } else {
  //     ViewTrashedProject(0, 0, keyWord, listCategory);
  //   }
  // }, [listCategory]);

  useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (!trashedProject) {
        getAllProjects(0, pageNo, keyWord, listCategory, listStatus);
      } else {
        ViewTrashedProject(0, pageNo, keyWord, listCategory);
      }
    }, 500);
  }, [
    listCategory,
    trashedProject,
    keyWord,
    listCategory,
    listStatus,
    pageNo,
    projectsReload,
    selectedUser,
  ]);

  useEffect(() => {
    generateFonts();
  }, [category]);
  const generateFonts = () => {
    if (category != undefined) {
      let fontOption = [];
      category.map((font) => {
        fontOption.push({
          value: font.category,
          label: font.category,
          id: font.id,
        });
        return true;
      });
      console.log(fontOption);
      setfonts(fontOption);
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };
  const RenderData = () => {
    history.push(`/admin/usage-tracking`);
  };
  const refreshProjectStats = async (projectDetails, type) => {
    setPageLoad(true);
    let renderFilter = {
      project_id: projectDetails.ProjectID,
      type: type,
      user_id: projectDetails.userId,
    };
    await getAdminProjectStatus(renderFilter).then((response) => {
      if (
        response.data !== undefined &&
        response.data !== null &&
        response.data !== "" &&
        response.status !== undefined &&
        response.status !== null &&
        response.status === 200
      ) {
        console.log(response);
        // setRefreshStatus(response.data.renderstatus);
        // getAllProjects(1, 0, "", listCategory, listStatus);
        setProjectsReload((r) => !r);
        let renderStatusChange = projectList;
        if (response.apiStatus == "false") {
          setRefreshEnable(true);
          var data = projectList;
          let index = data.findIndex(
            (obj) => obj.ProjectID == response.data.project_id
          );

          data[index].refreshStatus = true;
          setprojectList(data);
          if (type == "deatils") {
            setrenderProject(response.data.project_id);
            setRefreshStatus(true);
            setconfirmbtnlabel("OK");
            setconfirmmsg(response.message);
          }

          renderStatusChange.map((project, index) => {
            if (project.ProjectID === projectDetails.ProjectID) {
              project.RenderStatus = "0%";
            }
          });
          setPageLoad(false);
          return renderStatusChange;
        }
        if (response.apiStatus !== "false") {
          setRefreshEnable(false);
          setRefreshEnable(false);
          // setRefreshEnable(true);
          var data = projectList;
          let index = data.findIndex(
            (obj) => obj.ProjectID == response.data.project_id
          );

          data[index].refreshStatus = false;
          renderStatusChange.map((project, index) => {
            if (project.ProjectID === projectDetails.ProjectID) {
              project.RenderStatus = response.data.renderstatus;
            }
          });
          setPageLoad(false);
          // setprojectList(renderStatusChange)
          return renderStatusChange;
        }
        setprojectList(renderStatusChange);
      }
    });
  };

  const ListProject = () => {
    let data = [];
    if (trashedProject) {
      data = deletedProjects;
    } else {
      data = projectList;
    }
    return data.map((project, index) => {
      let RenderStatus = project.RenderStatus;
      let projectName = project.ProjectName;
      let Created = project.CreatedOn;
      let sortedCreatedDate = Created.split(" ")[0];
      let Modified = project.UpdatedOn;
      let sorteModifieddDate = Modified.split(" ")[0];
      let projectProgress = project.RenderStatus || "0%";
      // let sortedCars1 = project.sort((a, b) =>
      //   a.sortedCreatedDate.split('-').reverse().join().localeCompare(b.sortedCreatedDate.split('-').reverse().join()));
      let ddd = JSON.parse(project.ProjectData);
      let Modules = ddd[project.ProjectID].Modules;
      console.log(project, "project");
      if (
        projectName !== undefined &&
        projectName !== null &&
        projectName.trim() !== ""
      ) {
      } else {
        projectName = "No project name provided";
      }
      // projectRoleProjectId!==undefined&&projectRoleProjectId!==null&&

      return (
        <div className="pb-tbl-wrapper pb-data-tbl ">
          {project.Deleted === 0 ? (
            <div
              onClick={() =>
                openDetailModal(
                  project.ProjectID,
                  project.ProjectName,
                  project.RenderStatus
                )
              }
              className={
                project.renderError == 0
                  ? "pb-data-label ps-4 wrong-status cursor-pointer"
                  : "pb-data-label ps-4 cursor-pointer"
              }
              title={projectName}

              //
            >
              {projectName}
            </div>
          ) : (
            <div className="pb-data-label ps-4 " title={projectName}>
              {projectName}
            </div>
          )}
          {/* <div className="pb-data-label ps-4">{projectName}</div> */}
          <div
            className={
              project.renderError == 0
                ? "pb-data-label text-center wrong-status"
                : "pb-data-label text-center"
            }
          >
            {project.category_name}
          </div>
          <div
            className={
              project.renderError == 0
                ? "pb-data-label text-center wrong-status"
                : "pb-data-label text-center"
            }
          >
            {sortedCreatedDate}{" "}
          </div>
          <div
            className={
              project.renderError == 0
                ? "pb-data-label ps-3 pe-0 text-center wrong-status"
                : "pb-data-label ps-3 pe-0 text-center"
            }
          >
            {sorteModifieddDate}
          </div>
          <div className="pb-data-label pe-0 ps-0">
            <div className="d-flex align-items-center justify-content-between">
              {trashedProject ? (
                <div className="d-flex align-items-center pb-status-indicator">
                  <div className="progress custom-progress pb-progress-bar failed">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "100%" }}
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div className="pb-st-count">
                    <div className="d-flex align-items-center">
                      <div className="pb-failed-icon">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.1875 3.9375L2.8125 3.93751"
                            stroke="#FF4343"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.3125 7.3125V11.8125"
                            stroke="#FF4343"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.6875 7.3125V11.8125"
                            stroke="#FF4343"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.0625 3.9375V14.625C14.0625 14.7742 14.0032 14.9173 13.8977 15.0227C13.7923 15.1282 13.6492 15.1875 13.5 15.1875H4.5C4.35082 15.1875 4.20774 15.1282 4.10225 15.0227C3.99676 14.9173 3.9375 14.7742 3.9375 14.625V3.9375"
                            stroke="#FF4343"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.8125 3.9375V2.8125C11.8125 2.51413 11.694 2.22798 11.483 2.017C11.272 1.80603 10.9859 1.6875 10.6875 1.6875H7.3125C7.01413 1.6875 6.72798 1.80603 6.51701 2.017C6.30603 2.22798 6.1875 2.51413 6.1875 2.8125V3.9375"
                            stroke="#FF4343"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="failed-status">Trashed</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center pb-status-indicator">
                  {/* <div className="progress custom-progress pb-progress-bar"> */}
                  <ProgressBar
                    completed={projectProgress.substring(
                      0,
                      projectProgress.length - 1
                    )}
                  />
                  {/* <div className="progress-bar" role="progressbar" style={{ width: "57%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div> */}
                  {/* </div> */}
                  <div className="progress custom-progress pb-progress-bar">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${projectProgress}` }}
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div
                    className={
                      project.renderError == 0
                        ? "pb-st-count me-2 wrong-status"
                        : "pb-st-count me-2"
                    }
                  >
                    {projectProgress}
                  </div>
                  {/* {refreshEnable&&
                    <div className="pb-st-count">{projectProgress}</div>
                    } */}

                  {/* <button onClick={() => refreshProjectStats(project)}>
                    refresh
                  </button> */}
                </div>
              )}
              <div
                className="d-flex justify-content-end pb-status-set"
                // title="Restore"
              >
                {Modules !== undefined &&
                  Modules !== "" &&
                  Modules !== null &&
                  !trashedProject &&
                  projectProgress !== "100%" && (
                    <Tooltip title="Refresh" arrow>
                      <div
                        className="pb-stat-icon"
                        onClick={() => refreshProjectStats(project, "refresh")}
                      >
                        <svg
                          className="me-2"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="18"
                          height="21"
                          viewBox="0 0 592.99 592.99"
                          style={{
                            enableBackground: "new 0 0 592.99 592.99;",
                          }}
                          space="preserve"
                        >
                          <g>
                            <g>
                              <path
                                d="M274.292,21.879C122.868,21.879,0,145.072,0,296.496C0,447.92,122.262,571.111,275.262,571.111v-91.799
                      c-100.98,0-183.462-82.012-183.462-182.816c0-100.806,81.362-182.817,182.168-182.817c98.753,0,179.413,78.718,182.661,176.696
                      h-45.236l90.799,127.541l90.799-127.541h-44.486C545.248,141.767,423.67,21.879,274.292,21.879z"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </Tooltip>
                  )}
                {/* {trashedProject === true ? (
                  <Tooltip title="Restore" arrow>
                    <div
                      className="pb-stat-icon"
                      onClick={() => {
                        let message =
                          "You want to delete " + project.ProjectName + " ?";
                        let confirmBtn = "Delete";
                        if (project.Deleted === 1) {
                          message =
                            "You want to restore " + project.ProjectName + " ?";
                          confirmBtn = "Restore";
                        }
                        setconfirmmsg(message);
                        setconfirmbtnlabel(confirmBtn);
                        setopenUpdateModal(true);
                        setdeleteProjectid(project);
                        //deleteOrRestoreProject(project)
                      }}

                      // onClick={() => deleteOrRestoreProject(project)}
                    >
                      <svg
                        className="me-2"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width=""
                        height=""
                        viewBox="0 0 592.99 592.99"
                        style={{
                          enableBackground: "new 0 0 592.99 592.99;",
                        }}
                        space="preserve"
                      >
                        <g>
                          <g>
                            <path
                              d="M274.292,21.879C122.868,21.879,0,145.072,0,296.496C0,447.92,122.262,571.111,275.262,571.111v-91.799
                      c-100.98,0-183.462-82.012-183.462-182.816c0-100.806,81.362-182.817,182.168-182.817c98.753,0,179.413,78.718,182.661,176.696
                      h-45.236l90.799,127.541l90.799-127.541h-44.486C545.248,141.767,423.67,21.879,274.292,21.879z"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </Tooltip>
                ) : null} */}
                {trashedProject === true ? null : (
                  // <Tooltip title="Delete Permanently" arrow>
                  //   <div
                  //     className="pb-stat-icon download"
                  //     // title="Permanent delete"
                  //     onClick={() => {
                  //       let message =
                  //         "You want to permanently delete " +
                  //         project.ProjectName +
                  //         " ?";
                  //       let confirmBtn = "Delete";

                  //       setconfirmmsg(message);
                  //       setconfirmbtnlabel(confirmBtn);
                  //       setopenDeleteModal(true);
                  //       setdeleteProjectid(project);
                  //       setKeyWord("");
                  //       //deleteOrRestoreProject(project)
                  //     }}
                  //   >
                  //     <svg
                  //       className="me-1"
                  //       version="1.1"
                  //       id="Layer_1"
                  //       xmlns="http://www.w3.org/2000/svg"
                  //       xlink="http://www.w3.org/1999/xlink"
                  //       x="0px"
                  //       y="0px"
                  //       viewBox="0 0 512 512"
                  //       space="preserve"
                  //     >
                  //       <g>
                  //         <polygon points="190.551,495.523 117.226,495.523 87.886,113.866 190.551,113.866 	" />
                  //         <polygon points="394.773,495.523 321.448,495.523 321.448,113.866 424.112,113.866 	" />
                  //       </g>
                  //       <g>
                  //         <path
                  //           d="M468.321,97.389h-44.208H321.446H190.551H87.888h-44.21c-9.1,0-16.477,7.378-16.477,16.477
                  //         s7.377,16.477,16.477,16.477h28.95l28.168,366.444c0.661,8.585,7.818,15.213,16.429,15.213h73.325h51.333
                  //         c9.1,0,16.477-7.378,16.477-16.477s-7.377-16.477-16.477-16.477H207.03V130.343h97.941v365.18c0,9.099,7.378,16.477,16.477,16.477
                  //         h73.327c8.611,0,15.769-6.629,16.429-15.213l28.169-366.444h28.949c9.099,0,16.477-7.378,16.477-16.477
                  //         S477.419,97.389,468.321,97.389z M174.074,479.046h-41.589L105.68,130.343h68.394V479.046L174.074,479.046z M379.513,479.046
                  //         h-41.59V130.343h68.397L379.513,479.046z"
                  //         />
                  //         <path
                  //           d="M332.693,75.578c-9.099,0-16.477-7.379-16.477-16.477V32.954H201.899V59.1
                  //         c0,9.099-7.377,16.477-16.477,16.477s-16.477-7.379-16.477-16.477V16.477C168.944,7.378,176.321,0,185.421,0h147.272
                  //         c9.099,0,16.477,7.378,16.477,16.477V59.1C349.17,68.201,341.794,75.578,332.693,75.578z"
                  //         />
                  //       </g>
                  //     </svg>
                  //   </div>
                  // </Tooltip>
                  <Tooltip title="Download" arrow>
                    {priviewrenderUrl.length > 0 || projectProgress !== "0%" ? (
                      <div
                        className="pb-stat-icon download"
                        onClick={() => {
                          downloadPath(project.ProjectID, project.ProjectName);
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 4V16"
                            stroke="black"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 11L12 16L18 11"
                            stroke="black"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5 19H19"
                            stroke="black"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div
                        className="pb-stat-icon download"
                        onClick={() => {
                          notDownloadVideos();
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 4V16"
                            stroke="black"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 11L12 16L18 11"
                            stroke="black"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5 19H19"
                            stroke="black"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    )}
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <PageLoader showLoader={pageLoad} />
      <PageLoader showLoader={priviewPageLoad} />
      <div className={DisplayMode ? "theme dark" : "theme"}>
        <div className="main-wrapper">
          <Header />
          <div className="pb-wrapper">
            <div className="row pb-header mb-4">
              {SearchBoxErrorMsg === true && (
                <div className="input-error-msgs">
                  Please enter a project name
                </div>
              )}
              {AdminRole == 0 ? (
                <div className="col-md-5 col-lg-4 col-xl-5">
                  <div className="d-flex align-items-center justify-content-between pb-srch-customiser w-100">
                    <div className="srch-holder">
                      <Tooltip title="Search" arrow>
                        <div
                          // title="Search"
                          onClick={() => __searchByClick()}
                          className="srch-icon"
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M21.9243 21.925L27.9994 28.0001"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </Tooltip>
                      <div className="srch-inpt-holder">
                        <form>
                          <input
                            type="text"
                            onChange={(e) => __serachbox(e)}
                            name="focus"
                            required
                            className="form-control srch-input"
                            placeholder="Search Projects"
                            value={keyWord}
                          />

                          <button
                            className="search-close"
                            // title="Reset"
                            type="reset"
                            onClick={() => {
                              // if (trashedProject) {
                              //   ViewTrashedProject(0, 0, "", listCategory);
                              // } else {
                              //   getAllProjects(
                              //     0,
                              //     0,
                              //     "",
                              //     listCategory,
                              //     listStatus
                              //   );
                              // }
                              setPageNo(0);
                              setKeyWord("");
                            }}
                          >
                            {" "}
                            <Tooltip title="Reset" arrow>
                              <span>
                                <svg
                                  version="1.1"
                                  id="Layer_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  space="preserve"
                                >
                                  <g>
                                    <path
                                      d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M327.115,365.904
                           L256,294.789l-71.115,71.115l-38.789-38.789L217.211,256l-71.115-71.115l38.789-38.789L256,217.211l71.115-71.115l38.789,38.789
                           L294.789,256l71.115,71.115L327.115,365.904z"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </Tooltip>
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="srch-ft-selector drop-custom">
                      {/* <Select
                        className="custom-input"
                        styles={customStyles}
                        value={listCategory}
                        onChange={(e) => handleChange(e)}
                        options={fonts}
                      /> */}
                      <AsyncSelect
                        className="custom-input"
                        styles={customStyles}
                        defaultValue={defaultSelectListValue}
                        onChange={onSelectUser}
                        loadOptions={loadSuggestions}
                        cacheOptions
                        defaultOptions
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-5 col-lg-4 col-xl-5"></div>
              )}

              <div className="col-md-7 col-lg-8 col-xl-7">
                <div
                  className={
                    trashedProject
                      ? "d-flex flex-wrap justify-content-end"
                      : "d-flex flex-wrap"
                  }
                >
                  {!trashedProject && AdminRole == 0 ? (
                    <div className="d-flex align-items-center pb-filter-holder flex-fill me-2">
                      <div className="pb-filter-label pe-3">
                        Filter by Status
                      </div>
                      <div className="srch-ft-selector w-100 p-0 drop-custom">
                        <Select
                          className="custom-input"
                          styles={customStyles}
                          value={listStatus}
                          onChange={(e) => handleChangeStatus(e)}
                          options={statusOption}
                        />
                      </div>
                      {/* <div className="custom-select">
                        <select className="form-select form-field">
                          <option> All</option>
                        </select>
                      </div> */}
                    </div>
                  ) : (
                    <div className="d-flex align-items-center pb-filter-holder flex-fill me-2"></div>
                  )}

                  {trashedProject ? (
                    <button
                      type="button"
                      className="btn btn-outline-primary me-2"
                      onClick={() => {
                        setIncludeDelete(0);
                        setPageNo(0);
                        setKeyWord("");
                        // getAllProjects(0, 0, "", listCategory, listStatus);
                        settrashedProject(false);
                        getDashboardDetails();
                      }}
                    >
                      BACK
                    </button>
                  ) : (
                    AdminRole == 0 && (
                      <button
                        type="button"
                        className="btn btn-outline-primary text-nowrap me-2

                        "
                        onClick={() => {
                          setIncludeDelete(1);
                          setPageNo(0);
                          setKeyWord("");
                          settrashedProject(true);
                          // ViewTrashedProject(1, 0, "", listCategory);
                        }}
                      >
                        TRASHED PROJECTS
                      </button>
                    )
                  )}

                  <button
                    type="button"
                    className="btn btn-outline-primary me-2 "
                    onClick={() => {
                      RenderData();
                    }}
                  >
                    USAGE TRACKING
                  </button>
                </div>
              </div>
            </div>
            {!trashedProject && (
              <div className="row mb-4">
                <div className="size-wrap col-md-6 col-sm-6 col-lg-4">
                  <div className="d-flex align-items-center pb-card">
                    <div className="pb-count text-center">
                      {dashboardDetails.Total}
                    </div>
                    <div className="pb-desc">
                      Total <br /> projects
                    </div>
                    <div className="tick">
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="13.5" cy="13.5" r="13.5" fill="#468BE8" />
                        <path
                          d="M20.0312 9.34424L11.7188 17.6564L7.5625 13.5005"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="progress custom-progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="size-wrap col-md-6 col-sm-6 col-lg-4">
                  <div className="d-flex align-items-center pb-card">
                    <div className="pb-count text-center">
                      {dashboardDetails.InProgress}
                    </div>
                    <div className="pb-desc">
                      projects in <br /> progress
                    </div>
                    <div className="progress custom-progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "50%" }}
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="size-wrap col-md-6 col-sm-6 col-lg-4">
                  <div className="d-flex align-items-center pb-card">
                    <div className="pb-count text-center">
                      {dashboardDetails.Completed}
                    </div>
                    <div className="pb-desc">
                      completed <br /> projects
                    </div>
                    <div className="progress custom-progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="table-wraper">
              {/* <label className="d-flex align-items-center custom-checkbox mb-3">
                <input
                  type="checkbox"
                  checked={includeDelete === 1 ? true : false}
                  onChange={(e) => changeDeleteOption(e)}
                />
                <span className="checkmark"></span>
                <div className="check-label"> Include Deleted Projects </div>
              </label> */}
              <div
                className={
                  trashedProject ? "pb-tbl-blk trashed-table" : "pb-tbl-blk"
                }
              >
                <div className="pb-tbl-wrapper mb-3 sticky-head mt-0">
                  <div className="pb-head-label ps-4">Project name</div>
                  <div className="pb-head-label text-center">Category</div>
                  <div className="d-flex pb-head-label justify-content-center">
                    Created date
                    <div className="arrow">
                      <div className="arrow-up"></div>
                      <div className="arrow-down"></div>
                    </div>
                  </div>
                  <div className="d-flex pb-head-label justify-content-center ps-3 pe-0">
                    Last modified
                    <div className="arrow">
                      <div className="arrow-up"></div>
                      <div className="arrow-down"></div>
                    </div>
                  </div>
                  <div className="pb-head-label text-center">Status</div>
                </div>
                {ListProject()}
                {
                  fetching === true && ""
                  // <div style={{ display: "flex", justifyContent: "center" }}>
                  //   <CircularProgress style={{ color: "white" }} />{" "}
                  // </div>
                }
                {/* <PageLoader showLoader={fetching} /> */}

                {trashedProject
                  ? trashedProject && deletedProjects.length > 0
                    ? ""
                    : fetching === false && (
                        <React.Fragment>
                          <div className="no-project-selected blink">
                            No project available
                          </div>
                        </React.Fragment>
                      )
                  : !trashedProject && projectList.length > 0
                  ? ""
                  : fetching === false && (
                      <React.Fragment>
                        <div className="no-project-selected blink">
                          No project available
                        </div>
                      </React.Fragment>
                    )}
              </div>
              {loadMoreEnable && (
                <div className="load-more-btn-holder text-end mt-4">
                  <button
                    type="button"
                    style={{ display: "inline-block" }}
                    className="btn btn-primary cm-btn"
                    onClick={() => moveToNextPage()}
                  >
                    {fetching ? "Loading..." : "Load More"}{" "}
                    {fetching && (
                      <CircularProgress size="1rem" color="inherit" />
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {getEnableWelcomeMsg && (
        <WelcomeModal
          show={showWelcomeModal}
          closemodal={setshowWelcomeModal}
        />
      )}
      <NavigatorOnline onChange={(status) => showMessage(status)} />
      <RenderVideo
        closePreview={() => setOpenRenderVideo(false)}
        openRenderVideo={openRenderVideo}
        priviewrenderUrl={priviewrenderUrl}
      />

      <CustomModal
        isOpen={openUpdateModal}
        Closemodal={setopenUpdateModal}
        Content={confirmmsg}
        Header="Are you sure"
        Buttonlabel={confirmbtnlabel}
        Buttonclick={deleteOrRestoreProject}
        del_id={deleteProjectid}
      />
      <CustomModal
        isOpen={openDeleteModal}
        Closemodal={setopenDeleteModal}
        Content={confirmmsg}
        Header="Are you sure"
        Buttonlabel={confirmbtnlabel}
        Buttonclick={permanentDelete}
        del_id={deleteProjectid}
      />

      <SaveChangeModal
        isOpen={refreshStatus}
        Closemodal={setRefreshStatus}
        Content={confirmmsg}
        Header="Render Error Details"
        Buttonlabel1="Full Details"
        Buttonclick1={setRefreshStatus}
        Buttonlabel2="No!"
        Buttonclick2={setOpenRenderErrorModal}
      />

      <ErrorModal
        show={refreshStatus}
        // closemodal={setOpenRenderErrorModal}
        closemodal={setRefreshStatus}
        renderProjectId={renderProjectId}
      />
    </React.Fragment>
  );
}
