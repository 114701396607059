import React, { useEffect, useState } from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { deleteProject, newGetCategory, projectLists } from '../../../services/apiServices';

import Sidebar from '../../../components/sidebar';
import FormInputGroup from '../../login/components/FormInputGroup';
import CreateNewProjectModal from './createNewProjectModal';
import DataNotFound from '../../../components/dataNotFound';
import ProgressWithPercentage from '../../../components/progressPercentage';
import { getUserItem, isAdmin, showToaster } from '../../../services/helper';
import CardThumbnail from '../../../components/cardThumbnail';
import PaginationComponent from '../../../components/pagination';
import PagePagination from '../../../components/pagination/pagePagination';

// Icons
import {
  searchIcon,
  arrowDownIcon,
  noProjectIcon,
  plusIcon,
  clockIcon,
} from '../../../constants/icon';
import courseVideoIcon from '../../../assets/img/courseVideoIcon.png';
import HeaderNavigation from '../../../components/headerNavigation';
import PageLoader from '../../../services/pageLoader';

function CreateProjects() {
  const [modalShow, setModalShow] = useState(true);

  // Pagination Start
  // const itemsPerPage = 10;
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [projectList, setProjectList] = useState([]);
  const [keyWord, setKeyWord] = useState('');
  const [selectCategory, setSelectCategory] = useState([]);
  const [category, setCategory] = useState();
  const [allSlidesData, setAllSlidesData] = useState([]);
  const currentUserRoles = getUserItem('userRole');
  const isCurrentUserAdmin = isAdmin();

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleLastPage = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(totalPages);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async (search = '', limit = null, pageNo = null) => {
    setLoading(true);
    const body = {
      keyword: search.length ? search.trim : keyWord.trim(),
      deleted: 0,
      limit: limit ?? itemsPerPage,
      page_no: pageNo ?? currentPage,
    };
    if (category?.categoryId) {
      body.category_id = category.categoryId;
    }
    const projectListsResponse = await projectLists(body);
    if (projectListsResponse.status === 200) {
      setProjectList(projectListsResponse.data);
      setTotalItems(projectListsResponse.total_count);
    } else {
      showToaster(projectListsResponse.message, 'warning');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (totalItems !== 0 && totalItems !== null) {
      setModalShow(false);
    }
  }, [totalItems]);

  // Pagination End

  // Dropdown CustomToggle
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      role="button"
      className="common-dropdown-filter"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
    >
      {children}
    </div>
  ));

  const handleNewProjectModal = () => {
    setModalShow(true);
  };

  useEffect(() => {
    getProjects();
  }, [keyWord, category, currentPage, itemsPerPage]);

  const handleSearch = val => {
    try {
      if (keyWord !== val.target.value.trim()) {
        setKeyWord(val.target.value);
      } else {
        if (keyWord.trim() !== '') {
          setKeyWord(val.target.value);
        } else {
          setKeyWord('');
        }
      }
    } catch (error) {
      showToaster('Something went wrong. Please Refresh page and Try Again', 'warning');
    }
  };

  const GetCategoryList = () => {
    newGetCategory().then(response => {
      try {
        if (response.data.list) {
          let categoryList = response.data.list;
          setSelectCategory(categoryList);
        }
      } catch (error) {
        console.log(error);
        showToaster('Something went wrong. Please Try Again', 'warning');
      }
    });
  };

  useEffect(() => {
    GetCategoryList();
  }, []);

  useEffect(() => {
    let allSlideData = [];
    projectList.map(item => {
      let slideData = [];
      let duration = 0;
      let projectData = JSON.parse(item?.ProjectData);
      for (const moduleId in projectData?.[item?.ProjectID]['Modules']) {
        if (moduleId !== 'status') {
          const slides = projectData?.[item?.ProjectID]['Modules'][moduleId]['slides'];
          for (const slideId in slides) {
            const slideUrl = item?.allRenderUrls?.[moduleId]?.[slideId];
            if (slideUrl && slideUrl?.url) {
              slideData.push({
                url: slideUrl.url,
              });
              duration += slideUrl.duration;
            }
          }
        }
        allSlideData[item.ProjectID] = { slideData, duration };
      }
      setAllSlidesData(allSlideData);
    });
  }, [projectList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage]);

  const deleteProjects = id => {
    deleteProject(id)
      .then(response => {
        if (response.status === 200) {
          showToaster('Project Deleted', 'success');
          let pageNo = currentPage;
          if (!(projectList.length - 1) && currentPage > 1) {
            setCurrentPage(pageNo - 1);
          } else {
            getProjects();
          }
        } else {
          showToaster('Unable to delete', 'error');
        }
      })
      .catch(e => {
        console.log(e);
        showToaster(`Unable to delete : ${e}`, 'error');
      });
  };

  const resetData = () => {
    setKeyWord('');
    setCategory();
  };

  return (
    <>
      <PageLoader showLoader={loading} />
      <Sidebar resetData={resetData} />
      <main className="main-content-wrapper">
        <div className="content-wrapper">
          <HeaderNavigation />
          <div className="secondary-header secondary-sticky-header">
            <FormInputGroup
              leftIcon={searchIcon}
              type="search"
              id="list"
              placeholder=""
              label="Search projects"
              className="custom-search-form"
              onChange={e => handleSearch(e)}
            />

            <div className="common-dropdown-filter-wrap">
              <Dropdown align={{ lg: 'end' }}>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  <span className="dropdown-text">Filter by</span>
                  <span className="dropdown-icon">
                    {category?.categoryName || 'Category'}
                    {arrowDownIcon}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="common-dropdown-menu">
                  <Dropdown.Item
                    eventKey="1"
                    onClick={() => setCategory({ categoryId: '', categoryName: 'All' })}
                  >
                    All
                  </Dropdown.Item>
                  {selectCategory?.map(category => {
                    return (
                      <Dropdown.Item
                        key={category?.id}
                        eventKey={category?.id}
                        onClick={() =>
                          setCategory({
                            categoryId: category?.id,
                            categoryName: category?.category,
                          })
                        }
                      >
                        {category?.category}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>

              {/* <Dropdown align={{ lg: 'end' }}>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  <span className="dropdown-text">Filter by</span>
                  <span className="dropdown-icon">
                    Completion status
                    {arrowDownIcon}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="common-dropdown-menu">
                  <Dropdown.Item eventKey="1">Item - 1</Dropdown.Item>
                  <Dropdown.Item eventKey="2">Item - 2</Dropdown.Item>
                  <Dropdown.Item eventKey="3">Item - 3</Dropdown.Item>
                  <Dropdown.Item eventKey="4">Item - 4</Dropdown.Item>
                  <Dropdown.Item eventKey="5">Item - 5</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown align={{ lg: 'end' }}>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  <span className="dropdown-text">Filter by</span>
                  <span className="dropdown-icon">
                    Rendering status
                    {arrowDownIcon}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="common-dropdown-menu">
                  <Dropdown.Item eventKey="1">Item - 1</Dropdown.Item>
                  <Dropdown.Item eventKey="2">Item - 2</Dropdown.Item>
                  <Dropdown.Item eventKey="3">Item - 3</Dropdown.Item>
                  <Dropdown.Item eventKey="4">Item - 4</Dropdown.Item>
                  <Dropdown.Item eventKey="5">Item - 5</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown align={{ lg: 'end' }}>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  <span className="dropdown-text">Filter by</span>
                  <span className="dropdown-icon">
                    Date created
                    {arrowDownIcon}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="common-dropdown-menu">
                  <Dropdown.Item eventKey="1">Item - 1</Dropdown.Item>
                  <Dropdown.Item eventKey="2">Item - 2</Dropdown.Item>
                  <Dropdown.Item eventKey="3">Item - 3</Dropdown.Item>
                  <Dropdown.Item eventKey="4">Item - 4</Dropdown.Item>
                  <Dropdown.Item eventKey="5">Item - 5</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
          </div>
          {(keyWord.length && !projectList.length) ||
          (category?.categoryId && !projectList.length) ? (
            <DataNotFound icon={noProjectIcon} title="No project found" />
          ) : (
            <>
              <div className="create-project-wrapper">
                <Row className="create-project-list">
                  {(isCurrentUserAdmin || currentUserRoles.addProject) && (
                    <Col xl={3} lg={4} md={6} className="mb-4">
                      <button
                        className={`create-new-btn ${modalShow ? 'active' : ''}`}
                        role="button"
                        onClick={() => handleNewProjectModal()}
                      >
                        <span className="icon-wrap">{plusIcon}</span>
                        <span className="create-title">Create New Project</span>
                      </button>
                    </Col>
                  )}
                  {totalItems
                    ? projectList?.map(project => {
                        return (
                          <Col xl={3} lg={4} md={6} className="mb-4" key={project?.id}>
                            <CardThumbnail
                              thumbnailImage={project?.imageUrl}
                              title={project?.ProjectName}
                              courseVideoIcon={courseVideoIcon}
                              courseText={project?.category_name}
                              videoText="Total videos:"
                              totalVideos={allSlidesData[project.ProjectID]?.slideData?.length || 0}
                              clockIcon={clockIcon}
                              duration={allSlidesData[project.ProjectID]?.duration || 0}
                              id={project?._id}
                              getProjects={getProjects}
                              deleteProjects={deleteProjects}
                              projectRole={project?.projectRole}
                            >
                              <ProgressWithPercentage
                                variant="default"
                                now={0}
                                percentage={project?.RenderStatus}
                              />
                            </CardThumbnail>
                          </Col>
                        );
                      })
                    : null}
                </Row>
              </div>
              {totalItems ? (
                <div className="pagination-wrapper">
                  <PagePagination
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    setItemsPerPage={setItemsPerPage}
                  />
                  <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    onFirst={handleFirstPage}
                    onPrevious={handlePreviousPage}
                    onNext={handleNextPage}
                    onLast={handleLastPage}
                  />
                </div>
              ) : null}
            </>
          )}
        </div>
        <CreateNewProjectModal
          show={modalShow && !loading}
          onHide={() => setModalShow(false)}
          setModalShow={setModalShow}
        />
      </main>
    </>
  );
}
export default CreateProjects;
