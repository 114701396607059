import React, { useEffect, useState } from "react";
import demoImg from "../../assets/img/demo-img.png"

function ImageComponent(props) {
  const [Image, setImage] = useState(demoImg);
  useEffect(() => {
    // console.log(props.src);
    if (props.src !== undefined) {
      setImage(props.src);
    }
  }, [props.src]);

  return (
    <img
    src={props.src==undefined?demoImg:`${Image}?"time=${new Date().getTime()}`}
    onError={(e) => {
      e.target.src = demoImg;
    }}
  />
  );
}

export default ImageComponent;
