import { random } from "nanoid";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { uploadMediaFile } from "../../../../../services/api.service";
import { getUserItem, showToaster } from "../../../../../services/helper";
import { actionCreator } from "../../../../../store";
import SimpleBackdrop from "../../../../container/pageLoader";
import PageLoader from "../../../../../services/pageLoader";
function CanvasImg(props) {
  const canvas = useRef(null);
  const dispatch = useDispatch();
  let { UpdatePreviewFeature } = bindActionCreators(actionCreator, dispatch);

  const [Loader, setLoader] = useState(false);

  useEffect(async () => {
    try {
      // let currentCanvas = canvas.current;
      const currentCanvas = document.createElement("canvas");

      props.setexposure(100);
      props.setcontrast(100);
      props.setsaturation(100);
      props.setopacity(100);
      let imgLoaded = false;
      //console.log(currentCanvas);
      let ctx = currentCanvas.getContext("2d");
      ctx.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
      ctx.font = "24px sans-serif";
      ctx.textAlign = "center";
      ctx.fillStyle = "#468BE8";
      ctx.fillText("Loading...", 250, 250);
      ctx.rect(0, 0, currentCanvas.width, currentCanvas.height);
      let img = document.createElement("img");

      // set canvas size to match the bounding box

      // currentCanvas.width = 480;
      // currentCanvas.height = 270;
      // else {
      //   img.src = "../../../../assets/img/fallbackImage.jpg";
      // }
      img.crossOrigin = "anonymous";
      img.onload = function () {
        ctx.canvas.width = img.width;
        ctx.canvas.height = img.height;
        ctx.clearRect(0, 0, img.width, img.height);
        ctx.drawImage(img, 0, 0, img.width, img.height);
        imgLoaded = true;
      };
      if (props.src != "" && props.src != undefined && props.src != null) {
        img.src = props.src + `?not-from-cache-please=${Date.now()}`;
      }
      setTimeout(async () => {
        if (imgLoaded == false) {
          ctx.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
          ctx.font = "24px sans-serif";
          ctx.textAlign = "center";
          ctx.fillStyle = "#468BE8";
          ctx.fillText("Loading...", 250, 250);
          ctx.rect(0, 0, currentCanvas.width, currentCanvas.height);
          let img = document.createElement("img");
          // currentCanvas.width = 480;
          // currentCanvas.height = 270;
          // else {
          //   img.src = "../../../../assets/img/fallbackImage.jpg";
          // }
          img.crossOrigin = "anonymous";
          img.onload = function () {
            ctx.canvas.width = img.width;
            ctx.canvas.height = img.height;
            ctx.clearRect(0, 0, img.width, img.height);
            ctx.drawImage(img, 0, 0, img.width, img.height);
            imgLoaded = true;
          };
          if (props.src != "" && props.src != undefined && props.src != null) {
            img.src = props.src + `?not-from-cache-please=${Date.now()}`;
          }
        }
      }, 5000);
    } catch (error) {
      props.setexposure(100);
      props.setcontrast(100);
      props.setsaturation(100);
      props.setopacity(100);
      // let currentCanvas = canvas.current;
      const currentCanvas = document.createElement("canvas");
      let ctx = currentCanvas.getContext("2d");
      ctx.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
      ctx.font = "24px sans-serif";
      ctx.textAlign = "center";
      ctx.fillStyle = "#468BE8";
      ctx.fillText("Loading...", 250, 250);
      ctx.rect(0, 0, currentCanvas.width, currentCanvas.height);
      //ctx.stroke();
      let img = document.createElement("img");
      // currentCanvas.width = 480;
      // currentCanvas.height = 270;
      img.crossOrigin = "anonymous";
      img.onload = function () {
        ctx.canvas.width = img.width;
        ctx.canvas.height = img.height;
        ctx.clearRect(0, 0, img.width, img.height);
        ctx.drawImage(img, 0, 0, img.width, img.height);
      };
      img.src = props.src + `?not-from-cache-please=${Date.now()}`;
    }
  }, []);

  useEffect(async () => {
    if (props.processEditImg) {
      setLoader(true);
      let processedImg = await getImage();
      //console.log(processedImg);
      props.setnewImage(processedImg);
      if (props.uploadImage) {
        UploadCropedImage(processedImg);
      }
      //props.setuploadImage(false)
      props.setprocessEditImg(false);
      setLoader(false);
    }
  }, [props.processEditImg]);

  const UploadCropedImage = async (uploadedFile) => {
    try {
      //setLoader(true);
      //console.log(uploadedFile);
      let filename = "IMG_" + Math.floor(Math.random() * 1000000);
      let file = await urltoFile(uploadedFile, filename + ".jpg", "");
      //console.log(file);
      let data = new FormData();
      // let header = {
      //   "content-type": "multipart/form-data",
      //   media_type: "IMAGE",
      //   user_id: getUserItem("id"),
      //   file_name: filename + ".jpeg",
      //   private: 1
      // };
      // const config = {
      //   timeout: 1000 * 50000,
      //   headers: header,
      // };

      const config = {
        timeout: 1000 * 50000,
        headers: {
          "content-type": "multipart/form-data",
          media_type: "IMAGE",
          user_id: getUserItem("id"),
          file_name: filename + ".jpeg",
          private: 1
        }
      };

      //console.log(uploadedFile);
      data.append("file", file);
      uploadMediaFile(data, config).then((response) => {
        //console.log(response);
        // if (response.status === 200) {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            let asset = props.item[1];
            asset.Contentparams.contentdescription = response.data;
            let imgdata = {
              [props.item[0]]: asset
            };
            UpdatePreviewFeature(imgdata);
            props.closePreview();
            props.setuploadImage(false);
            props.setprocessEditImg(false);
            showToaster("Image saved successfully", "success");
            props.setshowLoader(false);
            //setLoader(false);
          } else if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ""
          ) {
          } else {
          }
        } catch (error) {
          props.setuploadImage(false);
          props.setprocessEditImg(false);
          setLoader(false);
          showToaster("Something went wrong!. Unable to update1", "warning");
          props.setshowLoader(false);
        }
        // } else if (response.status === 101) {
        //   props.setuploadImage(false);
        //   props.setprocessEditImg(false);
        //   setLoader(false);
        //   showToaster("Something went wrong!. Unable to update2", "warning");
        //   props.setshowLoader(false);
        // } else {
        //   props.setuploadImage(false);
        //   props.setprocessEditImg(false);
        //   setLoader(false);
        //   showToaster("Something went wrong!. Unable to update3", "warning");
        //   props.setshowLoader(false);
        // }
      });
    } catch (error) {
      props.setuploadImage(false);
      props.setprocessEditImg(false);
      setLoader(false);
      showToaster("Something went wrong!", "warning");
      props.setshowLoader(false);
    }
  };

  useEffect(async () => {
    if (props.src) {
      setLoader(true);
    }
    props.setexposure(100);
    props.setcontrast(100);
    props.setsaturation(100);
    props.setopacity(100);
    // let currentCanvas = canvas.current;
    const currentCanvas = document.createElement("canvas");
    let ctx = currentCanvas.getContext("2d");
    ctx.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
    ctx.font = "24px sans-serif";
    ctx.textAlign = "center";
    ctx.fillStyle = "#468BE8";
    ctx.fillText("Loading...", 250, 250);
    ctx.rect(0, 0, currentCanvas.width, currentCanvas.height);
    //ctx.stroke();
    let img = document.createElement("img");
    // currentCanvas.width = 480;
    // currentCanvas.height = 270;
    img.crossOrigin = "anonymous";

    img.onload = function () {
      ctx.canvas.width = img.width;
      ctx.canvas.height = img.height;
      ctx.clearRect(0, 0, img.width, img.height);
      ctx.filter =
        "contrast(" + props.contrast + "%) brightness(" + props.exposure + "%) saturate(" + props.saturation + "%) opacity(" + props.opacity + "%)";
      ctx.drawImage(img, 0, 0, img.width, img.height);
      //let imgDone = currentCanvas.toDataURL("image/jpeg");
      //console.log(imgDone);
    };
    img.src = props.src + `?not-from-cache-please=${Date.now()}`;
  }, [props.src]);

  useEffect(async () => {
    const currentCanvas = document.createElement("canvas");
    // let currentCanvas = canvas.current;
    let ctx = currentCanvas.getContext("2d");
    ctx.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
    ctx.font = "24px sans-serif";
    ctx.textAlign = "center";
    ctx.fillStyle = "#468BE8";
    ctx.fillText("Updating...", 250, 250);
    ctx.rect(0, 0, currentCanvas.width, currentCanvas.height);
    let img = document.createElement("img");
    currentCanvas.width = 480;
    currentCanvas.height = 270;
    img.crossOrigin = "anonymous";
    img.onload = function () {
      ctx.canvas.width = img.width;
      ctx.canvas.height = img.height;
      ctx.clearRect(0, 0, img.width, img.height);
      ctx.filter =
        "contrast(" + props.contrast + "%) brightness(" + props.exposure + "%) saturate(" + props.saturation + "%) opacity(" + props.opacity + "%)";
      ctx.drawImage(img, 0, 0, img.width, img.height);
      //let imgDone = currentCanvas.toDataURL("image/jpeg");
      console.log("imgDone");
    };
    img.src = props.src + `?not-from-cache-please=${Date.now()}`;
  }, [props.contrast, props.exposure, props.saturation, props.opacity]);

  const FetchImage = async (imageUrl) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(imageUrl, { mode: "no-cors" });
        if (!response.ok) {
          throw Error(`${response.status} ${response.statusText}`);
        }
        let blob = await response.blob();
        const imgUrl = URL.createObjectURL(blob);
        const imageEl = document.createElement("img");
        imageEl.src = imgUrl;
        resolve(imgUrl);
      } catch (error) {}
    });
  };

  const getImage = () => {
    return new Promise((resolve, reject) => {
      // let currentCanvas = canvas.current;
      const currentCanvas = document.createElement("canvas");
      //console.log(currentCanvas);
      let ctx = currentCanvas.getContext("2d");
      ctx.clearRect(0, 0, currentCanvas.width, currentCanvas.height);

      let img = document.createElement("img");
      currentCanvas.width = 480;
      currentCanvas.height = 270;
      img.crossOrigin = "anonymous";
      img.onload = function () {
        ctx.canvas.width = img.width;
        ctx.canvas.height = img.height;
        // ctx.clearRect(0, 0, img.width, img.height);
        ctx.filter =
          "contrast(" + props.contrast + "%) brightness(" + props.exposure + "%) saturate(" + props.saturation + "%) opacity(" + props.opacity + "%)";
        ctx.drawImage(img, 0, 0, img.width, img.height);
        let imgDone = currentCanvas.toDataURL("image/jpeg");
        //console.log(imgDone);
        resolve(imgDone);
      };
      img.src = props.src + `?not-from-cache-please=${Date.now()}`;
    });
  };

  const urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: "image/jpeg" });
      });
  };

  return (
    <>
      {props.src ? (
        <img
          src={props.src}
          // ref={canvas}s
          // width="480"
          // height="270"
          style={{ width: "100%", height: "100%" }}
          onLoad={() => setLoader(false)}
        />
      ) : (
        <div className="label-block">
          <p className="edit-label">No Image</p>
        </div>
      )}

      <PageLoader showLoader={Loader} />
      {/* <SimpleBackdrop showBackDrop={Loader} /> */}
    </>
  );
}

export default CanvasImg;
