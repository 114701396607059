import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import {
  getTemplatesRepo,
  getTemplatesRepoManual,
} from "../../../../services/api.service";
import ReactImageFallback from "react-image-fallback";
import Pagination from "./pagination";
import { getUserItem } from "../../../../services/helper";
import CircularProgress from "@material-ui/core/CircularProgress";
import TemplatePreview from "../RightBar/TemplatePriview";
import Tooltip from "@mui/material/Tooltip";
import { getTemplatePlanByOrg } from "../../../../services/authService";

export default function TemplateRepo(props) {
  const [templateRepo, setTemplateRepo] = useState([]);
  const [templateRepoLoader, setTemplateRepoLoader] = useState(false);
  const [templateErrorMsg, setTemplateErrorMsg] = useState("");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [openTemplatePreview, setOpenTemplatePreview] = useState(false);
  const [selectedTemplateFile, setSelectedTemplateFile] = useState(false);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const project = useSelector((state) => state.preview.previewProject);
  const projectId = useSelector((state) => state.preview.ProjectId);
  const ActiveModule = useSelector((state) => state.preview.ActiveModuleId);
  const ActiveSlide = useSelector((state) => state.preview.ActiveSlideId);
  const ActiveScreen = useSelector((state) => state.preview.ActiveScreenId);
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const [templateActive, setTemplateActive] = useState(true);
  const [uploadActive, setUploadActive] = useState(false);
  const [templateSelectionType, setTemplateSelectionType] = useState(undefined);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  let OrganiId = getUserItem("orgId");
  let userId = getUserItem("id");
  let AdminRole = getUserItem("role");
console.log(project, 'from template ===>');

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const changeAssetSection = (type) => {
    if (type === "1") {
      setTemplateActive(true);
      setUploadActive(false);
    } else if (type === "2") {
      setTemplateActive(false);
      setUploadActive(true);
    }
  };
  useEffect(async () => {
    setTemplateRepoLoader(true);
   await getAssetRepo();
    if (templateRepo.length > 0) {
      setPosts(templateRepo);
      setTemplateRepoLoader(false);
    }
  }, []);

  const viewTemplatePreview = (video) => {
    setOpenTemplatePreview(true);
    setSelectedTemplateFile(video);
  };

  const getAssetRepo =async (flag) => {
    setTemplateRepoLoader(true);
    let modules = project;
    let projectName = modules[projectId].ProjectName;
    let activeScreen =
      modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide][
        "Screens"
      ][ActiveScreen];

    let activeScreenDetails = {
      ScreenName:
        modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide][
          "Screens"
        ][ActiveScreen]["ScreenName"],
      ...activeScreen,
    };

    let SlideAudioVO =
      modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide][
        "SlideAudioVO"
      ] !== undefined
        ? modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide][
            "SlideAudioVO"
          ]
        : "Null";

    let screenContent = {
      [projectId]: {
        ProjectName: projectName,
        Modules: {
          [ActiveModule]: {
            ModuleName:
              modules[projectId]["Modules"][ActiveModule]["ModuleName"],
            slides: {
              [ActiveSlide]: {
                SlideName:
                  modules[projectId]["Modules"][ActiveModule]["slides"][
                    ActiveSlide
                  ]["SlideName"],
                SlideAudioVO: SlideAudioVO,
                Screens: {
                  [ActiveScreen]: activeScreenDetails,
                },
              },
            },
          },
        },
      },
    };

    let templateSelectionType1
    let orgId = AdminRole == 0 ? userId : OrganiId;
    await getTemplatePlanByOrg(orgId).then(async (response) => {
      setTemplateRepoLoader(true);
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200 &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
           templateSelectionType1=response.data.result[0]
          setTemplateSelectionType(response.data.result[0]);
          setTemplateRepoLoader(false);
        }
      } catch (error) {
        //this.setState({ uploadButtonProgress: false })
      }
    });


    let type;
    if (
      templateSelectionType1.private === true &&
      templateSelectionType1.public === false
    ) {
      type = "private";
    } else if (
      templateSelectionType1.public === true &&
      templateSelectionType1.private === false
    ) {
      type = "public";
    } else if (
      templateSelectionType1.public === true &&
      templateSelectionType1.private === true
    ) {
      type = "public&private";
    }
    if (templateSelectionType1.manualTemplate == false) {
      setTemplateRepoLoader(true);
      getTemplatesRepo(screenContent,orgId,projectId)
        .then((response) => {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {

            let projectData = response.data[0];
            if (projectData.length > 0) {
              setPosts(projectData);
            }

            setLoading(false);
            setTemplateRepo(projectData);

            setTemplateRepoLoader(false);
          } else if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ""
          ) {
            setTemplateRepoLoader(false);
            setTemplateRepo([]);
            setTemplateErrorMsg("No template available");
          } else {
            setTemplateRepoLoader(false);
            setTemplateRepo([]);
            setTemplateErrorMsg("No template available");
          }
        })
        .catch((error) => {
          setTemplateRepoLoader(false);
          setTemplateErrorMsg("No template available");
        });
    } else {


      setTemplateRepoLoader(true);
      getTemplatesRepoManual(screenContent, type, orgId, projectId)
        .then((response) => {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            let projectData = response.data[0];
            if (projectData.length > 0) {
              setPosts(projectData);
            }
            setLoading(false);
            setTemplateRepo(projectData);

            setTemplateRepoLoader(false);
          } else if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ""
          ) {
            setTemplateRepoLoader(false);
            setTemplateRepo([]);
            setTemplateErrorMsg("No template available");
          } else {
            setTemplateRepoLoader(false);
            setTemplateRepo([]);
            setTemplateErrorMsg("No template available");
          }
        })
        .catch((error) => {
          setTemplateRepoLoader(false);
          setTemplateErrorMsg("No template available");
        });
    }

    // }
  };


  return (
    <React.Fragment>
      <Modal
        isOpen={props.templateMode}
        fade={true}
        centered
        className={
          DisplayMode
            ? "modal-dialog modal-dialog-centered template-modal custom-modal dark"
            : "modal-dialog  modal-dialog-centered template-modal custom-modal"
        }
        // className="modal-dialog  modal-dialog-centered styles-modal custom-modal dark"
      >
        <ModalBody className="p-0">
          {/* <SimpleBackdrop showBackDrop={templateRepoLoader} /> */}
          <Tooltip title="Close" arrow>
            <div
              className="close-priview"
              onClick={() => props.setTemplateMode()}
            >
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="close">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0"
                    />
                    <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                  </g>
                </g>
              </svg>
            </div>
          </Tooltip>
          <div className="srch-temp-blk">
            <div className="srch-temp-wrapper"></div>
            <div className="cm-st-gallery my-3">
              {templateRepoLoader === false ? (
                templateRepo.length > 0? (
                  <div className="cm-gallery-wrapper">
                    {" "}
                    {currentPosts.map((template, index) => {
                      let thumbanilUrl=templateSelectionType.manualTemplate == false?
                      template.thumbnailUrl:template.thumbnailUrl
                      return (
                        <div
                          className="cm-gallery-container temp-card"
                          onClick={() => {
                            viewTemplatePreview(template);
                          }}
                        >
                           <div className="img-info">
                              {template.orgId !== undefined &&
                                template.orgId !== null &&
                                template.orgId !== "" && (
                                  <Tooltip title="Private Template" arrow>
                                    <span>
                                      <svg
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 330 330"
                                        space="preserve"
                                      >
                                        <g id="XMLID_486_">
                                          <path
                                            id="XMLID_487_"
                                            d="M165,330c63.411,0,115-51.589,115-115c0-29.771-11.373-56.936-30-77.379V85c0-46.869-38.131-85-85-85
                                        S80.001,38.131,80.001,85v52.619C61.373,158.064,50,185.229,50,215C50,278.411,101.589,330,165,330z M180,219.986V240
                                        c0,8.284-6.716,15-15,15s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
                                        C190,208.162,186.068,215.421,180,219.986z M110.001,85c0-30.327,24.673-55,54.999-55c30.327,0,55,24.673,55,55v29.029
                                        C203.652,105.088,184.91,100,165,100c-19.909,0-38.651,5.088-54.999,14.028V85z"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </Tooltip>
                                )}
                            </div>
                          {thumbanilUrl !== undefined &&
                          thumbanilUrl !== "" &&
                          thumbanilUrl !== null ? (
                            <img
                              className="img-fluid"
                              src={thumbanilUrl}
                            />
                          ) : (
                            <ReactImageFallback
                              src={thumbanilUrl}
                              fallbackImage="../../../assets/img/fallbackImage.jpg"
                              initialImage="../../../assets/img/fallbackImage.jpg"
                              alt="Logo"
                              className="img-fluid"
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="v-gal-empty-blk">
                    <div className="no-txt-selected blink">
                      {/* {capitalizeFirstLetter(templateErrorMsg)} */}
                      No template available
                    </div>
                  </div>
                )
              ) : (
                <div className="v-gal-empty-blk">
                  <CircularProgress />
                </div>
              )}
            </div>

            {templateRepo.length > 0 && (
              <Pagination
                paginate={paginate}
                postsPerPage={postsPerPage}
                totalPosts={posts.length}
              />
            )}
          </div>

          <TemplatePreview
            closePreview={() => setOpenTemplatePreview(false)}
            closeModal={() => props.setTemplateMode()}
            openTemplatePreview={openTemplatePreview}
            selectedVideo={selectedTemplateFile}
            setTemplateUpdate={props.setTemplateUpdate}
            setchanges={props.setchanges}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
