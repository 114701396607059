import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import FormFloatingInput from '../../../../components/form/formFloatingInput';
import {
  eyeCloseIcon,
  eyeOpenIcon,
  lockIcon,
  mailIcon,
  orgGroupIcon,
  userIcon,
  urlIcon,
} from '../../../../constants/icon';

export default function EditProfileForm() {
  const [phone, setPhone] = useState('+977');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <form>
      <FormFloatingInput
        leftIcon={userIcon}
        type="name"
        id="userName"
        placeholder=""
        label="Full Name"
        asterisk
      />
      <FormFloatingInput
        leftIcon={mailIcon}
        type="email"
        id="email"
        placeholder=""
        label="Email"
        asterisk
      />

      <div className="d-flex country-code">
        <PhoneInput
          country={'eg'}
          enableSearch={true}
          value={phone}
          onChange={phone => setPhone(phone)}
        />
        <FormFloatingInput
          leftIcon={mailIcon}
          type="number"
          id="number"
          placeholder=""
          label="Phone number"
          asterisk
        />
      </div>

      <Row>
        <Col lg={6}>
          <FormFloatingInput
            className="m-0"
            leftIcon={lockIcon}
            rightIcon={showPassword ? <>{eyeOpenIcon}</> : <>{eyeCloseIcon}</>}
            type={showPassword ? 'text' : 'password'}
            id="password"
            placeholder=""
            label="Password"
            onClick={togglePasswordVisibility}
            asterisk
          />
        </Col>
        <Col lg={6}>
          <FormFloatingInput
            className="m-0"
            leftIcon={lockIcon}
            rightIcon={showConfirmPassword ? <>{eyeOpenIcon}</> : <>{eyeCloseIcon}</>}
            type={showConfirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            placeholder=""
            label="Confirm Password"
            onClick={toggleConfirmPasswordVisibility}
            asterisk
          />
        </Col>
      </Row>
      <span className="separater-text-line">
        <span>Organization Details</span>
      </span>
      <Row>
        <Col lg={6}>
          <FormFloatingInput
            className="m-0"
            leftIcon={orgGroupIcon}
            type="text"
            id="orgName"
            placeholder=""
            label="Organization Name"
            asterisk
          />
        </Col>
        <Col lg={6}>
          <FormFloatingInput
            className="m-0"
            leftIcon={urlIcon}
            type="url"
            id="accountUrl"
            placeholder=""
            label="Account URL"
            asterisk
          />
        </Col>
      </Row>
    </form>
  );
}
