import React, { useEffect, useRef, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import WaveSurfer from "wavesurfer.js";
import { CircularProgress } from "@mui/material";
const formWaveSurferOptions = ref => ({
    container: ref,
    waveColor: "#58595a",
    progressColor: "#4667e8",
    cursorColor: "#4667e8",
    barWidth: 2,
    barRadius: 5,
    responsive: true,
    height: 150,
    // If true, normalize by the maximum peak instead of 1.0.
    // normalize: true,
    // Use the PeakCache to improve rendering speed of large waveforms.
    partialRender: true
});

export default function Waveform({ url,fileName}) {
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const [playing, setPlay] = useState(false);
    const [volume, setVolume] = useState(0.5);
    const [Isready, setIsready] = useState(false)
    // create new WaveSurfer instance
    // On component mount and when url changes
    useEffect(() => {
        setPlay(false);
        const options = formWaveSurferOptions(waveformRef.current);
        wavesurfer.current = WaveSurfer.create(options);

        wavesurfer.current.load(url);

        wavesurfer.current.on("ready", function () {
            // https://wavesurfer-js.org/docs/methods.html
            // wavesurfer.current.play();
            // setPlay(true);

            // make sure object stillavailable when file loaded
            if (wavesurfer.current) {
                wavesurfer.current.setVolume(volume);
                setVolume(volume);
                setIsready(true)
            }
        });

        // Removes events, elements and disconnects Web Audio nodes.
        // when component unmount
        return () => wavesurfer.current.destroy();
    }, [url]);

    const handlePlayPause = () => {
        setPlay(!playing);
        wavesurfer.current.playPause();
    };

    const onVolumeChange = e => {
        const { target } = e;
        const newVolume = +target.value;

        if (newVolume) {
            setVolume(newVolume);
            wavesurfer.current.setVolume(newVolume || 1);
        }
    };

    return (
        <div >
            {Isready ?   
             <div className="controls">
                    {!playing ? (
                        <Tooltip title="Play" arrow>
                            <span className="play-audio" onClick={handlePlayPause}>
                                <svg width="26"
                                    height="22" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 485 485" style={{ enableBackground: "0 0 485 485" }} space="preserve">
                                    <g>
                                        <path d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5
   s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026
   C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5
   S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z"/>
                                        <polygon points="181.062,336.575 343.938,242.5 181.062,148.425 	" />
                                    </g>

                                </svg>

                            </span></Tooltip>
                    )
                        :
                        (
                            <Tooltip title="Pause" arrow>
                                <span
                                    className="play-audio"
                                    onClick={handlePlayPause}

                                >
                                    <svg
                                        width="20"
                                        height="18"
                                        viewBox="0 0 11 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect y="0.5" width="4" height="12" fill="" />
                                        <rect x="7" y="0.5" width="4" height="12" fill="" />
                                    </svg>
                                </span></Tooltip>
                        )}

                </div>: <CircularProgress color="inherit" size="2rem" style={{ color: "aliceblue", position: "absolute", left: "0", top: "0", bottom: "0", right: "0", margin: "auto" }} />}

            <div  id="waveform" ref={waveformRef} />
                     
        </div>
    );
}
