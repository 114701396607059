import React from 'react';

const FormFloatingInput = props => {
  const {
    leftIcon,
    rightIcon,
    type,
    id,
    placeholder,
    label,
    onClick,
    inputValue,
    asterisk,
    onChange,
    onBlur,
    autoComplete
  } = props;
  return (
    <div className={`custom-form-floating ${props.className || ''}`}>
      {leftIcon && <span className="form-icon form-left-icon">{leftIcon}</span>}
      <input
        type={type}
        placeholder={placeholder}
        id={id}
        value={inputValue}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete={autoComplete}
      />
      <label htmlFor={id}>
        {label}
        {asterisk && <span className="label-with-asterisk"></span>}
      </label>
      {rightIcon && (
        <span className="form-icon form-right-icon" onClick={onClick}>
          {rightIcon}
        </span>
      )}
    </div>
  );
};

export default FormFloatingInput;
