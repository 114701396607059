import React from 'react';
import { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function CarouselSlider({slidesToShow=1,variableWidth=true,adaptiveHeight= true, className = '', children }) {
  const ref = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    // slidesToShow: slidesToShow,
    //slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    adaptiveHeight: true,
    // variableWidth: variableWidth,
    // adaptiveHeight: adaptiveHeight,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          //slidesToScroll: 1,
          swipeToSlide: true,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} ref={ref} className={`${className || ''} video-carousel-item`}>
      {children}
    </Slider>
  );
}
