import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Select from "react-select";
import {
  getOrganisation,
  inserttemplatePlan,
  getTemplatePlan,
} from "../../services/authService";
import { showToaster, ShowAlertBox, getUserItem } from "../../services/helper";
import { Checkbox } from "@mui/material";
import Radio from "@mui/material/Radio";
import Header from "../components/header/header";
import PageLoader from "../../services/pageLoader";
export default function TemplateplanSettings() {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [orgList, setOrgList] = useState([]);
  const [pageLoad, setPageLoad] = useState(false);
  const [templatePlan, setTemplatePlan] = useState(false);
  const [templatePlanAuto, setTemplatePlanAuto] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("a");

  useEffect(() => {
    getOrganisationList("", -1, -1);
  }, []);

  const AddTemplatePlan = async () => {
    try {
      setPageLoad(true);

      let roleArray = [];
      let projectRole = orgList.map((role) => {
        role.giveActionData.orgId = role._id;
        role.giveActionData.orgName = role.email;
        role.giveActionData.manualTemplate=role.manualTemplate;
        role.giveActionData.autoTemplate=role.autoTemplate;
        role.giveActionData.public=role.giveActionData.both?true:role.giveActionData.public;
        role.giveActionData.private=role.giveActionData.both?true:role.giveActionData.private;
        // if (role.giveActionData.editProject || role.giveActionData.viewProject || role.giveActionData.deleteProject) {
        roleArray.push(role.giveActionData);
        // }

        return role;
      });
      console.log(roleArray, "role");
      await inserttemplatePlan(roleArray).then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            setPageLoad(false);
            showToaster("Plan updated successfully", "success");
            getOrganisationList("", -1, -1);
            if (response.data.result != undefined) {
            }
          } else {
            setPageLoad(false);
            showToaster("Something went wrong. Please Try Again1", "warning");
          }
        } catch (error) {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      });
    } catch (error) {
      setPageLoad(false);
      showToaster("Something went wrong. Please Try Again", "warning");
    }
  };
  const updateProjectRoleDetails = async (projList) => {
    try {
      // await  getAllProjects(1, 0, "");
      console.log(projList, "listUsers");
      let projectData2 = JSON.stringify(projList);
      let giveActionData = {
        public: false,
        private: false,
        both:false
      };
      let initialData = JSON.parse(projectData2).map((data) => {
        data.giveActionData = giveActionData;
        data.manualTemplate = false;
        data.autoTemplate = false;
        return data;
      });
      const response = await getTemplatePlan();
      try {
        setPageLoad(true);
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          console.log(response.data.result, "response");
          // setCategoryList(ddd)
          let previousDate;
          setPageLoad(false);
          if (response.data.result != undefined) {
            let ddd = response.data.result;
            let arr = initialData;
            console.log(ddd);
            // ddd.map((actions) => {
            for (let index = 0; index < arr.length; index++) {
              const element = arr[index];
              for (let i = 0; i < ddd.length; i++) {
                const actions = ddd[i];

                if (element._id === actions.orgId) {
                  console.log(element._id, actions._id);
                  let actionData = {
                    public: actions.public&&!actions.private?true:false,
                    private: !actions.public&&actions.private?true:false,
                    both:actions.public&&actions.private?true:false
                  };
                  let templateManual = actions.manualTemplate;
                  let autoTemplate = actions.autoTemplate;
                  // let actionDataDesables = {
                  //     editProject: actions.editProject,
                  //     viewProject: actions.viewProject,
                  //     deleteProject: actions.deleteProject
                  // }
                  // arr[index].giveActionDataDesable = actionDataDesables
                  console.log(actions, element, actionData);
                  arr[index].giveActionData = actionData;
                  arr[index].manualTemplate = templateManual;
                  arr[index].autoTemplate = autoTemplate;
                }
              }
            }
            console.log(arr, "arrarar");
            console.log(projectData2, "projectData2projectData2");
            setOrgList(arr);
            // setcacheUserList(arr);
          } else {
            showToaster("Something went wrong. Please Try Again1", "warning");
          }
          console.log(previousDate, "datatatatat");
        } else {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again1", "warning");
        }
      } catch (error) {
        console.log(error);
        setPageLoad(false);
        showToaster("Something went wrong. Please Try Again123", "warning");
      }
    } catch (e) {
      console.log(e);
    }
    // setprojectList(projList);
    return true;
  };
  const getOrganisationList = (keyword, sortCreate, sortupdated) => {
    let filter = {
      params: 0,
      // isActive: listStatus.isActive,
      // orgId: userParentId,
      keyword: keyword,
      sortCreateAt: sortCreate,
      sortUpdatedAt: sortupdated,
    };
    setPageLoad(true);
    getOrganisation(filter).then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          // this.fontArray = response.data
          console.log(response.data);
          let giveActionData = {
            public: false,
            private: false,
            both:false
          };
          let projectData = response.data.result.data;
          projectData.map((data) => {
            data.giveActionData = giveActionData;
            data.manualTemplate = false;
            data.autoTemplate = false;
          });
          //   setOrgList(projectData);
          setPageLoad(false);
          updateProjectRoleDetails(projectData).then(() => {
            setPageLoad(false);
          });
          //generateFonts();
        }
      } catch (error) {}
    });
  };
  const changePermission = (orgId, actiontype, event) => {
    let previousData;
    let projectData = JSON.stringify(orgList);
    // previousData = JSON.parse(projectData).map((data) => {
    //   if (data._id === orgId) {
    //     data.giveActionData[actiontype] = event.target.checked;
    //   }
    //   return data;
    // });

    previousData = JSON.parse(projectData).map((data) => {
      if (data._id === orgId && actiontype === "public") {
        data.giveActionData["public"] = true;
        data.giveActionData["private"] = false;
        data.giveActionData["both"] = false;
      } else if(data._id === orgId && actiontype === "private") {
        data.giveActionData["private"] = true;
        data.giveActionData["public"] = false;
        data.giveActionData["both"] = false;
      }else if(data._id === orgId && actiontype === "both"){
        data.giveActionData["both"] = true;
        data.giveActionData["private"] = false;
        data.giveActionData["public"] = false;
      }
      return data;
    });
    setOrgList(previousData);
    console.log(previousData);
  };
  const changePermission1 = (orgId, actiontype, event) => {
    let previousData;
    let projectData = JSON.stringify(orgList);
    previousData = JSON.parse(projectData).map((data) => {
      if (data._id === orgId) {
        data[actiontype] = event.target.checked;
      } else {
        data[actiontype] = event.target.checked;
      }
      return data;
    });
    setOrgList(previousData);
    console.log(previousData);
  };
  const handleChange = (orgId, actiontype, event) => {
    let previousData;
    let projectData = JSON.stringify(orgList);
    previousData = JSON.parse(projectData).map((data) => {
      if (data._id === orgId && actiontype === "autoTemplate") {
        data.autoTemplate = true;
        data.manualTemplate = false;
      } else if(data._id === orgId && actiontype === "manualTemplate") {
        data.giveActionData["public"] =  data.giveActionData["private"]||
        data.giveActionData["both"]?false:true;
        data.manualTemplate = true;
        data.autoTemplate = false;
      }
      return data;
    });
    setOrgList(previousData);
    console.log(previousData);
    // setSelectedValue(event.target.value);
  };
  // const handleChange = (type, e) => {
  //   if (type == "automatic") {
  //     setTemplatePlan(true);
  //     setTemplatePlanAuto(false);
  //   } else {
  //     setTemplatePlanAuto(true);
  //     setTemplatePlan(false);
  //   }
  // };
  return (
    <>
      <PageLoader showLoader={pageLoad} />
      <div className={DisplayMode ? "theme dark" : "theme"}>
        <div className="main-wrapper">
          <Header />
          <div className="pb-wrapper p-0">
            <div className="row justify-content-center px-4 py-3 project-list-wrap">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="table-wraper pt-3">
                  <div className="user-list-wrap org-wraper">
                    <div className="user-list-block mb-3 sticky-head">
                      <div className="usage-head-label ">Organization Name</div>
                      <div className="usage-head-label text-center">Automatic</div>
                      <div className="usage-head-label text-center">Manual</div>
                    </div>

                    <div className="user-list-inner">
                      {!pageLoad &&
                        orgList.length > 0 &&
                        orgList.map((data, index) => {
                          console.log(data.manualTemplate);
                          return (
                            <div className="user-list-block usg-sub-block mb-2">
                              <div className="usage-head-label ">
                                {" "}
                                {data.orgName !== undefined
                                  ? data.orgName + "(" + data.email + ")"
                                  : data.email}
                              </div>
                              <div className="usage-head-label text-center">
                                <div className="templt-radio-holder">
                                  <Radio
                                    checked={data.autoTemplate}
                                    onChange={(e) =>
                                      handleChange(data._id, "autoTemplate", e)
                                    }
                                    // value={data.autoTemplate}
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "A" }}
                                  />
                              
                                </div>
                              </div>
                              <div className="usage-head-label text-center">
                                <div className="templt-radio-holder">
                                  <Radio
                                    checked={data.manualTemplate}
                                    onChange={(e) =>
                                      handleChange(
                                        data._id,
                                        "manualTemplate",
                                        e
                                      )
                                    }
                                    // value={data.manualTemplate}
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "B" }}
                                  />
                                  
                                </div>
                                {data.manualTemplate && (
                                  <div className="d-flex">
                                    <div className="me-2">
                                     
                                      <Radio
                                    checked={data.giveActionData.public}
                                    onChange={(e) =>
                                      changePermission(
                                        data._id,
                                        "public",
                                        e
                                      )
                                    }
                                    // value={data.autoTemplate}
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "A" }}
                                  />
                                      <label for="html-11"> Public</label>
                                    </div>
                                    <div className="me-2">
                                      {/* <Checkbox
                                        checked={data.giveActionData.private}
                                        onChange={(e) =>
                                          changePermission(
                                            data._id,
                                            "private",
                                            e
                                          )
                                        }
                                        color="primary"
                                        inputProps={{
                                          "aria-label": "secondary checkbox",
                                        }}
                                      />
                                      {data.giveActionData.private} */}
                                       <Radio
                                    checked={data.giveActionData.private}
                                    onChange={(e) =>
                                      changePermission(
                                        data._id,
                                        "private",
                                        e
                                      )
                                    }
                                    // value={data.autoTemplate}
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "A" }}
                                  />
                                      <label for="html-12"> Private</label>
                                    </div>
                                    <div>
                                      {/* <Checkbox
                                        checked={data.giveActionData.private}
                                        onChange={(e) =>
                                          changePermission(
                                            data._id,
                                            "private",
                                            e
                                          )
                                        }
                                        color="primary"
                                        inputProps={{
                                          "aria-label": "secondary checkbox",
                                        }}
                                      />
                                      {data.giveActionData.private} */}
                                       <Radio
                                    checked={data.giveActionData.both}
                                    onChange={(e) =>
                                      changePermission(
                                        data._id,
                                        "both",
                                        e
                                      )
                                    }
                                    // value={data.autoTemplate}
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "A" }}
                                  />
                                      <label for="html-12"> Both</label>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end w-100 footer-btn flex-wrap mt-4">
                  <button
                    type="button"
                    onClick={() => {
                      AddTemplatePlan();
                    }}
                    className="btn btn-primary  me-2 rounded-0"
                  >
                    Save
                  </button>
                  {/* <button type="button" className="btn btn-outline rounded-0 ">
                    Cancel
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
