import React from 'react';
import { Modal } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import Videoplayer from '../../modules/components/Videoplayer/Videoplayer';

export default function VideoPreview(props) {
  return (
    <Modal
      centered
      size="xl"
      backdrop="static"
      // className="view-modal"
      show={props.openVideoPreview}
    >
      <Modal.Body>
        <div id="view-img" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="d-flex view-header">
            <h3>Video Preview</h3>
            <Tooltip title="Close" arrow>
              <div className="close-priview" onClick={() => props.closePreview()}>
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="close">
                      <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
                      <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                    </g>
                  </g>
                </svg>
              </div>
            </Tooltip>
          </div>

          <Videoplayer thumbnailUrl="" videoUrl={props.selectedVideo} showVolume={false} />
        </div>
      </Modal.Body>
    </Modal>
  );
}
