import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { bindActionCreators } from "redux";
import { projectScreenPreview} from "../../../services/api.service";
import { getProjectById, UpdateDbProjectDatabyId } from "../../../services/authService";
import { getUserItem, showToaster } from "../../../services/helper";
import { actionCreator } from "../../../store";
import Tooltip from "@mui/material/Tooltip";
import { Checkbox } from "@mui/material";
import ImageComponent from "../../components/ImageComponent";
import TemplateRepo from "./TemplateRepo";

function ListModules(props) {
  const project = useSelector((state) => state.preview.previewProject);
  const projectId = useSelector((state) => state.preview.ProjectId);
  const ActiveModule = useSelector((state) => state.preview.ActiveModuleId);
  const ActiveSlide = useSelector((state) => state.preview.ActiveSlideId);
  const ActiveScreen = useSelector((state) => state.preview.ActiveScreenId);
  const ScreenPreviews = useSelector((state) => state.preview.ScreenPreviews);
  const [renderCheckCount, setRenderCheckCount] = useState(0);
  const [loaded, setLoaded] = useState(false);
  let Toggleleft = useSelector((state) => state.project.toggleleftscreen);
  const dispatch = useDispatch();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [thumbnailRequested, setthumbnailRequested] = useState(false);
  const [projectData, setProjectData] = useState(undefined);
  const [TemplateUpdate1, setTemplateUpdate1] = useState(false);
  const [templateMode1, setTemplateMode1] = useState(false);
  const [templateCheckArray, setTemplateCheckArray] = useState(undefined);
  const [refreshEnable, setRefreshEnable] = useState(false);
  const [refreshArray, setRefreshArray] = useState(undefined);
  const [templateManualCheckArray, setTemplateManualCheckArray] = useState(undefined);
 
  let { SelectActivePreviewScreen, SelectActivePreviewSlide, SelectActivePreviewModule, UpdateThumbnail, UpdatePreview, DeleteScreenPreview } =
    bindActionCreators(actionCreator, dispatch);
  const { id } = useParams();
  const history = useHistory();
  const timer1Ref = useRef(null);
  const timer2Ref = useRef(null);

  useEffect(() => {
    if (props.projectData !== undefined) {
      setProjectData(props.projectData);
    }
  }, [props.projectData]);

  useEffect(() => {
    setTemplateManualCheckArray(props.TemplateCheckArray);
  }, [props.TemplateCheckArray]);


  useEffect(() => {
    if (projectData && ScreenPreviews) {
      setRefreshEnable(false);
      const projectKeys = Object.keys(projectData);
      for (const projectId of projectKeys) {
        if (ScreenPreviews[projectId]) {
          const previewData = ScreenPreviews[projectId];
          const previewDataKeys = Object.keys(previewData);
          let newProjectData = JSON.parse(JSON.stringify(projectData));
          let keysToDelete = [];
          for (const dkey of previewDataKeys) {
            const { moduleId, slideId, screenId } = JSON.parse(dkey);
            const mediaurl = previewData[dkey];
            if (
              newProjectData[projectId] &&
              newProjectData[projectId].Modules[moduleId] &&
              newProjectData[projectId].Modules[moduleId].slides[slideId] &&
              newProjectData[projectId].Modules[moduleId].slides[slideId].Screens[screenId] &&
              mediaurl &&
              mediaurl["ThumbnailUrl"]
            ) {
              if (moduleId === "M01" && slideId === "SL01" && screenId === "SC01.01") {
                props.setRenderThumnails((arr) => [...arr, mediaurl["ThumbnailUrl"]]);
              }

              newProjectData[projectId].Modules[moduleId].slides[slideId].Screens[screenId].mediaurl = { ...mediaurl };
              keysToDelete.push(dkey);
            }
          }
          if (keysToDelete.length) {
            UpdatePreview(newProjectData);
            setProjectData(newProjectData);
            DeleteScreenPreview({ projectId, keysToDelete });
          }
        }
      }
    }
    setTimeout(() => {
      setRefreshArray(undefined);
      let screens = [];
      // let newProjectData = JSON.parse(projectData);
      let data = projectData;
      if (data != null) {
        if (data[projectId] != null) {
          if (data[projectId].Modules != undefined) {
            Object.keys(data[projectId].Modules).map((module) => {
              Object.keys(data[projectId].Modules[module].slides).map((slide) => {
                Object.keys(data[projectId].Modules[module].slides[slide].Screens).map(async (item, i) => {
                  if (data[projectId].Modules[module].slides[slide].Screens[item]["mediaurl"] == undefined) {
                    let currentScreen = {
                      moduleName: module,
                      slideName: slide,
                      screenName: item
                    };
                    screens.push(currentScreen);
                    setRefreshEnable(true);
                    // alert("hai")
                  } else {
                    setRefreshEnable(false);
                  }
                });
              });
            });
            // setthumbnailRequested(true)
          }
        }
      }
      setRefreshArray(screens);
    }, 600000);
  }, [projectData, ScreenPreviews]);

  // clear timer on component unmount
  useEffect(() => {
    return () => {
      clearTimeout(timer1Ref.current);
      clearTimeout(timer2Ref.current);
    };
  }, []);

  // useEffect(async () => {
  //   setLoaded(true);
  //   if (id != null && id != "" && id != undefined) {
  //     // if (project[projectId].Modules != undefined) {
  //     setLoaded(false);
  //     // if (!thumbnailRequested) {
  //     let a = await getProjectById(id);
  //     if (a.data.data.length > 0) {
  //       if(projectData==undefined){
  //         setProjectData(JSON.parse(a.data.data[0].ProjectData));
  //       }
  //       fetchThumbnail(JSON.parse(a.data.data[0].ProjectData));
  //     } else {
  //     }
  //   } else {
  //     showToaster("Invalid parameter", "warning");
  //     history.goBack();
  //     setLoaded(false);
  //   }
  // }, [templateMode1]);

  useEffect(async () => {
    if (id != null && id != "" && id != undefined) {
      // if (project[projectId].Modules != undefined) {
      setLoaded(false);
      // if (!thumbnailRequested) {
      let a = await getProjectById(id);
      if (a.data.data.length > 0) {
        let slideRenderData = JSON.parse(a.data.data[0].ProjectData);
        if (slideRenderData[id] != null) {
          if (slideRenderData[id].Modules != undefined) {
            Object.keys(slideRenderData[id].Modules).map((module) => {
              Object.keys(slideRenderData[id].Modules[module].slides).map((slide) => {
                slideRenderData[id].Modules[module].slides[slide].SlideRender = "0";
              });
            });
            let projectData1 = JSON.stringify(slideRenderData);
            setProjectData(JSON.parse(projectData1));
            fetchThumbnail(JSON.parse(projectData1));
          }
        }
        let templateValidArray = [];
        let projectData1 = JSON.parse(a.data.data[0].ProjectData);
        let projectDatas = projectData1[projectId].Modules;

        Object.entries(projectDatas).map((moduleData, moduleIndex) => {
          let mData = moduleData[1];
          let slides = mData.slides;
          Object.entries(slides).map((slideData, moduleIndex) => {
            let slide = slideData[1];
            let screens = slide.Screens;
            Object.entries(screens).map((screenData, moduleIndex) => {
              let slide = screenData[1];
              // item.map((element, index) => {
              Object.entries(slide).map((item, key) => {
                let activeTemplate = item.filter(function (data) {
                  return data.Contenttype === "TemplateID";
                });
                if (activeTemplate.length > 0) {
                  let isValid;
                  if (activeTemplate[0].Contentparams.contentdescription !== "no template") {
                    isValid = true;
                  } else {
                    isValid = false;
                  }
                  // }
                  let templateValid = {
                    isValid: isValid,
                    templateKeys: moduleData[0],
                    templateSlidekeys: slideData[0],
                    templateScreenkeys: screenData[0],
                    screenName: moduleData[1].ModuleName + "-" + slideData[1].SlideName + "-" + screenData[1].ScreenName
                  };
                  templateValidArray.push(templateValid);
                }
              });
            });
          });
        });
        setTemplateCheckArray(templateValidArray);
      } else {
      }

      // }
      //   }
      if (props.templateSelection.manualTemplate === true) {
        if (props.templateType === "templateSelection") {
          setTemplateMode1(true);
        } else {
          setTemplateMode1(false);
        }
      }
    } else {
      showToaster("Invalid parameter", "warning");
      history.goBack();
      setLoaded(false);
    }
  }, []);

  const fetchThumbnailtest = () => {
    if (project !== undefined) {
      if (project[projectId] != null) {
        if (project[projectId].Modules != undefined) {
          Object.keys(project[projectId].Modules).map((module) => {
            Object.keys(project[projectId].Modules[module].slides).map((slide) => {
              Object.keys(project[projectId].Modules[module].slides[slide].Screens).map(async (item, i) => {
                let template = project[projectId].Modules[module].slides[slide].Screens[item]["TM01"];
                if (
                  template.Contentparams.contentdescription !== undefined &&
                  template.Contentparams.contentdescription !== null &&
                  template.Contentparams.contentdescription !== ""
                ) {
                  let SlideAudioVO =
                    project[projectId].Modules[module].slides[slide]["SlideAudioVO"] !== undefined
                      ? project[projectId].Modules[module].slides[slide]["SlideAudioVO"]
                      : "Null";

                  let activeScreenDetails = {
                    ScreenName: project[projectId].Modules[module].slides[slide].Screens[item]["ScreenName"],
                    ...project[projectId].Modules[module].slides[slide].Screens[item]
                  };

                  let screenContent = {
                    [projectId]: {
                      ProjectName: project[projectId].ProjectName,
                      Modules: {
                        [module]: {
                          ModuleName: project[projectId].Modules[module]["ModuleName"],
                          slides: {
                            [slide]: {
                              SlideName: project[projectId].Modules[module].slides[slide]["SlideName"],
                              SlideAudioVO: SlideAudioVO,
                              Screens: {
                                [item]: activeScreenDetails
                              }
                            }
                          }
                        }
                      }
                    }
                  };

                  setTimeout(async () => {
                    UpdateThumbnail(module, slide, item, "../../../assets/img/demo-img.png");

                    let response = await projectScreenPreview(JSON.stringify(screenContent));
                    if (
                      response !== "" &&
                      response !== undefined &&
                      response !== null &&
                      response.data !== "" &&
                      response.data !== undefined &&
                      response.data !== null
                    ) {
                      if (response.data.mediaurl !== undefined && response.data.mediaurl !== null && response.data.mediaurl !== "") {
                        if (response.data.mediaurl["ThumbnailUrl"]) {
                          if (i === 0) {
                            props.setRenderThumnails((arr) => [...arr, response.data.mediaurl["ThumbnailUrl"]]);
                          }

                          UpdateThumbnail(module, slide, item, response.data.mediaurl["ThumbnailUrl"]);
                        }
                      }
                    }
                  }, i * 1000);
                }
              });
            });
          });
          setthumbnailRequested(true);
        }
      }
    }
  };

  const fetchThumbnail = (data) => {
    let screens = [];
    if (data !== undefined) {
      if (data[id] != null) {
        if (data[id].Modules != undefined) {
          Object.keys(data[id].Modules).map((module) => {
            Object.keys(data[id].Modules[module].slides).map((slide) => {
              Object.keys(data[id].Modules[module].slides[slide].Screens).map(async (item, i) => {
                if (
                  data[id].Modules[module].slides[slide].Screens[item]["mediaurl"] != undefined &&
                  data[id].Modules[module].slides[slide].Screens[item]["mediaurl"] != null &&
                  data[id].Modules[module].slides[slide].Screens[item]["mediaurl"] != ""
                ) {
                  let currentScreen = {
                    moduleName: module,
                    slideName: slide,
                    screenName: item,
                    mediaurl: {
                      ...data[id].Modules[module].slides[slide].Screens[item]["mediaurl"]
                    }
                  };
                  screens.push(currentScreen);
                } else {
                  let template = data[id].Modules[module].slides[slide].Screens[item]["TM01"];
                  if (
                    template.Contentparams.contentdescription !== undefined &&
                    template.Contentparams.contentdescription !== null &&
                    template.Contentparams.contentdescription !== ""
                  ) {
                    let currentScreen = {
                      moduleName: module,
                      slideName: slide,
                      screenName: item
                    };
                    screens.push(currentScreen);
                  }
                }
              });
            });
          });
          // setthumbnailRequested(true)
        }
        setLoaded(true);
        screens.sort((a, b) => {
          if (!a.mediaurl && b.mediaurl) {
            return -1;
          }
          if (a.mediaurl && !b.mediaurl) {
            return 1;
          }
          return 0;
        });
        let start = 0;
        let end = 0;
        let limit = 5;
        const individualTimeout = 5000;
        const groupTimeOut = 10000;

        if (screens.length !== 0) {
          (async () => {
            let _screens = screens.slice(start, end + limit);
            do {
              end = end + limit;
              start = end;
              let previews = await getPreviews(_screens, data);
              if (previews) {
                // alert("completed")
              }
              _screens = screens.slice(start, end + limit);
            } while (screens.length - _screens.length > 0);
          })();
        }

        props.setReload(false);
      }
    }
  };

  const refreshProjectPreview = async (mod, slide, screen) => {
    let data = projectData;
    if (data !== undefined) {
      let SlideAudioVO =
        data[id].Modules[mod].slides[slide]["SlideAudioVO"] !== undefined ? data[id].Modules[mod].slides[slide]["SlideAudioVO"] : "Null";
      let activeScreenDetails = {
        ScreenName: data[id].Modules[mod].slides[slide].Screens[screen]["ScreenName"],
        ...data[id].Modules[mod].slides[slide].Screens[screen]
      };

      let screendata = {
        [id]: {
          ProjectName: data[id].ProjectName,
          Modules: {
            [mod]: {
              ModuleName: data[id].Modules[mod]["ModuleName"],
              slides: {
                [slide]: {
                  SlideName: data[id].Modules[mod].slides[slide]["SlideName"],
                  SlideAudioVO: SlideAudioVO,
                  SlideRender: data[id].Modules[mod].slides[slide]["SlideRender"],
                  Screens: {
                    [screen]: activeScreenDetails
                  }
                }
              }
            }
          }
        }
      };
      let response = await projectScreenPreview(JSON.stringify(screendata));
      if (
        response !== "" &&
        response !== undefined &&
        response !== null &&
        response.data !== "" &&
        response.data !== undefined &&
        response.data !== null
      ) {
        if (response.data.mediaurl !== undefined && response.data.mediaurl !== null && response.data.mediaurl !== "") {
          if (response.data.mediaurl["ThumbnailUrl"]) {
            // if (index === 0) {
            //   props.setRenderThumnails(arr => [...arr, response.data.mediaurl['ThumbnailUrl']])

            let updatedprojectData = await UpdateProjectDatabyId(data, mod, slide, screen, response.data.mediaurl);
            if (updatedprojectData != undefined && updatedprojectData != null && updatedprojectData != "") {
              let updatedDb = await UpdateDbProjectDatabyId(id, updatedprojectData);
              if (updatedDb) {
                if (updatedDb.data.data != undefined) {
                  UpdatePreview(JSON.parse(updatedDb.data.data.ProjectData));
                  setProjectData(JSON.parse(updatedDb.data.data.ProjectData));
                  setRefreshArray(undefined);
                }
              }
            }
            // UpdateThumbnail(screen.moduleName, screen.slideName, screen.screenName, response.data.mediaurl["ThumbnailUrl"])
          }
        }
      }
    }
  };

  const getPreviews = (_screens, data) => {
    return new Promise((resolve) => {
      let count = 0;
      if (data !== undefined) {
        let promises = _screens.map((screen, index) => {
          let SlideAudioVO =
            data[id].Modules[screen.moduleName].slides[screen.slideName]["SlideAudioVO"] !== undefined
              ? data[id].Modules[screen.moduleName].slides[screen.slideName]["SlideAudioVO"]
              : "Null";
          let activeScreenDetails = {
            ScreenName: data[id].Modules[screen.moduleName].slides[screen.slideName].Screens[screen.screenName]["ScreenName"],
            ...data[id].Modules[screen.moduleName].slides[screen.slideName].Screens[screen.screenName]
          };

          let screendata = {
            [id]: {
              ProjectName: data[id].ProjectName,
              Modules: {
                [screen.moduleName]: {
                  ModuleName: data[id].Modules[screen.moduleName]["ModuleName"],
                  slides: {
                    [screen.slideName]: {
                      SlideName: data[id].Modules[screen.moduleName].slides[screen.slideName]["SlideName"],
                      SlideAudioVO: SlideAudioVO,
                      SlideRender: data[id].Modules[screen.moduleName].slides[screen.slideName]["SlideRender"],
                      Screens: {
                        [screen.screenName]: activeScreenDetails
                      }
                    }
                  }
                }
              }
            }
          };
          timer1Ref.current = setTimeout(async () => {
            UpdateThumbnail(screen.moduleName, screen.slideName, screen.screenName, "../../../assets/img/demo-img.png");
            if (screen.mediaurl) {
              count++;
              if (screen.mediaurl["ThumbnailUrl"]) {
                if (index === 0) {
                  props.setRenderThumnails((arr) => [...arr, screen.mediaurl["ThumbnailUrl"]]);
                }
                let updatedprojectData = await UpdateProjectDatabyId(data, screen.moduleName, screen.slideName, screen.screenName, screen.mediaurl);
                if (updatedprojectData != undefined && updatedprojectData != null && updatedprojectData != "") {
                  UpdatePreview(updatedprojectData);
                  setProjectData(updatedprojectData);
                }
              }
              if (_screens.length == count) {
                resolve(true);
              }
            } else {
              if (props.templateSelection.manualTemplate !== true) {
                timer2Ref.current = setTimeout(async () => {
                  let response = await projectScreenPreview(JSON.stringify(screendata));
                  console.log(response, 'response from list modules ===>');
                  if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null &&
                    response.data !== "" &&
                    response.data !== undefined &&
                    response.data !== null
                  ) {
                    count++;
                    if (response.data.mediaurl !== undefined && response.data.mediaurl !== null && response.data.mediaurl !== "") {
                      if (response.data.mediaurl["ThumbnailUrl"]) {
                        if (index === 0) {
                          props.setRenderThumnails((arr) => [...arr, response.data.mediaurl["ThumbnailUrl"]]);
                        }
                        let updatedprojectData = await UpdateProjectDatabyId(
                          data,
                          screen.moduleName,
                          screen.slideName,
                          screen.screenName,
                          response.data.mediaurl
                        );
                        if (updatedprojectData != undefined && updatedprojectData != null && updatedprojectData != "") {
                          let updatedDb = await UpdateDbProjectDatabyId(id, updatedprojectData);
                          if (updatedDb) {
                            if (updatedDb.data.data != undefined) {
                              UpdatePreview(JSON.parse(updatedDb.data.data.ProjectData));
                              setProjectData(JSON.parse(updatedDb.data.data.ProjectData));
                            }
                          }
                        }
                      }
                    }
                  }
                  if (_screens.length == count) {
                    resolve(true);
                  }
                }, index * 10000);
              }
            }
          }, 1000);
        });
      }
    });
  };

  const HandleSelectedScreen = (screenId, screenName) => {
    setPageLoaded(true);
    SelectActivePreviewScreen({
      ActiveScreen: screenId,
      ScreenName: screenName
    });
  };

  const HandleSelectedSlide = (slideId, slideName) => {
    SelectActivePreviewSlide({
      ActiveSlide: slideId,
      SlideName: slideName
    });
  };

  const changePermission = (slideId, moduleId, e) => {
    let slideRenderData = projectData;
    let slideRenderArray = [];
    let secretkey = "secretkey";
    if (projectData != undefined) {
      if (slideRenderData[id] != null) {
        if (slideRenderData[id].Modules != undefined) {
          let c = 0;
          Object.keys(slideRenderData[id].Modules).map((module) => {
            Object.keys(slideRenderData[id].Modules[module].slides).map((slide) => {
              if (slideId === slide && moduleId === module) {
                if (e.target.checked === true) {
                  slideRenderData[id].Modules[module].slides[slide].SlideRender = "1";
                  props?.setCheckedSlides([...props?.checkedSlides,{moduleName: moduleId, slideName:slideId}])
                  slideRenderArray.push(slide);
                } else {
                  slideRenderData[id].Modules[module].slides[slide].SlideRender = "0";
                  props?.setCheckedSlides( props?.checkedSlides.filter((slides) => slides?.moduleName !== module || slides?.slideName !== slide))
                }
              }
              if (moduleId == module && moduleId === module) {
                if (slideRenderData[id].Modules[module].slides[slide].SlideRender === "1") {
                  c++;
                }
              }
            });
          });
          setRenderCheckCount(c++);
          let projectData1 = JSON.stringify(slideRenderData);
          setProjectData(JSON.parse(projectData1));
          UpdatePreview(JSON.parse(projectData1));
          return slideRenderData;
        }
      }
    }
  };

  const HandleSelectedModule = (moduleId, moduleName) => {
    SelectActivePreviewModule({
      ActiveModule: moduleId,
      ModuleName: moduleName
    });
  };

  useEffect(() => {
    if (props.reload) {
      const runFn = async () => {
        try {
          let filter = {
            project_id: projectId
          };
          let a = await getProjectById(id);
          if (a.data.data.length > 0) {
            if (a.data.data[0].ProjectData !== undefined) {
              setProjectData(JSON.parse(a.data.data[0].ProjectData));
              fetchThumbnail(JSON.parse(a.data.data[0].ProjectData));
              let templateValidArray = [];
              let projectData1 = JSON.parse(a.data.data[0].ProjectData);
              let projectDatas = projectData1[projectId].Modules;
              Object.entries(projectDatas).map((moduleData, moduleIndex) => {
                let mData = moduleData[1];
                let slides = mData.slides;
                Object.entries(slides).map((slideData, moduleIndex) => {
                  let slide = slideData[1];
                  let screens = slide.Screens;
                  Object.entries(screens).map((screenData, moduleIndex) => {
                    let slide = screenData[1];
                    Object.entries(slide).map((item, key) => {
                      let activeTemplate = item.filter(function (data) {
                        return data.Contenttype === "TemplateID";
                      });
                      if (activeTemplate.length > 0) {
                        let isValid;
                        if (activeTemplate[0].Contentparams.contentdescription !== "no template") {
                          isValid = true;
                        } else {
                          isValid = false;
                        }
                        // }
                        let templateValid = {
                          isValid: isValid,
                          templateKeys: moduleData[0],
                          templateSlidekeys: slideData[0],
                          templateScreenkeys: screenData[0],
                          screenName: moduleData[1].ModuleName + "-" + slideData[1].SlideName + "-" + screenData[1].ScreenName
                        };
                        templateValidArray.push(templateValid);
                      }
                    });
                  });
                });
              });
              setTemplateManualCheckArray(templateValidArray);
              setTemplateCheckArray(templateValidArray);
            } else {
            }
          }
        } catch (e) {
          console.log(e);
        }
      };
      runFn();
    }
  }, [props.reload]);

  useEffect(() => {
    setPageLoaded(false);
  }, [project]);

  const UpdateProjectDatabyId = (projectdata, modulename, slidename, screenname, mediaurl) => {
    return new Promise((resolve, reject) => {
      if (projectdata != undefined) {
        if (projectdata[id] != null) {
          if (projectdata[id].Modules != undefined) {
            Object.keys(projectdata[id].Modules).map((module) => {
              if (module == modulename) {
                Object.keys(projectdata[id].Modules[module].slides).map((slide) => {
                  if (slide == slidename) {
                    Object.keys(projectdata[id].Modules[module].slides[slide].Screens).map(async (item, i) => {
                      if (item == screenname) {
                        projectdata[id].Modules[module].slides[slide].Screens[item].mediaurl = mediaurl;
                      }
                    });
                  }
                });
              }
            });
          }
        }
      }
      resolve(projectdata);
    });
  };

  return (
    <>
      <pageLoader showLoader={loaded} />
      <aside className={Toggleleft ? "is-visible" : ""}>
        <div className="module-wrap">
          <div className=" tab-view">
            <div className="tab-content">
              <div id="accordion" className="accordion">
                <div className="card">
                  {loaded &&
                    projectData != undefined &&
                    Object.entries(projectData[id].Modules).map((module) => {
                      let moduleValidation1 = 0;

                      let moduleValidation = false;
                      if (props.templateSelection.manualTemplate !== true) {
                        if (templateManualCheckArray !== undefined) {
                          templateManualCheckArray.map((tempArray) => {
                            if (module[0] === tempArray.templateKeys) {
                              if (tempArray.isValid === false) {
                                moduleValidation = true;
                              }
                            }
                          });
                        }
                      } else {
                        if (templateCheckArray !== undefined) {
                          templateCheckArray.map((tempArray) => {
                            if (module[0] === tempArray.templateKeys) {
                              if (tempArray.isValid === false) {
                                moduleValidation = true;
                              }
                            }
                          });
                        }
                      }

                      Object.entries(module[1].slides).map((slide, i) => {
                        if (slide[1].SlideRender == "1" && !moduleValidation) {
                          moduleValidation1++;
                        }
                      });
                      return (
                        <div>
                          <div className="card-header" id="headingOne">
                            <h5 className="mb-0 d-inline">
                              <a
                                className="btn btn-link "
                                data-bs-toggle="collapse"
                                data-bs-target={`#${module[0]}`}
                                aria-expanded={ActiveModule == module[0] ? "true" : "false"}
                                aria-controls="collapseOne"
                                onClick={() => {
                                  HandleSelectedModule(module[0], module[1].ModuleName);
                                }}
                              >
                                {moduleValidation1 > 0 && <span className="count-number">{moduleValidation1 > 0 && moduleValidation1}</span>}{" "}
                                {module[1].ModuleName}{" "}
                                {moduleValidation ? (
                                  <span className="warning-btns uncheck-btn-sm">
                                    <svg
                                      version="1.1"
                                      id="Capa_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xlink="http://www.w3.org/1999/xlink"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 106.031 106.031"
                                      space="preserve"
                                    >
                                      <g>
                                        <path
                                          d="M53.015,76.703c-5.815,0-10.528-4.712-10.528-10.528V10.528C42.487,4.714,47.201,0,53.015,0
                                                              c5.813,0,10.528,4.714,10.528,10.528v55.647C63.544,71.991,58.83,76.703,53.015,76.703z"
                                        />
                                        <circle cx="52.34" cy="97.007" r="9.024" />
                                      </g>
                                    </svg>
                                  </span>
                                ) : (
                                  <span className="warning-btns check-btn-sm">
                                    <svg viewBox="0 0 24 24" fill="" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z"
                                        fill=""
                                      ></path>
                                    </svg>
                                  </span>
                                )}
                              </a>
                            </h5>
                          </div>

                          <div
                            id={module[0]}
                            className={ActiveModule == module[0] ? "collapse show" : "collapse"}
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordion"
                          >
                            <div className="card-body" id="child1">
                              <div className="card">
                                {Object.entries(module[1].slides).map((slide, i) => {
                                  let slideValidation = false;
                                  if (props.templateSelection.manualTemplate !== true) {
                                    if (templateManualCheckArray !== undefined) {
                                      templateManualCheckArray.map((tempArray) => {
                                        if (module[0] === tempArray.templateKeys && slide[0] === tempArray.templateSlidekeys) {
                                          if (tempArray.isValid === false) {
                                            slideValidation = true;
                                          }
                                        }
                                      });
                                    }
                                  } else {
                                    if (templateCheckArray !== undefined) {
                                      templateCheckArray.map((tempArray) => {
                                        if (module[0] === tempArray.templateKeys && slide[0] === tempArray.templateSlidekeys) {
                                          if (tempArray.isValid === false) {
                                            slideValidation = true;
                                          }
                                        }
                                      });
                                    }
                                  }

                                  return (
                                    <div>
                                      <div className="usage-head-label text-center "></div>
                                      <div className="card-header">
                                        <div className="slide-select">
                                          <Checkbox
                                            disabled={slideValidation ? true : false}
                                            checked={slide[1].SlideRender === "1" && !slideValidation ? true : false}
                                            onChange={(e) => changePermission(slide[0], module[0], e)}
                                            // color="primary"
                                            inputProps={{
                                              "aria-label": "secondary checkbox"
                                            }}
                                            sx={{
                                              color: "#2E2E2E",
                                              "&.Mui-checked": {
                                                color: "#468BE8"
                                              }
                                            }}
                                          />
                                          {/* <label for="html1"></label> */}
                                        </div>
                                        <a
                                          className={slide[1].SlideRender === "1" ? "selected" : ""}
                                          data-bs-toggle="collapse"
                                          aria-expanded={ActiveSlide == slide[0] ? "true" : "false"}
                                          data-bs-target={`#${slide[0]}`}
                                          onClick={() => {
                                            HandleSelectedSlide(slide[0], slide[1].SlideName);
                                          }}
                                         
                                        >
                                          {" "}
                                          <strong>
                                            {/* {i + 1 > 9
                                            ? `${i + 1}`
                                            : `0${i + 1}`} */}
                                            {}
                                          </strong>{" "}
                                          <p style={{display:'inline', marginLeft:14}}>{slide[1].SlideName}</p>
                                          {slideValidation ? (
                                            <span className="warning-btns uncheck-btn-sm">
                                              <svg
                                                version="1.1"
                                                id="Capa_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 106.031 106.031"
                                                space="preserve"
                                              >
                                                <g>
                                                  <path
                                                    d="M53.015,76.703c-5.815,0-10.528-4.712-10.528-10.528V10.528C42.487,4.714,47.201,0,53.015,0
                                                              c5.813,0,10.528,4.714,10.528,10.528v55.647C63.544,71.991,58.83,76.703,53.015,76.703z"
                                                  />
                                                  <circle cx="52.34" cy="97.007" r="9.024" />
                                                </g>
                                              </svg>
                                            </span>
                                          ) : (
                                            <span className="warning-btns check-btn-sm">
                                              <svg viewBox="0 0 24 24" fill="" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z"
                                                  fill=""
                                                ></path>
                                              </svg>
                                            </span>
                                          )}
                                        </a>
                                      </div>
                                      <div
                                        className={slide[0] == ActiveSlide ? "collapse show" : "collapse"}
                                        data-bs-parent="#child1"
                                        id={`${slide[0]}`}
                                      >
                                        <div className="slide-wrap">
                                          <ul className="slide-inner">
                                            {Object.entries(slide[1].Screens).map((screen, i) => {
                                              let screenValidation = false;
                                              if (props.templateSelection.manualTemplate !== true) {
                                                if (templateManualCheckArray !== undefined) {
                                                  templateManualCheckArray.map((tempArray) => {
                                                    if (
                                                      module[0] === tempArray.templateKeys &&
                                                      slide[0] === tempArray.templateSlidekeys &&
                                                      screen[0] === tempArray.templateScreenkeys
                                                    ) {
                                                      if (tempArray.isValid === false) {
                                                        screenValidation = true;
                                                      }
                                                    }
                                                  });
                                                }
                                              } else {
                                                if (templateCheckArray !== undefined) {
                                                  templateCheckArray.map((tempArray) => {
                                                    if (
                                                      module[0] === tempArray.templateKeys &&
                                                      slide[0] === tempArray.templateSlidekeys &&
                                                      screen[0] === tempArray.templateScreenkeys
                                                    ) {
                                                      if (tempArray.isValid === false) {
                                                        screenValidation = true;
                                                      }
                                                    }
                                                  });
                                                }
                                              }

                                              return (
                                                <li className={screen[0] == ActiveScreen && "active"}>
                                                  <div
                                                    className="slide-list"
                                                    style={{
                                                      position: "relative"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        position: "absolute",
                                                        top: "3px",
                                                        left: "18px",
                                                        color: "blue",
                                                        zIndex: "99",
                                                        fontSize: "small"
                                                      }}
                                                    >
                                                      {screen[1]["TM01"] &&
                                                        screen[1]["TM01"]["Contentparams"] &&
                                                        screen[1]["TM01"]["Contentparams"]["contentdescription"] &&
                                                        screen[1]["TM01"]["Contentparams"]["contentdescription"].split("/").pop()}
                                                    </span>
                                                    {refreshArray !== undefined &&
                                                      refreshArray.map((refreshArray) => {
                                                        if (
                                                          module[0] === refreshArray.moduleName &&
                                                          slide[0] === refreshArray.slideName &&
                                                          screen[0] === refreshArray.screenName
                                                        ) {
                                                          return (
                                                            <Tooltip title="Refresh" arrow>
                                                              <div
                                                                className="refresh-button"
                                                                onClick={() => refreshProjectPreview(module[0], slide[0], screen[0])}
                                                              >
                                                                <svg
                                                                  className=""
                                                                  version="1.1"
                                                                  id="Capa_1"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  xlink="http://www.w3.org/1999/xlink"
                                                                  x="0px"
                                                                  y="0px"
                                                                  width="15"
                                                                  height="18"
                                                                  viewBox="0 0 592.99 592.99"
                                                                  style={{
                                                                    enableBackground: "new 0 0 592.99 592.99;"
                                                                  }}
                                                                  space="preserve"
                                                                >
                                                                  <g>
                                                                    <g>
                                                                      <path
                                                                        d="M274.292,21.879C122.868,21.879,0,145.072,0,296.496C0,447.92,122.262,571.111,275.262,571.111v-91.799
                                                          c-100.98,0-183.462-82.012-183.462-182.816c0-100.806,81.362-182.817,182.168-182.817c98.753,0,179.413,78.718,182.661,176.696
                                                          h-45.236l90.799,127.541l90.799-127.541h-44.486C545.248,141.767,423.67,21.879,274.292,21.879z"
                                                                      />
                                                                    </g>
                                                                  </g>
                                                                </svg>
                                                              </div>
                                                            </Tooltip>
                                                          );
                                                        }
                                                      })}

                                                    <div
                                                      className={screen[1]["mediaurl"] != undefined ? "img-wrap" : "img-wrap border-orange"}
                                                      style={{
                                                        cursor: screen[1]["mediaurl"] !== undefined ? "pointer" : "not-allowed"
                                                      }}
                                                      onClick={() => {
                                                        screen[1]["mediaurl"] !== undefined && HandleSelectedScreen(screen[0], screen[1].ScreenName);
                                                      }}
                                                    >
                                                      {screen[1]["mediaurl"] == undefined &&
                                                        screen[1]["TM01"]["Contentparams"]["contentdescription"] !== "no template" && (
                                                          <p className="preview-generate">Preview is generating...</p>
                                                        )}

                                                      {screenValidation && (
                                                        <span className="warning-btns uncheck-btn-sm">
                                                          <svg
                                                            version="1.1"
                                                            id="Capa_1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xlink="http://www.w3.org/1999/xlink"
                                                            x="0px"
                                                            y="0px"
                                                            viewBox="0 0 106.031 106.031"
                                                            space="preserve"
                                                          >
                                                            <g>
                                                              <path
                                                                d="M53.015,76.703c-5.815,0-10.528-4.712-10.528-10.528V10.528C42.487,4.714,47.201,0,53.015,0
                                                              c5.813,0,10.528,4.714,10.528,10.528v55.647C63.544,71.991,58.83,76.703,53.015,76.703z"
                                                              />
                                                              <circle cx="52.34" cy="97.007" r="9.024" />
                                                            </g>
                                                          </svg>
                                                        </span>
                                                      )}
                                                      {}

                                                      {ActiveModule == module[0] && slide[0] == ActiveSlide ? (
                                                        <ImageComponent
                                                          src={screen[1]["mediaurl"] != undefined ? `${screen[1]["mediaurl"].ThumbnailUrl}` : ""}
                                                        />
                                                      ) : (
                                                        ""
                                                      )}

                                                      <h3>{screen[0].slice(5)}</h3>
                                                    </div>
                                                  </div>
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
      {templateMode1 && (
        <TemplateRepo
          setTemplateUpdate={setTemplateUpdate1}
          templateMode={templateMode1}
          setTemplateMode1={setTemplateMode1}
          projectId={projectId}
          projectData2={projectData}
          TemplateCheckArray1={setTemplateCheckArray}
          updatedtemplateURldata={setProjectData}
        />
      )}
    </>
  );
}

export default ListModules;
