import React, { useState, useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
// import Cropper from "react-easy-crop";
// import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import getCroppedImg from "./CropFunctions";
import { uploadMediaFile } from "../../../../../services/api.service";
import { showToaster } from "../../../../../services/helper";
import { getUserItem } from "../../../../../services/helper";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../../../../store";
import SimpleBackdrop from "../../../../container/pageLoader";
import PageLoader from "../../../../../services/pageLoader";
import Cropper from "react-easy-crop";
function CropImage(props) {
  const dispatch = useDispatch();
  let { UpdatePreviewFeature } = bindActionCreators(actionCreator, dispatch);

  const [Loader, setLoader] = useState(false);

  let styles = {
    cropContainer: {
      position: "relative",
      width: "100%",
      height: 200,
      background: "#333",
      // [theme.breakpoints.up('sm')]: {
      //   height: 400,
      // },
    },
  };
  const dogImg =
    "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000";
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  // const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropper, setCropper] = useState();
  // const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    setZoom(1);
  }, [props.img]);

  useEffect(() => {
    if (props.cropImageReq) {
      showCroppedImage();
    }
  }, [props.cropImageReq]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    console.log(croppedArea, croppedAreaPixels, "qwerty");
  }, []);

  const urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: "image/jpeg" });
      });
  };

  const UploadCropedImage = async (uploadedFile) => {
    try {
      setLoader(true);
      //console.log(uploadedFile);
      let filename = "IMG_" + Math.floor(Math.random() * 1000000);
      let file = await urltoFile(uploadedFile, filename + ".jpg", "");
      //console.log(file);
      let data = new FormData();
      let header = {
        "content-type": "multipart/form-data",
        media_type: "IMAGE",
        user_id: getUserItem("id"),
        file_name: filename + ".jpeg",
        private: 1,
      };
      const config = {
        timeout: 1000 * 50000,
        headers: header,
      };
      //console.log(uploadedFile);
      data.append("file", file);
      uploadMediaFile(data, config).then((response) => {
        //console.log(response);
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            //console.log(response.data);
            // let asset = props.item[1];
            // asset.Contentparams.contentdescription =
            //   response.data;
            // let imgdata = {
            //   [props.item[0]]: asset,
            // };
            //console.log(imgdata);
            //UpdatePreviewFeature(imgdata);
            //props.closePreview();
            props.setdisplayImage(response.data);
            props.setcropMode(false);
            props.setcropImageReq(false);
            setLoader(false);
          } else if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ""
          ) {
          } else {
            showToaster("We couldn't Recognize the image Updated. Please try again with another image", "warning");
          }
        } catch (error) {
          props.setcropMode(false);
          props.setcropImageReq(false);
          setLoader(false);
          props.setcropMode(false);
          props.setcropImageReq(false);
          setLoader(false);
          showToaster("We couldn't Recognize the image Updated. Please try again with another image", "warning");
        }
      });
    } catch (error) {
      props.setcropMode(false);
      showToaster("We couldn't Recognize the image Updated. Please try again with another image", "warning");
      props.setcropImageReq(false);
      setLoader(false);
    }
  };
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        props.img,
        croppedAreaPixels,
        rotation
      );
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
      UploadCropedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);
  // const showCroppedImage = () => {
  //   try {
  //     const croppedImage = await getCroppedImg(
  //       props.img,
  //       croppedAreaPixels,
  //       rotation
  //     );
  //     if (typeof cropper !== "undefined") {
  //       // setCropData(cropper.getCroppedCanvas().toDataURL());
  //       //console.log("donee", croppedImage);
  //       setCroppedImage(cropper.getCroppedCanvas().toDataURL());
  //       //props.setdisplayImage(croppedImage)
  //       UploadCropedImage(cropper.getCroppedCanvas().toDataURL());
  //     }

  //     //props.setcropMode(false);
  //     //props.setcropImageReq(false);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  return (
    <>
      <Cropper
      // style={{ height: 480, width: "100%" }}
        image={props.img}
        crop={crop}
        zoom={zoom}
        aspect={16 / 9}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />

      {/* <Cropper
        style={{ height: "100%", width: "100%" }}
        zoomTo={0.5}
        initialAspectRatio={4 / 3}
        src={props.img}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
        onInitialized={(instance) => {
          setCropper(instance);
        }}
        guides={true}
      /> */}
      <div className="crop-container1">
        {/* <Cropper
        image={props.img}
        crop={crop}
        zoom={zoom}
        aspect={1 / 1}
        onCropChange={setCrop}
        onRotationChange={setRotation}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        classes={props.className}
      />
      <div>
        <div>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
      </div> */}

        {/* <SimpleBackdrop showBackDrop={Loader}/> */}
      </div>
      <PageLoader showLoader={Loader} />
    </>
  );
}

export default CropImage;
