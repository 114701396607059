import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { showToaster, getUserItem } from '../../../services/helper';
import { nanoid } from 'nanoid';
import { createProjects, getCategory,createProjectsPreview } from '../../../services/api.service';
import { createdProject, updateProjectNameStatus, updateProjectName } from '../../../store/action';
import Header from '../../components/header/header'
import CsvFileUpload from '../../container/uploadcsv/csvUpoad'
import SimpleBackdrop from '../../container/pageLoader';
import PageLoader from "../../../services/pageLoader";
import { customAlphabet } from 'nanoid/async'
import Tooltip from '@mui/material/Tooltip';
import { AddProjects, updateuserProjectRole,getUsersGlobalRole } from '../../../services/authService';
import Select from "react-select";
export default function Createproject() {
  const nanoid = customAlphabet('abcdefghijklmnopqrstuvzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890', 24)
  const history = useHistory();
  const dispatch = useDispatch()
  const [errorStatus, setErrorStatus] = useState();
  const [uploadProgress, setUploadProgress] = useState(false);
  const [checkStatus, setcheckStatus] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [listCategory, setListCategory] = useState(undefined)
  const [category, setCategory] = useState([]);
  let DisplayMode = useSelector(state => state.project.DisplayMode)
  const [inputActive, setInputActive] = useState(false)
  const [addCategoryValue, setAddCategoryValue] = useState(undefined);
  const [fonts, setfonts] = useStateWithCallbackLazy();
  const [categoryEditError, setCategoryEditError] = useState(false)
  const [createProjecteEnable, setCreateProjecteEnable] = useState(false)
  const [keyWord, setKeyWord] = useState("");
  let orgId = getUserItem("orgId");
  let userId = getUserItem('id');
  let AdminRole = getUserItem("role")
  //  const logout = () => {
  //     localStorage.clear();
  //     history.push('/');
  // }
  useEffect(async() => {
    if(AdminRole===1){
     await getuserGlobelRoles()
    }
    GetCategoryList()
    
    //dispatch(updateProjectNameStatus(false)) 
  }, []);
  const getuserGlobelRoles = () => {
    getUsersGlobalRole(userId).then((response) => {
      try {
        setPageLoad(true);
        if (response !== "" && response !== undefined && response !== null && response.data !== "" && response.data !== undefined && response.data !== null) {
          // console.log(projectList, "response");
          // setCategoryList(ddd)
          setPageLoad(false);

          // setTimeout(() => {
          //     setprojectList(projectData2)
          // }, 1000);
          setCreateProjecteEnable(response.data.result[0].addProject)
          if(response.data.result[0].addProject==false){
            history.push("/project-list");
          }
        } else {
          setPageLoad(false);
          showToaster('Something went wrong. Please Try Again1', 'warning');
        }
      } catch (error) {
        console.log(error);

        setPageLoad(false);
        showToaster('Something went wrong. Please Try Again', 'warning');
      }
    })
  }
  const GetCategoryList = () => {
    let body = {
      user_id: AdminRole== 0 ? userId : orgId ,
      list_id: 1
    }
    getCategory(body).then((response) => {
      try {
        if (response !== "" && response !== undefined && response !== null && response.data !== "" && response.data !== undefined && response.data !== null) {
          if (response.data.list != undefined) {
            console.log(response, "response");
            let ddd = response.data.list
            setCategory(ddd)
            if (response.data.list[0] !== undefined && response.data.list[0].category !== undefined) {
              setListCategory({
                value: response.data.list[0].category,
                label: response.data.list[0].category,
                id: response.data.list[0].id
              });
            }

            // setListCatagory(
            //   ddd
            // );
            //  let data1=data.push(response.data.list[(Object.keys(response.data.list)])
          }
        }
        else {

          showToaster('Something went wrong. Please Try Again', 'warning');
        }
      } catch (error) {
        console.log(error);

        showToaster('Something went wrong. Please Try Again', 'warning');
      }
    })
  }
  const formik = useFormik({
    initialValues: {
      courseName: '',
      // categoryname: ''
    },

    validationSchema: Yup.object().shape({
      courseName: Yup.string()
        .trim()
        .required("Course Name is required")
        .max(50, 'Must be exactly 50 characters')
        .min(5, "Minimum 5 characters are required"),
      // categoryname: Yup.string()
      //   .trim()
      //   .required("Category Name is required")
    }),
    onSubmit: async (values) => {

      setUploadProgress(true);
      setPageLoad(true)
      try {
        var projectId = await nanoid()
        // var projectId = nanoid(10)
        //console.log(projectId,"projectId");

        let jsondata = {
          APIData:{[projectId]: {
            ProjectName: values.courseName,
            CategoryID: listCategory.id,
          }},
          CategoryValue:listCategory.value,
          type:"create"
        }
        await createProjectsPreview(JSON.stringify(jsondata)).then((response) => {
          try {
            if (response !== "" && response !== undefined && response !== null && response.data !== "" && response.data !== undefined && response.data !== null) {
              setUploadProgress(false);
              if (response.status === 200 && response.status !== null && response.status !== null) {
                setPageLoad(false)
                showToaster('Project Created successfully', 'success');
                dispatch(updateProjectName(values.courseName))
                //dispatch(updateProjectNameStatus(true))
                dispatch(createdProject({
                  projectID: projectId,
                  projectDetails: jsondata
                }))
                let roleArray = [{
                  editProject: true,
                  viewProject: true,
                  deleteProject: true,
                  projectId: projectId,
                  action:"createUser"
                }]
                history.push(`/edit-project/${projectId}`);
                let orgid=orgId==""?userId:orgId
                updateuserProjectRole(userId, orgid, roleArray).then((response) => {
                  try {
                    if (response !== "" && response !== undefined && response !== null && response.data !== "" && response.data !== undefined && response.data !== null) {
                      setPageLoad(false);
                      if (response.data.result != undefined) {
                      
                      }
                    } else {
                      setPageLoad(false);
                      showToaster('Something went wrong. Please Try Again', 'warning');
                    }
                  } catch (error) {
                    setPageLoad(false);
                    showToaster('Something went wrong. Please Try Again', 'warning');
                  }
                })
                
                // this.setState({ openInputModal: true })
              } else {
                setPageLoad(false)
                setUploadProgress(false);
                showToaster(response.message, 'warning');
              }
            } else {
              setPageLoad(false)
              setUploadProgress(false);
              showToaster('Something went wrong. Please Try Again', 'warning');
            }
          } catch (error) {
            setPageLoad(false)
            setUploadProgress(false);
            showToaster('Something went wrong. Please Try Again', 'warning');
          }
        })
      }
      catch (e) {
        setPageLoad(false)
        setUploadProgress(false);
        showToaster('Something went wrong. Please Try Again', 'warning');
      }
    },

  })
  const backArrow = () => {
    history.push("/project-list");
  };
  const createNow = () => {
    history.push("/create-project");
  };
  const customStyles = {
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      height: "26px",
    }),
    singleValue: () => ({
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "12px",
      lineHeight: "18px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
    }),
    indicatorSeparator: () => ({
      border: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
  };
  const handleChange = (e) => {
    // alert()  
    setListCategory(e);
    console.log(e, "listtt");


  };
  // useEffect(() => {
  //   GetCategoryList()
  // }, [listCategory])
  useEffect(() => {
    generateFonts();
  }, [category]);
  const generateFonts = () => {
    if (category != undefined) {

      let fontOption = [];
      category.map((font) => {
        fontOption.push({ value: font.category, label: font.category, id: font.id });
        return true;
      });
      console.log(fontOption);
      setfonts(fontOption)
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };
  const SaveCategory = () => {
    if (addCategoryValue.length >= 50 || addCategoryValue.length < 5) {
      setCategoryEditError(true)
    } else {

      setPageLoad(true)
      try {
        let userId = getUserItem('id');
        let body = {
          user_id:  AdminRole== 0 ? userId : orgId ,
          list_id: 2,
          category_name: addCategoryValue
        }
        getCategory(body).then((response) => {
          try {
            if (response !== "" && response !== undefined && response !== null && response.data !== "" && response.data !== undefined && response.data !== null) {
              if (response.status === 200 && response.status !== null && response.status !== null) {

                showToaster('Category Added successfully', 'success');
                setPageLoad(false)
                // GetCategoryList()
                setInputActive(false)

                setListCategory({
                  id: response.data.category_id,
                  label: response.data.category_name,
                  value: response.data.category_name
                });
                // this.setState({ openInputModal: true })
              } else {
                setPageLoad(false)
                showToaster(response.message, 'warning');
              }
            } else if(response.status === 101) {
              setPageLoad(false)
              showToaster(response.message, 'warning');
            }
          } catch (error) {
            setPageLoad(false)
            showToaster('Something went wrong. Please Try Again', 'warning');
          }
        })
      }
      catch (e) {
        setPageLoad(false)
        showToaster('Something went wrong. Please Try Again', 'warning');
      }
    }

  }
  const AddCategory = () => {
    setInputActive(true)
    setAddCategoryValue("")
  }
  useEffect(() => {

    setTimeout(() => {
      setCategoryEditError(false)
    }, 4000);
  }, [categoryEditError])
  return (
    <>
      <div className={DisplayMode ? "theme dark" : "theme"}>
        <div className="main-wrapper">
          <Header />
          <div className="d-flex align-items-center justify-content-center cc-blk">
            <Tooltip title="Back to project list" arrow>
              <a className="mb-3 d-block back-btn" onClick={backArrow} href="">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  width="25px" height="25px" viewBox="0 0 400.004 400.004"
                  space="preserve">
                  <g>
                    <path d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757
               c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072
               c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315
               C400.004,190.438,392.251,182.686,382.688,182.686z"/>
                  </g>
                </svg>
              </a></Tooltip>
            <div className="cc-wrapper w-100">
              <div className="cc-heading">Create new course</div>
              <div className="cc-para">Curabitur sed aliquet urna. Donec cursus vehicula lectus ac gravida. Aenean sollicitudin facilisis aliquam. Maecenas pretium elit tristique iaculis mattis. </div>
              <div className="row m-0">
                <div className="col-6 p-0">
                  <div className="cc-left-part position-relative">
                    <form onSubmit={formik.handleSubmit} >
                      <div className="custom-input-holder">
                        <div className="custom-label fs-20">
                          Course Title
                    </div>

                        {/* <SimpleBackdrop showBackDrop={pageLoad} /> */}
                        <div className="custom-input-container">
                          <input className="custom-input" placeholder="Enter course" onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.courseName}
                            name="courseName" type="text"
                          />
                        </div>
                        {!inputActive && formik.touched.courseName && formik.errors.courseName ? (
                          <div className="input-error-msg" style={{ visibility: formik.touched.courseName && formik.errors.courseName ? "visible" : "hidden" }}>{formik.errors.courseName}</div>
                        ) : null}
                        <br />
                        <div className="custom-input-container">
                          <div className="custom-label fs-20">Category</div>

                          {inputActive ?
                            <>
                              <div className="custom-input-container">
                                <input className="custom-input" placeholder="Enter Category Name"
                                  value={addCategoryValue}
                                  onChange={(e) => {
                                    setAddCategoryValue(e.target.value)
                                  }}
                                  name="categoryname" type="text"
                                />

                              </div>

                              {categoryEditError ? <span className="input-error-msg" >Must be exactly 50 characters and Minimum 5 characters are required </span> : ""}
                            </>

                            :
                            <Select
                              className="custom-input"
                              styles={customStyles}
                              value={listCategory}
                              onChange={(e) => handleChange(e)}
                              options={fonts}
                            />
                          }

                        </div>

                      {AdminRole==0&&
                        <div className="themeblue-btn-holder mt-3">
                          <br />
                          {!inputActive ?
                            <button type="button" onClick={() => AddCategory()} className="btn btn-primary theme-bluebtn theme-bluebtn-sm ">Add Category</button>
                            : <div className="d-flex">
                              <button type="button" onClick={() => SaveCategory()} className="btn btn-primary theme-bluebtn me-3 theme-bluebtn-sm">Save</button>
                              <button type="button" onClick={() => {
                                setInputActive(false)
                                setAddCategoryValue("")
                              }} className="btn btn-primary theme-bluebtn theme-bluebtn-sm">Cancel</button></div>
                          }
                        </div>}
                        {/* <div name="courseName" component="div" className="input-error-msg" 
                    style={{ visibility:formik.touched.courseName && formik.errors.courseName ? "visible" : "hidden" }} ></div> */}

                        <div className="themeblue-btn-holder mt-3 d-flex justify-content-end">
                          <button type="submit" disabled={(listCategory != undefined) ? false : true} className={!inputActive ? "btn btn-primary theme-bluebtn" : "btn btn-primary theme-bluebtn disabled"}>Next</button>
                        </div>
                      </div>
                    </form>
                    {/* <div className="cc-or-seperator">OR</div> */}
                  </div>
                </div>
                {/* <CsvFileUpload /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="create-project" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog  modal-dialog-centered create-modal custom-modal dark">
            <div className="modal-content">
              <div className="modal-body">
                <div className="d-flex justify-content-end">
                  <div className="modal-close" data-bs-dismiss="modal">&times;</div>
                </div>
                <div className="modal-caption text-center">Let's make something amazing</div>
                <div className="modal-subcaption text-center">You can create your video either use a template or create video from scratch </div>
                <div className="d-flex align-items-center justify-content-center cp-wrapper">
                  <div className="cp-container w-50 mt-5">
                    <div className="cp-img-holder"></div>
                    <div className="cp-heading text-center mb-2">Create New Course</div>
                    <div className="cp-subheading text-center">Use one of our professionally designed video as a starting point. </div>
                    <div className="theme-btn-holder mt-3">
                      <button type="button" className="btn btn-primary theme-btnblue w-100 " data-bs-dismiss="modal" >create now</button>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3 mt-5">
                  <label className="d-flex align-items-center custom-checkbox">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <div className="check-label">Don't show me again</div>
                  </label>
                  <button type="button" className="cancel-btn" data-bs-dismiss="modal">Skip Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageLoader showLoader={pageLoad} />
      </>
  );
}