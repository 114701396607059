import { useParams } from 'react-router-dom';

import { Button, Table } from 'react-bootstrap';
import CustomCheckbox from '../../../../components/form/customCheckbox';
import CircleDetailBox from './circleDetailBox';
import PageLoader from '../../../../services/pageLoader';
import { Checkbox } from '@mui/material';

import fallBackImage from '../../../../assets/img/Thumbnailv3.jpg';
import { formatDateUser, isAdmin } from '../../../../services/helper';

function ProjectTable(props) {
  const {
    projectList,
    loading,
    setCurrentPage,
    currentPage,
    projectRoleDetails,
    setRoleDetails,
    totalProject,
  } = props;
  const params = useParams();
  const isCurrentUserAdmin = isAdmin();

  const onRoleChange = (projectId, roleType, e) => {
    // Create a new array of roles as a shallow copy of the existing roles
    const updatedRoles = projectRoleDetails.map(role => ({ ...role }));

    const existingRoleIndex = updatedRoles.findIndex(role => role.projectId === projectId);

    if (existingRoleIndex !== -1) {
      // If the role exists, toggle the value
      updatedRoles[existingRoleIndex][roleType] = !updatedRoles[existingRoleIndex][roleType];
    } else {
      // If the role for this project doesn't exist yet, create it
      updatedRoles.push({
        userId: params?.id, // Your user ID
        projectId,
        editProject: false,
        viewProject: false,
        deleteProject: false,
      });
      // Update the role type for the newly created role
      updatedRoles[updatedRoles.length - 1][roleType] = e.target.checked;
    }

    // Update the state with the new array of roles
    setRoleDetails(updatedRoles);
  };

  const showMoreHandler = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <PageLoader showLoader={loading} />
      <Table responsive className="common-table">
        <thead>
          <tr>
            <th className="col">Project Name</th>
            <th className="col-lg-1 text-center">Edit</th>
            <th className="col-lg-1 text-center">View</th>
            <th className="col-lg-1 text-center">Delete</th>
          </tr>
        </thead>
        <tbody>
          {projectList?.map((projects, index) => {
            const role = projectRoleDetails.find(role => role.projectId === projects.ProjectID);
            return (
              <tr key={index}>
                <td>
                  <CircleDetailBox
                    src={projects?.imageUrl ?? fallBackImage}
                    name={projects?.ProjectName}
                    desc={formatDateUser(projects?.updatedAt)}
                  />
                </td>
                <td>
                  <Checkbox
                    disabled={!isCurrentUserAdmin && !projects?.projectRole?.editProject}
                    checked={role ? role.editProject : false}
                    onChange={e => onRoleChange(projects?.ProjectID, 'editProject', e)}
                  />
                </td>
                <td>
                  <Checkbox
                    disabled={!isCurrentUserAdmin && !projects?.projectRole?.viewProject}
                    checked={role ? role.viewProject : false}
                    onChange={e => onRoleChange(projects?.ProjectID, 'viewProject', e)}
                  />
                </td>
                <td>
                  <Checkbox
                    disabled={!isCurrentUserAdmin && !projects?.projectRole?.deleteProject}
                    checked={role ? role.deleteProject : false}
                    onChange={e => onRoleChange(projects?.ProjectID, 'deleteProject', e)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
        {totalProject / (currentPage * 5) > 1 ? (
          <tfoot>
            <tr>
              <td>
                <Button variant="default" onClick={() => showMoreHandler()}>
                  Show more
                </Button>
              </td>
            </tr>
          </tfoot>
        ) : (
          <></>
        )}
      </Table>
    </>
  );
}
export default ProjectTable;
