import React, { useState } from 'react';

//Icons
import { arrowUpIcon, arrowDownIcon } from '../../constants/icon';

function PagePagination(props) {
  const { currentPage, totalItems, itemsPerPage, setItemsPerPage } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const startItem = Math.min((currentPage - 1) * itemsPerPage + 1, totalItems);
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleItemClick = newItemsPerPage => {
    setItemsPerPage(newItemsPerPage);
    setDropdownOpen(false);
  };

  return (
    <div className="page-pagination">
      <span>Items per page </span>
      <div className="per-page" onClick={toggleDropdown}>
        {itemsPerPage} {dropdownOpen ? <>{arrowUpIcon}</> : <>{arrowDownIcon}</>}
        {dropdownOpen && (
          <ul className="dropdown-list">
            {[10, 20, 30,40, 50].map(number => (
              <li key={number} onClick={() => handleItemClick(number)}>
                {number}
              </li>
            ))}
          </ul>
        )}
      </div>
      <span>
        {startItem}-{endItem} of {totalItems} items
      </span>
    </div>
  );
}

export default PagePagination;
