import _ from 'lodash';
import Swal from 'sweetalert2';
import { Tooltip } from '@mui/material';
import { bindActionCreators } from 'redux';
import { customAlphabet } from 'nanoid/async';
import { Col, Row, Button } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import {
  BgColor,
  TextColor,
  FONTSTYLE,
  FONTFAMILY,
  BULLETFONT,
  HEADERFONT,
  FONT_SIZE_H,
  FONT_SIZE_P,
  PARAGRAPHFONT,
} from '../../../constants';
import {
  getUserItem,
  showToaster,
  ShowAlertBox,
  getRandomNumber,
  getProjectQueryData,
  closeToaster,
  isAdmin,
} from '../../../services/helper';
import {
  getProject,
  getProjectID,
  updateProject,
  getTemplateData,
  getClientSetting,
  getProjectStyles,
  emailNotification,
  generateProjectPreview,
  listClusterDetails,
  SaveClusterDetails,
  checkMaxParallelRender,
} from '../../../services/api.service';
import {
  CreateProjectModel,
  removeMediaUrlFilter,
  CreateProjectModelManual,
  CreateProjectFromResponse,
} from '../../../services/DataHelper';
import {
  projectRender,
  getProjectStatus,
  preRenderProject,
  createRenderProject,
  getClusterList,
  deleteCluster,
  updateClusterStatus,
  getUserProjectRolesDetails,
} from '../../../services/apiServices';
import AddScreen from './addScreen';
import { actionCreator } from '../../../store';
import useFonts from '../../../hooks/fontHook';
import Sidebar from '../../../components/sidebar';
import ProjectModuleSidebar from './moduleSidebar';
import BrandSettingModal from '../brandSettingModal';
import PageLoader from '../../../services/pageLoader';
import CustomModal from '../../../services/CustomModal';
import EditScreenComponent from './editScreenComponent';
import VideoPreview from '../../../components/videoPreview';
import ValidationModal from '../../../services/CustomModalArray';
import EditableContent from '../../../components/EditableContent';
import HeaderNavigation from '../../../components/headerNavigation';
import { calculatePatternMatchPercentage } from '../../../lib/string-compare';
import {
  editIcon,
  renderIcon,
  longLeftArrowIcon,
  refreshIcon,
  alertTickIcon,
  crossIcon,
  alertWarningIcon,
  noProjectIcon,
} from '../../../constants/icon';
import AlertNotification from '../../../components/alertNotification/alertNotification';
import DataNotFound from '../../../components/dataNotFound';

export default function EditProjects() {
  const orgId = getUserItem('clientId');
  const { fonts } = useFonts();
  const params = useParams();
  const history = useHistory();
  const nanoid = customAlphabet('abcdefghijklmnopqrstuvzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890', 24);
  const random11char = customAlphabet('abcdefghijklmnopqrstuvwxyz', 11);
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    EditProject,
    InitProject,
    EmptyProject,
    EmptyPreview,
    SetStylesData,
    UpdateEditMode,
    RenameSlideName,
    RenameModuleName,
    ClearPreviewGeneratedOf,
  } = bindActionCreators(actionCreator, dispatch);

  const timerRef = useRef(null);
  const project = useSelector(state => state.project);
  const previewGeneratedOf = useSelector(state => state.project.previewGeneratedOf);

  const [imageUrl, setImageUrl] = useState();
  const [projectId, setProjectId] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [projectName, setProjectName] = useState();
  const [renderName, setRenderName] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [serverError, setServerError] = useState('');
  const [cacheProject, setCacheProject] = useState();
  const [renderVideos, setRenderVideos] = useState([]);
  const [clientSetting, setClientSetting] = useState();
  const [isRendering, setIsRendering] = useState(false);
  const [disableBack, setDisableBack] = useState(false);
  const [renderAttempt, setRenderAttempt] = useState(1);
  const [projectStatusFailedCount, setProjectStatusFailedCount] = useState(0);
  const [activeSlideName, setActiveSlideName] = useState();
  const [currentView, setCurrentView] = useState('screen');
  const [renderNote, setRenderNote] = useState('');
  const [showRefreshIcon, setShowRefreshIcon] = useState({});
  const [renderClusterName, setRenderClusterName] = useState('');
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [validationError, setValidationError] = useState([]);
  const [renderPercetange, setRenderPercetange] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [ostCountValidate, setostCountValidate] = useState([]);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [templateCheckArray, setTemplateCheckArray] = useState([]);
  const [TemplateAvailable, setTemplateAvailable] = useState(true);
  const [isTemplateLoading, setIsTemplateLoading] = useState(true);
  const [imageCountValidate, setImageCountValidate] = useState([]);
  const [videoCountValidate, setVideoCountValidate] = useState([]);
  const [slideRenderDataArray, setSlideRenderDataArray] = useState([]);
  const [recommendedTemplates, setRecommendedTemplates] = useState([]);
  const [isInnerSidebarActive, setIsInnerSidebarActive] = useState(false);
  const [paragraphLengthError, setParagraphLengthError] = useState(false);
  const [showPreviewAlert, setShowPreviewAlert] = useState(false);
  const [previewAlertMsg, setPreviewAlertMsg] = useState('');
  const [clusterCreating, setClusterCreating] = useState(false);
  const [templateCheckArrayCache, setTemplateCheckArrayCache] = useState([]);
  const [illustrationCountValidate, setIllustrationCountValidate] = useState([]);
  const [activeAudioScriptComparisonResult, setActiveAudioScriptComparisonResult] = useState([]);
  const [roleDetails, setRoleDetails] = useState([]);
  const [role, setRole] = useState();
  const currentUserRoles = getUserItem('userRole');
  const currentUserId = getUserItem('id');
  const isCurrentUserAdmin = isAdmin();

  const projectScreens =
    project?.createdProject?.[project.ProjectId]?.Modules?.[project.ActiveModule]?.slides?.[
      project.ActiveSlide
    ]?.Screens;

  let COUNT = 0;

  useEffect(() => {
    setSelectedTemplate('');
  }, [project.ActiveModule, project.ActiveSlide, project.ActiveScreen]);

  useEffect(() => {
    const activeSlide =
      project?.createdProject?.[project.ProjectId]?.Modules?.[project.ActiveModule]?.slides?.[
        project.ActiveSlide
      ];

    if (activeSlide) setActiveSlideName(activeSlide.SlideName);
  }, [project]);

  async function regenPreview(projectData, projectResponse, regenCount = 1) {
    let totalNoPreviewScreens = 0;
    let screenNames = '';
    Object.entries(projectData[projectResponse.ProjectID].Modules).map(
      ([moduleKey, moduleValue]) => {
        Object.entries(moduleValue.slides).map(([slideKey, slideValue]) => {
          Object.entries(slideValue.Screens).map(([screenKey, screenValue]) => {
            if (!screenValue.mediaurl) {
              totalNoPreviewScreens++;
              screenNames = screenNames
                ? `${screenNames}, ${screenValue.ScreenName}`
                : `${screenValue.ScreenName}`;
            }
          });
        });
      }
    );
    if (regenCount < 4) {
      if (totalNoPreviewScreens) {
        setShowPreviewAlert(true);
        setPreviewAlertMsg(
          `generating preview for ${totalNoPreviewScreens} ${
            totalNoPreviewScreens > 1 ? 'screens' : 'screen'
          }`
        );
        await timeout(15000);
        let latestProject = await getProject(params.id);
        let latestProjectData = JSON.parse(latestProject.ProjectData);
        totalNoPreviewScreens = 0;
        Object.entries(latestProjectData[latestProject.ProjectID].Modules).map(
          ([moduleKey, moduleValue]) => {
            Object.entries(moduleValue.slides).map(([slideKey, slideValue]) => {
              Object.entries(slideValue.Screens).map(([screenKey, screenValue]) => {
                if (!screenValue.mediaurl) {
                  totalNoPreviewScreens++;
                  screenNames = screenNames
                    ? `${screenNames}, ${screenValue.ScreenName}`
                    : `${screenValue.ScreenName}`;
                }
              });
            });
          }
        );
        if (totalNoPreviewScreens) {
          setShowPreviewAlert(true);
          setPreviewAlertMsg(
            `generating preview for ${totalNoPreviewScreens} ${
              totalNoPreviewScreens > 1 ? 'screens' : 'screen'
            }`
          );
        } else {
          setShowPreviewAlert(false);
          setPreviewAlertMsg('');
        }
        let previewGenerationCount = 0;
        for (const [moduleId, moduleValue] of Object.entries(
          latestProjectData[latestProject.ProjectID].Modules
        )) {
          for (const [slideId, slideValue] of Object.entries(moduleValue.slides)) {
            for (const [screenId, screenValue] of Object.entries(slideValue.Screens)) {
              if (!screenValue.mediaurl) {
                await generatePreviewWithProjectId(
                  latestProject.ProjectName,
                  latestProjectData,
                  latestProject.ProjectID,
                  { moduleId, slideId, screenId },
                  true,
                  latestProject.ProjectID
                );
                previewGenerationCount++;
                await timeout(5000);
              }
            }
          }
        }
        if (previewGenerationCount) {
          regenPreview(latestProjectData, latestProject, regenCount + 1);
        }
        // Object.entries(latestProjectData[latestProject.ProjectID].Modules).map(
        //   ([moduleId, moduleValue]) => {
        //     Object.entries(moduleValue.slides).map(([slideId, slideValue]) => {
        //       Object.entries(slideValue.Screens).map(async ([screenId, screenValue]) => {
        //       });
        //     });
        //   }
        // );
      } else {
        setShowPreviewAlert(false);
        setPreviewAlertMsg('');
      }
    } else {
      screenNames = '';
      await timeout(15000);
      let latestProject = await getProject(params.id);
      let latestProjectData = JSON.parse(latestProject.ProjectData);
      totalNoPreviewScreens = 0;
      Object.entries(latestProjectData[latestProject.ProjectID].Modules).map(
        ([moduleKey, moduleValue]) => {
          Object.entries(moduleValue.slides).map(([slideKey, slideValue]) => {
            Object.entries(slideValue.Screens).map(([screenKey, screenValue]) => {
              if (!screenValue.mediaurl) {
                totalNoPreviewScreens++;
                screenNames = screenNames
                  ? `${screenNames}, ${screenValue.ScreenName}`
                  : `${screenValue.ScreenName}`;
              }
            });
          });
        }
      );
      if (totalNoPreviewScreens) {
        setShowPreviewAlert(true);
        setPreviewAlertMsg(
          `preview generate fail for ${screenNames}. please try generating preview by clicking refresh icon.`
        );
      } else {
        setShowPreviewAlert(false);
        setPreviewAlertMsg('');
      }
    }
  }

  async function init(styleReload = true) {
    setIsLoading(true);
    try {
      let [clientSettingData, projectResponse] = await Promise.all([
        getClientSetting(),
        getProject(params.id),
      ]);
      const { checkPreview } = getProjectQueryData();

      if (clientSettingData) setClientSetting(clientSettingData);

      if (!projectResponse?.ProjectData) return history.replace('/create-project-list');

      let projectData = JSON.parse(projectResponse.ProjectData);

      if (styleReload) {
        getProjectStylesData(projectResponse.ProjectID, projectData);
      }

      if (projectResponse) {
        setCacheProject({ ...projectResponse, ProjectData: projectData });
        setImageUrl(projectResponse.imageUrl);
        setProjectId(projectResponse.ProjectID);
        setProjectName(projectResponse.ProjectName);
      }

      EmptyProject();
      await generateProjectData({ projectData, projectResponse, clientSettingData });
      if (checkPreview) {
        regenPreview(projectData, projectResponse);
      }
    } catch (error) {
      console.log({ editProjectsUseEffectError: error });
    }
  }

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => closeToaster();
  }, []);

  useEffect(() => {
    if (cacheProject) {
      let templateValidArray = [];

      if (cacheProject.ProjectData[cacheProject.ProjectID]?.Modules) {
        Object.entries(cacheProject.ProjectData[cacheProject.ProjectID].Modules).map(
          ([moduleKey, moduleValue]) => {
            Object.entries(moduleValue.slides).map(([slideKey, slideValue]) => {
              Object.entries(slideValue.Screens).map(([screenKey, screenValue]) => {
                Object.entries(screenValue).map(item => {
                  const activeTemplate = item.filter(data => data.Contenttype === 'TemplateID');

                  let isValid = false;
                  if (activeTemplate.length > 0) {
                    if (activeTemplate[0].Contentparams.contentdescription === '') {
                      isValid = false;
                    } else {
                      isValid = true;
                    }
                    if (activeTemplate[0].Contentparams.contentdescription === 'no template') {
                      isValid = false;
                    } else {
                      isValid = true;
                    }

                    let isPreviewGenerating = true;

                    if (!isValid) isPreviewGenerating = false;
                    if (isValid && screenValue?.mediaurl?.PreviewUrl) {
                      isPreviewGenerating = false;
                    }

                    const templateValid = {
                      isValid,
                      isPreviewGenerating,
                      templateKeys: moduleKey,
                      templateSlidekeys: slideKey,
                      templateScreenkeys: screenKey,
                      projectId: cacheProject.ProjectID,
                      screenName:
                        moduleValue.ModuleName +
                        '-' +
                        slideValue.SlideName +
                        '-' +
                        screenValue.ScreenName,
                    };
                    templateValidArray.push(templateValid);
                  }
                });
              });
            });
          }
        );
        setTemplateCheckArray(templateValidArray);
        setTemplateCheckArrayCache(templateValidArray);
      }
    }
  }, [cacheProject]);

  useEffect(() => {
    if (previewGeneratedOf) {
      const [projectId, moduleId, slideId, screenId] = previewGeneratedOf.split('-');

      setRefreshTimeOut({ [`${moduleId}-${slideId}-${screenId}`]: 0 });

      manipulateTemplateArray({ projectId, moduleId, slideId, screenId, cacheOnly: false });
    }
  }, [previewGeneratedOf]);

  useEffect(() => {
    let isMounted = true;
    if (project.ProjectId) {
      async function getSuggestedTemplates() {
        setIsTemplateLoading(true);
        try {
          const modules = cacheProject.ProjectData;
          const projectName = modules[project.ProjectId].ProjectName;
          const activeScreen =
            modules[project.ProjectId]['Modules'][project.ActiveModule]['slides'][
              project.ActiveSlide
            ]['Screens'][project.ActiveScreen];

          const activeScreenDetails = {
            ScreenName:
              modules[project.ProjectId]['Modules'][project.ActiveModule]['slides'][
                project.ActiveSlide
              ]['Screens'][project.ActiveScreen]['ScreenName'],
            ...activeScreen,
          };

          const SlideAudioVO =
            modules[project.ProjectId]['Modules'][project.ActiveModule]['slides'][
              project.ActiveSlide
            ]['SlideAudioVO'] !== undefined
              ? modules[project.ProjectId]['Modules'][project.ActiveModule]['slides'][
                  project.ActiveSlide
                ]['SlideAudioVO']
              : 'Null';

          const screenContent = {
            [project.ProjectId]: {
              ProjectName: projectName,
              Modules: {
                [project.ActiveModule]: {
                  ModuleName:
                    modules[project.ProjectId]['Modules'][project.ActiveModule]['ModuleName'],
                  slides: {
                    [project.ActiveSlide]: {
                      SlideName:
                        modules[project.ProjectId]['Modules'][project.ActiveModule]['slides'][
                          project.ActiveSlide
                        ]['SlideName'],
                      SlideAudioVO: SlideAudioVO,
                      Screens: {
                        [project.ActiveScreen]: activeScreenDetails,
                      },
                    },
                  },
                },
              },
            },
          };

          const templateData = await getTemplateData(screenContent, project.ProjectId);

          let templates = [];
          if (templateData?.[0] && isMounted) {
            templates = templateData[0];
          }

          setRecommendedTemplates(templates);
          setIsTemplateLoading(false);
        } catch (error) {
          console.log({ getSuggestedTemplatesError: error });
          setIsTemplateLoading(false);
          setRecommendedTemplates([]);
        }
      }

      getSuggestedTemplates();
    }

    return () => (isMounted = false);
  }, [project.ActiveScreen, project.ProjectId, project.ActiveSlide, project.ActiveModule]);

  function manipulateTemplateArray(data = {}) {
    const {
      projectId = project.ProjectId,
      moduleId = project.ActiveModule,
      slideId = project.ActiveSlide,
      screenId = project.ActiveScreen,
      cacheOnly = true,
    } = data;

    const checkArray = templateCheckArray.map(templateArr => {
      if (
        templateArr.projectId === projectId &&
        templateArr.templateKeys === moduleId &&
        templateArr.templateSlidekeys === slideId &&
        templateArr.templateScreenkeys === screenId
      ) {
        return { ...templateArr, isPreviewGenerating: false };
      }
      return templateArr;
    });

    // showToaster(
    //   `Preview generated: ${projectId}-${moduleId}-${slideId}-${screenId}`,
    //   'success',
    //   5000
    // );

    if (cacheOnly) {
      return setTemplateCheckArrayCache(checkArray);
    }

    setTemplateCheckArray(checkArray);
  }

  async function generateProjectData({ projectData, projectResponse, clientSettingData }) {
    if (projectData[projectResponse.ProjectID].Modules) {
      const formatData = await CreateProjectFromResponse(projectResponse);

      UpdateEditMode(true);

      const { from, module, slide, screen, action } = getProjectQueryData();

      if (from === 'generate-videos-inputs' && !action) {
        const projectData = JSON.parse(projectResponse.ProjectData);
        const modules = projectData?.[projectResponse.ProjectID]?.Modules;
        const modulesKeys = Object.keys(modules);

        if (!modulesKeys.find(key => key === module)) {
          showToaster('Invalid module query', 'error');
          return reloadPage();
        }

        const slides = modules[module].slides;
        const slidesKeys = Object.keys(slides);

        if (!slidesKeys.find(key => key === slide)) {
          showToaster('Invalid video query', 'error');
          return reloadPage();
        }

        const screens = slides[slide].Screens;
        const screensKeys = Object.keys(screens);

        if (!screensKeys.find(key => key === screen)) {
          showToaster('Invalid screen query', 'error');
          return reloadPage();
        }

        formatData.ActiveModule = module;
        formatData.ActiveSlide = slide;
        formatData.ActiveScreen = screen;
      }

      EditProject(formatData);

      /* START- Do we even need this code - in new design? */
      // if (location.state) {
      //   PreviousActive({
      //     activeSlide: location.state.ActiveSlide,
      //     activeModule: location.state.ActiveModule,
      //     activeScreen: location.state.ActiveScreen,
      //   });
      // }

      // if (localStorage.getItem('iscloned')) {
      //   localStorage.removeItem('iscloned');
      // }

      /* END- Do we even need this code - in new design? */
    } else {
      const NotificationURL =
        (process.env.REACT_APP_API_URL_DEV || process.env.REACT_APP_API_URL) +
        'api/project/v1/render-callback';
      const ProjectID = projectResponse.ProjectID;
      const ProjectName = projectResponse.ProjectName;
      const CategoryID = projectData[ProjectID].CategoryID;

      let projectInputs;
      if (clientSettingData.manualTemplate) {
        projectInputs = await CreateProjectModelManual(
          ProjectID,
          ProjectName,
          CategoryID,
          NotificationURL
        );
      } else {
        projectInputs = await CreateProjectModel(
          ProjectID,
          ProjectName,
          CategoryID,
          NotificationURL
        );
      }

      if (projectResponse.includeLogoImage) {
        projectInputs[projectResponse.ProjectID].Modules['M01'].slides['SL01'].Screens['SC01.01'][
          'IM01'
        ]['Contentparams']['contentdescription'] = projectResponse.imageUrl;
        projectInputs[projectResponse.ProjectID].Modules['M01'].slides['SL01'].Screens['SC01.01'][
          'IM01'
        ]['Contentparams']['contenttag'] = 'logo';
      }

      UpdateEditMode(false);
      InitProject(projectInputs);
    }

    setIsLoading(false);
    setIsInnerSidebarActive(true);
  }

  async function getProjectStylesData(ProjectID, projectData) {
    try {
      const styles = await getProjectStyles(ProjectID);

      let stylesData = styles?.data;
      if (!stylesData) {
        stylesData = {
          bgColor: BgColor,
          textColor: TextColor,
          fontStyle: FONTSTYLE,
          fontFamily: FONTFAMILY,
          headingSize: FONT_SIZE_H,
          paragraphSize: FONT_SIZE_P,
        };
        // setModalShow(true);
      }

      SetStylesData(stylesData);
      const { from, action } = getProjectQueryData();
      if ((from && action) || !projectData[ProjectID]?.Modules) setModalShow(true);

      // setIsLoading(false);
    } catch (error) {
      console.log({ getProjectStylesDataError: error });
    }
  }

  const reloadPage = () => {
    setTimeout(() => {
      window.location.replace(window.location.pathname);
    }, 2000);
  };

  const handleRenderClick = () => {
    setIsRendering(false);
    setClusterCreating(false);
    setRenderName('');
    setRenderPercetange('Progress Percent 0%');
    setRenderVideos([]);
    saveRenderSlide();
    setCurrentView('render');
  };

  const clearTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null; // Reset the timer reference
    }
  };

  const handleBackClick = ({ close } = {}) => {
    setCurrentView('screen');
    clearTimer();

    if (close) closeToaster();
  };

  const handleEditVideoClick = () => {
    setCurrentView('screen');
    clearTimer();
    closeToaster();
  };

  const onChangeSlideName = name => {
    setActiveSlideName(name);
    updateSlideName(name);
  };

  const showToast = message => {
    Swal.fire({
      html: message,
      icon: 'warning',
      focusConfirm: false,
      confirmButtonText: 'OK',
      confirmButtonColor: '#d33',
    });
  };

  const compareActiveAudioOST = (activeModule, activeSlide, activeScreen) => {
    const activeScreenDetails =
      project.createdProject?.[project.ProjectId]?.Modules?.[activeModule]?.slides?.[activeSlide]
        ?.Screens?.[activeScreen];

    let items = {
      ost: 0,
      ostH: 0,
      ostP: 0,
      ostB: 0,
    };

    let ostHArray = [];
    let ostBArray = [];
    let ostPArray = [];
    let ostString = '';
    let audioScriptString = '';

    const activeScreenDetailsArray = Object.values(activeScreenDetails);
    for (const activeScreenValue of activeScreenDetailsArray) {
      if (
        activeScreenValue?.Contenttype === 'AudioScript' &&
        activeScreenValue?.Contentparams?.contentdescription
      ) {
        audioScriptString = activeScreenValue.Contentparams.contentdescription;
      } else if (
        activeScreenValue?.Contenttype === 'OST' &&
        activeScreenValue?.Contentparams?.contentdescription
      ) {
        items.ost++;

        if (activeScreenValue?.Contentparams?.fontTag === '[H]') {
          items.ostH++;

          ostHArray.push(activeScreenValue.Contentparams.contentdescription.length);
          ostString += activeScreenValue.Contentparams.contentdescription;
        } else if (activeScreenValue?.Contentparams?.fontTag === '[P]') {
          items.ostP++;

          ostPArray.push(activeScreenValue.Contentparams.contentdescription.length);
          ostString += ' ' + activeScreenValue.Contentparams.contentdescription;
        } else if (activeScreenValue?.Contentparams?.fontTag === '[B]') {
          items.ostB++;

          ostBArray.push(activeScreenValue?.Contentparams?.contentdescription?.length);
          ostString += ' ' + activeScreenValue.Contentparams.contentdescription;
        }
      }
    }

    const compareAudioScript = calculatePatternMatchPercentage(audioScriptString, ostString);

    if (compareAudioScript < 80) {
      setActiveAudioScriptComparisonResult([
        ...activeAudioScriptComparisonResult,
        {
          activeModule,
          activeSlide,
          activeScreen,
          isValid: false,
        },
      ]);
    } else {
      setActiveAudioScriptComparisonResult([
        ...activeAudioScriptComparisonResult,
        { activeModule, activeSlide, activeScreen, isValid: true },
      ]);
    }
  };

  const countPlusOne = () => {
    COUNT += 1;
    return COUNT.toString();
  };

  const checkScreenEdit = () => {
    let updatedProjectData = JSON.parse(JSON.stringify(project.createdProject));
    const projectModules = updatedProjectData[project.ProjectId].Modules;

    let slideChanges = [];
    let hasDataChanged = false;
    let useDifferentTemplate = false;

    Object.entries(projectModules).map(([moduleKey, moduleValue]) => {
      Object.entries(moduleValue.slides).map(([slideKey, slideValue]) => {
        Object.entries(slideValue.Screens).map(([screenKey, screenValue]) => {
          const arrayData = [];
          let ostCountValidateArr = [];
          let imageCountValidateLocal = [];
          let videoCountValidateLocal = [];
          let illustrationCountValidateLocal = [];

          let screenValueArray = Object.entries(screenValue);
          screenValueArray.sort((a, b) => {
            const isTa = /^TM/.test(a[0]);
            const isTb = /^TM/.test(b[0]);

            if (isTa && !isTb) {
              return 1;
            }
            if (isTb && !isTa) {
              return -1;
            }
            return 0;
          });

          let oldScreenData;
          const cachedProjectModule =
            cacheProject.ProjectData[project.ProjectId]?.Modules?.[moduleKey];

          if (cachedProjectModule?.slides?.[slideKey]) {
            oldScreenData = cachedProjectModule.slides[slideKey].Screens[screenKey];
          }

          if (cachedProjectModule?.slides?.[slideKey]?.Screens?.[screenKey]) {
            if (cachedProjectModule.ModuleName !== moduleValue.ModuleName) {
              arrayData.push(1);
              hasDataChanged = true;
            }

            if (
              cachedProjectModule.slides[slideKey].SlideName !==
              moduleValue.slides[slideKey].SlideName
            ) {
              arrayData.push(1);
              hasDataChanged = true;
            }

            if (
              cachedProjectModule.slides[slideKey].Screens[screenKey].ScreenName !==
              moduleValue.slides[slideKey].Screens[screenKey].ScreenName
            ) {
              arrayData.push(1);
              hasDataChanged = true;
            }
          } else {
            arrayData.push(1);
            hasDataChanged = true;
            useDifferentTemplate = true;
          }

          if (!oldScreenData) {
            arrayData.push(1);
            hasDataChanged = true;
          }

          screenValueArray.map(([screenValueKey, screenValueValue]) => {
            if (screenValueValue.Contenttype === 'OST') {
              if (screenValueValue.Contentparams.fontTag === HEADERFONT) {
                // ostCountArray = { ostHeading: screenValueValue };
              }

              if (screenValueValue['Contentparams']['FontColour'].length === 8) {
                screenValueValue['Contentparams']['FontColour'] =
                  screenValueValue['Contentparams']['FontColour'].split('*')[0];
              }
              if (screenValueValue['Contentparams']['contentdescription'].includes('[H]')) {
                return screenValueValue['Contentparams']['contentdescription'].split('[H]')[0];
              }

              if (screenValueValue['Contentparams']['contentdescription'].includes('[P]')) {
                return screenValueValue['Contentparams']['contentdescription'].split('[P]')[0];
              }
              if (screenValueValue['Contentparams']['contentdescription'].includes('[B]')) {
                return screenValueValue['Contentparams']['contentdescription'].split('[B]')[0];
              }

              if (
                screenValueValue['Contentparams']['fontTag'] === HEADERFONT ||
                !screenValueValue['Contentparams']['fontTag']
              ) {
                screenValueValue['Contentparams']['contentdescription'] =
                  screenValueValue['Contentparams']['contentdescription'] + '[H]';
              } else if (screenValueValue['Contentparams']['fontTag'] === PARAGRAPHFONT) {
                screenValueValue['Contentparams']['contentdescription'] =
                  screenValueValue['Contentparams']['contentdescription'] + '[P]';
              } else if (screenValueValue['Contentparams']['fontTag'] === BULLETFONT) {
                screenValueValue['Contentparams']['contentdescription'] =
                  screenValueValue['Contentparams']['contentdescription'] + '[B]';
              }
              screenValueValue['Contentparams']['sequenceid'] = countPlusOne();

              if (screenValueValue['Contentparams']['fontTag'] !== '') {
                delete screenValueValue['Contentparams']['fontTag'];
              }
              if (oldScreenData) {
                if (oldScreenData[screenValueKey]) {
                  if (
                    screenValueValue['Contentparams']['contentdescription'] !==
                    oldScreenData[screenValueKey].Contentparams.contentdescription
                  ) {
                    if (
                      screenValueValue['Contentparams']['contentdescription'].length !==
                      oldScreenData[screenValueKey].Contentparams.contentdescription.length
                    ) {
                      useDifferentTemplate = true;
                    }
                    arrayData.push(1);
                    hasDataChanged = true;

                    let data = [];
                    data.push(moduleKey + '_' + slideKey + '_' + screenKey + '_' + screenValueKey);
                    ostCountValidateArr = [...ostCountValidate, ...data];
                  } else {
                    ostCountValidateArr = ostCountValidate;
                  }
                  if (
                    screenValueValue['Contentparams']['FontColour'] !==
                    oldScreenData[screenValueKey].Contentparams.FontColour
                  ) {
                    arrayData.push(1);
                    hasDataChanged = true;
                  }
                  if (
                    screenValueValue['Contentparams']['FontFamily'] !==
                    oldScreenData[screenValueKey].Contentparams.FontFamily
                  ) {
                    arrayData.push(1);
                    hasDataChanged = true;
                  }
                  if (
                    screenValueValue['Contentparams']['FontSizeH'] !==
                    oldScreenData[screenValueKey].Contentparams.FontSizeH
                  ) {
                    arrayData.push(1);
                    hasDataChanged = true;
                  }
                  if (
                    screenValueValue['Contentparams']['FontSizeP'] !==
                    oldScreenData[screenValueKey].Contentparams.FontSizeP
                  ) {
                    arrayData.push(1);
                    hasDataChanged = true;
                  }
                }
              }
              if (screenValueValue['Contentparams']['contenttag'] === '') {
                screenValueValue['Contentparams']['contenttag'] = 'Null';
                return screenValueValue;
              }
              return screenValueValue;
            }

            if (screenValueValue.Contenttype === 'Background') {
              screenValueValue['Contentparams']['sequenceid'] = countPlusOne();
              return screenValueValue;
            }
            if (screenValueValue.Contenttype === 'AudioScript') {
              screenValueValue['Contentparams']['sequenceid'] = countPlusOne();

              if (oldScreenData) {
                if (oldScreenData[screenValueKey]) {
                  if (
                    screenValueValue['Contentparams']['contentdescription'] !==
                    oldScreenData[screenValueKey].Contentparams.contentdescription
                  ) {
                    arrayData.push(1);
                    hasDataChanged = true;
                  }
                }
              }
              if (screenValueValue['Contentparams']['contenttag'] === '') {
                screenValueValue['Contentparams']['contenttag'] = 'Null';
                return screenValueValue;
              }
              return screenValueValue;
            }
            if (screenValueValue.Contenttype === 'Image') {
              if (screenValueValue['Contentparams']['searchstring']) {
                screenValueValue['Contentparams']['searchstring'] =
                  screenValueValue['Contentparams']['searchstring'].trim();
              }
              if (screenValueValue['Contentparams']['contenttag']) {
                screenValueValue['Contentparams']['contenttag'] =
                  screenValueValue['Contentparams']['contenttag'].trim();
              }

              if (
                screenValueValue['Contentparams']['contenttag'] === '' &&
                screenValueValue['Contentparams']['searchstring'] === ''
              ) {
                let imageArray = [];

                imageCountValidate.map((imgData, i) => {
                  if (
                    moduleKey + '_' + slideKey + '_' + screenKey + '_' + screenValueKey ===
                    imgData
                  ) {
                    if (imgData) {
                      imageArray = { ...imageCountValidate };
                      imageArray.splice(1, i);
                    }
                  }
                });

                imageCountValidateLocal = imageArray;
                delete screenValue[screenValueKey];
              } else {
                imageCountValidateLocal = imageCountValidate;
                screenValueValue['Contentparams']['sequenceid'] = countPlusOne();
              }
              if (
                screenValueValue['Contentparams']['contenttag'] === '' ||
                !screenValueValue['Contentparams']['contenttag'].length
              ) {
                screenValueValue['Contentparams']['contenttag'] = 'Null';
              }

              if (oldScreenData) {
                if (oldScreenData[screenValueKey]) {
                  if (
                    screenValueValue['Contentparams']['searchstring'] !==
                      oldScreenData[screenValueKey].Contentparams.searchstring ||
                    screenValueValue['Contentparams']['contenttag'] !==
                      oldScreenData[screenValueKey].Contentparams.contenttag
                  ) {
                    screenValueValue['Contentparams']['contentdescription'] = '';
                  }
                }
              }

              if (oldScreenData) {
                if (oldScreenData[screenValueKey]) {
                  if (
                    screenValueValue['Contentparams']['searchstring'] !==
                      oldScreenData[screenValueKey].Contentparams.searchstring ||
                    screenValueValue['Contentparams']['contenttag'] !==
                      oldScreenData[screenValueKey].Contentparams.contenttag ||
                    screenValueValue['Contentparams']['contentdescription'] !==
                      oldScreenData[screenValueKey].Contentparams.contentdescription
                  ) {
                    if (
                      (hasData(screenValueValue) && noData(oldScreenData[screenValueKey])) ||
                      (!hasData(screenValueValue) && !noData(oldScreenData[screenValueKey]))
                    ) {
                      useDifferentTemplate = true;
                    }
                    arrayData.push(1);
                    hasDataChanged = true;
                  }
                } else if (
                  screenValueValue['Contentparams']['searchstring'] ||
                  screenValueValue['Contentparams']['contenttag'] !== 'Null'
                ) {
                  arrayData.push(1);
                  hasDataChanged = true;
                  useDifferentTemplate = true;
                }
              }

              return screenValueValue;
            }
            if (screenValueValue.Contenttype === 'Video') {
              if (screenValueValue['Contentparams']['searchstring']) {
                screenValueValue['Contentparams']['searchstring'] =
                  screenValueValue['Contentparams']['searchstring'].trim();
              }
              if (screenValueValue['Contentparams']['contenttag']) {
                screenValueValue['Contentparams']['contenttag'] =
                  screenValueValue['Contentparams']['contenttag'].trim();
              }

              if (
                screenValueValue['Contentparams']['contenttag'] === '' &&
                screenValueValue['Contentparams']['searchstring'] === ''
              ) {
                let videoArray = [];
                videoCountValidate.map((videoData, i) => {
                  if (
                    moduleKey + '_' + slideKey + '_' + screenKey + '_' + screenValueKey ===
                    videoData
                  ) {
                    if (videoData) {
                      videoArray = videoCountValidate;
                      videoArray.splice(1, i);
                    }
                  }
                });
                videoCountValidateLocal = videoArray;

                delete screenValue[screenValueKey];
              } else {
                videoCountValidateLocal = videoCountValidate;
                screenValueValue['Contentparams']['sequenceid'] = countPlusOne();
              }
              if (
                screenValueValue['Contentparams']['contenttag'] === '' ||
                !screenValueValue['Contentparams']['contenttag'].length
              ) {
                screenValueValue['Contentparams']['contenttag'] = 'Null';
              }

              if (oldScreenData) {
                if (oldScreenData[screenValueKey]) {
                  if (
                    screenValueValue['Contentparams']['searchstring'] !==
                      oldScreenData[screenValueKey].Contentparams.searchstring ||
                    screenValueValue['Contentparams']['contenttag'] !==
                      oldScreenData[screenValueKey].Contentparams.contenttag
                  ) {
                    screenValueValue['Contentparams']['contentdescription'] = '';
                  }
                }
              }

              if (oldScreenData) {
                if (oldScreenData[screenValueKey]) {
                  if (
                    screenValueValue['Contentparams']['searchstring'] !==
                      oldScreenData[screenValueKey].Contentparams.searchstring ||
                    screenValueValue['Contentparams']['contenttag'] !==
                      oldScreenData[screenValueKey].Contentparams.contenttag ||
                    screenValueValue['Contentparams']['contentdescription'] !==
                      oldScreenData[screenValueKey].Contentparams.contentdescription
                  ) {
                    if (
                      (hasData(screenValueValue) && noData(oldScreenData[screenValueKey])) ||
                      (!hasData(screenValueValue) && !noData(oldScreenData[screenValueKey]))
                    ) {
                      useDifferentTemplate = true;
                    }

                    arrayData.push(1);
                    hasDataChanged = true;
                  }
                } else if (
                  screenValueValue['Contentparams']['searchstring'] ||
                  screenValueValue['Contentparams']['contenttag'] !== 'Null'
                ) {
                  arrayData.push(1);
                  hasDataChanged = true;
                  useDifferentTemplate = true;
                }
              }

              return screenValueValue;
            }
            if (screenValueValue.Contenttype === 'Illustration') {
              if (screenValueValue['Contentparams']['searchstring']) {
                screenValueValue['Contentparams']['searchstring'] =
                  screenValueValue['Contentparams']['searchstring'].trim();
              }
              if (screenValueValue['Contentparams']['contenttag']) {
                screenValueValue['Contentparams']['contenttag'] =
                  screenValueValue['Contentparams']['contenttag'].trim();
              }

              if (oldScreenData) {
                if (oldScreenData[screenValueKey]) {
                  if (
                    screenValueValue['Contentparams']['searchstring'] !==
                      oldScreenData[screenValueKey].Contentparams.searchstring ||
                    screenValueValue['Contentparams']['contenttag'] !==
                      oldScreenData[screenValueKey].Contentparams.contenttag
                  ) {
                    screenValueValue['Contentparams']['contentdescription'] = '';
                  }
                }
              }
              if (
                screenValueValue['Contentparams']['contenttag'] === '' &&
                screenValueValue['Contentparams']['searchstring'] === ''
              ) {
                let illustrationArray = [];

                illustrationCountValidate.map((illuData, i) => {
                  if (
                    moduleKey + '_' + slideKey + '_' + screenKey + '_' + screenValueKey ===
                    illuData
                  ) {
                    if (illuData) {
                      illustrationArray = illustrationCountValidate;
                      illustrationArray.splice(1, i);
                    }
                  }
                });
                illustrationCountValidateLocal = illustrationArray;
                delete screenValue[screenValueKey];
              } else {
                illustrationCountValidateLocal = illustrationCountValidate;
                screenValueValue['Contentparams']['sequenceid'] = countPlusOne();
              }

              if (
                screenValueValue['Contentparams']['contenttag'] === '' ||
                !screenValueValue['Contentparams']['contenttag'].length
              ) {
                screenValueValue['Contentparams']['contenttag'] = 'Null';
              }
              if (oldScreenData) {
                if (oldScreenData[screenValueKey]) {
                  if (
                    screenValueValue['Contentparams']['searchstring'] !==
                      oldScreenData[screenValueKey].Contentparams.searchstring ||
                    screenValueValue['Contentparams']['contenttag'] !==
                      oldScreenData[screenValueKey].Contentparams.contenttag ||
                    (screenValueValue['Contentparams']['contentdescription'] &&
                      oldScreenData[screenValueKey].Contentparams.contentdescription &&
                      screenValueValue['Contentparams']['contentdescription'] !==
                        oldScreenData[screenValueKey].Contentparams.contentdescription)
                  ) {
                    arrayData.push(1);
                    hasDataChanged = true;
                  }
                } else if (
                  screenValueValue['Contentparams']['searchstring'] ||
                  screenValueValue['Contentparams']['contenttag'] !== 'Null'
                ) {
                  arrayData.push(1);
                  hasDataChanged = true;
                }
              }
              return screenValueValue;
            }
            if (screenValueValue.Contenttype === 'TemplateID') {
              if (arrayData.length > 0) {
                if (clientSetting.manualTemplate) {
                  screenValueValue['Contentparams']['contentdescription'] = 'no template';
                }
              }

              ostCountValidateArr.map(ostData => {
                let ostValidateData = ostData.slice(0, -5);
                if (moduleKey + '_' + slideKey + '_' + screenKey === ostValidateData) {
                  if (ostData) {
                    if (clientSetting.manualTemplate) {
                      screenValueValue['Contentparams']['contentdescription'] = 'no template';
                    }

                    return screenValueValue;
                  }
                }
              });
              imageCountValidateLocal.map(imgData => {
                let imgValidateData = imgData.slice(0, -5);
                if (moduleKey + '_' + slideKey + '_' + screenKey === imgValidateData) {
                  if (imgData) {
                    if (clientSetting.manualTemplate) {
                      screenValueValue['Contentparams']['contentdescription'] = 'no template';
                    }
                    return screenValueValue;
                  }
                }
              });
              illustrationCountValidateLocal.map(iluData => {
                let illuValidateData = iluData.slice(0, -5);
                if (moduleKey + '_' + slideKey + '_' + screenKey === illuValidateData) {
                  if (iluData) {
                    if (clientSetting.manualTemplate) {
                      screenValueValue['Contentparams']['contentdescription'] = 'no template';
                    }
                    return screenValueValue;
                  }
                }
              });
              videoCountValidateLocal.map(videoData => {
                let videoValidateData = videoData.slice(0, -5);
                if (moduleKey + '_' + slideKey + '_' + screenKey === videoValidateData) {
                  if (videoData) {
                    if (clientSetting.manualTemplate) {
                      screenValueValue['Contentparams']['contentdescription'] = 'no template';
                    }
                    return screenValueValue;
                  }
                }
              });
              screenValueValue['Contentparams']['sequenceid'] = countPlusOne();

              if (oldScreenData) {
                if (oldScreenData[screenValueKey]) {
                  if (
                    selectedTemplate &&
                    selectedTemplate !==
                      oldScreenData[screenValueKey].Contentparams.contentdescription
                  ) {
                    arrayData.push(1);
                    hasDataChanged = true;
                  }
                }
              }

              if (screenValueValue['Contentparams']['useTemplateStatus'] !== '') {
                delete screenValueValue['Contentparams']['useTemplateStatus'];
                return screenValueValue;
              }

              return screenValueValue;
            }
            if (screenValueValue.Contenttype === 'ColourScheme') {
              screenValueValue['Contentparams']['sequenceid'] = countPlusOne();
              if (screenValueValue['Contentparams']['contentdescription'].length === 8) {
                screenValueValue['Contentparams']['contentdescription'] =
                  screenValueValue['Contentparams']['contentdescription'].split('*')[0];
              }
              if (oldScreenData) {
                if (oldScreenData[screenValueKey]) {
                  if (
                    screenValueValue['Contentparams']['contentdescription'] !==
                    oldScreenData[screenValueKey].Contentparams.contentdescription
                  ) {
                    arrayData.push(1);
                    hasDataChanged = true;
                  }
                }
              }
              return screenValueValue;
            }
            if (screenValueValue.Contenttype === 'ScreenDuration') {
              screenValueValue['Contentparams']['sequenceid'] = countPlusOne();
              if (screenValueValue['Contentparams']['contenttag'] === '') {
                screenValueValue['Contentparams']['contenttag'] = 'Null';
              }
              if (screenValueValue['Contentparams']['contentdescription'] === '') {
                screenValueValue['Contentparams']['contentdescription'] = '7';
              }
              if (oldScreenData) {
                if (oldScreenData[screenValueKey]) {
                  if (
                    screenValueValue['Contentparams']['contentdescription'] !==
                    oldScreenData[screenValueKey].Contentparams.contentdescription
                  ) {
                    arrayData.push(1);
                    hasDataChanged = true;
                  }
                }
              }
              return screenValueValue;
            }
            return screenValueValue;
          });

          if (
            oldScreenData &&
            Object.keys(oldScreenData).toString() !==
              Object.keys(moduleValue.slides[slideKey].Screens[screenKey]).toString()
          ) {
            arrayData.push(1);
            hasDataChanged = true;
          }

          if (arrayData.length) {
            if (screenKey === project.ActiveScreen && screenValue['mediaurl']) {
              delete projectModules[moduleKey].slides[slideKey].Screens[screenKey]['mediaurl'];
            }

            slideChanges.push(moduleKey + '_' + slideKey);
          }
        });

        slideValue.SlideRender = '1';
      });
    });

    return { updatedProjectData, slideChanges, hasDataChanged, useDifferentTemplate };
  };

  const templateForLastActiveScreen = async (projectDataforAPI, useDifferentTemplate) => {
    let choosenTemplate = '';

    if (!selectedTemplate) {
      if (!useDifferentTemplate) {
        return projectDataforAPI;
      } else {
        const projectName = projectDataforAPI[project.ProjectId]?.ProjectName;
        const activeScreen =
          projectDataforAPI?.[project.ProjectId]?.['Modules']?.[project.ActiveModule]?.['slides']?.[
            project.ActiveSlide
          ]?.['Screens']?.[project.ActiveScreen];

        const modifiedScreenData = activeScreen && JSON.parse(JSON.stringify(activeScreen));
        for (const key in modifiedScreenData) {
          // removes fontTag from OS01 object and appends the value to contentdescription
          if (modifiedScreenData[key].Contenttype === 'OST') {
            const { fontTag, ...restParams } = modifiedScreenData[key].Contentparams;
            modifiedScreenData[key].Contentparams = {
              ...restParams,
              contentdescription: restParams.contentdescription + fontTag,
            };
          }
          if (['Image', 'Video', 'Illustration'].includes(modifiedScreenData[key].Contenttype)) {
            const { searchstring, contenttag } = modifiedScreenData[key].Contentparams;
            if (!searchstring && !contenttag) {
              delete modifiedScreenData[key];
            }
          }
        }

        const activeScreenDetails = {
          ScreenName:
            projectDataforAPI[project.ProjectId]?.['Modules']?.[project.ActiveModule]?.['slides']?.[
              project.ActiveSlide
            ]?.['Screens']?.[project.ActiveScreen]?.['ScreenName'],
          ...activeScreen,
        };

        const SlideAudioVO = projectDataforAPI[project.ProjectId]?.['Modules'][
          project.ActiveModule
        ]?.['slides']?.[project.ActiveSlide]?.['SlideAudioVO']
          ? projectDataforAPI[project.ProjectId]?.['Modules'][project.ActiveModule]?.['slides'][
              project.ActiveSlide
            ]?.['SlideAudioVO']
          : 'Null';

        const screenContent = {
          [project.ProjectId]: {
            ProjectName: projectName,
            Modules: {
              [project.ActiveModule]: {
                ModuleName:
                  projectDataforAPI[project.ProjectId]?.['Modules']?.[project.ActiveModule]?.[
                    'ModuleName'
                  ],
                slides: {
                  [project.ActiveSlide]: {
                    SlideName:
                      projectDataforAPI[project.ProjectId]?.['Modules']?.[project.ActiveModule]?.[
                        'slides'
                      ][project.ActiveSlide]?.['SlideName'],
                    SlideAudioVO: SlideAudioVO,
                    Screens: {
                      [project.ActiveScreen]: activeScreenDetails,
                    },
                  },
                },
              },
            },
          },
        };

        setIsTemplateLoading(true);
        const templateResponse = await getTemplateData(screenContent, project.ProjectId);
        setIsTemplateLoading(false);

        let templateData = [];
        if (templateResponse?.[0]?.length) {
          templateData = templateResponse[0];
          const randomNumber = getRandomNumber({ max: templateData.length - 1 });
          choosenTemplate = templateData[randomNumber].mediaUrl;
        }

        setRecommendedTemplates(templateData);
      }
    } else {
      choosenTemplate = selectedTemplate;
    }

    if (choosenTemplate) {
      projectDataforAPI[project.ProjectId]['Modules'][project.ActiveModule]['slides'][
        project.ActiveSlide
      ]['Screens'][project.ActiveScreen]['TM01']['Contentparams']['contentdescription'] =
        choosenTemplate;
    } else {
      projectDataforAPI[project.ProjectId]['Modules'][project.ActiveModule]['slides'][
        project.ActiveSlide
      ]['Screens'][project.ActiveScreen]['TM01']['Contentparams']['contentdescription'] =
        'no template';
    }

    return projectDataforAPI;
  };

  const templateValidateFunction = projectData => {
    let templateValidArray = [];
    Object.entries(projectData[project.ProjectId].Modules).map(moduleData => {
      let mData = moduleData[1];
      let slides = mData.slides;
      Object.entries(slides).map(slideData => {
        let slide = slideData[1];
        let screens = slide.Screens;
        Object.entries(screens).map(screenData => {
          let slide = screenData[1];
          Object.entries(slide).map(item => {
            let activeTemplate = item.filter(function (data) {
              return data.Contenttype === 'TemplateID';
            });
            let isValid = false;
            if (activeTemplate.length > 0) {
              if (activeTemplate[0].Contentparams.contentdescription === '') {
                isValid = false;
              } else {
                isValid = true;
              }
              if (activeTemplate[0].Contentparams.contentdescription === 'no template') {
                isValid = false;
              } else {
                isValid = true;
              }

              let isPreviewGenerating = true;

              if (!isValid) isPreviewGenerating = false;
              if (isValid && slide?.mediaurl?.PreviewUrl) {
                isPreviewGenerating = false;
              }

              let templateValid = {
                isValid,
                isPreviewGenerating,
                templateKeys: moduleData[0],
                projectId: project.ProjectId,
                templateSlidekeys: slideData[0],
                templateScreenkeys: screenData[0],
                screenName:
                  moduleData[1].ModuleName +
                  '-' +
                  slideData[1].SlideName +
                  '-' +
                  screenData[1].ScreenName,
              };

              let hArray = 0;
              let pArray = 0;
              let bArray = 0;
              let imgArray = [];
              let videoArray = [];
              let illuArray = [];
              Object.entries(slide).map((screen, key) => {
                if (screen[1].Contenttype === 'OST') {
                  if (screen[1].Contentparams.contentdescription.slice(-3) === '[H]') {
                    hArray++;
                  } else if (screen[1].Contentparams.contentdescription.slice(-3) === '[P]') {
                    pArray++;
                  } else if (screen[1].Contentparams.contentdescription.slice(-3) === '[B]') {
                    bArray++;
                  }
                }
                if (screen[1].Contenttype === 'Image') {
                  imgArray.push(screen[0]);
                }
                if (screen[1].Contenttype === 'Video') {
                  videoArray.push(screen[0]);
                }
                if (screen[1].Contenttype === 'Illustration') {
                  illuArray.push(screen[0]);
                }
              });

              templateValid['HeadingCount'] = hArray + '-H OST,';
              templateValid['ParagraphCount'] = pArray + '-P OST,';
              templateValid['BulletCount'] = bArray + '-B OST,';
              templateValid['ImageCount'] = imgArray.length + '-Images,';
              templateValid['VideoCount'] = videoArray.length + '-Videos';
              templateValid['IllustrationCount'] = illuArray.length + '-Illustration,';

              templateValidArray.push(templateValid);
            }
          });
        });
      });
    });
    setTemplateCheckArray(templateValidArray);
    setTemplateCheckArrayCache(templateValidArray);
  };

  const GotoProject = async () => {
    // let project_id = props.match.params.id;
    // if (AdminRole === 1) {
    //   await getUsersProjectRole(userId).then(response => {
    //     try {
    //       setPageLoad(true);
    //       if (response) {
    //         setPageLoad(false);
    //         let tempArray = [];
    //         let projectRole = response.data.result;
    //         projectRole.map(projectrole => {
    //           if (projectrole.projectId === project_id && projectrole.viewProject === false) {
    //             setProjectRolePreview(true);
    //             history.push('/project-list');
    //           } else {
    //             if (EditMode) {
    //               if (clientSetting.manualTemplate) {
    //                 history.push({
    //                   pathname: `/project-preview/${projects.ProjectId}`,
    //                   state: {
    //                     ActiveModule: ActiveModule,
    //                     ActiveSlide: ActiveSlide,
    //                     ActiveScreen: ActiveScreen,
    //                     type: 'templateSelection',
    //                   },
    //                 });
    //               } else {
    //                 history.push({
    //                   pathname: `/project-preview/${projects.ProjectId}`,
    //                   state: {
    //                     ActiveModule: ActiveModule,
    //                     ActiveSlide: ActiveSlide,
    //                     ActiveScreen: ActiveScreen,
    //                   },
    //                 });
    //               }
    //             } else {
    //               if (clientSetting.manualTemplate) {
    //                 history.push({
    //                   pathname: `/project-preview/${projects.ProjectId}`,
    //                   state: {
    //                     type: 'templateSelection',
    //                   },
    //                 });
    //               } else {
    //                 history.push(`/project-preview/${projects.ProjectId}`);
    //               }
    //             }
    //           }
    //           return projectrole;
    //         });
    //         //   setProjectRoleProjectId(tempArray)
    //       } else {
    //         setPageLoad(false);
    //         showToaster('Something went wrong. Please Try Again1', 'warning');
    //       }
    //     } catch (error) {
    //       setPageLoad(false);
    //       showToaster('Something went wrong. Please Try Again', 'warning');
    //     }
    //   });
    // } else {
    if (project.EditMode) {
      if (clientSetting.manualTemplate) {
        history.push({
          pathname: `/project-preview/${project.ProjectId}`,
          state: {
            ActiveModule: project.ActiveModule,
            ActiveSlide: project.ActiveSlide,
            ActiveScreen: project.ActiveScreen,
            type: 'templateSelection',
          },
        });
      } else {
        history.push({
          pathname: `/project-preview/${project.ProjectId}`,
          state: {
            ActiveModule: project.ActiveModule,
            ActiveSlide: project.ActiveSlide,
            ActiveScreen: project.ActiveScreen,
          },
        });
      }
    } else {
      if (clientSetting.manualTemplate) {
        history.push({
          pathname: `/project-preview/${project.ProjectId}`,
          state: {
            type: 'templateSelection',
          },
        });
      } else {
        history.push(`/project-preview/${project.ProjectId}`);
      }
    }
    // }
  };

  const generatePreviewWithProjectId = async (
    ProjectName,
    projectData,
    projectId,
    { moduleId, slideId, screenId } = {},
    forceGenerate = false
  ) => {
    if (
      !(
        projectData[projectId]['Modules'][moduleId]['slides'][slideId]['Screens'][screenId]['TM01'][
          'Contentparams'
        ]['contentdescription'] === 'Null' ||
        projectData[projectId]['Modules'][moduleId]['slides'][slideId]['Screens'][screenId]['TM01'][
          'Contentparams'
        ]['contentdescription'] === '' ||
        projectData[projectId]['Modules'][moduleId]['slides'][slideId]['Screens'][screenId]['TM01'][
          'Contentparams'
        ]['contentdescription'] === 'no template'
      )
    ) {
      const SlideAudioVO = projectData[projectId]?.['Modules'][moduleId]?.['slides']?.[slideId]?.[
        'SlideAudioVO'
      ]
        ? projectData[projectId]?.['Modules'][moduleId]?.['slides'][slideId]?.['SlideAudioVO']
        : 'Null';

      const previewContent = {
        [projectId]: {
          ProjectName: ProjectName,
          Modules: {
            [moduleId]: {
              ModuleName: projectData[projectId]?.['Modules']?.[moduleId]?.['ModuleName'],
              slides: {
                [slideId]: {
                  SlideName:
                    projectData[projectId]?.['Modules']?.[moduleId]?.['slides'][slideId]?.[
                      'SlideName'
                    ],
                  SlideAudioVO: SlideAudioVO,
                  Screens: {
                    [screenId]: {
                      ScreenName:
                        projectData[projectId]?.['Modules']?.[moduleId]?.['slides']?.[slideId]?.[
                          'Screens'
                        ]?.[screenId]?.['ScreenName'],
                      ...projectData?.[projectId]?.['Modules']?.[moduleId]?.['slides']?.[slideId]?.[
                        'Screens'
                      ]?.[screenId],
                    },
                  },
                },
              },
            },
          },
        },
      };

      await generateProjectPreview(previewContent, forceGenerate);
    }
  };

  const generatePreview = async (projectData, { screen } = {}, forceGenerate = false) => {
    const activeScreen = screen || project.ActiveScreen;
    if (
      !(
        projectData[project.ProjectId]['Modules'][project.ActiveModule]['slides'][
          project.ActiveSlide
        ]['Screens'][activeScreen]['TM01']['Contentparams']['contentdescription'] === 'Null' ||
        projectData[project.ProjectId]['Modules'][project.ActiveModule]['slides'][
          project.ActiveSlide
        ]['Screens'][activeScreen]['TM01']['Contentparams']['contentdescription'] === '' ||
        projectData[project.ProjectId]['Modules'][project.ActiveModule]['slides'][
          project.ActiveSlide
        ]['Screens'][activeScreen]['TM01']['Contentparams']['contentdescription'] === 'no template'
      )
    ) {
      const SlideAudioVO = projectData[project.ProjectId]?.['Modules'][project.ActiveModule]?.[
        'slides'
      ]?.[project.ActiveSlide]?.['SlideAudioVO']
        ? projectData[project.ProjectId]?.['Modules'][project.ActiveModule]?.['slides'][
            project.ActiveSlide
          ]?.['SlideAudioVO']
        : 'Null';

      const previewContent = {
        [project.ProjectId]: {
          ProjectName: projectName,
          Modules: {
            [project.ActiveModule]: {
              ModuleName:
                projectData[project.ProjectId]?.['Modules']?.[project.ActiveModule]?.['ModuleName'],
              slides: {
                [project.ActiveSlide]: {
                  SlideName:
                    projectData[project.ProjectId]?.['Modules']?.[project.ActiveModule]?.['slides'][
                      project.ActiveSlide
                    ]?.['SlideName'],
                  SlideAudioVO: SlideAudioVO,
                  Screens: {
                    [activeScreen]: {
                      ScreenName:
                        projectData[project.ProjectId]?.['Modules']?.[project.ActiveModule]?.[
                          'slides'
                        ]?.[project.ActiveSlide]?.['Screens']?.[activeScreen]?.['ScreenName'],
                      ...projectData?.[project.ProjectId]?.['Modules']?.[project.ActiveModule]?.[
                        'slides'
                      ]?.[project.ActiveSlide]?.['Screens']?.[activeScreen],
                    },
                  },
                },
              },
            },
          },
        },
      };

      await generateProjectPreview(previewContent, forceGenerate);
    }
  };

  const saveProjectData = (projectDataPayload, generatePreviewData) => {
    updateProject(projectDataPayload).then(async response => {
      try {
        const projectData = JSON.parse(response.ProjectData);
        setCacheProject({ ...response, ProjectData: projectData });

        const createResponse = await CreateProjectFromResponse(response);
        createResponse.ActiveModule = project.ActiveModule;
        createResponse.ActiveSlide = project.ActiveSlide;
        createResponse.ActiveScreen = project.ActiveScreen;
        EditProject(createResponse);
        templateValidateFunction(projectData);
        setValidationError([]);

        setSelectedTemplate('');
        ClearPreviewGeneratedOf();

        if (generatePreviewData) {
          setRefreshTimeOut({
            [`${project.ActiveModule}-${project.ActiveSlide}-${project.ActiveScreen}`]: 60000,
          });
          generatePreview(projectData);
        }

        if (clientSetting.manualTemplate) {
          GotoProject();
        }

        UpdateEditMode(false);
        setIsSaving(false);

        let message = 'Project updated successfully';
        if (recommendedTemplates.length) {
          message = 'Project updated and preview is generating';
        }
        showToaster(message, 'success');
      } catch (error) {
        setIsSaving(false);
        console.log({ updateProjectError: error });
        showToaster('Something went wrong. Please Try Again', 'warning');
      }
    });
  };

  const saveProject = async () => {
    let validationErrorArray = [];

    try {
      EmptyPreview();

      const previousKeys = { module: null, slide: null, screen: null };
      const projectData = project.createdProject[project.ProjectId].Modules;
      let previousItems = null;
      let name;
      let screenDataError = false;

      const projectDataArray = Object.entries(projectData);
      for (const [moduleKey, moduleValue] of projectDataArray) {
        const slidesArray = Object.entries(moduleValue.slides);

        if (screenDataError) break;

        for (const [slideKey, slideValue] of slidesArray) {
          const screenArray = Object.entries(slideValue.Screens);

          if (screenDataError) break;

          for (const [screenKey, screenValue] of screenArray) {
            const screenValueArray = Object.values(screenValue);
            let mediaItems = {
              ost: 0,
              ostH: 0,
              ostP: 0,
              ostB: 0,
              video: 0,
              images: 0,
              illustrations: 0,
            };

            for (const screenValueData of screenValueArray) {
              if (
                screenValueData.Contenttype === 'Image' &&
                screenValueData.Contentparams.contenttag &&
                screenValueData.Contentparams.contenttag !== 'Null' &&
                screenValueData.Contentparams.searchstring
              ) {
                mediaItems.images++;
              } else if (
                screenValueData.Contenttype === 'Video' &&
                screenValueData.Contentparams.contenttag &&
                screenValueData.Contentparams.contenttag !== 'Null' &&
                screenValueData.Contentparams.searchstring
              ) {
                mediaItems.video++;
              } else if (
                screenValueData.Contenttype === 'Illustration' &&
                screenValueData.Contentparams.contenttag &&
                screenValueData.Contentparams.contenttag !== 'Null' &&
                screenValueData.Contentparams.searchstring
              ) {
                mediaItems.illustrations++;
              }

              if (!previousItems) {
                previousItems = { ...mediaItems };
                continue;
              }
            }

            let templateItem = screenValue['TM01'];

            if (templateItem.Contentparams.useTemplateStatus) {
              let message =
                moduleValue.ModuleName + '-' + slideValue.SlideName + '-' + screenValue.ScreenName;
              if (previousItems.images !== mediaItems.images) {
                message = message + ' image is not matched to previous screen';

                showToast(message);
                screenDataError = true;
                break;
              } else if (previousItems.video !== mediaItems.video) {
                message = message + ' video is not matched to previous screen';

                showToast(message);

                screenDataError = true;
                break;
              } else if (previousItems.illustrations !== mediaItems.illustrations) {
                message = message + ' illustrations is not matched to previous screen';

                showToast(message);

                screenDataError = true;
                break;
              } else if (previousItems.illustrations !== mediaItems.illustrations) {
                message = message + ' illustrations is not matched to previous screen';

                showToast(message);

                screenDataError = true;
                break;
              } else if (previousItems.ost !== mediaItems.ost) {
                message = message + ' ost is not matched to previous screen';

                showToast(message);

                screenDataError = true;
                break;
              } else if (previousItems.ostB !== mediaItems.ostB) {
                message = message + ' ost bullet is not matched to previous screen';

                showToast(message);

                screenDataError = true;
                break;
              } else if (previousItems.ostH !== mediaItems.ostH) {
                message = message + ' ost heading is not matched to previous screen';

                showToast(message);

                screenDataError = true;
                break;
              } else if (previousItems.ostP !== mediaItems.ostP) {
                message = message + ' ost paragraph is not matched to previous screen';

                showToast(message);

                screenDataError = true;
                break;
              }

              name =
                previousKeys.module +
                '_' +
                previousKeys.slide +
                '_' +
                previousKeys.screen +
                '_TM01';

              templateItem.Contentparams.sourcescreen = name;
            }

            previousKeys.module = moduleKey;
            previousKeys.slide = slideKey;
            previousKeys.screen = screenKey;

            previousItems = { ...mediaItems };
          }
          previousKeys.slide = slideKey;
        }
        previousKeys.module = moduleKey;
      }

      projectDataArray.map(([moduleKey2, moduleValue2]) => {
        const slideArray2 = Object.entries(moduleValue2.slides);

        slideArray2.map(([slideKey2, slideValue2]) => {
          const screenArray2 = Object.entries(slideValue2.Screens);

          screenArray2.map(([screenKey2, screenValue2]) => {
            const screenValue2Array = Object.entries(screenValue2);

            screenValue2Array.sort((a, b) => {
              const isTa = /^TM/.test(a[0]);
              const isTb = /^TM/.test(b[0]);

              if (isTa && !isTb) return 1;
              if (isTb && !isTa) return -1;
              return 0;
            });

            let OSTFound = false;
            screenValue2Array.map(([screenValue2Key, screenValue2Value]) => {
              if (screenValue2Value.Contenttype === 'OST') {
                OSTFound = true;
                if (!screenValue2Value.Contentparams.contentdescription) {
                  let ostData = '';
                  if (screenValue2Value.Contentparams.fontTag === HEADERFONT) {
                    ostData = screenValue2Key.replace('OS', 'Header ');
                  } else if (screenValue2Value.Contentparams.fontTag === PARAGRAPHFONT) {
                    ostData = screenValue2Key.replace('OS', 'Paragraph ');
                  }
                  if (screenValue2Value.Contentparams.fontTag === BULLETFONT) {
                    ostData = screenValue2Key.replace('OS', 'List ');
                  }

                  validationErrorArray.push(
                    moduleValue2.ModuleName +
                      '-' +
                      slideValue2.SlideName +
                      '-' +
                      screenValue2.ScreenName +
                      '-' +
                      ostData
                  );
                }
              } else if (screenValue2Value.Contenttype === 'AudioScript') {
                if (!screenValue2Value.Contentparams.contentdescription) {
                  validationErrorArray.push(
                    moduleValue2.ModuleName +
                      '-' +
                      slideValue2.SlideName +
                      '-' +
                      screenValue2.ScreenName +
                      ' Audio Script'
                  );
                }
              }
            });

            if (!OSTFound) {
              validationErrorArray.push('Atleast one Header / Paragraph / List');
            }
          });
        });
      });

      setValidationError(validationErrorArray);

      compareActiveAudioOST(project.ActiveModule, project.ActiveSlide, project.ActiveScreen);

      if (!screenDataError) {
        if (!validationErrorArray.length) {
          let { updatedProjectData, slideChanges, hasDataChanged, useDifferentTemplate } =
            checkScreenEdit();

          let urlArray;
          if (cacheProject.renderUrls) {
            urlArray = JSON.parse(JSON.stringify(cacheProject.renderUrls));
            Object.keys(cacheProject.renderUrls).map(key => {
              Object.keys(cacheProject.renderUrls[key]).map(k => {
                slideChanges.map(slideChange => {
                  if (slideChange === key + '-' + k) {
                    delete urlArray[key][k];
                  }
                });
              });
            });
          }

          const generatePreviewData = hasDataChanged || selectedTemplate || useDifferentTemplate;
          if (generatePreviewData) {
            if (!clientSetting.manualTemplate) {
              updatedProjectData = await templateForLastActiveScreen(
                updatedProjectData,
                useDifferentTemplate
              );
            }
          }

          setIsSaving(true);

          const updatedProjectDataForDB = JSON.parse(JSON.stringify(updatedProjectData));
          const projectDataForAPI = await removeMediaUrlFilter(
            updatedProjectDataForDB,
            project.ProjectId
          );

          let renderSlides = {};
          if (cacheProject.renderSlides) {
            renderSlides = { ...cacheProject.renderSlides };
          }

          if (generatePreviewData) {
            renderSlides = {
              ...renderSlides,
              [`${project.ActiveModule}-${project.ActiveSlide}`]: false,
            };
          }

          const projectDataPayload = {
            dbdata: {
              [project.ProjectId]: updatedProjectData[project.ProjectId],
            },
            APIData: {
              [project.ProjectId]: projectDataForAPI[project.ProjectId],
            },
            RenderUrl: {
              renderUrls: urlArray,
            },
            type: 'Update',
            renderSlides,
          };

          saveProjectData(projectDataPayload, generatePreviewData);
        }
      }
    } catch (error) {
      console.log({ saveProjectError: error });
    }
  };

  const hasErrorScreenInSlide = templateCheckArray?.find(template => !template.isValid);

  const hasTemplateError = (activeScreen = project.ActiveScreen) => {
    const findTemplate = templateCheckArray?.find(
      template =>
        template.templateKeys === project.ActiveModule &&
        template.templateSlidekeys === project.ActiveSlide &&
        template.templateScreenkeys === activeScreen
    );

    if (findTemplate) {
      return {
        isInValid: !findTemplate.isValid,
        isPreviewGenerating: findTemplate.isPreviewGenerating,
      };
    }

    return { isInvalid: false, isPreviewGenerating: false };
  };

  const hasErrorOrPreview = hasTemplateError();

  const reRenderProject = async () => {
    setDisableBack(true);
    setRenderPercetange(
      'Some rendering errors occured, we are working out and initiated the Re-Render'
    );
    let projectDatafromDb = await getProject(params.id);
    let urlArray;
    let slideRenderArray = [];
    let slideRenderData = JSON.parse(projectDatafromDb.ProjectData);
    slideRenderData[projectId].Modules[project.ActiveModule].slides[
      project.ActiveSlide
    ].SlideRender = '1';
    slideRenderArray = [
      {
        slideId: project.ActiveSlide,
        moduleId: project.ActiveModule,
      },
    ];
    setSlideRenderDataArray(slideRenderArray);
    if (slideRenderData != undefined) {
      if (slideRenderData[projectId] != null) {
        if (slideRenderData[projectId].Modules != undefined) {
          // let c = 0;
          Object.keys(slideRenderData[projectId].Modules).map(module => {
            Object.keys(slideRenderData[projectId].Modules[module].slides).map(slide => {
              if (slide === project.ActiveSlide && module === project.ActiveModule) {
                slideRenderData[projectId].Modules[module].slides[slide].SlideRender = '1';
              } else {
                slideRenderData[projectId].Modules[module].slides[slide].SlideRender = '0';
              }
            });
          });
        }
      }
    }

    let updatedProjectDataforDb = JSON.stringify(slideRenderData);
    let projectDataforAPI = await removeMediaUrlFilter(
      JSON.parse(updatedProjectDataforDb),
      projectId
    );
    let renderUrls = projectDatafromDb.renderUrls;
    const newProjectId = await nanoid();

    let renderSlides = {};
    if (cacheProject.renderSlides) {
      renderSlides = { ...cacheProject.renderSlides };
    }

    renderSlides = {
      ...renderSlides,
      [`${project.ActiveModule}-${project.ActiveSlide}`]: true,
    };
    let projectDataReq = {
      dbdata: {
        [projectId]: slideRenderData[projectId],
      },
      APIData: {
        [projectId]: projectDataforAPI[projectId],
      },
      RenderUrl: {
        renderUrls: renderUrls,
      },
      RenderData: {
        [newProjectId]: projectDataforAPI[projectId],
      },
      slideModuleData: {
        slideRenderDatas: slideRenderArray,
      },
      type: 'sliderender',
      renderSlides,
    };

    preRenderProject(JSON.stringify(projectDataReq)).then(async response => {
      try {
        setIsLoading(true);
        if (
          response !== '' &&
          response !== undefined &&
          response !== null &&
          response.data !== '' &&
          response.data !== undefined &&
          response.data !== null
        ) {
          if (response.status === 200 && response.status !== null && response.status !== null) {
            if (!response.data.is_valid) {
              showToaster('Project is not valid', 'success');
              handleBackClick();
            } else if (response.data.error !== 'Null') {
              showToaster(response.data.error, 'warning');
              handleBackClick();
            } else {
              showToaster('Project Updated successfully', 'success');
              renderProject(newProjectId);
            }
            setIsLoading(false);
          } else {
            setIsLoading(false);
            showToaster(response.message, 'warning');
            handleBackClick();
          }
        } else {
          setIsLoading(false);
          showToaster('Something went wrong. Please Try Again', 'warning');
          handleBackClick();
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        showToaster('Something went wrong. Please Try Again', 'warning');
        handleBackClick();
      }
    });
  };

  const saveRenderSlide = async () => {
    setDisableBack(true);
    let activeSlideRenderUrl = null;
    let isCrashed = false;
    if (cacheProject.renderUrls) {
      activeSlideRenderUrl = cacheProject.renderUrls[project.ActiveModule]?.[project.ActiveSlide];
      isCrashed = !_.isEmpty(activeSlideRenderUrl?.crashed);
    }

    const renderKey = `${project.ActiveModule}-${project.ActiveSlide}`;
    const alreadyRendered = cacheProject.renderSlides?.[renderKey];

    /* Old data (previously rendered videos before this feature) doesn't have renderSlides data */
    const oldRenderedData = !cacheProject.renderSlides;

    if (alreadyRendered && !isCrashed && (activeSlideRenderUrl || oldRenderedData)) {
      setRenderName(renderKey);
      setRenderVideos([activeSlideRenderUrl]);
      setDisableBack(false);
      return setRenderPercetange('');
    }

    try {
      setIsLoading(true);
      const check = await checkMaxParallelRender();

      if (!check.goForRender) {
        setIsLoading(false);
        setCurrentView('screen');
        const timer = 0;
        const showCloseButton = true;
        return showToaster(
          'Please wait till all your current rendering jobs are complete',
          'error',
          timer,
          showCloseButton
        );
      }

      let projectDatafromDb = await getProject(params.id);
      let urlArray;
      let slideRenderArray = [];
      let slideRenderData = JSON.parse(projectDatafromDb.ProjectData);
      slideRenderData[projectId].Modules[project.ActiveModule].slides[
        project.ActiveSlide
      ].SlideRender = '1';
      slideRenderArray = [
        {
          slideId: project.ActiveSlide,
          moduleId: project.ActiveModule,
        },
      ];
      setSlideRenderDataArray(slideRenderArray);
      if (slideRenderData != undefined) {
        if (slideRenderData[projectId] != null) {
          if (slideRenderData[projectId].Modules != undefined) {
            // let c = 0;
            Object.keys(slideRenderData[projectId].Modules).map(module => {
              Object.keys(slideRenderData[projectId].Modules[module].slides).map(slide => {
                if (slide === project.ActiveSlide && module === project.ActiveModule) {
                  slideRenderData[projectId].Modules[module].slides[slide].SlideRender = '1';
                } else {
                  slideRenderData[projectId].Modules[module].slides[slide].SlideRender = '0';
                }
                // if (projectDatafromDb) {
                //   if (
                //     projectDatafromDb.renderUrls !== undefined &&
                //     projectDatafromDb.renderUrls !== null &&
                //     projectDatafromDb.renderUrls !== ''
                //   ) {
                //     urlArray = projectDatafromDb.renderUrls;
                //     if (urlArray !== null && urlArray !== undefined) {
                //       Object.keys(urlArray).map(function (key, index) {
                //         Object.keys(urlArray[key]).map(function (k, i) {
                //           if (key.startsWith('M') && k.startsWith('SL')) {
                //             if (k === project.ActiveSlide && key === project.ActiveModule) {
                //               if (urlArray[key][k].url !== '') {
                //                 //need to work on this, show render already exist and reset render url if any screen data changed
                //                 // slideRenderData[projectId].Modules[module].slides[slide].SlideRender = '0';
                //               } else {
                //                 slideRenderData[projectId].Modules[module].slides[
                //                   slide
                //                 ].SlideRender = '1';
                //               }
                //             }
                //           }
                //         });
                //       });
                //     }
                //   }
                // }
              });
            });
          }
        }
      }

      let updatedProjectDataforDb = JSON.stringify(slideRenderData);
      let projectDataforAPI = await removeMediaUrlFilter(
        JSON.parse(updatedProjectDataforDb),
        projectId
      );
      let renderUrls = projectDatafromDb.renderUrls;
      const newProjectId = await nanoid();

      let projectDataReq = {
        dbdata: {
          [projectId]: slideRenderData[projectId],
        },
        APIData: {
          [projectId]: projectDataforAPI[projectId],
        },
        RenderUrl: {
          renderUrls: renderUrls,
        },
        RenderData: {
          [newProjectId]: projectDataforAPI[projectId],
        },
        slideModuleData: {
          slideRenderDatas: slideRenderArray,
        },
        type: 'sliderender',
      };

      preRenderProject(JSON.stringify(projectDataReq)).then(async response => {
        try {
          if (
            response !== '' &&
            response !== undefined &&
            response !== null &&
            response.data !== '' &&
            response.data !== undefined &&
            response.data !== null
          ) {
            if (response.status === 200 && response.status !== null && response.status !== null) {
              if (!response.data.is_valid) {
                showToaster('Project is not valid', 'success');
                handleBackClick();
              } else if (response.data.error !== 'Null') {
                showToaster(response.data.error, 'warning');
                handleBackClick();
              } else {
                showToaster('Project Updated successfully', 'success');
                renderProject(newProjectId);
              }
              setIsLoading(false);
            } else {
              setIsLoading(false);
              showToaster(response.message, 'warning');
              handleBackClick();
            }
          } else {
            setIsLoading(false);
            showToaster('Something went wrong. Please Try Again', 'warning');
            handleBackClick();
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
          showToaster('Something went wrong. Please Try Again', 'warning');
          handleBackClick();
        }
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      showToaster('Something went wrong. Please Try Again', 'warning');
      handleBackClick();
    }
  };

  const deleteClusterHandler = async (rendercluster_name, renderSlidesData = null) => {
    await deleteCluster({ rendercluster_name, renderSlidesData });

    let hasClusterDeleted = false;

    while (!hasClusterDeleted) {
      console.log('looop Vitra');
      await timeout(30000);
      let filter = {
        resource_group: 'Content-Pro',
        cluster_name: process.env.REACT_APP_CLUSTER_NAME,
      };

      const clusterListResponse = await listClusterDetails(filter);
      console.log(clusterListResponse.data.Body);

      if (clusterListResponse.data && clusterListResponse.data.Body) {
        let clusterList = clusterListResponse.data.Body;
        if (!Object.keys(clusterList).includes(rendercluster_name)) {
          hasClusterDeleted = true;
          await updateClusterStatus({
            rendercluster_name: rendercluster_name,
            status: 'Deleted',
            isDelete: 1,
          });
        }
      }
    }
  };

  const createRenderCluster = async screenCount => {
    setClusterCreating(true);
    setRenderPercetange('Servers are getting ready for you to start render');
    let generatedRenderClusterName = await random11char();
    setRenderClusterName(generatedRenderClusterName);
    let values = {
      userId: orgId,
      resource_group: 'Content-Pro',
      cluster_name: process.env.REACT_APP_CLUSTER_NAME,
      rendercluster_name: generatedRenderClusterName,
      vm_size: 'Standard_D8as_v5',
      vm_count: screenCount,
      min_scale_vm_count: screenCount,
      max_scale_vm_count: screenCount,
      max_units: '1',
    };
    let saveClusterResponse = {};

    try {
      const response = await SaveClusterDetails(values);
      if (
        response !== '' &&
        response !== undefined &&
        response !== null &&
        response.data !== '' &&
        response.data !== undefined &&
        response.data !== null &&
        response.status == 200
      ) {
        saveClusterResponse = response.data;
        // set message cluster creating...
        // showToaster('Cluster created successfully', 'success');
      } else {
        console.log(response, 'clusterCreationRepsonse');
        //set message cluster Creation Failed
        throw new Error('Cluster Creation Failed.');
      }
    } catch (e) {
      console.log(e);
      showToaster('Something went wrong. Please Try Again', 'warning');
      //set message cluster Creation Failed
      // showToaster(response.message, "warning");
      throw new Error('Cluster Creation Failed.');
    }

    let hasClusterCreated = false;

    while (!hasClusterCreated) {
      await timeout(30000);
      let filter = {
        resource_group: 'Content-Pro',
        cluster_name: process.env.REACT_APP_CLUSTER_NAME,
      };

      const response = await listClusterDetails(filter);

      if (response.data && response.data.Body) {
        let clusterList = response.data.Body;
        if (Object.keys(clusterList).includes(generatedRenderClusterName)) {
          if (clusterList[generatedRenderClusterName].provisioning_state == 'Succeeded') {
            hasClusterCreated = true;
            setClusterCreating(false);
            await updateClusterStatus({
              rendercluster_name: generatedRenderClusterName,
              status: 'Created',
              isDelete: 0,
            });
          }
        }
      }
    }
    return values;
  };
  const timeout = (delay = 5000) => {
    return new Promise(res => setTimeout(res, delay));
  };

  const renderProject = async renderId => {
    try {
      // setstartRender(true);
      // let clusterArray = [];
      // let clusterArray1 = [];
      // let clusterDetails = null;
      // const response = await getClusterList('number_of_render_inprogress');
      // console.log(response, 'responsecluster');
      // if (
      //   response !== '' &&
      //   response !== undefined &&
      //   response !== null &&
      //   response.data !== '' &&
      //   response.data !== undefined &&
      //   response.data !== null
      // ) {
      //   // clusterDetails = response.data.result[0]

      //   if (response.data.result[0].status === false) {
      //   }

      //   let clusterorgId = orgId;
      //   response.data.result.map(details => {
      //     if (details.isDelete == 0 && details.status !== 'Deleting') {
      //       if (details.orgId == clusterorgId) {
      //         clusterArray.push(details);
      //       } else if (details.orgId == 'All') {
      //         clusterArray1.push(details);
      //       }
      //     }
      //   });
      //   if (clusterArray.length > 0) {
      //     //  random = Math.floor(Math.random() * clusterArray.length);
      //     //  clusterDetails=clusterArray[random]
      //     clusterDetails = clusterArray[0];
      //   } else if (clusterArray1.length > 0) {
      //     // random = Math.floor(Math.random() * clusterArray1.length);
      //     // clusterDetails=clusterArray1[random]
      //     clusterDetails = clusterArray1[0];
      //   }
      // } else {
      //   setIsLoading(false);
      //   showToaster('Cluster Selection Fail.', 'warning');
      //   handleBackClick();
      //   return false;
      // }
      // if (!clusterDetails) {
      //   setIsLoading(false);
      //   showToaster('No Suitable cluster Found.', 'warning');
      //   handleBackClick();
      //   return false;
      // }
      setRenderNote('We are getting your servers ready. Please do not close this page.');
      let totalScreen = Object.keys(
        project.createdProject[project.ProjectId].Modules[project.ActiveModule].slides[
          project.ActiveSlide
        ].Screens
      ).length;
      let clusterDetails = await createRenderCluster(totalScreen);
      let slideRenderArray = [
        {
          slideId: project.ActiveSlide,
          moduleId: project.ActiveModule,
        },
      ];
      let renderFilter = {
        project_id: renderId,
        projectName: projectName,
        cluster_name: clusterDetails.cluster_name,
        render_cluster_name: clusterDetails.rendercluster_name,
        parallel_process_count: totalScreen,
      };
      let filter = {
        project_id: renderId,
        user_id: orgId,
      };
      let projectDatafromDb = await getProjectID(filter);
      if (slideRenderArray.length > 0) {
        setIsRendering(true);
        await projectRender(renderFilter).then(async response => {
          if (response.status !== undefined && response.status !== null && response.status !== '') {
            // timerRef.current = setTimeout(() => {
            //   getPreview(projectId,project);
            // }, 5000);
            setDisableBack(false);
            let renderProjectDetails = await getProject(params.id);
            let newDate = new Date();
            let renderName = `${project.ActiveModule}-${
              project.ActiveSlide
            }-${newDate.toGMTString()}`;
            setRenderName(renderName);
            setTemplateAvailable(true);
            if (projectDatafromDb.data[0].Is_Valid == 1) {
              renderProjectDetails.rendercluster_name = clusterDetails.rendercluster_name;
              createRenderProject(
                { ...renderProjectDetails, renderName: renderName },
                renderId
              ).then(response => {
                if (
                  response.status !== undefined &&
                  response.status !== null &&
                  response.status !== ''
                ) {
                  // timerRef.current = setTimeout(() => {
                  //   getPreview(projectId,project);
                  // }, 5000);
                } else if (
                  response.message !== undefined &&
                  response.message !== null &&
                  response.message !== ''
                ) {
                  // setRenderMessage(response.message);
                } else {
                  ShowAlertBox('Oops...', 'Something went wrong. Please try again', 'error');
                }
                getPreview(renderId, clusterDetails.rendercluster_name);
                setRenderNote(
                  <>
                    Your video is rendering. You will receive an email notification once the
                    rendering is complete. Click{' '}
                    <a href="/" target="_blank">
                      here
                    </a>{' '}
                    to go to your Dashboard.{' '}
                  </>
                );
              });
            }
          } else if (
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ''
          ) {
            // setRenderMessage(response.message);
          } else {
            ShowAlertBox('Oops...', 'Something went wrong. Please try again', 'error');
          }
        });
      } else {
        // setrenderStatus(true);
        var arrayValue = [];
        let renderProjectDetails = await getProject(params.id);
        renderProjectDetails.rendercluster_name = clusterDetails.rendercluster_name;
        await createRenderProject(renderProjectDetails, renderId).then(async response => {
          if (renderProjectDetails) {
            let slidId = renderProjectDetails.slideIds;
            let renderUrls = renderProjectDetails.renderUrls;

            if (renderUrls !== null && renderUrls !== undefined) {
              Object.keys(renderUrls).map(function (key, index) {
                Object.keys(renderUrls[key]).map(function (k, i) {
                  if (key.startsWith('M') && k.startsWith('SL')) {
                    slidId.map(slides => {
                      if (key == slides.moduleId && k == slides.slideId) {
                        if (renderUrls[key][k].SlideRender == '1') {
                          arrayValue.push(renderUrls[key][k]);
                        }
                      }
                    });
                  }
                });
              });
              if (arrayValue.length > 0) {
                if (arrayValue !== undefined && arrayValue !== null && arrayValue !== '') {
                  setRenderVideos(arrayValue);
                }
                let filter = {
                  project_id: renderId,
                  project_name: renderProjectDetails.ProjectName,
                  mediaurl: renderUrls,
                  renderstatus: '100%',
                };
                await emailNotification(filter).then(response => {});
              }
            }
          }
          if (response.status !== undefined && response.status !== null && response.status !== '') {
            // timerRef.current = setTimeout(() => {
            //   getPreview(projectId,project);
            // }, 5000);
          } else if (
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ''
          ) {
            // setRenderMessage(response.message);
          } else {
            ShowAlertBox('Oops...', 'Something went wrong. Please try again', 'error');
          }
        });
      }
    } catch (error) {
      console.log(error, 'error');
      setIsLoading(false);
      setIsRendering(false);
      showToaster('something went wrong on render', 'warning');
      handleBackClick();
    }
  };

  const getPreview = async (ProjectID, rendercluster_name) => {
    const renderFilter = {
      project_id: ProjectID,
      projectName: projectName,
    };

    getProjectStatus(renderFilter)
      .then(async response => {
        if (
          response.data !== undefined &&
          response.data !== null &&
          response.data !== '' &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200
        ) {
          setProjectStatusFailedCount(0);
          if (response.data.is_valid === 0) {
            //alert("in valid");
            // close network
            // setstartRender(false);
            // props.closePreview();
            showToaster('There is no valid data for rendering the project!!', 'warning');
            setIsRendering(false);
          } else if (response.apiStatus === false) {
            // setstartRender(false);
            setIsRendering(false);
            // props.closePreview();
            showToaster('Error, please try rendering video again', 'warning');
          } else if (response.message == 'Error, please try rendering video again') {
            // setstartRender(false);
            setIsRendering(false);
            // props.closePreview();
            showToaster('Error, please try rendering video again', 'warning');
          } else {
            if (
              response.data.mediaurl !== undefined &&
              response.data.mediaurl !== null &&
              response.data.mediaurl !== '' &&
              Object.keys(response.data.mediaurl).length !== 0
            ) {
              if (response.data.mediaurl.status == 'server_error, please try again!') {
                setServerError(true);
                await deleteClusterHandler(rendercluster_name);
                setRenderPercetange('Progress Percent ' + response.data.renderstatus);
                if (renderAttempt < clientSetting.maxRenderAttempt) {
                  setIsRendering(true);
                  setRenderPercetange(
                    'Some rendering errors occured, we are working out and initiated the Re-Render'
                  );
                  setRenderAttempt(renderAttempt + 1);
                  reRenderProject();
                } else {
                  setRenderPercetange(
                    'Your Rendering failed with data. Please do edit the video and render again'
                  );
                  setIsRendering(false);
                  setClusterCreating(false);
                }
                // showToaster('Error, please try rendering video again', 'error');

                return;
              }

              if (
                response.data.mediaurl.status == 'Server error! Please check your quota limits.'
              ) {
                setServerError(true);
                await deleteClusterHandler(rendercluster_name);
                setRenderPercetange('Progress Percent ' + response.data.renderstatus);
                if (renderAttempt < clientSetting.maxRenderAttempt) {
                  setIsRendering(true);
                  setRenderPercetange(
                    'Some rendering errors occured, we are working out and initiated the Re-Render'
                  );
                  setRenderAttempt(renderAttempt + 1);
                  reRenderProject();
                } else {
                  setRenderPercetange(
                    'Your Rendering failed with data. Please do edit the video and render again'
                  );
                  setClusterCreating(false);
                  setIsRendering(false);
                }
                // showToaster('Server error! Please check your quota limits.', 'warning');

                // setrenderStatus(false);
                return;
              }
              if (response.data.mediaurl.status === 'Rendering completed') {
                setIsRendering(false);
                setClusterCreating(true);
                setRenderPercetange('Your video is getting ready to be downloaded.');

                let renderSlides = {};
                if (cacheProject.renderSlides) {
                  renderSlides = { ...cacheProject.renderSlides };
                }

                renderSlides = {
                  ...renderSlides,
                  [`${project.ActiveModule}-${project.ActiveSlide}`]: true,
                };
                await deleteClusterHandler(rendercluster_name, {
                  renderSlides,
                  projectId: project.ProjectId,
                });
                setCacheProject(cache => ({ ...cache, renderSlides }));

                setClusterCreating(false);
                setRenderPercetange('Progress Percent ' + response.data.renderstatus);
                setRenderNote('');
                // setrenderStatus(true);
                // setrenderStatus(
                //   Number(
                //     response.data.renderstatus.substring(
                //       0,
                //       response.data.renderstatus.length - 1
                //     )
                //   )
                // );
                //setPriviewPageLoad(false);
                //setGetRenderVideo(false);
                var arrayValue = [];
                let mediaurl = response.data.mediaurl;
                let urlArray;
                // let projectDatafromDb = await getProjectById(props.projectId);
                // let projectDetails = projectDatafromDb;
                let renderProjectDetails = await getProject(params.id);
                if (renderProjectDetails) {
                  let slidId = renderProjectDetails.slideIds;
                  let renderUrls = renderProjectDetails.renderUrls;

                  if (renderUrls !== null && renderUrls !== undefined) {
                    Object.keys(renderUrls).map(function (key, index) {
                      Object.keys(renderUrls[key]).map(function (k, i) {
                        if (key.startsWith('M') && k.startsWith('SL')) {
                          slidId.map(slides => {
                            if (key == slides.moduleId && k == slides.slideId) {
                              if (renderUrls[key][k].SlideRender == '1') {
                                mediaurl[key][k] = renderUrls[key][k];
                              }
                              // mediaurl[slidId.moduleId][slides.slideId]=renderUrls[key][k]
                            }
                          });
                        }
                      });
                    });
                  }
                }
                // var arrayValue = [];
                // let mediaurl = response.data.mediaurl;
                Object.keys(mediaurl).map(function (key, index) {
                  Object.keys(mediaurl[key]).map(function (k, i) {
                    if (mediaurl[key][k].SlideRender === '1') {
                      arrayValue.push(mediaurl[key][k]);
                    }
                  });
                });
                // }
                // if (response.data.renderstatus !== "100%") {
                //   timerRef.current = setTimeout(() => {
                //     getPreview(ProjectID);
                //   }, 5000);
                // }
                if (arrayValue.length > 0) {
                  if (arrayValue !== undefined && arrayValue !== null && arrayValue !== '') {
                    setCacheProject({
                      ...renderProjectDetails,
                      ProjectData: JSON.parse(renderProjectDetails.ProjectData),
                    });
                    setRenderVideos(arrayValue);
                  }
                  if (!_.isEmpty(arrayValue[0].crashed)) {
                    setRenderPercetange('Your Request is being processed');
                    if (renderAttempt < clientSetting.maxRenderAttempt) {
                      setIsRendering(true);
                      setRenderPercetange(
                        'Some rendering errors occured, we are working out and initiated the Re-Render'
                      );
                      setRenderAttempt(renderAttempt + 1);
                      reRenderProject();
                    } else {
                      setRenderPercetange(
                        'Your Rendering failed with data. Please do edit the video and render again'
                      );
                    }
                    // showToaster('Render Crashed', 'error');
                  }
                }
                showToaster('Rendering Complete', 'success');
              } else {
                clearTimer();
                timerRef.current = setTimeout(() => {
                  getPreview(ProjectID, rendercluster_name);
                }, 5000);
              }
              setRenderPercetange('Progress Percent ' + response.data.renderstatus);
            } else if (
              response.message !== undefined &&
              response.message !== null &&
              response.message !== ''
            ) {
              clearTimer();
              timerRef.current = setTimeout(() => {
                getPreview(ProjectID, rendercluster_name);
              }, 5000);
            } else {
              if (response.message === 'string index out of range') {
                // setPriviewPageLoad(false);
                // this.setState({ templateErrorMsg: 'You have provided Insufficient data to generate Preview. Please add sufficient data'})
              }
              // this.setState({
              //     openRenderModal: false
              // }, () => {
              //     this.setState({ openTemplateError: true })
              // })
            }
          }
        } else if (
          response.data !== undefined &&
          response.data !== null &&
          response.data !== '' &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 500
        ) {
          if (projectStatusFailedCount < 3) {
            setProjectStatusFailedCount(projectStatusFailedCount++);
            clearTimer();
            timerRef.current = setTimeout(() => {
              getPreview(ProjectID, rendercluster_name);
            }, 5000);
          } else {
            setProjectStatusFailedCount(0);
            setIsRendering(false);
            await deleteClusterHandler(rendercluster_name);
            setClusterCreating(false);
            setRenderPercetange('Error, please try rendering video again');
          }
        }
      })
      .catch(async err => {
        console.log(err);
        await deleteClusterHandler(rendercluster_name);
        setClusterCreating(false);
        setRenderPercetange('Something went wrong Please try again');
      });
  };

  const handleCancle = () => {
    const copyData = JSON.parse(JSON.stringify(cacheProject));

    const ProjectData = JSON.stringify(copyData.ProjectData);

    generateProjectData({
      clientSettingData: clientSetting,
      projectData: copyData.ProjectData,
      projectResponse: { ...copyData, ProjectData: ProjectData },
    });
  };

  const updateModuleOrSlideName = async (updateData, type, name) => {
    const updateDataWithOutMediaUrl = await removeMediaUrlFilter(updateData, project.ProjectId);
    const projectDataPayload = {
      type: 'Update',
      dbdata: updateDataWithOutMediaUrl,
      APIData: updateDataWithOutMediaUrl,
      RenderUrl: { renderUrls: cacheProject.renderUrls },
    };

    setIsSaving(true);
    updateProject(projectDataPayload).then(async response => {
      try {
        const projectData = JSON.parse(response.ProjectData);
        setCacheProject({ ...response, ProjectData: projectData });

        setIsSaving(false);

        if (type === 'Module') {
          RenameModuleName(name);
        } else if (type === 'Video') {
          RenameSlideName(name);
        }

        showToaster(`${type} name is updated`, 'success');
      } catch (error) {
        setIsSaving(false);
        console.log({ updateModuleOrSlideNameError: error });
        showToaster('Something went wrong. Please Try Again', 'warning');
      }
    });
  };

  const updateSlideName = slideName => {
    const cacheProjectIdData = cacheProject.ProjectData[project.ProjectId];

    if (!cacheProjectIdData?.Modules?.[project.ActiveModule]?.slides?.[project.ActiveSlide]) {
      showToaster('You must save this video before renaming', 'error', 5000);
      return { revert: true };
    }

    const updateData = {
      [project.ProjectId]: {
        ...cacheProjectIdData,
        Modules: {
          ...cacheProjectIdData.Modules,
          [project.ActiveModule]: {
            ...cacheProjectIdData.Modules[project.ActiveModule],
            slides: {
              ...cacheProjectIdData.Modules[project.ActiveModule].slides,
              [project.ActiveSlide]: {
                ...cacheProjectIdData.Modules[project.ActiveModule].slides[project.ActiveSlide],
                SlideName: slideName,
              },
            },
          },
        },
      },
    };

    updateModuleOrSlideName(updateData, 'Video', slideName);
  };

  const updateModuleName = moduleName => {
    const cacheProjectIdData = cacheProject.ProjectData[project.ProjectId];

    if (!cacheProjectIdData?.Modules?.[project.ActiveModule]) {
      showToaster('You must save this module before renaming', 'error', 5000);
      return { revert: true };
    }

    const updateData = {
      [project.ProjectId]: {
        ...cacheProjectIdData,
        Modules: {
          ...cacheProjectIdData.Modules,
          [project.ActiveModule]: {
            ...cacheProjectIdData.Modules[project.ActiveModule],
            ModuleName: moduleName,
          },
        },
      },
    };

    updateModuleOrSlideName(updateData, 'Module', moduleName);
  };

  const handleSelectTemplate = value => {
    if (
      value !==
      project.createdProject[project.ProjectId]['Modules'][project.ActiveModule]['slides'][
        project.ActiveSlide
      ]['Screens'][project.ActiveScreen]['TM01']['Contentparams']['contentdescription']
    ) {
      setSelectedTemplate(value);
    } else if (selectedTemplate && selectedTemplate !== value) {
      setSelectedTemplate('');
    }

    manipulateTemplateArray();
  };

  const hasData = screenData => {
    let data = false;
    if (
      screenData['Contentparams']['searchstring'] &&
      screenData['Contentparams']['searchstring'] !== 'Null'
    ) {
      data = true;
    }
    if (
      screenData['Contentparams']['contenttag'] &&
      screenData['Contentparams']['contenttag'] !== 'Null'
    ) {
      data = true;
    }

    if (
      screenData['Contentparams']['contentdescription'] &&
      screenData['Contentparams']['contentdescription'] !== 'Null'
    ) {
      data = true;
    }

    return data;
  };

  const noData = screenData => {
    let noData = true;
    if (
      screenData['Contentparams']['searchstring'] &&
      screenData['Contentparams']['searchstring'] !== 'Null'
    ) {
      noData = false;
    }
    if (
      screenData['Contentparams']['contenttag'] &&
      screenData['Contentparams']['contenttag'] !== 'Null'
    ) {
      noData = false;
    }

    if (
      screenData['Contentparams']['contentdescription'] &&
      screenData['Contentparams']['contentdescription'] !== 'Null'
    ) {
      noData = false;
    }

    return noData;
  };

  const checkPreviewInCache = templateCheckArrayCache?.find(
    template =>
      template.templateKeys === project.ActiveModule &&
      template.templateSlidekeys === project.ActiveSlide &&
      template.templateScreenkeys === project.ActiveScreen
  );

  const handleApplyButton = () => {
    if (!checkPreviewInCache?.isPreviewGenerating || !paragraphLengthError) {
      setOpenSaveModal(true);
    }
  };

  const removeModuleOrSlide = async updateData => {
    const dbData = JSON.parse(JSON.stringify(updateData));
    const updateDataWithOutMediaUrl = await removeMediaUrlFilter(updateData, project.ProjectId);
    const projectDataPayload = {
      type: 'Update',
      dbdata: dbData,
      APIData: updateDataWithOutMediaUrl,
      RenderUrl: { renderUrls: cacheProject.renderUrls },
    };

    setIsSaving(true);
    return updateProject(projectDataPayload).then(async response => {
      try {
        const projectData = JSON.parse(response.ProjectData);
        setCacheProject({ ...response, ProjectData: projectData });

        setIsSaving(false);

        return { ok: true };
      } catch (error) {
        setIsSaving(false);
        console.log({ removeModuleOrSlideError: error });
        showToaster('Something went wrong. Please Try Again', 'warning');
        return { ok: false };
      }
    });
  };

  const removeSlide = slideId => {
    const cacheProjectIdData = cacheProject.ProjectData[project.ProjectId];

    if (!cacheProjectIdData?.Modules?.[project.ActiveModule]?.slides?.[slideId]) {
      // showToaster('You must save this video before removing', 'error', 5000);
      return { ok: true };
    }

    const { [slideId]: slideIdData, ...withOutSlideId } =
      cacheProjectIdData.Modules[project.ActiveModule].slides;

    const updateData = {
      [project.ProjectId]: {
        ...cacheProjectIdData,
        Modules: {
          ...cacheProjectIdData.Modules,
          [project.ActiveModule]: {
            ...cacheProjectIdData.Modules[project.ActiveModule],
            slides: {
              ...withOutSlideId,
            },
          },
        },
      },
    };

    return removeModuleOrSlide(updateData);
  };

  const removeModule = moduleId => {
    const cacheProjectIdData = cacheProject.ProjectData[project.ProjectId];

    if (!cacheProjectIdData?.Modules?.[moduleId]) {
      // showToaster('You must save this module before removing', 'error', 5000);
      return { ok: true };
    }

    const { [moduleId]: moduleIdData, ...withOutModuleId } = cacheProjectIdData.Modules;

    const updateData = {
      [project.ProjectId]: {
        ...cacheProjectIdData,
        Modules: {
          ...withOutModuleId,
        },
      },
    };

    return removeModuleOrSlide(updateData);
  };

  const refreshGeneratePreview = (generate, screen) => {
    if (generate) {
      setRefreshTimeOut({
        [`${project.ActiveModule}-${project.ActiveSlide}-${project.ActiveScreen}`]: 10000,
      });
      generatePreview(cacheProject.ProjectData, { screen }, true);
      showToaster('Preview is generating', 'success');
    }
  };

  const generateRefreshIcon = screen => {
    const error = hasTemplateError(screen);

    if (error?.isPreviewGenerating) {
      return (
        <Tooltip title="Refresh Preview" arrow>
          <span
            role="button"
            className="close-btn visible ms-1 start-0"
            onClick={() => refreshGeneratePreview(error?.isPreviewGenerating, screen)}
          >
            {refreshIcon}
          </span>
        </Tooltip>
      );
    }

    return null;
  };

  const setRefreshTimeOut = data => {
    setShowRefreshIcon(state => ({ ...state, ...data }));
  };
  useEffect(() => {
    getProjectRoles();
  }, []);

  const getProjectRoles = async () => {
    const projectRolesResponse = await getUserProjectRolesDetails(currentUserId);
    let roleDetails = projectRolesResponse?.data?.projectRoles.map((item, _) => {
      return {
        userId: item.userId,
        projectId: item.projectId,
        editProject: item.editProject,
        viewProject: item.viewProject,
        deleteProject: item.deleteProject,
      };
    });
    setRoleDetails(roleDetails);
  };

  useEffect(() => {
    if (roleDetails && project) {
      const role = roleDetails.find(role => role.projectId === project.ProjectId);
      setRole(role);
    }
  }, [roleDetails, project]);

  if (isLoading) return <PageLoader showLoader={isLoading} />;

  return (
    <>
      <PageLoader showLoader={isSaving} />
      {isCurrentUserAdmin || role?.editProject ? (
        <>
          <Sidebar />
          <main className="main-content-wrapper">
            {isInnerSidebarActive && currentView === 'screen' ? (
              <ProjectModuleSidebar
                projectName={projectName}
                cacheProject={cacheProject}
                removeSlideData={removeSlide}
                clientSetting={clientSetting}
                removeModuleData={removeModule}
                updateSlideName={updateSlideName}
                updateModuleName={updateModuleName}
                category={cacheProject.category_name}
              />
            ) : (
              ''
            )}
            <div
              className={`content-wrapper ${
                isInnerSidebarActive && currentView === 'screen' ? 'active' : ''
              }`}
            >
              <div className="content-block">
                <HeaderNavigation />
                {currentView === 'screen' && (
                  <Row className="screen-wrapper">
                    <Col lg={8}>
                      <div className="screen-holder">
                        <div className="screen-header">
                          <EditableContent
                            editable={true}
                            onClick={onChangeSlideName}
                            className="editable-dark-text"
                            initialContent={activeSlideName}
                          />
                          {projectScreens ? (
                            <span className="fs-13">
                              [ {Object.keys(projectScreens).length} screen(s) ]
                            </span>
                          ) : null}

                          <div className="button-wrap">
                            {/* <Button variant="default" onClick={() => setModalShow(true)}>
                          Brand setting {brandEditIcon}
                        </Button> */}
                            {!hasErrorScreenInSlide && recommendedTemplates.length ? (
                              <Button variant="default" onClick={handleRenderClick}>
                                Render all screen(s) {renderIcon}
                              </Button>
                            ) : null}
                          </div>
                        </div>
                        <AddScreen
                          clientSetting={clientSetting}
                          showRefreshIcon={showRefreshIcon}
                          hasTemplateError={hasTemplateError}
                          setRefreshTimeOut={setRefreshTimeOut}
                          generateRefreshIcon={generateRefreshIcon}
                          cacheProject={cacheProject}
                        />
                      </div>
                    </Col>
                    <Col lg={4} className="screen-sidebar">
                      <EditScreenComponent
                        fonts={fonts}
                        showMediaContent={isSaving}
                        clientSetting={clientSetting}
                        isTemplateLoading={isTemplateLoading}
                        selectTemplate={handleSelectTemplate}
                        selectedTemplateUrl={selectedTemplate}
                        recommendedTemplates={recommendedTemplates}
                        paragraphLengthError={paragraphLengthError}
                        setParagraphLengthError={setParagraphLengthError}
                        manipulateTemplateArray={manipulateTemplateArray}
                      />
                      <div className="w-100 d-flex">
                        {!!hasErrorOrPreview?.isInValid ? (
                          <div className="screen-thumbnail-items-wrap w-100 d-flex">
                            <span className="text-danger">Template Not Available</span>
                          </div>
                        ) : hasErrorOrPreview?.isPreviewGenerating ? (
                          <div className="screen-thumbnail-items-wrap w-100 d-flex">
                            <span className="text-info">Preview Generating</span>
                          </div>
                        ) : null}
                        <div className="screen-sidebar-footer">
                          <button
                            className="bg-primary-btn"
                            onClick={handleApplyButton}
                            disabled={
                              checkPreviewInCache?.isPreviewGenerating || paragraphLengthError
                            }
                          >
                            Apply
                          </button>
                          <button className="primary-btn" onClick={() => setOpenCancelModal(true)}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}

                {currentView === 'render' && (
                  <>
                    <div className="screen-wrapper">
                      <div className="screen-header">
                        <span
                          className="go-back-btn"
                          role={disableBack ? '' : 'button'}
                          onClick={disableBack ? () => {} : () => handleBackClick({ close: true })}
                        >
                          {longLeftArrowIcon} Go Back
                        </span>
                        <div className="button-wrap">
                          {renderVideos.length > 0 &&
                          renderVideos != undefined &&
                          renderVideos[0] !== undefined &&
                          renderVideos[0].url !== undefined &&
                          renderVideos[0].url !== '' ? (
                            <a
                              className="outline-primary-btn"
                              href={renderVideos[0].url}
                              target="_blank"
                              rel="noreferrer"
                              download={renderName}
                            >
                              Download video{renderIcon}{' '}
                            </a>
                          ) : (
                            // <button className="outline-primary-btn" type="button">
                            //   Download video{renderIcon}
                            // </button>
                            ''
                          )}

                          <Button variant="default" onClick={handleEditVideoClick}>
                            Edit video {editIcon}
                          </Button>
                        </div>
                      </div>
                      <div className="screen-body screen-render-wrap">
                        <div className="screen-title-wrap">
                          <EditableContent
                            initialContent={renderName}
                            editable={false}
                            className="editable-dark-text"
                          />
                          {renderVideos.length &&
                          renderVideos[0] !== undefined &&
                          !_.isEmpty(renderVideos[0].crashed) ? (
                            <span className="text-danger">Crashed</span>
                          ) : (
                            ''
                          )}
                          <div className="d-flex gap-2 flex-column">
                            <div className="screen-size">{renderPercetange}</div>
                            {(isRendering || clusterCreating) && (
                              <div className="loader-line m-0"></div>
                            )}
                          </div>
                        </div>
                        <>
                          {renderVideos.length > 0 &&
                          renderVideos != undefined &&
                          renderVideos[0] !== undefined &&
                          renderVideos[0].url !== undefined ? (
                            <VideoPreview
                              alt="video preview"
                              activeMediaUrl={renderVideos[0].url}
                              src="../../assets/img/video-preview.png"
                            />
                          ) : (
                            <>
                              <VideoPreview
                                src="../../assets/img/video-preview.png"
                                alt="video preview"
                              />
                            </>
                          )}
                        </>
                        <div className="text-danger">
                          <p>{renderNote}</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {showPreviewAlert && (
              <div className="alert-wrapper position-fixed top-0 start-50 translate-middle-x">
                <AlertNotification
                  className="alert-warning preview-notify"
                  desc={previewAlertMsg}
                  icon={alertWarningIcon}
                  closeBtn={crossIcon}
                  onclick={() => {
                    setShowPreviewAlert(false);
                  }}
                />
              </div>
            )}
          </main>
        </>
      ) : (
        <DataNotFound icon={noProjectIcon} title="you do not have access to this page" />
      )}
      <BrandSettingModal
        fonts={fonts}
        show={modalShow}
        imageUrl={imageUrl}
        onHide={() => setModalShow(false)}
        setImageUrl={url => setImageUrl(url)}
        clientSetting={clientSetting}
        reload={init}
        cacheProject={cacheProject}
      />

      <CustomModal
        Header="Are you sure?"
        isOpen={openSaveModal}
        Buttonclick={saveProject}
        Buttonlabel="Yes, save it!"
        Closemodal={setOpenSaveModal}
        Content="Do you want to save this project?"
      />

      <ValidationModal
        Buttonlabel="Ok"
        Header="Validation"
        Content={validationError}
        isOpen={!!validationError.length}
        Closemodal={setValidationError}
        Buttonclick={setValidationError}
      />

      <CustomModal
        Header="Are you sure?"
        isOpen={openCancelModal}
        Buttonclick={handleCancle}
        Buttonlabel="Yes, cancel it!"
        Closemodal={setOpenCancelModal}
        Content="Do you want to cancel the changes?"
      />
    </>
  );
}
