import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import PageLoader from '../../../services/pageLoader';
import { secondsToHrMinS } from '../../../lib/time-conversion';
import { projectAnalytics } from '../../../services/apiServices';
import PaginationComponent from '../../../components/pagination';
import { checkAdmin, showToaster } from '../../../services/helper';
import PagePagination from '../../../components/pagination/pagePagination';
import DataNotFound from '../../../components/dataNotFound';
import { noProjectIcon } from '../../../constants/icon';

const UsageTable = () => {
  const { token, client } = checkAdmin();
  const [projectAnalytic, setProjectAnalytic] = useState([]);
  // const itemsPerPage = 10;
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const getProjectAnalytic = () => {
    let body = {
      pageNumber: currentPage,
      pageSize: itemsPerPage,
      startDate: '',
      endDate: '',
    };
    setLoading(true);
    projectAnalytics(body.pageNumber, body.pageSize, body.startDate, body.endDate, {
      token,
      client,
    })
      .then(res => {
        setProjectAnalytic(res?.data?.data?.renderData);
        setTotalItems(res?.data?.data?.total);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        showToaster(e, 'error');
        setLoading(false);
      });
  };

  useEffect(() => {
    getProjectAnalytic();
  }, [itemsPerPage, currentPage]);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleLastPage = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(totalPages);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage]);

  const handleViewAll = id => {
    history.push(`/project-details/${id}`);
  };
  const handleViewRender = id => {
    history.push(`/render-detail/${id}`);
  };

  return (
    <>
      <PageLoader showLoader={loading} />
      {
            projectAnalytic?.length ? 
          <>
      <div className="common-border-table template-table-list">
        <Table responsive className="common-table usages-table">
          <thead>
            <tr>
              <th className="text-start">Project Name</th>
              <th className="text-center col-lg-2">Total Number of Modules</th>
              <th className="text-center">Total Number of Videos</th>
              <th className="text-center">Total Videos Duration</th>
              <th className="text-center">Total Size</th>
              <th className="text-center">Total Renders</th>
              <th className="text-center">Total Render Time</th>
              <th>
                <></>
              </th>
            </tr>
          </thead>
         
          <tbody>
            {projectAnalytic?.map((project, projectIndex) => {
              return (
                <tr key={projectIndex}>
                  <td >
                    <span  className="text-start">
                      {project?.projectName}
                    </span>
                  </td>
                  <td className="col-lg-2">
                    <span style={{ height: 40 }} className="text-center">
                      {project?.totalModules}
                    </span>
                  </td>
                  <td onClick={() => handleViewAll(project?._id)} className="pointer">
                    <span
                      style={{ height: 40, color: '#6750a4', fontWeight: 'bold' }}
                      className="text-center primary-color pointer"
                    >
                      {project?.totalVideos}
                    </span>
                  </td>
                  <td onClick={() => handleViewAll(project?._id)} className="pointer">
                    <span style={{ height: 40 }} className="text-center">
                      {secondsToHrMinS(project?.totalVideoDuration)}
                    </span>
                  </td>
                  <td>
                    <span style={{ height: 40 }} className="text-center">
                      {(project?.totalSize / 1024 / 1024).toFixed(2)}MB
                    </span>
                  </td>
                  <td onClick={() => handleViewRender(project?._id)} className="pointer">
                    <span
                      style={{ height: 40, color: '#6750a4', fontWeight: 'bold' }}
                      className="text-center  primary-color pointer"
                    >
                      {project?.totalRenders}
                    </span>
                  </td>
                  <td>
                    <span style={{ height: 40 }} className="text-center">
                      {secondsToHrMinS(project?.totalRenderDuration)}
                    </span>
                  </td>
                  <td>
                    {project?.Deleted === 1 ? (
                      <span style={{ height: 40 }} className="text-center deleted-box">
                        Deleted
                      </span>
                     ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <div className="pagination-wrapper">
        <PagePagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          setItemsPerPage={setItemsPerPage}
        />
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          onFirst={handleFirstPage}
          onPrevious={handlePreviousPage}
          onNext={handleNextPage}
          onLast={handleLastPage}
        />
      </div>
      </> : <DataNotFound icon={noProjectIcon} title="No Data found"/>
          }
         
    </>
  );
};

export default UsageTable;
