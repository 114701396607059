import { backArrowIcon } from '../../../constants/icon';
import useShowTemplates from '../../../hooks/showTemplateHook';

export default function TemplatesComponent(props) {
  const { goBack, recommendedTemplates, selectedTemplate, selectTemplate } = props;

  const { showTemplates, viewModal } = useShowTemplates({ selectTemplate });

  return (
    <>
      <div className="screen-sidebar-header screen-header-back">
        <div className="sticky-head">
          <span role="button" onClick={goBack}>
            {backArrowIcon} Theme templates
          </span>
          <p>Select on of the template from the below recommend</p>
        </div>
      </div>
      <div className="screen-sidebar-body screen-audio-wrap pe-2">
        {/* <FormInputGroup
          leftIcon={searchIcon}
          type="search"
          id="list"
          placeholder=""
          label="Search Images"
          className="custom-search-form"
        /> */}
        <div className="theme-templates-wrap">
          {/* <button className="triiger-btn" role="button">
            <span className="upload-icon">{plusIcon}</span>
          </button> */}
          {showTemplates({ selectedTemplate, recommendedTemplates })}
        </div>
      </div>
      {viewModal()}
    </>
  );
}
