import React, { useEffect, useState } from 'react';
import { Link, useLocation,useHistory } from 'react-router-dom';

import {
  logoIcon,
  projectsIcon,
  templateIcon,
  assetsIcon,
  settingsIcon,
  helpIcon,
  reportIconWhite,
} from '../../constants/icon';

function Sidebar(props) {
  const {resetData, fromUsage} = props
  const [activeItem, setActiveItem] = useState('projects');
  const location = useLocation();
  const history = useHistory()
  useEffect(() => {
    const path = location.pathname;
    if (path === '/list-projects') {
      setActiveItem('projects');
    } else if (path === '/template') {
      setActiveItem('template');
    } else if (path === '/assets') {
      setActiveItem('assets');
    } else if (path.toString().split('/').includes('settings')) {
      setActiveItem('settings');
    }else if( path === '/usages-tracking'){
      setActiveItem('usages-tracking');
    }
  }, [location.pathname]);


  const resetDatas = () =>{
    if (location.pathname === '/list-projects') {
      resetData();
    } else {
      history.push('/list-projects')
    }
  }

  return (
    <div className={`sidebar ${props.className || ''}`}>
      <div
        className="sidebar-logo pointer"
        onClick={() => resetDatas()}
      >
        {logoIcon}
      </div>
      <div className="sidebar-list">
        <ul>
          {
            !fromUsage &&
          <li>
            <Link to="/list-projects" className={activeItem === 'projects' ? 'active' : ' '}>
              <span className="sidebar-icon">{projectsIcon}</span>
              <span>Projects</span>
            </Link>
          </li>
          }
          {/* <li>
            <Link to="/template" className={activeItem === 'template' ? 'active' : ' '}>
              <span className="sidebar-icon">{templateIcon}</span>
              <span>Template</span>
            </Link>
          </li> */}
          {
            !fromUsage && 
          <li>
            <Link to="/assets" className={activeItem === 'assets' ? 'active' : ' '}>
              <span className="sidebar-icon">{assetsIcon}</span>
              <span>Assets</span>
            </Link>
          </li>
          }
           {/* <li>
            <Link to="/usages-tracking" className={activeItem === 'usages-tracking' ? 'active' : ' '}>
              <span className="sidebar-icon">{reportIconWhite}</span>
              <span>Reports</span>
            </Link>
          </li> */}
          
          <li>
            <Link
              to="/settings/user-management"
              className={activeItem === 'settings' ? 'active' : ' '}
            >
              <span className="sidebar-icon">{settingsIcon}</span>
              <span>Settings</span>
            </Link>
          </li> 
        </ul>
        {/* <ul className="py-3">
          <li>
            <Link to="#">
              <span className="sidebar-icon">{helpIcon}</span>
              <span>Help ?</span>
            </Link>
          </li>
        </ul> */}
      </div>
    </div>
  );
}
export default Sidebar;
