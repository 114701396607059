import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { createUsers, getOrganisation } from "../../services/authService";
import { showToaster, getUserItem } from "../../services/helper";
import * as Yup from "yup";
import Select from "react-select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { useStateWithCallbackLazy } from "use-state-with-callback";
// import spacetime from "spacetime";
import timezones from "timezones-list";
import {
  getTimeZones,
  rawTimeZones,
  timeZonesNames,
  abbreviations,
} from "@vvo/tzdb";
import { SaveClusterDetails } from "../../services/api.service";
import { clientList } from "../../services/apiServices";
// import TimezoneSelect from 'react-timezone-select'
export default function CreateCluster(props) {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [errorStatus, setErrorStatus] = useState();
  const [uploadProgress, setUploadProgress] = useState(false);
  // const [tz, setTz] = useState(
  //   Intl.DateTimeFormat().resolvedOptions().timeZone
  // );
  const [listStatus, setListStatus] = useState({
    value: "All",
    label: "All",
    orgId: 0,
  });
  const [statusOption, setStatusOption] = useStateWithCallbackLazy();
  const [listFilterStatus, setListFilterStatus] = useState([]);
  const [listVMDetails, setListVmDetails] = useState([]);
  const [vmOption, setVMOption] = useStateWithCallbackLazy();
  const [listVMStatus, setListVMStatus] = useState({
    value: "Standard_D16as_v5",
    status: 3,
  });
  const [maxUnitValue, setMaxUnitValue] = useState(1); 

  const handleChangeVmStatus = (e) => {
    setListVMStatus(e);
  };

  const generateVMStatus = () => {
    if (listVMDetails != undefined) {
      let statusOption = [];
      listVMDetails.map((filter) => {
        statusOption.push({
          value: filter.value,
          label: filter.value,
          status: filter.status,
        });
        return true;
      });
      setVMOption(statusOption);
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };

  useEffect(() => {
    generateVMStatus();
    setListVMStatus({
      value: "Standard_D16as_v5",
      label: "Standard_D16as_v5",
      status: 3,
    });
  }, [listFilterStatus]);

  const FilterByStatus = () => {
    let filerStatus = [
      {
        value: " Standard_D2as_v5",
        status: 0,
      },
      {
        value: " Standard_D4as_v5",
        status: 1,
      },
      {
        value: "Standard_D8as_v5",
        status: 2,
      },
      {
        value: " Standard_D16as_v5",
        status: 3,
      },
      {
        value: " Standard_D32as_v5",
        status: 4,
      },
      {
        value: " Standard_D48as_v5",
        status: 5,
      },
      {
        value: "Standard_D64as_v5",
        status: 6,
      },
      {
        value: "  Standard_D96as_v5",
        status: 7,
      },
    ];

    setListVmDetails(filerStatus);
  };

  // const patternMaxUnits = /^(?:1\d{0,1}|100)$/;

  const handleMaxUnitChange = event => {
    const min = 1;
    const max = 100;
    const value = Math.max(min, Math.min(max, Number(event.target.value)));
    setMaxUnitValue(value);
  };

  const formik = useFormik({
    initialValues: {
      resource_group: "Content-Pro",
      cluster_name: process.env.REACT_APP_CLUSTER_NAME,
      rendercluster_name: "",
      vm_size: "Standard_D16as_v5",
      vm_count: "1",
      min_scale_vm_count: "1",
      max_scale_vm_count: "4",
      userId: "",
      max_units: "3"
      // max_units: "3"
    },

    validationSchema: Yup.object().shape({
      rendercluster_name: Yup.string()
        .trim()  
        .matches(
            /^[a-z]+$/,
            "Only Allow Lowercase"
          )
        .max(11, "Must be exactly 11 characters")
        .min(4, "Minimum 4 characters are required")
        .required("ClusterName is required"),
        
        
      vm_count: Yup.number()
        .max(20, "Must be exactly 20 ")
        .min(1, "Minimum 1 characters are required")
        .required("VM Count is required"),
      min_scale_vm_count: Yup.number()
        .max(20, "Must be exactly 20 ")
        .min(1, "Minimum 1 characters are required")
        .required(" min_scale_vm_count is required"),
      max_scale_vm_count: Yup.number()
        .max(20, "Must be exactly 20 ")
        .min(1, "Minimum 1 characters are required")
        .required("max_scale_vm_count is required"),
      max_units: Yup.number()
        .min(1, "Must not be less than 1 ")
        .max(100, "Must not exceed 100 ")
        .required("max_units is required")
        // .trim()
        // .matches(patternMaxUnits, "Phone number is not valid")
      }),
    onSubmit: (values) => {
      // values.name = values.userName + " " + values.lastName;
      values.userId = listStatus.label !== "All" ? listStatus.orgId : "All";
      values.vm_size = listVMStatus.value;
      values.max_units= maxUnitValue
      setUploadProgress(true);
      try {
        SaveClusterDetails(values).then((response) => {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null&&
            response.status==200
          ) {
           
          
              props.setnewUsers(values.userName);
              values.rendercluster_name = "";
              values.vm_count = "1";
              values.min_scale_vm_count = "1";
              values.max_scale_vm_count = "4";
              values.max_units = "3";
              setUploadProgress(false);
              showToaster("Cluster created successfully", "success");
              props.closePreview();
            
          } else {
            console.log(response);
            showToaster(response.message, "warning");
            // this.setState({ errorStatus: response.message })
            // setErrorStatus(response.message);
            setUploadProgress(false);
          }
        });
      } catch (e) {
        setUploadProgress(false);
        showToaster("Something went wrong. Please Try Again", "warning");
        // this.setState({ errorStatus: e.response.data.errors })
        // setErrorStatus(e.response.data.errors);
      }
    },
  });
  // useMemo(() => {
  //   const tzValue = tz.value ?? tz;
  //   setDatetime(datetime.goto(tzValue));
  // }, [tz]);
  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader-inner">
          <img src="../../../assets/img/loader.gif"></img>
        </div>
      </div>
    );
  }
  useEffect(() => {
    getOrganisationList();
    FilterByStatus();
    // values = ""
  }, [props.createUserModal]);

  const getOrganisationList = () => {
    let filter = {
      params: 0,
      keyword: "",
      sortCreateAt: "",
      sortUpdatedAt: "",
    };
    clientList(filter).then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200 &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          let projectData = {
            name: "All",
            orgId: 0,
          };
          let orglist = [projectData, ...response.data.Body.result.Clients];
          setListFilterStatus(orglist);
          //generateFonts();
        }
      } catch (error) {}
    });
  };
  const handleChangeStatus = (e) => {
    setListStatus(e);
  };

  const generateStatus = () => {
    if (listFilterStatus != undefined) {
      let statusOption = [];
      listFilterStatus.map((filter) => {
        statusOption.push({
          value: filter.name,
          label: filter.name,
          orgId: filter._id,
        });
        return true;
      });
      setStatusOption(statusOption);
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };

  useEffect(() => {
    generateStatus();
    setListStatus({
      value: "All",
      label: "All",
      orgId: 0,
    });
  }, [listFilterStatus]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "gray",
      padding: 7,
    }),
    control: () => ({
      display: "flex",
      backgroundColor: DisplayMode ? "#2e2e2e" : "#f0efef",
      color: "#fff",
      height: "32px",
    }),
    singleValue: () => ({
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "12px",
      lineHeight: "18px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      color: "#fff",
    }),
    indicatorSeparator: () => ({
      border: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
  };

  return (
    <Modal
      isOpen={props.createUserModal}
      fade={true}
      centered
      className={
        DisplayMode
          ? "modal-dialog  modal-dialog-centered custom-modal dark add-category "
          : "modal-dialog  modal-dialog-centered custom-modal  add-category"
      }
      // className="modal-dialog  modal-dialog-centered styles-modal custom-modal dark"
    >
      <ModalBody>
      {uploadProgress && <Loader />}
        <form onSubmit={formik.handleSubmit} id="login-form">
          <div  className="modal-body">
            <div  className="text-center mb-3">
              <h2>Add Cluster</h2>
            </div>

            <div  className="mb-3">
              <label for=""  className="form-label">
                Select Organization
              </label>

              <div className="custom-input rolluser w-100 mb-3">
                <Select
                  className="custom-input"
                  styles={customStyles}
                  value={listStatus}
                  onChange={(e) => handleChangeStatus(e)}
                  options={statusOption}
                />
              </div>

              <div  className="mb-3">
                <label for=""  className="form-label">
                  Select VM Size
                </label>

                <div className="custom-input rolluser w-100">
                  <Select
                    className="custom-input"
                    styles={customStyles}
                    value={listVMStatus}
                    onChange={(e) => handleChangeVmStatus(e)}
                    options={vmOption}
                  />
                </div>
              </div>

              {/* {formik.touched.lastName && formik.errors.lastName ? (
                <div className="input-error-msg">{formik.errors.lastName}</div>
              ) : null} */}
            </div>

            <div  className="mb-3">
              <label for=""  className="form-label">
                Resource Group
              </label>
              <input
                id="resource_group"
                name="resource_group"
                type="resource_group"
                value="Content-Pro"
                className="custom-input"
                disabled={uploadProgress}
              />{" "}
            </div>
            <div  className="mb-3">
              <label for=""  className="form-label">
                Cluster Name
              </label>
              <input
                id="cluster_name"
                name="cluster_name"
                type="cluster_name"
                value={process.env.REACT_APP_CLUSTER_NAME}
                className="custom-input"
                placeholder="Enter your Last Name"
                disabled={uploadProgress}
              />{" "}
            </div>

            <div  className="mb-3">
              <label for=""  className="form-label">
                Render-Cluster Name
              </label>
              <input
                id="rendercluster_name"
                name="rendercluster_name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.rendercluster_name}
                className="custom-input"
                placeholder="Enter your Render Cluster Name"
                disabled={uploadProgress}
              />
            </div>
            {formik.touched.rendercluster_name &&
            formik.errors.rendercluster_name ? (
              <div className="input-error-msg">
                {formik.errors.rendercluster_name}
              </div>
            ) : null}
            <div  className="mb-3">
              <label for=""  className="form-label">
                VM Count{" "}
              </label>
              <input
              min="1"
                id="vm_count"
                name="vm_count"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.vm_count}
                className="custom-input"
                placeholder="Enter your VM Count"
                disabled={uploadProgress}
              />
            </div>
            {formik.touched.vm_count && formik.errors.vm_count ? (
              <div className="input-error-msg">{formik.errors.vm_count}</div>
            ) : null}
            <div  className="mb-3">
              <label for=""  className="form-label">
                Min Scale VM Count
              </label>

              <input
                min="1"
                id="min_scale_vm_count"
                name="min_scale_vm_count"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.min_scale_vm_count}
                className="custom-input"
                placeholder="Enter  Min Scale VM Count"
                disabled={uploadProgress}
              />
            </div>
            {formik.touched.min_scale_vm_count &&
            formik.errors.min_scale_vm_count ? (
              <div className="input-error-msg">
                {formik.errors.min_scale_vm_count}
              </div>
            ) : null}
             <div  className="mb-3">
              <label for=""  className="form-label">
              Max Scale VM Count
              </label>
              <input
                min="1"
                id="max_scale_vm_count"
                name="max_scale_vm_count"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.max_scale_vm_count}
                className="custom-input"
                placeholder="Enter your VM Count"
                disabled={uploadProgress}
              />
            </div>
            {formik.touched.max_units && formik.errors.max_units ? (
              <div className="input-error-msg">{formik.errors.max_units}</div>
            ) : null}
            <div  className="mb-3">
              <label for=""  className="form-label">
                Max Units
              </label>
              <input
                min="1"
                max="100"
                id="max_units"
                name="max_units"
                type="number"
                onChange={handleMaxUnitChange}
                value={maxUnitValue}
                className="custom-input"
                placeholder="Enter Max Units"
                disabled={uploadProgress}
              />
            </div>
            {formik.touched.max_scale_vm_count && formik.errors.max_scale_vm_count ? (
              <div className="input-error-msg">{formik.errors.max_scale_vm_count}</div>
            ) : null}
            <div
              className="input-error-msg"
              style={{ visibility: errorStatus ? "visible" : "hidden" }}
            >
              {errorStatus}
            </div>
            <div  className="d-flex justify-content-end w-100 footer-btn flex-wrap mt-4">
              <button
                type="button"
                onClick={() => {
                  {
                    props.closePreview();
                  }
                  {
                    formik.values.rendercluster_name = "";
                  }
                  {
                    formik.errors.rendercluster_name = false;
                  }
                  {
                    formik.touched.rendercluster_name = false;
                  }
                  {
                    formik.values.vm_count = "";
                  }
                  {
                    formik.errors.vm_count = false;
                  }
                  {
                    formik.touched.vm_count = false;
                  }
                  {
                    formik.values.min_scale_vm_count = "";
                  }
                  {
                    formik.errors.min_scale_vm_count = false;
                  }
                  {
                    formik.touched.min_scale_vm_count = false;
                  }
                  {
                    formik.values.max_units = "";
                  }
                  {
                    formik.errors.max_units = false;
                  }
                  {
                    formik.touched.max_units = false;
                  }
                  {
                    formik.values.max_scale_vm_count = "";
                  }
                  {
                    formik.errors.max_scale_vm_count = false;
                  }
                  {
                    formik.touched.max_scale_vm_count = false;
                  }
                }}
                 className="btn btn-outline rounded-0 me-2"
                data-bs-dismiss="modal"
              >
                CANCEL
              </button>
              <button type="submit"  className="btn btn-primary  rounded-0">
                ADD CLUSTER
              </button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}
