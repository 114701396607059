import Swal from 'sweetalert2';
import jwt from 'jwt-simple';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export const showToaster = (message, type, timer = 3000, showCloseButton = false) => {
  Toast.fire({
    timer,
    icon: type,
    title: message,
    showCloseButton,
  });
};

export const closeToaster = () => Swal.close();

export const ShowAlertBox = (title, message, type) => {
  Swal.fire(title, message, type);

  // Swal.fire({
  //     // position: 'top-end',
  //     icon: type,
  //     title: message,
  //     showConfirmButton: false,
  //     timer: 1500
  // })
};
export const getUserItem = type => {
  const getUserInfo = decodeEncodedItem(localStorage.getItem('_olive_user'));

  if (getUserInfo) {
    if (type === 'email') {
      return getUserInfo.email;
    } else if (type === 'status') {
      return getUserInfo.status;
    } else if (type === 'user_type') {
      return getUserInfo.user_type;
    } else if (type === 'role') {
      return getUserInfo.role;
    } else if (type === 'firstName') {
      return getUserInfo.firstName;
    } else if (type === 'lastName') {
      return getUserInfo.lastName;
    } else if (type === 'name') {
      return getUserInfo.name;
    } else if (type === 'clientId') {
      return getUserInfo.clientId;
    } else if (type === 'orgId') {
      return getUserInfo.orgId;
    } else if (type === 'timeZone') {
      return getUserInfo.timeZone;
    } else if (type === 'templateFeature') {
      return getUserInfo.templateFeature;
    } else if (type === 'userRole') {
      return getUserInfo.userRole;
    } else if (type === 'id') {
      // return '6226c5789ae7dd51adefd4c2'; //Prince USERID
      // return '6203723dbda41c5e26d539aa'
      return getUserInfo.id;
    } else if (type === 'welcomemessage') {
      return getUserInfo.welcomemessage;
    }
  }

  return null;
};

export const isAdmin = () => {
  const getUserInfo = decodeEncodedItem(localStorage.getItem('_olive_user'));
  return getUserInfo.role == 'ADMIN';
};

export const decodeEncodedItem = decodedItem => {
  if (decodedItem !== '' && decodedItem !== undefined && decodedItem !== null) {
    decodedItem = JSON.parse(decodedItem);
    decodedItem = jwt.decode(decodedItem, process.env.REACT_APP_JWT_SECRET, 'HS512');
  }
  return decodedItem;
};

export const isColor = strColor => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== '';
};

export const imageExists = (url, callback) => {
  var img = new Image();
  img.onload = function (error) {
    callback(true);
  };
  img.onerror = function (error) {
    callback(false);
  };
  img.src = url;
};

export const download = url => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.rel = 'noreferrer';
  a.download = url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const RandomGenerator = length => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const delay = ms => {
  let timerObj = null;
  return () => {
    clearTimeout(timerObj);
    return new Promise(resolve => {
      timerObj = setTimeout(resolve, ms);
    });
  };
};

export const convertDateToUserTimeZone = date => {
  let dateTime = new Date(date);
  let timeZone = getUserItem('timeZone');
  return timeZone
    ? dateTime.toLocaleDateString('en-CA', { timeZone }) +
        ' / ' +
        dateTime.toLocaleTimeString('en-US', { timeZone })
    : dateTime.toLocaleDateString('en-CA') + ' / ' + dateTime.toLocaleTimeString('en-US');
};

export const splitFontFamilyAndStyle = font => {
  const fontSplit = font.split(' : ');

  return { fontFamily: fontSplit[0], fontStyle: fontSplit[1] };
};

export const mergeFontFamilyAndStyle = font => {
  const fontFamily = font.split('/')[4];
  const fontStyle = font.split('/')[5];
  if (fontStyle) {
    return fontFamily + ' : ' + fontStyle;
  }

  return fontFamily;
};

export const templateName = template => {
  if (!template) return '';
  const templateSplit = template.split('/');
  const lastItem = templateSplit[templateSplit.length - 1];

  const lastItemSplit = lastItem.split('.ntp');

  return lastItemSplit[0];
};

export const getRandomNumber = ({ min = 0, max }) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getProjectQueryData = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const from = urlParams.get('from');
  const module = urlParams.get('module');
  const slide = urlParams.get('slide');
  const screen = urlParams.get('screen');
  const action = urlParams.get('action');
  const checkPreview = urlParams.get('checkPreview');

  return { from, module, slide, screen, action, checkPreview };
};

export const formatDate = date => {
  return new Date(date).toLocaleDateString('en-UK', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const checkAdmin = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const client = urlParams.get('client');

  console.log({ token, client });

  return { token, client };
};

export const convertDateFormat = inputDate => {
  const date = new Date(inputDate);

  // Extracting day, month, and year
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Months are zero-based, so we add 1
  const year = date.getUTCFullYear();

  // Formatting the date in the desired format
  const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;

  return formattedDate;
};

export function formatDateUser(inputDate) {
  const currentDate = new Date();
  const updateDate = new Date(inputDate);

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - updateDate;
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

  if (hoursDifference < 24) {
    if (hoursDifference < 2) {
      return 'updated an hour ago';
    } else {
      return `updated ${hoursDifference} hours ago`;
    }
  } else if (hoursDifference < 48) {
    return 'updated yesterday';
  } else {
    // Format the date using toLocaleDateString
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return updateDate.toLocaleDateString(undefined, options);
  }
}

export const getQueryData = query => {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get(query);
};
