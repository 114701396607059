import {
  ADDMODULE,
  EDITOST,
  DISPLAYMODE,
  TOGGLERIGHTSCREEN,
  UPDATESCREENPREVIEW,
  DELETESCREENPREVIEW,
  TOGGLELEFTSCREEN,
  EDITSCREENDURATION,
  UPDATETEMPLATEURLPREVIEW,
  EDITASSET,
  SELECTVIDEO,
  SELECTILLUSTRATION,
  SELECTIMAGE,
  ADDFEATURE,
  EDITAUDIOSCRIPT,
  SELECTOST,
  ADDSCREEN,
  REMOVESCREEN,
  REMOVESLIDE,
  ADDSLIDE,
  INITIALIZEPROJECT,
  REMOVEMODULE,
  SELECTMODULE,
  SELECTSCREEN,
  SELECTSLIDE,
  DELETEFEATURE,
  SETOSTFONT,
  CHANGEPROJECTNAME,
  EDITPROJECT,
  INITIALIZEPREVIEW,
  RENAMEMODULE,
  RENAMESLIDE,
  RENAMESCREEN,
  SELECTACTIVESCREEN,
  SELECTACTIVESLIDE,
  SELECTACTIVEMODULE,
  PREVIEW_UPDATEFEATURE,
  PREVIEW_SETOSTFONT,
  UPDATE_AUDIOSCRIPT_UPLOAD,
  EDITMODE,
  PREVIOUSACTIVE,
  PREVIOUS_ACTIVE_TO_PREVIEW,
  ASSIGN_THUMBNAIL,
  CHANGEPROJECTNAMEINPREVIEW,
  EDITFEATURE,
  PROJECTFROMLOCALSTORAGE,
  UPDATETHUMBNAIL,
  EMPTYPROJECT,
  EMPTYPREVIEW,
  SETOSTFONTFORNEWSCREENS,
  UPDATEOSTFONT,
  UPDATEPREVIEW,
  UPDATETEMPLATEURL,
  EDIT_SCREEN_FONT_STYLES,
  UPDATE_AUDIO_UPLOAD,
  STYLES_DATA,
  UPDATE_PROJECT_SCREEN_PREVIEW,
  CLEAR_PREVIEW_GENERATED_OF,
} from '../Reducers/constants';

export const InitProject = payload => {
  return dispatch =>
    dispatch({
      type: INITIALIZEPROJECT,
      payload: payload,
    });
};

export const AddModule = payload => {
  return dispatch =>
    dispatch({
      type: ADDMODULE,
      payload: payload,
    });
};

export const SelectModule = payload => {
  return dispatch => {
    dispatch({
      type: SELECTMODULE,
      payload: payload,
    });
  };
};

export const RenameModuleName = payload => {
  return dispatch => {
    dispatch({
      type: RENAMEMODULE,
      payload: payload,
    });
  };
};

export const RemoveModule = payload => {
  return dispatch => {
    dispatch({
      type: REMOVEMODULE,
      payload: payload,
    });
  };
};
export const AddSlide = payload => {
  return dispatch =>
    dispatch({
      type: ADDSLIDE,
      payload: payload,
    });
};
export const SelectSlide = payload => {
  return dispatch => {
    dispatch({
      type: SELECTSLIDE,
      payload: payload,
    });
  };
};

export const RenameSlideName = payload => {
  return dispatch => {
    dispatch({
      type: RENAMESLIDE,
      payload: payload,
    });
  };
};
export const RemoveSlide = payload => {
  return dispatch => {
    dispatch({
      type: REMOVESLIDE,
      payload: payload,
    });
  };
};

export const Addscreen = payload => {
  return dispatch => {
    dispatch({
      type: ADDSCREEN,
      payload: payload,
    });
  };
};
export const SelectScreen = payload => {
  return dispatch => {
    dispatch({
      type: SELECTSCREEN,
      payload: payload,
    });
  };
};
export const RenameScreenName = payload => {
  return dispatch => {
    dispatch({
      type: RENAMESCREEN,
      payload: payload,
    });
  };
};
export const EditAudioScript = payload => {
  return dispatch => {
    dispatch({
      type: EDITAUDIOSCRIPT,
      payload: payload,
    });
  };
};
export const EditScreenDuration = payload => {
  return dispatch => {
    dispatch({
      type: EDITSCREENDURATION,
      payload: payload,
    });
  };
};

export const UpdateTemplateUrl = (moduleId, slideId, screenId, templateUrl, projectId) => {
  return dispatch => {
    dispatch({
      type: UPDATETEMPLATEURL,
      payload: {
        ModuleId: moduleId,
        SlideId: slideId,
        ScreenId: screenId,
        templateUrl: templateUrl,
        projectId: projectId,
      },
    });
  };
};
export const UpdateTemplateUrlPreview = (moduleId, slideId, screenId, templateUrl, projectId) => {
  return dispatch => {
    dispatch({
      type: UPDATETEMPLATEURLPREVIEW,
      payload: {
        ModuleId: moduleId,
        SlideId: slideId,
        ScreenId: screenId,
        templateUrl: templateUrl,
        projectId: projectId,
      },
    });
  };
};
export const SelectOst = payload => {
  return dispatch => {
    dispatch({
      type: SELECTOST,
      payload: payload,
    });
  };
};
export const DeleteFeature = (featureId, type) => {
  return dispatch => {
    dispatch({
      type: DELETEFEATURE,
      payload: {
        featureId,
        type,
      },
    });
  };
};
export const RemoveScreen = payload => {
  return dispatch => {
    dispatch({
      type: REMOVESCREEN,
      payload: payload,
    });
  };
};
export const AddFeature = payload => {
  return dispatch => {
    dispatch({
      type: ADDFEATURE,
      payload: payload,
    });
  };
};

export const EditFeature = payload => {
  return dispatch => {
    dispatch({
      type: EDITFEATURE,
      payload: payload,
    });
  };
};

export const EditOst = payload => {
  return dispatch => {
    dispatch({
      type: EDITOST,
      payload: payload,
    });
  };
};

export const SelectImage = payload => {
  return dispatch => {
    dispatch({
      type: SELECTIMAGE,
      payload: payload,
    });
  };
};
export const SelectIllustration = payload => {
  return dispatch => {
    dispatch({
      type: SELECTILLUSTRATION,
      payload: payload,
    });
  };
};
export const SelectVideo = payload => {
  return dispatch => {
    dispatch({
      type: SELECTVIDEO,
      payload: payload,
    });
  };
};
export const EditAsset = (data, type) => {
  return dispatch => {
    dispatch({
      type: EDITASSET,
      payload: { data, type },
    });
  };
};
export const SetOstFont = (fontFamily, fontStyle, fontH, fontP, fontColor, bgColor) => {
  return dispatch => {
    dispatch({
      type: SETOSTFONT,
      payload: { fontFamily, fontStyle, fontH, fontP, fontColor, bgColor },
    });
  };
};
export const ChangeProjectName = payload => {
  return dispatch => {
    dispatch({
      type: CHANGEPROJECTNAME,
      payload: payload,
    });
  };
};
export const EditProject = payload => {
  return dispatch => {
    dispatch({
      type: EDITPROJECT,
      payload: payload,
    });
  };
};
export const InitilizePreview = payload => {
  return dispatch => {
    dispatch({
      type: INITIALIZEPREVIEW,
      payload: payload,
    });
  };
};
export const UpdatePreview = payload => {
  return dispatch => {
    dispatch({
      type: UPDATEPREVIEW,
      payload: {
        previewProject: payload,
      },
    });
  };
};

export const SelectActivePreviewScreen = payload => {
  return dispatch => {
    dispatch({
      type: SELECTACTIVESCREEN,
      payload: payload,
    });
  };
};
export const SelectActivePreviewSlide = payload => {
  return dispatch => {
    dispatch({
      type: SELECTACTIVESLIDE,
      payload: payload,
    });
  };
};
export const SelectActivePreviewModule = payload => {
  return dispatch => {
    dispatch({
      type: SELECTACTIVEMODULE,
      payload: payload,
    });
  };
};

export const UpdatePreviewFeature = payload => {
  return dispatch => {
    dispatch({
      type: PREVIEW_UPDATEFEATURE,
      payload: payload,
    });
  };
};
export const PreviewSetOstFont = payload => {
  return dispatch => {
    dispatch({
      type: PREVIEW_SETOSTFONT,
      payload: payload,
    });
  };
};
export const UpdateAudioScriptUpload = payload => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUDIOSCRIPT_UPLOAD,
      payload: payload,
    });
  };
};
export const UpdateEditMode = payload => {
  return dispatch => {
    dispatch({
      type: EDITMODE,
      payload: payload,
    });
  };
};
export const UpdateDisplayMode = payload => {
  return dispatch => {
    dispatch({
      type: DISPLAYMODE,
      payload: payload,
    });
  };
};
export const Togglerightscreen = payload => {
  return dispatch => {
    dispatch({
      type: TOGGLERIGHTSCREEN,
      payload: payload,
    });
  };
};
export const Toggleleftscreen = payload => {
  return dispatch => {
    dispatch({
      type: TOGGLELEFTSCREEN,
      payload: payload,
    });
  };
};
export const PreviousActive = payload => {
  return dispatch => {
    dispatch({
      type: PREVIOUSACTIVE,
      payload: payload,
    });
  };
};
export const PreviousActivetoPreview = payload => {
  return dispatch => {
    dispatch({
      type: PREVIOUS_ACTIVE_TO_PREVIEW,
      payload: payload,
    });
  };
};
export const AssignScreenThumbnail = payload => {
  return dispatch => {
    dispatch({
      type: ASSIGN_THUMBNAIL,
      payload: payload,
    });
  };
};
export const ChangeProjectNameInPreview = payload => {
  return dispatch => {
    dispatch({
      type: CHANGEPROJECTNAMEINPREVIEW,
      payload: payload,
    });
  };
};
export const ProjectFromLocalStorage = payload => {
  return dispatch => {
    dispatch({
      type: PROJECTFROMLOCALSTORAGE,
      payload: payload,
    });
  };
};
export const UpdateThumbnail = (moduleId, slideId, screenId, thumbnailUrl) => {
  return dispatch => {
    dispatch({
      type: UPDATETHUMBNAIL,
      payload: {
        ModuleId: moduleId,
        SlideId: slideId,
        ScreenId: screenId,
        thumbnailUrl: thumbnailUrl,
      },
    });
  };
};
export const EmptyProject = () => {
  return dispatch => {
    dispatch({
      type: EMPTYPROJECT,
    });
  };
};
export const EmptyPreview = () => {
  return dispatch => {
    dispatch({
      type: EMPTYPREVIEW,
    });
  };
};
export const SetOstFontNewScreens = (fontFamily, fontStyle, fontH, fontP, fontColor, bgColor) => {
  return dispatch => {
    dispatch({
      type: SETOSTFONTFORNEWSCREENS,
      payload: { fontFamily, fontStyle, fontH, fontP, fontColor, bgColor },
    });
  };
};
export const UpdateOstFont = (oldStyle, newStyle) => {
  return dispatch => {
    dispatch({
      type: UPDATEOSTFONT,
      payload: { oldStyle, newStyle },
    });
  };
};
export const UpdateScreenPreview = payload => {
  return dispatch => {
    dispatch({
      type: UPDATESCREENPREVIEW,
      payload: payload,
    });
  };
};
export const DeleteScreenPreview = payload => {
  return dispatch => {
    dispatch({
      type: DELETESCREENPREVIEW,
      payload: payload,
    });
  };
};

export const EditScreenFontStyles = ({
  fontFamily,
  fontStyle,
  fontH,
  fontP,
  fontColor,
  bgColor,
}) => {
  return dispatch => {
    dispatch({
      type: EDIT_SCREEN_FONT_STYLES,
      payload: { fontFamily, fontStyle, fontH, fontP, fontColor, bgColor },
    });
  };
};

export const UpdateAudioUpload = payload => {
  return dispatch => {
    dispatch({ type: UPDATE_AUDIO_UPLOAD, payload });
  };
};

export const SetStylesData = payload => {
  return dispatch => {
    dispatch({
      type: STYLES_DATA,
      payload: payload,
    });
  };
};

export const UpdateProjectScreenPreview = payload => {
  return dispatch => {
    dispatch({
      type: UPDATE_PROJECT_SCREEN_PREVIEW,
      payload: payload,
    });
  };
};

export const ClearPreviewGeneratedOf = () => {
  return dispatch => {
    dispatch({ type: CLEAR_PREVIEW_GENERATED_OF });
  };
};
