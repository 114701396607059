function AlertNotification(props) {
  const { icon, title, desc, closeBtn, onclick } = props;
  return (
    <div className={`alert-notification ${props.className || ''}`}>
      {icon && <div className="alert-icon-wrap">{icon}</div>}
      <div className="alert-info-wrap">
        {title && <h4>{title}</h4>}
        {desc && <p>{desc}</p>}
      </div>
      {closeBtn && (
        <span role="button" className="alert-close-btn" onClick={onclick}>
          {closeBtn}
        </span>
      )}
    </div>
  );
}
export default AlertNotification;
