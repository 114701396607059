import { FONT_URL } from '../../constants';
import {
  ADDMODULE,
  ADDFEATURE,
  ADDSCREEN,
  SELECTOST,
  REMOVESLIDE,
  EDITAUDIOSCRIPT,
  ADDSLIDE,
  INITIALIZEPROJECT,
  REMOVEMODULE,
  REMOVESCREEN,
  SELECTMODULE,
  SELECTSCREEN,
  SELECTSLIDE,
  EDITOST,
  DELETEFEATURE,
  SELECTIMAGE,
  SELECTILLUSTRATION,
  SELECTVIDEO,
  EDITASSET,
  EDITSCREENDURATION,
  SETOSTFONT,
  CHANGEPROJECTNAME,
  EDITPROJECT,
  RENAMEMODULE,
  RENAMESLIDE,
  RENAMESCREEN,
  EDITMODE,
  DISPLAYMODE,
  TOGGLERIGHTSCREEN,
  TOGGLELEFTSCREEN,
  PREVIOUSACTIVE,
  EDITFEATURE,
  PROJECTFROMLOCALSTORAGE,
  EMPTYPROJECT,
  SETOSTFONTFORNEWSCREENS,
  UPDATEOSTFONT,
  UPDATETEMPLATEURL,
  EDIT_SCREEN_FONT_STYLES,
  UPDATE_AUDIO_UPLOAD,
  STYLES_DATA,
  UPDATE_PROJECT_SCREEN_PREVIEW,
  CLEAR_PREVIEW_GENERATED_OF,
} from './constants';

const DefaultActiveSlide = 'SL01';
const DefaultActiveModule = 'M01';
const DefaultActiveScreen = 'SC01.01';
const DefaultActiveOst = 'OS01';
const DefaultActiveImage = 'IM01';
const DefaultIllustration = 'IL01';
const DefaultVideo = 'VD01';
const DefaultFontFamily = 'Exo-Bold';
const DefaultFontStyle = 'Exo-Bold.otf';
const DefaultFontHSize = '30';
const DefaultFontPSize = '30';
const DefauttTextColor = '#ffffff';
const DefaultBgColor = '##0000ff';
const DefaultFontUrl = 'https://oliveproduction-automation-storages.s3.amazonaws.com/Fonts/';
const initialState = {
  togglerightscreen: false,
  toggleleftscreen: false,
  createdProject: {},
  EditMode: false,
  DisplayMode: localStorage.getItem('displayMode')
    ? JSON.parse(localStorage.getItem('displayMode'))
    : true,
  ProjectId: '',
  ActiveModule: DefaultActiveModule,
  ActiveSlide: DefaultActiveSlide,
  ActiveOst: DefaultActiveOst,
  ActiveScreen: DefaultActiveScreen,
  ActiveImage: DefaultActiveImage,
  ActiveIllustration: DefaultIllustration,
  ActiveVideo: DefaultVideo,
  FontFamily: DefaultFontFamily,
  FontStyle: DefaultFontStyle,
  FontHSize: DefaultFontHSize,
  FontPSize: DefaultFontPSize,
  TextColor: DefauttTextColor,
  BgColor: DefaultBgColor,
  FontUrl: DefaultFontUrl + DefaultFontFamily + '/' + DefaultFontStyle,
  stylesData: '',
  previewGeneratedOf: '',
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZEPROJECT:
      let projectid = Object.keys(action.payload);
      return {
        ...state,
        createdProject: {
          ...action.payload,
        },
        ProjectId: projectid[0],
      };
    case EMPTYPROJECT: {
      return {
        ...state,
        createdProject: {},
        ProjectId: '',
        ActiveModule: DefaultActiveModule,
        ActiveSlide: DefaultActiveSlide,
        ActiveOst: DefaultActiveOst,
        ActiveScreen: DefaultActiveScreen,
        ActiveImage: DefaultActiveImage,
        ActiveIllustration: DefaultIllustration,
        ActiveVideo: DefaultVideo,
        FontFamily: DefaultFontFamily,
        FontStyle: DefaultFontStyle,
        FontHSize: DefaultFontHSize,
        FontPSize: DefaultFontPSize,
        TextColor: DefauttTextColor,
        BgColor: DefaultBgColor,
        FontUrl: DefaultFontUrl + DefaultFontFamily + '/' + DefaultFontStyle,
        stylesData: '',
        previewGeneratedOf: '',
      };
    }
    case PROJECTFROMLOCALSTORAGE: {
      return {
        ...action.payload,
      };
    }
    case EDITPROJECT:
      return {
        ...state,
        createdProject: action.payload.createdProject,
        ProjectId: action.payload.ProjectId,
        ActiveModule: action.payload.ActiveModule,
        ActiveSlide: action.payload.ActiveSlide,
        ActiveOst: action.payload.ActiveOst,
        ActiveScreen: action.payload.ActiveScreen,
        ActiveImage: action.payload.ActiveImage,
        ActiveIllustration: action.payload.ActiveIllustration,
        ActiveVideo: action.payload.ActiveVideo,
      };
    case CHANGEPROJECTNAME:
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            ProjectName: action.payload,
          },
        },
      };
    case ADDMODULE:
      let activeModule = Object.keys(action.payload);
      let highlightedModule = activeModule[0];
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              ...action.payload,
            },
          },
        },
        ActiveModule: highlightedModule,
        ActiveSlide: DefaultActiveSlide,
        ActiveScreen: DefaultActiveScreen,
        ActiveOst: DefaultActiveOst,
        ActiveImage: DefaultActiveImage,
        ActiveIllustration: DefaultIllustration,
        ActiveVideo: DefaultVideo,
      };
    case RENAMEMODULE:
      let modulename = action.payload;
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                ModuleName: modulename,
              },
            },
          },
        },
      };
    case REMOVEMODULE: {
      let moduleId = action.payload;
      let activeSlide = DefaultActiveSlide;
      let activeScreen = DefaultActiveScreen;
      let activeModule = DefaultActiveModule;
      let newState = Object.entries(state.createdProject[state.ProjectId].Modules).filter(
        ([_i, item]) => {
          if (_i != moduleId) {
            activeModule = _i;
            const slideKeys = Object.keys(item.slides);
            activeSlide = slideKeys[0];

            const screenKeys = Object.keys(item.slides[activeSlide].Screens);
            activeScreen = screenKeys[0];

            return true;
          }
          return false;
        }
      );
      let filteredObject = Object.fromEntries(newState);

      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: filteredObject,
          },
        },
        ActiveModule: activeModule,
        ActiveSlide: activeSlide,
        ActiveScreen: activeScreen,
      };
    }

    case SELECTMODULE:
      let modules = state.createdProject[state.ProjectId].Modules;
      let first_slide1 = Object.keys(modules[action.payload.moduleId].slides)[0];
      let first_screen1 = Object.keys(
        modules[action.payload.moduleId].slides[first_slide1].Screens
      )[0];
      let ostlist1 = Object.keys(
        modules[action.payload.moduleId].slides[first_slide1].Screens[first_screen1]
      ).filter(key => key.includes('OS'));
      let first_ost1 = ostlist1[0];
      return {
        ...state,
        ActiveModule: action.payload.moduleId,
        ActiveSlide: first_slide1,
        ActiveScreen: first_screen1,
        ActiveOst: first_ost1,
      };
    case ADDSLIDE:
      let SlideId = Object.keys(action.payload)[0];
      let ScreenId = Object.keys(action.payload[SlideId].Screens)[0];
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                slides: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                  ...action.payload,
                },
              },
            },
          },
        },
        ActiveSlide: SlideId,
        ActiveScreen: ScreenId,
        ActiveOst: DefaultActiveOst,
        ActiveImage: DefaultActiveImage,
        ActiveIllustration: DefaultIllustration,
        ActiveVideo: DefaultVideo,
      };
    case SELECTSLIDE:
      let activeScreen = Object.keys(
        state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[action.payload]
          .Screens
      )[0];
      let ostlist = Object.keys(
        state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[action.payload]
          .Screens[activeScreen]
      ).filter(key => key.includes('OS'));
      let first_ost2 = ostlist[0];
      return {
        ...state,
        ActiveSlide: action.payload,
        ActiveScreen: activeScreen,
        ActiveOst: first_ost2,
      };
    case RENAMESLIDE:
      let slidename = action.payload;
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                slides: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                  [state.ActiveSlide]: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                      state.ActiveSlide
                    ],
                    SlideName: slidename,
                  },
                },
              },
            },
          },
        },
      };
    case REMOVESLIDE:
      let slideId = action.payload;
      let filteredSlides = Object.entries(
        state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides
      ).filter(([_i, item]) => _i != slideId);
      let filteredSlidesObject = Object.fromEntries(filteredSlides);
      var last_added_slide = null;
      for (var key in filteredSlidesObject) {
        last_added_slide = key;
      }
      let last_screen = Object.keys(filteredSlidesObject[last_added_slide].Screens)[0];
      let ostlist5 = Object.keys(
        state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[last_added_slide]
          .Screens[last_screen]
      ).filter(key => key.includes('OS'));
      let first_ost5 = ostlist5[0];
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                slides: filteredSlidesObject,
              },
            },
          },
        },
        ActiveSlide: last_added_slide,
        ActiveScreen: last_screen,
        ActiveOst: first_ost5,
      };
    case ADDSCREEN:
      let LatestScreen = Object.keys(action.payload)[0];
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                slides: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                  [state.ActiveSlide]: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                      state.ActiveSlide
                    ],
                    Screens: {
                      ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                        state.ActiveSlide
                      ].Screens,
                      ...action.payload,
                    },
                  },
                },
              },
            },
          },
        },
        ActiveScreen: LatestScreen,
        ActiveOst: DefaultActiveOst,
        ActiveImage: DefaultActiveImage,
        ActiveIllustration: DefaultIllustration,
        ActiveVideo: DefaultVideo,
      };
    case REMOVESCREEN:
      let screenId = action.payload;
      let filteredScreens = Object.entries(
        state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[state.ActiveSlide]
          .Screens
      ).filter(([_i, item]) => _i != screenId);
      let filteredScreensObject = Object.fromEntries(filteredScreens);
      var last_added_screen = null;
      for (var key in filteredScreensObject) {
        last_added_screen = key;
      }
      let ostlist3 = Object.keys(
        state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[state.ActiveSlide]
          .Screens[last_added_screen]
      ).filter(key => key.includes('OS'));
      let first_ost3 = ostlist3[0];
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                slides: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                  [state.ActiveSlide]: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                      state.ActiveSlide
                    ],
                    Screens: filteredScreensObject,
                  },
                },
              },
            },
          },
        },
        ActiveScreen: last_added_screen,
        ActiveOst: first_ost3,
      };
    case SELECTSCREEN:
      let ostlist4 = Object.keys(
        state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[state.ActiveSlide]
          .Screens[action.payload]
      ).filter(key => key.includes('OS'));
      let first_ost4 = ostlist4[0];
      return {
        ...state,
        ActiveScreen: action.payload,
        ActiveOst: first_ost4,
      };
    case RENAMESCREEN:
      let screenname = action.payload;
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                slides: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                  [state.ActiveSlide]: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                      state.ActiveSlide
                    ],
                    Screens: {
                      ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                        state.ActiveSlide
                      ].Screens,
                      [state.ActiveScreen]: {
                        ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                          state.ActiveSlide
                        ].Screens[state.ActiveScreen],
                        ScreenName: screenname,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    case EDITAUDIOSCRIPT:
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                slides: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                  [state.ActiveSlide]: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                      state.ActiveSlide
                    ],
                    Screens: {
                      ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                        state.ActiveSlide
                      ].Screens,
                      [state.ActiveScreen]: {
                        ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                          state.ActiveSlide
                        ].Screens[state.ActiveScreen],
                        AS01: {
                          ...state.createdProject[state.ProjectId].Modules[state.ActiveModule]
                            .slides[state.ActiveSlide].Screens[state.ActiveScreen].AS01,
                          Contentparams: action.payload,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };

    case EDITSCREENDURATION:
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                slides: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                  [state.ActiveSlide]: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                      state.ActiveSlide
                    ],
                    Screens: {
                      ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                        state.ActiveSlide
                      ].Screens,
                      [state.ActiveScreen]: {
                        ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                          state.ActiveSlide
                        ].Screens[state.ActiveScreen],
                        SD01: {
                          ...state.createdProject[state.ProjectId].Modules[state.ActiveModule]
                            .slides[state.ActiveSlide].Screens[state.ActiveScreen].SD01,
                          Contentparams: action.payload,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };

    case UPDATETEMPLATEURL:
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [action.payload.projectId]: {
            ...state.createdProject[action.payload.projectId],
            Modules: {
              ...state.createdProject[action.payload.projectId].Modules,
              [action.payload.ModuleId]: {
                ...state.createdProject[action.payload.projectId].Modules[action.payload.ModuleId],
                slides: {
                  ...state.createdProject[action.payload.projectId].Modules[action.payload.ModuleId]
                    .slides,
                  [action.payload.SlideId]: {
                    ...state.createdProject[action.payload.projectId].Modules[
                      action.payload.ModuleId
                    ].slides[action.payload.SlideId],
                    Screens: {
                      ...state.createdProject[action.payload.projectId].Modules[
                        action.payload.ModuleId
                      ].slides[action.payload.SlideId].Screens,
                      [action.payload.ScreenId]: {
                        ...state.createdProject[action.payload.projectId].Modules[
                          action.payload.ModuleId
                        ].slides[action.payload.SlideId].Screens[action.payload.ScreenId],
                        TM01: {
                          ...state.createdProject[action.payload.projectId].Modules[
                            action.payload.ModuleId
                          ].slides[action.payload.SlideId].Screens[action.payload.ScreenId].TM01,
                          Contentparams: action.payload.templateUrl.Contentparams,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    case EDITASSET:
      let activeAssetType;
      if (action.payload.type === 'image') {
        activeAssetType = state.ActiveImage;
      } else if (action.payload.type === 'illustration') {
        activeAssetType = state.ActiveIllustration;
      } else if (action.payload.type === 'video') {
        activeAssetType = state.ActiveVideo;
      }
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                slides: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                  [state.ActiveSlide]: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                      state.ActiveSlide
                    ],
                    Screens: {
                      ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                        state.ActiveSlide
                      ].Screens,
                      [state.ActiveScreen]: {
                        ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                          state.ActiveSlide
                        ].Screens[state.ActiveScreen],
                        [activeAssetType]: {
                          ...state.createdProject[state.ProjectId].Modules[state.ActiveModule]
                            .slides[state.ActiveSlide].Screens[state.ActiveScreen][activeAssetType],
                          Contentparams: {
                            ...state.createdProject[state.ProjectId].Modules[state.ActiveModule]
                              .slides[state.ActiveSlide].Screens[state.ActiveScreen][
                              activeAssetType
                            ].Contentparams,
                            ...action.payload.data,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    case ADDFEATURE:
      let featureTypeId = Object.keys(action.payload)[0];
      let activeostid = state.ActiveOst;
      let activeimageid = state.ActiveImage;
      let activeilluid = state.ActiveIllustration;
      let activevideoid = state.ActiveVideo;
      if (action.payload[featureTypeId].Contenttype === 'OST') {
        activeostid = featureTypeId;
      } else if (action.payload[featureTypeId].Contenttype === 'Image') {
        activeimageid = featureTypeId;
      } else if (action.payload[featureTypeId].Contenttype === 'Illustration') {
        activeilluid = featureTypeId;
      } else if (action.payload[featureTypeId].Contenttype === 'Video') {
        activevideoid = featureTypeId;
      }
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                slides: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                  [state.ActiveSlide]: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                      state.ActiveSlide
                    ],
                    Screens: {
                      ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                        state.ActiveSlide
                      ].Screens,
                      [state.ActiveScreen]: {
                        ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                          state.ActiveSlide
                        ].Screens[state.ActiveScreen],
                        ...action.payload,
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ActiveOst: activeostid,
        ActiveImage: activeimageid,
        ActiveIllustration: activeilluid,
        ActiveVideo: activevideoid,
      };
    case EDITFEATURE:
      return {
        ...state,
        // createdProject: {
        //   ...state.createdProject,
        //   [state.ProjectId]: {
        //     ...state.createdProject[state.ProjectId],
        //     Modules: {
        //       ...state.createdProject[state.ProjectId].Modules,
        //       [state.ActiveModule]: {
        //         ...state.createdProject[state.ProjectId].Modules[
        //         state.ActiveModule
        //         ],
        //         slides: {
        //           ...state.createdProject[state.ProjectId].Modules[
        //             state.ActiveModule
        //           ].slides,
        //           [state.ActiveSlide]: {
        //             ...state.createdProject[state.ProjectId].Modules[
        //               state.ActiveModule
        //             ].slides[state.ActiveSlide],
        //             Screens: {
        //               ...state.createdProject[state.ProjectId].Modules[
        //                 state.ActiveModule
        //               ].slides[state.ActiveSlide].Screens,
        //               [state.ActiveScreen]: {
        //                 ...state.createdProject[state.ProjectId].Modules[
        //                   state.ActiveModule
        //                 ].slides[state.ActiveSlide].Screens[state.ActiveScreen],
        //                 ...action.payload,
        //               },
        //             },
        //           },
        //         },
        //       },
        //     },
        //   },
        // }
      };
    case DELETEFEATURE:
      let FeatureType = action.payload.type;
      let activeFeature;
      let filteredFeatures = Object.fromEntries(
        Object.entries(
          state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
            state.ActiveSlide
          ].Screens[state.ActiveScreen]
        ).filter(([_i, item]) => _i != action.payload.featureId)
      );
      let activeFeatures;
      if (FeatureType === 'OS') {
        activeFeatures = Object.fromEntries(
          Object.entries(filteredFeatures).filter(([key]) => key.includes('OS'))
        );
        for (var key in activeFeatures) {
          activeFeature = key;
        }
        return {
          ...state,
          createdProject: {
            ...state.createdProject,
            [state.ProjectId]: {
              ...state.createdProject[state.ProjectId],
              Modules: {
                ...state.createdProject[state.ProjectId].Modules,
                [state.ActiveModule]: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                  slides: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                    [state.ActiveSlide]: {
                      ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                        state.ActiveSlide
                      ],
                      Screens: {
                        ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                          state.ActiveSlide
                        ].Screens,
                        [state.ActiveScreen]: filteredFeatures,
                      },
                    },
                  },
                },
              },
            },
          },
          ActiveOst: activeFeature,
        };
      } else if (FeatureType == 'IM') {
        activeFeatures = Object.fromEntries(
          Object.entries(filteredFeatures).filter(([key]) => key.includes('IM'))
        );
        for (var key in activeFeatures) {
          activeFeature = key;
        }
        return {
          ...state,
          createdProject: {
            ...state.createdProject,
            [state.ProjectId]: {
              ...state.createdProject[state.ProjectId],
              Modules: {
                ...state.createdProject[state.ProjectId].Modules,
                [state.ActiveModule]: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                  slides: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                    [state.ActiveSlide]: {
                      ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                        state.ActiveSlide
                      ],
                      Screens: {
                        ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                          state.ActiveSlide
                        ].Screens,
                        [state.ActiveScreen]: filteredFeatures,
                      },
                    },
                  },
                },
              },
            },
          },
          ActiveImage: activeFeature,
        };
      } else if (FeatureType == 'IL') {
        activeFeatures = Object.fromEntries(
          Object.entries(filteredFeatures).filter(([key]) => key.includes('IL'))
        );
        for (var key in activeFeatures) {
          activeFeature = key;
        }
        return {
          ...state,
          createdProject: {
            ...state.createdProject,
            [state.ProjectId]: {
              ...state.createdProject[state.ProjectId],
              Modules: {
                ...state.createdProject[state.ProjectId].Modules,
                [state.ActiveModule]: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                  slides: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                    [state.ActiveSlide]: {
                      ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                        state.ActiveSlide
                      ],
                      Screens: {
                        ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                          state.ActiveSlide
                        ].Screens,
                        [state.ActiveScreen]: filteredFeatures,
                      },
                    },
                  },
                },
              },
            },
          },
          ActiveIllustration: activeFeature,
        };
      } else if (FeatureType == 'VD') {
        activeFeatures = Object.fromEntries(
          Object.entries(filteredFeatures).filter(([key]) => key.includes('VD'))
        );
        for (var key in activeFeatures) {
          activeFeature = key;
        }
        return {
          ...state,
          createdProject: {
            ...state.createdProject,
            [state.ProjectId]: {
              ...state.createdProject[state.ProjectId],
              Modules: {
                ...state.createdProject[state.ProjectId].Modules,
                [state.ActiveModule]: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                  slides: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                    [state.ActiveSlide]: {
                      ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                        state.ActiveSlide
                      ],
                      Screens: {
                        ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                          state.ActiveSlide
                        ].Screens,
                        [state.ActiveScreen]: filteredFeatures,
                      },
                    },
                  },
                },
              },
            },
          },
          ActiveVideo: activeFeature,
        };
      }

    case EDITOST:
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                slides: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                  [state.ActiveSlide]: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                      state.ActiveSlide
                    ],
                    Screens: {
                      ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                        state.ActiveSlide
                      ].Screens,
                      [state.ActiveScreen]: {
                        ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                          state.ActiveSlide
                        ].Screens[state.ActiveScreen],
                        [state.ActiveOst]: {
                          ...state.createdProject[state.ProjectId].Modules[state.ActiveModule]
                            .slides[state.ActiveSlide].Screens[state.ActiveScreen][state.ActiveOst],
                          Contentparams: action.payload,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    case SELECTOST:
      return {
        ...state,
        ActiveOst: action.payload,
      };

    case SELECTIMAGE:
      return {
        ...state,
        ActiveImage: action.payload,
      };
    case SELECTILLUSTRATION:
      return {
        ...state,
        ActiveIllustration: action.payload,
      };
    case SELECTVIDEO:
      return {
        ...state,
        ActiveVideo: action.payload,
      };
    case UPDATEOSTFONT: {
      let oldStyle = action.payload.oldStyle;
      let newStyle = action.payload.newStyle;
      Object.values(state.createdProject[state.ProjectId].Modules).forEach(slide => {
        Object.values(slide.slides).forEach(screen => {
          Object.values(screen.Screens).forEach(Screens => {
            let CS01 = Screens['CS01'];
            let screenStyleData = {};
            let OST = Object.keys(Screens).map(i => {
              if (Screens[i].Contenttype === 'OST') {
                let font = Screens[i].Contentparams.FontFamily.split('/');
                screenStyleData['fontFamily'] = font[4];
                screenStyleData['fontStyle'] = font[5];
                screenStyleData['textColor'] = Screens[i].Contentparams.FontColour;
                screenStyleData['headingSize'] = Screens[i].Contentparams.FontSizeH;
                screenStyleData['paragraphSize'] = Screens[i].Contentparams.FontSizeP;
              }
            });
            screenStyleData['bgColor'] = CS01.Contentparams.contentdescription;

            Object.entries(Screens).map(item => {
              if (JSON.stringify(screenStyleData) === JSON.stringify(oldStyle)) {
                if (item[0] === 'CS01') {
                  item[1].Contentparams.contentdescription = newStyle.bgColor;
                }
                if (item[1].Contenttype === 'OST') {
                  item[1].Contentparams.FontFamily =
                    FONT_URL + newStyle.fontFamily + '/' + newStyle.fontStyle;
                  item[1].Contentparams.FontSizeH = newStyle.headingSize;
                  item[1].Contentparams.FontSizeP = newStyle.paragraphSize;
                  item[1].Contentparams.FontColour = newStyle.textColor;
                }
              }
            });
          });
        });
      });
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: state.createdProject[state.ProjectId],
        },
        FontFamily: newStyle.fontFamily,
        FontStyle: newStyle.fontStyle,
        FontHSize: newStyle.headingSize,
        FontPSize: newStyle.paragraphSize,
        TextColor: newStyle.textColor,
        BgColor: newStyle.bgColor,
        FontUrl: DefaultFontUrl + newStyle.fontFamily + '/' + newStyle.fontStyle,
      };
    }
    case SETOSTFONTFORNEWSCREENS: {
      let FONTCOLOR, BGCOLOR;
      if (action.payload.fontColor.length === 8) {
        FONTCOLOR = action.payload.fontColor.split('*')[0];
      } else {
        FONTCOLOR = action.payload.fontColor;
      }
      if (action.payload.bgColor.length === 8) {
        BGCOLOR = action.payload.bgColor.split('*')[0];
      } else {
        BGCOLOR = action.payload.bgColor;
      }
      Object.values(state.createdProject[state.ProjectId].Modules).forEach(slide => {
        Object.values(slide.slides).forEach(screen => {
          Object.values(screen.Screens).forEach(Screens => {
            Object.entries(Screens).map(item => {
              if (item[1].Contenttype === 'OST') {
                if (item[1].Contentparams.FontColour.length === 8) {
                  item[1].Contentparams.FontFamily = FONT_URL + action.payload.fontFamily;
                  if (action.payload.fontStyle) {
                    item[1].Contentparams.FontFamily =
                      item[1].Contentparams.FontFamily + '/' + action.payload.fontStyle;
                  }
                  item[1].Contentparams.FontSizeH = action.payload.fontH;
                  item[1].Contentparams.FontSizeP = action.payload.fontP;
                  item[1].Contentparams.FontColour = FONTCOLOR;
                }
              }
              if (item[0] === 'CS01') {
                if (item[1].Contentparams.contentdescription.length === 8) {
                  item[1].Contentparams.contentdescription = BGCOLOR;
                }
              }
            });
          });
        });
      });
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: state.createdProject[state.ProjectId],
        },
        FontFamily: action.payload.fontFamily,
        FontStyle: action.payload.fontStyle,
        FontHSize: action.payload.fontH,
        FontPSize: action.payload.fontP,
        TextColor: FONTCOLOR,
        BgColor: BGCOLOR,
        FontUrl: DefaultFontUrl + action.payload.fontFamily + '/' + action.payload.fontStyle,
      };
    }

    case SETOSTFONT: {
      Object.values(state.createdProject[state.ProjectId].Modules).forEach(slide => {
        Object.values(slide.slides).forEach(screen => {
          Object.values(screen.Screens).forEach(Screens => {
            Object.entries(Screens).map(item => {
              if (item[1].Contenttype === 'OST') {
                item[1].Contentparams.FontFamily = FONT_URL + action.payload.fontFamily;
                if (action.payload.fontStyle) {
                  item[1].Contentparams.FontFamily =
                    item[1].Contentparams.FontFamily + '/' + action.payload.fontStyle;
                }

                item[1].Contentparams.FontSizeH = action.payload.fontH;
                item[1].Contentparams.FontSizeP = action.payload.fontP;
                item[1].Contentparams.FontColour = action.payload.fontColor;
              }
              if (item[0] === 'CS01') {
                item[1].Contentparams.contentdescription = action.payload.bgColor;
              }
            });
          });
        });
      });

      // let activeFeatures = Object.entries(state.createdProject[state.ProjectId].Modules[state.ActiveModule]
      //   .slides[state.ActiveSlide].Screens[state.ActiveScreen]).map((item) => {
      //     if (item[1].Contenttype === "OST") {
      //       item[1].Contentparams.FontFamily = FONT_URL + action.payload.fontFamily + "/" + action.payload.fontStyle
      //       item[1].Contentparams.FontSizeH = action.payload.fontH
      //       item[1].Contentparams.FontSizeP = action.payload.fontP
      //       item[1].Contentparams.FontColour = action.payload.fontColor
      //     }
      //     if (item[0] === "CS01") {
      //       item[1].Contentparams.contentdescription = action.payload.bgColor
      //     }
      //     return item;
      //   });
      // let setFontOstFeature = Object.fromEntries(activeFeatures);
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: state.createdProject[state.ProjectId],
          //{
          //   ...state.createdProject[state.ProjectId],
          //   Modules: {
          //     ...state.createdProject[state.ProjectId].Modules,
          //     [state.ActiveModule]: {
          //       ...state.createdProject[state.ProjectId].Modules[
          //       state.ActiveModule
          //       ],
          //       slides: {
          //         ...state.createdProject[state.ProjectId].Modules[
          //           state.ActiveModule
          //         ].slides,
          //         [state.ActiveSlide]: {
          //           ...state.createdProject[state.ProjectId].Modules[
          //             state.ActiveModule
          //           ].slides[state.ActiveSlide],
          //           Screens: {
          //             ...state.createdProject[state.ProjectId].Modules[
          //               state.ActiveModule
          //             ].slides[state.ActiveSlide].Screens,
          //             [state.ActiveScreen]: setFontOstFeature
          //           },
          //         },
          //       },
          //     },
          //   },
          // },
        },
        FontFamily: action.payload.fontFamily,
        FontStyle: action.payload.fontStyle,
        FontHSize: action.payload.fontH,
        FontPSize: action.payload.fontP,
        TextColor: action.payload.fontColor,
        BgColor: action.payload.bgColor,
        FontUrl: DefaultFontUrl + action.payload.fontFamily + '/' + action.payload.fontStyle,
      };
    }
    case EDITMODE: {
      return {
        ...state,
        EditMode: action.payload,
      };
    }

    case DISPLAYMODE: {
      return {
        ...state,
        DisplayMode: action.payload,
      };
    }
    case TOGGLERIGHTSCREEN: {
      return {
        ...state,
        togglerightscreen: action.payload,
      };
    }
    case TOGGLELEFTSCREEN: {
      return {
        ...state,
        toggleleftscreen: action.payload,
      };
    }
    case PREVIOUSACTIVE: {
      return {
        ...state,
        ActiveModule: action.payload.activeModule,
        ActiveSlide: action.payload.activeSlide,
        ActiveScreen: action.payload.activeScreen,
      };
    }
    case EDIT_SCREEN_FONT_STYLES: {
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                slides: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                  [state.ActiveSlide]: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                      state.ActiveSlide
                    ],
                    Screens: {
                      ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                        state.ActiveSlide
                      ].Screens,
                      [state.ActiveScreen]: {
                        ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                          state.ActiveSlide
                        ].Screens[state.ActiveScreen],

                        ...Object.entries(
                          state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                            state.ActiveSlide
                          ].Screens[state.ActiveScreen]
                        ).forEach(([key, value]) => {
                          const activeScreen =
                            state.createdProject[state.ProjectId].Modules[state.ActiveModule]
                              .slides[state.ActiveSlide].Screens[state.ActiveScreen];
                          if (value.Contenttype === 'OST') {
                            activeScreen[key].Contentparams.FontFamily =
                              FONT_URL + action.payload.fontFamily;
                            if (action.payload.fontStyle) {
                              activeScreen[key].Contentparams.FontFamily =
                                activeScreen[key].Contentparams.FontFamily +
                                '/' +
                                action.payload.fontStyle;
                            }

                            activeScreen[key].Contentparams.FontSizeH = action.payload.fontH;
                            activeScreen[key].Contentparams.FontSizeP = action.payload.fontP;
                            activeScreen[key].Contentparams.FontColour = action.payload.fontColor;
                          }
                          if (key === 'CS01') {
                            activeScreen[key].Contentparams.contentdescription =
                              action.payload.bgColor;
                          }
                        }),
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    }

    case UPDATE_AUDIO_UPLOAD: {
      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [state.ProjectId]: {
            ...state.createdProject[state.ProjectId],
            Modules: {
              ...state.createdProject[state.ProjectId].Modules,
              [state.ActiveModule]: {
                ...state.createdProject[state.ProjectId].Modules[state.ActiveModule],
                slides: {
                  ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides,
                  [state.ActiveSlide]: {
                    ...state.createdProject[state.ProjectId].Modules[state.ActiveModule].slides[
                      state.ActiveSlide
                    ],
                    SlideAudioVO: action.payload,
                  },
                },
              },
            },
          },
        },
      };
    }

    case STYLES_DATA: {
      return {
        ...state,
        stylesData: action.payload,
        ProjectId: action.payload.project_id || state.ProjectId,
      };
    }

    case UPDATE_PROJECT_SCREEN_PREVIEW: {
      const { projectId, moduleId, slideId, screenId, mediaurl } = action.payload;

      if (!state.createdProject[projectId].Modules[moduleId].slides[slideId].Screens[screenId]) {
        return state;
      }

      return {
        ...state,
        createdProject: {
          ...state.createdProject,
          [projectId]: {
            ...state.createdProject[projectId],
            Modules: {
              ...state.createdProject[projectId].Modules,
              [moduleId]: {
                ...state.createdProject[projectId].Modules[moduleId],
                slides: {
                  ...state.createdProject[projectId].Modules[moduleId].slides,
                  [slideId]: {
                    ...state.createdProject[projectId].Modules[moduleId].slides[slideId],
                    Screens: {
                      ...state.createdProject[projectId].Modules[moduleId].slides[slideId].Screens,
                      [screenId]: {
                        ...state.createdProject[projectId].Modules[moduleId].slides[slideId]
                          .Screens[screenId],
                        mediaurl,
                      },
                    },
                  },
                },
              },
            },
          },
        },
        previewGeneratedOf: `${projectId}-${moduleId}-${slideId}-${screenId}`,
      };
    }

    case CLEAR_PREVIEW_GENERATED_OF: {
      return {
        ...state,
        previewGeneratedOf: '',
      };
    }

    default:
      return state;
  }
};

export default projectReducer;
