import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch, Switch, Route } from "react-router-dom";
import {
  showToaster,
  ShowAlertBox,
  getUserItem,
} from "../../../services/helper";
import Tooltip from "@mui/material/Tooltip";
import { getUsageProject } from "../../../services/api.service";
import Select from "react-select";
import TrackinProject from "./TrackingProjectDetails";
import Header from "../../components/header/header";
import PageLoader from "../../../services/pageLoader";
import Pagination from "../../dashboard/project-priview/RightBar/pagination";
import { useStateWithCallbackLazy } from "use-state-with-callback";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function UsageTracking() {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [projectList, setprojectList] = useState([]);
  const [openProjectPreview, setOpenProjectPreview] = useState(false);
  const [ProjectID, setProjectID] = useState("");
  const [fullData, setfullData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(7);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [posts, setPosts] = useState(undefined);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [listStatus, setListStatus] = useState({
    value: 30,
    label: "30 days",
  });
  // var dt = new Date();
  const getStartDate = (noofdays) => {
    var dt = new Date();
    var startDate = new Date(dt.setDate(dt.getDate() - noofdays));
    return startDate;
  };
  const history = useHistory();
  const [value, onChange] = useState(new Date());
  const [startDate, setStartDate] = useState(getStartDate(listStatus.value));
  const [endDate, setEndDate] = useState(new Date());
  const [listFilterStatus, setListFilterStatus] = useState([]);
  const [statusOption, setStatusOption] = useStateWithCallbackLazy();
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(7);
  const [loadMoreFetching, setLoadMoreFetching] = useState(false);
  const [loadMoreEnable, setLoadMoreEnable] = useState(false);
  const [fetching, setFetching] = useState(true);
  // const [currentPosts, setcurrentPosts] = useState(undefined)
  // var  currentPosts=[]
  // console.log(posts,"posts");
  // const currentPosts=""
  const currentPosts =
    posts !== undefined && posts.slice(indexOfFirstPost, indexOfLastPost);
  // useEffect(() => {
  //     if (posts !== undefined) {

  //     }
  // }, [posts])
  const generateStatus = () => {
    if (listFilterStatus != undefined) {
      let statusOption = [];
      listFilterStatus.map((filter) => {
        console.log(filter);
        statusOption.push({
          value: filter.value,
          label: filter.label,
        });
        return true;
      });

      setStatusOption(statusOption);
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };

  const FilterByStatus = () => {
    let filerStatus = [
      {
        value: 5,
        label: "5 days",
      },
      {
        value: 10,
        label: "10 days",
      },
      {
        value: 20,
        label: "20 days",
      },
      {
        value: 30,
        label: "30 days",
      },
    ];

    setListFilterStatus(filerStatus);
  };
  useEffect(() => {
    generateStatus();
    // setListStatus({
    //     value: 15,
    //     label: "15 days",

    // });
  }, [listFilterStatus]);

  const paginate = (pageNumber) => {
    console.log(pageNumber);
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    console.log(value);

    getAllProjects(1);
    setPosts(projectList.project_details);
    FilterByStatus();
    if (posts !== undefined) {
    }
  }, []);
  // useEffect(() => {
  //     setPosts(projectList.project_details);
  //     console.log(projectList);

  // }, [projectList])

  useEffect(() => {
    getAllProjects(1);
  }, [startDate, endDate]);
  const moveToNextPage = () => {
    setFetching(true);
    setLoadMoreFetching(true);
    getAllProjects(pageNo + 1);
    setPageNo(pageNo + 1);
    // setLimit(pageNo *setLimit);
  };

  const getAllProjects = (PageNo) => {
   
    let userId = getUserItem("id");
  let orgId = getUserItem("orgId");
  let AdminRole = getUserItem("role");
  let Id = AdminRole == 0 ? userId : orgId;
    let filter = {
      userId: Id,
      startDate: new Date(startDate),
      endDate: endDate,
    };
    setPageLoaded(true);
    try {
      getUsageProject(filter).then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            let projectData = response.data;
            console.log(projectData);
            setLoadMoreEnable(false);
            // let projectList1 = [...projectList, ...projectData];
            // let projectList = projectData;
            if (projectData.project_details.length < limit) {
              setLoadMoreEnable(false);
            } else {
              setLoadMoreEnable(true);
            }
            setprojectList(projectData);
            setPosts(projectData.project_details);
            setPageLoaded(false);
          } else {
            console.log(projectList);
            showToaster("No records available", "warning");
            setprojectList([]);
            setPageLoaded(false);
            setLoadMoreEnable(false);
            setPosts([]);
          }
        } catch (error) {
          showToaster("Something went wrong. Please Try Again", "warning");
          setprojectList([]);
          setPageLoaded(false);
          setLoadMoreEnable(false);
          setPosts([]);
        }
      });
    } catch (error) {
      showToaster("Something went wrong. Please Try Again", "warning");
      setprojectList([]);
      setPageLoaded(false);
      setLoadMoreEnable(false);
      setPosts([]);
    }
  };
  const handleChangeStatus = (e) => {
    setListStatus(e);
    setStartDate(getStartDate(Number(e.value)));
    setEndDate(new Date());
    console.log(e, "listtt");
  };
  const customStyles = {
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      height: "26px",
    }),
    singleValue: () => ({
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "12px",
      lineHeight: "18px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
    }),
    indicatorSeparator: () => ({
      border: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
  };
  const openDetailModal = (projectId, data) => {
    history.push(`/render-details`, {
      projectId: projectId,
      fullData: data,
      type: "tracking",
    });

    // setProjectID(projectId)
    // setOpenProjectPreview(true)
    // setfullData(data)
  };
  const RenderData = () => {
    history.push(`/project-list`);
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <PageLoader showLoader={pageLoaded} />
      <div className={DisplayMode ? "theme dark" : "theme"}>
        <div className="main-wrapper">
          <Header />
          <div className="pb-wrapper usage-track">
            <div className="row pb-header mb-4">
              <div className="col-md-6 col-lg-4">
                {/* <Tooltip title="Back to dashboard" arrow> */}
                <button
                  type="submit"
                  onClick={() => {
                    RenderData();
                  }}
                  className="btn btn-outline-primary text-nowrap back-button me-3 mb-2 mb-lg-0"
                  value="submit"
                >
                  <svg
                    width="14"
                    height="11"
                    viewBox="0 0 14 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.42652e-06 5.5C0.00211858 5.41315 0.0186696 5.32723 0.0490002 5.24563C0.0651946 5.21658 0.0839324 5.18898 0.105001 5.16313C0.123314 5.12504 0.144368 5.08828 0.168001 5.05313L4.368 0.240672C4.53056 0.0540017 4.78216 -0.0333139 5.02804 0.0116137C5.27392 0.0565413 5.47672 0.226887 5.56004 0.458484C5.64336 0.690081 5.59456 0.947744 5.432 1.13441L2.219 4.81251L13.3 4.81251C13.6866 4.81251 14 5.12031 14 5.5C14 5.87969 13.6866 6.18749 13.3 6.18749L2.219 6.18749L5.432 9.86559C5.59456 10.0523 5.64337 10.3099 5.56004 10.5415C5.47672 10.7731 5.27392 10.9435 5.02804 10.9884C4.78216 11.0333 4.53056 10.946 4.368 10.7593L0.168001 5.94687C0.144368 5.91172 0.123314 5.87496 0.105001 5.83687C0.0839324 5.81102 0.0651946 5.78342 0.0490003 5.75437C0.0186696 5.67277 0.00211859 5.58685 1.42652e-06 5.5Z"
                      fill=""
                    ></path>
                  </svg>
                </button>
                {/* </Tooltip> */}
              </div>
              <div className="col-md-6 col-lg-6 offset-lg-2">
                <div className="d-flex align-items-center justify-content-end pb-filter-holder flex-fill filter-date">
                  <div className="pb-filter-label pe-3">Filter by Date</div>
                  <div className="form-field d-flex align-items-center">
                    <div className="calender-wrap d-flex align-items-center">
                      <Tooltip title="Start Date" arrow>
                        <svg
                          width="20"
                          height="22"
                          viewBox="0 0 20 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.09277 8.40445H18.9167"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.442 12.3088H14.4512"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.0045 12.3088H10.0137"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.55769 12.3088H5.56695"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.442 16.1955H14.4512"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.0045 16.1955H10.0137"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.55769 16.1955H5.56695"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.0438 1V4.29078"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.96564 1V4.29078"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.2383 2.58008H5.77096C2.83427 2.58008 1 4.21601 1 7.2231V16.2727C1 19.3271 2.83427 21.0009 5.77096 21.0009H14.229C17.175 21.0009 19 19.3555 19 16.3484V7.2231C19.0092 4.21601 17.1842 2.58008 14.2383 2.58008Z"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Tooltip>
                      {/* <span>Start Date</span> */}
                      <div style={{ color: "red" }}>
                        <DatePicker
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          selected={startDate}
                          onChange={(date) => {
                            date.setHours(0, 0, 0, 0);
                            console.log(date, "date");
                            setStartDate(date);
                          }}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={endDate}
                          className="form-field"
                        />
                      </div>
                    </div>
                    <div className="calender-wrap d-flex align-items-center">
                      <Tooltip title="End Date" arrow>
                        <svg
                          width="20"
                          height="22"
                          viewBox="0 0 20 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.09277 8.40445H18.9167"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.442 12.3088H14.4512"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.0045 12.3088H10.0137"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.55769 12.3088H5.56695"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.442 16.1955H14.4512"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.0045 16.1955H10.0137"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.55769 16.1955H5.56695"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.0438 1V4.29078"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.96564 1V4.29078"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.2383 2.58008H5.77096C2.83427 2.58008 1 4.21601 1 7.2231V16.2727C1 19.3271 2.83427 21.0009 5.77096 21.0009H14.229C17.175 21.0009 19 19.3555 19 16.3484V7.2231C19.0092 4.21601 17.1842 2.58008 14.2383 2.58008Z"
                            stroke=""
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Tooltip>
                      {/* <span>End Date</span> */}
                      <div style={{ color: "red" }}>
                        <DatePicker
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          selected={endDate}
                          onChange={(date) => {
                            date.setHours(23, 59, 59, 999);
                            setEndDate(date);
                          }}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          className="form-field"
                          // style={{ border: "solid 1px pink" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="size-wrap col-md-6 col-sm-6 col-lg-4">
                <div className="d-flex align-items-center pb-card">
                  {!pageLoaded &&
                  projectList !== undefined &&
                  projectList.project_details !== undefined &&
                  projectList.project_details.length !== undefined ? (
                    <div className="pb-count text-center">
                      {projectList.project_details.length < 10
                        ? "0" + projectList.project_details.length
                        : projectList.project_details.length}
                    </div>
                  ) : (
                    <div className="pb-count text-center">0</div>
                  )}
                  <div className="pb-desc ">
                    Total number of the Projects Rendered
                  </div>
                </div>
              </div>
              <div className="size-wrap col-md-6 col-sm-6 col-lg-4">
                <div className="d-flex align-items-center pb-card">
                  <div className="pb-count text-center time-duration ">
                    {projectList.project_details === undefined ? (
                      <span>0</span>
                    ) : (
                      <span>
                        {projectList.Total_projectDuration < 60
                          ? projectList.Total_projectDuration
                          : projectList.Total_projectDuration > 60 &&
                            (projectList.Total_projectDuration > 3600
                              ? (
                                  projectList.Total_projectDuration / 3600
                                ).toFixed(2)
                              : (
                                  projectList.Total_projectDuration / 60
                                ).toFixed(2))}
                        <small>
                          {projectList.Total_projectDuration < 60
                            ? "SECONDS"
                            : projectList.Total_projectDuration < 3600
                            ? "MINUTES"
                            : "HOURS"}
                        </small>
                      </span>
                    )}
                  </div>
                  <div className="pb-desc">
                    Total time of the Rendered Videos
                  </div>
                </div>
              </div>

              <div className="size-wrap col-md-6 col-sm-6 col-lg-4">
                <div className="d-flex align-items-center pb-card">
                  <div className="pb-count text-center size-kb">
                    {projectList.project_details === undefined ? (
                      <span>
                        0
                        {/* <small>{projectList.Total_size < 1024 ? "KB" : "MB"}</small> */}
                      </span>
                    ) : (
                      <span>
                        {projectList.Total_size < 1024
                          ? Number(projectList.Total_size / 1024)
                          : Number(
                              projectList.Total_size / (1024 * 1024)
                            ).toFixed(2)}
                        <small>
                          {projectList.Total_size < 1024 ? "KB" : "MB"}
                        </small>
                      </span>
                    )}
                  </div>
                  <div className="pb-desc">
                    Total Size of the Rendered Videos
                  </div>
                </div>
              </div>
            </div>
            <div className="table-wraper usage-track-table">
              <div className="pb-tbl-blk">
                <div className="pb-tbl-wrapper mb-3 sticky-head mt-0">
                  <div className="pb-head-label"> Project Name </div>
                  <div className="pb-head-label text-center">
                    Total Size of the Video{" "}
                  </div>
                  <div className="pb-head-label text-center">Total Duration</div>
                  <div className="pb-head-label text-center">
                    Number of Modules{" "}
                  </div>
                  <div className="pb-head-label text-center">Number of Videos</div>
                  <div className="pb-head-label  ">Date </div>
                  <div className="pb-head-label "></div>
                </div>
                {posts !== undefined && posts.length > 0 ? (
                  currentPosts.map((data, index) => {
                    let sortedCreatedDate;
                    if (data.RenderDate !== undefined) {
                      let Created = data.RenderDate;
                      sortedCreatedDate = Created.split("T")[0];
                      console.log(sortedCreatedDate);
                    }
                    // console.log(editCategory && editCategoryID === data.id);
                    return (
                      <div className="pb-tbl-wrapper pb-data-tbl">
                        <div
                          className="pb-data-label cursor-pointer"
                          onClick={() =>
                            openDetailModal(data._id.project_id, data)
                          }
                        >
                          {data.ddd[0]}
                        </div>
                        {/* <div className="pb-data-label text-center">
                          {sortedCreatedDate}/{data.RenderTime}
                        </div> */}
                        <div className="pb-data-label text-center">
                          {data.Total_Size < 1024
                            ? Number(data.Total_Size / 1000)
                            : Number((data.Total_Size )/(1000)/1000
                            ).toFixed(2)}{" "}
                          MB
                        </div>
                        <div className="pb-data-label text-center ">
                          {data.Total_duration} SEC
                        </div>
                        <div className="pb-data-label text-center">
                          {data.Total_Modules}
                        </div>
                        <div className="pb-data-label text-center">
                          {data.Total_videos}
                        </div>
                        <div className="pb-data-label text-center">
                          {sortedCreatedDate}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="no-project-selected blink">
                    No project available
                  </div>
                )}
               
              </div>
            </div>
            {posts !== undefined && posts.length > 0 && (
                  <Pagination
                    paginate={paginate}
                    postsPerPage={postsPerPage}
                    totalPosts={posts.length}
                  />
                )}
          </div>
        </div>
      </div>
      {/* <TrackinProject
                closePreview={() => setOpenProjectPreview(false)}
                openProjectPreview={openProjectPreview}
                projectId={ProjectID}
                fullData={fullData}
            /> */}
    </>
  );
}
