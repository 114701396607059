import { useState } from 'react';
import { Button } from 'react-bootstrap';

import { deleteIcon, plusIcon, renderIcon } from '../../../../constants/icon';
import image4 from '../../../../assets/img/thumbnail/thumbnail-04.png';
import image5 from '../../../../assets/img/thumbnail/thumbnail-05.png';
import image8 from '../../../../assets/img/image-03.png';
import image9 from '../../../../assets/img/image-04.png';
import image10 from '../../../../assets/img/image-05.png';

import CustomSwitchSlider from '../../../../components/form/customSwitchSlider';
import AssetsCheckboxBlock from './assetsCheckboxBlock';
import PreviewModal from './previewModal';
import AddFilesModal from './addFilesModal';

function IllustratorComponent() {
  const [modalShow, setModalShow] = useState(false);
  const [previewModalShow, setPreviewModalShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpenModal = src => {
    setSelectedImage(src);
    setPreviewModalShow(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setPreviewModalShow(false);
  };
  return (
    <>
      <PreviewModal
        selectedImage={selectedImage}
        show={previewModalShow}
        onHide={handleCloseModal}
      />
      <AddFilesModal show={modalShow} onHide={() => setModalShow(false)} />

      <div className="assets-container">
        <div className="assets-content">
          <h2>All illustrator files</h2>
          <div className="assets-checkbox-holder">
            <Button
              className="add-new-file-btn"
              variant="default"
              onClick={() => setModalShow(true)}
            >
              {plusIcon}
              Add new file
            </Button>

            <AssetsCheckboxBlock
              className="assets-checkbox-preview"
              mediaContent
              src={image4}
              onClick={() => handleOpenModal(image4)}
            />
            <AssetsCheckboxBlock
              className="assets-checkbox-preview"
              mediaContent
              src={image5}
              onClick={() => handleOpenModal(image5)}
            />

            <AssetsCheckboxBlock
              className="assets-checkbox-preview"
              mediaContent
              src={image8}
              onClick={() => handleOpenModal(image8)}
            />
            <AssetsCheckboxBlock
              className="assets-checkbox-preview"
              mediaContent
              src={image9}
              onClick={() => handleOpenModal(image9)}
            />
            <AssetsCheckboxBlock
              className="assets-checkbox-preview"
              mediaContent
              src={image10}
              onClick={() => handleOpenModal(image10)}
            />
          </div>
        </div>
        <div className="assets-sidebar">
          <h2>Settings</h2>
          <div className="settung-sharing-wrap">
            <div className="info-wrap">
              <h4>File Size</h4>
              <span>154 Kbs</span>
            </div>
          </div>
          <div className="settung-sharing-wrap">
            <div className="info-wrap">
              <h4>File Sharing</h4>
              <p>Turn on to share your file changes and updates</p>
            </div>
            <CustomSwitchSlider />
          </div>
          <Button variant="default">{renderIcon} Download</Button>
          <Button variant="default">{deleteIcon} Delete</Button>
        </div>
      </div>
    </>
  );
}
export default IllustratorComponent;
