import { debounce } from 'debounce';
import { Modal } from 'react-bootstrap';
import { Checkbox } from '@mui/material';
import { Nav, NavItem } from 'reactstrap';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import ReactImageFallback from 'react-image-fallback';
import React, { useCallback, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  getAssetsV1,
  videoBlockSearchV1,
  downloadVideoBlockUrlV1,
} from '../../services/api.service';
import VideoPreview from './videoPreview';
import useDebounce from '../../hooks/debounceHook';
import { checkIcon, editIcon, eyeOpenIcon } from '../../constants/icon';
import Pagination from '../../modules/dashboard/project-priview/RightBar/pagination';
import { capitalizeFirstLetter, showToaster, getUserItem } from '../../services/helper';
import AssetUpload from '../../modules/dashboard/project-priview/RightBar/Assetupload';

export default function VideoAssetRepo(props) {
  const projectId = useSelector(state => state.project.ProjectId);
  const project = useSelector(state => state.project.createdProject);
  const { debounce: customDebounce, debouncedValue } = useDebounce();

  const [posts, setPosts] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [keyWord, setKeyWord] = useState('');
  const [checked, setChecked] = useState(false);
  const [videoRepo, setVideoRepo] = useState([]);
  const [endPageNo, setEndPageNo] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectName, setProjectName] = useState('');
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [videoActive, setVideoActive] = useState(true);
  const [pexelActive, setPexelActive] = useState(false);
  const [videoErrorMsg, setVideoErrorMsg] = useState('');
  const [totalUrlCount, setTotalUrlCount] = useState('');
  const [uploadActive, setUploadActive] = useState(false);
  const [videoBlockRepo, setVideoBlockRepo] = useState([]);
  const [videoBlockUrl, setvideoBlockUrl] = useState(false);
  const [videoRepoLoader, setVideoRepoLoader] = useState(true);
  const [videoBlockActive, setVideoBlockActive] = useState(false);
  const [openVideoPreview, setOpenVideoPreview] = useState(false);
  const [selectedVideoFile, setSelectedVideoFile] = useState(false);
  const [SearchBoxErrorMsg, setSearchBoxErrorMsg] = useState(false);
  const [videoBlockRepoLoader, setVideoBlockRepoLoader] = useState(false);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    if (debouncedValue !== null) {
      if (videoBlockActive || pexelActive) {
        getVideoBlockRepo(debouncedValue);
      }
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (props.openAssetRpo) {
      setKeyWord('');
      getAssetRepo(keyWord);
      if (props.type === 'video') {
        setPosts(videoRepo);
      }
      setVideoActive(true);
      setVideoBlockActive(false);
      setUploadActive(false);
      setPexelActive(false);
    }

    return () => setKeyWord('');
  }, [props.openAssetRpo]);

  useEffect(() => {
    if (videoBlockActive || pexelActive) {
      if (props.openAssetRpo) {
        setKeyWord('');
        getVideoBlockRepo(keyWord);
      }
    }
  }, [videoBlockActive, pexelActive]);

  useEffect(() => {
    setPosts(videoRepo);
  }, [videoRepo]);

  useEffect(() => {
    setTimeout(() => {
      setSearchBoxErrorMsg(false);
    }, 10000);
  }, [SearchBoxErrorMsg]);

  useEffect(() => {
    if (props.openAssetRpo && videoActive) {
      if (project[projectId] != null) {
        console.log(project[projectId].ProjectName, 'projectproject');
        setProjectName(project[projectId].ProjectName);
      }
      setKeyWord('');
      setVideoRepo([]);
      getAssetRepo(keyWord);
    }
  }, [videoActive]);

  useEffect(() => {
    if (props.openAssetRpo) getVideoBlockRepo(keyWord);
  }, [pageNo]);

  useEffect(() => {
    if (props.openAssetRpo) getAssetRepo(keyWord);
  }, [checked]);

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const __serachbox = val => {
    try {
      let tags;

      tags =
        val.target.value !== null && val.target.value !== 'Null' && val.target.value !== ''
          ? val.target.value.split(',')
          : '';
      setSearchBoxErrorMsg(false);
      if (keyWord !== tags) {
        setKeyWord(tags);
        if (videoActive) {
          getSearchBoxResult(tags);
        } else {
          getSearchBoxResult1(tags);
        }
      } else {
        if (tags !== '') {
          setKeyWord(tags);
        } else {
          setKeyWord('');
        }
      }
    } catch (error) {}
  };

  const getSearchBoxResult = useCallback(
    debounce(value => {
      try {
        console.log(props.type);
        if (props.type === 'video') {
          setVideoRepoLoader(true);
          getAssetRepo(value);
        }
      } catch (error) {}
    }, 1000),
    [videoRepoLoader]
  );

  const getSearchBoxResult1 = value => {
    customDebounce(value);
  };

  const __searchByClick = () => {
    let searchValue;
    searchValue =
      document.getElementById('searchBtn1').value !== null &&
      document.getElementById('searchBtn1').value !== 'Null' &&
      document.getElementById('searchBtn1').value !== ''
        ? document.getElementById('searchBtn1').value.split(',')
        : '';
    if (searchValue === '' && videoActive) {
      setSearchBoxErrorMsg(true);
    } else {
      setSearchBoxErrorMsg(false);
      if (searchValue !== '') {
        setVideoRepoLoader(true);
        if (videoBlockActive || pexelActive) {
          getVideoBlockRepo(searchValue);
        } else {
          getAssetRepo(searchValue);
        }
      } else {
        setSearchBoxErrorMsg(true);
      }
    }
  };

  const getVideoBlockRepo = searchWord => {
    if (props.type === 'video') {
      if (pexelActive && !searchWord) {
        setTotalUrlCount(0);
        setVideoBlockRepo([]);
        return setVideoErrorMsg('No videos available. Please search with appropriate keyword');
      }

      let request = {
        page: pageNo,
        keywords: searchWord,
        videoType: pexelActive ? 'PEXEL' : 'SHUTTER',
      };
      setVideoBlockRepoLoader(true);
      videoBlockSearchV1(request)
        .then(response => {
          if (
            response !== '' &&
            response !== undefined &&
            response !== null &&
            response.status !== '' &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== '' &&
            response.data !== undefined &&
            response.data !== null
          ) {
            if (response.data.length > 0) {
              let projectData = response.data;
              setVideoBlockRepo(projectData);
              setVideoBlockRepoLoader(false);
              if (response.totalcount !== undefined && response.totalcount !== null) {
                setTotalUrlCount(response.totalcount);
              }
              console.log(projectData, 'response');
            } else {
              setVideoBlockRepoLoader(false);
              setVideoBlockRepo([]);
              setTotalUrlCount(0);
              setVideoErrorMsg('No videos available');
            }
          } else {
            setVideoBlockRepoLoader(false);
            setVideoBlockRepo([]);
            setTotalUrlCount(0);
            setVideoErrorMsg('No videos available');
          }
        })
        .catch(error => {
          setVideoBlockRepoLoader(false);
          setVideoBlockRepo([]);
          setTotalUrlCount(0);
          setVideoErrorMsg('No videos available');
        });
    }
  };
  const viewPrivateRepo = event => {
    setChecked(event.target.checked);
  };

  const getAssetRepo = searchWord => {
    if (props.type === 'video') {
      let request = {
        media_type: 'VIDEO',
        search_term: searchWord,
        start: pageNo,
        end: endPageNo,
        private: !checked ? 0 : 1,
      };
      setVideoRepoLoader(true);
      getAssetsV1(request)
        .then(response => {
          if (
            response !== '' &&
            response !== undefined &&
            response !== null &&
            response.status !== '' &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== '' &&
            response.data !== undefined &&
            response.data !== null
          ) {
            if (Object.entries(response.data).length > 0) {
              let projectData = response.data;
              setVideoRepo(projectData);
              setVideoRepoLoader(false);
            } else {
              setVideoRepoLoader(false);
              setVideoRepo([]);
              setVideoErrorMsg('No videos available');
            }
          } else if (
            response !== '' &&
            response !== undefined &&
            response !== null &&
            response.message !== '' &&
            response.message !== undefined &&
            response.message !== null
          ) {
            setVideoRepoLoader(false);
            setVideoRepo([]);
            setVideoErrorMsg('No videos available');
          } else {
            setVideoRepoLoader(false);
            setVideoRepo([]);
            setVideoErrorMsg('No videos available');
          }
        })
        .catch(error => {
          setVideoRepoLoader(false);
          setVideoRepo([]);
          setVideoErrorMsg('No videos available');
        });
    }
  };

  const viewVideoPreview = video => {
    console.log(video, 'imageee');
    setOpenVideoPreview(true);
    setSelectedVideoFile(video);
  };
  const changeAssetSection = type => {
    if (type === '1') {
      setVideoActive(true);
      setPexelActive(false);
      setUploadActive(false);
      setVideoBlockActive(false);
    } else if (type === '2') {
      setVideoActive(false);
      setUploadActive(true);
      setPexelActive(false);
      setVideoBlockActive(false);
    } else if (type === '3') {
      setVideoActive(false);
      setPexelActive(false);
      setTotalUrlCount(0);
      setVideoBlockRepo([]);
      setUploadActive(false);
      setVideoBlockActive(true);
      setKeyWord('');
    } else if (type === 'PEXEL') {
      setKeyWord('');
      setPexelActive(true);
      setTotalUrlCount(0);
      setVideoBlockRepo([]);
      setVideoActive(false);
      setUploadActive(false);
      setVideoBlockActive(false);
    }
  };

  const SelectVideoAsset = data => {
    setvideoBlockUrl(true);
    const config = {
      video_id: data.id,
      user_id: getUserItem('id'),
      videoType: pexelActive ? 'PEXEL' : 'SHUTTER',
    };
    if (videoBlockActive || pexelActive) {
      downloadVideoBlockUrlV1(config)
        .then(response => {
          if (response.data) {
            setvideoBlockUrl(false);
            console.log(response.data);

            props.setdisplayImage(response.data[0]?.s3_url);
            props.closePreview();
            showToaster('Video uploaded successfully', 'success');
          } else {
            setvideoBlockUrl(false);
            showToaster('Something went wrong. Please try again ', 'warning');
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const SelectAsset = mediaUrl => {
    props.setdisplayImage(mediaUrl);
    props.closePreview();
    showToaster('Video uploaded successfully', 'success');
  };

  const Resetkeyword = () => {
    setKeyWord('');
    getVideoBlockRepo('');
  };

  const ResetVideoRepo = () => {
    setKeyWord('');
    getAssetRepo('');
  };

  return (
    <>
      <Modal
        centered
        size="xl"
        backdrop="static"
        show={props.openAssetRpo}
        className="replace-modal replace-of-replace-modal"
      >
        <Modal.Body>
          <div>
            <div>
              <Tooltip title="Close" arrow>
                <div onClick={() => props.closePreview()} className="close-priview">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g data-name="Layer 2">
                      <g data-name="close">
                        <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
                        <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                      </g>
                    </g>
                  </svg>
                </div>
              </Tooltip>
              <div className="navtab-selector tab-assets">
                <Nav className="nav nav-tabs" role="tablist">
                  <NavItem className="nav-item" onClick={() => changeAssetSection('1')}>
                    <button className={videoActive === true ? 'nav-link active' : 'nav-link'}>
                      <div className="cm-vs-container">
                        <div className="d-flex align-items-center">
                          <div className="cm-icon assets">
                            <svg
                              width="1792"
                              height="1792"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 1792 1792"
                            >
                              <g>
                                <path
                                  id="svg_1"
                                  d="m512,1248l0,192q0,40 -28,68t-68,28l-320,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l320,0q40,0 68,28t28,68zm0,-512l0,192q0,40 -28,68t-68,28l-320,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l320,0q40,0 68,28t28,68zm1280,512l0,192q0,40 -28,68t-68,28l-960,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l960,0q40,0 68,28t28,68zm-1280,-1024l0,192q0,40 -28,68t-68,28l-320,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l320,0q40,0 68,28t28,68zm1280,512l0,192q0,40 -28,68t-68,28l-960,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l960,0q40,0 68,28t28,68zm0,-512l0,192q0,40 -28,68t-68,28l-960,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l960,0q40,0 68,28t28,68z"
                                ></path>
                              </g>
                            </svg>
                          </div>
                          <div className="cm-asset-name">Videos</div>
                        </div>
                      </div>
                    </button>
                  </NavItem>
                  {/* <NavItem className="nav-item" onClick={() => changeAssetSection('3')}>
                    <button className={videoBlockActive === true ? 'nav-link active' : 'nav-link'}>
                      <div className="cm-vs-container">
                        <div className="d-flex align-items-center">
                          <div className="cm-icon stock">
                            <svg
                              width="1792"
                              height="1792"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 1792 1792"
                            >
                              <g>
                                <path
                                  id="svg_1"
                                  d="m512,1248l0,192q0,40 -28,68t-68,28l-320,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l320,0q40,0 68,28t28,68zm0,-512l0,192q0,40 -28,68t-68,28l-320,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l320,0q40,0 68,28t28,68zm1280,512l0,192q0,40 -28,68t-68,28l-960,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l960,0q40,0 68,28t28,68zm-1280,-1024l0,192q0,40 -28,68t-68,28l-320,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l320,0q40,0 68,28t28,68zm1280,512l0,192q0,40 -28,68t-68,28l-960,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l960,0q40,0 68,28t28,68zm0,-512l0,192q0,40 -28,68t-68,28l-960,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l960,0q40,0 68,28t28,68z"
                                ></path>
                              </g>
                            </svg>
                          </div>
                          <div className="cm-asset-name">Stock Footage</div>
                        </div>
                      </div>
                    </button>
                  </NavItem> */}
                  <NavItem className="nav-item" onClick={() => changeAssetSection('PEXEL')}>
                    <button className={pexelActive ? 'nav-link active' : 'nav-link'}>
                      <div className="cm-vs-container">
                        <div className="d-flex align-items-center">
                          <div className="cm-icon stock">
                            <svg
                              width="1792"
                              height="1792"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 1792 1792"
                            >
                              <g>
                                <path
                                  id="svg_1"
                                  d="m512,1248l0,192q0,40 -28,68t-68,28l-320,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l320,0q40,0 68,28t28,68zm0,-512l0,192q0,40 -28,68t-68,28l-320,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l320,0q40,0 68,28t28,68zm1280,512l0,192q0,40 -28,68t-68,28l-960,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l960,0q40,0 68,28t28,68zm-1280,-1024l0,192q0,40 -28,68t-68,28l-320,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l320,0q40,0 68,28t28,68zm1280,512l0,192q0,40 -28,68t-68,28l-960,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l960,0q40,0 68,28t28,68zm0,-512l0,192q0,40 -28,68t-68,28l-960,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l960,0q40,0 68,28t28,68z"
                                ></path>
                              </g>
                            </svg>
                          </div>
                          <div className="cm-asset-name">Stock Footage</div>
                        </div>
                      </div>
                    </button>
                  </NavItem>
                  <NavItem className="nav-item" onClick={() => changeAssetSection('2')}>
                    <button className={uploadActive === true ? 'nav-link active' : 'nav-link'}>
                      <div className="cm-vs-container">
                        <div className="d-flex align-items-center">
                          <div className="cm-icon stock">
                            <svg
                              height="512px"
                              id="Layer_1"
                              style={{ enableBackground: 'new 0 0 512 512' }}
                              version="1.1"
                              viewBox="0 0 512 512"
                              width="512px"
                              space="preserve"
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                            >
                              <g>
                                <path d="M368,224c26.5,0,48-21.5,48-48c0-26.5-21.5-48-48-48c-26.5,0-48,21.5-48,48C320,202.5,341.5,224,368,224z" />
                                <path d="M452,64H60c-15.6,0-28,12.7-28,28.3v327.4c0,15.6,12.4,28.3,28,28.3h392c15.6,0,28-12.7,28-28.3V92.3   C480,76.7,467.6,64,452,64z M348.9,261.7c-3-3.5-7.6-6.2-12.8-6.2c-5.1,0-8.7,2.4-12.8,5.7l-18.7,15.8c-3.9,2.8-7,4.7-11.5,4.7   c-4.3,0-8.2-1.6-11-4.1c-1-0.9-2.8-2.6-4.3-4.1L224,215.3c-4-4.6-10-7.5-16.7-7.5c-6.7,0-12.9,3.3-16.8,7.8L64,368.2V107.7   c1-6.8,6.3-11.7,13.1-11.7h357.7c6.9,0,12.5,5.1,12.9,12l0.3,260.4L348.9,261.7z" />
                              </g>
                            </svg>
                          </div>
                          <div className="cm-asset-name">Upload</div>
                        </div>
                      </div>
                    </button>
                  </NavItem>
                </Nav>
              </div>
              {videoActive === true ? (
                <>
                  <div className="srch-temp-blk">
                    <div className="d-flex align-items-center position-relative flex-wrap">
                      <div className="d-flex align-items-center pro-temp-container mr-4">
                        <div className="cm-header .cm-subhead">Private Repo</div>
                        <Checkbox
                          checked={checked}
                          onChange={e => viewPrivateRepo(e)}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </div>
                      <div className="srch-temp-wrapper">
                        <div className="srch-holder">
                          <div className="srch-icon" onClick={() => __searchByClick()}>
                            {' '}
                            <Tooltip title="Search" arrow>
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21.9243 21.925L27.9994 28.0001"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Tooltip>
                          </div>
                          <div className="srch-inpt-holder">
                            <input
                              id="searchBtn1"
                              onChange={e => __serachbox(e)}
                              type="text"
                              name="focus"
                              required
                              className="form-control srch-input"
                              placeholder="Search videos"
                              value={keyWord}
                            />

                            <button
                              className="search-close"
                              type="reset"
                              onClick={() => ResetVideoRepo()}
                            >
                              <Tooltip title="Reset" arrow>
                                <svg
                                  version="1.1"
                                  id="Layer_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  space="preserve"
                                >
                                  <g>
                                    <path
                                      d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M327.115,365.904
                                      L256,294.789l-71.115,71.115l-38.789-38.789L217.211,256l-71.115-71.115l38.789-38.789L256,217.211l71.115-71.115l38.789,38.789
                                      L294.789,256l71.115,71.115L327.115,365.904z"
                                    />
                                  </g>
                                </svg>
                              </Tooltip>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cm-st-gallery my-3">
                      {videoRepoLoader === false && props.type === 'video' ? (
                        videoRepo.length > 0 ? (
                          <div className="cm-gallery-wrapper">
                            {currentPosts.map((video, index) => {
                              let mediaurl = '';
                              // console.log(video,"video");

                              if (
                                video.thumbnailurl !== undefined &&
                                video.thumbnailurl !== null &&
                                video.thumbnailurl !== ''
                              ) {
                                mediaurl = video.thumbnailurl;
                              } else if (video.thumbnailurl === null) {
                                mediaurl = video.mediaurl;
                              } else {
                                mediaurl = video;
                              }
                              //console.log(mediaurl, "mediaurl");

                              return (
                                <div className="cm-gallery-container replace-img">
                                  <div className="img-info">
                                    {video.private === 1 && (
                                      <Tooltip title="Private Images" arrow>
                                        <span>
                                          <svg
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 330 330"
                                            space="preserve"
                                          >
                                            <g id="XMLID_486_">
                                              <path
                                                id="XMLID_487_"
                                                d="M165,330c63.411,0,115-51.589,115-115c0-29.771-11.373-56.936-30-77.379V85c0-46.869-38.131-85-85-85
                                        S80.001,38.131,80.001,85v52.619C61.373,158.064,50,185.229,50,215C50,278.411,101.589,330,165,330z M180,219.986V240
                                        c0,8.284-6.716,15-15,15s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
                                        C190,208.162,186.068,215.421,180,219.986z M110.001,85c0-30.327,24.673-55,54.999-55c30.327,0,55,24.673,55,55v29.029
                                        C203.652,105.088,184.91,100,165,100c-19.909,0-38.651,5.088-54.999,14.028V85z"
                                              />
                                            </g>
                                          </svg>
                                        </span>
                                      </Tooltip>
                                    )}
                                  </div>
                                  <div className="icon-wrap">
                                    {video.thumbnailurl !== undefined &&
                                    video.thumbnailurl !== null &&
                                    video.thumbnailurl !== '' ? (
                                      <span
                                        onClick={() => {
                                          viewVideoPreview(video.mediaurl);
                                        }}
                                        className="img-view text-white"
                                        data-bs-toggle="modal"
                                        data-bs-target="#view-img"
                                      >
                                        {' '}
                                        <Tooltip arrow>{eyeOpenIcon}</Tooltip>
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() => {
                                          viewVideoPreview(video.mediaurl);
                                        }}
                                        className="img-view text-white"
                                        data-bs-toggle="modal"
                                        data-bs-target="#view-img"
                                      >
                                        <Tooltip arrow>{eyeOpenIcon}</Tooltip>
                                      </span>
                                    )}

                                    <span
                                      data-bs-toggle="modal"
                                      data-bs-target="#edit-img"
                                      className="img-edit text-white"
                                      onClick={() => SelectAsset(video.mediaurl)}
                                    >
                                      <Tooltip title="Save" arrow>
                                        {checkIcon}
                                      </Tooltip>
                                    </span>
                                  </div>
                                  <ReactImageFallback
                                    src={mediaurl}
                                    fallbackImage="../../../assets/img/fallbackImage.jpg"
                                    initialImage="../../../assets/img/fallbackImage.jpg"
                                    alt="templates"
                                    className="img-fluid"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="v-gal-empty-blk">
                            <div className="no-txt-selected blink">
                              {capitalizeFirstLetter(videoErrorMsg)}
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="v-gal-empty-blk">
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                    {videoRepo.length > 0 && (
                      <Pagination
                        paginate={paginate}
                        postsPerPage={postsPerPage}
                        totalPosts={posts.length}
                      />
                    )}
                  </div>
                </>
              ) : videoBlockActive || pexelActive ? (
                <div className="srch-temp-blk">
                  <div className="d-flex align-items-center position-relative flex-wrap">
                    {videoBlockRepoLoader === false && (
                      <h3 className="stock-footer-total">Total Items:&nbsp;{totalUrlCount}</h3>
                    )}
                    <br />

                    <div className="srch-temp-wrapper">
                      <div className="srch-holder">
                        <div className="srch-icon" onClick={() => __searchByClick()}>
                          {' '}
                          <Tooltip title="Search" arrow>
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M21.9243 21.925L27.9994 28.0001"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Tooltip>
                        </div>
                        <div className="srch-inpt-holder">
                          <input
                            id="searchBtn1"
                            onChange={e => __serachbox(e)}
                            type="text"
                            name="focus"
                            required
                            className="form-control srch-input"
                            placeholder="Search videos"
                            value={keyWord}
                          />

                          <button
                            className="search-close"
                            type="reset"
                            onClick={() => Resetkeyword()}
                          >
                            <Tooltip title="Reset" arrow>
                              <svg
                                version="1.1"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 512 512"
                                space="preserve"
                              >
                                <g>
                                  <path
                                    d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M327.115,365.904
                                  L256,294.789l-71.115,71.115l-38.789-38.789L217.211,256l-71.115-71.115l38.789-38.789L256,217.211l71.115-71.115l38.789,38.789
                                  L294.789,256l71.115,71.115L327.115,365.904z"
                                  />
                                </g>
                              </svg>
                            </Tooltip>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="cm-st-gallery my-3 stock-repo">
                    {videoBlockUrl && (
                      <div className="loader-wrapper video-loader">
                        <div className="loader-inner">
                          <img src="../../../assets/img/loader.gif"></img>
                        </div>
                      </div>
                    )}
                    {videoBlockRepoLoader === false && (videoBlockActive || pexelActive) ? (
                      videoBlockRepo.length > 0 ? (
                        <div className="cm-gallery-wrapper">
                          {videoBlockRepo.map((video, index) => {
                            let mediaurl = '';

                            if (
                              video.thumbnail_url !== undefined &&
                              video.thumbnail_url !== null &&
                              video.thumbnail_url !== ''
                            ) {
                              mediaurl = video.thumbnail_url;
                            }

                            return (
                              <div className="cm-gallery-container replace-img">
                                <div className="icon-wrap">
                                  {video.thumbnail_url !== undefined &&
                                  video.thumbnail_url !== null &&
                                  video.thumbnail_url !== '' ? (
                                    <span
                                      onClick={() => {
                                        viewVideoPreview(video.preview_urls._720p);
                                      }}
                                      className="img-view text-white"
                                      data-bs-toggle="modal"
                                      data-bs-target="#view-img"
                                    >
                                      {' '}
                                      <Tooltip arrow>{eyeOpenIcon}</Tooltip>
                                    </span>
                                  ) : (
                                    <span
                                      onClick={() => {
                                        viewVideoPreview(video.preview_urls._720p);
                                      }}
                                      className="img-view text-white"
                                      data-bs-toggle="modal"
                                      data-bs-target="#view-img"
                                    >
                                      {' '}
                                      <Tooltip arrow>{eyeOpenIcon}</Tooltip>
                                    </span>
                                  )}

                                  <span
                                    className="img-edit text-white"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit-img"
                                    onClick={() => SelectVideoAsset(video)}
                                  >
                                    <Tooltip title="Save" arrow>
                                      {editIcon}
                                    </Tooltip>
                                  </span>
                                </div>
                                <ReactImageFallback
                                  src={mediaurl}
                                  fallbackImage="../../../assets/img/fallbackImage.jpg"
                                  initialImage="../../../assets/img/fallbackImage.jpg"
                                  alt="templates"
                                  className="img-fluid"
                                />
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="v-gal-empty-blk">
                          <div className="no-txt-selected blink">
                            {capitalizeFirstLetter(videoErrorMsg)}
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="v-gal-empty-blk">
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                  {videoRepo.length > 0 && videoActive && (
                    <Pagination
                      paginate={paginate}
                      postsPerPage={postsPerPage}
                      totalPosts={posts.length}
                    />
                  )}
                </div>
              ) : (
                <AssetUpload
                  type={props.type}
                  item={props.item}
                  closePreview={props.closePreview}
                  setdisplayImage={props.setdisplayImage}
                />
              )}
            </div>
          </div>
          {/* </div>
                      </div> */}
          {(videoBlockActive || pexelActive) && videoBlockRepoLoader === false && (
            <div className="d-flex justify-content-between mt-3 position-relative control-btns">
              {pageNo >= 2 && (
                <button
                  onClick={() => {
                    setPageNo(pageNo - 1);
                  }}
                  className="btn btn-primary rounded-0 me-3"
                >
                  Previous
                </button>
              )}
              {totalUrlCount > 20 && (
                <button
                  onClick={() => {
                    setPageNo(pageNo + 1);
                  }}
                  className="btn btn-primary rounded-0 next-btn"
                >
                  Next
                </button>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>

      <VideoPreview
        selectedVideo={selectedVideoFile}
        openVideoPreview={openVideoPreview}
        closePreview={() => setOpenVideoPreview(false)}
      />
    </>
  );
}
