export const permissions = [
  {
    title: 'Create Project',
    name: 'addProject',
    desc: 'Allow user to create new project',
    type: 'checkbox',
  },
  {
    title: 'Add User',
    name: 'addUser',
    desc: 'Allow the user to add new user',
    type: 'checkbox',
  },
];
