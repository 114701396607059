import React, { useState, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { crossIcon, deleteIcon } from '../../../../constants/icon';

function LoadingProgressbar(props) {
  const { showProgress, onClick, title } = props;
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [countdown, setCountdown] = useState(120);

  const getVariant = () => {
    if (isUploadComplete) {
      return 'success';
    } else if (uploadProgress >= 50) {
      return 'success';
    } else if (uploadProgress >= 5) {
      return 'primary';
    } else {
      return 'default';
    }
  };

  useEffect(() => {
    if (showProgress) {
      const interval = setInterval(() => {
        if (uploadProgress < 100) {
          setUploadProgress(uploadProgress + 1);
        } else {
          setIsUploadComplete(true);
          clearInterval(interval);
        }
      }, 100);

      return () => clearInterval(interval);
    }
  }, [showProgress, uploadProgress]);

  useEffect(() => {
    if (showProgress && countdown > 0) {
      const countdownInterval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 100);

      return () => clearInterval(countdownInterval);
    }
  }, [showProgress, countdown]);

  return (
    <>
      {showProgress && (
        <div className="loading-progressbar">
          <div className="progress-wrap">
            <div className="progress-info">
              <h4>{title}</h4>
              <div className="progress-value">
                {isUploadComplete
                  ? 'Success'
                  : countdown === 0
                  ? 'Delete'
                  : `${Math.floor(countdown / 60)} min ${countdown % 60} sec left ...`}
              </div>
            </div>
            <ProgressBar variant={getVariant()} now={uploadProgress} />
          </div>
          <button className="close-btn" role="button" onClick={onClick}>
            {isUploadComplete ? deleteIcon : countdown === 0 ? deleteIcon : crossIcon}
          </button>
        </div>
      )}
    </>
  );
}

export default LoadingProgressbar;
