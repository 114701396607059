import React, {
  useState,
  useRef,
  lazy,
  Suspense,
  useEffect,
  useCallback,
} from "react";
import { useHistory, useRouteMatch, Route } from "react-router-dom";
import Header from "../../components/header/header";
import { getProjects } from "../../../services/api.service";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrgusers,
  getChildusers,
  updateuserGlobalRole,
  getUsersProjectRole,
  updateuserProjectRole,
  getUsersGlobalRole,
  resetuserPassword,
} from "../../../services/authService";
import {
  showToaster,
  ShowAlertBox,
  getUserItem,
} from "../../../services/helper";
import { Checkbox } from "@mui/material";
import Switch from "@mui/material/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import UserCreateModal from "./createUser";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";
import PageLoader from "../../../services/pageLoader";
import { debounce } from "debounce";
export default function RoleAssign(props) {
  const [projectList, setprojectList] = useState([]);
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [includeDelete, setIncludeDelete] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [limit, setLimit] = useState(5);
  const [loadMoreFetching, setLoadMoreFetching] = useState(false);
  const [loadMoreEnable, setLoadMoreEnable] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [SearchBoxErrorMsg, setSearchBoxErrorMsg] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [listUsers, setListUsers] = useState(undefined);
  const history = useHistory();
  const [keyWord, setKeyWord] = useState("");
  const [users, setUsers] = useState([]);
  const [fonts, setfonts] = useStateWithCallbackLazy();
  const [newUser, setnewUser] = useState(undefined);
  const [checkedCreateUser, setCheckedCreateUser] = useState(false);
  const [checkedCreateProject, setCheckedCreateProject] = useState(false);
  const [checkedProjectEnable, setCheckedProjectEnable] = useState(false);
  const [checkedUserEnable, setCheckedUserEnable] = useState(false);
  const [checkedDeleteProject, setCheckedDeleteProject] = useState(false);
  const [randomPassword, setRandomPassword] = useState(undefined);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [editCategoryID, seteditCategoryID] = useState(undefined);
  let userId = getUserItem("id");
  let userName = getUserItem("name");
  let userEmail = getUserItem("email");
  let AdminRole = getUserItem("role");
  let userParentId = getUserItem("orgId");
  const [cacheUserList, setcacheUserList] = useState(undefined);
  const countuser = useRef(0);
  // useEffect(async () => {
  //   await  getAllProjects(1, 0, "");
  //   await getOrganisationUsers();

  //   console.log(AdminRole, "listUserslistUsers");

  // }, []);
  useEffect(() => {
    const init = async () => {
      try {
        let ddd = localStorage.getItem("_userdetails");
        console.log(ddd, "listUserslistUsers");
        let userDetails = JSON.parse(ddd);
        setListUsers(userDetails);
      } catch (e) {
        console.log(e);
      }
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        await getAllProjects(1, 0, "");
        await getOrganisationUsers("",-1,-1);
      } catch (e) {
        console.log(e);
      }
    };
    if (listUsers) {
      init();
    }
  }, [listUsers]);

  const backToDashboard = () => {
    history.push("/user-roll");
  };

  const updateProjectRoleDetails = async (projList) => {
    try {
      console.log("asdassdfssdfsfd", listUsers);
      if (listUsers && projList) {
        countuser.current = 1;
        // await  getAllProjects(1, 0, "");
        console.log(projList, "listUsers");
        let projectData2 = JSON.stringify(projList);
        let giveActionData = {
          editProject: false,
          viewProject: false,
          deleteProject: false,
        };
        let initialData = JSON.parse(projectData2).map((data) => {
          data.giveActionData = giveActionData;
          return data;
        });
        let userProjectRoleId = AdminRole === 0 ? listUsers.id : userId;
        // await getAllProjects(1, 0, "");
        const response = await getUsersProjectRole(userProjectRoleId);
        try {
          setPageLoad(true);
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            console.log(response.data.result, "response");
            // setCategoryList(ddd)
            let previousDate;
            setPageLoad(false);
            if (response.data.result != undefined) {
              if (AdminRole === 0) {
                let ddd = response.data.result;
                let arr = initialData;
                // ddd.map((actions) => {
                for (let index = 0; index < arr.length; index++) {
                  const element = arr[index];
                  for (let i = 0; i < ddd.length; i++) {
                    const actions = ddd[i];
                    if (element.ProjectID === actions.projectId) {
                      let actionData = {
                        editProject: actions.editProject,
                        viewProject: actions.viewProject,
                        deleteProject: actions.deleteProject,
                      };
                      // let actionDataDesables = {
                      //     editProject: actions.editProject,
                      //     viewProject: actions.viewProject,
                      //     deleteProject: actions.deleteProject
                      // }
                      // arr[index].giveActionDataDesable = actionDataDesables
                      console.log(actions, element);
                      arr[index].giveActionData = actionData;
                    }
                  }
                }
                console.log(arr, "arrarar");
                console.log(projectData2, "projectData2projectData2");
                setprojectList(arr);
                setcacheUserList(arr);
              } else {
                const response3 = await getUsersProjectRole(listUsers.id);
                try {
                  setPageLoad(true);
                  if (
                    response3 !== "" &&
                    response3 !== undefined &&
                    response3 !== null &&
                    response3.data !== "" &&
                    response3.data !== undefined &&
                    response3.data !== null
                  ) {
                    console.log(response3.data.result, "response3");
                    setPageLoad(false);
                    let ddd = response3.data.result;
                    let arr = initialData;
                    for (let index = 0; index < arr.length; index++) {
                      const element = arr[index];
                      for (let i = 0; i < ddd.length; i++) {
                        const actions = ddd[i];
                        if (element.ProjectID === actions.projectId) {
                          let actionData = {
                            editProject: actions.editProject,
                            viewProject: actions.viewProject,
                            deleteProject: actions.deleteProject,
                          };
                          // let actionDataDesables = {
                          //     editProject: actions.editProject,
                          //     viewProject: actions.viewProject,
                          //     deleteProject: actions.deleteProject
                          // }
                          // arr[index].giveActionDataDesable = actionDataDesables
                          console.log(actions, element);
                          arr[index].giveActionData = actionData;
                        }
                      }
                    }
                    console.log(previousDate, "datatatatat");
                  } else {
                    setPageLoad(false);
                    showToaster(
                      "Something went wrong. Please Try Again1",
                      "warning"
                    );
                  }
                } catch (error) {
                  console.log(error);
                  setPageLoad(false);
                  showToaster(
                    "Something went wrong. Please Try Again111",
                    "warning"
                  );
                }
                let ddd = response.data.result;
                let arr = initialData;
                // ddd.map((actions) => {
                for (let index = 0; index < arr.length; index++) {
                  const element = arr[index];
                  for (let i = 0; i < ddd.length; i++) {
                    const actions = ddd[i];
                    if (element.ProjectID === actions.projectId) {
                      let actionData = {
                        editProject: actions.editProject,
                        viewProject: actions.viewProject,
                        deleteProject: actions.deleteProject,
                      };
                      let actionDataDesables = {
                        editProject: actions.editProject,
                        viewProject: actions.viewProject,
                        deleteProject: actions.deleteProject,
                      };
                      arr[index].giveActionDataDesable = actionDataDesables;
                      console.log(actions, element);
                      // arr[index].giveActionData = actionData
                    }
                  }
                }
                console.log(arr, "getUsersProjectRole");
                console.log(projectData2, "projectData2projectData2");
                setprojectList(arr);
                setcacheUserList(arr);
              }
            } else {
              showToaster("Something went wrong. Please Try Again1", "warning");
            }
            console.log(previousDate, "datatatatat");
          } else {
            setPageLoad(false);
            showToaster("Something went wrong. Please Try Again1", "warning");
          }
        } catch (error) {
          console.log(error);
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again123", "warning");
        }

        let userGlobelId = AdminRole === 0 ? listUsers.id : userId;
        const response1 = await getUsersGlobalRole(userGlobelId);
        try {
          setPageLoad(true);
          if (
            response1 !== "" &&
            response1 !== undefined &&
            response1 !== null &&
            response1.data !== "" &&
            response1.data !== undefined &&
            response1.data !== null
          ) {
            console.log(response1.data.result[0].addProject, "response1");
            // setCategoryList(ddd)
            setPageLoad(false);

            // setTimeout(() => {
            //     setprojectList(projectData2)
            // }, 1000);
            if (AdminRole === 0) {
              setCheckedCreateProject(response1.data.result[0].addProject);
              setCheckedCreateUser(response1.data.result[0].addUser);
              setCheckedUserEnable(response1.data.result[0].addUser);
              setCheckedProjectEnable(response1.data.result[0].addProject);
            } else {
              const response2 = await getUsersGlobalRole(listUsers.id);
              setPageLoad(true);
              try {
                if (
                  response2 !== "" &&
                  response2 !== undefined &&
                  response2 !== null &&
                  response2.data !== "" &&
                  response2.data !== undefined &&
                  response2.data !== null
                ) {
                  // console.log(projectList, "response");
                  // setCategoryList(ddd)
                  setPageLoad(false);
                  setCheckedUserEnable(response1.data.result[0].addUser);
                  setCheckedProjectEnable(response1.data.result[0].addProject);
                  setCheckedCreateProject(response2.data.result[0].addProject);
                  setCheckedCreateUser(response2.data.result[0].addUser);
                  console.log(
                    response2.data.result[0].addProject,
                    "datatatatat"
                  );
                } else {
                  setPageLoad(false);
                  showToaster(
                    "Something went wrong. Please Try Again1",
                    "warning"
                  );
                }
              } catch (error) {
                console.log(error);
                setPageLoad(false);
                showToaster(
                  "Something went wrong. Please Try Again11111",
                  "warning"
                );
              }
            }

            // setCheckedUserEnable(response.data.result[0].addUser)
            // setCheckedProjectEnable(response.data.result[0].addProject)
            // console.log(response.data.result[0].addProject, "datatatatat");
          } else {
            setPageLoad(false);
            showToaster(
              "Something went wrong. Please Try Again1222222",
              "warning"
            );
          }
        } catch (error) {
          console.log(error);

          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again33333", "warning");
        }
      }

      console.log(AdminRole, "listUserslistUsers");
    } catch (e) {
      console.log(e);
    }
    // setprojectList(projList);
    return true;
  };

  const getOrganisationUsers = () => {
    return new Promise(async (resolve, reject) => {
      setPageLoad(true);
      let userRoleId = AdminRole === 0 ? userId : userParentId;
      let filter = {
        userId: userId,
        isActive: 0,
        keyword: "",
        sortCreateAt:-1,
        sortUpdatedAt:-1
      };
      let filter1 = {
        userId: userId,
        isActive: 0,
        orgId: userParentId,
        keyword: "",
        sortCreateAt:-1,
        sortUpdatedAt:-1
      };
      if (AdminRole === 0) {
        await getOrgusers(filter).then((response) => {
          try {
            console.log(response, "response");
            if (
              response !== "" &&
              response !== undefined &&
              response !== null &&
              response.data !== "" &&
              response.data !== undefined &&
              response.data !== null
            ) {
              // let ddd = response.data.list
              // setCategoryList(ddd)

              setPageLoad(false);
              if (response.data.result.data != undefined) {
                // let projectData = {
                //     category: "No users",
                //     id: 0,
                //     email: ""
                // };
                let projectList1 = response.data.result.data;
                let listActiveUsers = [];
                projectList1.map((users) => {
                  console.log(users, "users");
                  if (users.isActive == 1) {
                    listActiveUsers.push(users);
                  }
                });
                setUsers(listActiveUsers);
                // setListUsers({
                //   value: "Select Users",
                //   label: "Select Users",
                //   id: 0,
                //   email: "",
                // });
                console.log(projectList1, "response");
              }
              // setListCatagory(
              //   ddd
              // );
              //  let data1=data.push(response.data.list[(Object.keys(response.data.list)])
            } else {
              setPageLoad(false);
              showToaster("Something went wrong. Please Try Again1", "warning");
            }
          } catch (error) {
            setPageLoad(false);
            showToaster("Something went wrong. Please Try Again", "warning");
          }
        });
        resolve(true);
      } else {
        await getChildusers(filter1).then((response) => {
          try {
            if (
              response !== "" &&
              response !== undefined &&
              response !== null &&
              response.data !== "" &&
              response.data !== undefined &&
              response.data !== null
            ) {
              console.log(response, "response");
              // let ddd = response.data.list
              // setCategoryList(ddd)
              setPageLoad(false);
              if (response.data.result.data != undefined) {
                let projectData = {
                  category: "No users",
                  id: 0,
                  email: "",
                };
                let projectList1 = response.data.result.data;
                let listActiveUsers = [];
                projectList1.map((users) => {
                  console.log(users, "users");
                  if (users.isActive == 1) {
                    listActiveUsers.push(users);
                  }
                });
                setUsers(listActiveUsers);
                // setListUsers({
                //   value: "Select Users",
                //   label: "Select Users",
                //   id: 0,
                //   email: "",
                // });
                console.log(projectList1, "response");
              }
              // setListCatagory(
              //   ddd
              // );
              //  let data1=data.push(response.data.list[(Object.keys(response.data.list)])
            } else {
              setPageLoad(false);
              showToaster("Something went wrong. Please Try Again1", "warning");
            }
          } catch (error) {
            setPageLoad(false);
            showToaster("Something went wrong. Please Try Again", "warning");
          }
        });
        resolve(true);
      }
    });
  };
  useEffect(() => {
    if (newUser !== undefined && newUser !== "" && newUser !== null) {
      getOrganisationUsers("",-1,-1);
      setnewUser(undefined);
    }
  }, [newUser]);

  const handleChange = async (e) => {
    // alert()
    setListUsers(e);
    let projectData2 = JSON.stringify(projectList);
    let giveActionData = {
      editProject: false,
      viewProject: false,
      deleteProject: false,
    };
    let initialData = JSON.parse(projectData2).map((data) => {
      data.giveActionData = giveActionData;
      return data;
    });
    let userProjectRoleId = AdminRole === 0 ? e.id : userId;
    await getUsersProjectRole(userProjectRoleId).then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          console.log(response.data.result, "response");
          // setCategoryList(ddd)
          let previousDate;
          setPageLoad(false);
          if (response.data.result != undefined) {
            if (AdminRole === 0) {
              let ddd = response.data.result;
              let arr = initialData;
              // ddd.map((actions) => {
              for (let index = 0; index < arr.length; index++) {
                const element = arr[index];
                for (let i = 0; i < ddd.length; i++) {
                  const actions = ddd[i];
                  if (element.ProjectID === actions.projectId) {
                    let actionData = {
                      editProject: actions.editProject,
                      viewProject: actions.viewProject,
                      deleteProject: actions.deleteProject,
                    };
                    // let actionDataDesables = {
                    //     editProject: actions.editProject,
                    //     viewProject: actions.viewProject,
                    //     deleteProject: actions.deleteProject
                    // }
                    // arr[index].giveActionDataDesable = actionDataDesables
                    console.log(actions, element);
                    arr[index].giveActionData = actionData;
                  }
                }
              }
              console.log(arr, "arrarar");
              console.log(projectData2, "projectData2projectData2");
              setprojectList(arr);
              setcacheUserList(arr);
            } else {
              getUsersProjectRole(e.id).then((response) => {
                try {
                  if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null &&
                    response.data !== "" &&
                    response.data !== undefined &&
                    response.data !== null
                  ) {
                    console.log(response.data.result, "response");
                    setPageLoad(false);
                    let ddd = response.data.result;
                    let arr = initialData;
                    for (let index = 0; index < arr.length; index++) {
                      const element = arr[index];
                      for (let i = 0; i < ddd.length; i++) {
                        const actions = ddd[i];
                        if (element.ProjectID === actions.projectId) {
                          let actionData = {
                            editProject: actions.editProject,
                            viewProject: actions.viewProject,
                            deleteProject: actions.deleteProject,
                          };
                          // let actionDataDesables = {
                          //     editProject: actions.editProject,
                          //     viewProject: actions.viewProject,
                          //     deleteProject: actions.deleteProject
                          // }
                          // arr[index].giveActionDataDesable = actionDataDesables
                          console.log(actions, element);
                          arr[index].giveActionData = actionData;
                        }
                      }
                    }
                    console.log(previousDate, "datatatatat");
                  } else {
                    setPageLoad(false);
                    showToaster(
                      "Something went wrong. Please Try Again1",
                      "warning"
                    );
                  }
                } catch (error) {
                  console.log(error);
                  setPageLoad(false);
                  showToaster(
                    "Something went wrong. Please Try Again",
                    "warning"
                  );
                }
              });
              let ddd = response.data.result;
              let arr = initialData;
              // ddd.map((actions) => {
              for (let index = 0; index < arr.length; index++) {
                const element = arr[index];
                for (let i = 0; i < ddd.length; i++) {
                  const actions = ddd[i];
                  if (element.ProjectID === actions.projectId) {
                    let actionData = {
                      editProject: actions.editProject,
                      viewProject: actions.viewProject,
                      deleteProject: actions.deleteProject,
                    };
                    let actionDataDesables = {
                      editProject: actions.editProject,
                      viewProject: actions.viewProject,
                      deleteProject: actions.deleteProject,
                    };
                    arr[index].giveActionDataDesable = actionDataDesables;
                    console.log(actions, element);
                    // arr[index].giveActionData = actionData
                  }
                }
              }
              console.log(arr, "arrarar");
              console.log(projectData2, "projectData2projectData2");
              // setprojectList(arr);
              setcacheUserList(arr);
            }
          } else {
            showToaster("Something went wrong. Please Try Again1", "warning");
          }
          console.log(previousDate, "datatatatat");
        } else {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again1", "warning");
        }
      } catch (error) {
        console.log(error);
        setPageLoad(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
    let userGlobelId = AdminRole === 0 ? e.id : userId;
    getUsersGlobalRole(userGlobelId).then((response) => {
      try {
        setPageLoad(true);
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          // console.log(projectList, "response");
          // setCategoryList(ddd)
          setPageLoad(false);

          // setTimeout(() => {
          //     setprojectList(projectData2)
          // }, 1000);
          if (AdminRole === 0) {
            setCheckedCreateProject(response.data.result[0].addProject);
            setCheckedCreateUser(response.data.result[0].addUser);
            setCheckedUserEnable(response.data.result[0].addUser);
            setCheckedProjectEnable(response.data.result[0].addProject);
          } else {
            getUsersGlobalRole(e.id).then((response1) => {
              try {
                setPageLoad(true);
                if (
                  response1 !== "" &&
                  response1 !== undefined &&
                  response1 !== null &&
                  response1.data !== "" &&
                  response1.data !== undefined &&
                  response1.data !== null
                ) {
                  // console.log(projectList, "response");
                  // setCategoryList(ddd)
                  setPageLoad(false);
                  setCheckedUserEnable(response.data.result[0].addUser);
                  setCheckedProjectEnable(response.data.result[0].addProject);
                  setCheckedCreateProject(response1.data.result[0].addProject);
                  setCheckedCreateUser(response1.data.result[0].addUser);
                  console.log(
                    response.data.result[0].addProject,
                    "datatatatat"
                  );
                } else {
                  setPageLoad(false);
                  showToaster(
                    "Something went wrong. Please Try Again1",
                    "warning"
                  );
                }
              } catch (error) {
                console.log(error);

                setPageLoad(false);
                showToaster(
                  "Something went wrong. Please Try Again",
                  "warning"
                );
              }
            });
          }

          // setCheckedUserEnable(response.data.result[0].addUser)
          // setCheckedProjectEnable(response.data.result[0].addProject)
          // console.log(response.data.result[0].addProject, "datatatatat");
        } else {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again1", "warning");
        }
      } catch (error) {
        console.log(error);

        setPageLoad(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
    console.log(e, "listtt");
  };
  const cancelRoleTable = (data) => {
    console.log(data);
    setprojectList(cacheUserList);
    setCheckedCreateProject(checkedProjectEnable);
    setCheckedCreateUser(checkedUserEnable);
    setListUsers(data);
  };
  // useEffect(() => {
  //     if (listUsers.id !== 0) {
  //         console.log("called,", listUsers);
  //         setPageLoad(true)
  //     }
  // }, [listUsers])
  useEffect(() => {
    generateUsers();
  }, [users]);

  const generateUsers = () => {
    if (users != undefined) {
      let fontOption = [];
      users.map((font) => {
        fontOption.push({
          value: font.name!==undefined?font.name: font.firstName+" "+ font.lastName,
          label: font.name!==undefined?font.name: font.firstName+" "+ font.lastName,
          id: font._id,
          email: font.email,
          timezone: font.timezone,
        });
        return true;
      });
      console.log(fontOption);
      setfonts(fontOption);
    }
  };
  const getAllProjects = (flag, PageNo, KeyWord) => {
    let filter;
    setPageLoad(true);
    try {
      filter = {
        page_no: PageNo, //page_no is working as start in fetching data
        limit: limit,
        keyword: KeyWord.trim(), //keyword is used for search functionality
        inc_delete: 0,
      };
      filter.projectRole = AdminRole;
      getProjects(filter).then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            let projectData = response.data;
            setLoadMoreEnable(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
            if (projectData.length > 0) {
              if (projectData.length < limit) {
                setLoadMoreEnable(false);
              } else {
                setLoadMoreEnable(true);
              }
              let giveActionData = {
                editProject: false,
                viewProject: false,
                deleteProject: false,
              };
              let giveActionDataDesable = {
                editProject: false,
                viewProject: false,
                deleteProject: false,
              };
              let updatedData;
              projectData.map((data) => {
                data.giveActionData = giveActionData;
                data.giveActionDataDesable = giveActionDataDesable;
              });
              // if (PageNo === 0) {
              //   let projectList1 = projectData;
              //   console.log(projectData);
              //   let sortedList = projectList1.sort((a, b) =>
              //     a.Deleted > b.Deleted ? 1 : b.Deleted > a.Deleted ? -1 : 0
              //   );
              //   setprojectList(sortedList);
              // } else {
              let projectList1 =
                PageNo === 0
                  ? [...projectData]
                  : [...projectList, ...projectData];
              let sortedList = projectList1.sort((a, b) =>
                a.Deleted > b.Deleted ? 1 : b.Deleted > a.Deleted ? -1 : 0
              );
              updateProjectRoleDetails(sortedList).then(() => {
                setFetching(false);
              });
              // setprojectList(sortedList);
              // }
              // setFetching(false);
              //setLoadMoreEnable(true);
            } else {
              // if (pageNo === 0) {
              //   setprojectList([]);
              //   setFetching(false);
              //   setLoadMoreEnable(false);
              // }
            }
            setPageLoad(false);
          } else if (
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ""
          ) {
            showToaster(response.message, "warning");
            setprojectList([]);
            setFetching(false);
            setLoadMoreEnable(false);
            setPageLoad(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
          } else {
            showToaster("No response from the server", "warning");
            setprojectList([]);
            setFetching(false);
            setLoadMoreEnable(false);
            setPageLoad(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
          }
        } catch (error) {
          showToaster("Something went wrong. Please Try Again", "warning");
          setprojectList([]);
          setFetching(false);
          setLoadMoreEnable(false);
          setLoadMoreFetching(false);
          setPageLoad(false);
        }
      });
    } catch (error) {
      showToaster("Something went wrong. Please Try Again", "warning");
      setprojectList([]);
      setFetching(false);
      setLoadMoreEnable(false);
      setLoadMoreFetching(false);
      setPageLoad(false);
    }
  };
  const moveToNextPage = () => {
    setFetching(true);
    setLoadMoreFetching(true);
    getAllProjects(1, pageNo + 1, keyWord);
    setPageNo(pageNo + 1);
  };
  const viewCreateUser = (image) => {
    setOpenCreateUser(true);
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "gray",
      padding: 7,
    }),
    control: () => ({
      display: "flex",
      backgroundColor: DisplayMode ? "#2e2e2e" : "#f0efef",
      color: "#fff",
      height: "32px",
    }),
    singleValue: () => ({
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "12px",
      lineHeight: "18px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      color: "#fff",
    }),
    indicatorSeparator: () => ({
      border: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
  };

  const __serachbox = (val) => {
    try {
      //console.log(val.target.value);
      setSearchBoxErrorMsg(false);
      if (keyWord !== val.target.value.trim()) {
        setKeyWord(val.target.value);
        // getInitialProjectList(val.target.value.trim());;
        getSearchBoxResult(val.target.value);
      } else {
        if (keyWord.trim() !== "") {
          setKeyWord(val.target.value);
        } else {
          setKeyWord("");
        }
      }
    } catch (error) {
      showToaster(
        "Something went wrong. Please Refresh page and Try Again",
        "warning"
      );
    }
  };

  const getSearchBoxResult = useCallback(
    debounce((value) => {
      try {
        getInitialProjectList(value.trim());
      } catch (error) {
        showToaster(
          "Something went wrong. Please Refresh page and Try Again",
          "warning"
        );
      }
    }, 1000),
    [keyWord]
  );

  const __searchByClick = () => {
    setSearchBoxErrorMsg(false);
    if (keyWord.trim() !== "") {
      getInitialProjectList(keyWord.trim());
    } else {
      setSearchBoxErrorMsg(true, () => {
        setTimeout(() => {
          setSearchBoxErrorMsg(false);
        }, 10000);
      });
    }
  };

  const getInitialProjectList = (keyword) => {
    setprojectList([]);
    setFetching(true);
    setPageNo(0);
    setLimit(7);
    setSearchBoxErrorMsg(false);
    getAllProjects(0, 0, keyword);
    // this.props.updateJson({})
  };
  const setCreateProjectEnable = (event) => {
    setCheckedCreateProject(event.target.checked);
  };
  const setCreateUserEnabled = (event) => {
    console.log("setCreateUserEnabled");
    setCheckedCreateUser(event.target.checked);
  };
  // const setDeleteProjectEnabled = (event) => {
  //     setCheckedDeleteProject(event.target.checked);
  // }
  // const setEditProjectEnabled = (event, value) => {
  //     seteditCategoryID(value)
  //     setCheckedEditProject(event.target.checked);
  // }
  const changePermission = (projectId, actiontype, event) => {
    let previousData;
    let projectData = JSON.stringify(projectList);
    previousData = JSON.parse(projectData).map((data) => {
      if (data.ProjectID === projectId) {
        data.giveActionData[actiontype] = event.target.checked;
      }
      return data;
    });
    setprojectList(previousData);
  };
  // const setViewProjectEnabled = (event) => {
  //     setCheckedViewProject(event.target.checked);
  // }
  const AddRoleinOrgUsers = async () => {
    console.log(listUsers.id, "listUsers");
    try {
      let filter = {
        addUser: checkedCreateUser,
        addProject: checkedCreateProject,
      };
      setPageLoad(true);
      await updateuserGlobalRole(listUsers.id, filter).then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            setPageLoad(false);
            if (response.data.result != undefined) {
            }
          } else {
            setPageLoad(false);
            showToaster("Something went wrong. Please Try Again1", "warning");
          }
        } catch (error) {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      });
      let roleArray = [];
      let projectRole = projectList.map((role) => {
        role.giveActionData.projectId = role.ProjectID;
        // if (role.giveActionData.editProject || role.giveActionData.viewProject || role.giveActionData.deleteProject) {
        roleArray.push(role.giveActionData);
        // }

        return role;
      });
      console.log(roleArray, "role");
      await updateuserProjectRole(listUsers.id, userId, roleArray).then(
        (response) => {
          try {
            if (
              response !== "" &&
              response !== undefined &&
              response !== null &&
              response.data !== "" &&
              response.data !== undefined &&
              response.data !== null
            ) {
              setPageLoad(false);
              showToaster("Role updated successfully", "success");
              if (response.data.result != undefined) {
              }
            } else {
              setPageLoad(false);
              showToaster("Something went wrong. Please Try Again1", "warning");
            }
          } catch (error) {
            setPageLoad(false);
            showToaster("Something went wrong. Please Try Again", "warning");
          }
        }
      );
    } catch (error) {
      setPageLoad(false);
      showToaster("Something went wrong. Please Try Again", "warning");
    }
  };
  const Allowed = {
    Uppers: "QWERTYUIOPASDFGHJKLZXCVBNM",
    Lowers: "qwertyuiopasdfghjklzxcvbnm",
    Numbers: "1234567890",
    Symbols: "@$!%*#?&",
  };

  const getRandomCharFromString = (str) =>
    str.charAt(Math.floor(Math.random() * str.length));

  const adminResetPassword = (email) => {
    let pwd = "";
    let length = 8;
    pwd += getRandomCharFromString(Allowed.Uppers); //pwd will have at least one upper
    pwd += getRandomCharFromString(Allowed.Lowers); //pwd will have at least one lower
    pwd += getRandomCharFromString(Allowed.Numbers); //pwd will have at least one number
    pwd += getRandomCharFromString(Allowed.Symbols); //pwd will have at least one symbolo

    for (let i = pwd.length; i < length; i++)
      pwd += getRandomCharFromString(Object.values(Allowed).join("")); //fill the rest of the pwd with random characters
    setRandomPassword(pwd);
    console.log(pwd, "generatePassword");
    console.log(randomPassword, "pwd");
    let filter = {
      email: email,
      userId: listUsers.id,
      password: pwd,
    };
    setPageLoad(true);
    resetuserPassword(filter).then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          console.log(response, "response");
          setPageLoad(false);
          if (response.data.result != undefined) {
            showToaster("Password is Reset Successfully", "success");
            // let projectList1 = response.data.result.data;
            // setUsers(projectList1);
            // setPosts(projectList1);
            // setListUsers({
            //   value: "Select Users",
            //   label: "Select Users",
            //   id: 0,
            //   email: "",
            // });
            // console.log(projectList1, "response");
          }
        } else {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again1", "warning");
        }
      } catch (error) {
        setPageLoad(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };
  return (
    <>
      <PageLoader showLoader={pageLoad} />
      <div className={DisplayMode ? "theme dark" : "theme"}>
        <div className="main-wrapper">
          <Header />

          <div className="pb-wrapper user-permission">
            <div className="row pb-header mb-4">
              <div className="col-md-6 col-lg-6">
                <div className="d-flex ">
                  <button
                    type="submit"
                    onClick={() => {
                      backToDashboard();
                      // setIncludeDelete(0)
                      // setPageNo(0);
                      // setKeyWord("");
                      // getAllProjects(0, 0, "", listCategory, listStatus);
                      // getDashboardDetails();
                    }}
                    className="btn btn-outline-primary text-nowrap back-button me-3 mb-2 mb-lg-0"
                    value="submit"
                  >
                    <svg
                      width="14"
                      height="11"
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.42652e-06 5.5C0.00211858 5.41315 0.0186696 5.32723 0.0490002 5.24563C0.0651946 5.21658 0.0839324 5.18898 0.105001 5.16313C0.123314 5.12504 0.144368 5.08828 0.168001 5.05313L4.368 0.240672C4.53056 0.0540017 4.78216 -0.0333139 5.02804 0.0116137C5.27392 0.0565413 5.47672 0.226887 5.56004 0.458484C5.64336 0.690081 5.59456 0.947744 5.432 1.13441L2.219 4.81251L13.3 4.81251C13.6866 4.81251 14 5.12031 14 5.5C14 5.87969 13.6866 6.18749 13.3 6.18749L2.219 6.18749L5.432 9.86559C5.59456 10.0523 5.64337 10.3099 5.56004 10.5415C5.47672 10.7731 5.27392 10.9435 5.02804 10.9884C4.78216 11.0333 4.53056 10.946 4.368 10.7593L0.168001 5.94687C0.144368 5.91172 0.123314 5.87496 0.105001 5.83687C0.0839324 5.81102 0.0651946 5.78342 0.0490003 5.75437C0.0186696 5.67277 0.00211859 5.58685 1.42652e-06 5.5Z"
                        fill=""
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="d-flex">
                  <div className="d-flex align-items-center pb-filter-holder flex-fill me-3">
                    <div className="pb-filter-label pe-3">Users List</div>
                    {/* <div className="custom-select"> */}
                    <Select
                      className="custom-input rolluser w-100 "
                      styles={customStyles}
                      value={listUsers}
                      onChange={(e) => handleChange(e)}
                      options={fonts}
                    />
                    {/* </div> */}
                  </div>
                  <button
                    type="submit"
                    onClick={() => {
                      viewCreateUser();
                    }}
                    className="btn btn-outline-primary text-nowrap add-user-btn"
                    value="submit"
                    data-bs-toggle="modal"
                    data-bs-target="#add-user"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.125 10H16.875"
                        stroke="#C0C2C4"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 3.125V16.875"
                        stroke="#C0C2C4"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Add User
                  </button>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center mb-3">
              {AdminRole == 0 && listUsers !== undefined ? (
                <div className="d-flex projectSwitch align-items-center me-3">
                  <span>Create Project</span>
                  {/* <div className="switch-custom d-flex"> */}
                  <Switch
                    // disabled={listUsers.id == 0 ? true : false}
                    checked={checkedCreateProject}
                    onChange={(e) => setCreateProjectEnable(e)}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  {/* <input type="checkbox" id="switch" /><label for="switch">Toggle</label> */}
                  {/* </div> */}
                </div>
              ) : (
                <div className="d-flex projectSwitch align-items-center me-3">
                  <span>Create Project</span>
                  {/* <div className="switch-custom d-flex"> */}
                  <Switch
                    disabled={checkedProjectEnable ? false : true}
                    checked={checkedCreateProject}
                    onChange={(e) => setCreateProjectEnable(e)}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  {/* <input type="checkbox" id="switch" /><label for="switch">Toggle</label> */}
                  {/* </div> */}
                </div>
              )}
              {AdminRole == 0 && listUsers !== undefined ? (
                <div className="d-flex projectSwitch align-items-center ">
                  <span>Add User</span>
                  {/* <div className="switch-custom d-flex"> */}
                  <Switch
                    // disabled={listUsers.id == 0 ? true : false}
                    checked={checkedCreateUser}
                    onChange={(e) => setCreateUserEnabled(e)}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  {/* <input type="checkbox" id="switch-1" /><label for="switch-1">Toggle</label> */}
                  {/* </div> */}
                </div>
              ) : (
                <div className="d-flex projectSwitch align-items-center ">
                  <span>Add User</span>
                  {/* <div className="switch-custom d-flex"> */}
                  <Switch
                    disabled={checkedUserEnable ? false : true}
                    checked={checkedCreateUser}
                    onChange={(e) => setCreateUserEnabled(e)}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  {/* <input type="checkbox" id="switch-1" /><label for="switch-1">Toggle</label> */}
                  {/* </div> */}
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-md-8">
                {/* {listUsers !== undefined && ( */}
                <div className="d-flex justify-content-end mb-2">
                  <button
                    type="button"
                    onClick={() => {
                      AddRoleinOrgUsers();
                    }}
                    className={
                      listUsers !== undefined
                        ? "btn btn-primary  me-2 rounded-0"
                        : "btn btn-primary  me-2 rounded-0 disabled"
                    }
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      cancelRoleTable(listUsers);
                      // getOrganisationUsers()
                      // setListUsers("")
                      // setListUsers({
                      //     value: "Select Users",
                      //     label: "Select Users",
                      //     id: 0,
                      //     email: ""
                      // });
                    }}
                    className={
                      listUsers !== undefined
                        ? "btn btn-outline rounded-0"
                        : "btn btn-outline rounded-0 disabled"
                    }
                  >
                    Cancel
                  </button>
                </div>
                {/* )} */}
                <div className="table-wraper ">
                  <div className="pb-tbl-blk">
                    <div className="pb-tbl-wrapper mb-3 pb-tbl-head mt-0">
                      <div className="pb-head-label">Project Name</div>
                      <div className="pb-head-label text-center"> Edit</div>
                      <div className="pb-head-label text-center">View</div>
                      <div className="pb-head-label text-center">Delete</div>
                    </div>

                    <>
                      {projectList.map((project, index) => {
                        let projectName = project.ProjectName;
                        let Created = project.CreatedOn;
                        let sortedCreatedDate = Created.split(" ")[0];
                        let Modified = project.UpdatedOn;
                        let sorteModifieddDate = Modified.split(" ")[0];
                        let projectProgress = project.RenderStatus;
                        // let sortedCars1 = project.sort((a, b) =>
                        //   a.sortedCreatedDate.split('-').reverse().join().localeCompare(b.sortedCreatedDate.split('-').reverse().join()));
                        let ddd = JSON.parse(project.ProjectData);
                        let Modules = ddd[project.ProjectID].Modules;
                        if (
                          projectName !== undefined &&
                          projectName !== null &&
                          projectName.trim() !== ""
                        ) {
                        } else {
                          projectName = "No project name provided";
                        }
                        return (
                          <div className="pb-tbl-wrapper pb-data-tbl">
                            <div className="pb-data-label  ">{projectName}</div>
                            <div className="pb-data-label text-center">
                              {/* <div className="custom-check-2">
                          <input type="checkbox" id="html-11" />
                          <label for="html-11"></label>
                        </div> */}
                              {AdminRole === 1 ? (
                                <div className="rollcheck">
                                  <Checkbox
                                    //  style={{cursor:' not-allowed'}}
                                    disabled={
                                      AdminRole === 1 &&
                                      project.giveActionDataDesable.editProject
                                        ? false
                                        : true
                                    }
                                    checked={project.giveActionData.editProject}
                                    onChange={(e) =>
                                      changePermission(
                                        project.ProjectID,
                                        "editProject",
                                        e
                                      )
                                    }
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                  {project.giveActionData.editProject}
                                </div>
                              ) : (
                                // listUsers !== undefined && (
                                <div className="rollcheck ">
                                  <Checkbox
                                    //  style={{cursor: "not-allowed"}}
                                    disabled={
                                      listUsers === undefined ? true : false
                                    }
                                    iconStyle={{ color: "red" }}
                                    checked={project.giveActionData.editProject}
                                    onChange={(e) =>
                                      changePermission(
                                        project.ProjectID,
                                        "editProject",
                                        e
                                      )
                                    }
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                  {project.giveActionData.editProject}
                                </div>
                                // )
                              )}
                            </div>
                            {/* <div className="">
                          <input type="checkbox" id="html-12" />
                          <label for="html-12"></label>
                        </div> */}
                            <div className="pb-data-label text-center">
                              {AdminRole === 1 ? (
                                <div className="rollcheck">
                                  <Checkbox
                                    disabled={
                                      AdminRole === 1 &&
                                      project.giveActionDataDesable.viewProject
                                        ? false
                                        : true
                                    }
                                    checked={project.giveActionData.viewProject}
                                    onChange={(e) =>
                                      changePermission(
                                        project.ProjectID,
                                        "viewProject",
                                        e
                                      )
                                    }
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                  {project.giveActionData.viewProject}
                                </div>
                              ) : (
                                // listUsers !== undefined && (
                                <div className="rollcheck">
                                  <Checkbox
                                    disabled={
                                      listUsers === undefined ? true : false
                                    }
                                    checked={project.giveActionData.viewProject}
                                    onChange={(e) =>
                                      changePermission(
                                        project.ProjectID,
                                        "viewProject",
                                        e
                                      )
                                    }
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                  {project.giveActionData.viewProject}
                                </div>
                                // )
                              )}
                            </div>{" "}
                            <div className="pb-data-label text-center">
                              {AdminRole === 1 ? (
                                <div className="rollcheck">
                                  <Checkbox
                                    disabled={
                                      AdminRole === 1 &&
                                      project.giveActionDataDesable
                                        .deleteProject
                                        ? false
                                        : true
                                    }
                                    checked={
                                      project.giveActionData.deleteProject
                                    }
                                    onChange={(e) =>
                                      changePermission(
                                        project.ProjectID,
                                        "deleteProject",
                                        e
                                      )
                                    }
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                  {project.giveActionData.deleteProject}
                                </div>
                              ) : (
                                // listUsers !== undefined && (
                                <div className="rollcheck">
                                  <Checkbox
                                    disabled={
                                      listUsers === undefined ? true : false
                                    }
                                    checked={
                                      project.giveActionData.deleteProject
                                    }
                                    onChange={(e) =>
                                      changePermission(
                                        project.ProjectID,
                                        "deleteProject",
                                        e
                                      )
                                    }
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                  {project.giveActionData.deleteProject}
                                </div>
                                // )
                              )}
                            </div>
                            {/* <div className="custom-check-2">
                          <input type="checkbox" id="html-13" />
                          <label for="html-13"></label>
                        </div> */}
                          </div>
                        );
                      })}
                    </>
                    {projectList.length > 0
                      ? ""
                      : fetching === false && (
                          <React.Fragment>
                            <div className="no-project-selected blink">
                              No project available
                            </div>
                          </React.Fragment>
                        )}
                  </div>
                  {projectList.length > 0 && (
                    <div className="d-flex justify-content-between w-100 footer-btn flex-wrap mt-4">
                      {loadMoreEnable && (
                        <div className="d-flex justify-content-end w-100">
                          <button
                            type="button"
                            style={{ display: "inline-block" }}
                            className="btn btn-primary cm-btn"
                            onClick={() => moveToNextPage()}
                          >
                            {fetching ? "Loading..." : "Load More"}{" "}
                            {fetching && (
                              <CircularProgress size="1rem" color="inherit" />
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {listUsers !== undefined && (
                <div className="col-md-4 user-permissin-details d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <div className="user-info">
                      <div className="user-img-wrap">
                        <div className="user-img-inner">
                          <div className="user-img">
                            <img src="assets/img/user-01.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3>
                      <b> {listUsers.value}</b>
                    </h3>
                    <p>
                      <i>{listUsers.timezone}</i>
                    </p>
                    <p>{listUsers.email}</p>
                    <button
                      onClick={() => adminResetPassword(listUsers.email)}
                      type="submit"
                      className="btn btn-outline mt-3 m-auto"
                      value="submit"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <UserCreateModal
        closePreview={() => setOpenCreateUser(false)}
        createUserModal={openCreateUser}
        setnewUsers={setnewUser}
        // selectedImage={selectedImageFile}
      />
    </>
  );
}
