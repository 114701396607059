import React, { useState } from 'react';
import { plusIcon } from '../../../constants/icon';

import RenderStatusReport from '../../projects/renderDetail/components/statusReport';
import AddTemplateModal from './addTemplateModal';
import CustomSwitchSlider from '../../../components/form/customSwitchSlider';

export default function StatusReportHeader() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <div className="status-report-header">
        <div className="render-status-wrap">
          <RenderStatusReport duration="10" title="Total Templates" statusCircle />
          <RenderStatusReport
            className="render-warning-status"
            duration="02"
            title="Active Template"
            statusCircle
          />
          <RenderStatusReport
            className="render-danger-status"
            duration="03"
            title="Error Render"
            statusCircle
          />
          <RenderStatusReport
            className="render-success-status"
            duration="05"
            title="Success Render "
            statusCircle
          />
        </div>
        <div className="button-wrap">
          <div className="switch-card switch-card-sm">
            <div className="switch-card-info">
              <h4>View all templates</h4>
            </div>
            <CustomSwitchSlider />
          </div>
          <button role="button" className="outline-primary-btn" onClick={() => setModalShow(true)}>
            {plusIcon}
            Add template
          </button>
        </div>
      </div>
      <AddTemplateModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
