import React from "react";
import { Breadcrumb } from "react-bootstrap";

function CustomBreadcrumb(props) {
  const { items } = props;
  return (
    <Breadcrumb className={`custom-breadcrumb ${props.className || ""}`}>
      {items.map((item, index) => (
        <Breadcrumb.Item key={index} href={item.link} active={item.active}>
          {item.text}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default CustomBreadcrumb;
