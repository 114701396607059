import { Modal } from 'react-bootstrap';

// Icons
import { crossIcon } from '../../../../constants/icon';
import Videoplayer from '../../../components/Videoplayer/Videoplayer';

function PreviewModal(props) {
  const { selectedImage, fromVideo, videoUrl, ...rest } = props;
  return (
    <Modal
      {...rest}
      className="media-preview-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div
          className="modal-close-btn"
          title="Close"
          data-bs-dismiss="modal"
          onClick={props.onHide}
        >
          {crossIcon}
        </div>
        <div className="preview-img-wrap">
          {selectedImage && !fromVideo && <img src={selectedImage} alt="preview images" />}
          {selectedImage && fromVideo && videoUrl ? (
            <Videoplayer thumbnailUrl={selectedImage} videoUrl={videoUrl} showVolume={true} />
          ) : (
            <h1>Loading...</h1>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default PreviewModal;
