export const secondsToHrMinS = totalSeconds => {
  var hours = Math.floor(totalSeconds / 3600);
  var minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  var seconds = totalSeconds - hours * 3600 - minutes * 60;

  if (hours > 0) {
    hours = hours + 'hr ';
  } else {
    hours = ""
  }
  if (minutes > 0) {
    minutes =  minutes+ "min ";
  }else{
    minutes =""
  }
  if (seconds > 0) {
    seconds = seconds + "sec";
  }else{
    seconds = "0 sec"
  }

  return hours +  minutes  + seconds;
};
export const secToHrMinS = totalSeconds => {
  var hours = Math.floor(totalSeconds / 3600);
  var minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  var seconds = totalSeconds - hours * 3600 - minutes * 60;

  if (hours > 0) {
    hours = hours + 'hr';
  } else {
    hours = ""
  }
  if (minutes > 0) {
    minutes =  minutes+ "m";
  }else{
    minutes =""
  }
  if (seconds > 0) {
    seconds = seconds + "s";
  }else{
    seconds = "0 s"
  }

  return hours +  minutes  + seconds;
};
