import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import { useDispatch, useSelector } from "react-redux";
import {
  showToaster,
  ShowAlertBox,
  getUserItem,
} from "../../../services/helper";
import { useHistory, useRouteMatch, Switch, Route } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import {
  getUsageProjectDetails,
  getRenderProjectDetails,
} from "../../../services/api.service";
import { updateSlideRenderName } from "../../../services/authService";
import Tooltip from "@mui/material/Tooltip";
import PreviewModal from "../../../modules/dashboard/project-priview/RightBar/PreviewModal";
import Videoplayer from "../../components/Videoplayer/Videoplayer";
import { random } from "nanoid";
import PageLoader from "../../../services/pageLoader";
import fileDownload from "js-file-download";
import axios from "axios";
import { SelectActivePreviewSlide } from "../../../store/Actions";
// let DisplayMode = useSelector(state => state.project.DisplayMode)
export default function RenderTrackingDetails(props) {
  const [projectList, setprojectList] = useState([]);
  const [Duration, setDuration] = useState(0);
  const [openVideoPreview, setOpenVideoPreview] = useState(false);
  const [Project, setProject] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [pageLoaded, setPageLoaded] = useState(false);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [editCategory, setEditCategory] = useState(false);
  const [editCategoryValue, setEditCategoryValue] = useState(undefined);
  const [editCategoryID, seteditCategoryID] = useState(undefined);
  const [categoryEditError, setCategoryEditError] = useState(false);
  const [renderStatus, setrenderStatus] = useState(undefined);
  const [activeScreen, setActiveScreen] = useState();
  const [screenList, setScreenList] = useState(undefined);
  const history = useHistory();
  useEffect(() => {
    if (props.location.state.type === "tracking") {
      setProject(props.location.state.fullData.ddd[0]);
      setrenderStatus(props.location.state.fullData.ddd1[0]);
    } else {
      setProject(props.location.state.fullData.project_name);
      setrenderStatus(props.location.state.fullData.render_status);
    }

    console.log(props);
    getProjectDetails();
  }, []);

  const getProjectDetails = () => {
    if (
      props.location.state.projectId != undefined &&
      // props.location.state.fullData != undefined &&
      props.location.state.projectId != "" &&
      // props.location.state.fullData != "" &&
      props.location.state.projectId != null
      // props.location.state.fullData != null
    ) {
      let userId = getUserItem("id");
      let filter;
      filter = {
        project_id: props.location.state.projectId,
      };
      console.log(filter);
      setPageLoaded(true);
      try {
        getUsageProjectDetails(filter).then((response) => {
          try {
            if (
              response !== "" &&
              response !== undefined &&
              response !== null &&
              response.status !== "" &&
              response.status !== undefined &&
              response.status !== null &&
              response.status === 200 &&
              response.data !== "" &&
              response.data !== undefined &&
              response.data !== null
            ) {
              let screenArray = [];
              let slideArray = [];
              let moduleArray = [];
              let projectData = response.data;
              console.log(projectData[0].render_details);
              // Object.entries(slides.screens).map((screen, inde) => {
              //   console.log(screen, "screen");
              //   slideArray.push(screen)
              // });

              // setScreenList(slideArray);
              // let projectList1 = [...projectList, ...projectData];
              // let projectList = projectData;
              Object.entries(projectData[0].render_details).map(
                (module, index1) => {
                  console.log(module, "screen");
                  moduleArray.push(module[0]);
                  Object.keys(module[1])
                    .filter((item) => item.startsWith("SL"))
                    .map((slide, i) => {
                      // let slideArray=[]
                      slideArray.push(slide);

                      // screenArray.push(module[1][slide].screens)
                    });
                }
              );
              console.log(
                projectData[0].render_details[moduleArray[0]][slideArray[0]]
                  .screens,
                "screen"
              );
              Object.entries(
                projectData[0].render_details[moduleArray[0]][slideArray[0]]
                  .screens
              ).map((screen, inde) => {
                screenArray.push(screen);
              });

              setActiveScreen(
                projectData[0].render_details[moduleArray[0]][slideArray[0]]
              );
              console.log(slideArray, "slidesss");
              setScreenList(screenArray);
              console.log(screenArray, "screensdaa");
              setprojectList(projectData);
              setPageLoaded(false);
            } else {
              showToaster("No response from the server", "warning");
              // setprojectList([]);
              setPageLoaded(false);
            }
          } catch (error) {
            console.log(error, "errorerror");
            showToaster("Something went wrong12. Please Try Again", "warning");
            //   setprojectList([]);
            setPageLoaded(false);
          }
        });
      } catch (error) {
        showToaster("Something went wrong2. Please Try Again", "warning");
        //   setprojectList([]);
        setPageLoaded(false);
      }
    }
  };

  const saveSlideRenderName = (value) => {
    if (editCategoryValue.length >= 50 || editCategoryValue.length < 3) {
      setCategoryEditError(true);
    } else {
      setPageLoaded(true);
      setCategoryEditError(false);
      try {
        let filter = {
          slideRenderName: editCategoryValue,
        };
        updateSlideRenderName(value._id, filter).then((response) => {
          try {
            if (
              response !== "" &&
              response !== undefined &&
              response !== null &&
              response.data !== "" &&
              response.data !== undefined &&
              response.data !== null
            ) {
              showToaster("Slide render name updated successfully", "success");

              setPageLoaded(false);
              getProjectDetails();
              setEditCategory(false);
              // this.setState({ openInputModal: true })
            } else {
              setPageLoaded(false);
              setEditCategory(false);
              showToaster("Something went wrong. Please Try Again", "warning");
            }
          } catch (error) {
            setPageLoaded(false);
            setEditCategory(false);
            showToaster("Something went wrong. Please Try Again", "warning");
          }
        });
      } catch (e) {
        setPageLoaded(false);
        setEditCategory(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    }
  };
  const downloadFile = (url) => {
    let filename = url.split("Projects/");
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename[1]);
      });
  };
  useEffect(() => {
    let duration = 0;
    projectList.map((data, index) => {
      duration = duration + data.Total_duration;
      setDuration(duration);
    });

  }, [projectList]);
  const openPreviewModal = (videoUrl, thumUrl) => {
    // setProjectID(projectId)
    setOpenVideoPreview(true);
    setPreviewUrl(videoUrl);
    setThumbnailUrl(thumUrl);
  };
  const backToDashboard = () => {
    if (props.location.state.type === "tracking") {
      history.push("/usage-tracking");
    } else {
      history.push("/render-list");
    }
  };
  const EditCategory = (value) => {
    setEditCategory(true);
    seteditCategoryID(value._id);

    setEditCategoryValue(value.slideRenderName);
  };
  useEffect(() => {
    setTimeout(() => {
      setCategoryEditError(false);
    }, 4000);
  }, [categoryEditError]);

  const ActiveSlide = (slides) => {
    // debugger;
    let slideArray = [];
    Object.entries(slides.screens).map((screen, inde) => {
      slideArray.push(screen);
    });
    setScreenList(slideArray);
  };

  return (
    <div className={DisplayMode ? "theme dark" : "theme"}>
      <div className="main-wrapper">
        <Header />

        <div className="progress-status">
          <div className="progress custom-progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "100%" }}
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>

        <div className="pb-wrapper project-list-wrapper">
          <div className="row pb-header mb-4">
            <div className="col-md-6 col-lg-4">
              <button
                onClick={() => {
                  backToDashboard();
                }}
                type="submit"
                className="btn btn-outline-primary text-nowrap back-button me-3 mb-2 mb-lg-0"
                value="submit"
              >
                <svg
                  width="14"
                  height="11"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.42652e-06 5.5C0.00211858 5.41315 0.0186696 5.32723 0.0490002 5.24563C0.0651946 5.21658 0.0839324 5.18898 0.105001 5.16313C0.123314 5.12504 0.144368 5.08828 0.168001 5.05313L4.368 0.240672C4.53056 0.0540017 4.78216 -0.0333139 5.02804 0.0116137C5.27392 0.0565413 5.47672 0.226887 5.56004 0.458484C5.64336 0.690081 5.59456 0.947744 5.432 1.13441L2.219 4.81251L13.3 4.81251C13.6866 4.81251 14 5.12031 14 5.5C14 5.87969 13.6866 6.18749 13.3 6.18749L2.219 6.18749L5.432 9.86559C5.59456 10.0523 5.64337 10.3099 5.56004 10.5415C5.47672 10.7731 5.27392 10.9435 5.02804 10.9884C4.78216 11.0333 4.53056 10.946 4.368 10.7593L0.168001 5.94687C0.144368 5.91172 0.123314 5.87496 0.105001 5.83687C0.0839324 5.81102 0.0651946 5.78342 0.0490003 5.75437C0.0186696 5.67277 0.00211859 5.58685 1.42652e-06 5.5Z"
                    fill=""
                  ></path>
                </svg>
              </button>
            </div>
            <div className="col-md-6 col-lg-6 offset-lg-2"></div>
          </div>
          {!pageLoaded ? (
            projectList !== undefined && Project !== undefined ? (
              <>
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h2> {Project} </h2>
                  <div className="d-flex align-items-center">
                    <small className="me-2">Render Status</small>
                    <h2>{renderStatus}</h2>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <small className="me-2">Total Render Count</small>
                  <h2 className="blue-text "> {projectList.length}</h2>
                </div>
              </>
            ) : (
              "no projects"
            )
          ) : (
            "not page loaded"
          )}

          <div className="table-wraper usage-track-table">
            <div className="pb-tbl-blk ">
              <div className="usage-block usage-block-head  mb-3 sticky-head mt-0">
                <div className="usage-head-label "></div>
                <div className="usage-head-label ">Name</div>
                <div className="usage-head-label text-center">
                  Rendered Date & Time
                </div>
                <div className="usage-head-label text-center">
                  Number of Modules
                </div>
                <div className="usage-head-label text-center">Number of Videos</div>
                <div className="usage-head-label text-center">Total Duration</div>
                <div className="usage-head-label text-center">Total Size</div>
              </div>
              <div className="prject-list-info">
                <div id="accordion-1" className="accordion">
                  {projectList.length > 0 && projectList !== undefined ? (
                    projectList.map((data, index) => {
                      let sortedCreatedDate;
                      let moduleSize = data.Total_Size;
                      let arrayvalue = [];
                      console.log(data, "moduleSize");
                      if (data.renderDate !== undefined) {
                        let Created = data.renderDate;
                        sortedCreatedDate = Created.split("T")[0];
                      }
                      Object.keys(data.render_details).map((ddd, eee) => {
                        arrayvalue.push(ddd);
                      });
                      console.log(arrayvalue, "arrayvalue");
                      return (
                        <div className="card">
                          <div className="card-header" id="heading-1">
                            <h5 className="mb-0">
                              <a className="usage-block collapsed">
                                <div
                                  className="usage-arrow cursor-pointer"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#acr${index}`}
                                  aria-expanded="false"
                                  aria-controls={`acr${index}`}
                                ></div>

                                <div className="usage-head-label ">
                                  <div className="d-flex label-edit align-items-center">
                                    <div className="edit-content">
                                      <>
                                        {!(
                                          editCategory &&
                                          editCategoryID === data._id
                                        ) ? (
                                          <Tooltip
                                            title={data.slideRenderName}
                                            arrow
                                          >
                                            <label>
                                              {data.slideRenderName}
                                            </label>
                                          </Tooltip>
                                        ) : (
                                          <input
                                            className="input-border"
                                            type="text"
                                            id="lname"
                                            name=""
                                            placeholder={
                                              editCategoryValue == ""
                                                ? "Enter slidename"
                                                : ""
                                            }
                                            value={
                                              editCategory
                                                ? editCategoryValue
                                                : data.slideRenderName
                                            }
                                            onChange={(e) => {
                                              setEditCategoryValue(
                                                e.target.value
                                              );
                                            }}
                                          />
                                        )}
                                      </>
                                    </div>
                                    <div className="edit-icon d-flex">
                                      {!(
                                        editCategory &&
                                        editCategoryID === data._id
                                      ) ? (
                                        <span
                                          onClick={() => {
                                            EditCategory(data);
                                          }}
                                          className="icon-wrap"
                                        >
                                          <Tooltip   arrow>
                                            <svg
                                              width="12px"
                                              height="12px"
                                              version="1.1"
                                              viewBox="0 0 512 512"
                                              enable-background="new 0 0 512 512"
                                            >
                                              <g>
                                                <g>
                                                  <path d="m455.1,137.9l-32.4,32.4-81-81.1 32.4-32.4c6.6-6.6 18.1-6.6 24.7,0l56.3,56.4c6.8,6.8 6.8,17.9 0,24.7zm-270.7,271l-81-81.1 209.4-209.7 81,81.1-209.4,209.7zm-99.7-42l60.6,60.7-84.4,23.8 23.8-84.5zm399.3-282.6l-56.3-56.4c-11-11-50.7-31.8-82.4,0l-285.3,285.5c-2.5,2.5-4.3,5.5-5.2,8.9l-43,153.1c-2,7.1 0.1,14.7 5.2,20 5.2,5.3 15.6,6.2 20,5.2l153-43.1c3.4-0.9 6.4-2.7 8.9-5.2l285.1-285.5c22.7-22.7 22.7-59.7 0-82.5z"></path>
                                                </g>
                                              </g>
                                            </svg>
                                          </Tooltip>
                                        </span>
                                      ) : (
                                        <>
                                          <span
                                            className="icon-wrap"
                                            onClick={() => {
                                              saveSlideRenderName(data);
                                            }}
                                          >
                                            <Tooltip title="Save" arrow>
                                              <svg
                                                width="16px"
                                                height="16px"
                                                viewBox="0 0 24 24"
                                                fill=""
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z"
                                                  fill=""
                                                ></path>
                                              </svg>
                                            </Tooltip>
                                          </span>
                                          <span
                                            onClick={() => {
                                              setEditCategory(false);
                                            }}
                                            className="icon-wrap"
                                          >
                                            {" "}
                                            <Tooltip title="Close" arrow>
                                              <svg
                                                width="12px"
                                                height="12px"
                                                viewBox="0 0 20 20"
                                              >
                                                <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"></path>
                                              </svg>
                                            </Tooltip>
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="usage-label cursor-pointer text-center"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#acr${index}`}
                                  aria-expanded="false"
                                  aria-controls={`acr${index}`}
                                >
                                  {sortedCreatedDate} /{data.renderTime}
                                </div>
                                <div
                                  className="usage-label text-center cursor-pointer"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#acr${index}`}
                                  aria-expanded="false"
                                  aria-controls={`acr${index}`}
                                >
                                  {arrayvalue.length}
                                </div>
                                <div
                                  className="usage-label text-center cursor-pointer"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#acr${index}`}
                                  aria-expanded="false"
                                  aria-controls={`acr${index}`}
                                >
                                  {data.Total_Slides}
                                </div>
                                <div
                                  className="usage-label text-center cursor-pointer"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#acr${index}`}
                                  aria-expanded="false"
                                  aria-controls={`acr${index}`}
                                >
                                  {data.Total_duration} sec
                                </div>
                                <div className="usage-label text-center cursor-pointer ">
                                  {data.Total_Size < 1024
                                    ? Number(data.Total_Size / 1000).toFixed(2)
                                    : Number(
                                        data.Total_Size / 1000 / 1000
                                      ).toFixed(2)}{" "}
                                  MB
                                </div>
                              </a>
                            </h5>
                            {editCategory &&
                            editCategoryID === data._id &&
                            categoryEditError ? (
                              <span className="input-error-msg">
                                Must be exactly 50 characters and Minimum 3
                                characters are required{" "}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            id={`acr${index}`}
                            className="collapse "
                            aria-labelledby="heading-1"
                            data-bs-parent="#accordion-1"
                          >
                            <div className="card-body" id="child1">
                              {Object.entries(data.render_details).map(
                                (module, index1) => {
                                  console.log(module, index1, "pppp");
                                  let duration1 = 0;
                                  let totalModuleSize = 0;
                                  let slideArray = [];
                                  let crashedCount=[];
                                  let successCOunt=[]
                                  let name = module[1].ModuleName;
                                  let keys = Math.floor(Math.random() * 1000);
                                  console.log(module);
                                  let ddd;
                                  ddd = Object.keys(module[1]).filter(function (
                                    k
                                  ) {
                                    if (k.startsWith("SL")) {
                                      return k;
                                    }
                                  });

                                  // let slide_Array = module[1]
                                  // delete slide_Array.ModuleName
                                  return (
                                    <div className="card">
                                      <div className="card-header">
                                        {Object.keys(module[1])
                                          .filter((item) =>
                                            item.startsWith("SL")
                                          )
                                          .map((slide, i) => {
                                            slideArray.push(slide);
                                            console.log(
                                              slideArray,
                                              "slideArray"
                                            );
                                          })}{" "}
                                        <a
                                          href="#"
                                          className="usage-block"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#acr${keys}`}
                                          aria-expanded="false"
                                          onClick={() =>
                                            ActiveSlide(
                                              module[1][slideArray[0]]
                                              // setActiveScreen(module[1][slide])
                                            )
                                          }
                                        >
                                          <div className="usage-label ">
                                            {module[1].ModuleName}
                                          </div>
                                          <div className="usage-label ">
                                            {Object.keys(module[1])
                                              .filter((item) =>
                                                item.startsWith("SL")
                                              )
                                              .map((slide, i) => {
                                                console.log(module[1][slide]);
                                                if(module[1][slide].duration!==""){
                                                  duration1 =
                                                  duration1 +
                                                  module[1][slide].duration;
                                                }
                                                
                                              })}{" "}
                                            {duration1} sec
                                          </div>
                                          <div className="usage-label ">
                                            {Object.keys(module[1])
                                              .filter((item) =>
                                                item.startsWith("SL")
                                              )
                                              .map((slide, i) => {
                                                console.log(slide[1].size);
                                                totalModuleSize = Number(
                                                  totalModuleSize +
                                                    module[1][slide].size / 1000
                                                );
                                              })}
                                            {totalModuleSize < 1024
                                              ? Number(
                                                  totalModuleSize / 1000
                                                ).toFixed(2)
                                              : Number(
                                                  totalModuleSize / 1000
                                                ).toFixed(2)}{" "}
                                            MB
                                          </div>
                                        </a>
                                      </div>

                                      <div
                                        className=" collapse"
                                        data-bs-parent="#child1"
                                        id={`acr${keys}`}
                                      >
                                        <div className="usage-inner">
                                          <div className="row">
                                            {
                                              
                                            Object.keys(module[1])
                                              .filter((item) =>
                                                item.startsWith("SL")
                                              )
                                              .map((slide, i) => {
                                                console.log(
                                                  module[1][slide],
                                                  "aaaa"
                                                );

                                                let totalSize = Number(
                                                  module[1][slide].size / 1000
                                                );
                                                Object.entries( module[1][slide].screens).map(
                                                  (screen, inde) => {
                                                    if(screen[1].url!==undefined&&screen[1].url!==""){
                                                      successCOunt.push(screen[0])
                                                    }
                                                  })

                                                  if( module[1][slide].crashed!==undefined&&module[1][slide].crashed!==null){
                                                    Object.keys( module[1][slide].crashed).map(
                                                      (crashed1, inde) => {
                                                        if (crashed1 !== undefined && crashed1 !== null) {
                                                          crashedCount.push(crashed1)
                                                      }
                                                      })
                                                  }
                                         
                                                return (
                                                  <>
                                                    <div className="col-4">
                                                      <div
                                                        className="slide-list "
                                                        onClick={() =>
                                                          ActiveSlide(
                                                            module[1][slide],
                                                            setActiveScreen(
                                                              module[1][slide]
                                                            )
                                                          )
                                                        }
                                                      >
                                                        <h3
                                                          className="slide-list-head "
                                                          // style={{
                                                          //   color:
                                                          //     module[1][
                                                          //       slide
                                                          //     ] === activeScreen
                                                          //       ? "red"
                                                          //       : "",
                                                          // }}
                                                        >
                                                          {
                                                            module[1][slide]
                                                              .SlideName
                                                          }
                                                        </h3>
                                                        <div className="slide-list-inner ">
                                                          <div className="d-flex justify-content-between">

                                                          <p className="mb-0">
                                                          {
                                                            module[1][slide]
                                                              .SlideName
                                                          }
                                                          {/* Render Video={successCOunt.length} */}
                                                          </p>
                                                          {/* <small className="mb-0" >
                                                            Crashed Video={crashedCount.length}
                                                          </small> */}
                                                          </div>
                                                         
                                                          <div className="d-flex justify-content-between align-items-center flex-warp">
                                                            <div className="d-flex">
                                                              <small className="me-3">
                                                                {totalSize <
                                                                1024
                                                                  ? Number(
                                                                      totalSize /
                                                                        1000
                                                                    ).toFixed(2)
                                                                  : Number(
                                                                      totalSize /
                                                                        1000
                                                                    ).toFixed(
                                                                      2
                                                                    )}{" "}
                                                                MB
                                                              </small>
                                                              <div className="d-flex time-duration align-items-center">
                                                                <svg
                                                                  width="14"
                                                                  height="15"
                                                                  viewBox="0 0 14 15"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M7.00002 2.54102C4.26594 2.54102 2.04169 4.76527 2.04169 7.49935C2.04169 10.2334 4.26594 12.4577 7.00002 12.4577C9.7341 12.4577 11.9584 10.2334 11.9584 7.49935C11.9584 4.76527 9.7341 2.54102 7.00002 2.54102M7.00002 13.3327C3.78352 13.3327 1.16669 10.7158 1.16669 7.49935C1.16669 4.28285 3.78352 1.66602 7.00002 1.66602C10.2165 1.66602 12.8334 4.28285 12.8334 7.49935C12.8334 10.7158 10.2165 13.3327 7.00002 13.3327"
                                                                    fill=""
                                                                  />
                                                                  <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M9.00154 9.65359C8.92512 9.65359 8.84812 9.63376 8.77754 9.59234L6.57837 8.28042C6.44653 8.20109 6.36487 8.05817 6.36487 7.90417V5.07617C6.36487 4.83467 6.56087 4.63867 6.80237 4.63867C7.04445 4.63867 7.23987 4.83467 7.23987 5.07617V7.65567L9.22612 8.83984C9.4332 8.96409 9.50145 9.23242 9.37779 9.44009C9.29554 9.57717 9.15029 9.65359 9.00154 9.65359Z"
                                                                    fill=""
                                                                  />
                                                                </svg>
                                                                <small className="ms-1">
                                                                  {
                                                                    module[1][
                                                                      slide
                                                                    ].duration
                                                                  }{" "}
                                                                  sec
                                                                </small>
                                                              </div>
                                                            </div>
                                                            {module[1][slide]
                                                              .url !== "" &&
                                                              module[1][slide]
                                                                .url !==
                                                                undefined && (
                                                                <button
                                                                  type="button"
                                                                  className="btn play-button"
                                                                  onClick={() =>
                                                                    openPreviewModal(
                                                                      module[1][
                                                                        slide
                                                                      ].url,
                                                                      module[1][
                                                                        slide
                                                                      ]
                                                                        .thumbnail_url
                                                                    )
                                                                  }
                                                                >
                                                                  {" "}
                                                                  <Tooltip
                                                                    title="Play Render Video"
                                                                    arrow
                                                                  >
                                                                    <svg
                                                                      width="8"
                                                                      height="10"
                                                                      viewBox="0 0 8 10"
                                                                      fill="none"
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                      <path
                                                                        d="M0.666687 0.916016V9.08268L7.08335 4.99935L0.666687 0.916016Z"
                                                                        fill="#468BE8"
                                                                      />
                                                                    </svg>
                                                                  </Tooltip>
                                                                  <span>
                                                                    PLAY
                                                                  </span>
                                                                </button>
                                                              )}
                                                            {module[1][slide]
                                                              .url !== "" &&
                                                              module[1][slide]
                                                                .url !==
                                                                undefined && (
                                                                <span
                                                                  // type="button"
                                                                  className="cursor pointer"
                                                                  onClick={() => {
                                                                    downloadFile(
                                                                      module[1][
                                                                        slide
                                                                      ].url
                                                                    );
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <Tooltip
                                                                    title="Download Slide Video"
                                                                    arrow
                                                                  >
                                                                    <svg
                                                                      width="20"
                                                                      height="20"
                                                                      viewBox="0 0 24 24"
                                                                      fill="none"
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                      <path
                                                                        d="M12 4V16"
                                                                        stroke="black"
                                                                        stroke-width="1.3"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                      ></path>
                                                                      <path
                                                                        d="M6 11L12 16L18 11"
                                                                        stroke="black"
                                                                        stroke-width="1.3"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                      ></path>
                                                                      <path
                                                                        d="M5 19H19"
                                                                        stroke="black"
                                                                        stroke-width="1.3"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                      ></path>
                                                                    </svg>
                                                                  </Tooltip>
                                                                </span>
                                                              )}
                                                          </div>
                                                        </div>

                                                        <div className="row my-2">
                                              {Object.entries( module[1][slide].screens).map(
                                                (screen, inde) => {
                                                  return (
                                                    <div className="col-6 mb-2">
                                                      <div className="slide-list">
                                                        <h3 className="slide-list-head">
                                                          Screen
                                                          {inde + 1}
                                                        </h3>
                                                        <div className="slide-list-inner">
                                                          {/* <p>{screen[0]}</p> */}
                                                          <div className="d-flex justify-content-between align-items-center flex-warp">
                                                            <div className="d-flex">
                                                              {/* <small className="me-3"></small> */}
                                                              <div className="d-flex time-duration align-items-center">
                                                                {/* <small className="ms-1"></small>s */}
                                                              </div>
                                                            </div>
                                                            {screen[1]
                                                              .url !== "" &&
                                                              screen[1] !==
                                                                undefined ? (
                                                                  <>
                                                                <button
                                                                  type="button"
                                                                  className="btn play-button me-2"
                                                                  onClick={() =>
                                                                    openPreviewModal(
                                                                      screen[1]
                                                                        .url,
                                                                      ""
                                                                    )
                                                                  }
                                                                >
                                                                  {" "}
                                                                  <Tooltip
                                                                    title="Play Render Video"
                                                                    arrow
                                                                  >
                                                                    <svg
                                                                      width="8"
                                                                      height="10"
                                                                      viewBox="0 0 8 10"
                                                                      fill="none"
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                      <path
                                                                        d="M0.666687 0.916016V9.08268L7.08335 4.99935L0.666687 0.916016Z"
                                                                        fill="#468BE8"
                                                                      />
                                                                    </svg>
                                                                  </Tooltip>
                                                                  <span>
                                                                    PLAY
                                                                  </span>
                                                                </button>
                                                                <span
                                                                // type="button"
                                                                className="cursor pointer"
                                                                onClick={() => {
                                                                  downloadFile(
                                                                    screen[1]
                                                                      .url
                                                                  );
                                                                }}
                                                              >
                                                                {" "}
                                                                <Tooltip
                                                                  title="Download Screen Video"
                                                                  arrow
                                                                >
                                                                  <svg
                                                                    width="20"
                                                                    height="20"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                  >
                                                                    <path
                                                                      d="M12 4V16"
                                                                      stroke="black"
                                                                      stroke-width="1.3"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                    ></path>
                                                                    <path
                                                                      d="M6 11L12 16L18 11"
                                                                      stroke="black"
                                                                      stroke-width="1.3"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                    ></path>
                                                                    <path
                                                                      d="M5 19H19"
                                                                      stroke="black"
                                                                      stroke-width="1.3"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                    ></path>
                                                                  </svg>
                                                                </Tooltip>
                                                              </span>
                                                              </>
                                                              ):<div className="crashed-list"><span className="input-error-msg align-items-center">
                                                              crashed
                                                            </span></div>}
                                                           
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              })}
                                          
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="no-project-selected blink">
                      No project available
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PreviewModal
        closePreview={() => setOpenVideoPreview(false)}
        openPreviewModals={openVideoPreview}
        previewUrl={previewUrl}
        thumbnailUrl={thumbnailUrl}
        showVolume={true}
      />
    </div>
  );
}
