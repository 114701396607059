import { showToaster } from "./helper";

const downloadFile = (url, fileName, setIsDownloading) => {
  const fileUrl = url;
  setIsDownloading(true)
  fetch(fileUrl)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setIsDownloading(false)
    })
    .catch(error => {
      console.error('Error downloading file:', error);
      setIsDownloading(false)
      showToaster(`Error downloading file:${error}`, 'error')
    });

};

export default downloadFile;
