import { useEffect, useState } from 'react';
import Sidebar from '../../../components/sidebar';
import InnerSidebarHeader from '../../../components/sidebar/innerSidebarHeader';
import InnerSidebar from '../../../components/sidebar/innerSidebar';
import FormTextarea from '../../../components/form/formTextarea';
import GenerateScriptComponent from './generateScriptComponent';
import { Accordion, Spinner } from 'react-bootstrap';
import AccordionItem from '../../../components/accordion/AccordionItem';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

// Icons
import {
  aiExtendIcon,
  backArrowIcon,
  chatGptIcon,
  composeScreenIcon,
  longRightArrowIcon,
  videoIcon,
} from '../../../constants/icon';
import HeaderNavigation from '../../../components/headerNavigation';
import {
  chatGPTGenerateInputs,
  chatGPTGenerateModules,
  chatGPTGenerateScript,
} from '../../../services/apiServices';
import {
  checkScript,
  getClientSetting,
  getProject,
  getProjectStyles,
  getTemplateData,
  saveProjectStyles,
} from '../../../services/api.service';
import { useParams } from 'react-router-dom';
import { CreateProjectModelforChatGPT, addScreenforChatGPT } from '../../../services/DataHelper';
import {
  closeToaster,
  getProjectQueryData,
  getRandomNumber,
  showToaster,
} from '../../../services/helper';
// import HeaderNavigation from '../../../components/headerNavigation';
import {
  BgColor,
  TextColor,
  FONTSTYLE,
  FONTFAMILY,
  FONT_SIZE_H,
  FONT_SIZE_P,
} from '../../../constants';
import ConfirmModal from './ConfirmModal';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../store';
import { useDispatch } from 'react-redux';
import GenerateModuleScriptComponent from './generateModuleScriptComponent';

function ChatGpt() {
  const [currentView, setCurrentView] = useState('chatGpt');
  const [isInnerSidebarActive, setIsInnerSidebarActive] = useState(false);
  const [segmentList, setSegmentList] = useState([]);
  const [scriptContent, setScriptContent] = useState('');
  const [generatingScript, setGeneratingScript] = useState(false);
  const [generatingScreenContent, setGeneratingScreenContent] = useState(false);
  const [disableGeneratePreview, setDisableGeneratePreview] = useState(false);
  const [generateModule, setGenerateModule] = useState(false);
  const params = useParams();
  const [screenValues, setScreenValues] = useState([]);
  const [moduleValues, setModuleValues] = useState([]);
  const [allModuleData, setAllModuleData] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [progressPercentage, setProgressPercentage] = useState('0%');
  const [generatingPreviewMessage, setGeneratingPreviewMessage] = useState('');
  const [project, setProject] = useState({});
  const [totalScreen, setTotalScreen] = useState(0);
  // const [totalCompletedScreen, setTotalCompletedScreen] = useState(0);
  // const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [optionSelected, setOptionSelected] = useState(false);
  const [expandContent, setExpandContent] = useState(false);
  // Accordion Start
  const [activeKeys, setActiveKeys] = useState([]);
  const [isHeaderActive, setIsHeaderActive] = useState({});
  const [clientSetting, setClientSetting] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  const { SetStylesData } = bindActionCreators(actionCreator, dispatch);

  useEffect(() => {
    async function init() {
      try {
        const [clientSettingData, projectResponse] = await Promise.all([
          getClientSetting(),
          getProject(params.projectId),
        ]);

        if (clientSettingData) setClientSetting(clientSettingData);

        if (!projectResponse?.ProjectData) return history.replace('/list-projects');
        await getProjectStylesData(projectResponse.ProjectID);
        const projectData = JSON.parse(projectResponse.ProjectData);
        if (projectResponse) {
          setProject({ ...projectResponse, ProjectData: projectData });
        }
        // generateProjectData({ projectData, projectResponse, clientSettingData });

        handleValidation(projectResponse);
      } catch (error) {
        console.log({ editProjectsUseEffectError: error });
      }
    }

    init();

    return () => closeToaster();
  }, []);

  async function getProjectStylesData(ProjectID) {
    try {
      const styles = await getProjectStyles(ProjectID);

      let stylesData = styles?.data;
      if (!stylesData) {
        try {
          stylesData = {
            bgColor: BgColor,
            textColor: TextColor,
            fontStyle: FONTSTYLE,
            project_id: ProjectID,
            fontFamily: FONTFAMILY,
            headingSize: FONT_SIZE_H,
            paragraphSize: FONT_SIZE_P,
          };

          saveProjectStyles(stylesData);
        } catch (error) {
          console.log({ saveProjectStylesError: error.message || error });
        }
      }
      SetStylesData(stylesData);
    } catch (error) {
      console.log({ getProjectStylesDataError: error });
    }
  }
  const handleAccordionClick = eventKey => {
    if (activeKeys.includes(eventKey)) {
      setActiveKeys(activeKeys.filter(key => key !== eventKey));
      setIsHeaderActive({ ...isHeaderActive, [eventKey]: false });
    } else {
      setActiveKeys([...activeKeys, eventKey]);
      setIsHeaderActive({ ...isHeaderActive, [eventKey]: true });
    }
  };

  const deleteScreen = ({ screenKey, title }) => {
    const { from, module, slide, screen } = getProjectQueryData();
    let moduleId = 'M01';
    let slideId = 'SL01';
    let screenId = '01';

    if (from === 'edit-projects') {
      moduleId = module;
      slideId = slide;
      screenId = screen;
    }
    let projectInputs = JSON.parse(JSON.stringify(projectData));
    let segments = JSON.parse(JSON.stringify(segmentList));

    delete segments[title];
    projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens = {};
    let screenDatas = [];
    let i = 0;
    let hasTemplateForAll = true;
    for (const screenValue of screenValues) {
      if (screenValue.screenKey !== screenKey) {
        if (!screenValue.hasTemplate) {
          hasTemplateForAll = false;
        }
        screenDatas[i] = screenValue;
        let newScreenKey = `SC${screenId}.0${i + 1}`;
        if (i + 1 >= 10) {
          newScreenKey = `SC${screenId}.${i + 1}`;
        }

        projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens[newScreenKey] =
          projectData[project.ProjectID].Modules[moduleId].slides[slideId].Screens[
            screenValue.screenKey
          ];
        screenDatas[i].screenKey = newScreenKey;
        i++;
      }
    }
    setDisableGeneratePreview(!hasTemplateForAll);
    setSegmentList(segments);
    setProjectData(projectInputs);
    setScreenValues(screenDatas);
  };

  const handleShowButtonClick = () => {
    history.push('/create-project');
  };
  // Accordion end

  const handleComposeClick = () => {
    let redirectUrl = `/edit-projects/${params.projectId}`;

    const { from, module, action } = getProjectQueryData();

    if (from === 'edit-projects') {
      redirectUrl += `?from=generate-videos-inputs&action=${action}&module=${module}`;
    }

    history.push(redirectUrl);
  };

  const handleGenerateModule = async () => {
    try {
      setGeneratingScript(true);
      setDisableGeneratePreview(true);
      setSegmentList([]);
      setProgressPercentage('0%');
      setGeneratingPreviewMessage('');
      setProjectData({});
      setScreenValues([]);

      let generateModuleResponse = await chatGPTGenerateModules({
        content: scriptContent,
      });
      let modules = generateModuleResponse.data.result.modules;
      setModuleValues(modules);

      const NotificationURL =
        (process.env.REACT_APP_API_URL_DEV || process.env.REACT_APP_API_URL) +
        'api/project/v1/render-callback';
      const ProjectID = project.ProjectID;
      const ProjectName = project.ProjectName;
      const CategoryID = project.ProjectData[ProjectID].CategoryID;

      let projectInputs = {
        [ProjectID]: {
          ProjectName: ProjectName,
          CategoryID: CategoryID,
          NotificationURL: NotificationURL,
          Modules: {},
        },
      };
      let modulesDatas = [];
      let hasTemplates = true;
      let hasValidCharacters = true;
      let allPromises = [];
      let totalScreen = 0;
      let totalCompletedScreen = 0;

      for (const [moduleIndex, module] of modules.entries()) {
        let moduleId = moduleIndex + 1 > 9 ? `M${moduleIndex + 1}` : `M0${moduleIndex + 1}`;
        projectInputs[ProjectID].Modules[moduleId] = {
          ModuleName: module.moduleName,
          slides: {},
        };
        let moduleData = {
          moduleName: module.moduleName,
          moduleId: moduleId,
          slides: [],
          templatesForAll: true,
        };
        modulesDatas[moduleIndex] = moduleData;
        setAllModuleData(modulesDatas);

        let moduleSlides = module.videos;
        for (const [slideIndex, slide] of moduleSlides.entries()) {
          let slideId = slideIndex + 1 > 9 ? `SL${slideIndex + 1}` : `SL0${slideIndex + 1}`;
          projectInputs[ProjectID].Modules[moduleId].slides[slideId] = {
            SlideName: slide.videoName,
            SlideAudioVO: 'Null',
            SlideRender: '1',
            Screens: {},
          };
          moduleData.slides[slideIndex] = {
            slideId: slideId,
            SlideName: slide.videoName,
            screens: [],
            totalScreens: 0,
            totalCompletedScreen: 0,
            templatesForAll: true,
          };
          modulesDatas[moduleIndex] = moduleData;
          setAllModuleData(modulesDatas);
          chatGPTGenerateScript({
            content: slide.videoContent,
            expand_content: false,
          }).then(async slideSegments => {
            setCurrentView('generateScript');
            // setTotalScreen(totalScreen + Object.keys(slideSegments.data.result).length);
            moduleData.slides[slideIndex].totalScreens = Object.keys(
              slideSegments.data.result
            ).length;
            modulesDatas[moduleIndex] = moduleData;
            setAllModuleData(modulesDatas);
            totalScreen = totalScreen + Object.keys(slideSegments.data.result).length;
            setGeneratingScript(false);
            setGeneratingScreenContent(true);
            let id = 1;
            let total = 1;
            for (const [title, script] of Object.entries(slideSegments.data.result)) {
              setGeneratingPreviewMessage('Generating for ' + title);
              let screenInputs = await chatGPTGenerateInputs({ content: script });
              if (screenInputs.status == 500) {
                throw new Error('generate input api failed');
              }
              const ost = screenInputs.data.result.OST;
              const tags = screenInputs.data.result.TAGS;
              if (ost) {
                const regMatch = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};:\\|,.0-9<>\/?'"`]*$/.test(ost);
                if (!regMatch) {
                  hasValidCharacters = false;
                }
                let screenKey =
                  id > 9
                    ? `SC${slideIndex + 1 > 9 ? slideIndex + 1 : '0' + (slideIndex + 1)}.${id}`
                    : `SC${slideIndex + 1 > 9 ? slideIndex + 1 : '0' + (slideIndex + 1)}.0${id}`;
                let projectData = await addScreenforChatGPT(
                  ProjectID,
                  projectInputs,
                  screenKey,
                  title,
                  ost,
                  tags.join(','),
                  moduleId,
                  slideId
                );
                const screenContent = {
                  [ProjectID]: {
                    ProjectName: ProjectName,
                    Modules: {
                      [moduleId]: {
                        ModuleName: projectData[ProjectID]?.['Modules']?.[moduleId]?.['ModuleName'],
                        slides: {
                          [slideId]: {
                            SlideName:
                              projectData[ProjectID]?.['Modules']?.[moduleId]?.['slides'][
                                slideId
                              ]?.['SlideName'],
                            SlideAudioVO: 'Null',
                            Screens: {
                              [screenKey]:
                                projectData[ProjectID].Modules[moduleId].slides[slideId].Screens[
                                  screenKey
                                ],
                            },
                          },
                        },
                      },
                    },
                  },
                };
                projectInputs = projectData;
                console.log(projectInputs, 'projectData');
                console.log(modulesDatas, 'moduleDatas');
                const templateResponse = await getTemplateData(screenContent, ProjectID);
                let hasTemplate = false;
                if (templateResponse?.[0]?.length) {
                  const randomNumber = getRandomNumber({ max: templateResponse[0].length - 1 });
                  projectInputs[ProjectID]['Modules'][moduleId]['slides'][slideId]['Screens'][
                    screenKey
                  ]['TM01']['Contentparams']['contentdescription'] =
                    templateResponse[0][randomNumber].mediaUrl;
                  hasTemplate = true;
                } else {
                  projectInputs[ProjectID]['Modules'][moduleId]['slides'][slideId]['Screens'][
                    screenKey
                  ]['TM01']['Contentparams']['contentdescription'] = 'no template';
                  hasTemplates = false;
                  moduleData.templatesForAll = false;
                  moduleData.slides[slideIndex].templatesForAll = false;
                }
                moduleData.slides[slideIndex].screens[id - 1] = {
                  ost,
                  tags: tags.join(','),
                  title,
                  hasTemplate,
                  validOST: regMatch,
                  screenKey: screenKey,
                  audioScript: ost,
                };
                moduleData.slides[slideIndex].totalCompletedScreen++;
                modulesDatas[moduleIndex] = moduleData;
                setAllModuleData(modulesDatas);
                // setTotalCompletedScreen(totalCompletedScreen + 1);
                totalCompletedScreen++;
                id++;
                let percentage = Math.round((totalCompletedScreen / totalScreen) * 100 * 100) / 100;
                setProgressPercentage(percentage + '%');
                if (totalScreen && totalScreen == totalCompletedScreen) {
                  console.log(modulesDatas, 'ssssssssssssssssssssssss');
                  console.log(projectInputs, 'aaaaaaaaaaaaaaaaaaaaaaaaaaaa');
                  setProjectData(projectInputs);
                  setDisableGeneratePreview(!hasTemplates || !hasValidCharacters);
                  setGeneratingScript(false);
                  setGeneratingScreenContent(false);
                }
              }
              // let percentage =
              //   Math.round((total / Object.keys(slideSegments.data.result).length) * 100 * 100) /
              //   100;
              // setProgressPercentage(percentage + '%');
              total++;
            }
          });
        }

        console.log(modulesDatas, 'moduleData');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGenerateScriptClick = async () => {
    setGeneratingScript(true);
    setDisableGeneratePreview(true);
    setSegmentList([]);
    setProgressPercentage('0%');
    setGeneratingPreviewMessage('');
    setProjectData({});
    setScreenValues([]);

    const data = await checkScript(scriptContent);

    if (data.data === 'generation') {
      setGeneratingScript(false);
      setDisableGeneratePreview(false);
      const timer = 0;
      const showCloseButton = true;
      return showToaster(
        'The text entered appears to be a prompt rather than a script. Add a valid script to continue.',
        'error',
        timer,
        showCloseButton
      );
    }

    let segments = await chatGPTGenerateScript({
      content: scriptContent,
      expand_content: expandContent,
    });
    setSegmentList(segments.data.result);

    const NotificationURL =
      (process.env.REACT_APP_API_URL_DEV || process.env.REACT_APP_API_URL) +
      'api/project/v1/render-callback';
    const ProjectID = project.ProjectID;
    const ProjectName = project.ProjectName;
    const CategoryID = project.ProjectData[ProjectID].CategoryID;
    const { from, module, slide, screen } = getProjectQueryData();
    let moduleId = 'M01';
    let slideId = 'SL01';
    let screenId = '01';

    if (from === 'edit-projects') {
      moduleId = module;
      slideId = slide;
      screenId = screen;
    }

    let projectInputs = await CreateProjectModelforChatGPT(
      ProjectID,
      ProjectName,
      CategoryID,
      NotificationURL,
      moduleId,
      slideId
    );

    setCurrentView('generateScript');
    setGeneratingScript(false);
    let id = 1;
    let total = 1;
    setGeneratingScreenContent(true);
    let screenDatas = [];
    let hasTemplates = true;
    let hasValidCharacters = true;
    for (const [title, script] of Object.entries(segments.data.result)) {
      setGeneratingPreviewMessage('Generating for ' + title);
      try {
        const screenInputs = await chatGPTGenerateInputs({ content: script });
        if (screenInputs.status == 500) {
          throw new Error('generate input api failed');
        }
        const ost = screenInputs.data.result.OST;
        const tags = screenInputs.data.result.TAGS;
        if (ost) {
          let screenKey = `SC${screenId}.0` + id;
          if (id >= 10) {
            screenKey = `SC${screenId}.` + id;
          }
          const regMatch = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};:\\|,.0-9<>\/?'"`]*$/.test(ost);
          if (!regMatch) {
            hasValidCharacters = false;
          }
          const ProjectID = project.ProjectID;
          let projectData = await addScreenforChatGPT(
            ProjectID,
            projectInputs,
            screenKey,
            title,
            ost,
            tags.join(','),
            moduleId,
            slideId
          );
          projectInputs = projectData;

          //template get
          const screenContent = {
            [ProjectID]: {
              ProjectName: ProjectName,
              Modules: {
                [moduleId]: {
                  ModuleName: projectData[ProjectID]?.['Modules']?.[moduleId]?.['ModuleName'],
                  slides: {
                    [slideId]: {
                      SlideName:
                        projectData[ProjectID]?.['Modules']?.[moduleId]?.['slides'][slideId]?.[
                          'SlideName'
                        ],
                      SlideAudioVO: 'Null',
                      Screens: {
                        [screenKey]:
                          projectData[ProjectID].Modules[moduleId].slides[slideId].Screens[
                            screenKey
                          ],
                      },
                    },
                  },
                },
              },
            },
          };

          const templateResponse = await getTemplateData(screenContent, ProjectID);
          let hasTemplate = false;
          if (templateResponse?.[0]?.length) {
            const randomNumber = getRandomNumber({ max: templateResponse[0].length - 1 });
            projectInputs[ProjectID]['Modules'][moduleId]['slides'][slideId]['Screens'][screenKey][
              'TM01'
            ]['Contentparams']['contentdescription'] = templateResponse[0][randomNumber].mediaUrl;
            hasTemplate = true;
          } else {
            projectInputs[ProjectID]['Modules'][moduleId]['slides'][slideId]['Screens'][screenKey][
              'TM01'
            ]['Contentparams']['contentdescription'] = 'no template';
            hasTemplates = false;
          }

          let latestProject = await getProject(params.projectId);
          let latestProjectData = JSON.parse(latestProject.ProjectData);
          projectInputs = _.merge(latestProjectData, projectInputs);
          setProjectData(projectInputs);
          screenDatas.push({
            ost,
            tags: tags.join(','),
            title,
            hasTemplate,
            validOST: regMatch,
            screenKey: screenKey,
            audioScript: ost,
          });
          setScreenValues(screenDatas);
          id++;
        }
      } catch (error) {
        console.log(error);
      }
      let percentage =
        Math.round((total / Object.keys(segments.data.result).length) * 100 * 100) / 100;
      setProgressPercentage(percentage + '%');
      total++;
    }
    setDisableGeneratePreview(!hasTemplates || !hasValidCharacters);
    setGeneratingScreenContent(false);
  };

  const handleValidation = projectResponse => {
    const { from, module, slide, screen } = getProjectQueryData();

    if (from === 'edit-projects') {
      const projectData = JSON.parse(projectResponse.ProjectData);
      const modules = projectData?.[projectResponse.ProjectID]?.Modules;
      const modulesKeys = Object.keys(modules);

      if (!modulesKeys.find(key => key === module)) {
        const lastModule = modulesKeys[modulesKeys.length - 1];
        const lastModuleNumber = lastModule.replace('M0', '');
        const nextModule = 'M0' + (Number(lastModuleNumber) + 1);
        if (nextModule !== module) {
          showToaster('Invalid module query', 'error');
          return routeToEditProjects();
        }
      }

      const slides = modules[module].slides;
      const slidesKeys = Object.keys(slides);
      const lastSlide = slidesKeys[slidesKeys.length - 1];
      const lastSlideNumber = lastSlide.replace('SL0', '');
      const nextSlide = 'SL0' + (Number(lastSlideNumber) + 1);
      if (nextSlide !== slide) {
        showToaster('Invalid video query', 'error');
        return routeToEditProjects();
      }

      const querySlideNumber = slide.replace('SL', '');
      if (querySlideNumber !== screen) {
        showToaster('Invalid screen query', 'error');
        return routeToEditProjects();
      }
    }
  };

  const routeToEditProjects = () => {
    setTimeout(() => {
      // window.location.replace(window.location.pathname);
      history.replace(`/edit-projects/${params.projectId}`);
    }, 2000);
  };

  const aiProcessHandler = () => {
    setExpandContent(true);
    setGenerateModule(false);
    setOptionSelected(true);
  };

  const manualScriptHandler = () => {
    setGenerateModule(false);
    setExpandContent(false);
    setOptionSelected(true);
  };

  const generateModuleHandler = async () => {
    setExpandContent(false);
    setOptionSelected(true);
    setGenerateModule(true);
  };

  return (
    <>
      <Sidebar />
      <main className="main-content-wrapper">
        {isInnerSidebarActive ? (
          <InnerSidebar innerSidebarFooter>
            <InnerSidebarHeader
              title="Virtual Reality Development"
              subTitle="Online course"
              icon={videoIcon}
            />
            <Accordion
              className="custom-accordion"
              activeKey={activeKeys}
              onSelect={handleAccordionClick}
            >
              <AccordionItem
                eventKey="0"
                isActive={activeKeys.includes('0')}
                headerContent="Module 1"
                bodyContent={['An Introduction To Virtual Realty', 'Video 2', 'Video 3']}
                showButton="Add new video"
                isHeaderActive={isHeaderActive['0']}
                onHeaderClick={handleAccordionClick}
                onShowButtonClick={handleShowButtonClick}
              />
            </Accordion>
          </InnerSidebar>
        ) : (
          ''
        )}
        <div className={`content-wrapper ${isInnerSidebarActive ? 'active' : ''}`}>
          <div className="content-block">
            <HeaderNavigation userName="John Doe" userEmail="john.doe@example.com" />
            <div className="chat-gpt-container">
              {!optionSelected && (
                <div className=" chat-gpt-content">
                  <h2>Choose what you would like to start with </h2>
                  <div className="project-compose-option">
                    <div className="project-compose-item">
                      <p>Upload the Manual Script</p>
                      <div className="button-wrap">
                        <button className="project-compose-btn" onClick={manualScriptHandler}>
                          {aiExtendIcon} Upload the Manual Script
                        </button>
                      </div>
                    </div>
                    <div className="project-compose-item">
                      <p>Enter Script for the AI to Process</p>
                      <div className="button-wrap">
                        <button className="project-compose-btn" onClick={aiProcessHandler}>
                          {chatGptIcon} Enter script for the Chatgpt to Process
                        </button>
                      </div>
                    </div>
                    <div className="project-compose-item">
                      <p>Compose Screen</p>
                      <div className="button-wrap">
                        <button className="project-compose-btn" onClick={handleComposeClick}>
                          {' '}
                          {composeScreenIcon} Compose Screen
                        </button>
                      </div>
                    </div>
                    {/* <div className="project-compose-item">
                      <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quia nisi adipisci
                        inventore expedita aliquam dicta.
                      </p>
                      <div className="button-wrap">
                        <button
                          role="button"
                          className="project-compose-btn"
                          onClick={generateModuleHandler}
                        >
                          Fourth Button
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
              {currentView === 'chatGpt' && optionSelected && (
                <div className="chat-gpt-content">
                  {/* <h2>Choose what you would like to start with </h2>
                  <div className="chat-button-wrap">
                    <button role="button" className="bg-primary-btn">
                      {chatGptIcon} Manual Script
                    </button>
                    <button role="button" className="primary-btn" onClick={handleComposeClick}>
                      {composeScreenIcon} Compose Screen
                    </button>
                  </div> */}
                  <div className="chat-button-wrap">
                    <button
                      className={
                        generatingScreenContent || generatingScript
                          ? 'disabled btn primary-btn'
                          : 'primary-btn'
                      }
                      disabled={generatingScreenContent || generatingScript}
                      onClick={() => {
                        setOptionSelected(false);
                        setScriptContent('');
                        closeToaster();
                      }}
                    >
                      {backArrowIcon} Go Back
                    </button>
                  </div>
                  <FormTextarea
                    id="message"
                    placeholder=""
                    label="Write script for screen"
                    className="chat-gpt-textarea"
                    inputValue={scriptContent}
                    maxLength={4000}
                    showInputLength={true}
                    onChange={setScriptContent}
                  />
                  <div className="chat-generate-wrap">
                    <span className="characters-count">{scriptContent.length}/4000 characters</span>
                    <button
                      className={
                        scriptContent.length || !generatingScript
                          ? 'primary-btn'
                          : 'btn primary-btn disabled'
                      }
                      onClick={generateModule ? handleGenerateModule : handleGenerateScriptClick}
                      disabled={!scriptContent.length || generatingScript}
                    >
                      {generatingScript ? (
                        <>
                          Generating Script
                          <Spinner animation="border" style={{ color: '#6750a4' }} size="sm" />
                        </>
                      ) : (
                        <>Generate script {longRightArrowIcon}</>
                      )}
                    </button>
                  </div>
                </div>
              )}
              {currentView === 'generateScript' && !generateModule && (
                <GenerateScriptComponent
                  project={project}
                  scriptContent={scriptContent}
                  segmentList={segmentList}
                  screenValues={screenValues}
                  setScreenValues={setScreenValues}
                  projectData={projectData}
                  setProjectData={setProjectData}
                  progressPercentage={progressPercentage}
                  onScriptContentChange={setScriptContent}
                  generatingScript={generatingScript}
                  disableGeneratePreview={disableGeneratePreview}
                  setDisableGeneratePreview={setDisableGeneratePreview}
                  generatingScreenContent={generatingScreenContent}
                  clientSettingData={clientSetting}
                  generatingPreviewMessage={generatingPreviewMessage}
                  generateModule={generateModule}
                  handleGenerateScriptClick={handleGenerateScriptClick}
                  // setOpenConfirmModal={setOpenConfirmModal}
                  deleteScreen={deleteScreen}
                />
              )}
              {currentView === 'generateScript' && generateModule && (
                <GenerateModuleScriptComponent
                  project={project}
                  scriptContent={scriptContent}
                  segmentList={segmentList}
                  screenValues={screenValues}
                  setScreenValues={setScreenValues}
                  projectData={projectData}
                  setProjectData={setProjectData}
                  progressPercentage={progressPercentage}
                  onScriptContentChange={setScriptContent}
                  generatingScript={generatingScript}
                  disableGeneratePreview={disableGeneratePreview}
                  setDisableGeneratePreview={setDisableGeneratePreview}
                  generatingScreenContent={generatingScreenContent}
                  clientSettingData={clientSetting}
                  generatingPreviewMessage={generatingPreviewMessage}
                  generateModule={generateModule}
                  moduleData={allModuleData}
                  setModuleData={setAllModuleData}
                  handleGenerateScriptClick={handleGenerateScriptClick}
                  // setOpenConfirmModal={setOpenConfirmModal}
                  deleteScreen={deleteScreen}
                />
              )}
            </div>
          </div>
        </div>
      </main>
      {/* <ConfirmModal
        Header="Expand Content"
        isOpen={openConfirmModal}
        Buttonclick={handleGenerateScriptClick}
        Buttonlabel="Yes"
        Closemodal={setOpenConfirmModal}
        Content="Do you want Vidayo Pro AI engine to enhance your script?"
      /> */}
    </>
  );
}
export default ChatGpt;
