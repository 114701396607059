import { useState } from 'react';
import { checkIcon } from '../../../constants/icon';

function LabelCheckbox(props) {
    const {labelText} = props;
  const [isChecked, setIsChecked] = useState(false);
  
  const checkboxClass = isChecked ? 'active' : '';

  const toggleCheckbox = props => {
    setIsChecked(!isChecked);
  };
  return (
    <label className={`custom-label-checkbox ${checkboxClass} ${props.className || ''}`}>
      <div className="input-wrap">
        <input type="checkbox" checked={isChecked} onChange={toggleCheckbox} />
        <span className="input-icon">{isChecked ? <>{checkIcon}</> : ''}</span>
        {labelText && <span className="label-text">{labelText}</span>}
      </div>
    </label>
  );
}
export default LabelCheckbox;
