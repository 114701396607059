import { useState } from 'react';

import PageLoader from '../../services/pageLoader';
import { closeIcon } from '../../constants/icon';
import { uploadMediaFile } from '../../services/api.service';
import { getUserItem, showToaster } from '../../services/helper';

const IMAGE = 'image';
const VIDEO = 'video';
const AUDIO = 'audio';
const TEMPLATE = 'template';
const ILL_ICON = 'ILL_ICON';
const ILL_LOGO = 'ILL_LOGO';
const ILLUSTRATION = 'illustration';

export default function UploadAsset(props) {
  const { selectAsset, type } = props;

  const [fontName, setFontName] = useState();
  const [imageFile, setImageFile] = useState('');
  const [pageLoad, setPageLoad] = useState(false);
  const [uploadOption, setUploadOption] = useState();
  const [privateOption, setPrivateOption] = useState(1);
  const [uploadedAsset, setUploadedAsset] = useState('');
  const [uploadErroMessage, setUploadErroMessage] = useState('');

  const handleUploadOptions = value => {
    setImageFile('');
    uploadOption(value);
    setUploadErroMessage('');
  };

  const assestUpload = event => {
    console.log({ ddd: event.target.files[0] });
    setUploadErroMessage('');
    try {
      const imageFile = event.target.files[0];
      var types = ['image/png', 'image/jpeg', 'image/jpg'];
      if (uploadOption === ILL_ICON) {
        types = ['image/vnd.microsoft.icon'];
      }
      if (type === AUDIO) {
        types = ['application/x-font-ttf'];
      }
      if (type === VIDEO) {
        types = ['video/mp4', 'video/x-m4v', 'video/*'];
      }
      if (imageFile) {
        if (uploadOption === ILL_ICON && imageFile.name.split('.').pop() !== 'ico') {
          setUploadErroMessage('Please upload a icon');
        } else if (type === IMAGE && types.every(type => imageFile['type'] !== type)) {
          setUploadErroMessage('Please upload a image');
        } else if (type === TEMPLATE && imageFile.name.split('.').pop() !== 'ntp') {
          setUploadErroMessage('Please upload a ntp file');
        } else if (type === AUDIO && imageFile.name.split('.').pop() !== 'ttf') {
          setUploadErroMessage('Please upload a ttf file');
        } else if (uploadOption === ILL_LOGO && types.every(type => imageFile['type'] !== type)) {
          setUploadErroMessage('Please upload a logo');
        } else if (type === VIDEO && types.every(type => imageFile['type'] !== type)) {
          setUploadErroMessage('Please upload a video');
        } else if (event.target.files.length !== 1) {
          setUploadErroMessage('Supports only single file upload');
        } else if (imageFile['size'] > 26214400) {
          setUploadErroMessage('Uploaded file should not exceed 25 mb');
        } else {
          if (type === IMAGE) {
            setImageFile(imageFile);
            setUploadedAsset('../../../assets/img/fallbackImage.jpg');
            getBase64(imageFile);
          } else if (type === VIDEO) {
            setImageFile(imageFile);
            setUploadedAsset('../../../assets/img/videobutton.png');
          } else if (type === AUDIO) {
            setImageFile(imageFile);
            setFontName(imageFile.name);
            setUploadedAsset('../../../assets/img/font.png');
          } else {
            setImageFile(imageFile);
            setUploadedAsset('../../../assets/img/fallbackImage.jpg');
            getBase64(imageFile);
          }
        }
      } else {
        setUploadErroMessage('Please upload a image');
      }
    } catch (error) {
      console.log({ assestUploadError: error });
      setUploadErroMessage('Something went wrong. Please try again');
    }
  };

  const getBase64 = file => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          setUploadedAsset(reader.result);
        };
      }
    };

    reader.onerror = error => {
      console.log({ getBase64Error: error });
    };
  };

  const uploadToRepo = () => {
    if (uploadOption === ILL_ICON) {
      const icon_Flag = document.getElementById('icon_Flag').value;

      if (icon_Flag) {
        if (imageFile) {
          setPageLoad(true);

          try {
            let data = new FormData();
            const config = {
              timeout: 1000 * 50000,
              headers: {
                media_type: 'ICON',
                tag_names: icon_Flag,
                private: privateOption,
                // user_id: getUserItem("id"),
                file_name: Date.now() + '.ico',
                'content-type': 'multipart/form-data',
              },
            };
            data.append('file', imageFile);
            uploadMediaFile(data, config).then(response => {
              try {
                if (response.data) {
                  document.getElementById('icon_Flag').value = '';
                  setImageFile('');
                  setPageLoad(false);
                  setUploadErroMessage('');
                  selectAsset(response.data);
                  showToaster('Icon successfully uploaded', 'success');
                } else if (response.message) {
                  setPageLoad(false);
                  setUploadErroMessage(response.message);
                } else {
                  setPageLoad(false);
                  setUploadErroMessage('Something went wrong. Please Try Again');
                }
              } catch (error) {
                setPageLoad(false);
                setUploadErroMessage('Something went wrong. Please Try Again');
              }
            });
          } catch (error) {
            setPageLoad(false);
            setUploadErroMessage('Something went wrong. Please Try Again');
          }
        }
      } else {
        document.getElementById('icon_Flag').focus();
        setUploadErroMessage('Please enter icon tag name');
      }
    } else if (type === AUDIO) {
      const font_family = document.getElementById('font_family').value;
      if (font_family) {
        if (imageFile) {
          setPageLoad(true);
          try {
            let data = new FormData();
            const config = {
              timeout: 1000 * 50000,
              headers: {
                font_family,
                media_type: 'FONT',
                // user_id: getUserItem('id'),
                file_name: imageFile.name,
                'content-type': 'multipart/form-data',
              },
            };
            data.append('file', imageFile);

            uploadMediaFile(data, config).then(response => {
              try {
                if (response.data) {
                  document.getElementById('font_family').value = '';
                  props.close();
                  setPageLoad(false);
                  showToaster('Font successfully uploaded', 'success');
                } else if (response.message) {
                  setPageLoad(false);
                  setUploadErroMessage(response.message);
                } else {
                  setPageLoad(false);
                  setUploadErroMessage('Something went wrong. Please Try Again');
                }
              } catch (error) {
                setPageLoad(false);
                setUploadErroMessage('Something went wrong. Please Try Again');
              }
            });
          } catch (error) {
            setPageLoad(false);
            setUploadErroMessage('Something went wrong. Please Try Again');
          }
        }
      } else {
        document.getElementById('font_family').focus();
        setUploadErroMessage('Please enter tag name');
      }
    } else if (uploadOption === ILL_LOGO) {
      const logo_Flag = document.getElementById('logo_Flag').value;

      if (logo_Flag) {
        if (imageFile) {
          setPageLoad(true);
          try {
            let data = new FormData();
            const config = {
              timeout: 1000 * 50000,
              headers: {
                media_type: 'LOGO',
                tag_names: logo_Flag,
                private: privateOption,
                file_name: imageFile.name,
                // user_id: getUserItem('id'),
                'content-type': 'multipart/form-data',
              },
            };

            data.append('file', imageFile);

            uploadMediaFile(data, config).then(response => {
              try {
                if (response.data) {
                  document.getElementById('logo_Flag').value = '';
                  setPageLoad(false);
                  setUploadedAsset('');
                  setUploadErroMessage('');
                  selectAsset(response.data);
                  showToaster('Logo successfully uploaded', 'success');
                } else if (response.message) {
                  setPageLoad(false);
                  setUploadErroMessage(response.message);
                } else {
                  setPageLoad(false);
                  setUploadErroMessage('Something went wrong. Please Try Again');
                }
              } catch (error) {
                setPageLoad(false);
                setUploadErroMessage('Something went wrong. Please Try Again');
              }
            });
          } catch (error) {
            setPageLoad(false);
            setUploadErroMessage('Something went wrong. Please Try Again');
          }
        }
      } else {
        document.getElementById('logo_Flag').focus();
        setUploadErroMessage('Please enter tag name');
      }
    } else if (type === TEMPLATE) {
      if (imageFile) {
        setPageLoad(true);
        try {
          let data = new FormData();
          const config = {
            timeout: 1000 * 50000,
            headers: {
              media_type: 'NKTEMPLATE',
              // user_id: getUserItem('id'),
              file_name: imageFile.name,
              template_type: 'motion_graphics',
              'content-type': 'multipart/form-data',
            },
          };
          data.append('file', imageFile);

          uploadMediaFile(data, config).then(response => {
            try {
              if (response.data) {
                document.getElementById('logo_Flag').value = '';
                setPageLoad(false);
                setUploadedAsset('');
                setUploadErroMessage('');
                selectAsset(response.data);
                showToaster('Template successfully uploaded', 'success');
              } else if (response.message) {
                setPageLoad(false);
                setUploadErroMessage(response.message);
              } else {
                setPageLoad(false);
                setUploadErroMessage('Something went wrong. Please Try Again');
              }
            } catch (error) {
              setPageLoad(false);
              setUploadErroMessage('Something went wrong. Please Try Again');
            }
          });
        } catch (error) {
          setPageLoad(false);
          setUploadErroMessage('Something went wrong. Please Try Again');
        }
      }
    } else if (type === VIDEO) {
      const video_Flag = document.getElementById('video_Flag').value;
      if (video_Flag) {
        if (imageFile) {
          setPageLoad(true);
          try {
            let data = new FormData();
            const config = {
              timeout: 1000 * 50000,
              headers: {
                media_type: 'VIDEO',
                tag_names: video_Flag,
                private: privateOption,
                file_name: imageFile.name,
                // user_id: getUserItem('id'),
                'content-type': 'multipart/form-data',
              },
            };
            data.append('file', imageFile);

            uploadMediaFile(data, config).then(response => {
              try {
                if (response.data) {
                  document.getElementById('video_Flag').value = '';
                  setPageLoad(false);
                  setUploadedAsset('');
                  selectAsset(response.data);
                  showToaster('Video successfully uploaded', 'success');
                } else if (response.message) {
                  setPageLoad(false);
                  setUploadErroMessage(response.message);
                } else {
                  setPageLoad(false);
                  setUploadErroMessage('Something went wrong. Please Try Again');
                }
              } catch (error) {
                setPageLoad(false);
                setUploadErroMessage('Something went wrong. Please Try Again');
              }
            });
          } catch (error) {
            setPageLoad(false);
            setUploadErroMessage('Something went wrong. Please Try Again');
          }
        }
      } else {
        document.getElementById('video_Flag').focus();
        setUploadErroMessage('Please enter tag name');
      }
    } else {
      if (imageFile) {
        let header = '';
        if (type === IMAGE) {
          header = {
            media_type: 'IMAGE',
            private: privateOption,
            file_name: imageFile.name,
            user_id: getUserItem('id'),
            'content-type': 'multipart/form-data',
          };
        }
        setPageLoad(true);
        try {
          let data = new FormData();
          const config = {
            headers: header,
            timeout: 1000 * 50000,
          };
          data.append('file', imageFile);

          uploadMediaFile(data, config).then(response => {
            try {
              if (response.data) {
                if (type === IMAGE) {
                  setPageLoad(false);
                  setUploadedAsset('');
                  selectAsset(response.data);
                  showToaster('Image successfully uploaded', 'success');
                }
              } else if (response.message) {
                setPageLoad(false);
                setUploadErroMessage(response.message);
              } else {
                setPageLoad(false);
                setUploadErroMessage('Something went wrong. Please Try Again');
              }
            } catch (error) {
              setPageLoad(false);
              setUploadErroMessage('Something went wrong. Please Try Again');
            }
          });
        } catch (error) {
          setPageLoad(false);
          setUploadErroMessage('Something went wrong. Please Try Again');
        }
      }
    }
  };

  const handleClose = () => {
    setFontName('');
    setImageFile('');
    setUploadedAsset('');
  };

  return (
    <>
      <PageLoader showLoader={pageLoad} />
      <div
        id="cm-upload"
        role="tabpanel"
        aria-labelledby="heading-tab"
        className="tab-pane show active"
      >
        <div className="text-right">
          {type === AUDIO && (
            <div className="font-close" onClick={() => props.close(false)}>
              {closeIcon}
            </div>
          )}
        </div>

        {type === ILLUSTRATION ? (
          <div className="stok-selct">
            <label className="templt-radio-holder">
              <label className="custom-radio">
                <input
                  name="q1"
                  type="radio"
                  checked={uploadOption === ILL_ICON}
                  onChange={() => handleUploadOptions(ILL_ICON)}
                />
                <span className="checkmark"></span>
              </label>
              <span className="templt-label">Icons</span>
            </label>
            <label className="templt-radio-holder">
              <label className="custom-radio">
                <input
                  name="q1"
                  type="radio"
                  checked={uploadOption === ILL_LOGO}
                  onChange={() => handleUploadOptions(ILL_LOGO)}
                />
                <span className="checkmark"></span>
              </label>
              <span className="templt-label">Logos</span>
            </label>
          </div>
        ) : null}

        {uploadOption === ILL_ICON ? (
          <div className="d-flex align-items-center justify-content-center defut-enter-blk">
            <div className="defut-label-holder">
              <label>Tag Name :</label>
            </div>
            <div className="defut-input-holder">
              <input
                type="text"
                id="icon_Flag"
                placeholder="Enter Icon tag name"
                onChange={() => setUploadErroMessage('')}
              />
            </div>
          </div>
        ) : uploadOption === ILL_LOGO ? (
          <div className="d-flex align-items-center justify-content-center defut-enter-blk">
            <div className="defut-label-holder">
              <label>Tag Name :</label>
            </div>
            <div className="defut-input-holder">
              <input
                type="text"
                id="logo_Flag"
                placeholder="Enter Logo tag name"
                onChange={() => setUploadErroMessage('')}
              />
            </div>
          </div>
        ) : null}
        {type === VIDEO ? (
          <div className="d-flex align-items-center justify-content-center defut-enter-blk mt-2">
            <div className="defut-label-holder">
              <label>Tag Name :</label>
            </div>
            <div className="defut-input-holder">
              <input
                type="text"
                id="video_Flag"
                placeholder="Enter Video tag name"
                onChange={() => setUploadErroMessage('')}
              />
            </div>
          </div>
        ) : null}

        {type === AUDIO ? (
          <div className="d-flex align-items-center justify-content-center defut-enter-blk">
            <div className="defut-label-holder">
              <label>Tag Name :</label>
            </div>
            <div className="defut-input-holder">
              <input
                type="text"
                id="font_family"
                placeholder="Enter Font tag name"
                onChange={() => setUploadErroMessage('')}
              />
            </div>
          </div>
        ) : null}
        {type !== AUDIO && (
          <div className="stok-selct">
            <label className="templt-radio-holder">
              <label className="custom-radio">
                <input
                  name="q11"
                  type="radio"
                  checked={privateOption === 1}
                  onChange={() => setPrivateOption(1)}
                />
                <span className="checkmark"></span>
              </label>
              <span className="templt-label">Private</span>
            </label>
            <label className="templt-radio-holder">
              <label className="custom-radio">
                <input
                  type="radio"
                  name="q11"
                  checked={privateOption === 0}
                  onChange={() => setPrivateOption(0)}
                />
                <span className="checkmark"></span>
              </label>
              <span className="templt-label">Public</span>
            </label>
          </div>
        )}
        <div className="aspt-blk">
          <div className={type === AUDIO ? 'aspt-upload-holder w-100' : 'aspt-upload-holder'}>
            <div className="aspt-container">
              {!uploadedAsset ? (
                <div className="cc-csv-btn">
                  <label className="btn btn-primary b-logo-up">
                    {type === IMAGE
                      ? 'Upload your image'
                      : uploadOption === ILL_ICON
                      ? 'Upload your icon'
                      : uploadOption === ILL_LOGO
                      ? 'Upload your logo'
                      : type === VIDEO
                      ? 'Upload your video'
                      : type === TEMPLATE
                      ? 'Upload your template'
                      : type === AUDIO
                      ? 'Upload your Font'
                      : ''}

                    <input
                      type="file"
                      id="assestUpload"
                      className="d-none"
                      onChange={assestUpload}
                      accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG,.ico,.ICO,.ntp,.NTP,.ttf,.mp4,.MP4"
                    />
                  </label>
                </div>
              ) : (
                <label className="cover-image">
                  <img src={uploadedAsset} alt="uploadedImag" className="object-cover" />
                </label>
              )}

              {type === AUDIO ? <div className="img-file-format">{fontName}</div> : null}
            </div>
          </div>
          {uploadedAsset && (
            <div className="upload-controls d-flex text-center my-2 align-items-center justify-content-center">
              <button
                type="button"
                onClick={() => uploadToRepo()}
                className="btn btn-primary btn-sm me-2 pro-submt-btn border-0

"
              >
                Save
              </button>
              <button
                type="button"
                onClick={handleClose}
                className="btn btn-danger me-2 btn-sm pro-submt-btn"
              >
                Close
              </button>
            </div>
          )}

          <div className="img-file-spec">
            <div className="mt-3 text-center red-text error-msg">{uploadErroMessage}</div>
            {uploadOption === ILL_ICON ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be ico and File size is less than 25 mb
              </div>
            ) : type === TEMPLATE ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be ntp and File size is less than 25 mb
              </div>
            ) : type === AUDIO ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be ttf and File size is less than 25 mb
              </div>
            ) : type === IMAGE ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be jpeg, jpg, png and File size is less than 25 mb
              </div>
            ) : type === VIDEO ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be mp4 and File size is less than 25 mb
              </div>
            ) : (
              <div className="cm-style-spec mt-3 text-center">
                File format should be jpeg, jpg, png and File size is less than 25 mb
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
