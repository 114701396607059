import { useState } from 'react';

import { templateName } from '../services/helper';
import ViewModal from '../components/editProject/viewModal';
import CarouselItem from '../components/carousel/carouselItem';
import Videoplayer from '../modules/components/Videoplayer/Videoplayer';
import { checkBoxIcon, checkIcon, eyeOpenIcon } from '../constants/icon';

export default function useShowTemplates({ selectTemplate }) {
  const [openTemplateView, setOpenTemplateView] = useState('');

  const carouselItem = (template, selectedTemplate) => {
    return (
      <CarouselItem
        key={template._id}
        carouselImage={template.thumbnailUrl}
        isActive={selectedTemplate === template.mediaUrl}
      >
        <div className="check-video">{checkBoxIcon}</div>
        <div className="carousel-icon-wrap">
          <span
            role="button"
            className="image-view"
            onClick={() =>
              setOpenTemplateView({
                previewUrl: template.previewUrl,
                mediaUrl: template.mediaUrl,
              })
            }
          >
            {eyeOpenIcon}
          </span>
          <span
            role="button"
            className="image-edit"
            onClick={() => selectTemplate(template.mediaUrl)}
          >
            {checkIcon}
          </span>
        </div>
      </CarouselItem>
    );
  };

  const showTemplates = ({ recommendedTemplates, selectedTemplate, withInnerDiv }) => {
    return recommendedTemplates.map(template => {
      if (withInnerDiv) {
        return <div key={template._id}>{carouselItem(template, selectedTemplate)}</div>;
      }

      return carouselItem(template, selectedTemplate);
    });
  };

  const viewModal = () => {
    return (
      <ViewModal
        open={!!openTemplateView}
        onHide={() => setOpenTemplateView('')}
        modalTitle={`TEMPLATE ID: ${templateName(openTemplateView.mediaUrl)}`}
      >
        <Videoplayer videoUrl={openTemplateView.previewUrl} showVolume={false} />
      </ViewModal>
    );
  };

  return { showTemplates, viewModal };
}
