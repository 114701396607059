
import Header from "../../components/header/header";
import React, { useState, lazy, Suspense, useEffect, useCallback } from "react";
import { useHistory, useRouteMatch, Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCategory } from "../../../services/api.service";
import {
    showToaster,
    ShowAlertBox,
    getUserItem,
} from "../../../services/helper";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Tooltip from '@mui/material/Tooltip';
import { Modal, ModalBody } from "reactstrap";
import PageLoader from "../../../services/pageLoader";
import CustomModal from "../../../services/CustomModal";
export default function Category() {
    let DisplayMode = useSelector((state) => state.project.DisplayMode);
    const [listCategory, setListCategory] = useState([])
    const [categoryList, setCategoryList] = useState([]);
    const [pageLoad, setPageLoad] = useState(false);
    const [openDeleteModal, setopenDeleteModal] = useState(false);
    const [openDeleteErrorModal, setopenDeleteErrorModal] = useState(false);
    const [deleteCategorytid, setdeleteCategorytid] = useState(undefined);
    const [confirmmsg, setconfirmmsg] = useState("");
    const [confirmbtnlabel, setconfirmbtnlabel] = useState("");
    const [addCategoryOpen, setAddCategoryOpen] = useState(false)
    const [editCategory, setEditCategory] = useState(false)
    const [editCategoryValue, setEditCategoryValue] = useState(undefined);
    const [editCategoryID, seteditCategoryID] = useState(undefined)
    const [categoryEditError, setCategoryEditError] = useState(false)
    let userId = getUserItem("id");
    let userName = getUserItem("name");
    let AdminRole = getUserItem("role")
    let userParentId = getUserItem("orgId");
    const history = useHistory();
    useEffect(() => {

        getCategoryDetails()

    }, []);

    const getCategoryDetails = () => {
        let userId = getUserItem('id');
        let body = {
            user_id: AdminRole== 0 ? userId : userParentId ,
            list_id: 1
        }
        let data = []
        setPageLoad(true);
        getCategory(body).then((response) => {

            try {
                if (response !== "" && response !== undefined && response !== null && response.data !== "" && response.data !== undefined && response.data !== null) {
                    console.log(listCategory, "response");
                    let ddd = response.data.list
                    setCategoryList(ddd)
                    setPageLoad(false);
                    // setListCatagory(
                    //   ddd
                    // );
                    //  let data1=data.push(response.data.list[(Object.keys(response.data.list)])
                } else {
                    setPageLoad(false);
                    showToaster('Something went wrong. Please Try Again1', 'warning');
                }
            } catch (error) {
                console.log(error);
                setPageLoad(false);
                showToaster('Something went wrong. Please Try Again3', 'warning');
            }
        })
    }
    const closeErrorModal = () => {
        setopenDeleteErrorModal(false)
    }

    const formik = useFormik({
        initialValues: {
            categoryName: '',
            // categoryname: ''
        },

        validationSchema: Yup.object().shape({
            categoryName: Yup.string()
                .trim()
                .required("Category Name is required")
                .max(50, 'Must be exactly 50 characters')
                .min(5, "Minimum 5 characters are required"),
            // categoryname: Yup.string()
            //   .trim()
            //   .required("Category Name is required")
        }),
        onSubmit: async (values) => {
            console.log(values, "values");
            setPageLoad(true)
            try {
                let userId = getUserItem('id');
                let body = {
                    user_id:  AdminRole == 0 ? userId : userParentId,
                    list_id: 2,
                    category_name: values.categoryName
                }
                getCategory(body).then((response) => {
                    try {
                        if (response !== "" && response !== undefined && response !== null && response.data !== "" && response.data !== undefined && response.data !== null) {
                            if (response.status === 200 && response.status !== null && response.status !== null) {

                                showToaster('Category Added successfully', 'success');
                                setAddCategoryOpen(false)
                                setPageLoad(false)
                                getCategoryDetails()
                                values.categoryName = ""
                                // this.setState({ openInputModal: true })
                            } else {
                                setPageLoad(false)
                                showToaster(response.message, 'warning');
                            }
                        } else if(response.status === 101) {
                            setPageLoad(false)
                            showToaster(response.message, 'warning');
                          }
                    } catch (error) {
                        setPageLoad(false)
                        showToaster('Something went wrong. Please Try Again', 'warning');
                    }
                })
            }
            catch (e) {
                setPageLoad(false)
                showToaster('Something went wrong. Please Try Again', 'warning');
            }
        },

    })


    const DeleteCategory = (category) => {
        console.log(category);

        let userId = getUserItem('id');
        let body = {
            user_id: AdminRole == 0 ? userId : userParentId,
            list_id: 4,
            category_id: category.id
        }
        let data = []
        setPageLoad(true);
        getCategory(body).then((response) => {
            try {
                if (response !== "" && response !== undefined && response !== null && response.data !== "" && response.data !== undefined && response.data !== null) {
                    console.log(response, "response");
                    setconfirmbtnlabel("");
                    setconfirmmsg("");
                    setopenDeleteModal(false);
                    setdeleteCategorytid("");
                    if (response.status !== 200 && response.status == 400) {
                        setopenDeleteErrorModal(true)
                        let message = "Category " + category.category + " is associated with " + response.data.list.length + " projects. Please permanently delete the corresponding projects and try again"
                        let confirmBtn = "OK";
                        setconfirmmsg(message);
                        setconfirmbtnlabel(confirmBtn);
                    } else {
                        getCategoryDetails()
                        showToaster("Deleted successfully", "success");
                    }
                    // let ddd = response.data.list
                    // setCategoryList(ddd)
                    setPageLoad(false);

                    // setListCatagory(
                    //   ddd
                    // );
                    //  let data1=data.push(response.data.list[(Object.keys(response.data.list)])
                } else {
                    setconfirmbtnlabel("");
                    setconfirmmsg("");
                    setopenDeleteModal(false);
                    setdeleteCategorytid("");
                    showToaster('Something went wrong. Please Try Again1', 'warning');
                }
            } catch (error) {
                console.log(error);
                setconfirmbtnlabel("");
                setconfirmmsg("");
                setopenDeleteModal(false);
                setdeleteCategorytid("");
                showToaster('Something went wrong. Please Try Again', 'warning');
            }
        })
    }

    const openAddCategory = () => {
        setAddCategoryOpen(true)
    }
    const BacktoDashboard = () => {
        history.push(`/project-list`);
    }
    const EditCategory = (value) => {

        setEditCategory(true);
        seteditCategoryID(value.id)

        setEditCategoryValue(value.category);

    }
    useEffect(() => {

        setTimeout(() => {
            setCategoryEditError(false)
        }, 4000);
    }, [categoryEditError])
    const SaveEditCategory = (value) => {
        console.log(editCategoryValue.length);
        if (editCategoryValue.length >= 50 || editCategoryValue.length < 5) {
            setCategoryEditError(true)
        } else {
            setPageLoad(true)
            setCategoryEditError(false)
            try {
                let userId = getUserItem('id');
                let body = {
                    user_id: AdminRole == 0 ? userId : userParentId,
                    list_id: 3,
                    category_id: value.id,
                    category_name: editCategoryValue
                }
                getCategory(body).then((response) => {
                    try {
                        if (response !== "" && response !== undefined && response !== null && response.data !== "" && response.data !== undefined && response.data !== null) {
                            if (response.status === 200 && response.status !== null && response.status !== null) {

                                showToaster('Category updated successfully', 'success');
                                setAddCategoryOpen(false)
                                setPageLoad(false)
                                getCategoryDetails()
                                setEditCategory(false);
                                // this.setState({ openInputModal: true })
                            } else {
                                setPageLoad(false)
                                setEditCategory(false);
                                showToaster(response.message, 'warning');
                            }
                        } else {
                            setPageLoad(false)
                            setEditCategory(false);
                            showToaster('Something went wrong. Please Try Again', 'warning');
                        }
                    } catch (error) {
                        setPageLoad(false)
                        setEditCategory(false);
                        showToaster('Something went wrong. Please Try Again', 'warning');
                    }
                })
            }
            catch (e) {
                setPageLoad(false)
                setEditCategory(false);
                showToaster('Something went wrong. Please Try Again', 'warning');
            }
        }


    }
    return (
        <React.Fragment>
            <PageLoader showLoader={pageLoad} />
            <div className={DisplayMode ? "theme dark" : "theme"}>
                {/* <div className="main-wrapper"> */}
                <Header />
                {/* </div> */}
                <div className="pb-wrapper">
                    <div className="row pb-header mb-4">

                        <div className="col-12 col-xs-12 col-sm-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="sub-head">Category</h3>
                                <div className="d-flex">
                                    <button type="button" className="btn btn-outline-primary me-3"
                                        onClick={() => {
                                            openAddCategory()
                                        }}
                                        value="" data-bs-toggle="modal" data-bs-target="#add-category">ADD CATEGORY</button>
                                    <button type="button" className="btn btn-outline-primary " onClick={() => {
                                        BacktoDashboard()
                                    }} value="">BACK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-wraper">
                        <div className="pb-tbl-blk category-blk">
                            <div className="pb-tbl-wrapper ">
                                <div className="pb-head-label text-left">Category name</div>
                                {/* <div className="pb-head-label text-center">Edit</div> */}
                                <div className="pb-head-label text-center">Delete</div>
                            </div>
                            {categoryList.length > 0 && !pageLoad ?
                                categoryList.map((data, index) => {
                                    console.log(categoryList[index]);
                                    console.log(editCategory && editCategoryID === data.id);
                                    return (
                                        <>
                                            <div className="pb-tbl-wrapper pb-data-tbl">
                                                <>
                                                    {!(editCategory && editCategoryID === data.id) ?

                                                        <div className="pb-data-label text-left" >{data.category}</div> :
                                                        <div className="pb-data-label text-center">
                                                            <input className="input-border" type="text" id="lname"
                                                                value={editCategory ? editCategoryValue : data.category}
                                                                onChange={(e) => {
                                                                    setEditCategoryValue(e.target.value)
                                                                }
                                                                }
                                                                name="lname"
                                                                readonly />
                                                        </div>

                                                    } </>
                                                {/* <div className="pb-data-label text-center">
                                                    <>
                                                    {(data.category === 'Template') ? (
                                                        <span>NA</span>
                                                    ) : !(editCategory && editCategoryID === data.id) ?
                                                            <button type="button" className="btn btn-sm btn-secondary d-inline-block m-auto"
                                                                onClick={() => {
                                                                    EditCategory(data)

                                                                }}>Edit</button> :
                                                            <>
                                                                <button type="button" onClick={() => { SaveEditCategory(data) }} className="btn btn-sm btn-success d-inline-block me-2">Save</button>
                                                                <button type="button" className="btn btn-sm btn-dark d-inline-block m-auto" onClick={() => { setEditCategory(false) }}>Cancel</button>
                                                            </>
                                                        }
                                                    </>
                                                </div> */}
                                                <div className="pb-data-label ps-3 pe-0 text-center">
                                                    {(data.category === 'Template') ? (
                                                        <span>NA</span>
                                                    ) : (
                                                    <button type="button"
                                                        // onClick={()=>{
                                                        //     DeleteCategory(data.id)

                                                        // }}
                                                        onClick={() => {
                                                            let message =
                                                                "you want to delete " +
                                                                data.category +
                                                                " ?";
                                                            let confirmBtn = "delete";

                                                            setconfirmmsg(message);
                                                            setconfirmbtnlabel(confirmBtn);
                                                            setopenDeleteModal(true);
                                                            setdeleteCategorytid(data);
                                                            //deleteOrRestoreProject(project)
                                                        }}
                                                        className="btn btn-sm btn-danger m-auto">Delete</button>
                                                    )}
                                                </div>
                                            </div>
                                            {(editCategory && editCategoryID === data.id) &&

                                                categoryEditError ? <span className="input-error-msg" >Must be exactly 50 characters and Minimum 5 characters are required </span> : ""
                                            }
                                        </>
                                    )
                                })
                                : !pageLoad && <div className="no-project-selected blink">
                                    No Category available
                            </div>}


                        </div>
                    </div>

                </div>
                <Modal
                    isOpen={addCategoryOpen}
                    fade={true}
                    centered
                    className={
                        DisplayMode
                            ? "modal-dialog  modal-dialog-centered custom-modal add-category dark"
                            : "modal-dialog  modal-dialog-centered custom-modal add-category"
                    }
                >
                    <ModalBody>
                        <PageLoader showLoader={pageLoad} />
                        <div>
                            <div className="modal-content border-0 rounded-0 ">
                                <form onSubmit={formik.handleSubmit} >
                                    <div className="modal-body">
                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                            <h3>Add Category</h3>
                                            <Tooltip title="Close" arrow>
                                                <div className="close-priview" onClick={() => {
                                                    {setAddCategoryOpen(false)} { formik.values.categoryName = "" }; { formik.errors.categoryName = false } {formik.touched.categoryName=false}
                                                }} data-bs-dismiss="modal">
                                                    <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><g data-name="close"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" /><path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" /></g></g></svg>
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">Category Name</label>
                                            <input onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.categoryName}
                                                name="categoryName" type="text" className="form-control" id="exampleFormControlInput1"
                                                placeholder="Please Enter Category Name" />
                                        </div>
                                        {formik.touched.categoryName && formik.errors.categoryName ? (
                                            <div className="input-error-msg" style={{ visibility: formik.touched.categoryName && formik.errors.categoryName ? "visible" : "hidden" }}>{formik.errors.categoryName}</div>
                                        ) : null}
                                        <div className="d-flex justify-content-end w-100 footer-btn flex-wrap">
                                            <button type="submit" className="btn btn-primary  me-2 rounded-0">Save</button>
                                            <button type="button" onClick={() => {
                                                setAddCategoryOpen(false);
                                                 { formik.values.categoryName = "" }; { formik.errors.categoryName = false } {formik.touched.categoryName=false}
                                            }} className="btn btn-outline rounded-0 modal-toggle" data-bs-dismiss="modal">Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
            <CustomModal
                isOpen={openDeleteModal}
                Closemodal={setopenDeleteModal}
                Content={confirmmsg}
                Header="Are you sure"
                Buttonlabel={confirmbtnlabel}
                Buttonclick={DeleteCategory}
                del_id={deleteCategorytid}
            />

            <CustomModal
                isOpen={openDeleteErrorModal}
                Closemodal={setopenDeleteErrorModal}
                Content={confirmmsg}
                Header="Warning Message"
                Buttonlabel={confirmbtnlabel}
                Buttonclick={closeErrorModal}
            // del_id={deleteCategorytid}
            />
        </React.Fragment>
    )
}
