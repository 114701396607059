import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { updateOSTConfig } from "../../services/authService";
import { showToaster, getUserItem } from "../../services/helper";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
export default function EditSettings(props) {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [errorStatus, setErrorStatus] = useState({});
  let userId = getUserItem("id");
  let userName = getUserItem("name");
  let AdminRole = getUserItem("role");
  let userParentId = getUserItem("orgId");

  const [configId, setConfigId] = useState(null);
  const [headingLimit, setHeadingLimit] = useState(10);
  const [paragraphLimit, setParagraphLimit] = useState(10);
  const [bulletLimit, setBulletLimit] = useState(10);
  const [moduleLimit, setModuleLimit] = useState(5);
  const [screenLimit, setScreenLimit] = useState(10);
  const [slideLimit, setSlideLimit] = useState(10);
  const [maxRenderAttempt, setMaxRenderAttempt] = useState(3);
  const [templateRenderEmail, setTemplateRenderEmail] = useState(true);

  useEffect(() => {
    if (props.selectedConfigs) {
      setConfigId(props.selectedConfigs._id);
      setHeadingLimit(props.selectedConfigs.headingLimit);
      setParagraphLimit(props.selectedConfigs.paragraphLimit);
      setBulletLimit(props.selectedConfigs.bulletLimit);
      setScreenLimit(props.selectedConfigs.screenLimit);
      setSlideLimit(props.selectedConfigs.slideLimit);
      setModuleLimit(props.selectedConfigs.moduleLimit);
      setTemplateRenderEmail(props.selectedConfigs.sendTemplateRenderEmail);
      setMaxRenderAttempt(props.selectedConfigs.maxRenderAttempt);
    }
  }, [props.selectedConfigs]);

  const saveErrorStatus = (intValue, field) => {
    setErrorStatus((status) => {
      let newStatus = { ...status };
      if (Number.isNaN(intValue) || intValue < 1 || intValue > 100) {
        newStatus[field] = true;
      } else {
        delete newStatus[field];
      }
      return { ...newStatus };
    });
  };

  const checkIsValueBetweenOneAndTen = (intValue, field) => {
    setErrorStatus((status) => {
      let newStatus = { ...status };
      if (Number.isNaN(intValue) || intValue < 1 || intValue > 10) {
        newStatus[field] = true;
      } else {
        delete newStatus[field];
      }
      return { ...newStatus };
    });
  };

  const onChange = (field, e) => {
    const value = e.target.value;
    const intValue = parseInt(value, 10);
    if (
      ["heading", "paragraph", "bullet", "screen", "slide", "module", "maxRenderAttempt"].indexOf(
        field
      ) > -1 &&
      value !== "" &&
      !/^\d+$/gm.test(value)
    ) {
      return;
    }
    switch (field) {
      case "heading":
        setHeadingLimit(value);
        saveErrorStatus(intValue, field);
        break;
      case "paragraph":
        setParagraphLimit(value);
        saveErrorStatus(intValue, field);
        break;
      case "bullet":
        setBulletLimit(value);
        saveErrorStatus(intValue, field);
        break;
      case "screen":
        setScreenLimit(value);
        saveErrorStatus(intValue, field);
        break;
      case "slide":
        setSlideLimit(value);
        saveErrorStatus(intValue, field);
        break;
      case "module":
        setModuleLimit(value);
        saveErrorStatus(intValue, field);
        break;
      case "templateEmail":
        setTemplateRenderEmail(intValue === 1);
        break;
      case "maxRenderAttempt":
        setMaxRenderAttempt(value);
        checkIsValueBetweenOneAndTen(intValue, field);
        break;
      default:
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const errorList = Object.keys(errorStatus);
    if (errorList.length > 0) {
      return;
    }
    const params = {
      _id: configId,
      headingLimit: parseInt(headingLimit, 10),
      paragraphLimit: parseInt(paragraphLimit, 10),
      bulletLimit: parseInt(bulletLimit, 10),
      screenLimit: parseInt(screenLimit, 10),
      slideLimit: parseInt(slideLimit, 10),
      moduleLimit: parseInt(moduleLimit, 10),
      maxRenderAttempt: parseInt(maxRenderAttempt, 10),
      sendTemplateRenderEmail: templateRenderEmail
    };
    try {
      const response = await updateOSTConfig(params);
      if (response.data && response.data.success) {
        props.updateConfigData({ ...params });
        props.clearSelectedConfig();
        showToaster("Updated successfully", "success");
      } else {
        console.log(response);
        showToaster("Error occured while saving", "warning");
      }
    } catch (e) {
      showToaster("Something went wrong. Please Try Again", "warning");
    }
  };

  const onCancelClick = () => {
    props.clearSelectedConfig();
    setErrorStatus({});
  };

  return (
    <Modal
      isOpen={props.selectedConfigs}
      fade={true}
      centered
      className={
        DisplayMode
          ? "modal-dialog  modal-dialog-centered custom-modal dark add-category "
          : "modal-dialog  modal-dialog-centered custom-modal  add-category"
      }
      // className="modal-dialog  modal-dialog-centered styles-modal custom-modal dark"
    >
      <ModalBody>
        <form onSubmit={onSubmit} id="login-form">
          <div className="modal-body">
            <div className="text-center mb-3">
              <h2>Edit Settings</h2>
            </div>
            <p className="text-center">
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit amet,
              consectetur{" "} */}
            </p>
            <div className="mb-3">
              <label for="" className="form-label">
                Module Limit
              </label>
              <input
                type="text"
                onChange={onChange.bind(null, "module")}
                value={moduleLimit}
                className="custom-input"
                placeholder="Enter module limit"
              />{" "}
              {errorStatus.module && (
                <div className="input-error-msg">
                  Value should be between 1 and 100
                </div>
              )}
            </div>
            <div className="mb-3">
              <label for="" className="form-label">
                Slide Limit
              </label>
              <input
                type="text"
                onChange={onChange.bind(null, "slide")}
                value={slideLimit}
                className="custom-input"
                placeholder="Enter slide limit"
              />{" "}
              {errorStatus.slide && (
                <div className="input-error-msg">
                  Value should be between 1 and 100
                </div>
              )}
            </div>
            <div className="mb-3">
              <label for="" className="form-label">
                Screen Limit
              </label>
              <input
                type="text"
                onChange={onChange.bind(null, "screen")}
                value={screenLimit}
                className="custom-input"
                placeholder="Enter screen limit"
              />{" "}
              {errorStatus.screen && (
                <div className="input-error-msg">
                  Value should be between 1 and 100
                </div>
              )}
            </div>
            <div className="mb-3">
              <label for="" className="form-label">
                Heading OST Limit
              </label>
              <input
                type="text"
                onChange={onChange.bind(null, "heading")}
                value={headingLimit}
                className="custom-input"
                placeholder="Enter heading limit"
              />{" "}
              {errorStatus.heading && (
                <div className="input-error-msg">
                  Value should be between 1 and 100
                </div>
              )}
            </div>
            <div className="mb-3">
              <label for="" className="form-label">
                Paragraph OST Limit
              </label>
              <input
                type="text"
                onChange={onChange.bind(null, "paragraph")}
                value={paragraphLimit}
                className="custom-input"
                placeholder="Enter paragraph limit"
              />{" "}
              {errorStatus.paragraph && (
                <div className="input-error-msg">
                  Value should be between 1 and 100
                </div>
              )}
            </div>
            <div className="mb-3">
              <label for="" className="form-label">
                Bullet OST Limit
              </label>
              <input
                type="text"
                onChange={onChange.bind(null, "bullet")}
                value={bulletLimit}
                className="custom-input"
                placeholder="Enter bullet limit"
              />{" "}
              {errorStatus.bullet && (
                <div className="input-error-msg">
                  Value should be between 1 and 100
                </div>
              )}
            </div>
            <div className="mb-3">
              <label for="" className="form-label">
                Send Template Render Email
              </label>
              <div className="custom-select mb-2">
                <select
                  onChange={onChange.bind(null, "templateEmail")}
                  value={templateRenderEmail ? "1" : "0"}
                  className="form-select form-field"
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label for="" className="form-label">
                Max Render Attempt
              </label>
              <input
                type="text"
                onChange={onChange.bind(null, "maxRenderAttempt")}
                value={maxRenderAttempt}
                className="custom-input"
                placeholder="Enter max render attempt"
              />{" "}
              {errorStatus.maxRenderAttempt && (
                <div className="input-error-msg">
                  Value should be between 1 and 10
                </div>
              )}
            </div>
            <div className="d-flex justify-content-end w-100 footer-btn flex-wrap mt-4">
              <button
                type="button"
                onClick={onCancelClick}
                className="btn btn-outline rounded-0 me-2"
                data-bs-dismiss="modal"
              >
                CANCEL
              </button>
              <button type="submit" className="btn btn-primary  rounded-0">
                SAVE
              </button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}
