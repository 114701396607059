import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { debounce } from "debounce";
import { publicSearch, getAssets, publicShutterStockSearch } from "../../../../services/api.service";
import ReactImageFallback from "react-image-fallback";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImagePreview from "../RightBar/ImagePriview";
import { TabContent, TabPane, Nav, NavItem } from "reactstrap";
import Tooltip from "@mui/material/Tooltip";
// import Pagination from '@mui/material/Pagination';
import Stack from "@mui/material/Stack";
import Posts from "./posts";
import Pagination from "./pagination";
import { actionCreator } from "../../../../store";
import { bindActionCreators } from "redux";
import { capitalizeFirstLetter, showToaster } from "../../../../services/helper";
export default function StockRepo(props) {
  const [pageNo, setPageNo] = useState(1);
  const [totalImage, setTotalImage] = useState(0);
  const [startPageNo, setStartPageNo] = useState(1);
  const [endPageNo, setEndPageNo] = useState(20);
  const [imagesRepo, setImagesRepo] = useState([]);
  const [SearchBoxErrorMsg, setSearchBoxErrorMsg] = useState(false);
  const [keyWord, setKeyWord] = useState("");
  const [imageRepoLoader, setImageRepoLoader] = useState(true);
  const [imageErrorMsg, setImageErrorMsg] = useState("");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [getShutterImage, setGetShuterImage] = useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState(false);
  const [searchOption, setSearchOption] = useState(13);
  const indexOfLastPost = currentPage * postsPerPage;
  // console.log("indexOfLastPost: ", indexOfLastPost);
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // console.log("indexOfFirstPost: ", indexOfFirstPost);

  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const dispatch = useDispatch();
  let { UpdatePreviewFeature } = bindActionCreators(actionCreator, dispatch);

  const [searchMode, setsearchMode] = useState(false);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  //console.log("currentPosts: ", currentPosts);
  useEffect(() => {
    getPublicStock(1, keyWord);
    // if (props.type === "image") {
    // setPosts(imagesRepo);
    // }
  }, [props.openAssetRpo]);

  // useEffect(() => {
  //   setImagesRepo([]);
  //   getSearchBoxResult();
  // }, [keyWord]);

  useEffect(() => {
    getPublicStock(1, keyWord);
  }, [startPageNo, pageNo]);

  const __serachbox = (val) => {
    try {
      setPageNo(1);
      setStartPageNo(1);
      setEndPageNo(20);
      let tags;
      tags = val.target.value;
      // console.log(tags,"tagstags");
      setSearchBoxErrorMsg(false);
      if (keyWord !== tags) {
        setKeyWord(tags);
        getSearchBoxResult(tags);
      } else {
        if (tags !== "") {
          setKeyWord(tags);
        } else {
          setKeyWord("");
        }
      }
    } catch (error) {}
  };

  const getSearchBoxResult = useCallback(
    debounce((value) => {
      try {
        setsearchMode(true);
        // this.keyWord = this.state.keyWord
        setImageRepoLoader(true);
        setImagesRepo([]);
        // if (props.type === "image") {
        //console.log("ghjhgjghjghjhgj");

        getPublicStock(1, value);
        // }
      } catch (error) {}
    }, 1000),
    [searchOption]
  );
  useEffect(() => {
    setTimeout(() => {
      setSearchBoxErrorMsg(false);
    }, 10000);
  }, [SearchBoxErrorMsg]);

  const __searchByClick = () => {
    let searchValue = document.getElementById("searchBtn1").value;
    // console.log(serachValue);
    if (searchValue === "") {
      setSearchBoxErrorMsg(true);
    } else {
      setSearchBoxErrorMsg(false);
      if (searchValue !== "") {
        setsearchMode(true);
        setImageRepoLoader(true);
        setImagesRepo([]);
        getPublicStock(1, searchValue);
        // this.setState({ imageRepoLoader: true, iconRepoLoader: true, videoRepoLoader: true, logoRepoLoader: true, iconRepo: [], imagesRepo: [], logoRepo: [], videoRepo: [], motionGraphics: [], motionGraphicsLoader: true })
      } else {
        setsearchMode(false);
        setSearchBoxErrorMsg(true);
      }
    }
  };

  const viewImagePreview = (image) => {
    // console.log(image, "imageee");
    setOpenImagePreview(true);
    setSelectedImageFile(image);
  };
  const getPublicStock = (flag, searchWord) => {
    console.log(searchOption);
    if (flag == 0) {
      setImageRepoLoader(false);
    } else {
      setImageRepoLoader(true);
    }

    let request = {
      media_type: "IMAGE",
      site_option: searchOption,
      search_term: searchWord,
      start: startPageNo,
      end: endPageNo,
      page: pageNo
    };
    publicSearch(request)
      .then((response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200 &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          if (Object.entries(response.data).length > 0) {
            let projectData = response.data;
            // if(Object.entries(response.data).length <=12){
            if(response.total_count){
              setTotalImage(response.total_count);
            }
            setPosts(projectData);
            setLoading(false);
            let projectList1;
            // if(searchMode){
            //   projectList1 = response.data
            // }else{
            projectList1 = [...imagesRepo, ...response.data];
            // }
            // let projectList = [...imagesRepo, ...projectData];

            setImagesRepo(response.data);
            // setPageLoader(false)
            setImageRepoLoader(false);
            // setImagesRepo([])
            // this.setState({ imagesRepo: projectList, imageRepoLoader: false ,progressLoader:false})
            //console.log(imagesRepo, "imgggg");
          } else {
            setImageRepoLoader(false);
            setImagesRepo([]);
            setImageErrorMsg(response.message);
            // this.setState({ imageRepoLoader: false,progressLoader:false, imagesRepo: [], imageErrorMsg: response.message })
          }
        } else if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.message !== "" &&
          response.message !== undefined &&
          response.message !== null
        ) {
          setImageRepoLoader(false);
          setImagesRepo([]);
          setImageErrorMsg("No images available");
        } else {
          setImageRepoLoader(false);
          setImageErrorMsg("No images available");
        }
      })
      .catch((error) => {
        setImageRepoLoader(false);
        setImageErrorMsg("No images available");
      });
  };
  useEffect(() => {
    getPublicStock(1, keyWord);
    setImagesRepo([]);
  }, [searchOption]);
  const SelectAsset = (image) => {
    console.log(image);
    let mediaUrl;
    let data;
    let asset = props.item[1];
    let request = {
      imageid: [image.imageid]
    };
    if (searchOption == 13) {
      setGetShuterImage(true);
      publicShutterStockSearch(request)
        .then((response) => {
          console.log(response, "activeScreenDetails");
          if (response.status === 200) {
            if (
              response !== "" &&
              response !== undefined &&
              response !== null &&
              response.status !== "" &&
              response.status !== undefined &&
              response.status !== null &&
              response.status === 200 &&
              response.data !== "" &&
              response.data !== undefined &&
              response.data !== null
            ) {
              setGetShuterImage(false);
              props.setdisplayImage(response.data.result.Images[0].mediaurl);
              props.closePreview();
            } else {
              // showToaster('Project id is missing ', 'warning');
            }
          } else if (response.status === 101) {
            showToaster(response.message, "warning");
            setGetShuterImage(false);
          } else {
            setGetShuterImage(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      props.setdisplayImage(image.mediaurl);
      props.closePreview();
    }
  };

  return (
    <React.Fragment>
      {getShutterImage ? (
        <div className="loader-wrapper video-loader">
          <div className="loader-inner">
            <img src="../../../assets/img/loader.gif"></img>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="d-flex align-items-center position-relative flex-wrap">
        <div className="srch-temp-wrapper">
          <div className="srch-holder">
            <Tooltip title="Search" arrow>
              <div className="srch-icon" onClick={() => __searchByClick()}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M21.9243 21.925L27.9994 28.0001" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </Tooltip>
            <div className="srch-inpt-holder">
              <input
                id="searchBtn1"
                onChange={(e) => __serachbox(e)}
                type="text"
                name="focus"
                required
                className="form-control srch-input"
                placeholder="Search stock images"
                value={keyWord}
              />
              <button
                className="search-close"
                type="reset"
                onClick={() => {
                  setPageNo(1);
                  setStartPageNo(1);
                  setEndPageNo(20);
                  setKeyWord("");
                  setImagesRepo([]);
                }}
              >
                {" "}
                <Tooltip title="Reset" arrow>
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    space="preserve"
                  >
                    <g>
                      <path
                        d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M327.115,365.904
                          L256,294.789l-71.115,71.115l-38.789-38.789L217.211,256l-71.115-71.115l38.789-38.789L256,217.211l71.115-71.115l38.789,38.789
                          L294.789,256l71.115,71.115L327.115,365.904z"
                      />
                    </g>
                  </svg>
                </Tooltip>
              </button>
            </div>
          </div>
        </div>
        {/* <p>
                    Upload image manually <a href="">Upload</a>
                </p> */}
      </div>

      <div className="stok-selct">
        <label className="templt-radio-holder">
          <label className="custom-radio">
            <input
              type="radio"
              disabled={imageRepoLoader}
              name="q1"
              onChange={() => setSearchOption(13)}
              onClick={() => {
                setPageNo(1);
                setStartPageNo(1);
                setEndPageNo(20);
                setImagesRepo([]);
              }}
              checked={searchOption === 13 ? true : false}
            />
            <span className="checkmark"></span>
          </label>
          <span className="templt-label">Shutterstock</span>
        </label>
        <label className="templt-radio-holder">
          <label className="custom-radio">
            <input
              type="radio"
              disabled={imageRepoLoader}
              name="q1"
              onClick={() => {
                setPageNo(1);
                setStartPageNo(1);
                setEndPageNo(20);
                setImagesRepo([]);
              }}
              onChange={() => setSearchOption(12)}
              checked={searchOption === 12 ? true : false}
            />
            <span className="checkmark"></span>
          </label>
          <span className="templt-label">Pexels</span>
        </label>
        <label className="templt-radio-holder">
          <label className="custom-radio">
            <input
              type="radio"
              disabled={imageRepoLoader}
              name="q1"
              onChange={() => setSearchOption(10)}
              onClick={() => {
                setPageNo(1);
                setStartPageNo(1);
                setEndPageNo(20);
                setImagesRepo([]);
              }}
              checked={searchOption === 10 ? true : false}
            />
            <span className="checkmark"></span>
          </label>
          <span className="templt-label">Unsplash</span>
        </label>
        <label className="templt-radio-holder">
          <label className="custom-radio">
            <input
              type="radio"
              name="q1"
              disabled={imageRepoLoader}
              onChange={() => setSearchOption(11)}
              onClick={() => {
                setPageNo(1);
                setStartPageNo(1);
                setEndPageNo(20);
                setImagesRepo([]);
              }}
              checked={searchOption === 11 ? true : false}
            />
            <span className="checkmark"></span>
          </label>
          <span className="templt-label">Pixabay</span>
        </label>
      </div>

      <div className="cm-st-gallery stock-repo">
        {/* {imagesRepo.length > 0 ? (
                    ""
                ) : (
                        <div className="v-gal-empty-blk">
                            <div className="no-txt-selected blink">
                                {capitalizeFirstLetter(imageErrorMsg)}
                            </div>
                        </div>
                    )} */}

        {imageRepoLoader === false ? (
          imagesRepo.length > 0 ? (
            <div className="cm-gallery-wrapper m-0">
              {imagesRepo.map((imagee, index) => {
                let mediaurl = "";
                // console.log(imagee,"imagee");

                if (imagee.thumbnailurl !== undefined && imagee.thumbnailurl !== null && imagee.thumbnailurl !== "") {
                  mediaurl = imagee.thumbnailurl;
                } else if (imagee.thumbnailurl === null) {
                  mediaurl = imagee.mediaurl;
                } else {
                  mediaurl = imagee;
                }
                //console.log(mediaurl, "mediaurl");

                return (
                  <div className="cm-gallery-container replace-img">
                    <div className="icon-wrap">
                      {imagee.thumbnailurl !== undefined && imagee.thumbnailurl !== null && imagee.thumbnailurl !== "" ? (
                        <span
                          onClick={() => {
                            viewImagePreview(imagee.mediaurl);
                          }}
                          className="img-view"
                          data-bs-toggle="modal"
                          data-bs-target="#view-img"
                        >
                          <Tooltip arrow>
                            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9.99889 5.64136C8.66889 5.64136 7.58789 6.72336 7.58789 8.05336C7.58789 9.38236 8.66889 10.4634 9.99889 10.4634C11.3289 10.4634 12.4109 9.38236 12.4109 8.05336C12.4109 6.72336 11.3289 5.64136 9.99889 5.64136ZM9.99889 11.9634C7.84189 11.9634 6.08789 10.2094 6.08789 8.05336C6.08789 5.89636 7.84189 4.14136 9.99889 4.14136C12.1559 4.14136 13.9109 5.89636 13.9109 8.05336C13.9109 10.2094 12.1559 11.9634 9.99889 11.9634Z"
                                fill=""
                              />
                              <mask id="mask0_2688_3325" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="17">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.000244141H19.9998V16.1052H0V0.000244141Z" fill="" />
                              </mask>
                              <g mask="url(#mask0_2688_3325)">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M1.56975 8.05251C3.42975 12.1615 6.56275 14.6045 9.99975 14.6055C13.4368 14.6045 16.5698 12.1615 18.4298 8.05251C16.5698 3.94451 13.4368 1.50151 9.99975 1.50051C6.56375 1.50151 3.42975 3.94451 1.56975 8.05251V8.05251ZM10.0017 16.1055H9.99775H9.99675C5.86075 16.1025 2.14675 13.2035 0.06075 8.34851C-0.02025 8.15951 -0.02025 7.94551 0.06075 7.75651C2.14675 2.90251 5.86175 0.00350586 9.99675 0.000505859C9.99875 -0.000494141 9.99875 -0.000494141 9.99975 0.000505859C10.0018 -0.000494141 10.0018 -0.000494141 10.0028 0.000505859C14.1388 0.00350586 17.8527 2.90251 19.9387 7.75651C20.0207 7.94551 20.0207 8.15951 19.9387 8.34851C17.8537 13.2035 14.1388 16.1025 10.0028 16.1055H10.0017Z"
                                  fill=""
                                />
                              </g>
                            </svg>
                          </Tooltip>
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            viewImagePreview(imagee);
                          }}
                          className="img-view"
                          data-bs-toggle="modal"
                          data-bs-target="#view-img"
                        >
                          {" "}
                          <Tooltip  arrow>
                            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9.99889 5.64136C8.66889 5.64136 7.58789 6.72336 7.58789 8.05336C7.58789 9.38236 8.66889 10.4634 9.99889 10.4634C11.3289 10.4634 12.4109 9.38236 12.4109 8.05336C12.4109 6.72336 11.3289 5.64136 9.99889 5.64136ZM9.99889 11.9634C7.84189 11.9634 6.08789 10.2094 6.08789 8.05336C6.08789 5.89636 7.84189 4.14136 9.99889 4.14136C12.1559 4.14136 13.9109 5.89636 13.9109 8.05336C13.9109 10.2094 12.1559 11.9634 9.99889 11.9634Z"
                                fill=""
                              />
                              <mask id="mask0_2688_3325" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="17">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.000244141H19.9998V16.1052H0V0.000244141Z" fill="" />
                              </mask>
                              <g mask="url(#mask0_2688_3325)">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M1.56975 8.05251C3.42975 12.1615 6.56275 14.6045 9.99975 14.6055C13.4368 14.6045 16.5698 12.1615 18.4298 8.05251C16.5698 3.94451 13.4368 1.50151 9.99975 1.50051C6.56375 1.50151 3.42975 3.94451 1.56975 8.05251V8.05251ZM10.0017 16.1055H9.99775H9.99675C5.86075 16.1025 2.14675 13.2035 0.06075 8.34851C-0.02025 8.15951 -0.02025 7.94551 0.06075 7.75651C2.14675 2.90251 5.86175 0.00350586 9.99675 0.000505859C9.99875 -0.000494141 9.99875 -0.000494141 9.99975 0.000505859C10.0018 -0.000494141 10.0018 -0.000494141 10.0028 0.000505859C14.1388 0.00350586 17.8527 2.90251 19.9387 7.75651C20.0207 7.94551 20.0207 8.15951 19.9387 8.34851C17.8537 13.2035 14.1388 16.1025 10.0028 16.1055H10.0017Z"
                                  fill=""
                                />
                              </g>
                            </svg>
                          </Tooltip>
                        </span>
                      )}

                      <span className="img-edit" data-bs-toggle="modal" data-bs-target="#edit-img" onClick={() => SelectAsset(imagee)}>
                        {" "}
                        <Tooltip title="Save" arrow>
                          <svg width="30px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z"
                              fill=""
                            />
                          </svg>
                        </Tooltip>
                      </span>
                    </div>
                    <ReactImageFallback
                      src={mediaurl}
                      fallbackImage="../../../assets/img/fallbackImage.jpg"
                      initialImage="../../../assets/img/fallbackImage.jpg"
                      alt="templates"
                      className="img-fluid"
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="v-gal-empty-blk">
              <div className="no-txt-selected">{capitalizeFirstLetter(imageErrorMsg)}</div>
            </div>
          )
        ) : (
          <div className="v-gal-empty-blk">
            <CircularProgress />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between mt-3 position-relative control-btns">
        {(startPageNo >= 2 || pageNo >= 2) && (
          <button
            onClick={() => {
              setPageNo(pageNo - 1);
              setStartPageNo(endPageNo - 38);
              setEndPageNo(endPageNo - 19);
            }}
            className="btn btn-primary rounded-0 me-3"
          >
            Previous
          </button>
        )}
        {(imagesRepo.length >= 19 || pageNo * 20 < totalImage) && (
          <button
            onClick={() => {
              setPageNo(pageNo + 1);
              setStartPageNo(endPageNo + 1);
              setEndPageNo(endPageNo + 19);
            }}
            className="btn btn-primary rounded-0 next-btn"
          >
            Next
          </button>
        )}
      </div>
      <ImagePreview closePreview={() => setOpenImagePreview(false)} openImagePreview={openImagePreview} selectedImage={selectedImageFile} />
    </React.Fragment>
  );
}
