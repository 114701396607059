import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';

import CardComponent from './CardComponent';
import { FONT_URL } from '../../../constants';
import useFonts from '../../../hooks/fontHook';
import BrandSettingModal from '../brandSettingModal';
import { getProjectQueryData, showToaster } from '../../../services/helper';
import FormTextarea from '../../../components/form/formTextarea';
import { longRightArrowIcon, plusIcon } from '../../../constants/icon';
import { generateProjectPreview, getProject, updateProject } from '../../../services/api.service';
import { addScreenforChatGPT, removeMediaUrlFilter } from '../../../services/DataHelper';
import { searchAssetsVideo } from '../../../services/apiServices';

function GenerateScriptComponent(props) {
  const {
    project,
    scriptContent,
    projectData,
    onScriptContentChange,
    segmentList = [],
    generatingScript,
    clientSettingData,
    handleGenerateScriptClick,
    // setOpenConfirmModal,
    disableGeneratePreview,
    setDisableGeneratePreview,
    setScreenValues,
    generatingScreenContent,
    setProjectData,
    progressPercentage,
    generatingPreviewMessage,
    deleteScreen,
    screenValues,
  } = props;

  const { fonts } = useFonts();
  const [projectImageUrl, setProjectImageUrl] = useState();
  const [showBrandingModal, setShowBrandingModal] = useState(false);

  const params = useParams();
  const [generatingPreview, setGeneratingPreview] = useState(false);
  const history = useHistory();
  const timer1Ref = useRef(null);

  useEffect(() => {
    setProjectImageUrl(project.imageUrl);
  }, [project.imageUrl]);

  const queryData = () => {
    const { from, module, slide } = getProjectQueryData();
    let moduleId = 'M01';
    let slideId = 'SL01';
    let redirectUrl = `/edit-projects/${project._id}?checkPreview=1`;

    if (from === 'edit-projects') {
      moduleId = module;
      slideId = slide;
      redirectUrl += `&from=generate-videos-inputs&module=${module}&slide=${slide}&screen=${screenValues[0]?.screenKey}`;
    }

    return { moduleId, slideId, redirectUrl };
  };

  const { moduleId, slideId, redirectUrl } = queryData();

  const handleGeneratePreview = async projectInputsData => {
    setGeneratingPreview(true);
    await updateProjectData(projectInputsData);
    setGeneratingPreview(false);
  };

  const reArrangeScreen = (index, destination) => {
    let screenDatas = JSON.parse(JSON.stringify(screenValues));
    let projectInputs = JSON.parse(JSON.stringify(projectData));
    const { from, module, slide, screen } = getProjectQueryData();
    let moduleId = 'M01';
    let slideId = 'SL01';
    let screenId = '01';

    if (from === 'edit-projects') {
      moduleId = module;
      slideId = slide;
      screenId = screen;
    }
    if (destination > index) {
      for (let i = index + 1; i <= destination; i++) {
        let screenKey = `SC${screenId}.0` + i;
        if (i >= 10) {
          screenKey = `SC${screenId}.` + i;
        }
        projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens[screenKey] =
          projectData[project.ProjectID].Modules[moduleId].slides[slideId].Screens[
            screenValues[i].screenKey
          ];
        screenDatas[i - 1] = screenValues[i];
        screenDatas[i - 1].screenKey = screenKey;
      }
    } else {
      for (let i = index - 1; i >= destination; i--) {
        let screenKey = `SC${screenId}.0` + (i + 2);
        if (i + 2 >= 10) {
          screenKey = `SC${screenId}.` + (i + 2);
        }
        projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens[screenKey] =
          projectData[project.ProjectID].Modules[moduleId].slides[slideId].Screens[
            screenValues[i].screenKey
          ];
        screenDatas[i + 1] = screenValues[i];
        screenDatas[i + 1].screenKey = screenKey;
      }
    }

    let screenKey = `SC${screenId}.0` + (destination + 1);
    if (destination >= 9) {
      screenKey = `SC${screenId}.` + (destination + 1);
    }

    projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens[screenKey] =
      projectData[project.ProjectID].Modules[moduleId].slides[slideId].Screens[
        screenValues[index].screenKey
      ];
    screenDatas[destination] = screenValues[index];
    screenDatas[destination].screenKey = screenKey;

    setProjectData(projectInputs);
    setScreenValues(screenDatas);
  };

  const updateScreenValues = value => {
    let hasTemplateForAll = true;
    let hasValidCharacters = true;
    let screenValuesData = JSON.parse(JSON.stringify(screenValues));
    for (let screenData of screenValuesData) {
      if (screenData.screenKey === value.screenKey) {
        screenData.hasTemplate = value.hasTemplate;
        screenData.ost = value.ost;
        screenData.tags = value.tags;
        screenData.validOST = value.validOST;
        screenData.audioScript = value.audioScript;
      }
      if (!screenData.hasTemplate) {
        hasTemplateForAll = false;
      }
      if (!screenData.validOST) {
        hasValidCharacters = false;
      }
    }
    setScreenValues(screenValuesData);
    setDisableGeneratePreview(!hasTemplateForAll || !hasValidCharacters);

    // setScreenValues([...screenValues,)
  };
  useEffect(() => {
    return () => {
      clearTimeout(timer1Ref.current);
    };
  }, []);

  function timeout(delay = 5000) {
    return new Promise(res => setTimeout(res, delay));
  }

  async function updateProjectData(projectInputsData) {
    try {
      let projectDataforAPI = await removeMediaUrlFilter(
        JSON.parse(JSON.stringify(projectInputsData)),
        project.ProjectID
      );
      let projectDataPayload = {
        dbdata: {
          [project.ProjectID]: projectInputsData[project.ProjectID],
        },
        APIData: {
          [project.ProjectID]: projectDataforAPI[project.ProjectID],
        },
        RenderUrl: {
          renderUrls: project.renderUrls,
        },
        type: 'Update',
      };

      let updatedProject = await updateProject(projectDataPayload);
      let projectDataforPreview = JSON.parse(updatedProject.ProjectData);
      let hasProjectDataChanged = false;
      for (const screenValue of screenValues) {
        if (screenValue.hasTemplate) {
          let activeScreen =
            projectDataforPreview[project.ProjectID].Modules[moduleId].slides[slideId].Screens[
              screenValue.screenKey
            ];
          if (
            activeScreen['VD01']?.Contentparams.contenttag &&
            !activeScreen['VD01']?.Contentparams.contentdescription
          ) {
            let videoResponse = await searchAssetsVideo({
              tags: activeScreen['VD01']?.Contentparams.contenttag,
            });
            projectDataforPreview[project.ProjectID].Modules[moduleId].slides[slideId].Screens[
              screenValue.screenKey
            ]['VD01'].Contentparams.contentdescription = videoResponse.data.result.result[0];
            hasProjectDataChanged = true;
          }
          await generatePreview(projectDataforPreview, project.ProjectID, screenValue.screenKey);
          await timeout(5000);
        }
      }
      if (hasProjectDataChanged) {
        let latestProject = await getProject(params.projectId);
        projectInputsData = JSON.parse(latestProject.ProjectData);
        projectInputsData = _.merge(projectInputsData, projectDataforPreview);
        projectDataforAPI = await removeMediaUrlFilter(
          JSON.parse(JSON.stringify(projectInputsData)),
          project.ProjectID
        );
        projectDataPayload = {
          dbdata: {
            [project.ProjectID]: projectInputsData[project.ProjectID],
          },
          APIData: {
            [project.ProjectID]: projectDataforAPI[project.ProjectID],
          },
          RenderUrl: {
            renderUrls: project.renderUrls,
          },
          type: 'Update',
        };

        let updatedProject = await updateProject(projectDataPayload);
      }
      history.push(redirectUrl);
    } catch (error) {
      console.log({ updateProjectDataError: error });
      showToaster(
        'Our Servers seems to be busy. Please click the Generate Preview again',
        'error',
        5000
      );
    }
  }

  const handleAddScreen = async () => {
    if (screenValues.length < clientSettingData.screenLimit) {
      const { from, module, slide, screen } = getProjectQueryData();
      let moduleId = 'M01';
      let slideId = 'SL01';
      let screenId = '01';

      if (from === 'edit-projects') {
        moduleId = module;
        slideId = slide;
        screenId = screen;
      }
      let screenKey = `SC${screenId}.0${screenValues.length + 1}`;
      let title = `Screen 0${screenValues.length + 1}`;
      if (screenValues.length + 1 >= 10) {
        screenKey = `SC${screenId}.${screenValues.length + 1}`;
        title = `Screen ${screenValues.length + 1}`;
      }
      let projectInputs = JSON.parse(JSON.stringify(projectData));
      projectInputs = await addScreenforChatGPT(
        project.ProjectID,
        projectInputs,
        screenKey,
        title,
        '',
        '',
        moduleId,
        slideId
      );
      setScreenValues([
        ...screenValues,
        {
          ost: '',
          tags: '',
          title,
          hasTemplate: false,
          validOST: true,
          screenKey: screenKey,
          audioScript: '',
        },
      ]);
      setProjectData(projectInputs);
      setDisableGeneratePreview(true);
    }
  };

  const generatePreview = async (projectData, ProjectID, activeScreen) => {
    try {
      const previewContent = {
        [ProjectID]: {
          ProjectName: project.ProjectName,
          Modules: {
            [moduleId]: {
              ModuleName: projectData[ProjectID]?.['Modules']?.[moduleId]?.['ModuleName'],
              slides: {
                [slideId]: {
                  SlideName:
                    projectData[ProjectID]?.['Modules']?.[moduleId]?.['slides'][slideId]?.[
                      'SlideName'
                    ],
                  SlideAudioVO: 'Null',
                  Screens: {
                    [activeScreen]: {
                      ScreenName:
                        projectData[ProjectID]?.['Modules']?.[moduleId]?.['slides']?.[slideId]?.[
                          'Screens'
                        ]?.[activeScreen]?.['ScreenName'],
                      ...projectData?.[ProjectID]?.['Modules']?.[moduleId]?.['slides']?.[slideId]?.[
                        'Screens'
                      ]?.[activeScreen],
                    },
                  },
                },
              },
            },
          },
        },
      };

      await generateProjectPreview(previewContent);
    } catch (error) {
      console.log(error);
    }
  };

  const singleScreenSettings = settings => {
    console.log({ settings });
    const projectInputs = JSON.parse(JSON.stringify(projectData));

    const { bgColor, textColor, fontStyle, fontFamily } = settings;

    const projectScreens =
      projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens;

    Object.entries(projectScreens).map(([screenKey, screenValue]) => {
      Object.entries(screenValue).map(([screenValueKey, screenValueValue]) => {
        if (screenValueValue.Contenttype === 'OST') {
          screenValueValue.Contentparams.FontFamily = FONT_URL + fontFamily;

          if (fontStyle) {
            screenValueValue.Contentparams.FontFamily =
              screenValueValue.Contentparams.FontFamily + '/' + fontStyle;
          }

          screenValueValue.Contentparams.FontColour = textColor;
        }

        if (screenValueKey === 'CS01') {
          screenValueValue.Contentparams.contentdescription = bgColor;
        }
      });
    });

    handleGeneratePreview(projectInputs);
  };

  return (
    <>
      <Row className="screen-wrapper">
        <Col lg={8}>
          <div className="screen-body screen-chat-body">
            <FormTextarea
              id="message"
              placeholder=""
              label="Write script for screen"
              className="chat-gpt-textarea"
              inputValue={scriptContent}
              maxLength={4000}
              showInputLength={true}
              onChange={onScriptContentChange}
            />
            <div className="chat-generate-wrap">
              <span className="characters-count">{scriptContent.length}/4000 characters</span>
              <button
                className={
                  generatingScreenContent || generatingPreview
                    ? ' btn primary-btn disabled'
                    : 'primary-btn'
                }
                disabled={generatingScreenContent || generatingPreview}
                onClick={handleGenerateScriptClick}
              >
                {generatingScript ? (
                  <>
                    Updating
                    <Spinner animation="border" style={{ color: '#6750a4' }} size="sm" />
                  </>
                ) : (
                  <>Update {longRightArrowIcon}</>
                )}
              </button>
            </div>
            {generatingScreenContent && (
              <div className="generate-progress-wrap">
                <div className="generate-title">{generatingPreviewMessage}</div>
                <div className="screen-size">Progress Percent {progressPercentage}</div>
                <div className="loader-line m-0"></div>
              </div>
            )}
          </div>
        </Col>
        <Col lg={4} className="screen-sidebar">
          <div className="chat-title-wrap">
            <h4>
              <strong>Title:</strong> {project.ProjectName}
            </h4>
            <span>
              <strong>Total:</strong> {Object.keys(segmentList).length} Screen(s)
            </span>
          </div>
          <div className="screen-sidebar-body pe-2">
            {screenValues.map((screenData, index) => {
              return (
                <CardComponent
                  key={index}
                  index={index}
                  title={screenData.title}
                  screenId={screenData.screenKey}
                  ost={screenData.ost}
                  tags={screenData.tags}
                  validOST={screenData.validOST}
                  audioScript={screenData.audioScript}
                  hasTemplate={screenData.hasTemplate}
                  generatingScreenContent={generatingScreenContent}
                  projectData={projectData}
                  setProjectData={setProjectData}
                  handleScreenValueChange={updateScreenValues}
                  project={project}
                  deleteScreen={deleteScreen}
                  length={screenValues.length}
                  reArrangeScreen={reArrangeScreen}
                />
              );
            })}
            {screenValues.length < clientSettingData.screenLimit && (
              <button
                className={
                  generatingScreenContent
                    ? 'disabled triiger-btn tag-content-btn'
                    : 'triiger-btn tag-content-btn'
                }
                onClick={handleAddScreen}
                disabled={generatingScreenContent}
              >
                <span className="upload-icon">{plusIcon}</span>
                <span>Add New Screen</span>
              </button>
            )}
          </div>
          <div className="chat-generate-wrap justify-content-end">
            <button
              className={disableGeneratePreview ? ' btn primary-btn disabled' : 'primary-btn'}
              onClick={() => setShowBrandingModal(true)}
              disabled={disableGeneratePreview || generatingScreenContent || generatingPreview}
            >
              {generatingPreview ? (
                <>
                  Generating preview
                  <Spinner animation="border" style={{ color: '#6750a4' }} size="sm" />
                </>
              ) : (
                <>Generate preview {longRightArrowIcon}</>
              )}
            </button>
          </div>
        </Col>
      </Row>
      <BrandSettingModal
        fonts={fonts}
        settingForSingleScreen
        show={showBrandingModal}
        imageUrl={projectImageUrl}
        onHide={() => setShowBrandingModal(false)}
        setImageUrl={url => setProjectImageUrl(url)}
        singleScreenSettings={singleScreenSettings}
      />
    </>
  );
}
export default GenerateScriptComponent;
