import Modal from 'react-bootstrap/Modal';

export default function ViewModal({ modalTitle, children, open, onHide, size = 'xl' }) {
  return (
    <Modal show={open} centered size={size} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        {modalTitle && <Modal.Title>{modalTitle}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}
