import React from 'react';

import Sidebar from '../../../components/sidebar';
import HeaderNavigation from '../../../components/headerNavigation';
import SecondaryTemplateHeader from './secondaryTemplateHeader';
import TemplateTable from './templateTable';
import StatusReportHeader from './statusReportHeader';

function Template() {
  return (
    <>
      <Sidebar />
      <main className="main-content-wrapper">
        <div className="content-wrapper">
          <HeaderNavigation />
          <StatusReportHeader />
          <SecondaryTemplateHeader />
          <TemplateTable />
        </div>
      </main>
    </>
  );
}
export default Template;
