import { useEffect, useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import * as Yup from 'yup';
import { Checkbox } from '@mui/material';
import { useFormik } from 'formik';
import timezones from 'timezones-list';
import Select from 'react-select';

// Icons
import { crossIcon } from '../../../../constants/icon';
import { createUser } from '../../../../services/apiServices';
import { showToaster } from '../../../../services/helper';
import FormInputGroup from '../../../login/components/FormInputGroup';

function AddNewUserModal(props) {
  const { fetchUserList,...rest } = props;
  const [loading, setLoading] = useState(false);
  const [listtimezone, setListtimezone] = useState();
  const [timeZones, setTimeZones] = useStateWithCallbackLazy();
  const [errorMessage, setErrorMessage] = useState('');
  const [informUser, setInformUser] = useState(false);

  useEffect(() => {
    generateTimeZones();
  }, []);

  const generateTimeZones = () => {
    let timeZoneOptions = [];
    timezones.map(font => {
      timeZoneOptions.push({
        value: font.tzCode,
        label: font.label,
      });
      return true;
    });
    setTimeZones(timeZoneOptions);
  };

  const handleChange = async e => {
    setListtimezone(e);
  };

  const userFormik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      fullName: Yup.string()
        .trim()
        .max(20, 'Must be exactly 20 characters')
        .min(1, 'Minimum 1 characters are required')
        .required('Name is required'),
      email: Yup.string()
        .trim()
        .email('Email is invalid')
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Email is required'),
      password: Yup.string()
        .trim()
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special Character'
        )
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
    }),
    onSubmit: async values => {
      let fullName = values.fullName;
      let [first_name, ...last_name] = fullName.split(' ').filter(Boolean);
      values.firstName = first_name;
      values.lastName = last_name.join(' ');
      values.timeZone = listtimezone?.value ?? '';
      values.sendEmail = informUser;
      try {
        setLoading(true);
        createUser(values)
          .then(response => {
            if (response?.data?.result) {
              values.firstName = '';
              values.lastName = '';
              values.email = '';
              values.password = '';
              values.confirmPassword = '';
            } else {
              setErrorMessage(response.message);
            }
          })
          .catch(e => {
            console.log(e, 'error');
          })
          .finally(() => {
            setLoading(false);
            props.onHide();
            fetchUserList()
          });
      } catch (e) {
        showToaster('Something went wrong. Please Try Again', 'warning');
      }
    },
  });

  return (
    <Modal
      {...rest}
      className="add-files-modal custom-modal-right"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={userFormik.handleSubmit} id="user-form">
        <Modal.Body>
          <div
            className="modal-close-btn"
            title="Close"
            data-bs-dismiss="modal"
            onClick={props.onHide}
          >
            {crossIcon}
          </div>
          <h2>Add User</h2>
          <FormInputGroup
            type="text"
            id="fullName"
            placeholder=""
            label="Full Name"
            asterisk
            className="custom-form-none-icon mb-4"
            onChange={userFormik.handleChange}
            value={userFormik.values.fullName}
            formik={userFormik}
          />
          {userFormik.errors.fullName ? (
            <div className="input-error-msg mb-3">{userFormik.errors.fullName}</div>
          ) : null}
          <FormInputGroup
            type="email"
            id="email"
            placeholder=""
            label="Email ID"
            asterisk
            className="custom-form-none-icon mb-4"
            onChange={userFormik.handleChange}
            value={userFormik.values.email}
            formik={userFormik}
          />
          {userFormik.errors.email ? (
            <div className="input-error-msg mb-3">{userFormik.errors.email}</div>
          ) : null}
          <Row>
            <Col lg={6}>
              <FormInputGroup
                type="password"
                id="password"
                placeholder=""
                label="Password"
                asterisk
                className="custom-form-none-icon mb-4"
                onChange={userFormik.handleChange}
                value={userFormik.values.password}
                formik={userFormik}
              />
              {userFormik.errors.password ? (
                <div className="input-error-msg mb-3">{userFormik.errors.password}</div>
              ) : null}
            </Col>

            <Col lg={6}>
              <FormInputGroup
                type="password"
                id="confirmPassword"
                placeholder=""
                label="Confirm Password"
                asterisk
                className="custom-form-none-icon mb-4"
                onChange={userFormik.handleChange}
                value={userFormik.values.confirmPassword}
                formik={userFormik}
              />
              {userFormik.errors.confirmPassword ? (
                <div className="input-error-msg mb-3">{userFormik.errors.confirmPassword}</div>
              ) : null}
            </Col>
          </Row>
          <Select
            className="custom-input rolluser w-100 org-rolleruser custom-form-floating custom-timezone "
            value={listtimezone}
            onChange={e => handleChange(e)}
            options={timeZones}
            placeholder="Select timezones"
          />
          {errorMessage.length ? <div className="input-error-msg mb-3">{errorMessage}</div> : null}
          <Checkbox
            color="primary"
            checked={informUser}
            onChange={e => setInformUser(e.target.checked)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />{' '}
          Notify user
        </Modal.Body>
        <Modal.Footer className="gap-4">
          <Button className="outline-primary-btn" onClick={props.onHide}>
            Cancel
          </Button>
          <button type="submit" className="bg-btn-primary" disabled={loading}>
            {loading ? 'Adding...' : 'Add user'}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
export default AddNewUserModal;
