import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';

import { crossIcon } from '../../../constants/icon';
import { showToaster } from '../../../services/helper';
import CustomModal from '../../../services/CustomModal';
import { uploadFile } from '../../../services/api.service';

export default function CustomFileUpload(props) {
  const {
    icon,
    fileName,
    defaultFile,
    description,
    handleUpload,
    handleRemove,
    fileType = 'image',
    accept = '.jpg, .jpeg, .png',
    fromVideo,
    files,
    setFiles,
  } = props;

  const [removeData, setRemoveData] = useState('');
  const [isUploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorStatus, setErrorStatus] = useState({});
  const videoTypes = ['.mp4'];

  useEffect(() => {
    // if (defaultFile && defaultFile !== 'Null') {
    if (defaultFile !== 'Null') {
      setSelectedFile(defaultFile);
    } else {
      setSelectedFile(null);
    }
  }, [defaultFile]);

  const handleFileChange = async event => {
    if (isUploading) return;

    let error = '';
    if (fileType === 'audio') {
      error = audioValidation(event);
    } else if (fileType === 'image') {
      error = imageValidation(event);
    } else if (fileType === 'video') {
      error = videoValidation(event);
    }

    if (error) return showToaster(error, 'warning');

    const data = URL.createObjectURL(event.target.files[0]);

    setUploading(true);
    setSelectedFile(data);

    const formData = new FormData();
    formData.append(fileType, event.target.files[0]);

    try {
      if (!fromVideo) {
        const file = await uploadFile(formData);
        handleUpload(file[0][fileType].Location);
        setUploading(false);
      } else {
        // handleUpload(data)
        handleUpload(event.target.files[0].name);
      }
    } catch (error) {
      console.log({ handleFileChangeError: error });
    }
  };

  const audioValidation = e => {
    const audioFile = e.target.files[0];
    const allowedExt = accept.split(',').map(data => data.trim());

    if (!allowedExt.find(ext => audioFile.name.endsWith(ext))) {
      return 'Please upload a mp3 file';
    }

    if (audioFile.size > 26214400) {
      return 'Uploaded file should not exceed 25 mb';
    }
  };

  const imageValidation = e => {
    const imageFile = e.target.files[0];
    const allowedExt = accept.split(',').map(data => data.trim());

    console.log({ allowedExt, name: imageFile.name });
    allowedExt.find(ext => {
      if (imageFile.name.toLowerCase().endsWith(ext)) {
        console.log('match');
      }

      console.log({ ext, lower: imageFile.name.toLowerCase() });
    });

    if (!allowedExt.find(ext => imageFile.name.toLowerCase().endsWith(ext))) {
      return 'Please upload a jpg, jpeg or png file';
    }

    if (imageFile.size > 10485760) {
      return 'Uploaded file should not exceed 10 mb';
    }
  };

  const videoValidation = e => {
    const videoFile = e.target.files[0];
    const allowedExt = accept.split(',').map(data => data.trim());

    allowedExt.find(ext => {
      if (videoFile.name.toLowerCase().endsWith(ext)) {
      }
    });

    if (!allowedExt.find(ext => videoFile.name.toLowerCase().endsWith(ext))) {
      return 'Please upload a video file';
    }

    if (videoFile.size > 10485760) {
      return 'Uploaded file should not exceed 10 mb';
    }
  };

  const handleRemoveData = data => {
    setRemoveData(data);
  };

  const onRemoveData = () => {
    handleRemove();
    setSelectedFile(null);
    showToaster(`${fileType} deleted successfully`, 'success');
  };

  const onFileChange = e => {
    const files = e.target.files;
    // console.log(files);
    const errors = [];
    for (const file of files) {
      const fileNameSplit = file.name.split('.').filter(f => f && f.trim());
      if (fileNameSplit.length <= 1) {
        errors.push(`Invalid file ${file.name}`);
      }
      const ext = `.${fileNameSplit[fileNameSplit.length - 1]}`;
      if (file.size > 2.5e7) {
        errors.push(`File size for ${file.name} greater than 25 MB`);
        showToaster(`File size for ${file.name} greater than 25 MB`);
      }
      switch (fileType) {
        case 'video':
          if (videoTypes.indexOf(ext) === -1) {
            errors.push(`Invalid file type for ${file.name}.`);
          }
          break;
        default:
      }
    }
    if (!errors.length) {
      setFiles(files);
    }
    setErrorStatus(status => {
      let newStatus = { ...status };
      if (errors.length) {
        newStatus['file'] = [...errors];
      } else {
        delete newStatus['file'];
      }
      return { ...newStatus };
    });
  };

  return (
    <Row>
      <Col xs={6} md={fileType === 'audio' || !selectedFile ? 12 : 7} className="position-relative">
        {isUploading && (
          <Spinner
            animation="border"
            variant="primary"
            className="position-absolute position-center"
          />
        )}
        <label className={`custom-upload-container ${props.className || ''}`}>
          {icon && <div className="upload-icon">{icon}</div>}
          {fileName && (
            <span className="upload-text">{selectedFile ? selectedFile.name : fileName}</span>
          )}

          {description && <span className="upload-description">{description}</span>}
          <input
            type="file"
            accept={accept}
            className="d-none"
            disabled={isUploading}
            onChange={fromVideo ? onFileChange : handleFileChange}
          />
        </label>
      </Col>
      {selectedFile && (
        <>
          {fileType === 'audio' ? (
            <Row className="mt-2">
              <Col>
                <div className="d-flex align-items-center gap-2 mt-2">
                  <audio className="w-100" controls src={selectedFile}></audio>
                  <span
                    role="button"
                    className="clone-btn"
                    onClick={() => handleRemoveData('audio')}
                  >
                    {crossIcon}
                  </span>
                </div>
              </Col>
            </Row>
          ) : fileType === 'image' ? (
            <Col xs={6} md={5}>
              <div className="image-file-updated">
                <img src={selectedFile} alt="audio_file" />
              </div>
            </Col>
          ) : null}
        </>
      )}
      <CustomModal
        Buttonlabel="Yes"
        isOpen={!!removeData}
        Header="Are you sure ?"
        Buttonclick={onRemoveData}
        Closemodal={() => setRemoveData('')}
        Content={`You want to remove this ${removeData}?`}
      />
    </Row>
  );
}
