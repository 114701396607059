import React from 'react'
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from "reactstrap";

function SaveDataWarningModal(props) {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  return (
    
    <Modal
    isOpen={props.show}
    fade={true}
    centered
    className={
      DisplayMode
        ? "modal-dialog  modal-dialog-centered custom-modal  dark"
        : "modal-dialog  modal-dialog-centered custom-modal "
    }
  >
    <ModalBody>
      <div className="pt-3 mb-5">
        <p>
        Document have pending changes. Do you want to continue?{" "}
        </p>
      </div>
      <div className="d-flex justify-content-between footer-btn flex-wrap">
        {/* <button type="button" className="btn btn-primary  rounded-0">
          Preview
        </button> */}
        <button
          type="button"
          className="btn btn-outline me-2 rounded-0 modal-toggle"
          data-bs-dismiss="modal"
          onClick={() => {
            props.cancel()
          }}
        >
          Cancel
        </button>
        <div className="d-flex upload-info align-items-center">
          <button
            type="button"
            className="btn btn-primary  rounded-0"
            onClick={() => {
                props.save()
            }}
          >
            Save
          </button>
        </div>
      </div>
    </ModalBody>
  </Modal>
  )
}

export default SaveDataWarningModal