import { useHistory } from 'react-router';
import fileDownload from 'js-file-download';
import { Dropdown, Spinner } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import TemplateTable from './templateTable';
import PageLoader from '../../../services/pageLoader';
import useDebounce from '../../../hooks/debounceHook';
import { clientList } from '../../../services/apiServices';
import DataNotFound from '../../../components/dataNotFound';
import { getTemplates } from '../../../services/api.service';
import PaginationComponent from '../../../components/pagination';
import { CustomDropdownToggle } from '../../../components/dropdown';
import HeaderNavigation from '../../../components/headerNavigation';
import { getQueryData, showToaster } from '../../../services/helper';
import FormFloatingInput from '../../../components/form/formFloatingInput';
import PagePagination from '../../../components/pagination/pagePagination';
import { arrowDownIcon, noProjectIcon, searchIcon } from '../../../constants/icon';

export default function TemplateList() {
  const history = useHistory();
  const { debounce, debouncedValue } = useDebounce();

  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [clients, setClients] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [searchBy, setSearchBy] = useState('name');
  const [isLoading, setIsLoading] = useState(true);
  const [tempSearch, setTempSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [clientQuery, setClientQuery] = useState('');
  const [exportData, setExportData] = useState(false);
  const [renderQuery, setRenderQuery] = useState(null);
  const [statusQuery, setStatusQuery] = useState(null);
  const [previewQuery, setPreviewQuery] = useState(null);
  const [totalTemplates, setTotalTemplates] = useState(0);
  const [fetchTemplates, setFetchTemplates] = useState('');

  const totalPages = Math.ceil(totalTemplates / limit);

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);

    const clientId = getQueryData('id');

    if (clientId) setClientQuery(clientId);

    async function getTemplatesData() {
      try {
        const defaultQuery = `pageNumber=${currentPage}&limit=${limit}`;

        const templates = await getTemplatesFn(defaultQuery);

        if (isMounted) {
          setIsLoading(false);
          setTemplates(templates.data);
          setTotalTemplates(templates.total);
        }
      } catch (error) {
        const err = JSON.parse(JSON.stringify(error.response));
        console.log({ getTemplatesDataError: err });

        if (err?.data?.error?.includes('Invalid auth')) {
          return history.replace('/old-login');
        }

        setIsLoading(false);
        showToaster('Something went wrong. Pleast try again later', 'error');
      }
    }

    getTemplatesData();
    return () => (isMounted = false);
  }, [
    limit,
    search,
    searchBy,
    currentPage,
    statusQuery,
    previewQuery,
    renderQuery,
    clientQuery,
    fetchTemplates,
  ]);

  useEffect(() => {
    clientList()
      .then(response => {
        const clients = response.data?.Body?.result?.Clients?.map(client => ({
          label: client.name,
          value: client._id,
        }));

        setClients(clients);
      })
      .catch(err => console.log({ clientListError: err }));
  }, []);

  useEffect(() => {
    if (debouncedValue !== null) {
      setSearch(debouncedValue);
      setCurrentPage(1);
    }
  }, [debouncedValue]);

  const getTemplatesFn = async defaultQuery => {
    let query = defaultQuery;
    if (search) query += '&searchBy=' + searchBy + '&searchQuery=' + search;
    if (statusQuery) query += '&status=' + statusQuery;
    if (renderQuery) query += '&render=' + renderQuery;
    if (clientQuery) query += '&client=' + clientQuery;
    if (previewQuery) query += '&preview=' + previewQuery;

    return getTemplates(query);
  };

  const handlePageChange = newPage => setCurrentPage(newPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
  };

  const handleLastPage = () => {
    if (currentPage !== totalPages) setCurrentPage(totalPages);
  };

  const handleSearch = e => {
    const { value } = e.target;
    debounce(value, 1000);
    setTempSearch(value);
  };

  const onSelect = (value, setState) => {
    let status = value;
    if (value === 'all') status = null;
    setState(status);
    setCurrentPage(1);
  };

  const filterDropdown = ({ onSelect, label, items, query }) => (
    <Dropdown onSelect={onSelect} align="end">
      <Dropdown.Toggle as={CustomDropdownToggle} id="dropdown-custom-components">
        <span className="dropdown-icon dropdown-max-width-sm">
          {query ? items.find(item => item.value == query)?.label : `Filter by ${label}`}
          {arrowDownIcon}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="common-dropdown-menu">
        <Dropdown.Item eventKey="all" active={query === null}>
          All
        </Dropdown.Item>
        {items.map(item => (
          <Dropdown.Item key={item.value} eventKey={item.value} active={query == item.value}>
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  const handleExport = async () => {
    setExportData(true);
    try {
      const defaultQuery = 'exportData=true';

      const templatesFile = await getTemplatesFn(defaultQuery);

      fileDownload(templatesFile, 'template-list.csv');
      setExportData(false);
    } catch (error) {
      const err = JSON.parse(JSON.stringify(error.response));
      console.log({ handleExportError: err });

      if (err?.data?.error?.includes('Invalid auth')) {
        return history.replace('/old-login');
      }

      setExportData(false);
      showToaster('Something went wrong. Pleast try again later', 'error');
    }
  };

  const handleClientSelect = value => {
    history.push('/template-list');
    onSelect(value, setClientQuery);
  };

  const updateRenderStatus = (template, data) => {
    setTemplates(list => {
      const newList = [...list];
      const idx = newList.findIndex(n => n.tid === template.tid);
      if (idx > -1) {
        newList[idx] = { ...newList[idx], ...data };
      }
      return [...newList];
    });
  };

  return (
    <>
      <PageLoader showLoader={isLoading} />
      <main className="main-content-wrapper">
        <div className="content-wrapper">
          <HeaderNavigation />
          <div className="secondary-header secondary-sticky-header secondary-header-spacing">
            <FormFloatingInput
              id="list"
              type="text"
              placeholder=""
              leftIcon={searchIcon}
              onChange={handleSearch}
              inputValue={tempSearch}
              className="custom-search-form"
              label={`Search by ${searchBy}`}
            />
            <div className="common-dropdown-filter-wrap">
              {filterDropdown({
                items: clients,
                query: clientQuery,
                label: 'organization',
                onSelect: value => handleClientSelect(value),
              })}
              {filterDropdown({
                label: 'status',
                query: statusQuery,
                items: [
                  { label: 'Active Template', value: 1 },
                  { label: 'InActive Template', value: 0 },
                  { label: 'Invalid Template', value: -1 },
                ],
                onSelect: value => onSelect(value, setStatusQuery),
              })}
              {filterDropdown({
                label: 'preview',
                query: previewQuery,
                items: [
                  { label: 'Preview Template', value: 1 },
                  { label: 'No Preview Template', value: 0 },
                ],
                onSelect: value => onSelect(value, setPreviewQuery),
              })}
              {filterDropdown({
                label: 'render',
                query: renderQuery,
                items: [
                  { label: 'Rendered Template', value: 1 },
                  { label: 'Not Rendered Template', value: 0 },
                ],
                onSelect: value => onSelect(value, setRenderQuery),
              })}
            </div>
            <button
              disabled={exportData}
              onClick={handleExport}
              className="bg-btn-primary btn btn-primary"
            >
              {exportData ? (
                <>
                  Exporting &nbsp;&nbsp;
                  <Spinner animation="border" variant="light" size="sm" />
                </>
              ) : (
                <>Export to CSV</>
              )}
            </button>
          </div>
          {templates.length ? (
            <>
              <TemplateTable
                clients={clients}
                templates={templates}
                clientQuery={clientQuery}
                setIsLoading={setIsLoading}
                setFetchTemplates={setFetchTemplates}
                updateRenderStatus={updateRenderStatus}
              />

              <div className="pagination-wrapper">
                <PagePagination
                  itemsPerPage={limit}
                  currentPage={currentPage}
                  totalItems={totalTemplates}
                  setItemsPerPage={value => setLimit(value)}
                />
                <PaginationComponent
                  totalPages={totalPages}
                  onNext={handleNextPage}
                  onLast={handleLastPage}
                  currentPage={currentPage}
                  onFirst={handleFirstPage}
                  onPageChange={handlePageChange}
                  onPrevious={handlePreviousPage}
                />
              </div>
            </>
          ) : (
            <DataNotFound icon={noProjectIcon} title="No template found" />
          )}
        </div>
      </main>
    </>
  );
}
