import React, { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/debounceHook';

function FormInputColor(props) {
  const { id, label, inputValue, onColorChange } = props;

  const { debounce, debouncedValue } = useDebounce();
  const [selectedColor, setSelectedColor] = useState(inputValue);

  useEffect(() => {
    if (debouncedValue) {
      onColorChange(debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setSelectedColor(inputValue);
    // if (inputValue) setSelectedColor(inputValue);
  }, [inputValue]);

  const handleColorChange = color => {
    setSelectedColor(color);
    debounce(color, 1000);
  };

  return (
    <div className={`custom-form-floating custom-color-input  ${props.className || ''}`}>
      <label htmlFor={id}>{label}</label>
      <div className="color-input-container">
        <input
          type="color"
          id={id}
          value={selectedColor}
          onChange={e => handleColorChange(e.target.value)}
        />
        <span className="selected-color">{selectedColor}</span>
      </div>
    </div>
  );
}

export default FormInputColor;
