import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import Sidebar from '../../../components/sidebar';
import HeaderNavigation from '../../../components/headerNavigation';
import EditableContent from '../../../components/EditableContent';
import DetailInnerSidebar from './components/detailInnerSidebar';
import Carousel from '../../../components/carousel/carousel';
import RenderStatusReport from './components/statusReport';

// Icons
import { longLeftArrowIcon, renderIcon } from '../../../constants/icon';
import { getNewProjects, getSizeDuration, projectRenderList } from '../../../services/apiServices';
import { showToaster } from '../../../services/helper';
import { secondsToHrMinS } from '../../../lib/time-conversion';
import downloadFile from '../../../services/DownlaodFile';
import VideoPreview from '../../../components/videoPreview';

function RenderDetail() {
  const history = useHistory();
  const [isInnerSidebarActive, setIsInnerSidebarActive] = useState(true);
  const [activeTitle, setActiveTitle] = useState('');
  const [projectDetails, setProjectDetails] = useState();
  const [renderList, setRenderList] = useState([]);
  const [totalRender, setTotalRender] = useState(0);
  const [activeSlide, setActiveSlide] = useState();
  const [activeScreen, setActiveScreen] = useState();
  const [activeModule, setActiveModule] = useState();
  const [activeRender, setActiveRender] = useState(null);
  const [activeVideoURL, setActiveVideoURL] = useState('');
  const [activeSize, setActiveSize] = useState(0);
  const [activeDuration, setActiveDuration] = useState('0 sec');
  const [isPlaying, setIsPlaying] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sizeDuration, setSizeDuration] = useState({ totalDuration: 0, totalSize: 0 });
  const [isDownloading, setIsDownloading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [loading, setLoading] = useState(false)
  const limit = 7;
  const { id } = useParams();

  useEffect(() => {
    getNewProjects(id).then(res => {
      try {
        if (res?.status === 200) {
          setProjectDetails(res?.data?.data);
        } else {
          showToaster(res, 'warning');
        }
      } catch (error) {}
    });
  }, [id]);

  useEffect(() => {
    if (projectDetails) {
      let body = {
        page_no: currentPage,
        limit: limit,
        keyword: '',
        projectId: projectDetails?.ProjectID,
      };
      projectRenderList(body)
        .then(response => {
          let renderData = response.data.Projects;
          if (renderData.length) {
            setTotalRender(response.data.total_count);
            if (currentPage === 0) {
              setRenderList(renderData);
            } else {
              setRenderList([...renderList, ...renderData]);
            }
            setFetching(false);
          } else {
            if (currentPage === 0) {
              setRenderList([]);
              setTotalRender(0);
              setFetching(false);
            }
          }
        })
        .catch(e => {
          showToaster(e, 'warning');
        });
    }
  }, [id, projectDetails, currentPage]);

  useEffect(() => {
    if (projectDetails) {
      getSizeDuration(projectDetails?.ProjectID).then(response => {
        setSizeDuration(response);
      });
    }
  }, [projectDetails]);

  useEffect(() => {
    if (renderList.length) {
      for (let i = 0; i < renderList.length; i++) {
        const item = renderList[i];
        if (item.renderUrls) {
          const keys = Object.keys(item.renderUrls);
          let foundRender = false;
          for (let j = 0; j < keys.length; j++) {
            const moduleId = item.renderUrls[keys[j]];
            const slideKeys = Object.keys(moduleId);
            let foundModule = false;
            for (let k = 0; k < slideKeys.length; k++) {
              const slideId = moduleId[slideKeys[k]];
              if (slideId && slideId.screens && Object.keys(slideId.screens).length > 0) {
                if (activeRender === null) {
                  setActiveRender(i);
                  setActiveModule(keys[j]);
                  setActiveSlide(slideKeys[k]);
                }
                foundRender = true;
                foundModule = true;
                break;
              }
            }
            if (foundModule) {
              setActiveModule(keys[j]);
              break;
            }
          }
          if (foundRender) {
            break;
          }
        }
      }
      // Other logic to be executed when activeRender changes
    }
  }, [renderList]);

  useEffect(() => {
    if (activeRender !== null) {
      const item = renderList[activeRender];
      if (item.renderUrls) {
        const keys = Object.keys(item.renderUrls);
        for (let j = 0; j < keys.length; j++) {
          const moduleId = item.renderUrls[keys[j]];
          const slideKeys = Object.keys(moduleId);
          for (let k = 0; k < slideKeys.length; k++) {
            const slideId = moduleId[slideKeys[k]];
            if (slideId && slideId.screens && Object.keys(slideId.screens).length > 0) {
              setActiveModule(keys[j]);
              setActiveSlide(slideKeys[k]);
              break;
            }
          }
        }
      }
    }
  }, [activeRender]);

  useEffect(() => {
    if (projectDetails &&activeRender &&activeModule && activeSlide) {
      const projectData = JSON.parse(projectDetails.ProjectData);
      const firstRenderUrls = renderList[activeRender].renderUrls;
      const firstScreenKey = Object.keys(
        firstRenderUrls[activeModule][activeSlide]['screens']
      )[0];
      if (
        Object.keys(firstRenderUrls[activeModule][activeSlide].crashed).length 
        // ||
        // !firstRenderUrls[activeModule][activeSlide]?.url?.length
      ) {
        setActiveTitle(
          projectData[projectDetails.ProjectID].Modules[activeModule].slides[activeSlide].Screens[
            firstScreenKey.replace('-', '.')
          ].ScreenName
        );
        setActiveScreen(firstScreenKey);
      } else {
        setActiveTitle(firstRenderUrls[activeModule][activeSlide].SlideName);
      }
      const firstSize = (firstRenderUrls[activeModule][activeSlide].size / 1024 / 1024).toFixed(
        2
      );
      setActiveSize(firstSize);
      const firstDuration = secondsToHrMinS(firstRenderUrls[activeModule][activeSlide].duration);
      setActiveDuration(firstDuration);
      // setActiveVideoURL(
      //   firstRenderUrls[activeModule][activeSlide]['screens'][firstScreenKey]?.url
      // );
    }
  }, [projectDetails, activeModule,activeSlide]);

  const screens = useMemo(() => {
    if (projectDetails && activeRender !==null && activeModule && activeSlide ) {
      const projectData = JSON.parse(renderList?.[activeRender].ProjectData);
      const screensData =
      renderList?.[activeRender]?.renderUrls?.[activeModule]?.[activeSlide]?.['screens'];
      if (screensData) {
        return Object.entries(screensData).map(([screenKey, screenValue]) => {
          const isCrashed =
          renderList?.[activeRender]?.renderUrls?.[activeModule]?.[activeSlide]?.[
            'crashed'
          ].hasOwnProperty(screenKey);
          return {
            uniqueKey: screenKey,
            title:
              projectData?.[projectDetails.ProjectID]?.Modules?.[activeModule]?.slides?.[activeSlide]
                ?.Screens[screenKey.replace('-', '.')]?.ScreenName,
            // url: screenValue?.url,
            crashed: isCrashed,
          };
        });
      }
    }
  }, [renderList,activeRender, activeModule, activeSlide, projectDetails]);
  
  const onCarouselClick = (url, title, screen) => {
    if (url.length) {
      setActiveVideoURL(url);
      setActiveTitle(title);
      setActiveScreen(screen);
      setIsPlaying(screen);
    }
  };

  return (
    <>

      <Sidebar />
      <main className="main-content-wrapper">
        <div className={`content-wrapper ${isInnerSidebarActive ? 'active' : ''}`}>
          <div className="content-block">
            <HeaderNavigation />
            {isInnerSidebarActive ? (
              <DetailInnerSidebar
                title={projectDetails?.ProjectName}
                renderList={renderList}
                currentPage={currentPage}
                totalRender={totalRender}
                activeRender={activeRender}
                activeSlide={activeSlide}
                fetching={fetching}
                setActiveSize={setActiveSize}
                setActiveDuration={setActiveDuration}
                setActiveVideoURL={setActiveVideoURL}
                setActiveRender={setActiveRender}
                setActiveModule={setActiveModule}
                setActiveSlide={setActiveSlide}
                setCurrentPage={setCurrentPage}
                setFetching={setFetching}
                setRenderList={setRenderList}
                id={id}
              />
            ) : (
              ''
            )}

            <div className="render-screen-wrapper">
              <div className="screen-header">
                <div className="render-status-wrap">
                  <RenderStatusReport
                    duration={secondsToHrMinS(sizeDuration?.totalDuration)}
                    title="Total Duration"
                    statusCircle
                  />
                  <RenderStatusReport
                    className="render-warning-status"
                    duration={`${(sizeDuration.totalSize / 1024 / 1024).toFixed(2)} MB`}
                    title="Total Size"
                    statusCircle
                  />
                  <RenderStatusReport
                    className="render-primary-status"
                    duration={totalRender}
                    title="Total Render"
                    statusCircle
                  />
                </div>
                <span className="go-back-btn" role="button" onClick={() => history.goBack()}>
                  {longLeftArrowIcon} Go Back
                </span>
              </div>
              <div className="screen-render-holder">
                <div className="video-preview-wrap">
                  <div className="screen-title-wrap">
                    <EditableContent
                      editable={true}
                      initialContent={activeTitle}
                      className="editable-dark-text"
                    />
                    <div class="screen-size">
                      {activeSize} MB | {activeDuration}
                      <Button
                        variant="default"
                        onClick={() => downloadFile(activeVideoURL, activeTitle, setIsDownloading)}
                        disabled={!activeVideoURL.length || isDownloading}
                      >
                        {isDownloading ? 'Downloading ...' : `Download`}
                        {renderIcon}
                      </Button>
                    </div>
                  </div>
                  <VideoPreview
                    alt="video preview"
                    activeMediaUrl={activeVideoURL}
                    src="../../assets/img/video-preview.png"
                  />
                </div>
              </div>
              <div className="screen-footer">
                <div className="slider-main-wrapper render-slide-wrapper">
                  <Carousel
                    items={screens || []}
                    className="video-thumbnail-wrap"
                    carouselVideo
                    onButtonClick={onCarouselClick}
                    fromRender
                    activeItem={activeScreen}
                    isPlaying={isPlaying}
                  ></Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default RenderDetail;
