import { bindActionCreators } from 'redux';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spinner, Tab, Tabs } from 'react-bootstrap';

import {
  TextColor,
  HEADERFONT,
  BULLETFONT,
  TEXT_SIZES,
  FONT_SIZE_H,
  FONT_SIZE_P,
  INIT_OST_FONT,
  PARAGRAPHFONT,
  FONT_URL,
} from '../../../constants';
import {
  plusIcon,
  listIcon,
  vioceIcon,
  crossIcon,
  imageIcon,
  headingIcon,
  paragraphIcon,
  videoPlayIcon,
  illustrationIcon,
  longRightArrowIcon,
  checkBoxIcon,
  eyeOpenIcon,
  checkIcon,
} from '../../../constants/icon';
import {
  showToaster,
  mergeFontFamilyAndStyle,
  splitFontFamilyAndStyle,
} from '../../../services/helper';
import AudioComponent from './audioComponent';
import VideoComponent from './videoComponent';
import ImageComponent from './imageComponent';
import { actionCreator } from '../../../store';
import TemplatesComponent from './templatesComponent';
import CustomModal from '../../../services/CustomModal';
import CustomBreadcrumb from '../../../components/breadcrumb';
import useShowTemplates from '../../../hooks/showTemplateHook';
import LightAccordion from '../../../components/lightAccordion';
import FormTextarea from '../../../components/form/formTextarea';
import FormInputGroup from '../../login/components/FormInputGroup';
import IllustrationIconComponent from './illustrationIconComponent';
import DropdownCommon from '../../../components/form/dropdownCommon';
import FormInputColor from '../../../components/form/formInputColor';
import CarouselSlider from '../../../components/carousel/carouselSlider';
import FormCalculateInput from '../../../components/form/formCalculateInput';

export default function EditScreenComponent(props) {
  const {
    fonts,
    clientSetting,
    selectTemplate,
    showMediaContent,
    isTemplateLoading,
    selectedTemplateUrl,
    recommendedTemplates,
    paragraphLengthError,
    setParagraphLengthError,
    manipulateTemplateArray,
  } = props;

  const { showTemplates, viewModal } = useShowTemplates({ selectTemplate });

  const dispatch = useDispatch();
  const {
    EditOst,
    SelectOst,
    AddFeature,
    DeleteFeature,
    EditScreenDuration,
    EditScreenFontStyles,
  } = bindActionCreators(actionCreator, dispatch);

  const project = useSelector(state => state.project);

  const [deleteOSTId, setDeleteOSTId] = useState('');
  const [showContent, setShowContent] = useState(false);
  const [activeComponent, setActiveComponent] = useState('audio');

  const screenData = useMemo(() => {
    const activeSlideData =
      project?.createdProject?.[project.ProjectId]?.Modules?.[project.ActiveModule]?.slides?.[
        project.ActiveSlide
      ];
    const activeScreenData = activeSlideData?.Screens?.[project.ActiveScreen];

    let OSTData = [];
    let listOST = [];
    let videoList = [];
    let imageList = [];
    let headerOST = [];
    let paragraphOST = [];
    let illustrationList = [];
    let bgColor = '';
    let fontColor = '';
    let fontStyle = '';
    let fontFamily = '';
    let audioScript = '';
    let headingSize = '';
    let fontFamilyUrl = '';
    let paragraphSize = '';
    let screenDuration = '';
    let selectedTemplate = '';
    let fontFamilyAndStyle = '';
    const audioUpload = activeSlideData.SlideAudioVO;

    if (activeScreenData) {
      Object.entries(activeScreenData).map(([key, value]) => {
        if (value.Contenttype === 'OST') {
          const OSTId = key;
          const OSTType = value.Contentparams.fontTag;
          const contentParams = value.Contentparams;
          OSTData.push({ OSTId, contentParams });

          if (OSTType === HEADERFONT) {
            headerOST.push({
              OSTId,
              contentParams,
              label: 'Header ' + (headerOST.length + 1),
              allowRemove: clientSetting.headingLimit <= headerOST.length + 1,
            });
          } else if (OSTType === PARAGRAPHFONT) {
            paragraphOST.push({
              OSTId,
              contentParams,
              label: 'Paragraph ' + (paragraphOST.length + 1),
              allowRemove: clientSetting.paragraphLimit <= paragraphOST.length + 1,
            });
          } else if (OSTType === BULLETFONT) {
            listOST.push({
              OSTId,
              contentParams,
              label: 'List Item ' + (listOST.length + 1),
              allowRemove: clientSetting.bulletLimit <= listOST.length + 1,
            });
          }

          fontColor = value.Contentparams.FontColour;
          headingSize = value.Contentparams.FontSizeH;
          paragraphSize = value.Contentparams.FontSizeP;
          fontFamilyUrl = value.Contentparams.FontFamily;
          fontFamilyAndStyle = mergeFontFamilyAndStyle(value.Contentparams.FontFamily);

          const fontFamilyAndStyleData = splitFontFamilyAndStyle(fontFamilyAndStyle);
          fontFamily = fontFamilyAndStyleData.fontFamily;
          fontStyle = fontFamilyAndStyleData.fontStyle;
        } else if (key === 'CS01') {
          bgColor = value.Contentparams.contentdescription;
        } else if (key === 'SD01') {
          screenDuration = value.Contentparams.contentdescription;
        } else if (key === 'AS01') {
          audioScript = value.Contentparams.contentdescription;
        } else if (value.Contenttype === 'Video') {
          const videoNameNumber = key.replace('VD', '');

          videoList.push({
            videoId: key,
            label: 'Video ' + videoNameNumber,
            contentParams: value.Contentparams,
          });
        } else if (value.Contenttype === 'Image') {
          const imageNameNumber = key.replace('IM', '');

          imageList.push({
            imageId: key,
            label: 'Image ' + imageNameNumber,
            contentParams: value.Contentparams,
          });
        } else if (value.Contenttype === 'Illustration') {
          const illustrationNameNumber = key.replace('IL', '');

          illustrationList.push({
            illustrationId: key,
            contentParams: value.Contentparams,
            label: 'Illustration ' + illustrationNameNumber,
          });
        } else if (value.Contenttype === 'TemplateID') {
          selectedTemplate = value.Contentparams.contentdescription;
        }
      });
    }

    return {
      OSTData,
      bgColor,
      listOST,
      videoList,
      imageList,
      headerOST,
      fontColor,
      fontStyle,
      fontFamily,
      headingSize,
      audioScript,
      audioUpload,
      paragraphOST,
      fontFamilyUrl,
      paragraphSize,
      screenDuration,
      selectedTemplate,
      illustrationList,
      activeScreenData,
      fontFamilyAndStyle,
      screenName: activeScreenData?.ScreenName,
    };
  }, [project]);

  useEffect(() => {
    if (!showMediaContent) setShowContent(false);
  }, [showMediaContent]);

  const headingLimitReached = clientSetting.headingLimit <= screenData.headerOST.length;
  const paragraphLimitReached = clientSetting.paragraphLimit <= screenData.paragraphOST.length;
  const listLimitReached = clientSetting.bulletLimit <= screenData.listOST.length;
  let setOSTPayload = {
    bgColor: screenData.bgColor,
    fontH: screenData.headingSize,
    fontP: screenData.paragraphSize,
    fontColor: screenData.fontColor,
    fontStyle: screenData.fontStyle,
    fontFamily: screenData.fontFamily,
  };

  const breadcrumbItems = [
    { link: '#', text: 'Settings' },
    {
      text: (
        <span title={screenData.screenName}>
          {screenData.screenName?.length > 30
            ? screenData.screenName?.substring(0, 30) + '...'
            : screenData.screenName}
        </span>
      ),
      active: true,
    },
  ];

  const handleAudioClick = () => {
    setActiveComponent('audio');
    setShowContent(!showContent);
  };

  const handleVideoClick = () => {
    setActiveComponent('video');
    setShowContent(!showContent);
  };

  const handleImageClick = () => {
    setActiveComponent('image');
    setShowContent(!showContent);
  };

  const handleIllustrationClick = () => {
    setActiveComponent('illustration');
    setShowContent(!showContent);
  };

  const handleViewAllonClick = () => {
    setActiveComponent('templates');
    setShowContent(!showContent);
  };

  const handleAddOrCancle = (allowRemove, fontTag, OSTId, label) => {
    if (allowRemove) return setDeleteOSTId({ OSTId, label });

    addOST(fontTag);
  };

  const removeOST = () => {
    manipulateTemplateArray();
    DeleteFeature(deleteOSTId.OSTId, 'OS');
    showToaster(`${deleteOSTId.label} Deleted Successfully`, 'success');
    setDeleteOSTId('');
  };

  const addOST = fontTag => {
    if (
      (fontTag === HEADERFONT && headingLimitReached) ||
      (fontTag === PARAGRAPHFONT && paragraphLimitReached) ||
      (fontTag === BULLETFONT && listLimitReached)
    ) {
      /* Do not add if limit reached */
      return console.log('Limit reached');
    }

    let newOST = 'OS01';
    if (screenData.OSTData.length) {
      const lastOSTId = screenData.OSTData[screenData.OSTData.length - 1].OSTId;
      const OSTNumber = lastOSTId.replace('OS0', '');
      const OSTNumberSplit = OSTNumber.split('OS')[1];

      let data;
      if (OSTNumberSplit) data = parseInt(OSTNumberSplit) + 1;
      else data = parseInt(OSTNumber) + 1;

      newOST = data < 10 ? 'OS0' + data : 'OS' + data;
    }

    const stylesData = project.stylesData;

    AddFeature({
      [newOST]: {
        Contenttype: 'OST',
        Contentparams: {
          fontTag,
          contenttag: '',
          sequenceid: '4',
          contentdescription: '',
          FontColour: screenData.fontColor || stylesData.textColor,
          FontSizeH: screenData.headingSize || stylesData.headingSize,
          FontSizeP: screenData.paragraphSize || stylesData.paragraphSize,
          FontFamily:
            screenData.fontFamilyUrl ||
            FONT_URL + stylesData.fontFamily + '/' + stylesData.fontStyle,
        },
      },
    });
  };

  const addButton = (tag, fontTag) => {
    return (
      <div className="add-form-component">
        <span>No {tag}. Please add using "+" button</span>
        <span className="clone-btn" role="button" onClick={() => addOST(fontTag)}>
          {plusIcon}
        </span>
      </div>
    );
  };

  const OSTValidation = value => {
    const regMatch = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};:\\|,.0-9<>\/?'"`]*$/.test(value);

    if (!regMatch) {
      return 'Accepts only alphabetic characters, numbers and basic symbols';
    }

    // if (value.length > 1500) {
    if (value.length > 250) {
      // for paragraph
      return 'Content must be less than 250 characters';
    }
  };

  const onChangeOSTDescription = (value, contentParams) => {
    const error = OSTValidation(value);

    if (error) return setParagraphLengthError(error);

    manipulateTemplateArray();
    EditOst({ ...contentParams, contentdescription: value });
  };

  const handleFocus = OSTId => {
    SelectOst(OSTId);
  };

  const editScreenFontStyles = payload => {
    manipulateTemplateArray();
    EditScreenFontStyles(payload);
  };

  const handleFontChange = font => {
    const { fontFamily, fontStyle } = splitFontFamilyAndStyle(font);

    editScreenFontStyles({ ...setOSTPayload, fontFamily, fontStyle });
  };

  const handleFontColorChange = fontColor => {
    editScreenFontStyles({ ...setOSTPayload, fontColor });
  };

  const handleBackgroungColorChange = bgColor => {
    editScreenFontStyles({ ...setOSTPayload, bgColor });
  };

  const handleDurationChange = duration => {
    manipulateTemplateArray();
    EditScreenDuration({ contenttag: 'Null', sequenceid: '10', contentdescription: duration });
  };

  const handleHeaderSizeChange = fontH => {
    editScreenFontStyles({ ...setOSTPayload, fontH });
  };

  const handleParagraphSizeChange = fontP => {
    editScreenFontStyles({ ...setOSTPayload, fontP });
  };

  const addRemoveIcon = ({ data, dataListLength, index, font }) => {
    // let icon = 'ADD';
    // if (data.allowRemove) icon = 'REMOVE';
    // else if (index !== dataListLength - 1) icon = 'REMOVE'; // other than last data
    // else if (screenData.OSTData.length && index === dataListLength - 1) icon = 'BOTH';

    let icon = 'REMOVE';

    if (font === BULLETFONT) {
      icon = 'ADD';
      if (data.allowRemove) icon = 'REMOVE';
      else if (index !== dataListLength - 1) icon = 'REMOVE'; // other than last data
      else if (screenData.OSTData.length && index === dataListLength - 1) icon = 'BOTH';
    }

    const addIcon = (
      <span
        role="button"
        className="clone-btn"
        onClick={() => handleAddOrCancle(false, font, data.OSTId, data.label)}
      >
        {plusIcon}
      </span>
    );

    const removeIcon = (
      <span
        role="button"
        className="clone-btn"
        onClick={() => handleAddOrCancle(true, font, data.OSTId, data.label)}
      >
        {crossIcon}
      </span>
    );

    if (icon === 'ADD') return addIcon;
    if (icon === 'REMOVE') return removeIcon;
    if (icon === 'BOTH')
      return (
        <div className="d-flex flex-column gap-1">
          {removeIcon} {addIcon}
        </div>
      );
  };

  return (
    <>
      {showContent ? (
        <>
          {activeComponent === 'audio' && (
            <AudioComponent
              goBack={() => setShowContent(false)}
              audioScript={screenData.audioScript}
              audioUpload={screenData.audioUpload}
              manipulateTemplateArray={manipulateTemplateArray}
            />
          )}
          {activeComponent === 'video' && (
            <VideoComponent
              videoList={screenData.videoList}
              goBack={() => setShowContent(false)}
              manipulateTemplateArray={manipulateTemplateArray}
            />
          )}
          {activeComponent === 'image' && (
            <ImageComponent
              goBack={() => setShowContent(false)}
              imageList={screenData.imageList}
              manipulateTemplateArray={manipulateTemplateArray}
            />
          )}
          {/* {activeComponent === 'illustration' && (
            <IllustrationIconComponent
              goBack={() => setShowContent(false)}
              illustrationList={screenData.illustrationList}
            />
          )} */}
          {activeComponent === 'templates' && (
            <TemplatesComponent
              selectTemplate={selectTemplate}
              goBack={() => setShowContent(false)}
              recommendedTemplates={recommendedTemplates}
              selectedTemplate={selectedTemplateUrl || screenData.selectedTemplate}
            />
          )}
        </>
      ) : (
        <>
          <div className="screen-sidebar-header">
            <CustomBreadcrumb className="sticky-head" items={breadcrumbItems} />
          </div>
          <div className="screen-sidebar-body pe-2">
            <Tabs id="screen-tag" className="custom-tab" defaultActiveKey="paragraph">
              {/* <Tab
                eventKey="header"
                title={
                  <>
                    <span className="tabs-icon">{headingIcon}</span>
                    Header
                  </>
                }
              >
                {screenData.headerOST.length ? (
                  <>
                    {screenData.headerOST.map((header, i) => (
                      <div className="add-form-component" key={header.OSTId}>
                        <FormInputGroup
                          type="text"
                          id="header"
                          showInputLength
                          label={header.label}
                          className="custom-form-none-icon"
                          onFocus={() => handleFocus(header.OSTId)}
                          inputValue={header.contentParams.contentdescription}
                          onChange={e =>
                            onChangeOSTDescription(e.target.value, header.contentParams)
                          }
                        />
                        {addRemoveIcon({
                          index: i,
                          data: header,
                          font: HEADERFONT,
                          dataListLength: screenData.headerOST.length,
                        })}
                      </div>
                    ))}
                    {headingLimitReached && (
                      <div className="screen-thumbnail-items-wrap">
                        <span className="justify-content-start">Max. heading limit reached</span>
                      </div>
                    )}
                  </>
                ) : (
                  addButton('Header Text', HEADERFONT)
                )}
              </Tab> */}
              <Tab
                eventKey="paragraph"
                title={
                  <>
                    <span className="tabs-icon">{paragraphIcon}</span>
                    Paragraph
                  </>
                }
              >
                {screenData.paragraphOST.length ? (
                  <>
                    {screenData.paragraphOST.map((paragraph, i) => (
                      <div className="add-form-component" key={paragraph.OSTId}>
                        <FormTextarea
                          id="message"
                          showInputLength
                          label={paragraph.label}
                          error={paragraphLengthError}
                          inputValidation={OSTValidation}
                          setError={setParagraphLengthError}
                          onFocus={() => handleFocus(paragraph.OSTId)}
                          inputValue={paragraph.contentParams.contentdescription}
                          onChange={e => onChangeOSTDescription(e, paragraph.contentParams)}
                        />
                        {addRemoveIcon({
                          index: i,
                          data: paragraph,
                          font: PARAGRAPHFONT,
                          dataListLength: screenData.paragraphOST.length,
                        })}
                      </div>
                    ))}
                    {paragraphLimitReached && (
                      <div className="screen-thumbnail-items-wrap">
                        <span className="justify-content-start">Max. paragraph limit reached</span>
                      </div>
                    )}
                  </>
                ) : (
                  addButton('Paragraph Text', PARAGRAPHFONT)
                )}
              </Tab>
              {/* <Tab
                eventKey="list"
                title={
                  <>
                    <span className="tabs-icon">{listIcon}</span>
                    List
                  </>
                }
              >
                {screenData.listOST.length ? (
                  <>
                    {screenData.listOST.map((list, i) => (
                      <div className="add-form-component" key={list.OSTId}>
                        <FormInputGroup
                          id="list"
                          type="text"
                          showInputLength
                          label={list.label}
                          className="custom-form-none-icon"
                          onFocus={() => handleFocus(list.OSTId)}
                          inputValue={list.contentParams.contentdescription}
                          onChange={e => onChangeOSTDescription(e.target.value, list.contentParams)}
                        />
                        {addRemoveIcon({
                          index: i,
                          data: list,
                          font: BULLETFONT,
                          dataListLength: screenData.listOST.length,
                        })}
                      </div>
                    ))}
                    {listLimitReached && (
                      <div className="screen-thumbnail-items-wrap">
                        <span className="justify-content-start">Max. list limit reached</span>
                      </div>
                    )}
                  </>
                ) : (
                  addButton('List Item', BULLETFONT)
                )}
              </Tab> */}
            </Tabs>

            <LightAccordion>
              <h4>Font styles</h4>
              <div className="light-accordion-wrap responsive-layout">
                <Row className="mb-4">
                  <Col xs={12} md={8}>
                    <DropdownCommon
                      items={fonts}
                      name="FontFamily"
                      label="Font Family"
                      onSelectItem={handleFontChange}
                      defaultValue={screenData.fontFamilyAndStyle}
                    />
                  </Col>
                  <Col md={4}>
                    <DropdownCommon
                      items={TEXT_SIZES}
                      name="paragraphSize"
                      label={'Paragraph Size'}
                      onSelectItem={handleParagraphSizeChange}
                      defaultValue={screenData.paragraphSize}
                    />
                  </Col>
                </Row>
                {/* <Row className="mb-4">
                  <Col xs={6}>
                    <DropdownCommon
                      name="headingSize"
                      items={TEXT_SIZES}
                      label={'Header Size'}
                      onSelectItem={handleHeaderSizeChange}
                      defaultValue={screenData.headingSize}
                    />
                  </Col>
                  <Col xs={6}>
                    <DropdownCommon
                      items={TEXT_SIZES}
                      name="paragraphSize"
                      label={'Paragraph Size'}
                      onSelectItem={handleParagraphSizeChange}
                      defaultValue={screenData.paragraphSize}
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Col md={6}>
                    <FormInputColor
                      type="color"
                      id="colorInput"
                      placeholder=""
                      label="Text Color"
                      inputValue={screenData.fontColor}
                      onColorChange={handleFontColorChange}
                    />
                  </Col>
                  <Col md={6}>
                    <FormInputColor
                      type="color"
                      id="colorInput"
                      placeholder=""
                      label="Text Background"
                      inputValue={screenData.bgColor}
                      onColorChange={handleBackgroungColorChange}
                    />
                  </Col>
                </Row>
              </div>
            </LightAccordion>
            <div className="media-wrap">
              <h4>Voice</h4>
              <div className="media-btn-wrap">
                <span className="media-btn" role="button" onClick={handleAudioClick}>
                  {vioceIcon}
                </span>
              </div>
            </div>
            <div className="media-wrap">
              <h4>Add media assets</h4>
              <div className="media-btn-wrap">
                <span className="media-btn" role="button" onClick={handleVideoClick}>
                  {videoPlayIcon}
                </span>
                <span className="media-btn" role="button" onClick={handleImageClick}>
                  {imageIcon}
                </span>
                {/* <span className="media-btn" role="button" onClick={handleIllustrationClick}>
                  {illustrationIcon}
                </span> */}
              </div>
            </div>
            {/* <Row className="calculate-input-wrapper">
              <Col md={8}>
                <FormCalculateInput
                  id="screenDuration"
                  value={screenData.screenDuration}
                  handleChange={handleDurationChange}
                  label="Each screen duration in seconds"
                />
              </Col>
            </Row> */}
            <div className="recommended-templates-wrap">
              <div className="template-info">
                <h4>Recommended templates</h4>
                {!isTemplateLoading && recommendedTemplates.length ? (
                  <span role="button" onClick={handleViewAllonClick}>
                    View all {longRightArrowIcon}
                  </span>
                ) : null}
              </div>
              {isTemplateLoading ? (
                <Spinner variant="primary" animation="border" />
              ) : recommendedTemplates.length ? (
                <CarouselSlider slidesToShow={1.4} variableWidth={false} adaptiveHeight={false} >
                  {showTemplates({
                    recommendedTemplates,
                    selectedTemplate: selectedTemplateUrl || screenData.selectedTemplate,
                    withInnerDiv: true,
                  })}
                </CarouselSlider>
              ) : (
                'Template not available'
              )}
            </div>
          </div>
        </>
      )}

      <CustomModal
        Header="Are you sure"
        isOpen={!!deleteOSTId}
        Buttonclick={removeOST}
        Closemodal={setDeleteOSTId}
        Buttonlabel="Yes, Delete it!"
        Content={`Do you want to delete ${deleteOSTId.label}?`}
      />

      {viewModal()}
    </>
  );
}
