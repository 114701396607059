import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadMediaFile,
  createProjectsPreview,
} from "../../../../services/api.service";
import ReactImageFallback from "react-image-fallback";
import {
  getUserItem,
  showToaster,
  ShowAlertBox,
} from "../../../../services/helper";
import Tooltip from "@mui/material/Tooltip";
import SimpleBackdrop from "../../../container/pageLoader";
import PageLoader from "../../../../services/pageLoader";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import { actionCreator } from "../../../../store";
import { bindActionCreators } from "redux";
import { Modal, ModalBody } from "reactstrap";
import Waveform from "./Waveform";
import { CircularProgress } from "@mui/material";
import { BULLETFONT, HEADERFONT, PARAGRAPHFONT } from "../../../../constants";
import { getProjectById } from "../../../../services/authService";
import { removeMediaUrlFilter } from "../../../../services/DataHelper";
export default function AudioUpload(props) {
  const [upload, setUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState("");
  const [pageLoad, setPageLoad] = useState(false);
  const [showMicOptions, setShowMicOptions] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [audiError, setAudiError] = useState(false);
  const [pos, setPos] = useState(0);
  const [audioFile, setAudioFile] = useState("");
  const [audioDuration, setAudioDuration] = useState("");
  const [audioReady, setaudioReady] = useState(false);
  const project = useSelector((state) => state.preview.previewProject);
  const projectId = useSelector((state) => state.preview.ProjectId);
  const ActiveModule = useSelector((state) => state.preview.ActiveModuleId);
  const ActiveSlide = useSelector((state) => state.preview.ActiveSlideId);
  const ActiveScreen = useSelector((state) => state.preview.ActiveScreenId);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [audioName, setAudioName] = useState("");
  const [cacheProjectData, setcacheProjectData] = useState(undefined);
  const dispatch = useDispatch();
  const { UpdateAudioScriptUpload,UpdatePreview } = bindActionCreators(
    actionCreator,
    dispatch
  );
  let COUNT = 0;

  const countPlusOne = () => {
    // let  count
    COUNT += 1;
    let result = COUNT.toString();
    return result;
  };

  const handleTogglePlay = () => {
    setPlaying(!playing);
  };

  const handlePosChange = (e) => {
    setPos(e.originalArgs[0]);
  };
  const handleReady = (args) => {
    setaudioReady(true);
    let time = args.wavesurfer.getDuration();
    var hrs = Math.floor(time / 3600);
    var mins = Math.floor((time % 3600) / 60);
    var secs = Math.floor(time % 60);
    var ret = "";
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    // this.setState({ audioDuration: ret })
    setAudioDuration(ret);
  };

  const errorAudioFile = () => {
    setUploadProgress("");
    setUpload(false);
    setShowMicOptions(false);
    setAudiError(false);
    setPlaying(false);
    setPos(0);
    setAudioDuration("");
  };
  const uploadAudio = () => {
    try {
      setaudioReady(false);
      setPlaying(false);
      let audioFile = document.getElementById("uploadAudioFile");
      
      let validateMp31= audioFile.files[0].name.split(".mp3")
      console.log(validateMp31[0],"audiofile");
      let validateMp3=validateMp31[0]
      if(validateMp3.includes('.')){
        showToaster("File name contains dots please rename and upload again", "warning");
      }else{
        const ext = [".mp3"];
        if (audioFile.files[0] !== undefined && audioFile.files[0] !== null) {
          let filextension = ext.some((el) =>
            audioFile.files[0].name.endsWith(el)
          );
          if (filextension === true) {
            if (audioFile.files.length !== 1) {
              showToaster("Supports only single file upload", "warning");
            } else if (audioFile.files[0]["size"] > 26214400) {
              showToaster("Uploaded file should not exceed 25 mb", "warning");
            } else {
              uploadMP3(audioFile.files[0]);
              // this.setState({ uploadLoad: true }, () => {
              //     this.uploadMP3(audioFile.files[0]);
              // })
            }
          } else {
            showToaster("Please upload a MP3 file", "warning");
          }
        } else {
          showToaster("Please upload file again", "warning");
        }
      }
    //   const replaceDots = (validateMp3 = '') => {
    //     let res = "";
    //     const { length: len } = validateMp3;
    //     for (let i = 0; i < len; i++) {
    //        const el = validateMp3[i];
    //        if(el === '.'){
    //           res += '-';
    //        }else{
    //           res += el;
    //        };
    //     };
    //     return res;
    //  };
    //  let mp3Name=replaceDots(validateMp3)
     
     console.log(audioFile.files[0].name);
     
    } catch (error) {
      showToaster("Something went wrong. Please try again", "warning");
    }
  };

  const uploadMP3 = async (file) => {
    try {
      let data = new FormData();
      const config = {
        timeout: 1000 * 50000,
        headers: {
          "content-type": "multipart/form-data",
          media_type: "AUDIO",
          user_id: getUserItem("id"),
          file_name: file.name,
          private: 1,
        },
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUpload(true);
          setUploadProgress(percentCompleted);

          // this.setState({ uploadProgress: percentCompleted })
        },
      };
      data.append("file", file);
      uploadMediaFile(data, config).then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            showToaster("Successfully uploaded file", "success");
            setUploadProgress("");
            setUpload(false);
            setShowMicOptions(false);
            setAudiError(false);
            // this.setState({ uploadProgress: "", uploadLoad: false, showMicOptions: false, audiError: false })
            //console.log(response.data);

            // addToActiveProject(response.data);
            setPlaying(false);
            setAudioFile(response.data);
          } else if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ""
          ) {
            showToaster(response.message, "warning");
            setUploadProgress("");
            setUpload(false);
            setShowMicOptions(false);
            // this.setState({ uploadProgress: "", uploadLoad: false, showMicOptions: false })
          } else {
            showToaster("Something went wrong. Please try again", "warning");
            setUploadProgress("");
            setUpload(false);
            setShowMicOptions(false);
            // this.setState({ uploadProgress: "", uploadLoad: false, showMicOptions: false })
          }
        } catch (error) {
          showToaster("Something went wrong. Please try again", "warning");
          setUploadProgress("");
          setUpload(false);
          setShowMicOptions(false);
          // this.setState({ uploadProgress: "", uploadLoad: false, showMicOptions: false })
        }
      });
    } catch (error) {
      showToaster("Something went wrong. Please try again", "warning");
      setUploadProgress("");
      setUpload(false);
      setShowMicOptions(false);
      setAudiError(false);
      // this.setState({ uploadProgress: "", uploadLoad: false, showMicOptions: false })
    }
  };
  const addToActiveProject = async () => {
    //console.log(mp3Url);
    //setAudioFile(mp3Url);
    // this.setState({ audioFile: mp3Url })
    setPlaying(false);
    if (project !== undefined) {
      let allModule = project;
      allModule[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide][
        "SlideAudioVO"
      ] = audioFile;
      setPageLoad(true);

      //console.log(allModule);

      let projectDatafromDb = await getProjectById(projectId);
      if (projectDatafromDb.data.data.length > 0) {
        setcacheProjectData(
          JSON.parse(projectDatafromDb.data.data[0].ProjectData)
        );
      }
      let cachedData = JSON.parse(projectDatafromDb.data.data[0].ProjectData);
      let projectData = allModule[projectId]["Modules"];
      Object.entries(projectData).map((moduleData, moduleIndex) => {
        let mData = moduleData[1];
        let slides = mData.slides;
        //console.log(mData);
        Object.entries(slides).map((slideData, moduleIndex) => {
          let slide = slideData[1];
          let screens = slide.Screens;
          Object.entries(screens).map((screenData, moduleIndex) => {
            console.log(screenData);
            let _arr = [];
            let slide = screenData[1];
            let screenEntries = Object.entries(slide);
            screenEntries.sort((a, b) => {
              const isTa = /^TM/.test(a[0]);
              const isTb = /^TM/.test(b[0]);
              
              if(isTa && !isTb) {
                return 1;
              }
              if(isTb && !isTa) {
                return -1;
              }
              return 0;
            })
            screenEntries.map((item, key) => {
              //console.log(cachedData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[screenData[0]]);
              let oldScreendata = undefined;
              if (cachedData[projectId].Modules != undefined) {
                if (cachedData[projectId].Modules[moduleData[0]] != undefined) {
                  console.log(
                    cachedData[projectId].Modules[moduleData[0]].slides[
                      slideData[0]
                    ],
                    "slides"
                  );
                  if (
                    cachedData[projectId].Modules[moduleData[0]].slides[
                      slideData[0]
                    ] != undefined
                  ) {
                    oldScreendata =
                      cachedData[projectId].Modules[moduleData[0]].slides[
                        slideData[0]
                      ].Screens[screenData[0]];
                  }
                }
              }
              console.log(oldScreendata);

              item.map((element, index) => {
                if (element.Contenttype === "OST") {
                  if (element["Contentparams"]["FontColour"].length === 8) {
                    element["Contentparams"]["FontColour"] =
                      element["Contentparams"]["FontColour"].split("*")[0];
                  }
                  console.log(
                    element["Contentparams"]["contentdescription"],
                    "popopopopo"
                  );
                  if (
                    element["Contentparams"]["contentdescription"].includes(
                      "[H]"
                    )
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"].split(
                        "[H]"
                      )[0];
                    element["Contentparams"]["fontTag"] = HEADERFONT;
                  }

                  if (
                    element["Contentparams"]["contentdescription"].includes(
                      "[P]"
                    )
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"].split(
                        "[P]"
                      )[0];
                    element["Contentparams"]["fontTag"] = PARAGRAPHFONT;
                  }
                  if (
                    element["Contentparams"]["contentdescription"].includes(
                      "[B]"
                    )
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"].split(
                        "[B]"
                      )[0];
                    element["Contentparams"]["fontTag"] = BULLETFONT;                      
                  }

                  if (
                    element["Contentparams"]["fontTag"] === HEADERFONT ||
                    element["Contentparams"]["fontTag"] === undefined
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"] + "[H]";
                  } else if (
                    element["Contentparams"]["fontTag"] === PARAGRAPHFONT
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"] + "[P]";
                  } else if (
                    element["Contentparams"]["fontTag"] === BULLETFONT
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"] + "[B]";
                  }
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  // if (element['Contentparams']['contentdescription'] === "") {
                  //     delete slide[item[0]]
                  // console.log(element['Contentparams']["fontTag"]);

                  // }
                  if (element["Contentparams"]["fontTag"] !== "") {
                    delete element["Contentparams"]["fontTag"];
                  }
                  //console.log(element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription);
                  console.log(oldScreendata);
                  if (oldScreendata) {
                    console.log("11111");
                    if (oldScreendata[item[0]]) {
                      console.log("22222");
                      if (
                        element["Contentparams"]["contentdescription"] !=
                        oldScreendata[item[0]].Contentparams.contentdescription
                      ) {
                        _arr.push(1);
                      }
                      if (
                        element["Contentparams"]["FontColour"] !=
                        oldScreendata[item[0]].Contentparams.FontColour
                      ) {
                        _arr.push(1);
                      }
                      if (
                        element["Contentparams"]["FontFamily"] !=
                        oldScreendata[item[0]].Contentparams.FontFamily
                      ) {
                        _arr.push(1);
                      }
                      if (
                        element["Contentparams"]["FontSizeH"] !=
                        oldScreendata[item[0]].Contentparams.FontSizeH
                      ) {
                        _arr.push(1);
                      }
                      if (
                        element["Contentparams"]["FontSizeP"] !=
                        oldScreendata[item[0]].Contentparams.FontSizeP
                      ) {
                        _arr.push(1);
                      }
                    }
                  }
                  if (element["Contentparams"]["contenttag"] === "") {
                    element["Contentparams"]["contenttag"] = "Null";
                    return element;
                  }

                  // this.setState({ projectInputs: projectInputs })
                  return element;
                }
                if (element.Contenttype === "Background") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  return element;
                }
                // console.log(oldScreendata[item[0]]);
                if (element.Contenttype === "AudioScript") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  // if (element['Contentparams']['contentdescription'] === "") {
                  //     delete slide["AS01"]

                  // }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]]) {
                      if (
                        element["Contentparams"]["contentdescription"] !=
                        oldScreendata[item[0]].Contentparams.contentdescription
                      ) {
                        _arr.push(1);
                      }
                    }
                  }
                  if (element["Contentparams"]["contenttag"] === "") {
                    element["Contentparams"]["contenttag"] = "Null";
                    return element;
                  }
                  return element;
                }

                if (element.Contenttype === "Image") {
                  if(element["Contentparams"]["searchstring"]) {
                    element["Contentparams"]["searchstring"] = element["Contentparams"]["searchstring"].trim();
                  }
                  if(element["Contentparams"]["contenttag"]) {
                    element["Contentparams"]["contenttag"] = element["Contentparams"]["contenttag"].trim();
                  }
                  // console.log(slide, "slide");
                  //element["Contentparams"]["contentdescription"] = "";
                  if (
                    element["Contentparams"]["contenttag"] === "" &&
                    element["Contentparams"]["searchstring"] === ""
                  ) {
                    delete slide[item[0]];
                  } else {
                    element["Contentparams"]["sequenceid"] = countPlusOne();
                  }
                  if (
                    element["Contentparams"]["contenttag"] === "" ||
                    element["Contentparams"]["contenttag"] === []
                  ) {
                    element["Contentparams"]["contenttag"] = "Null";
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                        if ((element["Contentparams"]["searchstring"] != oldScreendata[item[0]].Contentparams.searchstring) ||
                        (element["Contentparams"]["contenttag"] != oldScreendata[item[0]].Contentparams.contenttag) ||
                        (element["Contentparams"]["contentdescription"] && oldScreendata[item[0]].Contentparams.contentdescription && element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription)
                    ) {
                            _arr.push(1)
                        }
                    } else if(element["Contentparams"]["searchstring"] || element["Contentparams"]["contenttag"] !== "Null") {
                      _arr.push(1)
                    }
                  }
                  return element;
                }
                if (element.Contenttype === "Video") {
                  if(element["Contentparams"]["searchstring"]) {
                    element["Contentparams"]["searchstring"] = element["Contentparams"]["searchstring"].trim();
                  }
                  if(element["Contentparams"]["contenttag"]) {
                    element["Contentparams"]["contenttag"] = element["Contentparams"]["contenttag"].trim();
                  }
                  // console.log(slide, "slide");
                  //element["Contentparams"]["contentdescription"] = "";
                  // console.log(oldScreendata[item[0]]);
                  if (
                    element["Contentparams"]["contenttag"] === "" &&
                    element["Contentparams"]["searchstring"] === ""
                  ) {
                    delete slide[item[0]];
                  } else {
                    element["Contentparams"]["sequenceid"] = countPlusOne();
                  }
                  if (
                    element["Contentparams"]["contenttag"] === "" ||
                    element["Contentparams"]["contenttag"] === []
                  ) {
                    element["Contentparams"]["contenttag"] = "Null";
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                        if ((element["Contentparams"]["searchstring"] != oldScreendata[item[0]].Contentparams.searchstring) ||
                        (element["Contentparams"]["contenttag"] != oldScreendata[item[0]].Contentparams.contenttag) ||
                        (element["Contentparams"]["contentdescription"] && oldScreendata[item[0]].Contentparams.contentdescription && element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription)
                    ) {
                            _arr.push(1)
                        }
                    } else if(element["Contentparams"]["searchstring"] || element["Contentparams"]["contenttag"] !== "Null") {
                      _arr.push(1)
                    }
                  }
                  return element;
                }
                if (element.Contenttype === "TemplateID") {
                  // console.log(element['Contentparams']['useTemplateStatus'],"hariiiiiiiii");
                  //console.log(element["Contentparams"]["contentdescription"]);
                  //element["Contentparams"]["contentdescription"] = "";
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                        if (
                        (element["Contentparams"]["contentdescription"] && oldScreendata[item[0]].Contentparams.contentdescription && element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription)
                        ) {
                            _arr.push(1)
                        }
                    }
                  }
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  if (element["Contentparams"]["useTemplateStatus"] !== "") {
                    delete element["Contentparams"]["useTemplateStatus"];
                    return element;
                  }
                  return element;
                }
                if (element.Contenttype === "ColourScheme") {
                  
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  if (
                    element["Contentparams"]["contentdescription"].length === 8
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"].split(
                        "*"
                      )[0];
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (
                        element["Contentparams"]["contentdescription"] !=
                        oldScreendata[item[0]].Contentparams.contentdescription
                      ) {
                        _arr.push(1);
                      }
                    }
                  }
                  return element;
                }
                if (element.Contenttype === "Illustration") {
                  if(element["Contentparams"]["searchstring"]) {
                    element["Contentparams"]["searchstring"] = element["Contentparams"]["searchstring"].trim();
                  }
                  if(element["Contentparams"]["contenttag"]) {
                    element["Contentparams"]["contenttag"] = element["Contentparams"]["contenttag"].trim();
                  }
                  //element["Contentparams"]["contentdescription"] = "";
                  if (
                    element["Contentparams"]["contenttag"] === "" &&
                    element["Contentparams"]["searchstring"] === ""
                  ) {
                    delete slide[item[0]];
                    // return element;
                  } else {
                    element["Contentparams"]["sequenceid"] = countPlusOne();
                  }

                  if (
                    element["Contentparams"]["contenttag"] === "" ||
                    element["Contentparams"]["contenttag"] === []
                  ) {
                    element["Contentparams"]["contenttag"] = "Null";
                    // return element;
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                        if ((element["Contentparams"]["searchstring"] != oldScreendata[item[0]].Contentparams.searchstring) ||
                        (element["Contentparams"]["contenttag"] != oldScreendata[item[0]].Contentparams.contenttag) ||
                        (element["Contentparams"]["contentdescription"] && oldScreendata[item[0]].Contentparams.contentdescription && element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription)
                    ) {
                            _arr.push(1)
                        }
                    } else if(element["Contentparams"]["searchstring"] || element["Contentparams"]["contenttag"] !== "Null") {
                      _arr.push(1)
                    }
                  }
                  return element;
                }
                //console.log(element, "elemetttsssss");
                if (element.Contenttype === "ScreenDuration") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  if (element["Contentparams"]["contenttag"] === "") {
                    element["Contentparams"]["contenttag"] = "Null";
                    // return element;
                  }
                  if (element["Contentparams"]["contentdescription"] === "") {
                    element["Contentparams"]["contentdescription"] = "7";
                    // return element;
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (
                        element["Contentparams"]["contentdescription"] !=
                        oldScreendata[item[0]].Contentparams.contentdescription
                      ) {
                        _arr.push(1);
                      }
                    }
                  }
                  return element;
                }

                console.log(element);
                return element;
              });
              if (slide["mediaurl"]) {
                console.log("errr");
                console.log(
                  projectData[moduleData[0]].slides[slideData[0]].Screens[
                    screenData[0]
                  ]
                );
                if (_arr.length > 0) {
                  delete projectData[moduleData[0]].slides[slideData[0]]
                    .Screens[screenData[0]]["mediaurl"];
                }
              }
              console.log(_arr);
              return item;
            });

            return screenData;
          });
          return slideData;
        });
        return moduleData;
      });

      console.log(project[projectId], "111111111");
      let updatedProjectDataforDb = JSON.stringify(project);
      console.log(updatedProjectDataforDb);

      let projectDataforAPI = await removeMediaUrlFilter(
        JSON.parse(updatedProjectDataforDb),
        projectId
      );
      console.log(projectDataforAPI);
      let renderUrls = ""
      let urlArray
      if (projectDatafromDb.data.data.length > 0) {
          renderUrls = projectDatafromDb.data.data[0].renderUrls;
          urlArray = projectDatafromDb.data.data[0].renderUrls
          if (urlArray !== null && urlArray !== undefined) {
              Object.keys(urlArray).map(function (key, index) {
                  Object.keys(urlArray[key]).map(function (k, i) {
                
                          if (ActiveModule +"-"+ActiveSlide == key + "-" + k) {
                              delete urlArray[key][k]
                              // console.log(urlArray[key][k], key, k, slidechange, "renderUrls");
                          }
                          return urlArray
                      
                  });
              });
          }

      }
      let templateValidArray = [];
      // console.log(project.createdProject);
      let projectDataReq = {
        dbdata: {
          [projectId]: project[projectId],
        },
        APIData: {
          [projectId]: projectDataforAPI[projectId],
        },
        RenderUrl: {
          renderUrls: urlArray
      },
        type: "Update",
      };
      console.log(projectDataReq);
      createProjectsPreview(JSON.stringify(projectDataReq)).then(async (response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            setUploadProgress(false);
            if (
              response.status === 200 &&
              response.status !== null &&
              response.status !== null
            ) {
              setPageLoad(false);
              showToaster("Project Updated successfully", "success");
              // let getProjectData = await getProjetcDetailsById(projectId)
              let projectDatafromDb = await getProjectById(projectId);
              console.log(projectDatafromDb);
              if (projectDatafromDb.data.data.length > 0) {
                UpdatePreview(
                  JSON.parse(projectDatafromDb.data.data[0].ProjectData)
                );
              }
               UpdateAudioScriptUpload(audioFile);
              props.closePreview();
              setPageLoad(false);
              setAudioFile("");
              setAudioDuration("");
              setaudioReady(false);
            } else {
              setUploadProgress(false);
              showToaster(response.message, "warning");
            }
          } else {
            setUploadProgress(false);
            showToaster("Something went wrong. Please Try Again", "warning");
          }
        } catch (error) {
          setUploadProgress(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      });

      // createProjects(JSON.stringify(allModule)).then((response) => {
      //   try {
      //     if (
      //       response !== "" &&
      //       response !== undefined &&
      //       response !== null &&
      //       response.data !== "" &&
      //       response.data !== undefined &&
      //       response.data !== null
      //     ) {
      //       // this.props.updateSeletedProject(true);
      //       console.log(response.data);

      //       ShowAlertBox("SUCCESS", "Audio file updated to project", "success");
      //     }
      //   } catch (error) {}
      // });
    }
  };
  useEffect(() => {
    // if (
    //   project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide][
    //     "SlideAudioVO"
    //   ] !== "Null"
    // ) {
    //   setPlaying(false);
    //   setAudioFile(
    //     project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide][
    //       "SlideAudioVO"
    //     ]
    //   );
    // }
  }, []);
  return (
    <React.Fragment>
      <PageLoader showLoader={pageLoad} />
      {/* <SimpleBackdrop showBackDrop={pageLoad} /> */}
      <Modal
        isOpen={props.openAudioUpload}
        fade={true}
        centered
        className={
          DisplayMode
            ? "modal-dialog  modal-dialog-centered custom-modal audio-modal audio-upload dark"
            : "modal-dialog  modal-dialog-centered custom-modal audio-modal audio-upload"
        }
      >
        <ModalBody>
          {/* <div className="modal-body">
                    <div className="modal-content border-0"> */}

          <div className="img-block">
            <video
              id="video"
              preload="none"
              width="100%"
              poster="../../../assets/img/shutterstock_1240584409.jpeg"
            >
              <source
                id="mp4"
                src="http://media.w3.org/2010/05/sintel/trailer.mp4"
                type="video/mp4"
              />
              <source
                id="webm"
                src="http://media.w3.org/2010/05/sintel/trailer.webm"
                type="video/webm"
              />
              <source
                id="ogv"
                src="http://media.w3.org/2010/05/sintel/trailer.ogv"
                type="video/ogg"
              />
            </video>
          </div>

          <div className="time-duration text-end mt-2">
            <span> {audioDuration}</span>
          </div>
          {upload === true ? (
            <Box>
              {" "}
              <LinearProgress />{" "}
            </Box>
          ) : (
            <React.Fragment>
              {audioFile !== "" ? <Waveform url={audioFile} /> : ""}

              <div className="d-flex justify-content-between footer-btn flex-wrap">
                <div className="d-flex upload-info align-items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.04688 5.76485L9 2.8125L11.9531 5.76485"
                      stroke=""
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 10.6874V2.81445"
                      stroke=""
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15.1875 10.6875V14.625C15.1875 14.7742 15.1282 14.9173 15.0227 15.0227C14.9173 15.1282 14.7742 15.1875 14.625 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V10.6875"
                      stroke=""
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <input
                    type="file"
                    accept=".mp3"
                    id="uploadAudioFile"
                    className="input"
                    onChange={() => uploadAudio()}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                  <span>UPLOAD AUDIO</span>
                </div>
                <button
                  onClick={() => {
                    setPlaying(false);
                    props.closePreview();
                    setAudioFile("");
                    setAudioDuration("");
                  }}
                  type="button"
                  className="btn btn-outline me-2 rounded-0 modal-toggle"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  onClick={() => addToActiveProject()}
                  type="button"
                  className="btn btn-primary  rounded-0"
                >
                  Update
                </button>
              </div>
            </React.Fragment>
          )}
          {/* </div>
                </div> */}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
