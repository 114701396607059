import { useEffect, useState } from 'react';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import EditableContent from '../EditableContent';

// Icons
import { brandEditIcon, ellipseIcon, deleteIcon, videoIcon } from '../../constants/icon';
import FallBackImage from '../../assets/img/Thumbnailv3.jpg';
import CustomModal from '../../services/CustomModal';
import { secondsToHrMinS } from '../../lib/time-conversion';
import { isAdmin } from '../../services/helper';

function CardThumbnail(props) {
  const {
    children,
    thumbnailImage,
    title,
    courseVideoIcon,
    courseText,
    videoText,
    totalVideos,
    clockIcon,
    duration,
    id,
    deleteProjects,
    projectRole,
  } = props;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const history = useHistory();
  const handleViewAll = () => {
    if (totalVideos) history.push(`/project-details/${id}`);
  };

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const isCurrentUserAdmin = isAdmin();

  const handleEditClick = () => {
    history.push(`/edit-projects/${id}`);
  };

  const addImageFallback = event => {
    event.currentTarget.src = { FallBackImage };
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      role="button"
      className="common-dropdown-btn"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
    >
      {children}
    </div>
  ));

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const closeMenu = () => {
    setDropdownVisible(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', closeMenu);

    return () => {
      window.removeEventListener('scroll', closeMenu);
    };
  }, []);

  return (
    <>
      <div className="card-thumbnail">
        <div className="img-wrap">
          {thumbnailImage?.length ? (
            <img src={thumbnailImage} alt="Online course" onError={addImageFallback} />
          ) : (
            <img src={FallBackImage} alt="Online course" onError={addImageFallback} />
          )}
        </div>
        <div className="info-wrap">
          {isEditingTitle ? (
            <EditableContent initialContent={title} editable={true} />
          ) : (
            <h4 className="ellipsis-text">{title}</h4>
          )}
          <div className="icon-wrap ellipsis-text">
            {courseVideoIcon && <img src={courseVideoIcon} alt="Online course"></img>}
            {courseText}
          </div>
          <div className="icon-wrap-holder">
            <div className="icon-wrap">
              {courseVideoIcon && <img src={courseVideoIcon} alt="Total videos"></img>}
              {videoText} {totalVideos}
            </div>
            {clockIcon && (
              <div className="icon-wrap">
                {clockIcon}
                {secondsToHrMinS(duration)}
              </div>
            )}
          </div>
        </div>
        {!totalVideos &&
        !isCurrentUserAdmin &&
        !projectRole?.editProject &&
        !projectRole?.deleteProject ? null : (
          <div className="common-dropdown-wrap">
            <Dropdown
              align={{ lg: 'end' }}
              onVisibleChange={toggleDropdown}
              visible={dropdownVisible}
              trigger={['click']}
            >
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                {ellipseIcon}
              </Dropdown.Toggle>

              <Dropdown.Menu className="common-dropdown-menu script-dropdown-menu">
                {totalVideos && (isCurrentUserAdmin || projectRole?.viewProject) ? (
                  <Dropdown.Item eventKey="1" onClick={handleViewAll}>
                    {videoIcon} View all
                  </Dropdown.Item>
                ) : null}
                {isCurrentUserAdmin || projectRole?.editProject ? (
                  <Dropdown.Item eventKey="2" onClick={handleEditClick}>
                    {brandEditIcon} Edit
                  </Dropdown.Item>
                ) : null}
                {isCurrentUserAdmin || projectRole?.deleteProject ? (
                  <Dropdown.Item eventKey="3" onClick={() => setDeleteModal(true)}>
                    {deleteIcon} Delete
                  </Dropdown.Item>
                ) : null}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        {children}
      </div>
      <CustomModal
        Header="Are you sure?"
        isOpen={deleteModal}
        Buttonclick={() => deleteProjects(id)}
        Buttonlabel="Yes, Delete it!"
        Closemodal={setDeleteModal}
        Content={`Do you want to delete?`}
      />
    </>
  );
}
export default CardThumbnail;
