import { Axioslib, AxioslibUser } from '../lib/axioslib';
import AxioslibApi from '../lib/axioslib-api';
// import AxioslibApiTemp from "../lib/axioslib-temp";
import { getUserItem } from './helper';

export const changePassword = body => {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`users/changepwrd`, body)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const resetuserPassword = body => {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`users/reset-password`, body)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const checkUserLogin = body => {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`users/login`, body)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const resetPassword = body => {
  return new Promise((resolve, reject) => {
    AxioslibUser.post('users/forgot' + body)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject({});
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getEnableWelcomeMsg = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.get('users/enable-welcome-msg', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject({});
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const updateEnableWelcomeMsg = body => {
  let data = '';
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.put('users/enable-welcome-msg', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject({});
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const AddStyles = data => {
  return new Promise((resolve, reject) => {
    Axioslib.post(`users/styles-data`, JSON.stringify(data))
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const updateStyles = data => {
  return new Promise((resolve, reject) => {
    Axioslib.put(`users/update-styles`, JSON.stringify(data))
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getStyles = project_id => {
  // var data = JSON.stringify({"project_id":project_id});
  return new Promise((resolve, reject) => {
    Axioslib.get(`users/get-styles/${project_id}`)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        console.log(e);
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const AddProjects = data => {
  return new Promise((resolve, reject) => {
    Axioslib.post(`users/add-project`, data)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getAllProjects = data => {
  return new Promise((resolve, reject) => {
    Axioslib.get(`users/get_project`, data)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getOstLimit = data => {
  return new Promise((resolve, reject) => {
    Axioslib.get(`users/get-ostlimit`, data)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const updateProjects = data => {
  return new Promise((resolve, reject) => {
    Axioslib.put(`users/update_project`, data)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getTemplateAssets = () => {
  return new Promise((resolve, reject) => {
    Axioslib.get(`media/getTemplateassets`)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getTemplateAssetVideos = () => {
  return new Promise((resolve, reject) => {
    Axioslib.get(`media/getTemplateassetvideos`)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getTemplateAssetIllustration = () => {
  return new Promise((resolve, reject) => {
    Axioslib.get(`media/getTemplateIllustration`)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const saveTemplate = (data, templateType, type, OrgId) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  data.templatetype = templateType;
  data.orgId = OrgId;
  return new Promise((resolve, reject) => {
    Axioslib.post(`template/savetemplate`, data, {
      headers: {
        authorization: `Bearer ${accessToken}`,
        type: type,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const findTemplateById = tid => {
  return new Promise((resolve, reject) => {
    Axioslib.get(`template/templatebyid/${tid}`)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const UpdateTemplatebyId = (tid, data) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.put(`api/admin/template/v1/update-template/${tid}`, data, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        reject({ status: 500, message: e.response.data.errors });
      });
  });
};

export const deleteTemplateById = tid => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.delete(`template/delete-template/${tid}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const UpdateTemplateActivebyId = (tid, data) => {
  let filter = {
    isActive: data,
  };
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.put(`template/updatetemplate/${tid}`, filter, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getAllTemplateByUserid = tid => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.get(`template/alltemplates`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getAllTemplate = params => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return Axioslib.get(`template/listtemplate`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    params,
  });
};
export const saveAsset = (url, type) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    let assettype = '';
    if (type == 'IMG') {
      assettype = 'Image';
    } else if (type == 'ILLU') {
      assettype = 'Illustration';
    } else if (type == 'VID') {
      assettype = 'Video';
    }
    let data = {
      assetUrl: url,
      type: assettype,
    };
    Axioslib.post(`asset/saveasset`, data, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getAllTemplateAsset = type => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.get(`asset/getasset`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const createUsers = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  // if (userId !== undefined && userId !== null && userId !== "") {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`users/Adduser`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const createOrgnaizationUsers = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  // if (userId !== undefined && userId !== null && userId !== "") {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`users/create-adminuser`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getPaymentPlans = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  // if (userId !== undefined && userId !== null && userId !== "") {
  return new Promise((resolve, reject) => {
    Axioslib.get(`users/get-payment-plan`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getOrgUsersById = id => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  // if (userId !== undefined && userId !== null && userId !== "") {
  return new Promise((resolve, reject) => {
    Axioslib.post(`users/get-user/${id}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getOrgusers = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post(`users/get-orgusers`, body, {
      // headers: {
      //   authorization: `Bearer ${accessToken}`,
      // }
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getOrganisation = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post(`users/get-organisation`, body, {
      // headers: {
      //   authorization: `Bearer ${accessToken}`,
      // }
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const updateOrgnaizationUsers = (id, body) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post(`users/update-user/${id}`, body, {
      // headers: {
      //   authorization: `Bearer ${accessToken}`,
      // }
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getChildusers = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post(`users/get-childUser`, body, {
      // headers: {
      //   authorization: `Bearer ${accessToken}`,
      // }
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const updateUserStatus = (userId, body, type) => {
  let filter = {
    userdata: body,
    type: type,
  };
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  // if (userId !== undefined && userId !== null && userId !== "") {
  return new Promise((resolve, reject) => {
    Axioslib.post(`users/update-user/${userId}`, filter, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const updateuserGlobalRole = (userId, body) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  // if (userId !== undefined && userId !== null && userId !== "") {
  return new Promise((resolve, reject) => {
    Axioslib.post(`users/update-globalrole/${userId}`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const inserttemplatePlan = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  // if (userId !== undefined && userId !== null && userId !== "") {
  return new Promise((resolve, reject) => {
    Axioslib.post(`users/insert-template-plan`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getTemplatePlan = orgId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.get(`users/get-template-plan`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getTemplatePlanByOrg = orgId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.get(`users/get-template-plan/${orgId}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const updateTemplatePlanById = (orgId, body) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  // if (userId !== undefined && userId !== null && userId !== "") {
  return new Promise((resolve, reject) => {
    Axioslib.post(`users/update-template-plan/${orgId}`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const updateuserProjectRole = (userId, orgId, body) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  // if (userId !== undefined && userId !== null && userId !== "") {
  return new Promise((resolve, reject) => {
    Axioslib.post(`users/insert-projectRole/${userId}/${orgId}`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const updateSlideRenderName = (userId, body) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  // if (userId !== undefined && userId !== null && userId !== "") {
  return new Promise((resolve, reject) => {
    Axioslib.post(`api/update-renderdata/${userId}`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getUsersProjectRole = userId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.get(`users/get-projectRole/${userId}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getProjectById = id => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.get(`api/getProjectbyId/${id}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getUsersGlobalRole = userId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.get(`users/get-globalRole/${userId}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const deleteUsersProjectRole = projectId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.delete(`users/delete-projectRole/${projectId}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getRenderErrorDetails = projectId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  let userId = getUserItem('id');
  return new Promise((resolve, reject) => {
    Axioslib.get(`users/get-renderError/${userId}/${projectId}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const UpdateDbProjectDatabyId = (projectId, data) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.put(`api/updateProjectbyId/${projectId}`, JSON.stringify({ data }), {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const createTemplateProject = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  if (Id) {
    return Axioslib.post('template/save-template-project', body, {
      headers: {
        'Content-Type': ' application/json',
        Accept: 'application/json',
        user_id: Id,
        authorization: `Bearer ${accessToken}`,
      },
    });
  }
  return Promise.reject();
};

export const renderTemplate = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      body['user_id'] = Id;
      Axioslib.post('template/render', body, {
        headers: {
          user_id: Id,
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                resolve({
                  status: response.status,
                  apiStatus: response.data.Body.success,
                  message: response.data.Body.message,
                });
              } else {
                resolve({ status: 101, message: ' No response from the server' });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: 'Something went wrong. Please try again' });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'Something went wrong' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getTemplateRenderStatus = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      body.user_id = Id;
      Axioslib.post('template/project-status', body, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  resolve({
                    data: response.data.Body.result,
                    status: response.data.StatusCode,
                    message: response.data.Body.message,
                    apiStatus: response.data.Body.success,
                  });
                } else {
                  resolve({ status: 101, message: ' No response from the server' });
                }
              } else {
                resolve({ status: 101, message: ' No response from the server' });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: 'Something went wrong. Please try again' });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'Something went wrong' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getOSTConfigsList = params => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return Axioslib.get(`users/listostconfigs`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    params,
  });
};

export const updateOSTConfig = params => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return Axioslib.post(`users/updateostconfig`, params, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getUsersList = params => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return Axioslib.post(`users/getuserslisting`, params, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};

export const insertRenderProjectDetails = (body, renderid, renderOutsideOrg = false) => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  body.orgId = Id;
  body.renderId = renderid;
  body.createdBy = userId;
  body.renderOutsideOrg = renderOutsideOrg;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  // if (userId !== undefined && userId !== null && userId !== "") {
  return new Promise((resolve, reject) => {
    Axioslib.post(`users/save-render-project`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

//user login new api calls

export const userLogin = (body, headers) => {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`/api/user/v1/login`, body, {
      headers,
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ message: e.response.data.error, code: e.response.data.code });
      });
  });
};

export const saveTemplateData = (data, templateType, type, OrgId) => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  data.templatetype = templateType;
  data.orgId = OrgId;

  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/admin/template/v1/save-template', data, {
      headers: {
        type,
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const templatePreview = data => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/admin/template/v1/preview', data, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const createTemplateProjectV1 = data => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/admin/template/v1/save-template-project', data, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};
