import React, { useState } from 'react';
import { Navbar, Dropdown, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import {
  addCollaboratorIcon,
  editProfileIcon,
  exportIcon,
  helpIcon,
  logOutIcon,
  memberPlusIcon,
} from '../../constants/icon';
import { decodeEncodedItem } from '../../services/helper';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    className="profile-dropdown-wrap"
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
    ref={ref}
  >
    {children}
  </div>
));

function HeaderNavigation() {
  let history = useHistory();
  let userSessionData = decodeEncodedItem(localStorage.getItem('_olive_user'));

  const logout = () => {
    localStorage.removeItem('_user');
    localStorage.removeItem('_olive_user');
    localStorage.removeItem('_s_admin_user');
    history.push('/login');
  };

  const handleEditProfileClick = () => {
    history.push('/setting/edit-profile');
  };

  return (
    <Navbar expand="lg" className="header-navbar sticky-head">
      <div className="navbar-toggler-wrap">
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-auto" />
      </div>
      <Navbar.Collapse id="basic-navbar-nav">
        <div className="user-info-holder w-100 gap-5">
          {/* <Button variant="default">{memberPlusIcon}Add Collaborator</Button> */}
          <div className="common-dropdown-wrap">
            <Dropdown align={{ lg: 'end' }}>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <div className="user-info">
                  <h4>
                    {userSessionData?.firstName} {userSessionData?.lastName}
                  </h4>
                  <span>{userSessionData?.email}</span>
                </div>
                <div className="circle-wrap">
                  {userSessionData?.firstName
                    ? userSessionData?.firstName?.[0]?.toUpperCase()
                    : userSessionData?.lastName?.[0]?.toUpperCase()}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="common-dropdown-menu">
                <Dropdown.Item eventKey="1" onClick={handleEditProfileClick}>
                  {editProfileIcon} Edit Profile
                </Dropdown.Item>
                <Dropdown.Item eventKey="2">{exportIcon} Export/Share</Dropdown.Item>
                <Dropdown.Item eventKey="3">{helpIcon} Help & Support</Dropdown.Item>
                <Dropdown.Item eventKey="4" onClick={() => logout()}>
                  {logOutIcon} Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default HeaderNavigation;
