import { useState } from 'react';
import { Button } from 'react-bootstrap';
import CustomSwitchSlider from '../../../../components/form/customSwitchSlider';
import AssetsCheckboxBlock from './assetsCheckboxBlock';
import { deleteIcon, plusIcon, renderIcon } from '../../../../constants/icon';
import AddFilesModal from './addFilesModal';

function DocumentComponent() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <AddFilesModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="assets-container">
        <div className="assets-content">
          <h2>All documents files</h2>
          <div className="assets-checkbox-holder">
            <Button
              className="add-new-file-btn"
              variant="default"
              onClick={() => setModalShow(true)}
            >
              {plusIcon}
              Add new file
            </Button>
            <AssetsCheckboxBlock docsContent />
            <AssetsCheckboxBlock docsContent />
            <AssetsCheckboxBlock docsContent />
            <AssetsCheckboxBlock docsContent />
            <AssetsCheckboxBlock docsContent />
          </div>
        </div>
        <div className="assets-sidebar">
          <h2>Settings</h2>
          <div className="settung-sharing-wrap">
            <div className="info-wrap">
              <h4>File Size</h4>
              <span>154 Kbs</span>
            </div>
          </div>
          <div className="settung-sharing-wrap">
            <div className="info-wrap">
              <h4>File Sharing</h4>
              <p>Turn on to share your file changes and updates</p>
            </div>
            <CustomSwitchSlider />
          </div>
          <Button variant="default">{renderIcon} Download</Button>
          <Button variant="default">{deleteIcon} Delete</Button>
        </div>
      </div>
    </>
  );
}
export default DocumentComponent;
