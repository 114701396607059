import React from 'react';

export default function PageLoader({ showLoader, wrapperClass = '' }) {
  return (
    <>
      {showLoader ? (
        <div className={`loader-wrapper video-loader ${wrapperClass}`}>
          <div className="loader-inner">
            <img src="../../../assets/img/loader.gif" alt="loader"></img>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
