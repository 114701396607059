import React, { useState } from 'react';
// Icons
import { settingsIcon } from '../../../../constants/icon';

import Sidebar from '../../../../components/sidebar';
import InnerSidebar from '../../../../components/sidebar/innerSidebar';
import HeaderNavigation from '../../../../components/headerNavigation';
import InnerSidebarHeader from '../../../../components/sidebar/innerSidebarHeader';
import InnerSidebarList from '../../../../components/sidebar/innerSidebarList';
import EditProfileContent from './editProfileContent';

function EditProfile() {
  const [isInnerSidebarActive, setIsInnerSidebarActive] = useState(true);
  return (
    <>
      <Sidebar />
      <main className="main-content-wrapper">
        <div className={`content-wrapper ${isInnerSidebarActive ? 'active' : ''}`}>
          <div className="content-block">
            {isInnerSidebarActive && (
              <InnerSidebar>
                <InnerSidebarHeader
                  title="Settings"
                  subTitle="Manage all setting"
                  icon={settingsIcon}
                />
                <InnerSidebarList />
              </InnerSidebar>
            )}
            <HeaderNavigation />
            <EditProfileContent />
          </div>
        </div>
      </main>
    </>
  );
}
export default EditProfile;
