import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FONT_URL } from '../../../constants'
import { CreateScreen,CreateScreenManual } from '../../../services/DataHelper'
import { actionCreator } from '../../../store/index'
import { showToaster } from "../../../services/helper";
import CustomModal from '../../../services/CustomModal'
import Tooltip from '@mui/material/Tooltip';
import { getStyles } from '../../../services/authService'
import { removeObjectFromArray } from '../../../services/HelperFunctions'

function AddScreen(props) {
    let project = useSelector(state => state.project)
    let ActiveModule = useSelector(state => state.project.ActiveModule)
    let ActiveSlide = useSelector(state => state.project.ActiveSlide)
    let ActiveScreen = useSelector(state => state.project.ActiveScreen)
    let projectID = useSelector(state => state.project.ProjectId)
    let FontUrl = useSelector(state => state.project.FontUrl)
    let FontHSize = useSelector(state => state.project.FontHSize)
    let FontPSize = useSelector(state => state.project.FontPSize)
    let TextColor = useSelector(state => state.project.TextColor)
    let BgColor = useSelector(state => state.project.BgColor)
    let EditMode = useSelector(state => state.project.EditMode)
    const dispatch = useDispatch()
    let { Addscreen, SelectScreen, RemoveScreen, RenameScreenName } = bindActionCreators(actionCreator, dispatch)
    const [projects, setProjects] = useState("")
    const [projectLoaded, setProjectLoaded] = useState(false)
    const [projectId, setProjectId] = useState("")
    const [toggle, setToggle] = useState(false)
    const [oldScreenName, setoldScreenName] = useState("")
    const [openUpdateModal, setopenUpdateModal] = useState(false)
    const [deleteScreenId, setdeleteScreenId] = useState(undefined)
    const [ScreenName, setScreenName] = useState("")
    const [ScreenCount, setScreenCount] = useState(0)

    useEffect(() => {
        setProjectId(projectID)
        setProjects(project)
        if (project.createdProject[projectID] !== undefined) {
            setProjectLoaded(true);
        } else {
            setProjectLoaded(false)
        }

    }, [project, projectID, ActiveSlide])

    useEffect(() => {
        try {
            if (projectId && ActiveModule && ActiveSlide) {	
                const screens = Object.keys(projects.createdProject[projectId]?.Modules[ActiveModule]?.slides[ActiveSlide]?.Screens)
                setScreenCount(screens.length);
            }
        } catch(e) {
            console.log(e);
        }
    }, [projects, projectId, ActiveModule, ActiveSlide])

    const handleSlideClick = (screenId) => {
        SelectScreen(screenId)
    }


    const removeScreen = (screenid) => {
        RemoveScreen(screenid)
        setopenUpdateModal(false)
        setdeleteScreenId(undefined)
        showToaster('Screen Deleted Successfully', 'success');
        if(props?.errorScreen?.some((item) => 
        item?.activeModule === ActiveModule && 
        item?.activeSlide === ActiveSlide &&
        item?.activeScreen === screenid  
        )){
            props?.setErrorScreen(removeObjectFromArray(props?.errorScreen, 'activeScreen',screenid ))
            
        }
        if(props?.TemplateCheckArray?.some((item) => 
        item?.templateKeys === ActiveModule && 
        item?.templateSlidekeys === ActiveSlide &&
        item?.templateScreenkeys === screenid  
        )){
            props?.settemplateCheckArray(removeObjectFromArray(props?.TemplateCheckArray, 'templateScreenkeys',screenid ))
            
        }

        if(props?.invalidAudioScreens?.some((item) => 
        `${ActiveModule} ${ActiveSlide} ${screenid}` === `${item?.activeModule} ${item?.activeSlide} ${item?.activeScreen}`
        )) {
            props?.setInvalidAudioScreen(removeObjectFromArray(props?.invalidAudioScreens,'activeScreen', screenid))
        }

        if(props?.invalidOstScreens?.some((item) => item?.activeModule === ActiveModule && 
        item?.activeSlide === ActiveSlide &&
        item?.activeScreen === screenid )) {
            props?.setInvalidOstScreen(removeObjectFromArray(props?.invalidOstScreens,'activeScreen', screenid))
        }

        if(props?.activeAudioScriptComparisonResultsState?.some((item) => (item?.activeModule === ActiveModule && 
            item?.activeSlide === ActiveSlide &&
            item?.activeScreen === screenid) && !item?.isValid)) {
            props?.setActiveAudioScriptComparisonResultsState(removeObjectFromArray(props?.activeAudioScriptComparisonResultsState,'activeScreen', screenid))
        }
    }

    const listScreen = () => {
        let slidesArray = [];
        if (projectLoaded) {
            if (project?.createdProject?.[projectId]?.Modules?.[ActiveModule] != undefined) {
                if (project.createdProject[projectId].Modules?.[ActiveModule].slides?.[ActiveSlide] !== undefined) {
                    let ScreenLength = Object.keys(project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens).length;
                    Object.entries(project?.createdProject?.[projectId]?.Modules?.[ActiveModule]?.slides[ActiveSlide].Screens).map((i, item) => {
                        let ScreenID = i[0];
                        let Screen = i[1];
                        const classNames = ScreenID === ActiveScreen ? `panel-label cm-gen-spec cm-screen-container active` : `panel-label cm-gen-spec cm-screen-container`;   
                        let Style = "" 
                        if (props.TemplateCheckArray !== undefined) {
                            props.TemplateCheckArray.map((tempArray) => {
                                if (ScreenID === tempArray.templateScreenkeys && ActiveModule === tempArray.templateKeys&& ActiveSlide === tempArray.templateSlidekeys) {
                                    if (tempArray.isValid === false) {
                                        Style = "red"
                                    } else {
                                        Style = ""
                                    }
                                }
                            })

                        }
                        props?.errorScreen?.map((item) =>{
                            if(item?.activeModule === ActiveModule && item?.activeSlide === ActiveSlide && item?.activeScreen === ScreenID) {
                                Style = "red"
                            } 
                        })
                        props?.invalidAudioScreens?.map((item) =>{
                            if(item?.activeModule === ActiveModule && item?.activeSlide === ActiveSlide && item?.activeScreen === ScreenID) {
                                Style = "red"
                            }    
                        })
                        props?.invalidOstScreens?.map((item) =>{
                            if(item?.activeModule === ActiveModule && item?.activeSlide === ActiveSlide && item?.activeScreen === ScreenID) {
                                Style = "red"
                            }    
                        })
                        // props?.activeAudioScriptComparisonResultsState?.map((item) =>{
                        //     if(item?.activeModule === ActiveModule && item?.activeSlide === ActiveSlide && item?.activeScreen === ScreenID && !item.isValid) {
                        //         Style = "red"
                        //     } 
                        // })
                        
                       
                        slidesArray.push(
                            <div className={classNames } id={ScreenID} style={{ color: Style }} key={ScreenID} >
                                {
                                    toggle &&
                                    (ScreenID === ActiveScreen ?
                                        (
                                            <div className="d-flex justify-content-between
                                        align-items-center
                                        ">
                                                <input
                                                    type="text"
                                                    id="lname"
                                                    name="lname"
                                                    s
                                                    value={Screen.ScreenName}
                                                    onChange={(e) => ChangeScreenName(e.target.value)}
                                                    onBlur={() => ChangeScreenNameBluroff(Screen.ScreenName)}
                                                    autoFocus
                                                    maxlength="200"
                                                />
                                                <div className="re-edit">
                                                  
                                                    <span onClick={(e) => ChangeScreenName(e.target.value)}>
                                                        <Tooltip title="Save" arrow>
                                                            <svg width="22px" height="22px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3904 5.31232L7.07178 10.7106L3.67991 7.88408L4.32009 7.11586L6.92822 9.2893L10.6096 4.68762L11.3904 5.31232Z" fill="" />
                                                            </svg></Tooltip>
                                                    </span>
                                                    <span onClick={() => ChangeScreenName(ScreenName)}>
                                                        <Tooltip title="Close" arrow>
                                                            <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><g data-name="close"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" /><path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" /></g></g></svg>
                                                        </Tooltip></span>
                                                </div>
                                                {/* <span>max allower characters 200</span> */}
                                            </div>

                                        )
                                        :
                                        <>
                                            <p onClick={() => handleSlideClick(ScreenID)} onDoubleClick={() => {
                                                setoldScreenName(Screen.ScreenName)
                                                setToggle(true)
                                            }}>{Screen.ScreenName}</p>
                                            {ScreenLength == 1 ? "" : (
                                                <Tooltip   arrow>
                                                    <div onClick={() => {
                                                        setopenUpdateModal(true)
                                                        setdeleteScreenId(ScreenID)
                                                    }} className="cm-del-icon">< svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.1041 9.8187C10.1979 9.91178 10.2507 10.0385 10.2507 10.1707C10.2507 10.3028 10.1979 10.4295 10.1041 10.5226C10.011 10.6165 9.88436 10.6692 9.75223 10.6692C9.6201 10.6692 9.49344 10.6165 9.40039 10.5226L5.29232 6.40817L1.18424 10.5226C1.09119 10.6165 0.964535 10.6692 0.832404 10.6692C0.700273 10.6692 0.573613 10.6165 0.480566 10.5226C0.386753 10.4295 0.333984 10.3028 0.333984 10.1707C0.333984 10.0385 0.386753 9.91178 0.480566 9.8187L4.5936 5.70921L0.480566 1.59972C0.354867 1.47397 0.305775 1.2907 0.351785 1.11893C0.397794 0.947163 0.531913 0.812997 0.703622 0.766972C0.875331 0.720947 1.05854 0.770055 1.18424 0.895797L5.29232 5.01025L9.40039 0.895797C9.59471 0.701416 9.90975 0.701416 10.1041 0.895797C10.2984 1.09018 10.2984 1.40533 10.1041 1.59972L5.99104 5.70921L10.1041 9.8187Z" fill="" />
                                                        </svg></div></Tooltip>
                                            )}
                                        </>
                                    )
                                }
                                {
                                    !toggle && <>
                                        <p onClick={() => handleSlideClick(ScreenID)} onDoubleClick={() => {
                                            setoldScreenName(Screen.ScreenName)
                                            setToggle(true)
                                        }} >{Screen.ScreenName}</p>
                                        {ScreenLength == 1 ? "" : (
                                            <Tooltip   arrow>
                                                <div onClick={() => {
                                                    setopenUpdateModal(true)
                                                    setdeleteScreenId(ScreenID)
                                                }} className="cm-del-icon"><svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.1041 9.8187C10.1979 9.91178 10.2507 10.0385 10.2507 10.1707C10.2507 10.3028 10.1979 10.4295 10.1041 10.5226C10.011 10.6165 9.88436 10.6692 9.75223 10.6692C9.6201 10.6692 9.49344 10.6165 9.40039 10.5226L5.29232 6.40817L1.18424 10.5226C1.09119 10.6165 0.964535 10.6692 0.832404 10.6692C0.700273 10.6692 0.573613 10.6165 0.480566 10.5226C0.386753 10.4295 0.333984 10.3028 0.333984 10.1707C0.333984 10.0385 0.386753 9.91178 0.480566 9.8187L4.5936 5.70921L0.480566 1.59972C0.354867 1.47397 0.305775 1.2907 0.351785 1.11893C0.397794 0.947163 0.531913 0.812997 0.703622 0.766972C0.875331 0.720947 1.05854 0.770055 1.18424 0.895797L5.29232 5.01025L9.40039 0.895797C9.59471 0.701416 9.90975 0.701416 10.1041 0.895797C10.2984 1.09018 10.2984 1.40533 10.1041 1.59972L5.99104 5.70921L10.1041 9.8187Z" fill="" />
                                                    </svg></div></Tooltip>
                                        )}
                                    </>
                                }
                                
                            </div>
                        )
                    })
                    return slidesArray
                }
            }
        }
    }

    const addScreen = async () => { 
        let newScreenId;
        let screenName;
        const slideNo = ActiveSlide.replace('SL', '')
        let screenArray = [];
        Object.entries(projects.createdProject[projectId].Modules?.[ActiveModule].slides?.[ActiveSlide].Screens).map((item, key) => {
            screenArray.push(item[0])
            let lastItem = screenArray.pop();
           let screenNo = lastItem.replace(`SC${slideNo}.0`, '');
           let a = parseInt(screenNo)
           let array = screenNo.split(`SC${slideNo}.`);
           let b = parseInt(array[1])
           let data;
           if (array[1] !== undefined && array[1] !== null && array[1] !== "") {
               data = b + 1
           } else {
               data = a + 1
           }
           if (data !== undefined && data !== null) {
               newScreenId = data <= 9 ? (`SC${slideNo}.0${(data)}`) : (`SC${slideNo}.${(data)}`)
               screenName = data <= 9 ? ('0' + data) : (data)
           }
       })
       let newScreen;
       if(props.TemplateSelectionType.manualTemplate==true){
           if (EditMode) {
               let response = await getStyles(projectID);
               let style = await response.data.result;
               let font_url = FONT_URL + style.fontFamily + "/" + style.fontStyle;
               newScreen = await CreateScreenManual(newScreenId, screenName, font_url, style.headingSize, style.paragraphSize, style.textColor, style.bgColor);
           } else {
               newScreen = await CreateScreenManual(newScreenId, screenName, FontUrl, FontHSize, FontPSize, TextColor, BgColor);
           }
       }else{
           if (EditMode) {
               let response = await getStyles(projectID);
               let style = await response.data.result;
               let font_url = FONT_URL + style.fontFamily + "/" + style.fontStyle;
               newScreen = await CreateScreen(newScreenId, screenName, font_url, style.headingSize, style.paragraphSize, style.textColor, style.bgColor);
           } else {
               newScreen = await CreateScreen(newScreenId, screenName, FontUrl, FontHSize, FontPSize, TextColor, BgColor);
           }
       }
        Addscreen(newScreen) 
    
    }

    const ChangeScreenName = (name) => {
        RenameScreenName(name);
        setScreenName(name)
    }
    const ChangeScreenNameBluroff = (name) => {
        if (name.length == 0) {
            RenameScreenName(oldScreenName);
        }
        setToggle(false)
    }
    


    return (
        <div className="d-flex justify-content-between cm-screen-blk">
            <div className="d-flex cm-screen-wrapper">
                {projectLoaded &&
                    listScreen()
                }
                
            </div>
            { props.screenLimit > ScreenCount && (
            <div onClick={addScreen} className="d-flex align-items-center cm-add-label cm-a-space">
                <svg className="me-2" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.47266 3.17188H9.52734C9.62109 3.17188 9.66797 3.21875 9.66797 3.3125V15.6875C9.66797 15.7812 9.62109 15.8281 9.52734 15.8281H8.47266C8.37891 15.8281 8.33203 15.7812 8.33203 15.6875V3.3125C8.33203 3.21875 8.37891 3.17188 8.47266 3.17188Z" fill="" />
                    <path d="M3.09375 8.83203H14.9062C15 8.83203 15.0469 8.87891 15.0469 8.97266V10.0273C15.0469 10.1211 15 10.168 14.9062 10.168H3.09375C3 10.168 2.95312 10.1211 2.95312 10.0273V8.97266C2.95312 8.87891 3 8.83203 3.09375 8.83203Z" fill="" />
                </svg>
                <span>Add Screen</span >
            </div>
            )}
            <CustomModal isOpen={openUpdateModal} Closemodal={setopenUpdateModal} Content="Do you want to delete the screen?" Header="Are you sure" Buttonlabel="Yes, Delete it!" Buttonclick={removeScreen} del_id={deleteScreenId} />
        </div>
    )
}

export default AddScreen
