import * as icons from '../../constants/icon';
import React from 'react';

export default function PreviewIcons() {
  return (
    <div className="d-flex flex-wrap gap-2 m-3 preview-icons-test-admin">
      <span title="aiContentProLogo">{icons.aiContentProLogo}</span>
      <span title="mailIcon">{icons.mailIcon}</span>
      <span title="lockIcon">{icons.lockIcon}</span>
      <span title="userIcon">{icons.userIcon}</span>
      <span title="eyeOpenIcon">{icons.eyeOpenIcon}</span>
      <span title="eyeCloseIcon">{icons.eyeCloseIcon}</span>
      <span title="domainIcon">{icons.domainIcon}</span>
      <span title="sidebarLogo">{icons.sidebarLogo}</span>
      <span title="orgName">{icons.orgName}</span>
      <span title="projectsIcon">{icons.projectsIcon}</span>
      <span title="templateIcon">{icons.templateIcon}</span>
      <span title="assetsIcon">{icons.assetsIcon}</span>
      <span title="reportIcon">{icons.reportIcon}</span>
      <span title="settingsIcon">{icons.settingsIcon}</span>
      <span title="helpIcon">{icons.helpIcon}</span>
      <span title="editIcon">{icons.editIcon}</span>
      <span title="videoIcon">{icons.videoIcon}</span>
      <span title="arrowDownIcon">{icons.arrowDownIcon}</span>
      <span title="arrowUpIcon">{icons.arrowUpIcon}</span>
      <span title="tickIcon">{icons.tickIcon}</span>
      <span title="crossIcon">{icons.crossIcon}</span>
      <span title="plusIcon">{icons.plusIcon}</span>
      <span title="addCollaboratorIcon">{icons.addCollaboratorIcon}</span>
      <span title="editProfileIcon">{icons.editProfileIcon}</span>
      <span title="exportIcon">{icons.exportIcon}</span>
      <span title="logOutIcon">{icons.logOutIcon}</span>
      <span title="brandEditIcon">{icons.brandEditIcon}</span>
      <span title="uploadCloudIcon">{icons.uploadCloudIcon}</span>
      <span title="headingIcon">{icons.headingIcon}</span>
      <span title="paragraphIcon">{icons.paragraphIcon}</span>
      <span title="listIcon">{icons.listIcon}</span>
      <span title="vioceIcon">{icons.vioceIcon}</span>
      <span title="videoPlayIcon">{icons.videoPlayIcon}</span>
      <span title="imageIcon">{icons.imageIcon}</span>
      <span title="illustrationIcon">{icons.illustrationIcon}</span>
      <span title="minusIcon">{icons.minusIcon}</span>
      <span title="backArrowIcon">{icons.backArrowIcon}</span>
      <span title="recordIcon">{icons.recordIcon}</span>
      <span title="speechIcon">{icons.speechIcon}</span>
      <span title="renderIcon">{icons.renderIcon}</span>
      <span title="longLeftArrowIcon">{icons.longLeftArrowIcon}</span>
      <span title="longRightArrowIcon">{icons.longRightArrowIcon}</span>
      <span title="chatGptIcon">{icons.chatGptIcon}</span>
      <span title="composeScreenIcon">{icons.composeScreenIcon}</span>
      <span title="searchIcon">{icons.searchIcon}</span>
      <span title="ellipseIcon">{icons.ellipseIcon}</span>
      <span title="deleteIcon">{icons.deleteIcon}</span>
      <span title="noProjectIcon">{icons.noProjectIcon}</span>
      <span title="clockIcon">{icons.clockIcon}</span>
      <span title="pageFirstIcon">{icons.pageFirstIcon}</span>
      <span title="pageLastIcon">{icons.pageLastIcon}</span>
      <span title="pagePrevIcon">{icons.pagePrevIcon}</span>
      <span title="pageNextIcon">{icons.pageNextIcon}</span>
      <span title="editVideoIcon">{icons.editVideoIcon}</span>
      <span title="cloneIcon">{icons.cloneIcon}</span>
      <span title="viewRenderIcon">{icons.viewRenderIcon}</span>
      <span title="playControlIcon">{icons.playControlIcon}</span>
      <span title="pauseControlIcon">{icons.pauseControlIcon}</span>
      <span title="checkBoxIcon">{icons.checkBoxIcon}</span>
      <span title="deletePromptIcon">{icons.deletePromptIcon}</span>
      <span title="documentIcon">{icons.documentIcon}</span>
      <span title="imagesIcon">{icons.imagesIcon}</span>
      <span title="videosIcon">{icons.videosIcon}</span>
      <span title="illustratorIcon">{icons.illustratorIcon}</span>
      <span title="audioIcon">{icons.audioIcon}</span>
      <span title="pdfIcon">{icons.pdfIcon}</span>
      <span title="checkIcon">{icons.checkIcon}</span>
      <span title="uploadIcon">{icons.uploadIcon}</span>
      <span title="laptopIcon">{icons.laptopIcon}</span>
      <span title="dropBoxIcon">{icons.dropBoxIcon}</span>
      <span title="stackPlayIcon">{icons.stackPlayIcon}</span>
      <span title="memberPlusIcon">{icons.memberPlusIcon}</span>
      <span title="orgGroupIcon">{icons.orgGroupIcon}</span>
      <span title="logoIcon">{icons.logoIcon}</span>
      <span title="faviIcon">{icons.faviIcon}</span>
      <span title="editUserIcon">{icons.editUserIcon}</span>
      <span title="plainImageIcon">{icons.plainImageIcon}</span>
      <span title="cancelCropIcon">{icons.cancelCropIcon}</span>
      <span title="cropIcon">{icons.cropIcon}</span>
      <span title="closeIcon">{icons.closeIcon}</span>
      <span title="assetIcon">{icons.assetIcon}</span>
      <span title="stockImageIcon">{icons.stockImageIcon}</span>
      <span title="uploadFileIcon">{icons.uploadFileIcon}</span>
      <span title="plainSearchIcon">{icons.plainSearchIcon}</span>
      <span title="resetIcon">{icons.resetIcon}</span>
      <span title="privateLockIcon">{icons.privateLockIcon}</span>
      <span title="alertQuestionIcon">{icons.alertQuestionIcon}</span>
      <span title="alertWarningIcon">{icons.alertWarningIcon}</span>
      <span title="alertDangerIcon">{icons.alertDangerIcon}</span>
      <span title="alertTickIcon">{icons.alertTickIcon}</span>
      <span title="urlIcon">{icons.urlIcon}</span>
      <span title="loadingPlayIcon">{icons.loadingPlayIcon}</span>
      <span title="circlePlayIcon">{icons.circlePlayIcon}</span>
      <span title="circleLockIcon">{icons.circleLockIcon}</span>
      <span title="contentProLogo">{icons.contentProLogo}</span>
      <span title="exclamationIcon">{icons.exclamationIcon}</span>
      <span title="moveFirstIcon">{icons.moveFirstIcon}</span>
      <span title="moveLastIcon">{icons.moveLastIcon}</span>
      <span title="aiExtendIcon">{icons.aiExtendIcon}</span>
      <span title="refreshIcon">{icons.refreshIcon}</span>
      <span title="colorPickerIcon">{icons.colorPickerIcon}</span>
      <span title="dissmissIcon">{icons.dissmissIcon}</span>
    </div>
  );
}
