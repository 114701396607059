import { Axioslib, AxioslibUser } from '../lib/axioslib';
import { getUserItem } from './helper';

export const newGetCategory = search => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  let url = `api/category/v1/list`;
  if (search) {
    url += `?search=${search}`;
  }
  return new Promise((resolve, reject) => {
    AxioslibUser.get(url, {
      headers: {
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data.Body.result) {
            let result = {
              data: response.data.Body.result,
              message: response.data.Body.message,
              status: response.data.StatusCode,
            };
            resolve(result);
          } else {
            resolve({ message: ' No response from the server' });
          }
        } else {
          resolve({
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        resolve({ message: 'Something went wrong. Please try again' });
      });
  });
};

export const createCategory = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/category/v1/create', body, {
      headers: {
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data.Body.result) {
            let result = {
              data: response.data.Body.result,
              message: response.data.Body.message,
              status: response.data.StatusCode,
            };
            resolve(result);
          } else {
            resolve({ message: ' No response from the server' });
          }
        } else {
          resolve({
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        resolve({ message: 'Something went wrong. Please try again' });
      });
  });
};

export const createNewProjects = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole === 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  body = JSON.parse(body);
  if (body.type === 'create') {
    body = JSON.stringify({ ...body, createdBy: userId });
  }
  return new Promise((resolve, reject) => {
    //   if (Id) {
    AxioslibUser.post('api/project/v1/create', body, {
      headers: {
        'Content-Type': ' application/json',
        Accept: 'application/json',
        // user_id: Id,
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({
            message: 'No response from the server',
          });
        }
      })
      .catch(error => {
        resolve({ message: 'No response from the server' });
      });
  });
};

export const projectLists = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== '') {
      AxioslibUser.post('api/project/v1/list', body, {
        headers: {
          user_id: userId,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                    total_count: response.data.Body.result.total_count,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getNewProjects = id => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise(resolve => {
    AxioslibUser.get(`api/project/v1/${id}`, {
      headers: {
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({
            message: 'No response from the server',
          });
        }
      })
      .catch(error => {
        resolve({ message: 'No response from the server' });
      });
  });
};
export const preRenderProject = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/project/v1/pre-render', body, {
      headers: {
        'Content-Type': ' application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (
                response.data.Body.result !== undefined &&
                response.data.Body.result !== null &&
                response.data.Body.result !== ''
              ) {
                let result = {
                  data: response.data.Body.result,
                  message: response.data.Body.message,
                  status: response.data.StatusCode,
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({
                status: 101,
                message: ' No response from the server',
              });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(error => {
        resolve({ status: 101, message: 'No response from the server' });
      });
  });
};

export const projectRender = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    let Id = getUserItem('clientId');
    if (Id !== undefined && Id !== null && Id !== '') {
      body['user_id'] = Id;
      AxioslibUser.post('api/project/v1/render', body, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (
            response.data.status !== undefined &&
            response.data.status !== null &&
            response.data.status !== ''
          ) {
            if (response.data.render_id !== undefined && response.data.render_id !== null) {
              resolve({
                status: response.data.status,
                message: response.data.render_id,
              });
            } else {
              resolve({
                status: 101,
                message: ' No response from the server',
              });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'Something went wrong' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const createRenderProject = (body, renderid, renderOutsideOrg = false) => {
  let userId = getUserItem('id');
  body.renderId = renderid;
  body.createdBy = userId;
  body.renderOutsideOrg = renderOutsideOrg;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  // if (userId !== undefined && userId !== null && userId !== "") {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/project/v1/create/render-project`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getProjectStatus = body => {
  let Id = getUserItem('clientId');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/project/v1/check/status', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (
                response.data.Body.result !== undefined &&
                response.data.Body.result !== null &&
                response.data.Body.result !== ''
              ) {
                resolve({
                  data: response.data.Body.result,
                  status: response.data.StatusCode,
                  message: response.data.Body.message,
                  apiStatus: response.data.Body.success,
                });
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({
                status: 101,
                message: ' No response from the server',
              });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        resolve({ status: 101, message: 'Something went wrong' });
      });
  });
};

export const projectRenderList = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    let Id = getUserItem('clientId');
    if (Id !== undefined && Id !== null && Id !== '') {
      AxioslibUser.post('api/project/v1/render-list', body, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.data.Body) {
            resolve({
              data: response.data.Body.result,
              status: response.data.StatusCode,
              message: response.data.Body.message,
            });
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'Something went wrong' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getClusterList = (sort_by = '') => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  let url = `api/generic/v1/clusters`;
  if (sort_by) {
    url += `?sort_by=${sort_by}`;
  }
  return new Promise((resolve, reject) => {
    AxioslibUser.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data.success === true) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const clientList = body => {
  return new Promise((resolve, reject) => {
    Axioslib.post(`api/client/v1/list`, body, {
      // headers: {
      //   authorization: `Bearer ${accessToken}`,
      // }
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getSizeDuration = projectId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/project/v1/${projectId}/render-duration`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data) {
          resolve(response.data);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        console.log(e);
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const chatGPTGenerateScript = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post(`api/generic/v1/chat-gpt/content-segmentation`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: response.data.error });
        }
      })
      .catch(e => {
        console.log(e);
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const chatGPTGenerateInputs = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post(`api/generic/v1/chat-gpt/input-generation`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: response.data.error });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const chatGPTGenerateModules = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post(`api/generic/v1/chat-gpt/generate-module`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: response.data.error });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const projectPreviewRegen = projectId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/project/v1/${projectId}/regen-previews`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data) {
          resolve(response.data);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        console.log(e);
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const deleteProject = projectId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/project/v1/${projectId}/delete`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        console.log(e);
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const updateClusterStatus = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/generic/v1/cluster/status/update`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            resolve(response.data);
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(e => {
        console.log(e);
        resolve({ status: 101, message: 'No response from the server' });
      });
  });
};

export const deleteCluster = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/generic/v1/cluster/delete`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              let result = {
                data: response.data.Body,
                message: response.data.Body.message,
                status: response.data.StatusCode,
              };
              resolve(result);
            } else {
              resolve({
                status: 101,
                message: response.data.status,
              });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(e => {
        console.log(e);
        resolve({ status: 101, message: 'No response from the server' });
      });
  });
};

export const searchAssetsVideo = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post(`api/generic/v1/search-assets-video`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: response.data.error });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const projectAnalytics = (
  pageNumber,
  pageSize,
  startDate,
  endDate,
  { token, client } = {}
) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  let url = `api/render/v1/project-analytics?pageNumber=${pageNumber}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`;

  let header = {};
  if (token && client) {
    accessToken = token;
    header = { admin_access: true, clientid: client };
    url = `api/render/v1/admin/project-analytics?pageNumber=${pageNumber}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`;
  }

  return new Promise((resolve, reject) => {
    Axioslib.get(url, {
      headers: {
        ...header,
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: response.data.error });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const allProjectAnalytics = ({ token, client } = {}) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  let url = `api/render/v1/all-project-analytics`;

  let header = {};
  if (token && client) {
    accessToken = token;
    url = `api/render/v1/admin/all-project-analytics`;
    header = { admin_access: true, clientid: client };
  }

  return new Promise((resolve, reject) => {
    Axioslib.get(url, {
      headers: {
        ...header,
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: response.data.error });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const activeOrg = (clientId, body) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.put(`api/client/v1/${clientId}/status`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: response.data.error });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getUserListByAdmin = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post(`api/user/v1/list`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response?.data);
        } else {
          resolve({ status: 101, message: response.data.error });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getUserListByUser = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post(`api/user/v1/for-non-admin/list`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response?.data);
        } else {
          resolve({ status: 101, message: response.data.error });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const createUser = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/user/v1/create`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: response.data.error });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const updateUser = (userId, updateType) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/user/v1/${userId}/${updateType}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        console.log(e);
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const exportUsers = body => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/user/v1/export`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const updateUserRole = body => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/user/v1/${body.userId}/role-update`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const getUserDetails = userId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/user/v1/${userId}/details`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response) {
          resolve(response.data);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        console.log(e);
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getUserProjectRolesDetails = (userId) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/user/v1/${userId}/project-roles`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        console.log(e);
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const saveUserRole = body => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/project/v1/project-role-assign`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export const deleteAssets = body => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/generic/v1/delete-assets`, body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};