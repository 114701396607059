import React, { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/debounceHook';

const FormTextarea = props => {
  const {
    id,
    label,
    error,
    onClick,
    onChange,
    inputValue,
    showInputLength,
    placeholder = '',
    disabled = false,
    maxLength = '',
    onFocus = () => {},
    setError = () => {},
    inputValidation = () => {
      return true;
    },
  } = props;

  const { debounce, debouncedValue } = useDebounce();
  const [isFocused, setIsFocused] = useState(false);
  const [selectedValue, setSelectedValue] = useState(inputValue);

  useEffect(() => {
    if (debouncedValue !== null) {
      onChange(debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setSelectedValue(inputValue);
    // if (inputValue) setSelectedValue(inputValue);
  }, [inputValue]);

  const handleFocus = value => {
    setIsFocused(value);

    if (true) return onFocus();
  };

  const handleChange = e => {
    const value = e.target.value;

    const error = inputValidation(value);
    if (error) {
      setError(error);
    } else {
      setError('');
    }

    setSelectedValue(value);
    debounce(value, 800);
  };

  return (
    <div
      className={`custom-form-floating custom-form-textarea ${isFocused ? 'focused' : ''} ${
        props.className || ''
      }`}
    >
      <textarea
        id={id}
        value={selectedValue}
        onChange={handleChange}
        placeholder={placeholder}
        onFocus={() => handleFocus(true)}
        maxLength={maxLength}
        onBlur={() => handleFocus(false)}
        disabled={disabled}
      />
      <label htmlFor={id} className={isFocused ? 'focused-label' : ''}>
        {label}
      </label>
      <span className="form-icon form-right-icon" onClick={onClick}></span>
      {showInputLength && (
        <div className="screen-thumbnail-items-wrap mb-2">
          <span className="position-absolute end-0 mt-0">{selectedValue.length} characters</span>
        </div>
      )}
      {error && <span className="text-danger mt-1">{error}</span>}
    </div>
  );
};

export default FormTextarea;
