import React, { useState, useEffect } from 'react';
import { Accordion, Col, Row, Button } from 'react-bootstrap';
import { useHistory, useParams, Link } from 'react-router-dom';

import Sidebar from '../../../components/sidebar';
import InnerSidebar from '../../../components/sidebar/innerSidebar';
import HeaderNavigation from '../../../components/headerNavigation';
import InnerSidebarHeader from '../../../components/sidebar/innerSidebarHeader';
import AccordionItem from './components/accordionItem';
import EditableContent from '../../../components/EditableContent';
import DetailThumbnail from './components/detailThumbnail';
import CircleIcon from './components/circleIcon';

// Icons
import {
  videoIcon,
  editVideoIcon,
  cloneIcon,
  deleteIcon,
  viewRenderIcon,
  renderIcon,
  noProjectIcon,
} from '../../../constants/icon';
import { getNewProjects, getUserProjectRolesDetails } from '../../../services/apiServices';
import { getUserItem, isAdmin, showToaster } from '../../../services/helper';
import VideoPreview from '../../../components/videoPreview';
import downloadFile from '../../../services/DownlaodFile';
import DataNotFound from '../../../components/dataNotFound';
import PageLoader from '../../../services/pageLoader';

function DetailProjects() {
  const [isInnerSidebarActive, setIsInnerSidebarActive] = useState(true);
  const history = useHistory();
  let { id } = useParams();

  // Detail Thumbnail Start
  const [loading, setLoading] = useState(false);
  const [activeVideoURL, setActiveVideoURL] = useState('');
  const [activeThumbnail, setActiveThumbnail] = useState('SL01');
  const [projectData, setProjectData] = useState();
  const [activeKeys, setActiveKeys] = useState([]);
  const [isHeaderActive, setIsHeaderActive] = useState({});
  const [projectDetails, setProjectDetails] = useState();

  const [slidesData, setSlidesData] = useState([]);
  const [activeTitle, setActiveTitle] = useState(slidesData[0]?.slideName);
  const [isDownloading, setIsDownloading] = useState(false);
  const [roleDetails, setRoleDetails] = useState([]);
  const [role, setRole] = useState();
  const currentUserId = getUserItem('id');
  const currentUserRoles = getUserItem('userRole');
  const isCurrentUserAdmin = isAdmin();

  useEffect(() => {
    getProjectRoles();
  }, []);

  const getProjectRoles = async () => {
    const projectRolesResponse = await getUserProjectRolesDetails(currentUserId);
    let roleDetails = projectRolesResponse?.data?.projectRoles.map((item, _) => {
      return {
        userId: item.userId,
        projectId: item.projectId,
        editProject: item.editProject,
        viewProject: item.viewProject,
        deleteProject: item.deleteProject,
      };
    });
    setRoleDetails(roleDetails);
  };

  useEffect(() => {
    const slideData = [];
    if (projectDetails?.allRenderUrls && projectData) {
      for (const moduleId in projectData?.[projectDetails?.ProjectID]['Modules']) {
        if (moduleId !== 'status') {
          const module = projectData?.[projectDetails?.ProjectID]['Modules'][moduleId];
          const slides = projectData?.[projectDetails?.ProjectID]['Modules'][moduleId]['slides'];
          const moduleName = module.ModuleName;
          for (const slideId in slides) {
            const slide = slides[slideId];
            const slideName = slide.SlideName;
            const slideUrl = projectDetails.allRenderUrls?.[moduleId]?.[slideId];
            if (slideUrl && slideUrl?.url) {
              slideData.push({
                slideId: slideId,
                moduleId: moduleId,
                moduleName: moduleName,
                slideName: slideName,
                url: slideUrl.url,
                thumbnail: slideUrl.thumbnail_url,
              });
            }
            setSlidesData(slideData);
          }
        }
      }
      if (slideData.length) {
        setActiveVideoURL(slideData[0]?.url);
        setActiveTitle(slideData[0]?.slideName);
      }
    }
  }, [projectDetails, projectData]);

  const handleThumbnailClick = (count, url, slideName) => {
    setActiveThumbnail(count);

    setActiveVideoURL(url);
    setActiveTitle(slideName);
  };
  // Detail Thumbnail End
  const handleAccordionClick = eventKey => {
    if (activeKeys.includes(eventKey)) {
      setActiveKeys(activeKeys.filter(key => key !== eventKey));
      setIsHeaderActive({ ...isHeaderActive, [eventKey]: false });
    } else {
      setActiveKeys([...activeKeys, eventKey]);
      setIsHeaderActive({ ...isHeaderActive, [eventKey]: true });
    }
  };

  const handleViewRender = () => {
    history.push(`/render-detail/${id}`);
  };

  useEffect(() => {
    getNewProjects(id).then(res => {
      setLoading(true);
      try {
        if (res?.status === 200) {
          setProjectDetails(res?.data?.data);
        } else {
          showToaster(res, 'warning');
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log({ detailPageError: error });
      }
    });
  }, [id]);

  useEffect(() => {
    if (projectDetails) {
      setProjectData(JSON.parse(projectDetails?.ProjectData));
      const firstModuleKey = Object.keys(projectDetails.allRenderUrls)[0];
      setActiveThumbnail(Object.keys(projectDetails.allRenderUrls[firstModuleKey])[0]);
    }
  }, [projectDetails]);

  const ListMOdules = () => {
    return (
      <>
        {projectData &&
          projectDetails &&
          Object.keys(projectData?.[projectDetails?.ProjectID]?.['Modules']).map(
            (moduleKey, index) => {
              const module = projectData?.[projectDetails?.ProjectID]['Modules'][moduleKey];
              const slideKeys = Object.keys(module?.slides);
              const slideCount = slidesData.filter(slideData => slideData.moduleId === moduleKey);
              if (slideCount.length) {
                return (
                  <AccordionItem
                    eventKey={index}
                    isActive={activeKeys.includes(index)}
                    modalTitle={module.ModuleName}
                    isHeaderActive={isHeaderActive[index]}
                    onHeaderClick={handleAccordionClick}
                    totalVideo={`Total videos: ${slideCount.length}`}
                  >
                    {slideKeys.map((slideKey, sIndex) => {
                      const slide = module.slides[slideKey];
                      let slideUrl = slidesData?.find(slideData => {
                        return slideData.moduleId === moduleKey && slideData.slideId === slideKey;
                      });
                      let videoURL;
                      if (slideUrl) {
                        videoURL = slideUrl.url;
                      }
                      return (
                        <DetailThumbnail
                          className={activeThumbnail === slideKey ? 'active' : ''}
                          key={slideKey}
                          thumbnail={
                            projectDetails?.allRenderUrls[moduleKey][slideKey]?.thumbnail_url
                          }
                          title={slide.SlideName}
                          count={slideKey.replace('SL', '')}
                          onClick={() => handleThumbnailClick(slideKey, videoURL, slide.SlideName)}
                        />
                      );
                    })}
                  </AccordionItem>
                );
              }
            }
          )}
      </>
    );
  };
  useEffect(() => {
    setLoading(true);
    if (roleDetails && projectDetails) {
      const role = roleDetails.find(role => role.projectId === projectDetails?.ProjectID);
      setRole(role);
      setLoading(false);
    }
  }, [roleDetails, projectDetails]);

  return (
    <>
      <PageLoader showLoader={loading} />
      {role?.viewProject || isCurrentUserAdmin ? (
        <>
          <Sidebar />
          <main className="main-content-wrapper">
            <div className={`content-wrapper ${isInnerSidebarActive ? 'active' : ''}`}>
              <div className="content-block">
                <HeaderNavigation />
                {isInnerSidebarActive ? (
                  <InnerSidebar>
                    <InnerSidebarHeader
                      title={projectDetails?.ProjectName}
                      subTitle={`Total videos:${slidesData?.length}`}
                      icon={videoIcon}
                    />
                    {slidesData.length ? (
                      <Accordion
                        className="custom-accordion project-detail-accordion"
                        activeKey={activeKeys}
                        onSelect={handleAccordionClick}
                      >
                        {projectDetails && ListMOdules()}
                      </Accordion>
                    ) : null}
                  </InnerSidebar>
                ) : (
                  ''
                )}

                <Row className="screen-wrapper">
                  <Col>
                    <div className="video-preview-wrap">
                      <div className="screen-title-wrap">
                        <EditableContent
                          editable={true}
                          initialContent={activeTitle}
                          className="editable-dark-text"
                        />
                        <Button
                          variant="default"
                          onClick={() =>
                            downloadFile(activeVideoURL, activeTitle, setIsDownloading)
                          }
                          disabled={isDownloading}
                        >
                          {isDownloading ? 'Downloading ...' : `Download`}
                          {renderIcon}
                        </Button>
                      </div>
                      <VideoPreview
                        alt="video preview"
                        activeMediaUrl={activeVideoURL}
                        src="../../assets/img/video-preview.png"
                      />
                    </div>
                  </Col>
                  <Col className="detail-sidebar">
                    {role?.editProject || currentUserRoles ? (
                      <CircleIcon
                        icon={editVideoIcon}
                        title="Edit Video"
                        onClick={() => history.push(`/edit-projects/${id}`)}
                      />
                    ) : null}
                    <CircleIcon
                      icon={viewRenderIcon}
                      title="View Render"
                      onClick={handleViewRender}
                    />
                    {/* <CircleIcon icon={cloneIcon} title="Clone Video" /> hidden for now
                <CircleIcon icon={deleteIcon} title="Delete Video" /> */}
                  </Col>
                </Row>
              </div>
            </div>
          </main>
        </>
      ) : (
        <DataNotFound icon={noProjectIcon} title="you do not have access to this page" />
      )}
    </>
  );
}
export default DetailProjects;
