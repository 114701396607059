import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';

import jwt from 'jwt-simple';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import FormInputGroup from './components/FormInputGroup';
import 'react-phone-input-2/lib/bootstrap.css';

// Icons
import {
  aiContentProLogo,
  mailIcon,
  lockIcon,
  userIcon,
  eyeOpenIcon,
  eyeCloseIcon,
  domainIcon,
  orgName,
  contentProLogo,
} from '../../constants/icon';
import { showToaster } from '../../services/helper';
import { createOrgnaizationUsers, inserttemplatePlan, userLogin } from '../../services/authService';
import { checkDomain, createClient } from '../../services/api.service';
import { joinUserToRoom } from '../../services/socket.service';
import Loading from '../../components/Loading';

function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [checkDomainData, setCheckDomainData] = useState();
  const [invalidDomain, setInvalidDomain] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  const [load, setLoad] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const [activeOrg, setActiveOrg] = useState(false);
  const history = useHistory();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Start of Color Mode Switcher
  const [isDarkMode, setIsDarkMode] = useState(false);

  const determineDarkMode = () => {
    const isDark = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(isDark);
  };
  useEffect(() => {
    determineDarkMode();
  }, []);
  // End of Color Mode Switcher

  useEffect(() => {
    if (errorCode === 422 || errorCode === 429) {
      disableButtonFor15Minutes();
    }
  }, [errorCode]);

  function disableButtonFor15Minutes() {
    setButtonDisabled(true);

    setTimeout(
      () => {
        setButtonDisabled(false);
      },
      15 * 60 * 1000
    ); // 15 minutes in milliseconds
  }

  useEffect(() => {
    if (localStorage.getItem('_olive_user')) {
      history.push('/list-projects');
    }
  }, []);

  useEffect(() => {
    const subDomain = window.location.host.split('.')[0];
    if (subDomain.length && subDomain !== 'www') {
      checkDomain(window.location.host.split('.')[0])
        .then(response => {
          if (response.data.exists) {
            setCheckDomainData(response.data.data);
            setInvalidDomain(false);
            if (!response.data.data.isActive) {
              setActiveOrg(false);
              // window.location.href = `http://localhost:3000/page-not-found`;// for localhost
             window.location.href = `http://www.${process.env.REACT_APP_API_DOMAIN}page-not-found`; // for dev server
            } else {
              setActiveOrg(true);
            }
          } else {
            setInvalidDomain(true);
          }
        })
        .finally(() => {
          setPageLoad(false);
        });
    } else {
      setPageLoad(false);
    }
  }, []);

  const loginFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .trim()
        .email('Email is invalid')
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Email is required'),
      password: Yup.string().trim().required('Password is required'),
    }),
    onSubmit: values => {
      try {
        setLoad(true);
        userLogin(values, { clientId: checkDomainData._id })
          .then(response => {
            if (response.data) {
              if (response.data.userData) {
                setLoad(false);
                setButtonDisabled(false);
                window.localStorage.setItem('_user', JSON.stringify(response.data.token));
                let loggedinUserDetails = jwt.encode(
                  response.data.userData,
                  process.env.REACT_APP_JWT_SECRET,
                  'HS512'
                );
                window.localStorage.setItem('_olive_user', JSON.stringify(loggedinUserDetails));
                joinUserToRoom();
                history.push('/list-projects');
                setErrorCode(null);
              } else {
                showToaster('Something went wrong. Please Try Again', 'warning');
                setLoad(false);
              }
            } else if (response.status !== '') {
              if (response.message) {
                setErrorMessage(response.message);
                setErrorCode(response.code);
                setLoad(false);
              } else {
                setErrorMessage(response.message);
                setErrorCode(response.code);
                setLoad(false);
              }
            } else {
              showToaster('No response from the server', 'warning');
              setLoad(false);
            }
          })
          .catch(e => {
            console.log(e, 'error');
            setLoad(false);
          })
          .finally(() => {
            setLoad(false);
          });
      } catch (e) {
        console.log('error!', e);
        showToaster('Something went wrong. Please Try Again', 'warning');
      }
    },
  });

  return (
    <>
      <div className="theme" data-color-mode={isDarkMode ? 'dark' : 'light'}>
        <Row className="login-page-wrapper">
          <Col xs={12} md={6} xl={7}>
            <div className="image-container">
              <img className="img-fluid" src="../../assets/img/ai-intro.png" alt="ai content pro" />
            </div>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <div className="login-page">
              <div className="login-page-form">
                <div className="login-page-logo">{contentProLogo}</div>
                <h2>Create amazing content</h2>
                <h4>Welcome back! Please use your official email to sign in.</h4>
                <>
                  <form onSubmit={loginFormik.handleSubmit}>
                    <FormInputGroup
                      leftIcon={mailIcon}
                      rightIcon={lockIcon}
                      type="email"
                      id="email"
                      placeholder=""
                      label="Email"
                      onChange={loginFormik.handleChange}
                      inputValue={loginFormik.values.email}
                      formik={loginFormik}
                    />
                    {loginFormik.errors.email ? (
                      <div className="input-error-msg mb-3">{loginFormik.errors.email}</div>
                    ) : null}

                    <FormInputGroup
                      leftIcon={lockIcon}
                      rightIcon={showPassword ? <>{eyeOpenIcon}</> : <>{eyeCloseIcon}</>}
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      placeholder=""
                      label="Password"
                      onClick={togglePasswordVisibility}
                      onChange={loginFormik.handleChange}
                      inputValue={loginFormik.values.password}
                      formik={loginFormik}
                    />
                    {loginFormik.errors.password ? (
                      <div className="input-error-msg mb-3">{loginFormik.errors.password}</div>
                    ) : null}

                    <button
                      type="submit"
                      className={buttonDisabled ? 'primary-btn disabled-button' : 'primary-btn'}
                      disabled={buttonDisabled || load}
                    >
                      {load ? 'Logging in...' : 'Login'}
                    </button>

                    {errorMessage ? (
                      <div className="input-error-msg mb-3">{errorMessage}</div>
                    ) : null}
                  </form>
                </>

                <span className="login-policy-text">
                  By Creating an account, I declare that I have read and accepted AI Content Pro{' '}
                  <a href="#top">Terms of Use</a> and <a href="#top">Privacy Policy.</a>{' '}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default LoginPage;
