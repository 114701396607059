import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { updateOSTConfig } from "../../services/authService";
import { showToaster, getUserItem } from "../../services/helper";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { uploadMediaFile } from "../../services/api.service";
import axios from "axios";
import ChipInput from "material-ui-chip-input";
import { useEffect } from "react";
export default function UploadMultipleAsset(props) {
  let DisplayMode = useSelector(state => state.project.DisplayMode);
  const [errorStatus, setErrorStatus] = useState({});
  const assetTypes = ["IMAGE", "VIDEO", "ICON", "LOGO"];
  const imgTypes = [".png", ".jpeg", ".jpg"];
  const iconTypes = [".ico"];
  const videoTypes = [".mp4"];
  let userId = getUserItem("id");
  let userName = getUserItem("name");
  let AdminRole = getUserItem("role");
  let userParentId = getUserItem("orgId");
  const abortController = useRef(null);
  const isClosedRef = useRef(false);

  const [fileType, setFileType] = useState("IMAGE");
  const [files, setFiles] = useState([]);
  const [tags, setTags] = useState("");
  const [isPrivate, setIsPrivate] = useState(0);
  const [uploadingData, setUploadingData] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [acceptTypes, setAcceptTypes] = useState(imgTypes);
  const [illustrationType, setIllustrationType] = useState(false);

  useEffect(() => {
    if (props.isAddFile) {
      isClosedRef.current = false;
    }
    if (assetTypes.indexOf(props.mediaType) > -1) {
      setFileType(props.mediaType);
    }
  }, [props.isAddFile, props.mediaType]);

  useEffect(() => {
    let accTypes = imgTypes;
    if (fileType === "VIDEO") {
      accTypes = videoTypes;
    } else if (fileType === "ICON") {
      accTypes = iconTypes;
    }
    if (["ICON", "LOGO"].indexOf(fileType) > -1) {
      setIllustrationType(true);
    } else {
      setIllustrationType(false);
    }
    setAcceptTypes(accTypes);
    setErrorStatus({});
    setUploadingData({});
    setFiles([]);
    setIsUploading(false);
  }, [fileType]);

  const checkErrorStatus = () => {
    const errStatus = { ...errorStatus };
    // if (fileType !== "IMAGE" && (!tags || (tags && !tags.trim()))) {
    //   errStatus["tags"] = true;
    // }
    if (assetTypes.indexOf(fileType) === -1) {
      errStatus["filetype"] = true;
    }
    if ([1, 0].indexOf(isPrivate) === -1) {
      errStatus["isprivate"] = true;
    }

    setErrorStatus(errStatus);
    return errStatus;
  };

  const onChange = (field, e) => {
    const value = e.target.value;
    // console.log(field, value)
    setErrorStatus(status => {
      let newStatus = { ...status };
      let isError = false;
      switch (field) {
        case "filetype":
          if (assetTypes.indexOf(value) === -1) {
            isError = true;
          }
          break;
        case "tags":
          if (!value || (value && !value.trim())) {
            isError = true;
          }
          break;
        case "isprivate":
          if ([1, 0].indexOf(parseInt(value, 10)) === -1) {
            isError = true;
          }
          break;
        default:
      }
      if (isError) {
        newStatus[field] = true;
      } else {
        delete newStatus[field];
      }
      return { ...newStatus };
    });
    switch (field) {
      case "filetype":
        setFileType(value);
        break;
      case "tags":
        setTags(value);
        break;
      case "isprivate":
        setIsPrivate(parseInt(value, 10));
        break;
      default:
    }
  };

  const onCancelClick = () => {
    // try {
    //   abortController.current.cancel();
    // } catch (e) {}
    isClosedRef.current = true;
    props.closeAddFile();
    setErrorStatus({});
    setUploadingData({});
    setFileType("IMAGE");
    setFiles([]);
    setTags("");
    setIsPrivate(0);
    setIsUploading(false);
    setAcceptTypes(imgTypes);
  };

  const onSubmit = async e => {
    try {
      e.preventDefault();
      const errorList = Object.keys(checkErrorStatus());
      if (errorList.length > 0) {
        return;
      }
      setIsUploading(true);
      const uploadPromise = [];
      for (let i = 0; i < files.length; i++) {
        if (isClosedRef.current) {
          break;
        }
        const file = files[i];
        setUploadingData(data => {
          const newData = { ...data };
          newData[i] = { file: file.name, progress: 0, isAborted: false };
          return { ...newData };
        });

        const tags = getTagsFromFileName(file.name);

        let data = new FormData();
        abortController.current = axios.CancelToken.source();
        const config = {
          // timeout: 1000 * 50000,
          headers: {
            "content-type": "multipart/form-data",
            media_type: fileType,
            user_id: userId,
            file_name: file.name,
            tag_names: tags,
            private: isPrivate,
          },
          cancelToken: abortController.current.token,
          // onUploadProgress: progressEvent => {
          //   const percentCompleted = Math.round(
          //     (progressEvent.loaded * 100) / progressEvent.total
          //   );
          //   setUploadingData(data => {
          //     const newData = { ...data };
          //     newData[i].progress = percentCompleted;
          //     return { ...newData };
          //   });
          // },
        };
        data.append("file", file);
        uploadPromise.push(uploadMediaFile(data, config));
        // const response = await uploadMediaFile(data, config);
        // console.log("response upload", response);
        // if (response.status === 101) {
        //   showToaster(`${file.name}: ${response.message}`, "warning");
        // } else if (response.status === 200) {
        //   showToaster(`${file.name}: Uploaded Successfully`, "success");
        // } else {
        //   showToaster(`${file.name}: ${response.message}`, "warning");
        // }
      }

      Promise.allSettled(uploadPromise);

      showToaster("Upload in progress...", "info");

      setTimeout(() => {
        setIsUploading(false);
        setFiles([]);
        props.uploadSuccess({ isPrivate: isPrivate === 1, fileType });
        onCancelClick();
      }, 1000);
    } catch (err) {
      setIsUploading(false);
      setFiles([]);
      showToaster("Something went wrong. Please Try Again", "warning");
    }
  };

  const onAbortClick = idx => {
    abortController.current.cancel();
    setUploadingData(data => {
      const newData = { ...data };
      newData[idx].isAborted = true;
      return { ...newData };
    });
  };

  const onFileChange = e => {
    const files = e.target.files;
    // console.log(files);
    const errors = [];
    setUploadingData({});
    for (const file of files) {
      const fileNameSplit = file.name.split(".").filter(f => f && f.trim());
      if (fileNameSplit.length <= 1) {
        errors.push(`Invalid file ${file.name}`);
      }
      const ext = `.${fileNameSplit[fileNameSplit.length - 1]}`;
      if (file.size > 2.5e7) {
        errors.push(`File size for ${file.name} greater than 25 MB`);
      }

      switch (fileType) {
        case "IMAGE":
        case "LOGO":
          if (imgTypes.indexOf(ext) === -1) {
            errors.push(`Invalid file type for ${file.name}.`);
          }
          break;
        case "VIDEO":
          if (videoTypes.indexOf(ext) === -1) {
            errors.push(`Invalid file type for ${file.name}.`);
          }
          break;
        case "ICON":
          if (iconTypes.indexOf(ext) === -1) {
            errors.push(`Invalid file type for ${file.name}.`);
          }
          break;
        default:
      }
    }
    if (!errors.length) {
      setFiles(files);
    }
    setErrorStatus(status => {
      let newStatus = { ...status };
      if (errors.length) {
        newStatus["file"] = [...errors];
      } else {
        delete newStatus["file"];
      }
      return { ...newStatus };
    });
  };

  const listUploads = () => {
    const arr = [];
    const uploadingFilesList = [];
    const uploadedFilesList = [];
    const abortedFilesList = [];
    for (let i in uploadingData) {
      if (uploadingData[i].progress < 100 && !uploadingData[i].isAborted) {
        uploadingFilesList.push(
          <div key={i} className="alert-list">
            <span>{uploadingData[i].file}</span>
            <button type="button" title="Cancel Upload" onClick={onAbortClick.bind(null, i)} className="close" data-dismiss="alert" aria-label="Close">
              <img src="assets/img/close-1.png" />
            </button>
            <div className="progress custom-progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${uploadingData[i].progress}%` }}
                aria-valuenow={uploadingData[i].progress}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        );
      }
      if (uploadingData[i].progress === 100) {
        uploadedFilesList.push(
          <div key={i} className="alert-list active">
            <span>{uploadingData[i].file}</span>
            {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <img src="assets/img/close-1.png" />
            </button> */}
          </div>
        );
      }
      if (uploadingData[i].isAborted) {
        abortedFilesList.push(
          <div key={i} className="alert-list">
            <span>{uploadingData[i].file}</span>
          </div>
        );
      }
    }
    if (uploadingFilesList.length) {
      const upFilesLen = uploadingFilesList.length + abortedFilesList.length + uploadedFilesList.length;
      arr.push(
        <div className="add-new-inner">
          <h4>
            Uploading - {upFilesLen}/{files.length} files
          </h4>
          <div className="add-new-list">{uploadingFilesList}</div>
        </div>
      );
    }
    if (uploadedFilesList.length) {
      arr.push(
        <div className="add-new-inner">
          <h4>Uploaded</h4>
          <div className="add-new-list">{uploadedFilesList}</div>
        </div>
      );
    }
    if (abortedFilesList.length) {
      arr.push(
        <div className="add-new-inner">
          <h4>Cancelled</h4>
          <div className="add-new-list">{abortedFilesList}</div>
        </div>
      );
    }
    return arr;
  };

  const onIllusChange = () => {
    // console.log('illuschange')
    setFileType("ICON");
  };

  // const onTagsChange = data => {
  //   onChange("tags", { target: { value: data.toString() } });
  // };

  const onRemoveSelected = idx => {
    setFiles(fs => {
      let newFs = [...fs];
      newFs.splice(idx, 1);
      return [...newFs];
    });
  };

  const listSelectedFiles = () => {
    if (!files.length || isUploading) {
      return null;
    }
    const filesList = [];
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      filesList.push(
        <div key={i} className="alert-list d-block">
          <div className="d-flex">
            <span>{f.name}</span>
            <button type="button" onClick={onRemoveSelected.bind(null, i)} className="close" data-dismiss="alert" aria-label="Close">
              <img src="assets/img/close-1.png" />
            </button>
          </div>
          <span className="d-block fst-italic">
            Tags: <span>{getTagsFromFileName(f.name)}</span>
          </span>
        </div>
      );
    }
    return (
      <div className="add-new-inner">
        <h4>Selected files</h4>
        <div className="add-new-list">{filesList}</div>
      </div>
    );
  };

  const getTagsFromFileName = fileName => {
    const fileNameSplit = fileName.split(".").filter(f => f && f.trim());
    const fileNameTags = fileNameSplit[0].split("-");
    const tags = fileNameTags.filter(tag => tag).join(" ");

    return tags;
  };

  const disabledStyle = isUploading ? { cursor: "not-allowed" } : {};
  const uploadDisabled = !files.length || isUploading || Object.keys(errorStatus).length;
  const uploadDisabledStyle = uploadDisabled ? { cursor: "not-allowed" } : {};

  return (
    <Modal
      isOpen={props.isAddFile}
      fade={true}
      centered
      className={
        DisplayMode ? "modal-dialog  modal-dialog-centered custom-modal modal-lg dark add-new-file " : "modal-dialog  modal-dialog-centered custom-modal modal-lg  add-new-file"
      }
    >
      <div className="modal-content border-0 rounded-1">
        <ModalBody>
          <div onClick={onCancelClick} className="modal-close text-right" data-bs-dismiss="modal">
            <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g data-name="Layer 2">
                <g data-name="close">
                  <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                  <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"></path>
                </g>
              </g>
            </svg>
          </div>
          <div className="text-center mb-2">
            <h2>Add Multiple Files</h2>
          </div>
          <small className="text-start">Choose the File Type</small>

          <ul>
            <li>
              <div className="radio-btn">
                <input
                  type="radio"
                  disabled={isUploading}
                  style={disabledStyle}
                  onChange={onChange.bind(null, "filetype")}
                  value="IMAGE"
                  id="test1"
                  name="radio-group"
                  checked={fileType === "IMAGE"}
                />
                <label for="test1"></label>
              </div>
              <div className="files-img">
                <img src="assets/img/Image.svg" />
              </div>
              <span>Images</span>
            </li>
            <li>
              <div className="radio-btn">
                <input
                  type="radio"
                  disabled={isUploading}
                  style={disabledStyle}
                  onChange={onChange.bind(null, "filetype")}
                  value="VIDEO"
                  id="test2"
                  name="radio-group"
                  checked={fileType === "VIDEO"}
                />
                <label for="test2"></label>
              </div>
              <div className="files-img">
                <img src="assets/img/FilmStrip.svg" />
              </div>
              <span>Videos</span>
            </li>
            <li>
              <div className="radio-btn">
                <input type="radio" disabled={isUploading} style={disabledStyle} onChange={onIllusChange} id="test3" name="radio-group" checked={illustrationType} />
                <label for="test3"></label>
              </div>
              <div className="files-img">
                <img src="assets/img/vector-pen.svg" />
              </div>
              <span>Illustration</span>
            </li>
          </ul>

          <p className="my-3 my-md-4">
            {`File types allowed: ${acceptTypes.join(", ")}`}
            <br />
            Maximum file size allowed: 25 MB
          </p>
          <form onSubmit={onSubmit} id="login-form">
            <div className="row">
              {["IMAGE", "VIDEO"].indexOf(fileType) === -1 && (
                <div className="col">
                  <div className="mb-3">
                    <small className="mb-0 text-start">Type</small>
                    <div className="d-flex">
                      <div className="p-2">
                        <label>
                          <input
                            type="radio"
                            disabled={isUploading}
                            style={disabledStyle}
                            className="align-middle"
                            onChange={onChange.bind(null, "filetype")}
                            value="ICON"
                            id="test1"
                            name="radio-group"
                            checked={fileType === "ICON"}
                          />
                          <small className="mb-0 text-start px-2">Icon</small>
                        </label>
                      </div>
                      <div className="p-2">
                        <label>
                          <input
                            type="radio"
                            disabled={isUploading}
                            style={disabledStyle}
                            className="align-middle"
                            onChange={onChange.bind(null, "filetype")}
                            value="LOGO"
                            id="test1"
                            name="radio-group"
                            checked={fileType === "LOGO"}
                          />
                          <small className="mb-0 text-start px-2">Logo</small>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col">
                <div className="mb-3">
                  <small className="mb-0 text-start">Visibility</small>
                  <div className="d-flex">
                    {/* <div className="p-2">
                      <label>
                        <input
                          type="radio"
                          disabled={isUploading}
                          style={disabledStyle}
                          className="align-middle"
                          onChange={onChange.bind(null, "isprivate")}
                          value="1"
                          id="test1"
                          name="private-group"
                          checked={isPrivate === 1}
                        />
                        <small className="mb-0 text-start px-2">Private</small>
                      </label>
                    </div> */}
                    <div className="p-2">
                      <label>
                        <input
                          type="radio"
                          disabled={isUploading}
                          style={disabledStyle}
                          className="align-middle"
                          onChange={onChange.bind(null, "isprivate")}
                          value="0"
                          id="test1"
                          name="private-group"
                          checked={isPrivate === 0}
                        />
                        <small className="mb-0 text-start px-2">Public</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {fileType !== "IMAGE" && (
              <div className="mb-3">
                <small className="mb-0 text-start">Tags</small>
                <div className="panel-txtarea-blk asset-txtarea asset-tags mt-2">
                  <ChipInput
                    onChange={onTagsChange}
                    className="panel-txtarea"
                    newChipKeys={["Enter", "Tab", ","]}
                    disabled={isUploading}
                  />
                </div>
                {errorStatus.tags && (
                  <div className="input-error-msg">Tags are required</div>
                )}
              </div>
            )} */}
            {listUploads()}
            {listSelectedFiles()}
            <div className="file">
              <label for="input-file">BROWSE FILES</label>
              <input id="input-file" disabled={isUploading} style={disabledStyle} onChange={onFileChange} accept={acceptTypes.toString()} multiple type="file" />
            </div>
            {errorStatus.file && (
              <div className="input-error-msg">
                {errorStatus.file.map((n, i) => (
                  <div key={i} className="my-1">
                    {n}
                  </div>
                ))}
              </div>
            )}

            <div className="d-flex justify-content-end w-100 footer-btn flex-wrap mt-4">
              <button type="button" onClick={onCancelClick} className="btn btn-outline rounded-0 me-2" data-bs-dismiss="modal">
                CANCEL
              </button>
              <button type="submit" disabled={uploadDisabled} style={uploadDisabledStyle} className="btn btn-primary  rounded-0">
                UPLOAD
              </button>
            </div>
          </form>
        </ModalBody>
      </div>
    </Modal>
  );
}
