import FallBackImage from '../../../../assets/img/Thumbnailv3.jpg';

function CircleDetailBox(props) {
  const { src, name, desc } = props;
  const addImageFallback = event => {
    event.currentTarget.src = { FallBackImage };
  };
  return (
    <div className={`circle-detail-block ${props.className || ''}`}>
      <div className="circle-img-wrap">
        {src?.length ? <img src={src} alt="project" /> : <img src={FallBackImage} alt="project" />}
      </div>
      <div className="info-wrap">
        {name && <h4>{name}</h4>}
        {desc && <span>{desc}</span>}
      </div>
    </div>
  );
}
export default CircleDetailBox;
