import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';

// Icons
import {
  ellipseIcon,
  deleteIcon,
  arrowDownIcon,
  arrowUpIcon,
  moveFirstIcon,
  moveLastIcon,
} from '../../../constants/icon';
import FormTextarea from '../../../components/form/formTextarea';
import TagInput from '../../../components/tagInput';
import { getTemplateData } from '../../../services/api.service';
import { getProjectQueryData, getRandomNumber } from '../../../services/helper';

function CardComponent(props) {
  const {
    index,
    length,
    title,
    screenId,
    ost,
    audioScript,
    validOST,
    tags,
    generatingScreenContent,
    hasTemplate,
    projectData,
    setProjectData,
    deleteScreen,
    handleScreenValueChange,
    project,
    reArrangeScreen,
    activeModule = null,
    activeSlide = null,
  } = props;
  const [isActive, setIsActive] = useState(false);

  const queryData = () => {
    const { from, module, slide } = getProjectQueryData();
    let moduleId = activeModule || 'M01';
    let slideId = activeSlide || 'SL01';

    if (from === 'edit-projects') {
      moduleId = module;
      slideId = slide;
    }

    return { moduleId, slideId };
  };

  let { moduleId, slideId } = queryData();

  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const handleDelete = () => {
    deleteScreen({ screenKey: screenId, title });
  };

  const moveUpHandler = () => {
    reArrangeScreen(index, index - 1);
  };
  const moveDownHandler = () => {
    reArrangeScreen(index, index + 1);
  };
  const moveToFirstHandler = () => {
    reArrangeScreen(index, 0);
  };
  const moveToLastHandler = () => {
    reArrangeScreen(index, length - 1);
  };

  const handleOstChange = async value => {
    if (!generatingScreenContent) {
      const regMatch = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};:\\|,.0-9<>\/?'"`]*$/.test(value);

      let projectInputs = JSON.parse(JSON.stringify(projectData));
      projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens[screenId][
        'OS01'
      ].Contentparams.contentdescription = value + '[P]';
      setProjectData(projectInputs);
      const screenContent = {
        [project.ProjectID]: {
          ProjectName: project.ProjectName,
          Modules: {
            [moduleId]: {
              ModuleName: projectInputs[project.ProjectID]?.['Modules']?.[moduleId]?.['ModuleName'],
              slides: {
                [slideId]: {
                  SlideName:
                    projectInputs[project.ProjectID]?.['Modules']?.[moduleId]?.['slides'][
                      slideId
                    ]?.['SlideName'],
                  SlideAudioVO: 'Null',
                  Screens: {
                    [screenId]:
                      projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens[
                        screenId
                      ],
                  },
                },
              },
            },
          },
        },
      };

      const templateResponse = await getTemplateData(screenContent, project.ProjectID);
      let templateAvailable = false;
      if (templateResponse?.[0]?.length) {
        const randomNumber = getRandomNumber({ max: templateResponse[0].length - 1 });
        projectInputs[project.ProjectID]['Modules'][moduleId]['slides'][slideId]['Screens'][
          screenId
        ]['TM01']['Contentparams']['contentdescription'] =
          templateResponse[0][randomNumber].mediaUrl;
        templateAvailable = true;
      } else {
        projectInputs[project.ProjectID]['Modules'][moduleId]['slides'][slideId]['Screens'][
          screenId
        ]['TM01']['Contentparams']['contentdescription'] = 'no template';
      }

      setProjectData(projectInputs);
      handleScreenValueChange({
        screenKey: screenId,
        tags,
        ost: value,
        validOST: regMatch,
        audioScript,
        hasTemplate: templateAvailable,
      });
    }
  };

  const handleAudioScriptChange = async value => {
    if (!generatingScreenContent) {
      let projectInputs = JSON.parse(JSON.stringify(projectData));
      projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens[screenId][
        'AS01'
      ].Contentparams.contentdescription = value;
      setProjectData(projectInputs);

      handleScreenValueChange({
        screenKey: screenId,
        tags,
        ost,
        validOST,
        audioScript: value,
        hasTemplate,
      });
    }
  };

  const handleTagChange = value => {
    if (!generatingScreenContent) {
      if (value.length) {
        let projectInputs = JSON.parse(JSON.stringify(projectData));
        projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens[screenId][
          'VD01'
        ].Contentparams.contenttag = value.toString();
        setProjectData(projectInputs);
        handleScreenValueChange({
          screenKey: screenId,
          tags: value.toString(),
          ost,
          validOST,
          hasTemplate,
          audioScript,
        });
      }
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      role="button"
      className="common-dropdown-btn"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
    >
      {children}
    </div>
  ));

  return (
    <Card className={`common-card ${isActive ? 'active' : ''}`} onClick={toggleActive}>
      <Card.Header>
        <Card.Title>{title}</Card.Title>
        <div className="common-dropdown-wrap">
          <Dropdown align={{ lg: 'end' }}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {ellipseIcon}
            </Dropdown.Toggle>

            <Dropdown.Menu className="common-dropdown-menu script-dropdown-menu">
              {index !== 0 && (
                <Dropdown.Item eventKey="1" onClick={moveUpHandler}>
                  {arrowUpIcon}Move Up
                </Dropdown.Item>
              )}
              {index !== length - 1 && (
                <Dropdown.Item eventKey="2" onClick={moveDownHandler}>
                  {arrowDownIcon}Move Down
                </Dropdown.Item>
              )}
              {index !== 0 && (
                <Dropdown.Item eventKey="3" onClick={moveToFirstHandler}>
                  {moveFirstIcon}Move To First
                </Dropdown.Item>
              )}
              {index !== length - 1 && (
                <Dropdown.Item eventKey="4" onClick={moveToLastHandler}>
                  {moveLastIcon}Move To Last
                </Dropdown.Item>
              )}
              <Dropdown.Item eventKey="5" onClick={handleDelete}>
                {deleteIcon} Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Header>
      <Card.Body>
        {/* <Card.Text>{description}</Card.Text> */}
        <FormTextarea
          placeholder=""
          label="Audio Script"
          inputValue={audioScript}
          showInputLength={true}
          onChange={handleAudioScriptChange}
          className="mb-4"
          disabled={generatingScreenContent}
        />
        <FormTextarea
          placeholder=""
          label="Paragraph"
          inputValue={ost}
          showInputLength={true}
          onChange={handleOstChange}
          className="mb-4"
          disabled={generatingScreenContent}
        />
        {!hasTemplate && (ost.length < 180 || ost.length > 220) && (
          <p className="text-danger mb-4">
            Template not found. Please input Paragraph between 35 and 250 characters
          </p>
        )}
        {!validOST && (
          <p className="text-danger mb-4">
            Paragraph has invalid characters
          </p>
        )}
        <TagInput
          label="Add Tag"
          onChange={handleTagChange}
          placeholder="Video Tags"
          disabled={generatingScreenContent}
          tags={tags}
          handleFocus={() => console.log('focus')}
        />
      </Card.Body>
    </Card>
  );
}

export default CardComponent;
