import React, { useEffect, useState } from "react";
import ReactImageFallback from "react-image-fallback";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { bindActionCreators } from "redux";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { uploadMediaFile } from "../../../../services/api.service";
import { getUserItem, showToaster } from "../../../../services/helper";
import { actionCreator } from "../../../../store";
import Videoplayer from "../../../components/Videoplayer/Videoplayer";
import AssetRepo from "../RightBar/AssetRepo";
import IconRepo from "../RightBar/IconRepo";
import VideoRepo from "../RightBar/Videorepo";
import CanvasImg from "./CropImage/CanvasImg";
import CropImage from "./CropImage/CropImage";
import pageLoader from "../../../../services/pageLoader";
export default function EditAssetImage(props) {
  const [openAssetRpo, setOpenAssetRepo] = useState(false);
  const [openIcontRpo, setOpenIcontRepo] = useState(false);
  const [openVideoRpo, setOpenVideoRpo] = useState(false);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [displayImage, setdisplayImage] = useState("");
  const [cropMode, setcropMode] = useState(false);
  const [cropImageReq, setcropImageReq] = useState(false);
  const [contrast, setcontrast] = useState(100);

  const [changedContrast, setchangedContrast] = useState(100);
  const [exposure, setexposure] = useState(100);
  const [changedExposure, setchangedExposure] = useState(100);
  const [saturation, setsaturation] = useState(100);
  const [changedSaturation, setchangedSaturation] = useState(100);
  const [opacity, setopacity] = useState(100);
  const [changedOpacity, setchangedOpacity] = useState(100);
  const [processEditImg, setprocessEditImg] = useState(false);
  const [newImage, setnewImage] = useState("");
  const [uploadImage, setuploadImage] = useState(false);
  const [showLoader, setshowLoader] = useState(false);

  const dispatch = useDispatch();
  let { UpdatePreviewFeature } = bindActionCreators(actionCreator, dispatch);

  useEffect(() => {
    if (props) {
      if (props.item) {
        // if (props.item[1].Contentparams.contentdescription) {
        console.log(props);
        setdisplayImage(props.item[1]?.Contentparams?.contentdescription);
        // }
      }
    }
  }, [props.item]);
  // useEffect(() => {
  //   if(props.openAssetModals){
  //     //setcropMode(false)
  //   }

  // }, [props.openAssetModals])

  const openAssetRepo = (type) => {
    if (type === "image") {
      setOpenAssetRepo(true);
    } else if (type === "illustration") {
      setOpenIcontRepo(true);
    } else if (type === "video") {
      setOpenVideoRpo(true);
    }
  };

  const selectImage = (image) => {
    //console.log(image);
    setdisplayImage(image);
  };
  const UpdateImage = () => {
    if (props.type === "video") {
      let asset = props.item[1];
      asset.Contentparams.contentdescription = displayImage;
      let data = {
        [props.item[0]]: asset
      };
      UpdatePreviewFeature(data);
      showToaster("Video saved successfully", "success");
      props.closePreview();
    } else if (props.type === "illustration") {
      let asset = props.item[1];
      asset.Contentparams.contentdescription = displayImage;
      let data = {
        [props.item[0]]: asset
      };
      showToaster("Illustration saved successfully", "success");
      UpdatePreviewFeature(data);
      props.closePreview();
    } else {
      setshowLoader(true);
      setprocessEditImg(true);
      setuploadImage(true);

      //
    }
    props.setchanges(true);
    //props.closePreview();
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.openAssetModals}
        fade={true}
        className={
          DisplayMode
            ? "modal-dialog  modal-dialog-centered custom-modal edit-modal dark"
            : "modal-dialog  modal-dialog-centered custom-modal edit-modal"
        }
        // className="modal-dialog  modal-dialog-centered custom-modal edit-modal dark"
        centered
      >
        <ModalBody>
          {showLoader && (
            <div className="loader-wrapper video-loader">
              <div className="loader-inner">
                <img src="../../../assets/img/loader.gif"></img>
              </div>
            </div>
          )}
          {/* <div className="modal fade " id="edit-img" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
          {/* <div className="modal-dialog  modal-dialog-centered custom-modal edit-modal dark"> */}
          {/* <div className="modal-content">
            <div className="modal-body"> */}
          <div className="d-flex align-items-center flex-lg-row flex-column">
            <div className={cropMode ? "img-block crop-image" : "img-block"}>
              {cropMode && !processEditImg ? (
                <CropImage
                  img={newImage}
                  className="img-fluid"
                  cropImageReq={cropImageReq}
                  setcropMode={setcropMode}
                  closePreview={props.closePreview}
                  setcropImageReq={setcropImageReq}
                  item={props.item}
                  setdisplayImage={setdisplayImage}
                />
              ) : props.type === "video" ? (
                <Videoplayer thumbnailUrl="" videoUrl={displayImage} showVolume={false} />
              ) : (
                // <video style={{ width: "100%" }} src={displayImage} controls />
                props.item != undefined &&
                (props.type === "image" ? (
                  <>
                    <CanvasImg
                      src={displayImage}
                      setnewImage={setnewImage}
                      setprocessEditImg={setprocessEditImg}
                      processEditImg={processEditImg}
                      contrast={changedContrast}
                      exposure={changedExposure}
                      saturation={changedSaturation}
                      opacity={changedOpacity}
                      setshowLoader={setshowLoader}
                      setuploadImage={setuploadImage}
                      uploadImage={uploadImage}
                      item={props.item}
                      closePreview={props.closePreview}
                      setexposure={setexposure}
                      setcontrast={setcontrast}
                      setsaturation={setsaturation}
                      setopacity={setopacity}
                    />
                    {/* <ReactImageFallback
                      src={displayImage}
                      fallbackImage="../../../assets/img/fallbackImage.jpg"
                      initialImage="../../../assets/img/fallbackImage.jpg"
                      alt="templates"
                      className="img-fluid"
                    /> */}
                  </>
                ) : (
                  <>
                    <ReactImageFallback
                      src={displayImage}
                      fallbackImage="../../../assets/img/fallbackImage.jpg"
                      initialImage="../../../assets/img/fallbackImage.jpg"
                      alt="templates"
                      className="img-fluid"
                    />
                  </>
                ))
              )}
              {/* <img src="../../../assets/img/image-02.png" /> */}
            </div>

            <div className="img-more">
              <div className="d-flex  align-items-center">
                <div className="label-block">
                  <p className="edit-label">Appearance</p>
                </div>
                <div className="field-block d-flex ps-4">
                  <div
                    className={
                      cropMode
                        ? "d-flex flex-column justify-content-center align-items-center replace-btn not-allowed"
                        : "d-flex flex-column justify-content-center align-items-center replace-btn"
                    }
                  >
                    <button
                      onClick={() => {
                        openAssetRepo(props.type);
                      }}
                      type="button"
                      className="btn editor-btn br-2 mb-1 px-0"
                      disabled={cropMode}
                    >
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.9545 6H7.04545C6.74421 6 6.5 6.24421 6.5 6.54545V17.4545C6.5 17.7558 6.74421 18 7.04545 18H17.9545C18.2558 18 18.5 17.7558 18.5 17.4545V6.54545C18.5 6.24421 18.2558 6 17.9545 6Z"
                          stroke=""
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M18.5 13.9454L15.613 11.1544C15.5107 11.0556 15.3719 11 15.2273 11C15.0826 11 14.9439 11.0556 14.8416 11.1544L11.7948 14.0998C11.6925 14.1987 11.5538 14.2543 11.4091 14.2543C11.2644 14.2543 11.1257 14.1987 11.0234 14.0998L9.61297 12.7363C9.51068 12.6375 9.37194 12.5819 9.22727 12.5819C9.08261 12.5819 8.94387 12.6375 8.84158 12.7363L6.5 15"
                          stroke=""
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.5 11C11.0523 11 11.5 10.5523 11.5 10C11.5 9.44772 11.0523 9 10.5 9C9.94772 9 9.5 9.44772 9.5 10C9.5 10.5523 9.94772 11 10.5 11Z"
                          fill=""
                        />
                      </svg>
                    </button>
                    <small>Replace</small>
                  </div>
                  {cropMode ? (
                    <>
                      <div
                        className="d-flex flex-column justify-content-center align-items-center crop-btn active"
                        onClick={() => {
                          setcropMode(false);
                        }}
                      >
                        <button type="button" className="btn editor-btn br-2 mb-1 px-0">
                          <svg
                            version="1.1"
                            width="18"
                            height="20"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 32 32"
                            enable-background="new 0 0 32 32"
                            space="preserve"
                          >
                            <polyline fill="none" stroke="" stroke-width="2" stroke-miterlimit="10" points="9,4 9,23 28,23 " />
                            <polyline fill="none" stroke="" stroke-width="2" stroke-miterlimit="10" points="23,21 23,9 11,9 " />
                            <line fill="none" stroke="" stroke-width="2" stroke-miterlimit="10" x1="9" y1="9" x2="4" y2="9" />
                            <line fill="none" stroke="" stroke-width="2" stroke-miterlimit="10" x1="23" y1="28" x2="23" y2="23" />
                            <line fill="none" stroke="" stroke-width="2" stroke-miterlimit="10" x1="9" y1="23" x2="27" y2="5" />
                          </svg>
                        </button>
                        <small>Cancel Crop</small>
                      </div>
                      <div
                        className="d-flex flex-column justify-content-center align-items-center crop-btn active"
                        onClick={() => {
                          setcropImageReq(true);
                        }}
                      >
                        <button type="button" className="btn editor-btn br-2 mb-1 px-0 active">
                          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z"
                              fill=""
                            />
                          </svg>
                        </button>
                        <small>Apply</small>
                      </div>
                    </>
                  ) : (
                    props.type !== "video" &&
                    props.type !== "illustration" && (
                      <div
                        className="d-flex flex-column justify-content-center align-items-center crop-btn"
                        onClick={() => {
                          setprocessEditImg(true);
                          setcropMode(true);
                          setchangedContrast(100);
                          setchangedExposure(100);
                          setchangedSaturation(100);
                          setchangedOpacity(100);
                        }}
                      >
                        <button type="button" className="btn editor-btn br-2 mb-1 px-0">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 8H5" stroke="" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8 5V16H19" stroke="" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16 14V8H11" stroke="" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16 19V16" stroke="" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </button>
                        <small>Crop</small>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="divider"></div>
              <div className={(cropMode || props.type === "video" || props.type === "illustration") && "custom-disabled"}>
                <div className="d-flex  align-items-center mb-3">
                  <div className="label-block">
                    <p className="edit-label">Exposure</p>
                  </div>
                  <div className="field-block d-flex ps-4">
                    <div className="custom-range-1 d-flex flex-fill align-items-center">
                      <div className="flex-fill me-2 position-relative">
                        {/* <span className="color-box"> </span> */}
                        {/* <input
                          className="range  "
                          type="range"
                          min="0"
                          max="200"
                          value={exposure}
                          onChange={(e) => {
                            setexposure(e.target.value);
                          }}
                          step="1"
                          disabled={
                            cropMode ||
                            props.type === "video" ||
                            props.type === "illustration"
                          }
                        /> */}
                        <Slider
                          className={"range"}
                          value={exposure}
                          min={1}
                          max={200}
                          onChange={(e) => setexposure(e)}
                          onAfterChange={(e) => setchangedExposure(e)}
                          railStyle={{
                            background: "linear-gradient(270deg, #9CA3AF 0%, #E5E7EB 100%)",
                            borderRadius: "100px",
                            width: "100%",
                            height: "6px",
                            display: "block",
                            position: "absolute",
                            top: "4px"
                          }}
                          trackStyle={{
                            display: "none"
                          }}
                          // handleStyle={{}}
                          handleStyle={{
                            //webkitAppearance: 'none,'
                            appearance: "none",
                            width: "10px",
                            height: "14px",
                            background: "#fff",
                            cursor: "pointer",
                            border: "2px solid #DFDFDF",
                            zIndex: 9,
                            borderRadius: "50% 50% 50% 50% / 60% 60% 40% 40%",
                            webkitTransform: "rotate(360deg)",
                            position: "relative",
                            top: "0px"
                          }}
                        />
                      </div>
                      <output className="output border-0" id="">
                        {" "}
                        {exposure}%
                      </output>
                    </div>
                  </div>
                </div>
                <div className="d-flex  align-items-center mb-3">
                  <div className="label-block">
                    <p className="edit-label">Contrast</p>
                  </div>
                  <div className="field-block d-flex ps-4">
                    <div className="custom-range-1 d-flex flex-fill align-items-center">
                      <div className="flex-fill me-2 position-relative">
                        {/* <span className="color-box"> </span> */}
                        {/* <input
                          className="range  "
                          type="range"
                          min="0"
                          max="200"
                          value={contrast}
                          onChange={(e) => {
                            setcontrast(e.target.value);
                          }}
                          step="1"
                          disabled={
                            cropMode ||
                            props.type === "video" ||
                            props.type === "illustration"
                          }
                        /> */}
                        <Slider
                          className={"range"}
                          value={contrast}
                          min={1}
                          max={200}
                          onChange={(e) => setcontrast(e)}
                          onAfterChange={(e) => setchangedContrast(e)}
                          railStyle={{
                            background: "linear-gradient(270deg, #9CA3AF 0%, #E5E7EB 100%)",
                            borderRadius: "100px",
                            width: "100%",
                            height: "6px",
                            display: "block",
                            position: "absolute",
                            top: "4px"
                          }}
                          trackStyle={{
                            display: "none"
                          }}
                          // handleStyle={{}}
                          handleStyle={{
                            //webkitAppearance: 'none,'
                            appearance: "none",
                            width: "10px",
                            height: "14px",
                            background: "#fff",
                            cursor: "pointer",
                            border: "2px solid #DFDFDF",
                            zIndex: 9,
                            borderRadius: "50% 50% 50% 50% / 60% 60% 40% 40%",
                            webkitTransform: "rotate(360deg)",
                            position: "relative",
                            top: "0px"
                          }}
                        />
                      </div>
                      <output className="output border-0" id="">
                        {" "}
                        {contrast}%
                      </output>
                    </div>
                  </div>
                </div>
                <div className="d-flex  align-items-center">
                  <div className="label-block">
                    <p className="edit-label">Saturation</p>
                  </div>
                  <div className="field-block d-flex ps-4">
                    <div className="custom-range-1 d-flex flex-fill align-items-center">
                      <div className="flex-fill me-2 position-relative">
                        {/* <span className="color-box"> </span> */}
                        {/* <input
                          className="range  "
                          type="range"
                          min="0"
                          max="200"
                          value={saturation}
                          onChange={(e) => setsaturation(e.target.value)}
                          disabled={
                            cropMode ||
                            props.type === "video" ||
                            props.type === "illustration"
                          }
                          step="1"
                        /> */}
                        <Slider
                          className={"range"}
                          value={saturation}
                          min={1}
                          max={200}
                          onChange={(e) => setsaturation(e)}
                          onAfterChange={(e) => setchangedSaturation(e)}
                          railStyle={{
                            background: "linear-gradient(270deg, #9CA3AF 0%, #E5E7EB 100%)",
                            borderRadius: "100px",
                            width: "100%",
                            height: "6px",
                            display: "block",
                            position: "absolute",
                            top: "4px"
                          }}
                          trackStyle={{
                            display: "none"
                          }}
                          // handleStyle={{}}
                          handleStyle={{
                            //webkitAppearance: 'none,'
                            appearance: "none",
                            width: "10px",
                            height: "14px",
                            background: "#fff",
                            cursor: "pointer",
                            border: "2px solid #DFDFDF",
                            zIndex: 9,
                            borderRadius: "50% 50% 50% 50% / 60% 60% 40% 40%",
                            webkitTransform: "rotate(360deg)",
                            position: "relative",
                            top: "0px"
                          }}
                        />
                      </div>
                      <output className="output border-0" id="">
                        {" "}
                        {saturation}%
                      </output>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="d-flex  align-items-center mb-4">
                  <div className="label-block">
                    <p className="edit-label">Opacity</p>
                  </div>
                  <div className="field-block d-flex ps-4">
                    <div className="custom-range d-flex align-items-center flex-fill">
                      <div className="flex-fill me-2 position-relative">
                        {/* <span className="color-box"> </span> */}
                        {/* <input
                          className="range  "
                          type="range"
                          min="0"
                          max="200"
                          value={opacity}
                          onChange={(e) => setopacity(e.target.value)}
                          disabled={
                            cropMode ||
                            props.type === "video" ||
                            props.type === "illustration"
                          }
                          step="1"
                        /> */}
                        <Slider
                          className={"range"}
                          value={opacity}
                          min={1}
                          max={200}
                          onChange={(e) => setopacity(e)}
                          onAfterChange={(e) => setchangedOpacity(e)}
                          railStyle={{
                            background:
                              "linear-gradient(90deg, rgba(229, 231, 235, 0) 0%, rgba(229, 231, 235, 0.68) 35.94%, #56C4F5 68.75%, #C156C2 100%)",
                            borderRadius: "100px",
                            width: "100%",
                            height: "6px",
                            display: "block",
                            position: "absolute",
                            top: "4px"
                          }}
                          trackStyle={{
                            display: "none"
                          }}
                          // handleStyle={{}}
                          handleStyle={{
                            webkitAppearance: "none",
                            appearance: "none",
                            width: "10px",
                            height: "10px",
                            background: "transparent",
                            cursor: "pointer",
                            borderRadius: "50px",
                            border: "2px solid #DFDFDF",
                            boxSizing: "border-box",
                            zIndex: 9,
                            top: "9px"
                          }}
                        />
                      </div>
                      <output className="output border-0" id="">
                        {" "}
                        {opacity}%
                      </output>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end footer-btn">
                <button
                  type="button"
                  className="btn btn-outline me-2  rounded-0"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    props.closePreview();
                    setcropImageReq(false);
                    setcropMode(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={cropMode}
                  onClick={() => {
                    // setcontrast(100);
                    // setexposure(100);
                    // setsaturation(100);
                    // setopacity(100);
                    // setchangedContrast(100)
                    // setchangedExposure(100)
                    // setchangedSaturation(100)
                    // setchangedOpacity(100)
                    cropMode ? setcropImageReq(true) : UpdateImage();
                  }}
                  type="button"
                  className="btn btn-primary  rounded-0"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          {/* </div>
          </div> */}
          {/* </div>
      </div> */}
        </ModalBody>
      </Modal>

      <AssetRepo
        item={props.item}
        type={props.type}
        closePreview={() => setOpenAssetRepo(false)}
        openAssetRpo={openAssetRpo}
        selectImage={selectImage}
        setdisplayImage={setdisplayImage}
      />
      <IconRepo
        item={props.item}
        type={props.type}
        closePreview={() => setOpenIcontRepo(false)}
        openAssetRpo={openIcontRpo}
        setdisplayImage={setdisplayImage}
      />

      <VideoRepo
        item={props.item}
        type={props.type}
        closePreview={() => setOpenVideoRpo(false)}
        openAssetRpo={openVideoRpo}
        setdisplayImage={setdisplayImage}
      />
    </React.Fragment>
  );
}
