import React, { useState } from 'react';
import {
  pdfIcon,
  checkIcon,
  ellipseIcon,
  deleteIcon,
  eyeOpenIcon,
} from '../../../../constants/icon';
import FallBackImage from '../../../../assets/img/Thumbnailv3.jpg';
import { capitalizeFirstLetter } from '../../../../services/helper';
import Tooltip from '@mui/material/Tooltip';
import { Dropdown } from 'react-bootstrap';

function AssetsCheckboxBlock(props) {
  const {
    docsContent,
    mediaContent,
    onClick,
    src,
    mediaUrl,
    fileType,
    fileName,
    tagName,
    deleteUrls,
    setDeleteUrls,
    setOpenModal,
  } = props;
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = (e, deleteUrl) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      setDeleteUrls([...deleteUrls, deleteUrl]);
    } else {
      const filteredArray = deleteUrls.filter(item => item !== deleteUrl);
      setDeleteUrls(filteredArray);
    }
  };

  const checkboxClass = isChecked ? 'active' : '';

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      role="button"
      className="common-dropdown-btn"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
    >
      {children}
    </div>
  ));

  const addImageFallback = event => {
    event.currentTarget.src = { FallBackImage };
  };

  const openDeleteModalHandler = (openModal, mediaUrl) => {
    setOpenModal(openModal);
    setDeleteUrls([mediaUrl]);
  };

  return (
    <div className="assets-checkbox-block__wrapper">
      <label className={`assets-checkbox-block ${checkboxClass} ${props.className || ''}`}>
        <div className="input-holder">
          {props.showAssetDeleteOption && (
            <>
              <div className="input-wrap">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={e => toggleCheckbox(e, mediaUrl)}
                />
                <span className="input-icon">{isChecked ? <>{checkIcon}</> : ''}</span>
              </div>

              <div className="common-dropdown-wrap">
                <Dropdown align={{ lg: 'end' }}>
                  <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    {ellipseIcon}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="common-dropdown-menu script-dropdown-menu">
                    <Dropdown.Item
                      eventKey="2"
                      onClick={() => openDeleteModalHandler(true, mediaUrl)}
                    >
                      {deleteIcon} Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </>
          )}
        </div>
        {docsContent && (
          <div className="info-wrap">
            <span className="files-type">{pdfIcon}</span>
            <span className="file-name">File name</span>
          </div>
        )}
        {mediaContent && (
          <div className="assets-checkbox-img-holder">
            <img src={src} alt="checkbox image" onError={addImageFallback} />
            {!mediaUrl && !fileType === 'image' ? null : (
              <span className="icon" role="button" onClick={onClick}>
                {eyeOpenIcon}
              </span>
            )}
          </div>
        )}
      </label>

      <div className="file-name">
        <Tooltip title={fileName}>
          <strong>{fileName}</strong>
        </Tooltip>
      </div>
      <div className="tag-name">
        {tagName.split(',').map((tag, index) => {
          return (
            <Tooltip title={tag} key={index}>
              <p key={index}> {tag} </p>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
}

export default AssetsCheckboxBlock;
