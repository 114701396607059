import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import { useStateWithCallbackLazy } from "use-state-with-callback";

import { checkDomain, createClient } from '../../services/api.service';
import { showToaster } from '../../services/helper';
import {
    aiContentProLogo,
    mailIcon,
    lockIcon,
    userIcon,
    eyeOpenIcon,
    eyeCloseIcon,
    domainIcon,
    orgName,
    contentProLogo
  } from "../../constants/icon";
import timezones from "timezones-list";

import FormInputGroup from './components/FormInputGroup';
import { useHistory } from 'react-router-dom';



const Register = () => {
    const [listtimezone, setListtimezone] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [fonts, setfonts] = useStateWithCallbackLazy();
    const [phone, setPhone] = useState("+91");
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const [loading, setLoading] = useState(false)

    const determineDarkMode = () => {
        const isDark = localStorage.getItem("darkMode") === "true";
        setIsDarkMode(isDark);
    };
    useEffect(() => {
        determineDarkMode();
    }, []);

    useEffect(() => {
        generateTimeZones();
      }, []);
    
      const generateTimeZones = () => {
          let fontOption = [];
          timezones.map((font) => {
            fontOption.push({
              value: font.tzCode,
              label: font.label,
            });
            return true;
          });
          setfonts(fontOption);
      };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      phonenumber: "",
      clientName: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().trim().email("Email is invalid").min(2, "Too Short!").max(50, "Too Long!").required("Email is required"),
      password: Yup.string()
        .trim()
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special Character"
        )
        .required("Password is required"),
      userName: Yup.string().trim().max(20, "Must be exactly 20 characters").min(1, "Minimum 1 characters are required").required("Name is required"),
      clientName: Yup.string()
        .trim()
        .max(20, "Must be exactly 20 characters")
        .min(1, "Minimum 5 characters are required")
        .required("Organization Name is required"),
      subdomain: Yup.string()
        .trim()
        .max(20, "Must be exactly 20 characters")
        .min(3, "Minimum 3 characters are required")
        .required("Account URL is required"),
      phonenumber: Yup.string().trim().required("Phone Number is required").matches(phoneRegExp, "Phone number is not valid"),
      confirmPassword: Yup.string()
     .oneOf([Yup.ref('password'), null], 'Passwords must match')
     .required("Confirm password is required")
    }),
    onSubmit: async (values) => {
      let fullName = values.userName
      let [first_name,...last_name] = fullName.split(   " ").filter(Boolean)
      values.firstName =first_name
      values.countrycode = Number(phone);
      values.timeZone = listtimezone?.value ?? "";
      values.subdomain= values.subdomain
      values.lastName= last_name.join(" ")
      try {
        setLoading(true)
        createClient(values).then((response) => {
          if(response?.data?.data) {
            window.location.href = `http://${values.subdomain}.${process.env.REACT_APP_API_DOMAIN}login`
            values.firstName =""
            values.countrycode ="";
            values.subdomain= ""
            values.lastName= ""
            values.phonenumber = ""
            setLoading(true)
            } else {
                setErrorMessage(response.message)
            }
        }).catch((e) =>{
            console.log(e, 'error');
            setLoading(false)
        }).finally(() =>{
          setLoading(false)
        })
      } catch (e) {
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    }
  });

  const handleChange = async (e) => {
    setListtimezone(e);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="theme" data-color-mode={isDarkMode ? "dark" : "light"}>
    <Row className="login-page-wrapper register-page-wrapper">
      <Col xs={12} md={6} xl={7}>
        <div className="image-container">
          <img
            className="img-fluid"
            src="../../assets/img/ai-intro.png"
            alt="ai content pro"
          />
        </div>
      </Col>
      <Col xs={12} md={6} xl={5}>
        <div className="login-page">
          <div className="login-page-form">
            <div className="login-page-logo">{contentProLogo}</div>
            <h2>Create amazing content</h2>
            <h4>
              Welcome back! Please use your official email to sign in.
            </h4>
            <>
                <form onSubmit={formik.handleSubmit} id="login-form">
                  <FormInputGroup
                    leftIcon={userIcon}
                    rightIcon={lockIcon}
                    type="name"
                    id="userName"
                    placeholder=""
                    label="Full Name"
                    onChange={formik.handleChange}
                    inputValue={formik.values.userName}
                    formik={formik}
                  />
                { formik.errors.userName ? <div className="input-error-msg mb-3">{formik.errors.userName}</div> : null}
                  <FormInputGroup
                    leftIcon={mailIcon}
                    rightIcon={lockIcon}
                    type="email"
                    id="email"
                    placeholder=""
                    label="Email"
                    onChange={formik.handleChange}
                    inputValue={formik.values.email}
                    formik={formik}
                  />
                {formik.errors.email ? <div className="input-error-msg mb-3">{formik.errors.email}</div> : null}

                  <div className=" position-relative">
                    <div className="d-flex country-code">
                      <PhoneInput
                        country={"eg"}
                        enableSearch={true}
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                        
                      />
                      <FormInputGroup
                        type="number"
                        id="phonenumber"
                        placeholder=""
                        label="Phone Number"
                        onChange={formik.handleChange}
                        inputValue={formik.values.phonenumber}
                        formik={formik}
                      />

                    </div>
                { formik.errors.phonenumber ? <div className="input-error-msg mb-3">{formik.errors.phonenumber}</div> : null}
                  </div>
                
                  <FormInputGroup
                    leftIcon={lockIcon}
                    rightIcon={
                      showPassword ? (
                        <>{eyeOpenIcon}</>
                      ) : (
                        <>{eyeCloseIcon}</>
                      )
                    }
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder=""
                    label="Password"
                    onClick={togglePasswordVisibility}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    formik={formik}
                  />
                { formik.errors.password ? <div className="input-error-msg mb-3">{formik.errors.password}</div> : null}

                  <FormInputGroup
                    leftIcon={lockIcon}
                    rightIcon={
                      showPassword ? (
                        <>{eyeOpenIcon}</>
                      ) : (
                        <>{eyeCloseIcon}</>
                      )
                    }
                    type={showPassword ? "text" : "password"}
                    id="confirmPassword"
                    placeholder=""
                    label="Confirm Password"
                    onClick={togglePasswordVisibility}
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                    formik={formik}
                  />
                    {formik.errors.confirmPassword ? <div className="input-error-msg">{formik.errors.confirmPassword}</div> : null}

                  <div>
                    <div className="d-flex org-details align-items-center">
                      <span></span>
                        <p>Organization Details</p>
                      <span></span>
                    </div>
                    <FormInputGroup
                    leftIcon={orgName}
                    type="text"
                    id="clientName"
                    placeholder=""
                    label="Organization Name"
                    onChange={formik.handleChange}
                    inputValue={formik.values.clientName}
                    formik={formik}
                    />
                    { formik.errors.clientName ? <div className="input-error-msg mb-3">{formik.errors.clientName}</div> : null}

                    <FormInputGroup
                      leftIcon={domainIcon}
                      type="text"
                      id="subdomain"
                      placeholder=""
                      label="Account URL"
                      onChange={formik.handleChange}
                      inputValue={formik.values.subdomain}
                      formik={formik}
                    />
                    {formik.errors.subdomain ? <div className="input-error-msg mb-3">{formik.errors.subdomain}</div> : null}
                    <Select
                      className="custom-input rolluser w-100 org-rolleruser custom-form-floating custom-timezone "
                      value={listtimezone}
                      onChange={(e) => handleChange(e)}
                      options={fonts}
                      placeholder="Select timezones"
                    />
                     {formik.errors.timeZone ? <div className="input-error-msg mb-3">{formik.errors.timeZone}</div> : null}
                  </div>
                  <button type="submit" className="primary-btn">
                    {loading ? 'Registering...' : "Register"}
                  </button>
                  {errorMessage.length ? <div className="input-error-msg mb-3">{errorMessage}</div> : null}
                </form>
            </>

            <span className="login-policy-text">
              By Creating an account, I declare that I have read and
              accepted AI Content Pro <a href="#top">Terms of Use</a> and{" "}
              <a href="#top">Privacy Policy.</a>{" "}
            </span>
          </div>
        </div>
      </Col>
    </Row>
  </div>
  )
}

export default Register