import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Col, Dropdown, Row, Spinner } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';

import CardComponent from './CardComponent';
import { FONT_URL } from '../../../constants';
import useFonts from '../../../hooks/fontHook';
import BrandSettingModal from '../brandSettingModal';
import { getProjectQueryData, showToaster } from '../../../services/helper';
import FormTextarea from '../../../components/form/formTextarea';
import { deleteIcon, ellipseIcon, longRightArrowIcon, plusIcon } from '../../../constants/icon';
import { generateProjectPreview, getProject, updateProject } from '../../../services/api.service';
import { addScreenforChatGPT, removeMediaUrlFilter } from '../../../services/DataHelper';
import { searchAssetsVideo } from '../../../services/apiServices';

function GenerateModuleScriptComponent(props) {
  const {
    project,
    scriptContent,
    projectData,
    onScriptContentChange,
    segmentList = [],
    generatingScript,
    clientSettingData,
    handleGenerateScriptClick,
    // setOpenConfirmModal,
    moduleData,
    disableGeneratePreview,
    setDisableGeneratePreview,
    setScreenValues,
    generatingScreenContent,
    setProjectData,
    setModuleData,
    progressPercentage,
    generatingPreviewMessage,
    screenValues,
  } = props;

  const { fonts } = useFonts();
  const [projectImageUrl, setProjectImageUrl] = useState();
  const [showBrandingModal, setShowBrandingModal] = useState(false);

  const params = useParams();
  const [generatingPreview, setGeneratingPreview] = useState(false);
  const [activeModule, setActiveModule] = useState('');
  const [activeSlide, setActiveSlide] = useState('');
  const history = useHistory();
  const timer1Ref = useRef(null);

  useEffect(() => {
    setProjectImageUrl(project.imageUrl);
  }, [project.imageUrl]);

  const redirectUrl = `/edit-projects/${project._id}?checkPreview=1`;

  const handleGeneratePreview = async projectInputsData => {
    setGeneratingPreview(true);
    await updateProjectData(projectInputsData);
    setGeneratingPreview(false);
  };

  const deleteModule = async moduleId => {
    let projectInputs = JSON.parse(JSON.stringify(projectData));
    delete projectInputs[project.ProjectID].Modules[moduleId];
    let moduleDatas = moduleData.filter(module => moduleId !== module.moduleId);
    setProjectData(projectInputs);
    setModuleData(moduleDatas);
  };

  const deleteSlide = async ({ moduleId, slideId }) => {
    let projectInputs = JSON.parse(JSON.stringify(projectData));
    delete projectInputs[project.ProjectID].Modules[moduleId].slides[slideId];
    let moduleDatas = [...moduleData];
    for (const module of moduleDatas) {
      if (module.moduleId === moduleId) {
        module.slides = module.slides.filter(slide => slide.slideId !== slideId);
        module.templatesForAll = module.slides.reduce(
          (result, slide) => result && slide.templatesForAll,
          true
        );
      }
    }
    setProjectData(projectInputs);
    setModuleData(moduleDatas);
  };

  const deleteScreen = ({ screenKey, title }) => {
    let moduleId = activeModule;
    let slideId = activeSlide;
    let screenId = activeSlide.replace('SL', '');

    let projectInputs = JSON.parse(JSON.stringify(projectData));
    projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens = {};
    let screenDatas = [];
    let hasTemplateForAll = true;
    let i = 0;
    let moduleDatas = [...moduleData];
    for (const module of moduleDatas) {
      module.templatesForAll = true;
      for (const slide of module.slides) {
        slide.templatesForAll = true;
        for (const screen of slide.screens) {
          if (module.moduleId === moduleId) {
            if (slideId === slide.slideId) {
              if (screen.screenKey !== screenKey) {
                if (!screen.hasTemplate) {
                  hasTemplateForAll = false;
                }
                screenDatas[i] = screen;
                let newScreenKey = `SC${screenId}.0${i + 1}`;
                if (i + 1 >= 10) {
                  newScreenKey = `SC${screenId}.${i + 1}`;
                }

                projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens[
                  newScreenKey
                ] =
                  projectData[project.ProjectID].Modules[moduleId].slides[slideId].Screens[
                    screen.screenKey
                  ];
                screenDatas[i].screenKey = newScreenKey;
                i++;
              }
            }
          }
        }
        slide.screens = screenDatas;
      }
    }
    setModuleData(moduleDatas);
    setDisableGeneratePreview(!hasTemplateForAll);
    setProjectData(projectInputs);
    setScreenValues(screenDatas);
  };

  const reArrangeScreen = (index, destination) => {
    let projectInputs = JSON.parse(JSON.stringify(projectData));
    let moduleId = activeModule;
    let slideId = activeSlide;
    let screenId = activeSlide.replace('SL', '');
    let moduleDatas = [...moduleData];
    let activeModuleData = moduleData.filter(module => module.moduleId === moduleId)[0];
    let activeSlideData = activeModuleData.slides.filter(slide => slide.slideId === slideId)[0];
    let screenDatas = [...activeSlideData.screens];
    if (destination > index) {
      for (let i = index + 1; i <= destination; i++) {
        let screenKey = `SC${screenId}.0` + i;
        if (i >= 10) {
          screenKey = `SC${screenId}.` + i;
        }
        projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens[screenKey] =
          projectData[project.ProjectID].Modules[moduleId].slides[slideId].Screens[
            activeSlideData.screens[i].screenKey
          ];
        screenDatas[i - 1] = activeSlideData.screens[i];
        screenDatas[i - 1].screenKey = screenKey;
      }
    } else {
      for (let i = index - 1; i >= destination; i--) {
        let screenKey = `SC${screenId}.0` + (i + 2);
        if (i + 2 >= 10) {
          screenKey = `SC${screenId}.` + (i + 2);
        }
        projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens[screenKey] =
          projectData[project.ProjectID].Modules[moduleId].slides[slideId].Screens[
            activeSlideData.screens[i].screenKey
          ];
        screenDatas[i + 1] = activeSlideData.screens[i];
        screenDatas[i + 1].screenKey = screenKey;
      }
    }

    let screenKey = `SC${screenId}.0` + (destination + 1);
    if (destination >= 9) {
      screenKey = `SC${screenId}.` + (destination + 1);
    }

    projectInputs[project.ProjectID].Modules[moduleId].slides[slideId].Screens[screenKey] =
      projectData[project.ProjectID].Modules[moduleId].slides[slideId].Screens[
        activeSlideData.screens[index].screenKey
      ];
    screenDatas[destination] = activeSlideData.screens[index];
    screenDatas[destination].screenKey = screenKey;

    setProjectData(projectInputs);
    for (const module of moduleDatas) {
      if (module.moduleId === moduleId) {
        for (const slide of module.slides) {
          if (slideId === slide.slideId) {
            slide.screens = screenDatas;
          }
        }
      }
    }
    setModuleData(moduleDatas);
  };

  const updateScreenValues = value => {
    let hasTemplateForAll = true;
    let moduleDatas = [...moduleData];
    for (const module of moduleDatas) {
      module.templatesForAll = true;
      for (const slide of module.slides) {
        slide.templatesForAll = true;
        for (const screen of slide.screens) {
          if (module.moduleId === activeModule) {
            if (activeSlide === slide.slideId) {
              if (screen.screenKey === value.screenKey) {
                screen.hasTemplate = value.hasTemplate;
                screen.ost = value.ost;
                screen.tags = value.tags;
                screen.audioScript = value.audioScript;
              }
            }
          }
          if (!screen.hasTemplate) {
            hasTemplateForAll = false;
            slide.templatesForAll = false;
            module.templatesForAll = false;
          }
        }
      }
    }

    setModuleData(moduleDatas);
    setDisableGeneratePreview(!hasTemplateForAll);

    // setScreenValues([...screenValues,)
  };
  useEffect(() => {
    return () => {
      clearTimeout(timer1Ref.current);
    };
  }, []);

  function timeout(delay = 5000) {
    return new Promise(res => setTimeout(res, delay));
  }

  async function updateProjectData(projectInputsData) {
    try {
      let projectDataforAPI = await removeMediaUrlFilter(
        JSON.parse(JSON.stringify(projectInputsData)),
        project.ProjectID
      );
      let projectDataPayload = {
        dbdata: {
          [project.ProjectID]: projectInputsData[project.ProjectID],
        },
        APIData: {
          [project.ProjectID]: projectDataforAPI[project.ProjectID],
        },
        RenderUrl: {
          renderUrls: project.renderUrls,
        },
        type: 'Update',
      };

      let updatedProject = await updateProject(projectDataPayload);
      let projectDataforPreview = JSON.parse(updatedProject.ProjectData);
      let hasProjectDataChanged = false;
      for (const module of moduleData) {
        for (const slide of module.slides) {
          for (const screen of slide.screens) {
            if (screen.hasTemplate) {
              let activeScreen =
                projectDataforPreview[project.ProjectID].Modules[module.moduleId].slides[
                  slide.slideId
                ].Screens[screen.screenKey];
              if (
                activeScreen['VD01']?.Contentparams.contenttag &&
                !activeScreen['VD01']?.Contentparams.contentdescription
              ) {
                let videoResponse = await searchAssetsVideo({
                  tags: activeScreen['VD01']?.Contentparams.contenttag,
                });
                projectDataforPreview[project.ProjectID].Modules[module.moduleId].slides[
                  slide.slideId
                ].Screens[screen.screenKey]['VD01'].Contentparams.contentdescription =
                  videoResponse.data.result.result[0];
                hasProjectDataChanged = true;
              }
              await generatePreview(
                projectDataforPreview,
                project.ProjectID,
                module.moduleId,
                slide.slideId,
                screen.screenKey
              );
              await timeout(5000);
            }
          }
        }
      }
      if (hasProjectDataChanged) {
        let latestProject = await getProject(params.projectId);
        projectInputsData = JSON.parse(latestProject.ProjectData);
        projectInputsData = _.merge(projectInputsData, projectDataforPreview);
        projectDataforAPI = await removeMediaUrlFilter(
          JSON.parse(JSON.stringify(projectInputsData)),
          project.ProjectID
        );
        projectDataPayload = {
          dbdata: {
            [project.ProjectID]: projectInputsData[project.ProjectID],
          },
          APIData: {
            [project.ProjectID]: projectDataforAPI[project.ProjectID],
          },
          RenderUrl: {
            renderUrls: latestProject.renderUrls,
          },
          type: 'Update',
        };

        let updatedProject = await updateProject(projectDataPayload);
      }
      history.push(redirectUrl);
    } catch (error) {
      console.log({ updateProjectDataError: error });
      showToaster(
        'Our Servers seems to be busy. Please click the Generate Preview again',
        'error',
        5000
      );
    }
  }

  const handleAddScreen = async () => {
    if (screenValues.length < clientSettingData.screenLimit) {
      const { from, module, slide, screen } = getProjectQueryData();
      let moduleId = 'M01';
      let slideId = 'SL01';
      let screenId = '01';

      if (from === 'edit-projects') {
        moduleId = module;
        slideId = slide;
        screenId = screen;
      }
      let screenKey = `SC${screenId}.0${screenValues.length + 1}`;
      let title = `Screen 0${screenValues.length + 1}`;
      if (screenValues.length + 1 >= 10) {
        screenKey = `SC${screenId}.${screenValues.length + 1}`;
        title = `Screen ${screenValues.length + 1}`;
      }
      let projectInputs = JSON.parse(JSON.stringify(projectData));
      projectInputs = await addScreenforChatGPT(
        project.ProjectID,
        projectInputs,
        screenKey,
        title,
        '',
        '',
        moduleId,
        slideId
      );
      setScreenValues([
        ...screenValues,
        {
          ost: '',
          tags: '',
          title,
          hasTemplate: false,
          screenKey: screenKey,
          audioScript: '',
        },
      ]);
      setProjectData(projectInputs);
      setDisableGeneratePreview(true);
    }
  };

  const generatePreview = async (projectData, ProjectID, moduleId, slideId, activeScreen) => {
    try {
      const previewContent = {
        [ProjectID]: {
          ProjectName: project.ProjectName,
          Modules: {
            [moduleId]: {
              ModuleName: projectData[ProjectID]?.['Modules']?.[moduleId]?.['ModuleName'],
              slides: {
                [slideId]: {
                  SlideName:
                    projectData[ProjectID]?.['Modules']?.[moduleId]?.['slides'][slideId]?.[
                      'SlideName'
                    ],
                  SlideAudioVO: 'Null',
                  Screens: {
                    [activeScreen]: {
                      ScreenName:
                        projectData[ProjectID]?.['Modules']?.[moduleId]?.['slides']?.[slideId]?.[
                          'Screens'
                        ]?.[activeScreen]?.['ScreenName'],
                      ...projectData?.[ProjectID]?.['Modules']?.[moduleId]?.['slides']?.[slideId]?.[
                        'Screens'
                      ]?.[activeScreen],
                    },
                  },
                },
              },
            },
          },
        },
      };

      await generateProjectPreview(previewContent);
    } catch (error) {
      console.log(error);
    }
  };

  const singleScreenSettings = settings => {
    console.log({ settings });
    const projectInputs = JSON.parse(JSON.stringify(projectData));

    const { bgColor, textColor, fontStyle, fontFamily } = settings;

    for (const module of moduleData) {
      for (const slide of module.slides) {
        let projectScreens =
          projectInputs[project.ProjectID].Modules[module.moduleId].slides[slide.slideId].Screens;

        Object.entries(projectScreens).map(([screenKey, screenValue]) => {
          Object.entries(screenValue).map(([screenValueKey, screenValueValue]) => {
            if (screenValueValue.Contenttype === 'OST') {
              screenValueValue.Contentparams.FontFamily = FONT_URL + fontFamily;

              if (fontStyle) {
                screenValueValue.Contentparams.FontFamily =
                  screenValueValue.Contentparams.FontFamily + '/' + fontStyle;
              }

              screenValueValue.Contentparams.FontColour = textColor;
            }

            if (screenValueKey === 'CS01') {
              screenValueValue.Contentparams.contentdescription = bgColor;
            }
          });
        });
      }
    }
    handleGeneratePreview(projectInputs);
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      role="button"
      className="common-dropdown-btn"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
    >
      {children}
    </div>
  ));

  return (
    <>
      <Row className="screen-wrapper">
        <Col lg={8}>
          <div className="screen-body screen-chat-body">
            <FormTextarea
              id="message"
              placeholder=""
              label="Write script for screen"
              className="chat-gpt-textarea"
              inputValue={scriptContent}
              maxLength={4000}
              showInputLength={true}
              onChange={onScriptContentChange}
            />
            <div className="chat-generate-wrap">
              <span className="characters-count">{scriptContent.length}/4000 characters</span>
              <button
                className={
                  generatingScreenContent || generatingPreview
                    ? ' btn primary-btn disabled'
                    : 'primary-btn'
                }
                disabled={generatingScreenContent || generatingPreview}
                onClick={handleGenerateScriptClick}
              >
                {generatingScript ? (
                  <>
                    Updating
                    <Spinner animation="border" style={{ color: '#6750a4' }} size="sm" />
                  </>
                ) : (
                  <>Update {longRightArrowIcon}</>
                )}
              </button>
            </div>
            {generatingScreenContent && (
              <div className="generate-progress-wrap">
                <div className="generate-title">{generatingPreviewMessage}</div>
                <div className="screen-size">Progress Percent {progressPercentage}</div>
                <div className="loader-line m-0"></div>
              </div>
            )}
          </div>
        </Col>
        <Col lg={4} className="screen-sidebar">
          {moduleData.map((module, moduleIndex) => {
            return (
              <div
                className={`chat-wrap ${activeModule === module.moduleId ? 'active' : ''} ${
                  !module.templatesForAll ? 'red-border' : ''
                }`}
              >
                <div
                  className={`chat-title-wrap ${activeModule === module.moduleId ? 'active' : ''} ${
                    !module.templatesForAll ? 'red-border' : ''
                  }`}
                  key={moduleIndex}
                >
                  <h4
                    role="button"
                    onClick={() => {
                      if (activeModule === module.moduleId) {
                        setActiveModule('');
                      } else {
                        setActiveModule(module.moduleId);
                      }
                      setActiveSlide('');
                    }}
                  >
                    <strong>Module Name:</strong> {module.moduleName}
                  </h4>
                  <div className="common-dropdown-wrap">
                    <Dropdown align={{ lg: 'end' }}>
                      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        {ellipseIcon}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="common-dropdown-menu script-dropdown-menu">
                        <Dropdown.Item
                          eventKey="5"
                          onClick={() => {
                            deleteModule(module.moduleId);
                          }}
                        >
                          {deleteIcon} Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <span>
                    <strong>Total Videos:</strong> {Object.keys(module.slides).length} Videos(s)
                  </span>
                </div>
                {module.slides.map((slide, slideIndex) => {
                  return (
                    activeModule === module.moduleId && (
                      <div
                        className={`chat-wrap ${activeSlide === slide.slideId ? 'active' : ''} ${
                          !slide.templatesForAll ? 'red-border' : ''
                        }`}
                      >
                        <div
                          className={`chat-title-wrap slide ${
                            activeSlide === slide.slideId ? 'active' : ''
                          } ${!slide.templatesForAll ? 'red-border' : ''}`}
                          key={moduleIndex + slideIndex}
                        >
                          <h4
                            role="button"
                            onClick={() => {
                              if (activeSlide === slide.slideId) {
                                setActiveSlide('');
                              } else {
                                setActiveSlide(slide.slideId);
                              }
                            }}
                          >
                            <strong>Video Title:</strong> {slide.SlideName}
                          </h4>
                          <div className="common-dropdown-wrap">
                            <Dropdown align={{ lg: 'end' }}>
                              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                {ellipseIcon}
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="common-dropdown-menu script-dropdown-menu">
                                <Dropdown.Item
                                  eventKey="5"
                                  onClick={() => {
                                    deleteSlide({
                                      moduleId: module.moduleId,
                                      slideId: slide.slideId,
                                    });
                                  }}
                                >
                                  {deleteIcon} Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <span>
                            <strong>Total Screens:</strong> {slide.totalScreens} screen(s)
                          </span>
                          <span>
                            <strong>Progress Percentage:</strong>{' '}
                            {slide.totalCompletedScreen
                              ? Math.round(
                                  (slide.totalCompletedScreen / slide.totalScreens) * 100 * 100
                                ) /
                                  100 +
                                '%'
                              : '0%'}
                          </span>
                        </div>
                        {activeSlide === slide.slideId && (
                          <div
                            className="screen-sidebar-body pe-2"
                            key={moduleIndex + slideIndex + '345'}
                          >
                            {slide.screens.map((screenData, index) => {
                              return (
                                <CardComponent
                                  key={moduleIndex + slideIndex + index}
                                  index={index}
                                  activeModule={activeModule}
                                  activeSlide={activeSlide}
                                  title={screenData.title}
                                  screenId={screenData.screenKey}
                                  ost={screenData.ost}
                                  tags={screenData.tags}
                                  audioScript={screenData.audioScript}
                                  hasTemplate={screenData.hasTemplate}
                                  generatingScreenContent={generatingScreenContent}
                                  projectData={projectData}
                                  setProjectData={setProjectData}
                                  handleScreenValueChange={updateScreenValues}
                                  project={project}
                                  deleteScreen={deleteScreen}
                                  length={slide.screens.length}
                                  reArrangeScreen={reArrangeScreen}
                                />
                              );
                            })}
                            {Object.keys(slide.screens).length < clientSettingData.screenLimit && (
                              <button
                                key={moduleIndex + slideIndex + 'button-key'}
                                className={
                                  generatingScreenContent
                                    ? 'disabled triiger-btn tag-content-btn'
                                    : 'triiger-btn tag-content-btn'
                                }
                                onClick={handleAddScreen}
                                disabled={generatingScreenContent}
                              >
                                <span className="upload-icon">{plusIcon}</span>
                                <span>Add New Screen</span>
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    )
                  );
                })}
              </div>
            );
          })}

          <div className="chat-generate-wrap justify-content-end">
            <button
              className={disableGeneratePreview ? ' btn primary-btn disabled' : 'primary-btn'}
              onClick={() => setShowBrandingModal(true)}
              disabled={disableGeneratePreview || generatingScreenContent || generatingPreview}
            >
              {generatingPreview ? (
                <>
                  Generating preview
                  <Spinner animation="border" style={{ color: '#6750a4' }} size="sm" />
                </>
              ) : (
                <>Generate preview {longRightArrowIcon}</>
              )}
            </button>
          </div>
        </Col>
      </Row>
      <BrandSettingModal
        fonts={fonts}
        settingForSingleScreen
        show={showBrandingModal}
        imageUrl={projectImageUrl}
        onHide={() => setShowBrandingModal(false)}
        setImageUrl={url => setProjectImageUrl(url)}
        singleScreenSettings={singleScreenSettings}
      />
    </>
  );
}
export default GenerateModuleScriptComponent;
