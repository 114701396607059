import axios from 'axios';
import { Axioslib, AxioslibUser } from '../lib/axioslib';
import { getUserItem } from './helper';

export const getRenderDashboard = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== '') {
      AxioslibUser.post('api/dashboard-render', body, {
        headers: {
          user_id: userId,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};
export const getAdminRenderDashboard = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== '') {
      AxioslibUser.post('api/admin/dashboard-render', body, {
        headers: {
          user_id: userId,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getDashboard = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== '') {
      AxioslibUser.post('api/dashboard', body, {
        headers: {
          user_id: userId,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getProjectsRendered = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      AxioslibUser.post('users/get_projects-rendered', body, {
        headers: {
          user_id: Id,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                    total_count: response.data.Body.result?.total_count,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getRenderProjects = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      AxioslibUser.post('users/get_render-projects', body, {
        headers: {
          user_id: Id,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                    total_count: response.data.Body.result.total_count,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getClusterRenderProjects = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      AxioslibUser.post('users/admin/cluster/render-projects', body, {
        headers: {
          user_id: Id,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                    total_count: response.data.Body.result.total_count,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getAdminRenderProjects = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      AxioslibUser.post('users/admin/list/render-projects', body, {
        headers: {
          user_id: Id,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                    total_count: response.data.Body.result.total_count,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getProjects = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== '') {
      AxioslibUser.post('api/get_projects', body, {
        headers: {
          user_id: userId,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                    total_count: response.data.Body.result.total_count,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};
export const getProjectID = body => {
  let Id = '';
  if (body.user_id) {
    Id = body.user_id;
  } else {
    let userId = getUserItem('id');
    let orgId = getUserItem('orgId');
    let AdminRole = getUserItem('role');
    Id = AdminRole == 0 ? userId : orgId;
  }
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      AxioslibUser.post('api/get_projectsId', body, {
        headers: {
          user_id: Id,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};
export const uploadcsvFile = (body, config) => {
  let userId = getUserItem('id');
  return new Promise((resolve, reject) => {
    AxioslibUser.post(
      'api/upload/csv',
      body,
      {
        headers: {
          token: 'QCSU7nvg5Xk5qUdqQgXFdQ',
          'Content-Type': 'multipart/form-data',
        },
      },
      config
    )
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (
                response.data.Body.result !== undefined &&
                response.data.Body.result !== null &&
                response.data.Body.result !== ''
              ) {
                let result = {
                  data: response.data.Body.result,
                  message: response.data.Body.message,
                  status: response.data.StatusCode,
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        resolve({
          status: 101,
          message: 'Something went wrong in server Response',
        });
      });
  });
};

export const uploadMediaFile = (body, config) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('/media/uploadMedia', body, config, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (
                response.data.Body.result !== undefined &&
                response.data.Body.result !== null &&
                response.data.Body.result !== '' &&
                response.data.Body.result.mediaurl !== undefined &&
                response.data.Body.result.mediaurl !== null
              ) {
                let result = {
                  data: response.data.Body.result.mediaurl,
                  message: response.data.Body.message,
                  status: response.data.StatusCode,
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        const errorMsg = axios.isCancel(error) ? 'Upload cancelled' : 'No server Response';
        resolve({ status: 101, message: errorMsg });
      });
  });
};

export const getAllFonts = body => {
  // let accessToken = JSON.parse(localStorage.getItem('_user'))
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/get-fonts', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (
                response.data.Body.result !== undefined &&
                response.data.Body.result !== null &&
                response.data.Body.result !== ''
              ) {
                let result = {
                  data: response.data.Body.result.fonts,
                  message: response.data.Body.message,
                  status: response.data.StatusCode,
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        resolve({ status: 101, message: 'No server Response' });
      });
  });
};

export const renderPreview = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      body['user_id'] = Id;
      AxioslibUser.post('/api/render', body, {
        headers: {
          user_id: Id,
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (
            response.data.status !== undefined &&
            response.data.status !== null &&
            response.data.status !== ''
          ) {
            if (response.data.render_id !== undefined && response.data.render_id !== null) {
              resolve({
                status: response.data.status,
                message: response.data.render_id,
              });
            } else {
              resolve({
                status: 101,
                message: ' No response from the server',
              });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'Something went wrong' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const renderSerial = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      body['user_id'] = Id;
      AxioslibUser.post('/api/render-serial', body, {
        headers: {
          user_id: Id,
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.message !== undefined && response.data.message !== null) {
              resolve({
                message: response.data.message,
              });
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'Something went wrong' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};
export const getFinalRenderVideo = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      body.user_id = Id;
      AxioslibUser.post('api/render-final-video', body, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              resolve({
                data: response.data,
                status: response.status,
              });
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'Something went wrong' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getProjectPreview = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      body.user_id = Id;
      AxioslibUser.post('api/project-status', body, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  resolve({
                    data: response.data.Body.result,
                    status: response.data.StatusCode,
                    message: response.data.Body.message,
                    apiStatus: response.data.Body.success,
                  });
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'Something went wrong' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};
export const publicSearchFont = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/search-public', body, {
      headers: {
        user_id: userId,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (
                response.data.Body.result !== undefined &&
                response.data.Body.result !== null &&
                response.data.Body.result !== ''
              ) {
                let result = {
                  data: response.data.Body.result.Fonts,
                  message: response.data.Body.message,
                  status: response.data.StatusCode,
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        resolve({ status: 101, message: 'Something went wrong' });
      });
  });
};
export const publicSearch = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/search-public', body, {
      headers: {
        user_id: userId,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status !== undefined && response.status !== null && response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (
                response.data.Body.result !== undefined &&
                response.data.Body.result !== null &&
                response.data.Body.result !== ''
              ) {
                let result = {
                  data: response.data.Body.result.Images,
                  message: response.data.Body.message,
                  status: response.data.StatusCode,
                };
                if (response.data.Body.result.total_count) {
                  result.total_count = response.data.Body.result.total_count;
                }
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        let response = error.response;
        if (
          response !== undefined &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 400
        ) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (
                response.data.Body.result !== undefined &&
                response.data.Body.result !== null &&
                response.data.Body.result !== ''
              ) {
                let result = {
                  message: response.data.Body.message,
                  status: 101,
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({ status: 101, message: 'No records available ' });
        }
      });
  });
};

export const getCategory = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/get-categories', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        resolve({ status: 101, message: 'Same Category name not allowed' });
      });
  });
};

export const downloadVideoBlockUrl = config => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/download-videoblock', config, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data !== undefined && response.data !== null && response.data !== null) {
          if (response.data.Body !== undefined && response.data.Body !== null) {
            if (
              response.data.Body.result !== undefined &&
              response.data.Body.result !== null &&
              response.data.Body.result !== '' &&
              response.data.Body.result.mediaurl !== undefined &&
              response.data.Body.result.mediaurl !== null
            ) {
              let result = {
                data: response.data.Body.result.mediaurl,
                message: response.data.Body.message,
                status: response.data.StatusCode,
              };
              resolve(result);
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        let response = error.response;
        if (
          response !== undefined &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 400
        ) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data !== undefined && response.data !== null) {
              if (
                response.data.results !== undefined &&
                response.data.results !== null &&
                response.data.results !== ''
              ) {
                let result = {
                  message: response.data.message,
                  status: 101,
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({ status: 101, message: 'No records available ' });
        }
      });
  });
};
export const videoBlockSearch = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/search-videoblock', body, {
      headers: {
        user_id: userId,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status !== undefined && response.status !== null && response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (
              response.data.results !== undefined &&
              response.data.results !== null &&
              response.data.results !== ''
            ) {
              let result = {
                data: response.data.results,
                totalcount: response.data.total_results,
                message: response.data.message,
                status: response.status,
              };
              resolve(result);
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        let response = error.response;
        if (
          response !== undefined &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 400
        ) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data !== undefined && response.data !== null) {
              if (
                response.data.results !== undefined &&
                response.data.results !== null &&
                response.data.results !== ''
              ) {
                let result = {
                  message: response.data.message,
                  status: 101,
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({ status: 101, message: 'No records available ' });
        }
      });
  });
};
export const publicShutterStockSearch = body => {
  let userId = getUserItem('id');
  let filter = {
    user_id: userId,
    imgId: body,
  };
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/save-shutterstock', filter, {
      headers: {
        user_id: userId,
        authorization: `Bearer ${accessToken}`,
        // "token":"QCSU7nvg5Xk5qUdqQgXFdQ"
      },
    })
      .then(response => {
        if (response.status !== undefined && response.status !== null && response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (
                response.data.Body !== undefined &&
                response.data.Body !== null &&
                response.data.Body !== ''
              ) {
                let result = {
                  data: response.data.Body,
                  message: response.data.Body.message,
                  status: response.data.StatusCode,
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        let response = error.response;
        if (
          response !== undefined &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 400
        ) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (
                response.data.Body.result !== undefined &&
                response.data.Body.result !== null &&
                response.data.Body.result !== ''
              ) {
                let result = {
                  message: response.data.Body.message,
                  status: 101,
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({ status: 101, message: 'No records available ' });
        }
      });
  });
};
export const projectDeleteOrRestore = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      AxioslibUser.post('api/delete_restore', body, {
        headers: {
          user_id: Id,
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            resolve(response);
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const projectScreenCatchePreview1 = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== '') {
      Axioslib.post('/api/cache-priview', body, {
        headers: {
          user_id: userId,
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (
              response.data.message !== undefined &&
              response.data.message !== null &&
              response.data.message !== null
            ) {
              if (response.data.message === 'NOTFOUND') {
                resolve({ status: 400, message: 'Not Found' });
              }
            }
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        })
        .catch(error => {
          console.log(error);

          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const projectScreenPreview = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== '') {
      Axioslib.post('/api/priview', body, {
        headers: {
          user_id: userId,
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.status === 200) {
              if (response.data !== undefined && response.data !== null && response.data !== null) {
                if (response.data.Body !== undefined && response.data.Body !== null) {
                  if (
                    response.data.Body.result !== undefined &&
                    response.data.Body.result !== null &&
                    response.data.Body.result !== ''
                  ) {
                    let result = {
                      data: response.data.Body.result,
                      message: response.data.Body.message,
                      status: response.data.StatusCode,
                    };
                    resolve(result);
                  } else {
                    resolve({
                      status: 101,
                      message: ' No response from the server',
                    });
                  }
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({
                status: 101,
                message: 'Something went wrong. Please try again',
              });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getAssets = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/search-repo', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
        user_id: userId,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (
                response.data.Body.result !== undefined &&
                response.data.Body.result !== null &&
                response.data.Body.result !== ''
              ) {
                let result = {
                  data: response.data.Body.result,
                  message: response.data.Body.message,
                  status: response.data.StatusCode,
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        const errMsg = error?.response?.data?.message || 'Something went wrong';
        resolve({ status: 101, message: errMsg });
      });
  });
};
export const getTemplatesRepoManual = (body, type, orgId, projectid) => {
  let userId = getUserItem('id');
  let id = orgId !== 'AdminRole' ? orgId : userId;
  //    body.orgId=id;

  let filter = {
    screenContent: body,
    type: type,
    orgId: id,
    projectId: projectid,
  };
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/suggest-templatesmanual', filter, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            let result = {
              data: response.data,
              message: response.statusText,
              status: response.status,
            };
            resolve(result);
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        resolve({ status: 101, message: 'Something went wrong' });
      });
  });
};

export const getTemplatesRepo = (body, orgid, projectId) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  let filter = {
    screenContent: body,
    orgId: orgid,
    projectId: projectId,
  };
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/suggest-templates', filter, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            let result = {
              data: response.data,
              message: response.statusText,
              status: response.status,
            };
            resolve(result);
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        resolve({ status: 101, message: 'Something went wrong' });
      });
  });
};
// Jesna ends here --------------------
export const createProjectsPreview = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  body = JSON.parse(body);
  if (body.type == 'create') {
    body = JSON.stringify({ ...body, createdBy: userId });
  }
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      AxioslibUser.post('api/create-projectpreview', body, {
        headers: {
          'Content-Type': ' application/json',
          Accept: 'application/json',
          user_id: Id,
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: 'No response from the server' });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'No response from the server' });
    }
  });
};
export const createProjects = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      AxioslibUser.post('api/create-project', body, {
        headers: {
          'Content-Type': ' application/json',
          Accept: 'application/json',
          user_id: Id,
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: 'No response from the server' });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'No response from the server' });
    }
  });
};
export const getUsageProject = body => {
  return new Promise((resolve, reject) => {
    if (body !== undefined && body !== null && body !== '') {
      AxioslibUser.post(`api/get-trackingProject`, body, {})
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              let result = {
                data: response.data,
                status: response.status,
              };
              resolve(result);
            } else {
              resolve({ status: 101, message: 'No records available' });
            }
          } else {
            resolve({ status: 101, message: 'No response from the server' });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'No response from the server' });
    }
  });
};
export const getUsageProjectChildDetails = body => {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/get-child-renderdata`, body)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getUsageProjectParentDetails = body => {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/get-parent-renderdata`, body)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getUsageProjectDetails = body => {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/get-renderdata`, body)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getRenderProjectDetails = body => {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/get-dashboard-renderdata`, body)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const projectScreenCatchePreview = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== '') {
      Axioslib.post('/api/cache-priview', body, {
        headers: {
          user_id: userId,
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (
              response.data.message !== undefined &&
              response.data.message !== null &&
              response.data.message !== null
            ) {
              if (response.data.message === 'NOTFOUND') {
                resolve({ status: 400, message: 'Not Found' });
              }
            }
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        })
        .catch(error => {
          console.log(error);

          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getAdminProjects = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== '') {
      AxioslibUser.post('api/get_admin_projects', body, {
        headers: {
          user_id: userId,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getAdminDashboard = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== '') {
      AxioslibUser.post('api/admin_dashboard', body, {
        headers: {
          user_id: userId,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (
                  response.data.Body.result !== undefined &&
                  response.data.Body.result !== null &&
                  response.data.Body.result !== ''
                ) {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getAdminUsageProject = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (body !== undefined && body !== null && body !== '') {
      AxioslibUser.post(`api/get-admin-trackingProject`, body, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              let result = {
                data: response.data,
                status: response.status,
              };
              resolve(result);
            } else {
              resolve({ status: 101, message: 'No records available' });
            }
          } else {
            resolve({ status: 101, message: 'No response from the server' });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'No response from the server' });
    }
  });
};

export const updateClusterDetails = (id, body) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/update-cluster-status/${id}`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data.success === true) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const listVMDetails = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/get-vm-data`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const listClusterDetails = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/list_clusters`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const SaveClusterDetails = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/save-cluster`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              let result = {
                data: response.data.Body,
                message: response.data.Body.message,
                status: response.data.StatusCode,
              };
              resolve(result);
            } else {
              resolve({
                status: 101,
                message: response.data.status,
              });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(e => {
        console.log(e);
        resolve({ status: 101, message: 'No response from the server' });
      });
  });
};

export const DeleteClusterDetails = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/delete_clusters`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              let result = {
                data: response.data.Body,
                message: response.data.Body.message,
                status: response.data.StatusCode,
              };
              resolve(result);
            } else {
              resolve({
                status: 101,
                message: response.data.status,
              });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(e => {
        console.log(e);
        resolve({ status: 101, message: 'No response from the server' });
      });
  });
};

export const getClusterDetails = (sort_by = '') => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  let url = `api/get-cluster-data/`;
  if (sort_by) {
    url += `?sort_by=${sort_by}`;
  }
  return new Promise((resolve, reject) => {
    AxioslibUser.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data.success === true) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getAdminUsageProjectDetails = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/get-admin-renderdata`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getAdminProjectStatus = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/admin-project-status', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (
                response.data.Body.result !== undefined &&
                response.data.Body.result !== null &&
                response.data.Body.result !== ''
              ) {
                resolve({
                  data: response.data.Body.result,
                  status: response.data.StatusCode,
                  message: response.data.Body.message,
                  apiStatus: response.data.Body.success,
                });
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        resolve({ status: 101, message: 'Something went wrong' });
      });
  });
};

export const emailNotification = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      body['user_id'] = Id;
      AxioslibUser.post('/api/project-rendered', body, {
        headers: {
          user_id: Id,
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (
            response.data.status !== undefined &&
            response.data.status !== null &&
            response.data.status !== ''
          ) {
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'Something went wrong' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const getSingleClusterDetails = clusterId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/get/cluster/${clusterId}/details`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data.success === true) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getClusterList = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`/clusters/list`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data.success === true) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getSingleRenderProjectDetails = renderId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`/api/get/project-render/${renderId}/details`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data.success === true) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const clusterReset = clusterId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`/clusters/${clusterId}/reset`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data.success === true) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const clusterRefresh = clusterId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`/clusters/${clusterId}/refresh`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data.success === true) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const deleteRenderProject = renderProjectId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(
      `/users/delete-render-project`,
      { render_id: renderProjectId },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then(response => {
        if (response.data.success === true) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const updateUserProfile = id => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`/users/update-user/:userId`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data.success === true) {
          resolve(response);
        } else {
          resolve({ status: 101, message: 'No response from the server' });
        }
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const createClient = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`/api/client/v1/create`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        resolve(response);
      })
      .catch(e => {
        resolve({ message: e.response.data.error });
      });
  });
};

export const checkDomain = subdomain => {
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`/api/client/v1/check-domain`, {
      headers: {
        subdomain: subdomain,
      },
    })
      .then(response => {
        resolve(response);
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const uploadFile = formData => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));
  formData.append('client', 'BT101');
  formData.append('bucket', 'myconnect-olivegroup');

  // const url = process.env.REACT_APP_FILE_UPLOAD_URL;
  const url = 'https://awsuploader.services.olive.media/upload';

  return new Promise((resolve, reject) => {
    AxioslibUser.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${accessToken}`,
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        const errorMsg = axios.isCancel(error) ? 'Upload cancelled' : 'No server Response';
        resolve({ status: 101, message: errorMsg });
      });
  });
};

export const getClientSetting = () => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get(`/api/client-setting/v1/list`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data.data))
      .catch(error => reject(error.message || error));
  });
};

export const getProject = projectId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/project/v1/${projectId}`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data.data))
      .catch(error => reject(error.message || error));
  });
};

export const getFonts = () => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.get('/api/generic/v1/fonts', {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => {
        if (response.data?.Body?.result) {
          resolve(response.data.Body.result.fonts);
        } else {
          reject({
            status: 500,
            message: 'No data',
          });
        }
      })
      .catch(error => reject({ status: 101, message: error.message || error }));
  });
};

export const getProjectStyles = projectId => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.get(`/api/style/v1/project/${projectId}/get`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const saveProjectStyles = body => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post('/api/style/v1/create', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const saveProjectImage = body => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post('/api/project/v1/update/image', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const getTemplateData = (body, projectId) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  let filter = {
    screenContent: body,
    projectId: projectId,
  };
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/template/v1/suggest-templates', filter, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const updateProject = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/project/v1/update', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const generateProjectPreview = (body, forceGenerate = false) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  if (forceGenerate) {
    body.forceGenerate = true;
  }
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/project/v1/generate-preview', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const getAssetsV1 = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/generic/v1/search-repo', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data?.Body?.result) {
            let result = {
              data: response.data.Body.result,
              message: response.data.Body.message,
              status: response.data.StatusCode,
            };
            resolve(result);
          } else {
            reject('No response from the server');
          }
        } else {
          reject('Something went wrong. Please try again');
        }
      })
      .catch(error => {
        console.log({ getAssetsV1Error: error });
        const errMsg = error?.response?.data?.message || 'Something went wrong';
        reject(errMsg);
      });
  });
};

export const publicShutterStockSearchV1 = body => {
  let userId = getUserItem('id');
  let filter = {
    user_id: userId,
    imgId: body,
  };
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/save-shutterstock', filter, {
      headers: {
        user_id: userId,
        authorization: `Bearer ${accessToken}`,
        // "token":"QCSU7nvg5Xk5qUdqQgXFdQ"
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data?.Body) {
            resolve(response.data.Body);
          } else {
            reject('No response from the server');
          }
        } else {
          reject('Something went wrong. Please try again');
        }
      })
      .catch(error => {
        console.log({ publicShutterStockSearchV1Error: error });

        reject(error.response);
      });
  });
};

export const publicSearchV1 = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/generic/v1/image/search-public', body, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data?.Body?.result) {
            let result = {
              data: response.data.Body.result.Images,
            };
            if (response.data.Body.result.total_count) {
              result.total_count = response.data.Body.result.total_count;
            }
            resolve(result);
          } else {
            reject('No response from the server');
          }
        } else {
          reject('Something went wrong. Please try again');
        }
      })
      .catch(error => {
        console.log({ publicSearchV1Error: error });

        const errMsg = error?.response?.data?.message || 'Something went wrong';
        reject(errMsg);
      });
  });
};

export const videoBlockSearchV1 = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/generic/v1/search-videoblock', body, {
      headers: {
        user_id: userId,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data) {
            if (response.data.results) {
              let result = {
                data: response.data.results,
                totalcount: response.data.total_results,
                message: response.data.message,
                status: response.status,
              };
              resolve(result);
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        let response = error.response;
        if (response.status === 400) {
          if (response.data) {
            if (response.data.results) {
              let result = {
                message: response.data.message,
                status: 101,
              };
              resolve(result);
            } else {
              resolve({
                status: 101,
                message: ' No response from the server',
              });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({ status: 101, message: 'No records available ' });
        }
      });
  });
};

export const downloadVideoBlockUrlV1 = config => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/generic/v1/download-videoblock', config, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data) {
          if (response.data.Body) {
            if (response.data.Body.result) {
              const result = {
                data: response.data.Body.result,
                message: response.data.Body.message,
                status: response.data.StatusCode,
              };
              resolve(result);
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        let response = error.response;
        if (response.status === 400) {
          if (response.data) {
            if (response.data.results) {
              let result = {
                message: response.data.message,
                status: 101,
              };
              resolve(result);
            } else {
              resolve({
                status: 101,
                message: ' No response from the server',
              });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({ status: 101, message: 'No records available ' });
        }
      });
  });
};

export const checkScript = script => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(
      'api/project/v1/check-script',
      { script },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const checkMaxParallelRender = () => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get('api/render/v1/check-max-parallel-render', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const getClients = query => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/client/v1/list-with-user?${query}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const getTemplates = query => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/admin/template/v1/list?${query}`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const getClientTemplateRelations = tid => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/admin/template/v1/${tid}/client-template/list`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const changeTemplateStatus = ({ tid, payload }) => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.put(`api/admin/template/v1/${tid}/status`, payload, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const deleteTemplate = ({ tid, client }) => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.delete(`api/admin/template/v1/${tid}/client/${client}`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const exportClients = body => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/client/v1/export`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      body,
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const assignTemplate = data => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/admin/template/v1/assign-template`, data, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const renderTemplateData = data => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/admin/template/v1/render`, data, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data.Body))
      .catch(error => reject(error));
  });
};

export const renderedTemplateStatus = data => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/admin/template/v1/rendered-template-status`, data, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data.Body.result))
      .catch(error => reject(error));
  });
};

export const getClientSettingOfClient = client => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/admin/client-setting/v1/${client}`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const saveClientSettingOfClient = ({ data, client }) => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/admin/client-setting/v1/save-client-setting/${client}`, data, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};
