import { Tooltip } from '@mui/material';
import React, { useState, lazy, Suspense } from 'react';
import { Badge, Dropdown, Spinner, Table } from 'react-bootstrap';

import {
  listIcon,
  resetIcon,
  checkIcon,
  deleteIcon,
  ellipseIcon,
  eyeOpenIcon,
  circlePlayIcon,
  viewRenderIcon,
  circleLockIcon,
} from '../../../constants/icon';
import {
  deleteTemplate,
  changeTemplateStatus,
  renderedTemplateStatus,
  getClientTemplateRelations,
} from '../../../services/api.service';
import { CustomDropdownToggle } from '../../../components/dropdown';
import { showToaster, templateName } from '../../../services/helper';

const PreviewModal = lazy(() => import('./previewModal'));
const RenderTemplate = lazy(() => import('./renderTemplate'));
const TemplateAssign = lazy(() => import('./templateAssign'));
const GeneratePreview = lazy(() => import('./previewGenerate'));
const CustomModal = lazy(() => import('../../../services/CustomModal'));
const TemplateDetailOffcanvas = lazy(() => import('./templateDetailOffcanvas'));

export default function TemplateTable(props) {
  const { templates, setFetchTemplates, clientQuery, updateRenderStatus, setIsLoading, clients } =
    props;

  const [viewMore, setViewMore] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [assignTemplate, setAssignTemplate] = useState('');
  const [renderTemplate, setRenderTemplate] = useState('');
  const [previewTemplate, setPreviewTemplate] = useState('');
  const [deleteTemplateData, setDeleteTemplate] = useState(null);
  const [activateTemplate, setActivateTemplate] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [totalClientsUsedThisTemplate, setTotalClientsUsedThisTemplate] = useState(null);

  const handleClose = () => setViewMore('');
  const handleCloseModal = () => setPreviewUrl('');
  const handlePreview = template => setPreviewTemplate(template);
  const handleRender = template => setRenderTemplate(template);

  const showRender = template => {
    if (template.projectDetails) {
      if (template.isRendering) {
        return (
          <Tooltip title="Refresh" arrow>
            <span role="button" className="render-loading" onClick={() => handleRefresh(template)}>
              {template.projectDetails.RenderStatus} {viewRenderIcon}
            </span>
          </Tooltip>
        );
      } else {
        if (
          template.previewUrl &&
          template.projectDetails.Is_Valid === 1 &&
          template.projectDetails.RenderStatus === '0%'
        ) {
          return (
            <button
              type="button"
              onClick={() => handleRender(template)}
              className="btn btn-sm btn-secondary d-inline-block m-auto"
            >
              Render
            </button>
          );
        }
        if (!template.previewUrl) {
          return (
            <Tooltip title="After preview generation render function will be available" arrow>
              <span>NA</span>
            </Tooltip>
          );
        }
        if (template.projectDetails.Is_Valid === 0) {
          return <span>Invalid</span>;
        }
        if (
          template.renderDetails &&
          template.projectDetails.RenderStatus === '100%' &&
          template.renderDetails.is_valid.toString() === '1'
        ) {
          const slideRender = template.renderDetails?.mediaurl?.M01?.SL01?.SlideRender;
          if (slideRender === '1') {
            const videoUrl = template.renderDetails?.mediaurl?.M01?.SL01?.url;
            const thumbnailUrl = template.renderDetails?.mediaurl?.M01?.SL01?.thumbnail_url;
            return (
              <span
                role="button"
                className="table-thumbnail"
                onClick={() => setPreviewUrl(videoUrl)}
              >
                <img src={thumbnailUrl} alt="thumbanial" />
                <span className="cirlce-play-icon">{circlePlayIcon}</span>
              </span>
            );
          }
        }
        return null;
      }
    } else {
      return (
        <Tooltip title="After preview generation render function will be available" arrow>
          <span>NA</span>
        </Tooltip>
      );
    }
  };

  const fetchClientTemplateRelations = async tid => {
    if (clientQuery) return null;
    try {
      const templates = await getClientTemplateRelations(tid);

      setTotalClientsUsedThisTemplate(templates.data?.totalClientsUsed);
    } catch (error) {
      console.log({ getClientTemplateRelationsError: error });
    }
  };

  const handleStatus = (status, template) => {
    setActivateTemplate(status);
    setSelectedTemplate(template);

    fetchClientTemplateRelations(template.tid);
  };

  const handleCloseConfirmationModal = () => {
    setActivateTemplate(null);
    setSelectedTemplate(null);
    setTotalClientsUsedThisTemplate(null);
  };

  const handleActivationConfirm = async () => {
    try {
      setIsLoading(true);
      const payload = { isActive: activateTemplate, client: clientQuery || 'ALL' };
      await changeTemplateStatus({ tid: selectedTemplate.tid, payload });
      setFetchTemplates(Math.random());
      showToaster(
        `Template ${templateName(selectedTemplate.mediaurl)} ${
          activateTemplate ? 'activated' : 'deactivated'
        }`,
        'success'
      );
    } catch (error) {
      setIsLoading(false);
      showToaster('Something went wrong. Please try again or contact to our support.', 'error');
      console.log({ handleActivationConfirmError: error });
    }
  };

  const confirmContent = () => {
    if (clientQuery) return null;
    if (totalClientsUsedThisTemplate !== null) {
      return <span>{`Total ${totalClientsUsedThisTemplate} clients have this template.`}</span>;
    }
    return <Spinner animation="border" variant="primary" size="sm" />;
  };

  const handleDelete = template => {
    setDeleteTemplate(template);
    fetchClientTemplateRelations(template.tid);
  };

  const handleCloseDeleteModal = () => {
    setDeleteTemplate(null);
    setTotalClientsUsedThisTemplate(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      setIsLoading(true);
      await deleteTemplate({ tid: deleteTemplateData.tid, client: clientQuery || 'ALL' });
      setFetchTemplates(Math.random());
      showToaster(`Template ${templateName(deleteTemplateData.mediaUrl)} deleted`, 'success');
    } catch (error) {
      setIsLoading(false);
      showToaster('Something went wrong. Please try again or contact to our support.', 'error');
      console.log({ handleDeleteConfirmError: error });
    }
  };

  const clientLabel = () => {
    if (!clientQuery) return 'given client';
    const client = clients.find(client => client.value === clientQuery);

    if (client) return client.label + ' client';
    return 'given client';
  };

  const showLockIcon = template => {
    let lockIcon = null;

    if (template.isPublic != null && !template.isPublic) lockIcon = circleLockIcon;

    return lockIcon;
  };

  const handleRefresh = async template => {
    try {
      let renderFilter = {
        client: clientQuery || 'ALL',
        tId: template.tid,
        projectId: template.projectDetails?.ProjectID,
      };
      const response = await renderedTemplateStatus(renderFilter);
      console.log({ handleRefreshrenderedTemplateStatus: response });

      if (response) {
        if (response.is_valid === 0) {
          updateRenderStatus(template, { isRendering: false });
          showToaster('There is no valid data for rendering the project!!', 'warning');
        } else if (response.apiStatus === false) {
          updateRenderStatus(template, { isRendering: false });
        } else if (response.message === 'Error, please try rendering video again') {
          updateRenderStatus(template, { isRendering: false });
          showToaster('Error, please try rendering video again', 'warning');
        } else {
          if (response.mediaurl && Object.keys(response.mediaurl).length) {
            if (response.renderstatus === '100%') {
              updateRenderStatus(template, {
                isRendering: false,
                renderDetails: { ...response.data },
                projectDetails: {
                  ...template.projectDetails,
                  RenderStatus: '100%',
                },
              });
            } else {
              updateRenderStatus(template, {
                projectDetails: {
                  ...template.projectDetails,
                  RenderStatus: response.renderstatus,
                },
              });
            }
          }
        }
      }
    } catch (error) {
      console.log({ handeRefreshError: error });
      showToaster('Error, please try again', 'warning');
    }
  };

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <TemplateAssign
          clients={clients}
          template={assignTemplate}
          close={() => setAssignTemplate('')}
        />
        <RenderTemplate
          client={clientQuery || 'ALL'}
          refreshData={setFetchTemplates}
          renderTemplate={renderTemplate}
          updateRenderStatus={updateRenderStatus}
          closeModal={() => setRenderTemplate('')}
        />

        <CustomModal
          Buttonlabel="Yes"
          Content={confirmContent()}
          isOpen={activateTemplate !== null}
          Buttonclick={handleActivationConfirm}
          Closemodal={handleCloseConfirmationModal}
          disableButton={!clientQuery && totalClientsUsedThisTemplate === null}
          Header={
            <>
              {!selectedTemplate?.previewUrl || !selectedTemplate?.renderDetails ? (
                <p className="fst-italic">Preview or Render has not been generated.</p>
              ) : null}
              Are you sure you want to {activateTemplate ? 'activate' : 'deactivate'} template{' '}
              {templateName(selectedTemplate?.mediaUrl)} from{' '}
              {clientQuery ? clientLabel() : 'All clients'} ?
            </>
          }
        />
        <CustomModal
          Buttonlabel="Yes"
          Content={confirmContent()}
          isOpen={!!deleteTemplateData}
          Buttonclick={handleDeleteConfirm}
          Closemodal={handleCloseDeleteModal}
          disableButton={!clientQuery && totalClientsUsedThisTemplate === null}
          Header={`Are you sure you want to delete template ${templateName(
            deleteTemplateData?.mediaUrl
          )} from ${clientQuery ? clientLabel() : 'All clients'}?`}
        />
        <GeneratePreview
          clientQuery={clientQuery}
          refreshData={setFetchTemplates}
          previewTemplate={previewTemplate}
          closePreview={() => setPreviewTemplate('')}
        />
        <PreviewModal show={previewUrl} onHide={handleCloseModal} />
        <TemplateDetailOffcanvas show={viewMore} onHide={handleClose} />
      </Suspense>

      <div className="common-border-table template-table-list">
        <Table responsive className="common-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Template Name</th>
              <th>No of ost H</th>
              <th>No of ost P</th>
              <th>No of ost B</th>
              <th>No of images</th>
              <th>No of Illustrations</th>
              <th>No of videos</th>
              <th>Preview</th>
              <th>Render</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {templates.map(template => (
              <tr key={template._id}>
                <td>
                  <span>{template.tid}</span>
                </td>
                <td>
                  <span title={template.mediaUrl}>
                    {showLockIcon(template)} {templateName(template.mediaUrl)}
                  </span>
                </td>

                <td>
                  <Tooltip
                    title={
                      template.ost_lengths?.Heading_Lengths?.length
                        ? `Heading length: ${template.ost_lengths?.Heading_Lengths}
                        `
                        : ''
                    }
                    arrow
                  >
                    <span>{template.no_of_ost_h}</span>
                  </Tooltip>
                </td>
                <td>
                  <Tooltip
                    title={
                      template.ost_lengths?.Paragraph_Lengths?.length
                        ? `Paragraph length: ${template.ost_lengths?.Paragraph_Lengths}
                      `
                        : ''
                    }
                    arrow
                  >
                    <span>{template.no_of_ost_p}</span>
                  </Tooltip>
                </td>
                <td>
                  <Tooltip
                    title={
                      template.ost_lengths?.Bulletpoint_Lengths?.length
                        ? `Bullet Point length: ${template.ost_lengths?.Bulletpoint_Lengths}
                      `
                        : ''
                    }
                    arrow
                  >
                    <span>{template.no_of_ost_b}</span>
                  </Tooltip>
                </td>
                <td>
                  <span>{template.no_of_images}</span>
                </td>
                <td>
                  <span>{template.no_of_illustrations}</span>
                </td>
                <td>
                  <span>{template.no_of_videos}</span>
                </td>
                <td>
                  {template.previewUrl && template.thumbnailUrl ? (
                    <span
                      role="button"
                      className="table-thumbnail"
                      onClick={() => setPreviewUrl(template.previewUrl)}
                    >
                      <img src={template.thumbnailUrl} alt="thumbanial" />
                      <span className="cirlce-play-icon">{circlePlayIcon}</span>
                    </span>
                  ) : (
                    <button
                      className="btn btn-sm btn-secondary d-inline-block m-auto"
                      onClick={() => handlePreview(template)}
                      type="button"
                    >
                      Preview
                    </button>
                  )}
                </td>
                <td>{showRender(template)}</td>
                <td className="pointer">
                  {template.isActive ? (
                    <Badge bg="success">Active</Badge>
                  ) : (
                    <Badge bg="secondary">Inactive</Badge>
                  )}
                </td>
                <td>
                  <div className="common-dropdown-wrap">
                    <Dropdown>
                      <Dropdown.Toggle as={CustomDropdownToggle} id="dropdown-custom-components">
                        <span className="ellipse-btn" role="button">
                          {ellipseIcon}
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="common-dropdown-menu script-dropdown-menu">
                        <Dropdown.Item eventKey="3" onClick={() => setAssignTemplate(template)}>
                          {listIcon} Assign
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="1" onClick={() => handleDelete(template)}>
                          {deleteIcon} Delete
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="2"
                          onClick={() => handleStatus(template.isActive ? 0 : 1, template)}
                        >
                          {template.isActive ? (
                            <>{resetIcon} Deactivate</>
                          ) : (
                            <>{checkIcon} Activate</>
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="3" onClick={() => setViewMore(template)}>
                          {eyeOpenIcon} View More
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}
