
import React, { useEffect } from 'react'
import { useParams, useHistory, useLocation } from "react-router-dom";
import { getProjectID } from './api.service';
export default function Authorize() {

    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        console.log(params);
        let tId = '';
        if(location.search) {
            const searchParams = new URLSearchParams(location.search);
            tId = searchParams.get('tId');
        }
        if (params.id !== undefined && params.id !== "" && params.projectName !== undefined && params.projectName !== "") {
            let redirectPath = { pathname: `/project-list/download`, state: { projectId: params.id, projectName: params.projectName } }
            if(tId) {
                redirectPath = { pathname: '/templatelist', search: `?search=${tId}&all=1`, }
            }
            if (localStorage.getItem('_user') && localStorage.getItem('_olive_user')) {
                             
                    // let filter = {
                    //     project_id: params.id
                    // };
                    // getProjectID(filter).then(async (response) => {
                    //     try {
                    //         if (
                    //             response !== "" &&
                    //             response !== undefined &&
                    //             response !== null &&
                    //             response.status !== "" &&
                    //             response.status !== undefined &&
                    //             response.status !== null &&
                    //             response.status === 200 &&
                    //             response.data !== "" &&
                    //             response.data !== undefined &&
                    //             response.data !== null
                    //         ) {
                    //             let projectData = JSON.parse(response.data[0].ProjectData);
                              
                    //         }
                    //     } catch (error) {
                    //         //this.setState({ uploadButtonProgress: false })
                    //     }
                    // });
                
                // history.push('/download/' + params.id)
                history.push(redirectPath)
            } else {
                history.push({pathname:"/",state:{rpath:redirectPath}})
            }
        } else {
            history.push('/')
        }
        //get download id from mail
        //check i loged or not
        //islogon redirect to download else login with project id

    }, [])
    return (
        <div>

        </div>
    )
}
