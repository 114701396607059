function RenderStatusReport(props) {
  const { duration, title, statusCircle } = props;
  return (
    <div className="status-report">
      {duration && <h4>{duration}</h4>}
      <div className="status-info-wrap">
        {title && <span className="status-count">{title}</span>}
        {statusCircle && <span className={`render-status-circle ${props.className || ''}`}></span>}
      </div>
    </div>
  );
}
export default RenderStatusReport;
