import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Table, Dropdown, Spinner } from 'react-bootstrap';
import {
  arrowDownIcon,
  checkIcon,
  deleteIcon,
  editIcon,
  editUserIcon,
  editVideoIcon,
  ellipseIcon,
  eyeOpenIcon,
  noProjectIcon,
  plusIcon,
  renderIcon,
  searchIcon,
} from '../../../../constants/icon';
import { convertDateFormat, getUserItem, isAdmin, showToaster } from '../../../../services/helper';
import FormFloatingInput from '../../../../components/form/formFloatingInput';
import PagePagination from '../../../../components/pagination/pagePagination';
import PaginationComponent from '../../../../components/pagination';
import AddNewUserModal from './addNewUserModal';
import {
  exportUsers,
  getUserListByAdmin,
  getUserListByUser,
  updateUser,
} from '../../../../services/apiServices';
import DataNotFound from '../../../../components/dataNotFound';
import PageLoader from '../../../../services/pageLoader';
import fileDownload from 'js-file-download';
import CustomModal from '../../../../services/CustomModal';
import useDebounce from '../../../../hooks/debounceHook';

function UserMgmtTable(props) {
  const { debounce, debouncedValue } = useDebounce();
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [keyWord, setKeyWord] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [isActive, setIsActive] = useState();
  const [sortBy, setSortBy] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [openActiveModal, setOpenActiveModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [activeStatus, setActiveStatus] = useState();
  const [activeLoad, setActiveLoad] = useState(false);
  const [userIdState, setUserIdState] = useState();
  const [tempSearchName, setTempSearchName] = useState('');
  const isCurrentUserAdmin = isAdmin();
  const currentUserRoles = getUserItem('userRole');
  const currentUserId = getUserItem('id');
  // Pagination Start
  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleLastPage = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(totalPages);
    }
  };
  // Pagination End

  const exportHandler = async () => {
    setIsExporting(true);
    const file = await exportUsers({ keyword: keyWord, isActive });
    fileDownload(file, 'userList.csv');
    setIsExporting(false);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      role="button"
      className="common-dropdown-btn"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
    >
      {children}
    </div>
  ));

  const CustomToggleFilter = React.forwardRef(({ children, onClick }, ref) => (
    <div
      role="button"
      className="common-dropdown-filter"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
    >
      {children}
    </div>
  ));
  useEffect(() => {
    if (debouncedValue !== null) {
      setKeyWord(debouncedValue);
    }
  }, [debouncedValue]);

  const handleSearch = e => {
    const { value } = e.target;
    debounce(value, 1000);
    setTempSearchName(value);
  };

  const fetchUserList = async () => {
    setIsLoading(true);
    let body = { keyword: keyWord, sort_by: sortBy, sort_direction: '', pageNo: currentPage };
    if (isActive !== undefined) {
      body.isActive = isActive;
    }
    let userList = [];
    if (isCurrentUserAdmin) {
      userList = await getUserListByAdmin(body);
    } else {
      userList = await getUserListByUser(body);
    }
    setIsLoading(false);
    console.log(userList, 'userList');
    setUsersList(userList.result?.data);
    setTotalItems(userList?.total);
  };

  useEffect(() => {
    fetchUserList();
  }, [keyWord, isActive, sortBy, currentPage]);

  const openActiveModalBox = (status, userId) => {
    setActiveStatus(status);
    if (status == 2) {
      setOpenDeleteModal(true);
    } else {
      setOpenActiveModal(true);
    }
    setUserIdState(userId);
  };

  const updateUsers = async () => {
    setActiveLoad(true);
    const response = await updateUser(
      userIdState,
      activeStatus === 2 ? 'delete' : activeStatus === 1 ? 'deactivate' : 'activate'
    );
    if (response) {
      setActiveLoad(false);
      setOpenActiveModal(false);
    }
    if (response?.status === 200) {
      setIsLoading(true);
      let body = { keyword: keyWord, sort_by: sortBy, sort_direction: '' };
      if (activeStatus == 2) {
        setKeyWord('');
        setSortBy('');
        body = { keyword: '', sort_by: '', sort_direction: '' };
      }
      if (isActive !== undefined) {
        body.isActive = isActive;
      }
      let usersResponse = [];
      if (isCurrentUserAdmin) {
        usersResponse = await getUserListByAdmin(body);
      } else {
        usersResponse = await getUserListByUser(body);
      }
      setUsersList(usersResponse.result?.data);
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageLoader showLoader={isLoading} />
      <AddNewUserModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        fetchUserList={fetchUserList}
      />
      <div className="secondary-header secondary-sticky-header">
        <FormFloatingInput
          leftIcon={searchIcon}
          type="search"
          id="list"
          placeholder=""
          label="Search user by name or email"
          className="custom-search-form"
          inputValue={tempSearchName}
          onChange={handleSearch}
        />
        <div className="common-dropdown-filter-wrap">
          <Dropdown align={{ lg: 'end' }}>
            <Dropdown.Toggle as={CustomToggleFilter} id="dropdown-custom-components">
              <span className="dropdown-icon dropdown-max-width">
                {isActive === 1 ? 'Active users' : isActive === 0 ? 'Inactive users' : 'All'}
                {arrowDownIcon}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="common-dropdown-menu">
              <Dropdown.Item eventKey="1" onClick={() => setIsActive()}>
                All
              </Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={() => setIsActive(1)}>
                Active users
              </Dropdown.Item>
              <Dropdown.Item eventKey="2" onClick={() => setIsActive(0)}>
                Inactive users
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <span className="total-number-count">
            Total user: <strong>{totalItems}</strong>
          </span>

          <Button variant="default" disabled={isExporting} onClick={exportHandler}>
            {isExporting ? (
              <>
                Exporting &nbsp;&nbsp;
                <Spinner animation="border" style={{ color: '#6750a4' }} size="sm" />
              </>
            ) : (
              <>{renderIcon}Export</>
            )}
          </Button>
          {(isCurrentUserAdmin || currentUserRoles.addUser) && (
            <button
              role="button"
              className="outline-primary-btn"
              onClick={() => setModalShow(true)}
            >
              {plusIcon}
              Add new user
            </button>
          )}
        </div>
      </div>

      <div className="common-border-table template-table-list">
        <h4>User Management</h4>
        {usersList?.length ? (
          <Table responsive className="common-table usages-table">
            <thead>
              <tr>
                <th className=" text-start">User's Name</th>
                <th className=" text-center">Email ID</th>
                <th className=" text-center">Role</th>
                <th className=" text-center">Created Date</th>
                <th className="text-center">Status</th>
                {/* <th className="">Deleted</th> */}
                <th className="text-center">More</th>
              </tr>
            </thead>
            <tbody>
              {usersList?.map((users, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <span>
                        {users?.firstName} {users?.lastName}
                      </span>
                    </td>
                    <td>
                      <span>{users?.email}</span>
                    </td>
                    <td>
                      <span>{users?.role}</span>
                    </td>
                    <td>
                      <span>{convertDateFormat(users?.createdAt)}</span>
                    </td>

                    <td>
                      {users?.status === 1 ? (
                        <span className="action-status success">Active</span>
                      ) : (
                        <span className="action-status danger">Inactive</span>
                      )}
                    </td>
                    {users._id != currentUserId && (
                      <td>
                        <div className="common-dropdown-wrap">
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                              {ellipseIcon}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="common-dropdown-menu script-dropdown-menu">
                              <Dropdown.Item
                                eventKey="1"
                                onClick={() => history.push(`/settings/${users?._id}/user-details`)}
                              >
                                {eyeOpenIcon} View details
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="1"
                                onClick={() =>
                                  openActiveModalBox(users?.status === 1 ? 1 : 0, users._id)
                                }
                              >
                                {editUserIcon}
                                {users?.status === 1 ? 'Deactive' : 'Active'}
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="1"
                                onClick={() => history.push(`/settings/${users?._id}/user-details`)}
                              >
                                {editUserIcon}Add permission
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => openActiveModalBox(2, users._id)}
                              >
                                {deleteIcon}Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <DataNotFound icon={noProjectIcon} title="No user found" />
        )}
      </div>
      <div className="pagination-wrapper">
        <PagePagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
        />
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          onFirst={handleFirstPage}
          onPrevious={handlePreviousPage}
          onNext={handleNextPage}
          onLast={handleLastPage}
        />
      </div>
      <CustomModal
        isOpen={openActiveModal}
        Closemodal={setOpenActiveModal}
        Header={`Are you sure you want to ${activeStatus ? 'Deactivate' : 'Activate'}?`}
        Buttonlabel={activeLoad ? (activeStatus ? 'Deactivating' : 'Activating') : 'Yes'}
        Buttonclick={updateUsers}
      />
      <CustomModal
        isOpen={openDeleteModal}
        Closemodal={setOpenDeleteModal}
        Header={`Are you sure you want to Delete ?`}
        Buttonlabel={activeLoad ? 'Deleting' : 'Yes'}
        Buttonclick={updateUsers}
      />
    </>
  );
}
export default UserMgmtTable;
