import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

// Icons
import { crossIcon, laptopIcon, dropBoxIcon } from '../../../../constants/icon';

function UploadFileModal(props) {
  const { ...rest } = props;
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <Modal
      {...rest}
      className="add-files-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div
          className="modal-close-btn"
          title="Close"
          data-bs-dismiss="modal"
          onClick={props.onHide}
        >
          {crossIcon}
        </div>
        <h2>File upload</h2>
        <div className="file-upload-box-wrapper">
          <div className="file-upload-box">
            {laptopIcon}
            <h4>Upload from your Device</h4>
            <label className="upload-btn">
              <input type="file" accept=".png, .jpeg, .jpg, .pdf" onChange={handleFileChange} />
              Upload
            </label>
          </div>
          <div className="file-upload-box">
            {dropBoxIcon}
            <h4>Upload from your Dropbox</h4>
            <label className="upload-btn">
              <input type="file" accept=".png, .jpeg, .jpg, .pdf" onChange={handleFileChange} />
              Upload
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="default" onClick={props.onHide}>
          No, I Cancelled
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default UploadFileModal;
