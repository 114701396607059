import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { debounce } from "debounce";
import { getAssets } from "../../../../services/api.service";
import ReactImageFallback from "react-image-fallback";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImagePreview from "../RightBar/ImagePriview";
import VideoPreview from "../RightBar/VideoPriview";
import SimpleBackdrop from "../../../container/pageLoader";
import { TabContent, TabPane, Nav, NavItem } from "reactstrap";
// import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import Stack from "@mui/material/Stack";
import Posts from "./posts";
import Pagination from "./pagination";
import { capitalizeFirstLetter } from "../../../../services/helper";
import { Checkbox } from "@mui/material";
export default function LogoRepo(props) {
  const [pageNo, setPageNo] = useState(1);
  // const [pageNo, setPageNo] = useState(1);
  const [endPageNo, setEndPageNo] = useState(100);
  const [logosRepo, setLogoRepo] = useState([]);
  const [SearchBoxErrorMsg, setSearchBoxErrorMsg] = useState(false);
  const [keyWord, setKeyWord] = useState("");
  const [logoRepoLoader, setLogoRepoLoader] = useState(true);
  const [pageLoader, setPageLoader] = useState(false);
  const [logoErrorMsg, setLogoErrorMsg] = useState("");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState(false);
  const [checked, setChecked] = useState(false);
  const indexOfLastPost = currentPage * postsPerPage;
  // console.log("indexOfLastPost: ", indexOfLastPost);

  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // console.log("indexOfFirstPost: ", indexOfFirstPost);

  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setKeyWord("");
    getAssetRepo(keyWord);
    //console.log("currentPosts: ", props);
    // if (props.type === "illustration") {
    //console.log("currentPosts: ", currentPosts);
    setPosts(logosRepo);
    // }
  }, [props.openAssetRpo]);

  useEffect(() => {
    setPosts(logosRepo);
  }, [logosRepo]);

  // useEffect(() => {
  //   getSearchBoxResult();
  // }, [keyWord]);

  const __serachbox = (val) => {
    try {
      let tags;

      tags =
        val.target.value !== null &&
          val.target.value !== "Null" &&
          val.target.value !== ""
          ? val.target.value.split(",")
          : "";
      // console.log(tags,"tagstags");
      setSearchBoxErrorMsg(false);
      if (keyWord !== tags) {
        setKeyWord(tags);
        getSearchBoxResult(tags)
      } else {
        if (tags !== "") {
          setKeyWord(tags);
        } else {
          setKeyWord("");
        }
      }
    } catch (error) { }
  };

  const getSearchBoxResult = useCallback(debounce((value) => {
    try {
      // this.keyWord = this.state.keyWord
      setLogoRepoLoader(true);
      setLogoRepo([]);
      // if (props.type === "illustration") {
      //console.log("ghjhgjghjghjhgj");
      getAssetRepo(value);
      // }
    } catch (error) { }
  }, 1000), [logoRepoLoader]);
  useEffect(() => {
    setTimeout(() => {
      setSearchBoxErrorMsg(false);
    }, 10000);
  }, [SearchBoxErrorMsg]);

  const __searchByClick = () => {
    //console.log(props, "fgfdgfdgfdg");
    let searchValue = document.getElementById("searchBtn1").value;
    // console.log(serachValue);
    if (searchValue === "") {
      setSearchBoxErrorMsg(true);
    } else {
      setSearchBoxErrorMsg(false);
      if (searchValue !== "") {
        setLogoRepoLoader(true);
        getAssetRepo(searchValue);
        // this.setState({ imageRepoLoader: true, iconRepoLoader: true, videoRepoLoader: true, logoRepoLoader: true, iconRepo: [], imagesRepo: [], logoRepo: [], videoRepo: [], motionGraphics: [], motionGraphicsLoader: true })
      } else {
        setSearchBoxErrorMsg(true);
      }
    }
  };

  const SelectAsset = (mediaUrl) => {
    //console.log(medialUrl);
    // let asset = props.item[1];
    // asset.Contentparams.contentdescription = medialUrl;
    // let data = {
    //   [props.item[0]]: asset,
    // };
    //console.log(data);
    //UpdatePreviewFeature(data);
    props.setdisplayImage(mediaUrl)
    props.closePreview();
  };

  const getAssetRepo = (searchWord) => {
    setLogoRepoLoader(true);
    // if (props.type === "illustration") {
    //console.log(props.AssetType, "fgfdgfdgfdg");

    let request = {
      media_type: "LOGO",
      search_term: searchWord,
      start: pageNo,
      end: endPageNo,
      private: !checked ? 0 : 1
    };
    getAssets(request)
      .then((response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200 &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          if (Object.entries(response.data).length > 0) {
            //console.log(response.data.mediaurl, "response");
            let projectData = response.data.mediaurl;
            setLogoRepo(projectData);
            setLogoRepoLoader(false);
          } else {
            setLogoRepoLoader(false);
            setLogoRepo([]);
            setLogoErrorMsg("No logos available");
          }
        } else if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.message !== "" &&
          response.message !== undefined &&
          response.message !== null
        ) {
          // this.setState({ iconRepoLoader: false,progressLoader:false, iconErrorMsg: "No logos available", iconRepo: [] })
          setLogoRepoLoader(false);
          setLogoRepo([]);
          setLogoErrorMsg("No logos available");
        } else {
          setLogoRepoLoader(false);
          setLogoRepo([]);
          setLogoErrorMsg("No logos available");
          // this.setState({ iconRepoLoader: false,progressLoader:false, iconErrorMsg: "No logos available", iconRepo: [] })
        }
      })
      .catch((error) => {
        setLogoRepoLoader(false);
        setLogoRepo([]);
        setLogoErrorMsg("No logos available");
        // this.setState({ iconRepoLoader: false,progressLoader:false, iconErrorMsg: "No icons available", iconRepo: [] })
      });
    // }
  };
  const viewImagePreview = (image) => {
    // console.log(image, "imageee");
    setOpenImagePreview(true);
    setSelectedImageFile(image);
  };
  const viewPrivateRepo = (event) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    // setPrivateValue(1)
    getAssetRepo(keyWord)
  }, [checked])
  return (
    <React.Fragment>

      <div className="srch-temp-blk">

        <div className="d-flex align-items-center position-relative flex-wrap">
          <div className="d-flex align-items-center pro-temp-container mr-4">
            <div className="cm-header .cm-subhead">
              Private Repo
             </div>
            <Checkbox
              checked={checked}
              onChange={(e) => viewPrivateRepo(e)}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          <div className="srch-temp-wrapper">
            <div className="srch-holder">
              <div
                className="srch-icon"

                onClick={() => __searchByClick()}
              ><Tooltip title="Search" arrow>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21.9243 21.925L27.9994 28.0001"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg></Tooltip>
              </div>
              <div className="srch-inpt-holder">
                <input
                  id="searchBtn1"
                  onChange={(e) => __serachbox(e)}
                  type="text"
                  name="focus"
                  required
                  className="form-control srch-input"
                  placeholder="Search logos"
                  value={keyWord}
                />
                <button
                  className="search-close"
                  type="reset"
                  onClick={() => setKeyWord("")}
                >
                  <Tooltip title="Reset" arrow>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      space="preserve"
                    >
                      <g>
                        <path
                          d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M327.115,365.904
                                      L256,294.789l-71.115,71.115l-38.789-38.789L217.211,256l-71.115-71.115l38.789-38.789L256,217.211l71.115-71.115l38.789,38.789
                                      L294.789,256l71.115,71.115L327.115,365.904z"
                        />
                      </g>
                    </svg></Tooltip>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="cm-st-gallery my-3">
          {/* {logosRepo.length > 0 ? (
                        ""
                    ) : (
                            <div className="v-gal-empty-blk">
                                <div className="no-txt-selected blink">
                                    {capitalizeFirstLetter(logoErrorMsg)}
                                </div>
                            </div>
                        )} */}

          {logoRepoLoader === false ? (
            logosRepo.length > 0 ? (
              <div className="cm-gallery-wrapper">
                {currentPosts.map((logos, index) => {
                  //console.log(logos, "icon");
                  let mediaurl = "";
                  if (
                    logos.thumbnailurl !== undefined &&
                    logos.thumbnailurl !== null &&
                    logos.thumbnailurl !== ""
                  ) {
                    mediaurl = logos.thumbnailurl;
                  } else if (logos.thumbnailurl === null) {
                    mediaurl = logos.mediaurl;
                  } else {
                    mediaurl = logos;
                  }
                  return (
                    <div className="cm-gallery-container replace-img">
                      <div className="img-info">
                        {logos.private === 1 &&
                          <Tooltip title="Private Images" arrow>
                            <span>
                              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 330 330" space="preserve">
                                <g id="XMLID_486_">
                                  <path id="XMLID_487_" d="M165,330c63.411,0,115-51.589,115-115c0-29.771-11.373-56.936-30-77.379V85c0-46.869-38.131-85-85-85
                                        S80.001,38.131,80.001,85v52.619C61.373,158.064,50,185.229,50,215C50,278.411,101.589,330,165,330z M180,219.986V240
                                        c0,8.284-6.716,15-15,15s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
                                        C190,208.162,186.068,215.421,180,219.986z M110.001,85c0-30.327,24.673-55,54.999-55c30.327,0,55,24.673,55,55v29.029
                                        C203.652,105.088,184.91,100,165,100c-19.909,0-38.651,5.088-54.999,14.028V85z"/>
                                </g>
                              </svg>
                            </span></Tooltip>}
                      </div>
                      <div className="icon-wrap">
                        {logos.thumbnailurl !== undefined &&
                          logos.thumbnailurl !== null &&
                          logos.thumbnailurl !== "" ? (
                            <span
                              onClick={() => {
                                viewImagePreview(logos.mediaurl);
                              }}
                              className="img-view"
                              data-bs-toggle="modal"
                              data-bs-target="#view-img"
                            >
                              <svg
                                width="20"
                                height="17"
                                viewBox="0 0 20 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.99889 5.64136C8.66889 5.64136 7.58789 6.72336 7.58789 8.05336C7.58789 9.38236 8.66889 10.4634 9.99889 10.4634C11.3289 10.4634 12.4109 9.38236 12.4109 8.05336C12.4109 6.72336 11.3289 5.64136 9.99889 5.64136ZM9.99889 11.9634C7.84189 11.9634 6.08789 10.2094 6.08789 8.05336C6.08789 5.89636 7.84189 4.14136 9.99889 4.14136C12.1559 4.14136 13.9109 5.89636 13.9109 8.05336C13.9109 10.2094 12.1559 11.9634 9.99889 11.9634Z"
                                  fill=""
                                />
                                <mask
                                  id="mask0_2688_3325"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="20"
                                  height="17"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0 0.000244141H19.9998V16.1052H0V0.000244141Z"
                                    fill=""
                                  />
                                </mask>
                                <g mask="url(#mask0_2688_3325)">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M1.56975 8.05251C3.42975 12.1615 6.56275 14.6045 9.99975 14.6055C13.4368 14.6045 16.5698 12.1615 18.4298 8.05251C16.5698 3.94451 13.4368 1.50151 9.99975 1.50051C6.56375 1.50151 3.42975 3.94451 1.56975 8.05251V8.05251ZM10.0017 16.1055H9.99775H9.99675C5.86075 16.1025 2.14675 13.2035 0.06075 8.34851C-0.02025 8.15951 -0.02025 7.94551 0.06075 7.75651C2.14675 2.90251 5.86175 0.00350586 9.99675 0.000505859C9.99875 -0.000494141 9.99875 -0.000494141 9.99975 0.000505859C10.0018 -0.000494141 10.0018 -0.000494141 10.0028 0.000505859C14.1388 0.00350586 17.8527 2.90251 19.9387 7.75651C20.0207 7.94551 20.0207 8.15951 19.9387 8.34851C17.8537 13.2035 14.1388 16.1025 10.0028 16.1055H10.0017Z"
                                    fill=""
                                  />
                                </g>
                              </svg>
                            </span>
                          ) : (

                            <span
                              onClick={() => {
                                viewImagePreview(logos);
                              }}
                              className="img-view"
                              data-bs-toggle="modal"
                              data-bs-target="#view-img"
                            ><Tooltip  arrow>
                                <svg
                                  width="20"
                                  height="17"
                                  viewBox="0 0 20 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.99889 5.64136C8.66889 5.64136 7.58789 6.72336 7.58789 8.05336C7.58789 9.38236 8.66889 10.4634 9.99889 10.4634C11.3289 10.4634 12.4109 9.38236 12.4109 8.05336C12.4109 6.72336 11.3289 5.64136 9.99889 5.64136ZM9.99889 11.9634C7.84189 11.9634 6.08789 10.2094 6.08789 8.05336C6.08789 5.89636 7.84189 4.14136 9.99889 4.14136C12.1559 4.14136 13.9109 5.89636 13.9109 8.05336C13.9109 10.2094 12.1559 11.9634 9.99889 11.9634Z"
                                    fill=""
                                  />
                                  <mask
                                    id="mask0_2688_3325"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="20"
                                    height="17"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0 0.000244141H19.9998V16.1052H0V0.000244141Z"
                                      fill=""
                                    />
                                  </mask>
                                  <g mask="url(#mask0_2688_3325)">
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M1.56975 8.05251C3.42975 12.1615 6.56275 14.6045 9.99975 14.6055C13.4368 14.6045 16.5698 12.1615 18.4298 8.05251C16.5698 3.94451 13.4368 1.50151 9.99975 1.50051C6.56375 1.50151 3.42975 3.94451 1.56975 8.05251V8.05251ZM10.0017 16.1055H9.99775H9.99675C5.86075 16.1025 2.14675 13.2035 0.06075 8.34851C-0.02025 8.15951 -0.02025 7.94551 0.06075 7.75651C2.14675 2.90251 5.86175 0.00350586 9.99675 0.000505859C9.99875 -0.000494141 9.99875 -0.000494141 9.99975 0.000505859C10.0018 -0.000494141 10.0018 -0.000494141 10.0028 0.000505859C14.1388 0.00350586 17.8527 2.90251 19.9387 7.75651C20.0207 7.94551 20.0207 8.15951 19.9387 8.34851C17.8537 13.2035 14.1388 16.1025 10.0028 16.1055H10.0017Z"
                                      fill=""
                                    />
                                  </g>
                                </svg></Tooltip>
                            </span>
                          )}

                        <span
                          className="img-edit"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-img"
                          onClick={() => SelectAsset(logos)}

                        >      <Tooltip title="Save" arrow>
                            <svg
                              width="30px"
                              height="24px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z"
                                fill=""
                              />
                            </svg></Tooltip>
                        </span>
                      </div>
                      <ReactImageFallback
                        src={mediaurl}
                        fallbackImage="../../../assets/img/fallbackImage.jpg"
                        initialImage="../../../assets/img/fallbackImage.jpg"
                        alt="templates"
                        className="img-fluid"
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
                <div className="v-gal-empty-blk">
                  <div className="no-txt-selected blink">
                    {capitalizeFirstLetter(logoErrorMsg)}
                  </div>
                </div>
              )
          ) : (
              <div className="v-gal-empty-blk">
                <CircularProgress />
              </div>
            )}
        </div>
        {logosRepo.length > 0 && (
          <Pagination
            paginate={paginate}
            postsPerPage={postsPerPage}
            totalPosts={posts.length}
          />
        )}
      </div>
      <ImagePreview
        closePreview={() => setOpenImagePreview(false)}
        openImagePreview={openImagePreview}
        selectedImage={selectedImageFile}
      />
    </React.Fragment>
  );
}
