import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actionCreator } from '../../../store';
import FormTextarea from '../../../components/form/formTextarea';
import CustomFileUpload from '../../../components/form/customFileUpload';
import { recordIcon, speechIcon, backArrowIcon, uploadCloudIcon } from '../../../constants/icon';

function AudioComponent({ goBack, audioScript, audioUpload, manipulateTemplateArray }) {
  const dispatch = useDispatch();
  let { EditAudioScript, UpdateAudioUpload } = bindActionCreators(actionCreator, dispatch);

  const [currentView, setCurrentView] = useState('audio');
  const [audioScriptError, setAudioScriptError] = useState('');

  const handleSpeechClick = () => {
    setCurrentView('speech');
  };

  // const handleRecordClick = () => {
  //   setCurrentView('record');
  // };

  const handleBackClick = () => {
    setCurrentView('audio');
  };

  const checkInputValidation = value => {
    const regexMatch = /^[\.a-zA-Z,' \d-]*$/.test(value);

    if (!regexMatch) {
      return 'Accepts only alphabetic characters and numbers';
    }

    return false;
  };

  const handleChangeAudio = value => {
    if (!checkInputValidation(value)) {
      manipulateTemplateArray();
      EditAudioScript({ contenttag: '', sequenceid: '2', contentdescription: value });
    }
  };

  const handleAudioUpload = audio => {
    UpdateAudioUpload(audio);
  };

  const handleAudioRemove = () => {
    manipulateTemplateArray();
    UpdateAudioUpload('Null');
  };

  return (
    <>
      {currentView === 'audio' && (
        <>
          <div className="screen-sidebar-header screen-header-back">
            <div className="sticky-head">
              <span role="button" onClick={goBack}>
                {backArrowIcon} Audio
              </span>
              <p>Upload a Audio file for the Video to be created</p>
            </div>
          </div>
          <div className="screen-sidebar-body screen-audio-wrap">
            <CustomFileUpload
              accept=".mp3"
              fileType="audio"
              icon={uploadCloudIcon}
              defaultFile={audioUpload}
              fileName="Upload a Audio file"
              handleUpload={handleAudioUpload}
              handleRemove={handleAudioRemove}
              description="Click to browse, or drag & drop a file here"
            />
            <div className="screen-btn-wrap">
              {/* <button role="button" className="screen-action-btn" onClick={handleRecordClick}>
                {recordIcon} Record
              </button> */}
              <button role="button" className="screen-action-btn" onClick={handleSpeechClick}>
                {speechIcon} Text To Speech
              </button>
            </div>
          </div>
        </>
      )}

      {currentView === 'speech' && (
        <>
          <div className="screen-sidebar-header screen-header-back">
            <div className="sticky-head">
              <span role="button" onClick={handleBackClick}>
                {backArrowIcon} Text to speech
              </span>
              <p>Type text to convert audio and add to your video</p>
            </div>
          </div>
          <div className="screen-sidebar-body screen-audio-wrap">
            <div className="mt-2">
              <FormTextarea
                id="message"
                placeholder=""
                error={audioScriptError}
                inputValue={audioScript}
                label="Text to voice script"
                onChange={handleChangeAudio}
                setError={setAudioScriptError}
                inputValidation={checkInputValidation}
              />
            </div>
          </div>
        </>
      )}

      {/* {currentView === 'record' && (
        <>
          <div className="screen-sidebar-header screen-header-back">
            <div className="sticky-head">
              <span role="button" onClick={handleBackClick}>
                {backArrowIcon} Record
              </span>
              <p>Record voice to convert audio and add to your video</p>
            </div>
          </div>
          <div className="screen-sidebar-body screen-audio-wrap">
            <div className="mt-2">
              <FormTextarea id="message" placeholder="" label="Text to Record script" />
            </div>
          </div>
        </>
      )} */}
    </>
  );
}
export default AudioComponent;
