import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import {
  CreateModule,
  CreateProjectFromResponse,
  CreateProjectModel,
  CreateModuleManual,
  CreateProjectModelManual,
  removeMediaUrlFilter,
  CreatePreviewFromResponse,
} from '../../../services/DataHelper';
import { actionCreator } from '../../../store/index';
import Header from '../../components/header/header';
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';
// import COUNT from '../../../constants';
import AddScreen from './AddScreen';
import AddSlide from './AddSlide';
import AddAudioScript from '../../container/addinput/addAudioScript';
import AddScreenDuration from '../../container/addinput/screenDuration';
import AddOst from '../../container/addinput/AddOST';
import AddVisualEffect from '../../container/addinput/AddVisualEffects';
import {
  createProjects,
  getProjectID,
  getTemplatesRepo,
  projectScreenPreview,
} from '../../../services/api.service';
import Swal from 'sweetalert2';
import { showToaster, getUserItem } from '../../../services/helper';
import { useHistory } from 'react-router-dom';
import PageLoader from '../../../services/pageLoader';
import {
  BULLETFONT,
  FONT_SIZE_H,
  FONT_SIZE_P,
  FONT_URL,
  HEADERFONT,
  INIT_OST_FONT,
  PARAGRAPHFONT,
} from '../../../constants';
import StyleChooser from '../../dashboard/project-priview/RightBar/StyleChooser';
import CustomModal from '../../../services/CustomModal';
import CustomModalArray from '../../../services/CustomModalArray';
import NavigatorOnline from 'react-navigator-online';
import UsePreviousTemplate from './UsePreviousTemplate';
import SaveChangeModal from '../../../services/SaveChangeModal';
import {
  getOstLimit,
  getStyles,
  getTemplatePlanByOrg,
  getUsersProjectRole,
} from '../../../services/authService';
import { getProjectById } from '../../../services/authService';
import { Modal, ModalBody } from 'reactstrap';
import { calculatePatternMatchPercentage } from '../../../lib/string-compare';
import { removeObjectFromArray } from '../../../services/HelperFunctions';

function AddProject(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    AddModule,
    InitProject,
    RemoveModule,
    SelectModule,
    EditProject,
    RenameModuleName,
    UpdateEditMode,
    PreviousActive,
    EmptyProject,
    EmptyPreview,
    InitilizePreview,
    PreviousActivetoPreview,
  } = bindActionCreators(actionCreator, dispatch);
  let project = useSelector(state => state.project);
  let EditMode = useSelector(state => state.project.EditMode);
  let ActiveModule = useSelector(state => state.project.ActiveModule);
  let ActiveSlide = useSelector(state => state.project.ActiveSlide);
  let ActiveScreen = useSelector(state => state.project.ActiveScreen);
  let FontUrl = useSelector(state => state.project.FontUrl);
  let FontHSize = useSelector(state => state.project.FontHSize);
  let FontPSize = useSelector(state => state.project.FontPSize);
  let TextColor = useSelector(state => state.project.TextColor);
  let BgColor = useSelector(state => state.project.BgColor);

  const [projects, setProjects] = useState('');
  const [projectLoaded, setProjectLoaded] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');
  const [toggle, setToggle] = useState(false);
  const [styleChooser, setStyleChooser] = useState(false);
  const [ASValidate, setASValidate] = useState(false);
  const [OstValidate, setOstValidate] = useState(false);
  const [openUpdateModal, setopenUpdateModal] = useState(false);
  const [openDeletemodal, setopenDeletemodal] = useState(false);
  const [deleteModuleId, setdeleteModuleId] = useState(undefined);
  const [oldModuleName, setoldModuleName] = useState('');
  const [madeChange, setmadeChange] = useState(false);
  const [renderCount, setrenderCount] = useState(0);
  const [refreshpage, setrefreshPage] = useState(false);
  const [openSaveChangesModal, setopenSaveChangesModal] = useState(false);
  const [openTemplateChangesModal, setopenTemplateChangesModal] = useState(false);
  const [ModuleName, setModuleName] = useState('');
  let DisplayMode = useSelector(state => state.project.DisplayMode);
  const [templateCheckArray, settemplateCheckArray] = useState(undefined);
  const [validatingTempLoader, setvalidatingTempLoader] = useState(false);
  const [cacheProjectData, setcacheProjectData] = useState(undefined);
  const [projectRolePreview, setProjectRolePreview] = useState(false);
  const [screenLimit, setScreenLimit] = useState(10);
  const [slideLimit, setSlideLimit] = useState(10);
  const [moduleLimit, setModuleLimit] = useState(10);
  const [ostCountValidate, setostCountValidate] = useState([]);
  const [imageCountValidate, setImageCountValidate] = useState([]);
  const [illuCountValidate, setIlluCountValidate] = useState([]);
  const [videoCountValidate, setVideoCountValidate] = useState([]);
  const [templateSelectionType, setTemplateSelectionType] = useState(undefined);
  const [ostValidation, setOstValidation] = useState([]);
  const [ostValidationEnable, setOstValidationEnable] = useState(false);
  const [audioScriptValidation, setAudioScriptValidation] = useState([]);
  const [ModuleCount, setModuleCount] = useState(0);
  const [errorScreen, setErrorScreen] = useState([]);
  const [activeScreenState, setActiveScreenState] = useState(ActiveScreen);
  const [activeSlideState, setActiveSlideState] = useState(ActiveSlide);
  const [activeModuleState, setActiveModuleState] = useState(ActiveModule);
  const [invalidAudioScreens, setInvalidAudioScreen] = useState([]);
  const [invalidOstScreens, setInvalidOstScreen] = useState([]);
  const [activeAudioScriptComparisonResultsState, setActiveAudioScriptComparisonResultsState] =
    useState([]);
  const [currentScreenKey, setCurrentScreenKey] = useState('');
  const [slideChangesState, setSlidesChangesState] = useState([]);
  const previousActiveScreen = useRef(null);
  const previousActiveSlide = useRef(null);
  const previousActiveModule = useRef(null);
  let { id } = useParams();

  let COUNT = 0;
  let userId = getUserItem('id');
  let AdminRole = getUserItem('role');
  let userParentId = getUserItem('orgId');
  let orgId = AdminRole === 0 ? userId : userParentId;

  const showMessage = status => {
    if (status) {
      showToaster('"now! you have an internet connection.', 'success');
    } else {
      showToaster('now! you have no internet connection', 'warning');
    }
  };

  useEffect(() => {
    if (audioScriptValidation.length > 0) {
      showToaster('Audio script is Required please  fill and continue', 'warning');
    }
  }, [audioScriptValidation]);

  useEffect(() => {
    if (ostValidation.length > 0) {
      setOstValidationEnable(true);
      // showToaster('OST is Required please  fill and continue', 'warning');
    } else {
      setOstValidationEnable(false);
    }
  }, [ostValidation]);

  useEffect(() => {
    try {
      if (projectId) {
        if (
          projects.createdProject[projectId] !== undefined &&
          projects.createdProject[projectId].Modules !== undefined
        ) {
          const module = Object.keys(projects.createdProject[projectId].Modules);
          setModuleCount(module.length);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [projects, projectId]);

  useEffect(async () => {
    let orgId = AdminRole == 0 ? userId : userParentId;
    await getTemplatePlanByOrg(orgId).then(async response => {
      try {
        if (
          response !== '' &&
          response !== undefined &&
          response !== null &&
          response.status !== '' &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200 &&
          response.data !== '' &&
          response.data !== undefined &&
          response.data !== null
        ) {
          let planData = response.data.result[0];
          setTemplateSelectionType(response.data.result[0]);
          let project_id = props.match.params.id;
          if (project_id) {
            setProjectId(project_id);
            let filter = {
              project_id: props.match.params.id,
            };
            getProjectID(filter).then(async response => {
              try {
                if (
                  response !== '' &&
                  response !== undefined &&
                  response !== null &&
                  response.status !== '' &&
                  response.status !== undefined &&
                  response.status !== null &&
                  response.status === 200 &&
                  response.data !== '' &&
                  response.data !== undefined &&
                  response.data !== null
                ) {
                  let projectDatafromDb = await getProjectById(project_id);
                  let projectData = '';
                  if (projectDatafromDb.data.data.length > 0) {
                    setcacheProjectData(JSON.parse(projectDatafromDb.data.data[0].ProjectData));
                    projectData = JSON.parse(projectDatafromDb.data.data[0].ProjectData);
                  }
                  setProjectName(response.data[0].ProjectName);
                  if (projectData[project_id].Modules != undefined) {
                    EmptyProject();
                    setProjectLoaded(false);
                    let res = await CreateProjectFromResponse(projectDatafromDb.data.data[0]);
                    UpdateEditMode(true);
                    EditProject(res);
                    if (props.location.state !== undefined) {
                      PreviousActive({
                        activeModule: props.location.state.ActiveModule,
                        activeSlide: props.location.state.ActiveSlide,
                        activeScreen: props.location.state.ActiveScreen,
                      });
                    }
                    if (localStorage.getItem('iscloned')) {
                      setStyleChooser(true);
                      localStorage.removeItem('iscloned');
                    }
                  } else {
                    EmptyProject();
                    setProjectLoaded(false);

                    let categoryId = JSON.parse(response.data[0].ProjectData);
                    let NotificationURL =
                      (process.env.REACT_APP_API_URL_DEV || process.env.REACT_APP_API_URL) +
                      'api/project-rendered';
                    let projectInputs;
                    if (planData.manualTemplate == true) {
                      projectInputs = await CreateProjectModelManual(
                        project_id,
                        response.data[0].ProjectName,
                        categoryId[project_id].CategoryID,
                        NotificationURL,
                        INIT_OST_FONT,
                        FONT_SIZE_H,
                        FONT_SIZE_P
                      );
                    } else {
                      projectInputs = await CreateProjectModel(
                        project_id,
                        response.data[0].ProjectName,
                        categoryId[project_id].CategoryID,
                        NotificationURL,
                        INIT_OST_FONT,
                        FONT_SIZE_H,
                        FONT_SIZE_P
                      );
                    }
                    UpdateEditMode(false);
                    InitProject(projectInputs);
                    setStyleChooser(true);
                  }
                }
              } catch (error) {
                console.log(error);
              }
            });
          } else {
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
    let response = await getOstLimit();
    setModuleLimit(response.data.result.data[0].moduleLimit);
    setProjectRoles();
  }, []);

  const setProjectRoles = async () => {
    let project_id = props.match.params.id;
    if (AdminRole === 1) {
      await getUsersProjectRole(userId).then(response => {
        try {
          setPageLoad(true);
          if (
            response !== '' &&
            response !== undefined &&
            response !== null &&
            response.data !== '' &&
            response.data !== undefined &&
            response.data !== null
          ) {
            setPageLoad(false);
            let tempArray = [];
            let projectRole = response.data.result;
            projectRole.map(projectrole => {
              if (projectrole.projectId === project_id && projectrole.editProject === false) {
                history.push('/project-list');
              }

              return projectrole;
            });
          } else {
            setPageLoad(false);
            showToaster('Something went wrong. Please Try Again1', 'warning');
          }
        } catch (error) {
          setPageLoad(false);
          showToaster('Something went wrong. Please Try Again', 'warning');
        }
      });
    }
  };
  useEffect(() => {
    if (project.createdProject[projectId] != null) {
      setProjectLoaded(true);
      if (project.createdProject[projectId].Modules[ActiveModule] != undefined) {
        if (
          project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] != undefined
        ) {
          setrenderCount(renderCount + 1);
          setmadeChange(true);
        }
      }
    } else {
      //15 sec => redirect to project creation page
    }
    setProjects(project);
  }, [project]);
  useEffect(() => {
    if (OstValidate) {
      setOstValidate(false);
    }
    if (ASValidate) {
      setASValidate(false);
    }
  }, [OstValidate, ASValidate]);

  useEffect(() => {
    if (cacheProjectData) {
      let templateValidArray = [];
      if (cacheProjectData[projectId]?.Modules) {
        Object.entries(cacheProjectData[projectId].Modules).map((moduleData, moduleIndex) => {
          let mData = moduleData[1];
          let slides = mData.slides;
          Object.entries(slides).map((slideData, moduleIndex) => {
            let slide = slideData[1];
            let screens = slide.Screens;
            Object.entries(screens).map((screenData, moduleIndex) => {
              let slide = screenData[1];
              Object.entries(slide).map((item, key) => {
                let activeTemplate = item.filter(function (data) {
                  return data.Contenttype === 'TemplateID';
                });
                let isValids = false;
                if (activeTemplate.length > 0) {
                  if (activeTemplate[0].Contentparams.contentdescription !== '') {
                    isValids = true;
                  } else {
                    isValids = false;
                  }
                  if (activeTemplate[0].Contentparams.contentdescription !== 'no template') {
                    isValids = true;
                  } else {
                    isValids = false;
                  }

                  let templateValid = {
                    isValid: isValids,
                    templateKeys: moduleData[0],
                    templateSlidekeys: slideData[0],
                    templateScreenkeys: screenData[0],
                    screenName:
                      moduleData[1].ModuleName +
                      '-' +
                      slideData[1].SlideName +
                      '-' +
                      screenData[1].ScreenName,
                  };
                  templateValidArray.push(templateValid);
                }
              });
            });
          });
        });
        settemplateCheckArray(templateValidArray);
      }
    }
  }, [cacheProjectData]);

  const RearrangeData = (oldData, newData) => {
    return new Promise((resolve, reject) => {
      Object.entries(newData).map((moduleData, moduleIndex) => {
        let mData = moduleData[1];
        let slides = mData.slides;
        Object.entries(slides).map((slideData, moduleIndex) => {
          let slide = slideData[1];
          let screens = slide.Screens;
          Object.entries(screens).map((screenData, moduleIndex) => {
            let slide = screenData[1];
            let _arr = [];
            Object.entries(slide).map((item, key) => {
              let oldDataObj =
                oldData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[
                  screenData[0]
                ][item[0]];
              if (item[1].Contenttype === 'AudioScript') {
                if (
                  item[1].Contentparams.contentdescription !=
                  oldDataObj.Contentparams.contentdescription
                ) {
                  _arr.push(1);
                }
              }
              if (item[0] == 'CS01') {
                if (
                  item[1].Contentparams.contentdescription !=
                  oldDataObj.Contentparams.contentdescription
                ) {
                  _arr.push(2);
                }
              }
              if (item[1].Contenttype === 'Image') {
                if (oldDataObj != undefined) {
                  if (
                    item[1].Contentparams.contentdescription !=
                    oldDataObj.Contentparams.contentdescription
                  ) {
                    _arr.push(4);
                  }
                }
              }
              if (item[1].Contenttype === 'ScreenDuration') {
                if (
                  item[1].Contentparams.contentdescription !=
                  oldDataObj.Contentparams.contentdescription
                ) {
                  _arr.push(4);
                }
              }
              if (item[1].Contenttype === 'OST') {
                let contentdescription_split = oldDataObj.Contentparams.contentdescription.slice(
                  0,
                  -3
                );
                if (item[1].Contentparams.contentdescription != contentdescription_split) {
                  _arr.push(5);
                }
              }
              if (item[1].Contenttype === 'Video') {
                if (oldDataObj != undefined) {
                  if (
                    item[1].Contentparams.contentdescription !=
                    oldDataObj.Contentparams.contentdescription
                  ) {
                    _arr.push(4);
                  }
                }
              }
              if (item[1].Contenttype === 'Illustration') {
                if (oldDataObj != undefined) {
                  if (
                    item[1].Contentparams.contentdescription !=
                    oldDataObj.Contentparams.contentdescription
                  ) {
                    _arr.push(4);
                  }
                }
              }
              if (item[0] == 'mediaurl') {
                delete newData[moduleData[0]].slides[slideData[0]].Screens[screenData[0]][
                  'mediaurl'
                ];
              }
            });
          });
        });
      });
      resolve(newData);
    });
  };

  const CreateNewModule = async () => {
    let moduleArray = [];
    let newMod;
    let data;
    Object.entries(projects.createdProject[projectId].Modules).map((item, key) => {
      if (item[0] === '') {
        moduleArray.push('M01');
      } else {
        moduleArray.push(item[0]);
      }
      let lastItem = moduleArray.pop();
      let moduleNo = lastItem.replace('M0', '');
      let a = parseInt(moduleNo);
      let array = moduleNo.split('M');
      let b = parseInt(array[1]);

      if (array[1] !== undefined && array[1] !== null && array[1] !== '') {
        data = b + 1;
      } else {
        data = a + 1;
      }
      if (data !== undefined && data !== null) {
        newMod = data <= 9 ? 'M0' + data : 'M' + data;
      }
    });
    let newModule;
    if (templateSelectionType.manualTemplate == true) {
      if (EditMode) {
        let response = await getStyles(projectId);
        let style = await response.data.result;
        let font_url = FONT_URL + style.fontFamily + '/' + style.fontStyle;
        newModule = await CreateModuleManual(
          newMod,
          data,
          font_url,
          style.headingSize,
          style.paragraphSize,
          style.textColor,
          style.bgColor
        );
      } else {
        newModule = await CreateModuleManual(
          newMod,
          data,
          FontUrl,
          FontHSize,
          FontPSize,
          TextColor,
          BgColor
        );
      }
    } else {
      if (EditMode) {
        let response = await getStyles(projectId);
        let style = await response.data.result;
        let font_url = FONT_URL + style.fontFamily + '/' + style.fontStyle;
        newModule = await CreateModule(
          newMod,
          data,
          font_url,
          style.headingSize,
          style.paragraphSize,
          style.textColor,
          style.bgColor
        );
      } else {
        newModule = await CreateModule(
          newMod,
          data,
          FontUrl,
          FontHSize,
          FontPSize,
          TextColor,
          BgColor
        );
      }
    }

    AddModule(newModule);
    generatePreview();
  };
  const Removemodule = moduleId => {
    RemoveModule(moduleId);
    setdeleteModuleId(undefined);
    setopenDeletemodal(false);
    showToaster('Module Deleted Successfully', 'success');
    if (errorScreen?.some(item => item?.activeModule === moduleId)) {
      setErrorScreen(removeObjectFromArray(errorScreen, 'activeModule', moduleId));
    }
    if (invalidAudioScreens?.some(item => item?.activeModule === moduleId)) {
      setInvalidAudioScreen(removeObjectFromArray(invalidAudioScreens, 'activeModule', moduleId));
    }
    if (invalidOstScreens?.some(item => item?.activeModule === moduleId)) {
      setInvalidOstScreen(removeObjectFromArray(invalidOstScreens, 'activeModule', moduleId));
    }
    if (
      activeAudioScriptComparisonResultsState?.some(
        item => item?.activeModule === moduleId && !item?.isValid
      )
    ) {
      setActiveAudioScriptComparisonResultsState(
        removeObjectFromArray(activeAudioScriptComparisonResultsState, 'activeModule', moduleId)
      );
    }
  };

  const cancelButton = () => {
    let message = 'you want to exit from the project?';
    let confirmBtn = 'exit';
    Swal.fire({
      title: 'Are you sure ',
      html: message,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, ' + confirmBtn + ' it!',
      confirmButtonColor: '#d33',
      focusConfirm: false,
      focusCancel: true,
    }).then(result => {
      if (result.isConfirmed) {
        history.push('/project-list');
      }
    });
  };
  const backArrow = () => {
    if (
      history.location.state !== undefined &&
      history.location.state.path !== undefined &&
      history.location.state.path === 'preview-project'
    ) {
      if (renderCount > 3) {
        setopenSaveChangesModal(true);
      } else {
        history.goBack();
      }
    } else {
      if (renderCount > 2) {
        setopenSaveChangesModal(true);
      } else {
        history.goBack();
      }
    }
  };

  const handleModuleClick = moduleId => {
    let firstSlide = Object.keys(
      projects.createdProject[projectId].Modules[ActiveModule].slides
    )[0];
    let payload = {
      moduleId: moduleId,
      slideId: firstSlide,
    };
    SelectModule(payload);
  };

  function toggleInput(moduleid, moduleName) {
    setoldModuleName(moduleName);
    setToggle(true);
  }

  const ChangeModuleName = name => {
    RenameModuleName(name);
    setModuleName(name);
  };

  const ChangeModuleNameBluroff = name => {
    if (name.length == 0) {
      RenameModuleName(oldModuleName);
    }
    setToggle(false);
  };
  const listModules = () => {
    if (projects) {
      let modsArray = [];
      let moduleArrlength = Object.keys(projects.createdProject[projectId].Modules).length;
      Object.entries(projects.createdProject[projectId].Modules).map((i, item) => {
        let Style = false;
        let moduleid = i[0];
        let module = i[1];
        const classNames =
          ActiveModule === moduleid ? 'cm-mod-container active  ' : 'cm-mod-container  ';
        if (templateCheckArray !== undefined) {
          templateCheckArray.map(tempArray => {
            if (moduleid === tempArray.templateKeys) {
              if (tempArray.isValid === false) {
                Style = true;
              }
            }
          });
        }
        errorScreen?.map(item => {
          if (item?.activeModule === moduleid) {
            Style = true;
          }
        });
        invalidAudioScreens?.map(item => {
          if (item?.activeModule === moduleid) {
            Style = true;
          }
        });
        invalidOstScreens?.map(item => {
          if (item?.activeModule === moduleid) {
            Style = true;
          }
        });
        // activeAudioScriptComparisonResultsState?.map((item) =>{
        //   if (item?.activeModule === moduleid && !item.isValid) {
        //     Style = true;
        //  }
        // })

        modsArray.push(
          <div className={classNames} key={moduleid}>
            {toggle &&
              (ActiveModule == moduleid ? (
                // <p>{text}</p>
                <div
                  className="d-flex justify-content-between
                                        align-items-center
                                        "
                >
                  {' '}
                  <input
                    type="text"
                    id="lname"
                    name="lname"
                    s
                    value={module.ModuleName}
                    onChange={e => {
                      ChangeModuleName(e.target.value);
                    }}
                    onBlur={() => ChangeModuleNameBluroff(module.ModuleName)}
                    autoFocus
                    maxlength="200"
                  />
                  <div className="re-edit">
                    <span onClick={e => ChangeModuleName(e.target.value)}>
                      <Tooltip title="Save" arrow>
                        <svg
                          width="22px"
                          height="22px"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.3904 5.31232L7.07178 10.7106L3.67991 7.88408L4.32009 7.11586L6.92822 9.2893L10.6096 4.68762L11.3904 5.31232Z"
                            fill=""
                          />
                        </svg>
                      </Tooltip>
                    </span>
                    <span onClick={() => ChangeModuleName(ModuleName)}>
                      <Tooltip title="Close" arrow>
                        <svg
                          width="20px"
                          height="20px"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g data-name="Layer 2">
                            <g data-name="close">
                              <rect
                                width="24"
                                height="24"
                                transform="rotate(180 12 12)"
                                opacity="0"
                              />
                              <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                            </g>
                          </g>
                        </svg>
                      </Tooltip>
                    </span>
                  </div>
                </div>
              ) : (
                <>
                  <h3
                    onDoubleClick={() => {
                      toggleInput(moduleid, module.ModuleName);
                    }}
                    onClick={() => {
                      handleModuleClick(moduleid);
                    }}
                  >
                    {module.ModuleName}
                  </h3>
                </>
              ))}
            {!toggle && (
              <>
                {Style && (
                  <span className="warning-btns uncheck-btn-sm error-notification">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 106.031 106.031"
                      space="preserve"
                    >
                      <g>
                        <path
                          d="M53.015,76.703c-5.815,0-10.528-4.712-10.528-10.528V10.528C42.487,4.714,47.201,0,53.015,0
                                                    c5.813,0,10.528,4.714,10.528,10.528v55.647C63.544,71.991,58.83,76.703,53.015,76.703z"
                        />
                        <circle cx="52.34" cy="97.007" r="9.024" />
                      </g>
                    </svg>
                  </span>
                )}
                <h3
                  onDoubleClick={() => toggleInput(moduleid, module.ModuleName)}
                  onClick={() => handleModuleClick(moduleid)}
                >
                  {module.ModuleName}
                </h3>
              </>
            )}
            {moduleArrlength === 1 ? (
              ''
            ) : (
              <Tooltip arrow>
                <div
                  onClick={() => {
                    setdeleteModuleId(moduleid);
                    setopenDeletemodal(true);
                    //Removemodule(moduleid)
                  }}
                  className="cm-del-icon"
                >
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.1041 9.8187C10.1979 9.91178 10.2507 10.0385 10.2507 10.1707C10.2507 10.3028 10.1979 10.4295 10.1041 10.5226C10.011 10.6165 9.88436 10.6692 9.75223 10.6692C9.6201 10.6692 9.49344 10.6165 9.40039 10.5226L5.29232 6.40817L1.18424 10.5226C1.09119 10.6165 0.964535 10.6692 0.832404 10.6692C0.700273 10.6692 0.573613 10.6165 0.480566 10.5226C0.386753 10.4295 0.333984 10.3028 0.333984 10.1707C0.333984 10.0385 0.386753 9.91178 0.480566 9.8187L4.5936 5.70921L0.480566 1.59972C0.354867 1.47397 0.305775 1.2907 0.351785 1.11893C0.397794 0.947163 0.531913 0.812997 0.703622 0.766972C0.875331 0.720947 1.05854 0.770055 1.18424 0.895797L5.29232 5.01025L9.40039 0.895797C9.59471 0.701416 9.90975 0.701416 10.1041 0.895797C10.2984 1.09018 10.2984 1.40533 10.1041 1.59972L5.99104 5.70921L10.1041 9.8187Z"
                      fill=""
                    />
                  </svg>
                </div>
              </Tooltip>
            )}
          </div>
        );
      });
      return modsArray;
    }
  };
  const countPlusOne = () => {
    COUNT += 1;
    let result = COUNT.toString();
    return result;
  };

  const compareActiveAudioOst = (activeModules, activeSlides, activeScreens) => {
    let modules = project.createdProject;

    let activeScreen =
      modules?.[projectId]?.['Modules']?.[activeModules]?.['slides']?.[activeSlides]?.['Screens']?.[
        activeScreens
      ];

    let activeScreenDetails = {
      ScreenName:
        modules[projectId]?.['Modules']?.[activeModules]?.['slides']?.[activeSlides]?.['Screens']?.[
          activeScreens
        ]?.['ScreenName'],
      ...activeScreen,
    };
    let items = {
      ost: 0,
      ostH: 0,
      ostP: 0,
      ostB: 0,
    };
    let ostHArray = [];
    let ostBArray = [];
    let ostPArray = [];
    let audioScriptString = '';
    let oSTString = '';
    for (const [key, item] of Object.entries(activeScreenDetails)) {
      if (item?.Contenttype === 'AudioScript' && item?.Contentparams?.contentdescription !== '') {
        audioScriptString = item.Contentparams.contentdescription;
      }

      if (item?.Contenttype === 'OST' && item?.Contentparams?.contentdescription !== '') {
        items.ost++;

        if (item?.Contentparams?.fontTag === '[H]') {
          items.ostH++;
          ostHArray.push(item.Contentparams.contentdescription.length);
          oSTString += item.Contentparams.contentdescription;
        }
        if (item?.Contentparams?.fontTag === '[P]') {
          items.ostP++;
          ostPArray.push(item.Contentparams.contentdescription.length);
          oSTString += ' ' + item.Contentparams.contentdescription;
        }
        if (item?.Contentparams?.fontTag === '[B]') {
          items.ostB++;
          ostBArray.push(item?.Contentparams?.contentdescription?.length);
          oSTString += ' ' + item.Contentparams.contentdescription;
        }
      }
    }
    const compareAudioScript = calculatePatternMatchPercentage(audioScriptString, oSTString);
    let moduleSlideScreenKey = activeModules + ' ' + activeSlides + ' ' + activeScreens;
    if (compareAudioScript < 80) {
      setActiveAudioScriptComparisonResultsState([
        ...activeAudioScriptComparisonResultsState,
        {
          activeModule: activeModules,
          activeSlide: activeSlides,
          activeScreen: activeScreens,
          isValid: false,
        },
      ]);
    } else {
      setActiveAudioScriptComparisonResultsState([
        ...activeAudioScriptComparisonResultsState,
        {
          activeModule: activeModules,
          activeSlide: activeSlides,
          activeScreen: activeScreens,
          isValid: true,
        },
      ]);
    }
  };

  const onSlideScreenChange = () => {
    let modules = project.createdProject;
    let projectName = modules[projectId]?.ProjectName;
    let prevActiveScreen = previousActiveScreen.current;
    let prevActiveSlide = previousActiveSlide.current;
    let prevActiveModule = previousActiveModule.current;
    let activeScreen =
      modules?.[projectId]?.['Modules']?.[prevActiveModule]?.['slides']?.[prevActiveSlide]?.[
        'Screens'
      ]?.[prevActiveScreen];
    const modifiedScreenData = activeScreen && JSON.parse(JSON.stringify(activeScreen));
    for (const key in modifiedScreenData) {
      // removes fontTag from OS01 object and appends the value to contentdescription
      if (modifiedScreenData[key].Contenttype === 'OST') {
        const { fontTag, ...restParams } = modifiedScreenData[key].Contentparams;
        modifiedScreenData[key].Contentparams = {
          ...restParams,
          contentdescription: restParams.contentdescription + fontTag,
        };
      }
      if (['Image', 'Video', 'Illustration'].includes(modifiedScreenData[key].Contenttype)) {
        const { searchstring, contenttag } = modifiedScreenData[key].Contentparams;
        if (!searchstring && !contenttag) {
          delete modifiedScreenData[key];
        }
      }
    }

    let activeScreenDetails = {
      ScreenName:
        modules[projectId]?.['Modules']?.[prevActiveModule]?.['slides']?.[prevActiveSlide]?.[
          'Screens'
        ]?.[prevActiveScreen]?.['ScreenName'],
      ...modifiedScreenData,
    };

    let SlideAudioVO =
      modules[projectId]?.['Modules'][prevActiveModule]?.['slides']?.[prevActiveSlide]?.[
        'SlideAudioVO'
      ] !== undefined
        ? modules[projectId]?.['Modules'][prevActiveModule]?.['slides'][prevActiveSlide]?.[
            'SlideAudioVO'
          ]
        : 'Null';

    let screenContent = {
      [projectId]: {
        ProjectName: projectName,
        Modules: {
          [prevActiveModule]: {
            ModuleName: modules[projectId]?.['Modules']?.[prevActiveModule]?.['ModuleName'],
            slides: {
              [prevActiveSlide]: {
                SlideName:
                  modules[projectId]?.['Modules']?.[prevActiveModule]?.['slides'][
                    prevActiveSlide
                  ]?.['SlideName'],
                SlideAudioVO: SlideAudioVO,
                Screens: {
                  [prevActiveScreen]: activeScreenDetails,
                },
              },
            },
          },
        },
      },
    };
    let ostError = false;
    let audioError = false;

    Object.entries(activeScreenDetails).map(item => {
      item.map(element => {
        if (element?.Contenttype === 'OST') {
          if (
            element['Contentparams']['contentdescription'] === '[H]' ||
            element['Contentparams']['contentdescription'] === '[P]' ||
            element['Contentparams']['contentdescription'] === '[B]'
          ) {
            const includesInvalidOstScreen = invalidOstScreens?.some(
              ost =>
                ost?.activeModule === prevActiveModule &&
                ost?.activeSlide === prevActiveSlide &&
                ost?.activeScreen === prevActiveScreen &&
                ost?.ostName === item[0]
            );
            !includesInvalidOstScreen &&
              setInvalidOstScreen([
                ...invalidOstScreens,
                {
                  activeModule: prevActiveModule,
                  activeSlide: prevActiveSlide,
                  activeScreen: prevActiveScreen,
                  ostName: item[0],
                },
              ]);
            ostError = true;
          } else {
            invalidOstScreens?.some(ostScreen => {
              ostScreen?.activeModule === prevActiveModule &&
                ostScreen?.activeSlide === prevActiveSlide &&
                ostScreen?.activeScreen === prevActiveScreen &&
                ostScreen?.ostName === item[0] &&
                setInvalidOstScreen(removeObjectFromArray(invalidOstScreens, 'ostName', item[0]));
            });
            ostError = false;
          }
        }
        if (element?.Contenttype === 'AudioScript') {
          if (element['Contentparams']['contentdescription'] === '') {
            let includesInvalidAudioScreen = invalidAudioScreens?.some(
              audio =>
                audio?.activeModule === prevActiveModule &&
                audio?.activeSlide === prevActiveSlide &&
                audio?.activeScreen === prevActiveScreen
            );
            !includesInvalidAudioScreen &&
              setInvalidAudioScreen([
                ...invalidAudioScreens,
                {
                  activeModule: prevActiveModule,
                  activeSlide: prevActiveSlide,
                  activeScreen: prevActiveScreen,
                },
              ]);
            audioError = true;
          } else {
            invalidAudioScreens?.some(audio => {
              audio?.activeModule === prevActiveModule &&
                audio?.activeSlide === prevActiveSlide &&
                audio?.activeScreen === prevActiveScreen &&
                setInvalidAudioScreen(
                  removeObjectFromArray(invalidAudioScreens, 'activeScreen', prevActiveScreen)
                );
            });
            audioError = false;
          }
        }
      });
    });

    if (!ostError && !audioError) {
      compareActiveAudioOst(prevActiveModule, prevActiveSlide, prevActiveScreen);
      if (!templateSelectionType?.manualTemplate) {
        getTemplatesRepo(screenContent, orgId, projectId)
          .then(response => {
            if (response.status === 200 && response.data[0].length) {
              errorScreen?.some(
                item =>
                  item?.activeModule === prevActiveModule &&
                  item?.activeSlide === prevActiveSlide &&
                  item?.activeScreen === prevActiveScreen &&
                  setErrorScreen(
                    removeObjectFromArray(errorScreen, 'activeScreen', prevActiveScreen)
                  )
              );
              modules[projectId]['Modules'][prevActiveModule]['slides'][prevActiveSlide]['Screens'][
                prevActiveScreen
              ]['TM01']['Contentparams']['contentdescription'] = response?.data[0][0].mediaUrl;
            } else {
              modules[projectId]['Modules'][prevActiveModule]['slides'][prevActiveSlide]['Screens'][
                prevActiveScreen
              ]['TM01']['Contentparams']['contentdescription'] = 'no template';
              setErrorScreen([
                ...errorScreen,
                {
                  activeModule: prevActiveModule,
                  activeSlide: prevActiveSlide,
                  activeScreen: prevActiveScreen,
                },
              ]);

              templateCheckArray.map(item => {
                if (
                  item.templateKeys === prevActiveModule &&
                  item.templateSlidekeys === prevActiveSlide &&
                  item.templateScreenkeys === prevActiveScreen
                ) {
                  item.isValid = false;
                }
              });
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    } else {
      errorScreen?.some(
        item =>
          item?.activeModule === prevActiveModule &&
          item?.activeSlide === prevActiveSlide &&
          item?.activeScreen === prevActiveScreen &&
          setErrorScreen(removeObjectFromArray(errorScreen, 'activeScreen', prevActiveScreen))
      );
    }
  };

  const templateForLastActiveScreen = async projectDataforAPI => {
    let modules = projectDataforAPI;
    let projectName = modules[projectId]?.ProjectName;
    let activeScreen =
      modules?.[projectId]?.['Modules']?.[ActiveModule]?.['slides']?.[ActiveSlide]?.['Screens']?.[
        ActiveScreen
      ];

    const modifiedScreenData = activeScreen && JSON.parse(JSON.stringify(activeScreen));
    for (const key in modifiedScreenData) {
      // removes fontTag from OS01 object and appends the value to contentdescription
      if (modifiedScreenData[key].Contenttype === 'OST') {
        const { fontTag, ...restParams } = modifiedScreenData[key].Contentparams;
        modifiedScreenData[key].Contentparams = {
          ...restParams,
          contentdescription: restParams.contentdescription + fontTag,
        };
      }
      if (['Image', 'Video', 'Illustration'].includes(modifiedScreenData[key].Contenttype)) {
        const { searchstring, contenttag } = modifiedScreenData[key].Contentparams;
        if (!searchstring && !contenttag) {
          delete modifiedScreenData[key];
        }
      }
    }

    let activeScreenDetails = {
      ScreenName:
        modules[projectId]?.['Modules']?.[ActiveModule]?.['slides']?.[ActiveSlide]?.['Screens']?.[
          ActiveScreen
        ]?.['ScreenName'],
      ...activeScreen,
    };

    let SlideAudioVO =
      modules[projectId]?.['Modules'][ActiveModule]?.['slides']?.[ActiveSlide]?.['SlideAudioVO'] !==
      undefined
        ? modules[projectId]?.['Modules'][ActiveModule]?.['slides'][ActiveSlide]?.['SlideAudioVO']
        : 'Null';

    let screenContent = {
      [projectId]: {
        ProjectName: projectName,
        Modules: {
          [ActiveModule]: {
            ModuleName: modules[projectId]?.['Modules']?.[ActiveModule]?.['ModuleName'],
            slides: {
              [ActiveSlide]: {
                SlideName:
                  modules[projectId]?.['Modules']?.[ActiveModule]?.['slides'][ActiveSlide]?.[
                    'SlideName'
                  ],
                SlideAudioVO: SlideAudioVO,
                Screens: {
                  [ActiveScreen]: activeScreenDetails,
                },
              },
            },
          },
        },
      },
    };
    let templateResponse = await getTemplatesRepo(screenContent, orgId, projectId);
    if (templateResponse.status === 200 && templateResponse.data[0].length) {
      projectDataforAPI[projectId]['Modules'][ActiveModule]['slides'][ActiveSlide]['Screens'][
        ActiveScreen
      ]['TM01']['Contentparams']['contentdescription'] = templateResponse.data[0][0].mediaUrl;
    } else if (templateResponse.status === 200 && !templateResponse.data[0].length) {
      projectDataforAPI[projectId]['Modules'][ActiveModule]['slides'][ActiveSlide]['Screens'][
        ActiveScreen
      ]['TM01']['Contentparams']['contentdescription'] = 'no template';
    }
    return projectDataforAPI;
  };

  //  check if screen is edited or not
  const checkScreenEdit = changedScreens => {
    let cachedData = cacheProjectData;
    let updatedProjectData = JSON.parse(JSON.stringify(project.createdProject));
    let projectData = updatedProjectData[projectId].Modules;
    let OstCountArray = {};
    let slideChanges = [];
    Object.entries(projectData).map((moduleData, moduleIndex) => {
      let mData = moduleData[1];
      let slides = mData.slides;
      Object.entries(slides).map((slideData, moduleIndex) => {
        let slide = slideData[1];
        let screens = slide.Screens;
        Object.entries(screens).map((screenData, moduleIndex) => {
          let _arr = [];
          let slide = screenData[1];
          let ostCountvalidate1 = [];
          let imageCountValidateLocal = [];
          let illuCountValidateLocal = [];
          let videoCountValidateLocal = [];
          let screenEntries = Object.entries(slide);
          screenEntries.sort((a, b) => {
            const isTa = /^TM/.test(a[0]);
            const isTb = /^TM/.test(b[0]);

            if (isTa && !isTb) {
              return 1;
            }
            if (isTb && !isTa) {
              return -1;
            }
            return 0;
          });
          let oldScreendata = undefined;
          if (cachedData[projectId].Modules !== undefined) {
            if (cachedData[projectId].Modules[moduleData[0]] !== undefined) {
              if (cachedData[projectId].Modules[moduleData[0]].slides[slideData[0]] !== undefined) {
                oldScreendata =
                  cachedData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[
                    screenData[0]
                  ];
              }
            }
          }
          if (cachedData[projectId].Modules !== undefined) {
            if (cachedData[projectId].Modules[moduleData[0]] !== undefined) {
              if (cachedData[projectId].Modules[moduleData[0]].slides[slideData[0]] !== undefined) {
                if (
                  cachedData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[
                    screenData[0]
                  ] !== undefined
                ) {
                  if (
                    cachedData[projectId].Modules[moduleData[0]].ModuleName !==
                    moduleData[1].ModuleName
                  ) {
                    _arr.push(1);
                  }
                  if (
                    cachedData[projectId].Modules[moduleData[0]].slides[slideData[0]].SlideName !==
                    moduleData[1].slides[slideData[0]].SlideName
                  ) {
                    _arr.push(1);
                  }
                  if (
                    cachedData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[
                      screenData[0]
                    ].ScreenName !==
                    moduleData[1].slides[slideData[0]].Screens[screenData[0]].ScreenName
                  ) {
                    _arr.push(1);
                  }
                }
              }
            }
          }
          if (oldScreendata === undefined) {
            _arr.push(1);
          }
          screenEntries.map((item, key) => {
            item.map((element, index) => {
              if (element.Contenttype === 'OST') {
                if (element['Contentparams']['fontTag'] === HEADERFONT) {
                  OstCountArray = {
                    ostHeading: element,
                  };
                }

                if (element['Contentparams']['FontColour'].length === 8) {
                  element['Contentparams']['FontColour'] =
                    element['Contentparams']['FontColour'].split('*')[0];
                }
                if (element['Contentparams']['contentdescription'].includes('[H]')) {
                  return element['Contentparams']['contentdescription'].split('[H]')[0];
                }

                if (element['Contentparams']['contentdescription'].includes('[P]')) {
                  return element['Contentparams']['contentdescription'].split('[P]')[0];
                }
                if (element['Contentparams']['contentdescription'].includes('[B]')) {
                  return element['Contentparams']['contentdescription'].split('[B]')[0];
                }

                if (
                  element['Contentparams']['fontTag'] === HEADERFONT ||
                  element['Contentparams']['fontTag'] === undefined
                ) {
                  element['Contentparams']['contentdescription'] =
                    element['Contentparams']['contentdescription'] + '[H]';
                } else if (element['Contentparams']['fontTag'] === PARAGRAPHFONT) {
                  element['Contentparams']['contentdescription'] =
                    element['Contentparams']['contentdescription'] + '[P]';
                } else if (element['Contentparams']['fontTag'] === BULLETFONT) {
                  element['Contentparams']['contentdescription'] =
                    element['Contentparams']['contentdescription'] + '[B]';
                }
                element['Contentparams']['sequenceid'] = countPlusOne();
                if (element['Contentparams']['fontTag'] !== '') {
                  delete element['Contentparams']['fontTag'];
                }
                if (oldScreendata) {
                  if (oldScreendata[item[0]]) {
                    if (
                      element['Contentparams']['contentdescription'] !==
                      oldScreendata[item[0]].Contentparams.contentdescription
                    ) {
                      _arr.push(1);

                      let data = [];
                      data.push(
                        moduleData[0] + '_' + slideData[0] + '_' + screenData[0] + '_' + item[0]
                      );
                      let projectList1 = [...ostCountValidate, ...data];
                      ostCountvalidate1 = projectList1;
                    } else {
                      ostCountvalidate1 = ostCountValidate;
                    }
                    if (
                      element['Contentparams']['FontColour'] !==
                      oldScreendata[item[0]].Contentparams.FontColour
                    ) {
                      _arr.push(1);
                    }
                    if (
                      element['Contentparams']['FontFamily'] !==
                      oldScreendata[item[0]].Contentparams.FontFamily
                    ) {
                      _arr.push(1);
                    }
                    if (
                      element['Contentparams']['FontSizeH'] !==
                      oldScreendata[item[0]].Contentparams.FontSizeH
                    ) {
                      _arr.push(1);
                    }
                    if (
                      element['Contentparams']['FontSizeP'] !==
                      oldScreendata[item[0]].Contentparams.FontSizeP
                    ) {
                      _arr.push(1);
                    }
                  }
                }
                if (element['Contentparams']['contenttag'] === '') {
                  element['Contentparams']['contenttag'] = 'Null';
                  return element;
                }
                return element;
              }
              if (element.Contenttype === 'Background') {
                element['Contentparams']['sequenceid'] = countPlusOne();
                return element;
              }
              if (element.Contenttype === 'AudioScript') {
                element['Contentparams']['sequenceid'] = countPlusOne();

                if (oldScreendata) {
                  if (oldScreendata[item[0]]) {
                    if (
                      element['Contentparams']['contentdescription'] !==
                      oldScreendata[item[0]].Contentparams.contentdescription
                    ) {
                      _arr.push(1);
                    }
                  }
                }
                if (element['Contentparams']['contenttag'] === '') {
                  element['Contentparams']['contenttag'] = 'Null';
                  return element;
                }
                return element;
              }
              if (element.Contenttype === 'Image') {
                if (element['Contentparams']['searchstring']) {
                  element['Contentparams']['searchstring'] =
                    element['Contentparams']['searchstring'].trim();
                }
                if (element['Contentparams']['contenttag']) {
                  element['Contentparams']['contenttag'] =
                    element['Contentparams']['contenttag'].trim();
                }

                if (oldScreendata) {
                  if (oldScreendata[item[0]] !== undefined) {
                    if (
                      element['Contentparams']['searchstring'] !==
                        oldScreendata[item[0]].Contentparams.searchstring ||
                      element['Contentparams']['contenttag'] !==
                        oldScreendata[item[0]].Contentparams.contenttag
                    ) {
                      element['Contentparams']['contentdescription'] = '';
                    }
                  }
                }
                if (
                  element['Contentparams']['contenttag'] === '' &&
                  element['Contentparams']['searchstring'] === ''
                ) {
                  let _arrimg = [];

                  imageCountValidate.map((imgData, i) => {
                    if (
                      moduleData[0] + '_' + slideData[0] + '_' + screenData[0] + '_' + item[0] ===
                      imgData
                    ) {
                      if (imgData !== undefined && imgData !== null && imgData !== '') {
                        _arrimg = { ...imageCountValidate };
                        _arrimg.splice(1, i);
                      }
                    }
                  });

                  imageCountValidateLocal = _arrimg;
                  delete slide[item[0]];
                } else {
                  imageCountValidateLocal = imageCountValidate;
                  element['Contentparams']['sequenceid'] = countPlusOne();
                }
                if (
                  element['Contentparams']['contenttag'] === '' ||
                  element['Contentparams']['contenttag'] === []
                ) {
                  element['Contentparams']['contenttag'] = 'Null';
                }
                if (oldScreendata) {
                  if (oldScreendata[item[0]] != undefined) {
                    if (
                      element['Contentparams']['searchstring'] !==
                        oldScreendata[item[0]].Contentparams.searchstring ||
                      element['Contentparams']['contenttag'] !==
                        oldScreendata[item[0]].Contentparams.contenttag ||
                      (element['Contentparams']['contentdescription'] &&
                        oldScreendata[item[0]].Contentparams.contentdescription &&
                        element['Contentparams']['contentdescription'] !==
                          oldScreendata[item[0]].Contentparams.contentdescription)
                    ) {
                      _arr.push(1);
                    }
                  } else if (
                    element['Contentparams']['searchstring'] ||
                    element['Contentparams']['contenttag'] !== 'Null'
                  ) {
                    _arr.push(1);
                  }
                }
                return element;
              }
              if (element.Contenttype === 'Video') {
                if (element['Contentparams']['searchstring']) {
                  element['Contentparams']['searchstring'] =
                    element['Contentparams']['searchstring'].trim();
                }
                if (element['Contentparams']['contenttag']) {
                  element['Contentparams']['contenttag'] =
                    element['Contentparams']['contenttag'].trim();
                }

                if (oldScreendata) {
                  if (oldScreendata[item[0]] != undefined) {
                    if (
                      element['Contentparams']['searchstring'] !==
                        oldScreendata[item[0]].Contentparams.searchstring ||
                      element['Contentparams']['contenttag'] !==
                        oldScreendata[item[0]].Contentparams.contenttag ||
                      element['Contentparams']['contentdescription'] !=
                        oldScreendata[item[0]].Contentparams.contentdescription
                    ) {
                      element['Contentparams']['contentdescription'] = '';
                    }
                  }
                }
                if (
                  element['Contentparams']['contenttag'] === '' &&
                  element['Contentparams']['searchstring'] === ''
                ) {
                  let _arrvideo = [];
                  videoCountValidate.map((videoData, i) => {
                    if (
                      moduleData[0] + '_' + slideData[0] + '_' + screenData[0] + '_' + item[0] ===
                      videoData
                    ) {
                      if (videoData !== undefined && videoData !== null && videoData !== '') {
                        _arrvideo = videoCountValidate;
                        _arrvideo.splice(1, i);
                      }
                    }
                  });
                  // setVideoCountValidate(_arrvideo)
                  videoCountValidateLocal = _arrvideo;

                  delete slide[item[0]];
                } else {
                  videoCountValidateLocal = videoCountValidate;
                  element['Contentparams']['sequenceid'] = countPlusOne();
                }
                if (
                  element['Contentparams']['contenttag'] === '' ||
                  element['Contentparams']['contenttag'] === []
                ) {
                  element['Contentparams']['contenttag'] = 'Null';
                }
                if (oldScreendata) {
                  if (oldScreendata[item[0]] != undefined) {
                    if (
                      element['Contentparams']['searchstring'] !==
                        oldScreendata[item[0]].Contentparams.searchstring ||
                      element['Contentparams']['contenttag'] !==
                        oldScreendata[item[0]].Contentparams.contenttag ||
                      (element['Contentparams']['contentdescription'] &&
                        oldScreendata[item[0]].Contentparams.contentdescription &&
                        element['Contentparams']['contentdescription'] !==
                          oldScreendata[item[0]].Contentparams.contentdescription)
                    ) {
                      _arr.push(1);
                    }
                  } else if (
                    element['Contentparams']['searchstring'] ||
                    element['Contentparams']['contenttag'] !== 'Null'
                  ) {
                    _arr.push(1);
                  }
                }
                return element;
              }
              if (element.Contenttype === 'Illustration') {
                if (element['Contentparams']['searchstring']) {
                  element['Contentparams']['searchstring'] =
                    element['Contentparams']['searchstring'].trim();
                }
                if (element['Contentparams']['contenttag']) {
                  element['Contentparams']['contenttag'] =
                    element['Contentparams']['contenttag'].trim();
                }

                if (oldScreendata) {
                  if (oldScreendata[item[0]] != undefined) {
                    if (
                      element['Contentparams']['searchstring'] !==
                        oldScreendata[item[0]].Contentparams.searchstring ||
                      element['Contentparams']['contenttag'] !==
                        oldScreendata[item[0]].Contentparams.contenttag
                    ) {
                      element['Contentparams']['contentdescription'] = '';
                    }
                  }
                }
                //element["Contentparams"]["contentdescription"] = "";
                if (
                  element['Contentparams']['contenttag'] === '' &&
                  element['Contentparams']['searchstring'] === ''
                ) {
                  let _arrIllu = [];

                  illuCountValidate.map((illuData, i) => {
                    if (
                      moduleData[0] + '_' + slideData[0] + '_' + screenData[0] + '_' + item[0] ===
                      illuData
                    ) {
                      if (illuData !== undefined && illuData !== null && illuData !== '') {
                        _arrIllu = illuCountValidate;
                        _arrIllu.splice(1, i);
                      }
                    }
                  });
                  // setIlluCountValidate(_arrIllu)
                  illuCountValidateLocal = _arrIllu;
                  delete slide[item[0]];
                  // return element;
                } else {
                  illuCountValidateLocal = illuCountValidate;
                  element['Contentparams']['sequenceid'] = countPlusOne();
                }

                if (
                  element['Contentparams']['contenttag'] === '' ||
                  element['Contentparams']['contenttag'] === []
                ) {
                  element['Contentparams']['contenttag'] = 'Null';
                  // return element;
                }
                if (oldScreendata) {
                  if (oldScreendata[item[0]] !== undefined) {
                    if (
                      element['Contentparams']['searchstring'] !==
                        oldScreendata[item[0]].Contentparams.searchstring ||
                      element['Contentparams']['contenttag'] !==
                        oldScreendata[item[0]].Contentparams.contenttag ||
                      (element['Contentparams']['contentdescription'] &&
                        oldScreendata[item[0]].Contentparams.contentdescription &&
                        element['Contentparams']['contentdescription'] !==
                          oldScreendata[item[0]].Contentparams.contentdescription)
                    ) {
                      _arr.push(1);
                    }
                  } else if (
                    element['Contentparams']['searchstring'] ||
                    element['Contentparams']['contenttag'] !== 'Null'
                  ) {
                    _arr.push(1);
                  }
                }
                return element;
              }
              if (element.Contenttype === 'TemplateID') {
                if (_arr.length > 0) {
                  if (templateSelectionType.manualTemplate) {
                    element['Contentparams']['contentdescription'] = 'no template';
                  }
                }

                ostCountvalidate1.map(ostData => {
                  let ostValidateData = ostData.slice(0, -5);
                  if (
                    moduleData[0] + '_' + slideData[0] + '_' + screenData[0] ===
                    ostValidateData
                  ) {
                    if (ostData !== undefined && ostData !== null && ostData !== '') {
                      if (templateSelectionType.manualTemplate) {
                        element['Contentparams']['contentdescription'] = 'no template';
                      }

                      return element;
                    }
                  }
                });
                imageCountValidateLocal.map(imgData => {
                  let imgValidateData = imgData.slice(0, -5);
                  if (
                    moduleData[0] + '_' + slideData[0] + '_' + screenData[0] ===
                    imgValidateData
                  ) {
                    if (imgData !== undefined && imgData !== null && imgData !== '') {
                      if (templateSelectionType.manualTemplate) {
                        element['Contentparams']['contentdescription'] = 'no template';
                      }
                      return element;
                    }
                  }
                });
                illuCountValidateLocal.map(iluData => {
                  let illuValidateData = iluData.slice(0, -5);
                  if (
                    moduleData[0] + '_' + slideData[0] + '_' + screenData[0] ===
                    illuValidateData
                  ) {
                    if (iluData !== undefined && iluData !== null && iluData !== '') {
                      if (templateSelectionType.manualTemplate) {
                        element['Contentparams']['contentdescription'] = 'no template';
                      }
                      return element;
                    }
                  }
                });
                videoCountValidateLocal.map(videoData => {
                  let videoValidateData = videoData.slice(0, -5);
                  if (
                    moduleData[0] + '_' + slideData[0] + '_' + screenData[0] ===
                    videoValidateData
                  ) {
                    if (videoData !== undefined && videoData !== null && videoData !== '') {
                      if (templateSelectionType.manualTemplate) {
                        element['Contentparams']['contentdescription'] = 'no template';
                      }
                      return element;
                    }
                  }
                });
                element['Contentparams']['sequenceid'] = countPlusOne();
                if (element['Contentparams']['useTemplateStatus'] !== '') {
                  delete element['Contentparams']['useTemplateStatus'];
                  return element;
                }
                return element;
              }
              if (element.Contenttype === 'ColourScheme') {
                element['Contentparams']['sequenceid'] = countPlusOne();
                if (element['Contentparams']['contentdescription'].length === 8) {
                  element['Contentparams']['contentdescription'] =
                    element['Contentparams']['contentdescription'].split('*')[0];
                }
                if (oldScreendata) {
                  if (oldScreendata[item[0]] !== undefined) {
                    if (
                      element['Contentparams']['contentdescription'] !==
                      oldScreendata[item[0]].Contentparams.contentdescription
                    ) {
                      _arr.push(1);
                    }
                  }
                }
                return element;
              }
              if (element.Contenttype === 'ScreenDuration') {
                element['Contentparams']['sequenceid'] = countPlusOne();
                if (element['Contentparams']['contenttag'] === '') {
                  element['Contentparams']['contenttag'] = 'Null';
                  // return element;
                }
                if (element['Contentparams']['contentdescription'] === '') {
                  element['Contentparams']['contentdescription'] = '7';
                  // return element;
                }
                if (oldScreendata) {
                  if (oldScreendata[item[0]] != undefined) {
                    if (
                      element['Contentparams']['contentdescription'] !==
                      oldScreendata[item[0]].Contentparams.contentdescription
                    ) {
                      _arr.push(1);
                    }
                  }
                }
                return element;
              }
              return element;
            });
            return item;
          });
          if (
            oldScreendata &&
            Object.keys(oldScreendata).toString() !==
              Object.keys(moduleData[1].slides[slideData[0]].Screens[screenData[0]]).toString()
          ) {
            _arr.push(1);
          }
          if (_arr.length > 0) {
            changedScreens &&
              changedScreens.push({
                moduleId: moduleData[0],
                slideId: slideData[0],
                screenId: screenData[0],
              });
            if (slide['mediaurl']) {
              delete projectData[moduleData[0]].slides[slideData[0]].Screens[screenData[0]][
                'mediaurl'
              ];
            }
          }
          if (_arr.length > 0) {
            slideChanges.push(moduleData[0] + '-' + slideData[0]);
            setSlidesChangesState(slideChanges);
          }
          return screenData;
        });
        slideData[1].SlideRender = '1';
        return slideData;
      });

      return moduleData;
    });
    return { updatedProjectData, slideChanges };
  };

  const templateValidateFunction = (projectData1, projectData) => {
    let templateValidArray = [];

    Object.entries(projectData1[projectId].Modules).map(moduleData => {
      let mData = moduleData[1];
      let slides = mData.slides;
      Object.entries(slides).map(slideData => {
        let slide = slideData[1];
        let screens = slide.Screens;
        Object.entries(screens).map(screenData => {
          let slide = screenData[1];
          Object.entries(slide).map(item => {
            let activeTemplate = item.filter(function (data) {
              return data.Contenttype === 'TemplateID';
            });
            let isValids = false;
            if (activeTemplate.length > 0) {
              if (activeTemplate[0].Contentparams.contentdescription !== '') {
                isValids = true;
              } else {
                isValids = false;
              }
              if (activeTemplate[0].Contentparams.contentdescription !== 'no template') {
                isValids = true;
              } else {
                isValids = false;
              }

              let templateValid = {
                isValid: isValids,
                templateKeys: moduleData[0],
                templateSlidekeys: slideData[0],
                templateScreenkeys: screenData[0],
                screenName:
                  moduleData[1].ModuleName +
                  '-' +
                  slideData[1].SlideName +
                  '-' +
                  screenData[1].ScreenName,
              };

              let hArray = 0;
              let pArray = 0;
              let bArray = 0;
              let imgArray = [];
              let videoArray = [];
              let illuArray = [];
              Object.entries(slide).map((screen, key) => {
                if (screen[1].Contenttype === 'OST') {
                  if (screen[1].Contentparams.contentdescription.slice(-3) === '[H]') {
                    hArray++;
                  } else if (screen[1].Contentparams.contentdescription.slice(-3) === '[P]') {
                    pArray++;
                  } else if (screen[1].Contentparams.contentdescription.slice(-3) === '[B]') {
                    bArray++;
                  }
                }
                if (screen[1].Contenttype === 'Image') {
                  imgArray.push(screen[0]);
                }
                if (screen[1].Contenttype === 'Video') {
                  videoArray.push(screen[0]);
                }
                if (screen[1].Contenttype === 'Illustration') {
                  illuArray.push(screen[0]);
                }
              });

              templateValid['HeadingCount'] = hArray + '-H OST,';
              templateValid['ParagraphCount'] = pArray + '-P OST,';
              templateValid['BulletCount'] = bArray + '-B OST,';
              templateValid['ImageCount'] = imgArray.length + '-Images,';
              templateValid['VideoCount'] = videoArray.length + '-Videos';
              templateValid['IllustrationCount'] = illuArray.length + '-Illustration,';

              // }

              if (activeTemplate[0].Contentparams.contentdescription === '') {
                projectData[projectId].Modules[moduleData[0]].slides[slideData[0]].SlideRender =
                  '0';
              } else {
                projectData[projectId].Modules[moduleData[0]].slides[slideData[0]].SlideRender =
                  '1';
              }
              templateValidArray.push(templateValid);
            }
          });
        });
      });
    });
    settemplateCheckArray(templateValidArray);
  };

  const saveProjects = projectDataReq => {
    createProjects(JSON.stringify(projectDataReq)).then(response => {
      try {
        if (
          response !== '' &&
          response !== undefined &&
          response !== null &&
          response.data !== '' &&
          response.data !== undefined &&
          response.data !== null
        ) {
          if (response.status === 200 && response.status !== null && response.status !== null) {
            setProjectLoaded(true);
            let filter = {
              project_id: projectId,
            };
            getProjectID(filter).then(async response => {
              try {
                if (
                  response !== '' &&
                  response !== undefined &&
                  response !== null &&
                  response.status !== '' &&
                  response.status !== undefined &&
                  response.status !== null &&
                  response.status === 200 &&
                  response.data !== '' &&
                  response.data !== undefined &&
                  response.data !== null
                ) {
                  let projectDatafromDb = await getProjectById(projectId);
                  let projectData = '';
                  if (projectDatafromDb.data.data.length > 0) {
                    setcacheProjectData(JSON.parse(projectDatafromDb.data.data[0].ProjectData));
                    projectData = JSON.parse(projectDatafromDb.data.data[0].ProjectData);
                  }
                  let projectData1 = JSON.parse(response.data[0].ProjectData);

                  let res = await CreateProjectFromResponse(projectDatafromDb.data.data[0]);
                  EditProject(res);
                  templateValidateFunction(projectData1, projectData);
                  setAudioScriptValidation([]);
                  setOstValidation([]);
                  setvalidatingTempLoader(false);

                  if (templateSelectionType.manualTemplate === true) {
                    GotoProject();
                  } else {
                    setopenTemplateChangesModal(true);
                  }
                }
              } catch (error) {
                setProjectLoaded(false);
              }
            });

            UpdateEditMode(false);
          } else {
            setProjectLoaded(false);
            showToaster(response.message, 'warning');
          }
        } else {
          setProjectLoaded(false);
          showToaster('Something went wrong. Please Try Again', 'warning');
        }
      } catch (error) {
        setProjectLoaded(false);
        showToaster('Something went wrong. Please Try Again', 'warning');
      }
    });
  };

  const submiModuleDetails = async () => {
    let audioScriptValida = [];
    let ostValidation = [];

    try {
      EmptyPreview();
      const previousKeys = {
        module: null,
        slide: null,
        screen: null,
      };
      let projectData = project.createdProject[projectId].Modules;
      let priviousItems = null;
      let name;
      let screenDataError = false;
      for (const [moduleKey, modules] of Object.entries(projectData)) {
        let mData = modules.slides;
        if (screenDataError) break;
        for (const [slideKey, slide] of Object.entries(mData)) {
          let sData = slide.Screens;
          if (screenDataError) break;
          for (const [screenKey, screen] of Object.entries(sData)) {
            let items = {
              video: 0,
              images: 0,
              illustrations: 0,
            };

            for (const [key, item] of Object.entries(screen)) {
              if (
                item.Contenttype === 'Image' &&
                (item.Contentparams.contenttag !== '' ||
                  item.Contentparams.contenttag !== 'Null') &&
                item.Contentparams.searchstring !== ''
              ) {
                items.images++;
              }
              if (
                item.Contenttype === 'Video' &&
                (item.Contentparams.contenttag !== '' ||
                  item.Contentparams.contenttag !== 'Null') &&
                item.Contentparams.searchstring !== ''
              ) {
                items.video++;
              }
              if (
                item.Contenttype === 'Illustration' &&
                (item.Contentparams.contenttag !== '' ||
                  item.Contentparams.contenttag !== 'Null') &&
                item.Contentparams.searchstring !== ''
              ) {
                items.illustrations++;
              }

              if (priviousItems === null) {
                priviousItems = { ...items };
                continue;
              }
            }
            let temItem = screen['TM01'];
            if (temItem.Contentparams.useTemplateStatus === true) {
              if (priviousItems.images !== items.images) {
                let message =
                  modules.ModuleName +
                  '-' +
                  slide.SlideName +
                  '-' +
                  screen.ScreenName +
                  ' image is not matched to previous screen';
                Swal.fire({
                  html: message,
                  icon: 'warning',
                  confirmButtonText: 'OK',
                  confirmButtonColor: '#d33',
                  focusConfirm: false,
                }).then(result => {
                  if (result.isConfirmed) {
                  }
                });
                screenDataError = true;
                break;
              } else if (priviousItems.video !== items.video) {
                let message =
                  modules.ModuleName +
                  '-' +
                  slide.SlideName +
                  '-' +
                  screen.ScreenName +
                  ' video is not matched to previous screen';
                Swal.fire({
                  html: message,
                  icon: 'warning',
                  confirmButtonText: 'OK',
                  confirmButtonColor: '#d33',
                  focusConfirm: false,
                }).then(result => {
                  if (result.isConfirmed) {
                  }
                });
                screenDataError = true;
                break;
              } else if (priviousItems.illustrations !== items.illustrations) {
                let message =
                  modules.ModuleName +
                  '-' +
                  slide.SlideName +
                  '-' +
                  screen.ScreenName +
                  ' illustrations is not matched to previous screen';
                Swal.fire({
                  html: message,
                  icon: 'warning',
                  confirmButtonText: 'OK',
                  confirmButtonColor: '#d33',
                  focusConfirm: false,
                }).then(result => {
                  if (result.isConfirmed) {
                  }
                });
                screenDataError = true;
                break;
              } else if (priviousItems.ost !== items.ost) {
                let message =
                  modules.ModuleName +
                  '-' +
                  slide.SlideName +
                  '-' +
                  screen.ScreenName +
                  ' ost is not matched to previous screen';
                Swal.fire({
                  html: message,
                  icon: 'warning',
                  confirmButtonText: 'OK',
                  confirmButtonColor: '#d33',
                  focusConfirm: false,
                }).then(result => {
                  if (result.isConfirmed) {
                  }
                });
                screenDataError = true;
                break;
              } else if (priviousItems.ostB !== items.ostB) {
                let message =
                  modules.ModuleName +
                  '-' +
                  slide.SlideName +
                  '-' +
                  screen.ScreenName +
                  ' ost bullet is not matched to previous screen';
                Swal.fire({
                  html: message,
                  icon: 'warning',
                  confirmButtonText: 'OK',
                  confirmButtonColor: '#d33',
                  focusConfirm: false,
                }).then(result => {
                  if (result.isConfirmed) {
                  }
                });
                screenDataError = true;
                break;
              } else if (priviousItems.ostH !== items.ostH) {
                let message =
                  modules.ModuleName +
                  '-' +
                  slide.SlideName +
                  '-' +
                  screen.ScreenName +
                  ' ost heading is not matched to previous screen';
                Swal.fire({
                  html: message,
                  icon: 'warning',
                  confirmButtonText: 'OK',
                  confirmButtonColor: '#d33',
                  focusConfirm: false,
                }).then(result => {
                  if (result.isConfirmed) {
                  }
                });
                screenDataError = true;
                break;
              } else if (priviousItems.ostP !== items.ostP) {
                let message =
                  modules.ModuleName +
                  '-' +
                  slide.SlideName +
                  '-' +
                  screen.ScreenName +
                  ' ost paragraph is not matched to previous screen';
                Swal.fire({
                  html: message,
                  icon: 'warning',
                  confirmButtonText: 'OK',
                  confirmButtonColor: '#d33',
                  focusConfirm: false,
                }).then(result => {
                  if (result.isConfirmed) {
                  }
                });
                screenDataError = true;
                break;
              }
              name =
                previousKeys.module +
                '_' +
                previousKeys.slide +
                '_' +
                previousKeys.screen +
                '_TM01';
              temItem.Contentparams.sourcescreen = name;
            }

            previousKeys.module = moduleKey;
            previousKeys.slide = slideKey;

            previousKeys.screen = screenKey;
            priviousItems = { ...items };

            previousKeys.screen = screenKey;
          }
          previousKeys.slide = slideKey;
        }
        previousKeys.module = moduleKey;
      }
      Object.entries(projectData).map((moduleData, moduleIndex) => {
        let mData = moduleData[1];
        let slides = mData.slides;
        Object.entries(slides).map((slideData, moduleIndex) => {
          let slide = slideData[1];
          let screens = slide.Screens;
          Object.entries(screens).map((screenData, moduleIndex) => {
            let slide = screenData[1];
            let screenEntries = Object.entries(slide);
            screenEntries.sort((a, b) => {
              const isTa = /^TM/.test(a[0]);
              const isTb = /^TM/.test(b[0]);

              if (isTa && !isTb) {
                return 1;
              }
              if (isTb && !isTa) {
                return -1;
              }
              return 0;
            });
            screenEntries.map((item, key) => {
              item.map((element, index) => {
                if (element.Contenttype === 'OST') {
                  if (element['Contentparams']['contentdescription'] === '') {
                    ostValidation.push(
                      moduleData[1].ModuleName +
                        '-' +
                        slideData[1].SlideName +
                        '-' +
                        screenData[1].ScreenName +
                        '-' +
                        item[0]
                    );
                  }
                }
                if (element.Contenttype === 'AudioScript') {
                  if (element['Contentparams']['contentdescription'] === '') {
                    audioScriptValida.push(1);
                  }
                }
              });
            });
          });
        });
      });

      setAudioScriptValidation(audioScriptValida);
      setOstValidation(ostValidation);
      compareActiveAudioOst(ActiveModule, ActiveSlide, ActiveScreen);
      if (!screenDataError) {
        if (audioScriptValida.length === 0 && ostValidation.length === 0) {
          let { updatedProjectData, slideChanges } = checkScreenEdit();
          let projectDatafromDb = await getProjectById(projectId);
          let renderUrls = '';
          let urlArray;
          if (projectDatafromDb.data.data.length > 0) {
            if (
              projectDatafromDb.data.data[0].renderUrls !== undefined &&
              projectDatafromDb.data.data[0].renderUrls !== null &&
              projectDatafromDb.data.data[0].renderUrls !== ''
            ) {
              renderUrls = projectDatafromDb.data.data[0].renderUrls;
              urlArray = projectDatafromDb.data.data[0].renderUrls;
              if (urlArray !== null && urlArray !== undefined) {
                Object.keys(urlArray).map(function (key, index) {
                  Object.keys(urlArray[key]).map(function (k, i) {
                    slideChanges.map(slidechange => {
                      if (slidechange === key + '-' + k) {
                        delete urlArray[key][k];
                      }
                      return urlArray;
                    });
                  });
                });
              }
            }
          }
          setProjectLoaded(false);
          setvalidatingTempLoader(true);
          if (!templateSelectionType?.manualTemplate) {
            updatedProjectData = await templateForLastActiveScreen(updatedProjectData);
          }
          let updatedProjectDataforDb = JSON.stringify(updatedProjectData);
          let projectDataforAPI = await removeMediaUrlFilter(
            JSON.parse(updatedProjectDataforDb),
            projectId
          );
          let projectDataReq = {
            dbdata: {
              [projectId]: updatedProjectData[projectId],
            },
            APIData: {
              [projectId]: projectDataforAPI[projectId],
            },
            RenderUrl: {
              renderUrls: urlArray,
            },
            type: 'Update',
            orgId: AdminRole == 0 ? userId : userParentId,
          };
          saveProjects(projectDataReq);
        }
      }
    } catch (e) {
      setProjectLoaded(false);
      console.log(e);
      showToaster('So mething went wrong. Please Try Again', 'warning');
    }
  };
  const generatePreview = async () => {
    let audioScriptValida = [];
    let ostValidation = [];
    let changedScreens = [];

    try {
      EmptyPreview();
      const previousKeys = {
        module: null,
        slide: null,
        screen: null,
      };
      let projectData = project.createdProject[projectId].Modules;
      let priviousItems = null;
      let screenDataError = false;
      for (const [moduleKey, modules] of Object.entries(projectData)) {
        let mData = modules.slides;
        if (screenDataError) break;
        for (const [slideKey, slide] of Object.entries(mData)) {
          let sData = slide.Screens;
          if (screenDataError) break;
          for (const [screenKey, screen] of Object.entries(sData)) {
            let items = {
              video: 0,
              images: 0,
              illustrations: 0,
            };

            for (const [key, item] of Object.entries(screen)) {
              if (
                item.Contenttype === 'Image' &&
                (item.Contentparams.contenttag !== '' ||
                  item.Contentparams.contenttag !== 'Null') &&
                item.Contentparams.searchstring !== ''
              ) {
                items.images++;
              }
              if (
                item.Contenttype === 'Video' &&
                (item.Contentparams.contenttag !== '' ||
                  item.Contentparams.contenttag !== 'Null') &&
                item.Contentparams.searchstring !== ''
              ) {
                items.video++;
              }
              if (
                item.Contenttype === 'Illustration' &&
                (item.Contentparams.contenttag !== '' ||
                  item.Contentparams.contenttag !== 'Null') &&
                item.Contentparams.searchstring !== ''
              ) {
                items.illustrations++;
              }

              if (priviousItems === null) {
                priviousItems = { ...items };
                continue;
              }
            }

            previousKeys.module = moduleKey;
            previousKeys.slide = slideKey;

            previousKeys.screen = screenKey;
            priviousItems = { ...items };

            previousKeys.screen = screenKey;
          }
          previousKeys.slide = slideKey;
        }
        previousKeys.module = moduleKey;
      }
      Object.entries(projectData).map(moduleData => {
        let mData = moduleData[1];
        let slides = mData.slides;
        Object.entries(slides).map(slideData => {
          let slide = slideData[1];
          let screens = slide.Screens;
          Object.entries(screens).map(screenData => {
            let slide = screenData[1];
            let screenEntries = Object.entries(slide);
            screenEntries.sort((a, b) => {
              const isTa = /^TM/.test(a[0]);
              const isTb = /^TM/.test(b[0]);
              if (isTa && !isTb) {
                return 1;
              }
              if (isTb && !isTa) {
                return -1;
              }
              return 0;
            });
            screenEntries.map((item, _) => {
              item.map((element, _) => {
                if (element.Contenttype === 'OST') {
                  if (element['Contentparams']['contentdescription'] === '') {
                    ostValidation.push(
                      moduleData[1].ModuleName +
                        '-' +
                        slideData[1].SlideName +
                        '-' +
                        screenData[1].ScreenName +
                        '-' +
                        item[0]
                    );
                  }
                }
                if (element.Contenttype === 'AudioScript') {
                  if (element['Contentparams']['contentdescription'] === '') {
                    audioScriptValida.push(1);
                  }
                }
              });
            });
          });
        });
      });

      setAudioScriptValidation(audioScriptValida);
      setOstValidation(ostValidation);
      compareActiveAudioOst(ActiveModule, ActiveSlide, ActiveScreen);
      // if (!screenDataError) {
      if (audioScriptValida.length === 0 && ostValidation.length === 0) {
        let { updatedProjectData, slideChanges } = checkScreenEdit(changedScreens);
        let projectDatafromDb = await getProjectById(projectId);
        let renderUrls = '';
        let urlArray;
        if (projectDatafromDb.data.data.length > 0) {
          if (projectDatafromDb.data.data[0].renderUrls) {
            renderUrls = projectDatafromDb.data.data[0].renderUrls;
            urlArray = projectDatafromDb.data.data[0].renderUrls;
            if (urlArray) {
              Object.keys(urlArray).map(function (key, _) {
                Object.keys(urlArray[key]).map(function (k, ivalid) {
                  slideChanges.map(slidechange => {
                    if (slidechange === key + '-' + k) {
                      delete urlArray[key][k];
                    }
                    return urlArray;
                  });
                });
              });
            }
          }
        }
        if (!templateSelectionType?.manualTemplate) {
          updatedProjectData = await templateForLastActiveScreen(updatedProjectData);
        }
        let updatedProjectDataforDb = JSON.stringify(updatedProjectData);
        let projectDataforAPI = await removeMediaUrlFilter(
          JSON.parse(updatedProjectDataforDb),
          projectId
        );
        let projectDataReq = {
          dbdata: {
            [projectId]: updatedProjectData[projectId],
          },
          APIData: {
            [projectId]: projectDataforAPI[projectId],
          },
          RenderUrl: {
            renderUrls: urlArray,
          },
          type: 'Update',
          orgId: AdminRole == 0 ? userId : userParentId,
        };
        createProjects(JSON.stringify(projectDataReq)).then(response => {
          try {
            if (
              response !== '' &&
              response !== undefined &&
              response !== null &&
              response.data !== '' &&
              response.data !== undefined &&
              response.data !== null
            ) {
              if (response.status === 200 && response.status !== null && response.status !== null) {
                setProjectLoaded(true);
                let filter = {
                  project_id: projectId,
                };
                getProjectID(filter).then(async response => {
                  try {
                    if (response && response.status === 200 && response.data) {
                      let projectDatafromDb = await getProjectById(projectId);
                      let projectData = '';
                      if (projectDatafromDb.data.data.length > 0) {
                        setcacheProjectData(JSON.parse(projectDatafromDb.data.data[0].ProjectData));
                        projectData = JSON.parse(projectDatafromDb.data.data[0].ProjectData);
                      }
                      for (const changedScreen of changedScreens) {
                        let modules = updatedProjectData;
                        let activeScreen =
                          modules?.[projectId]?.['Modules']?.[changedScreen.moduleId]?.['slides']?.[
                            changedScreen?.slideId
                          ]?.['Screens']?.[changedScreen?.screenId];
                        let activeScreenDetails = {
                          ScreenName:
                            modules[projectId]?.['Modules']?.[changedScreen.moduleId]?.['slides']?.[
                              changedScreen?.slideId
                            ]?.['Screens']?.[changedScreen?.screenId]?.['ScreenName'],
                          ...activeScreen,
                        };
                        let SlideAudioVO =
                          modules[projectId]?.['Modules'][changedScreen.moduleId]?.['slides']?.[
                            changedScreen?.slideId
                          ]?.['SlideAudioVO'] !== undefined
                            ? modules[projectId]?.['Modules'][changedScreen.moduleId]?.['slides'][
                                changedScreen?.slideId
                              ]?.['SlideAudioVO']
                            : 'Null';

                        if (
                          !(
                            modules[projectId]['Modules'][changedScreen.moduleId]['slides'][
                              changedScreen?.slideId
                            ]['Screens'][changedScreen?.screenId]['TM01']['Contentparams'][
                              'contentdescription'
                            ] === 'Null' ||
                            modules[projectId]['Modules'][changedScreen.moduleId]['slides'][
                              changedScreen?.slideId
                            ]['Screens'][changedScreen?.screenId]['TM01']['Contentparams'][
                              'contentdescription'
                            ] === '' ||
                            modules[projectId]['Modules'][changedScreen.moduleId]['slides'][
                              changedScreen?.slideId
                            ]['Screens'][changedScreen?.screenId]['TM01']['Contentparams'][
                              'contentdescription'
                            ] === 'no template'
                          )
                        ) {
                          let previewData = {
                            [projectId]: {
                              ProjectName: modules[projectId]?.ProjectName,
                              Modules: {
                                [changedScreen?.moduleId]: {
                                  ModuleName:
                                    modules[projectId]?.['Modules']?.[changedScreen.moduleId]?.[
                                      'ModuleName'
                                    ],
                                  slides: {
                                    [changedScreen?.slideId]: {
                                      SlideName:
                                        modules[projectId]?.['Modules']?.[changedScreen.moduleId]?.[
                                          'slides'
                                        ][changedScreen?.slideId]?.['SlideName'],
                                      SlideAudioVO: SlideAudioVO,
                                      Screens: {
                                        [changedScreen?.screenId]: activeScreenDetails,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          };
                          await projectScreenPreview(JSON.stringify(previewData));
                        }
                      }
                      let res = await CreateProjectFromResponse(projectDatafromDb.data.data[0]);
                      project.createdProject = _.merge(project.createdProject, res.createdProject);
                      setAudioScriptValidation([]);
                      setOstValidation([]);
                      setvalidatingTempLoader(false);
                    }
                  } catch (error) {
                    setProjectLoaded(false);
                  }
                });

                UpdateEditMode(false);
              } else {
                setProjectLoaded(false);
                showToaster(response.message, 'warning');
              }
            } else {
              setProjectLoaded(false);
              showToaster('Something went wrong. Please Try Again', 'warning');
            }
          } catch (error) {
            setProjectLoaded(false);
            showToaster('Something went wrong. Please Try Again', 'warning');
          }
        });
      }
      // }
    } catch (e) {
      setProjectLoaded(false);
      console.log(e);
      showToaster('So mething went wrong. Please Try Again', 'warning');
    }
  };

  const GotoProject = async resolve => {
    let project_id = props.match.params.id;
    if (AdminRole === 1) {
      await getUsersProjectRole(userId).then(response => {
        try {
          setPageLoad(true);
          if (
            response !== '' &&
            response !== undefined &&
            response !== null &&
            response.data !== '' &&
            response.data !== undefined &&
            response.data !== null
          ) {
            // setCategoryList(ddd)
            setPageLoad(false);
            let tempArray = [];
            let projectRole = response.data.result;
            projectRole.map(projectrole => {
              if (projectrole.projectId === project_id && projectrole.viewProject === false) {
                setProjectRolePreview(true);
                history.push('/project-list');
              } else {
                if (EditMode) {
                  if (templateSelectionType.manualTemplate == true) {
                    history.push({
                      pathname: `/project-preview/${projects.ProjectId}`,
                      state: {
                        ActiveModule: ActiveModule,
                        ActiveSlide: ActiveSlide,
                        ActiveScreen: ActiveScreen,
                        type: 'templateSelection',
                      },
                    });
                  } else {
                    history.push({
                      pathname: `/project-preview/${projects.ProjectId}`,
                      state: {
                        ActiveModule: ActiveModule,
                        ActiveSlide: ActiveSlide,
                        ActiveScreen: ActiveScreen,
                      },
                    });
                  }
                } else {
                  if (templateSelectionType.manualTemplate == true) {
                    history.push({
                      pathname: `/project-preview/${projects.ProjectId}`,
                      state: {
                        type: 'templateSelection',
                      },
                    });
                  } else {
                    history.push(`/project-preview/${projects.ProjectId}`);
                  }
                }
              }
              return projectrole;
            });
            //   setProjectRoleProjectId(tempArray)
          } else {
            setPageLoad(false);
            showToaster('Something went wrong. Please Try Again1', 'warning');
          }
        } catch (error) {
          setPageLoad(false);
          showToaster('Something went wrong. Please Try Again', 'warning');
        }
      });
    } else {
      if (EditMode) {
        if (templateSelectionType.manualTemplate == true) {
          history.push({
            pathname: `/project-preview/${projects.ProjectId}`,
            state: {
              ActiveModule: ActiveModule,
              ActiveSlide: ActiveSlide,
              ActiveScreen: ActiveScreen,
              type: 'templateSelection',
            },
          });
        } else {
          history.push({
            pathname: `/project-preview/${projects.ProjectId}`,
            state: {
              ActiveModule: ActiveModule,
              ActiveSlide: ActiveSlide,
              ActiveScreen: ActiveScreen,
            },
          });
        }
      } else {
        if (templateSelectionType.manualTemplate == true) {
          history.push({
            pathname: `/project-preview/${projects.ProjectId}`,
            state: {
              type: 'templateSelection',
            },
          });
        } else {
          history.push(`/project-preview/${projects.ProjectId}`);
        }
      }
    }

    // }
  };

  const closeTempModal = () => {
    setopenTemplateChangesModal(false);
    setrefreshPage(true);
  };

  useEffect(() => {
    previousActiveModule.current = activeModuleState;
    setActiveModuleState(ActiveModule);
    setCurrentScreenKey(`${ActiveModule} ${ActiveSlide} ${ActiveScreen}`);
  }, [ActiveModule, ActiveSlide, ActiveScreen]);

  useEffect(() => {
    previousActiveSlide.current = activeSlideState;
    setActiveSlideState(ActiveSlide);
    setCurrentScreenKey(`${ActiveModule} ${ActiveSlide} ${ActiveScreen}`);
  }, [ActiveModule, ActiveSlide, ActiveScreen]);

  useEffect(() => {
    previousActiveScreen.current = activeScreenState;
    setActiveScreenState(ActiveScreen);
    setCurrentScreenKey(`${ActiveModule} ${ActiveSlide} ${ActiveScreen}`);
  }, [ActiveModule, ActiveSlide, ActiveScreen]);

  useEffect(() => {
    if (Object.keys(project?.createdProject).length) {
      onSlideScreenChange();
    }
  }, [currentScreenKey]);

  useEffect(async () => {
    let filter = {
      project_id: id,
    };
    await getProjectID(filter).then(async response => {
      try {
        if (
          response !== '' &&
          response !== undefined &&
          response !== null &&
          response.status === 200 &&
          response.data !== '' &&
          response.data !== undefined &&
          response.data !== null
        ) {
          let a = await getProjectById(filter.project_id);
          let preview = await CreatePreviewFromResponse(a.data.data[0]);
          InitilizePreview(preview);
        }
      } catch (e) {
        console.log(e);
      }
    });
  }, [id]);

  return (
    <div>
      <>
        <div className={DisplayMode ? 'theme dark' : 'theme'}>
          <div className="main-wrapper">
            <Header />
            <div className="cm-wrapper">
              <Tooltip title="Back" arrow>
                <a className="mb-3 d-block back-btn" onClick={backArrow}>
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="25px"
                    height="25px"
                    viewBox="0 0 400.004 400.004"
                    style={{ enableBackground: 'new 0 0 400.004 400.004' }}
                    space="preserve"
                  >
                    <g>
                      <path
                        d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757
             c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072
             c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315
             C400.004,190.438,392.251,182.686,382.688,182.686z"
                      />
                    </g>
                  </svg>
                </a>
              </Tooltip>
              <div className="d-flex justify-content-between align-items-center cm-header mb-4">
                <div className="cm-left-header">
                  <div className="cm-caption mb-2">
                    {EditMode ? 'Edit Course' : 'Build a New Course'}
                  </div>
                  <div className="cm-subhead">
                    {EditMode
                      ? 'Enter the details below and edit course'
                      : 'Enter the details below and create new course'}
                  </div>
                </div>
                <div className="cm-right-header">
                  <div className="theme-btn-holder d-flex">
                    {
                      <button
                        type="button"
                        className="btn btn-outline me-2 px-5 me-3"
                        onClick={() => setStyleChooser(true)}
                      >
                        Styles
                      </button>
                    }
                    <button
                      type="button"
                      onClick={() => setopenUpdateModal(true)}
                      className="btn btn-primary cm-btn"
                    >
                      update course
                    </button>
                  </div>
                </div>
              </div>
              <PageLoader showLoader={!projectLoaded} />
              <PageLoader showLoader={validatingTempLoader} />
              {/* <SimpleBackdrop showBackDrop={!projectLoaded} /> */}
              {projectLoaded ? (
                <div className="d-flex cm-blk">
                  <div className="cm-left-blk">
                    <div className="cm-mod-blk">
                      <div className="cm-mod-wrapper">
                        {/* <div className="cm-mod-container active" title="module 01"> */}

                        {projectLoaded && listModules()}
                        {/* </div> */}
                      </div>
                      {moduleLimit > ModuleCount && (
                        <div
                          onClick={CreateNewModule}
                          className="d-flex align-items-center justify-content-center cm-add-label cm-add-mod"
                        >
                          {/* <span className="plus" onClick={this.addModule}>+</span> */}
                          <svg
                            className="me-2"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.47266 3.17188H9.52734C9.62109 3.17188 9.66797 3.21875 9.66797 3.3125V15.6875C9.66797 15.7812 9.62109 15.8281 9.52734 15.8281H8.47266C8.37891 15.8281 8.33203 15.7812 8.33203 15.6875V3.3125C8.33203 3.21875 8.37891 3.17188 8.47266 3.17188Z"
                              fill=""
                            />
                            <path
                              d="M3.09375 8.83203H14.9062C15 8.83203 15.0469 8.87891 15.0469 8.97266V10.0273C15.0469 10.1211 15 10.168 14.9062 10.168H3.09375C3 10.168 2.95312 10.1211 2.95312 10.0273V8.97266C2.95312 8.87891 3 8.83203 3.09375 8.83203Z"
                              fill=""
                            />
                          </svg>

                          <span>Add Module</span>
                        </div>
                      )}
                    </div>
                  </div>

                  {projectLoaded && (
                    <div className="cm-right-blk">
                      <AddSlide
                        slideLimit={slideLimit}
                        TemplateSelectionType={templateSelectionType}
                        TemplateCheckArray={templateCheckArray}
                        errorScreen={errorScreen}
                        generatePreview={generatePreview}
                        invalidOstScreens={invalidOstScreens}
                        invalidAudioScreens={invalidAudioScreens}
                        activeAudioScriptComparisonResultsState={
                          activeAudioScriptComparisonResultsState
                        }
                        setErrorScreen={setErrorScreen}
                        setInvalidAudioScreen={setInvalidAudioScreen}
                        setInvalidOstScreen={setInvalidOstScreen}
                        settemplateCheckArray={settemplateCheckArray}
                        setActiveAudioScriptComparisonResultsState={
                          setActiveAudioScriptComparisonResultsState
                        }
                      />
                      <AddScreen
                        screenLimit={screenLimit}
                        TemplateSelectionType={templateSelectionType}
                        TemplateCheckArray={templateCheckArray}
                        errorScreen={errorScreen}
                        invalidOstScreens={invalidOstScreens}
                        invalidAudioScreens={invalidAudioScreens}
                        activeAudioScriptComparisonResultsState={
                          activeAudioScriptComparisonResultsState
                        }
                        settemplateCheckArray={settemplateCheckArray}
                        setErrorScreen={setErrorScreen}
                        setInvalidAudioScreen={setInvalidAudioScreen}
                        setInvalidOstScreen={setInvalidOstScreen}
                        setActiveAudioScriptComparisonResultsState={
                          setActiveAudioScriptComparisonResultsState
                        }
                      />
                      <div className="course-scroller">
                        <AddAudioScript
                          errorScreen={errorScreen}
                          invalidAudioScreens={invalidAudioScreens}
                          invalidOstScreens={invalidOstScreens}
                          activeAudioScriptComparisonResultsState={
                            activeAudioScriptComparisonResultsState
                          }
                          templateCheckArray={templateCheckArray}
                        />
                        <AddScreenDuration />
                        <AddOst
                          setostCountValidate={setostCountValidate}
                          setModuleLimit={setModuleLimit}
                          setSlideLimit={setSlideLimit}
                          setScreenlimit={setScreenLimit}
                          invalidOstScreens={invalidOstScreens}
                        />
                        <AddVisualEffect
                          setIlluCountValidate={setIlluCountValidate}
                          setVideoCountValidate={setVideoCountValidate}
                          setImageCountValidate={setImageCountValidate}
                        />
                        <UsePreviousTemplate />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <h3 className="loading-msg">Loading...</h3>
              )}
            </div>
          </div>
        </div>

        {!validatingTempLoader && (
          <Modal
            isOpen={openTemplateChangesModal}
            fade={true}
            centered
            className={
              DisplayMode
                ? 'modal-dialog  modal-dialog-centered custom-modal dark template-valid'
                : 'modal-dialog  modal-dialog-centered custom-modal dark template-valid'
            }
          >
            <ModalBody>
              <div className="modal-content border-0 rounded-0">
                <div className="modal-body">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h3>Template Validation Table</h3>
                    <div className="close-priview" data-bs-dismiss="modal" onClick={closeTempModal}>
                      <Tooltip title="Close" arrow>
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g data-name="Layer 2">
                            <g data-name="close">
                              <rect
                                width="24"
                                height="24"
                                transform="rotate(180 12 12)"
                                opacity="0"
                              />
                              <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                            </g>
                          </g>
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                  <p>
                    {' '}
                    For the X marked screens,the templates are not available. Please retry by adding
                    a different combination of on-screen text and assets.
                  </p>
                  <div className="template-table">
                    <div className="template-table-wrap template-thead">
                      <div className="template-block"> Screen Name</div>
                      <div className="template-block">Template Available</div>
                    </div>
                    {templateCheckArray !== undefined &&
                      templateCheckArray !== '' &&
                      templateCheckArray.map((data, index) => {
                        return (
                          <div className="template-table-wrap ">
                            <div className="template-block">
                              {data.screenName}
                              {data.isValid == false ? (
                                <p>
                                  (
                                  {data.HeadingCount +
                                    ' ' +
                                    data.BulletCount +
                                    ' ' +
                                    data.ParagraphCount +
                                    ' ' +
                                    data.ImageCount +
                                    ' ' +
                                    data.IllustrationCount +
                                    ' ' +
                                    data.VideoCount}
                                  ) Templates are not available in this combination{' '}
                                </p>
                              ) : (
                                <p>
                                  (
                                  {data.HeadingCount +
                                    ' ' +
                                    data.BulletCount +
                                    ' ' +
                                    data.ParagraphCount +
                                    ' ' +
                                    data.ImageCount +
                                    ' ' +
                                    data.IllustrationCount +
                                    ' ' +
                                    data.VideoCount}
                                  ){' '}
                                </p>
                              )}
                            </div>

                            <div className="template-block  text-center">
                              {data.isValid == false ? (
                                <span className="uncheck-btn">
                                  <Tooltip title="Invalid Template" arrow>
                                    <svg
                                      width="14px"
                                      height="auto"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"></path>
                                    </svg>
                                  </Tooltip>
                                </span>
                              ) : (
                                <span className="check-btn">
                                  <Tooltip title="Valid Template" arrow>
                                    <svg
                                      width="20px"
                                      height="auto"
                                      viewBox="0 0 24 24"
                                      fill=""
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z"
                                        fill=""
                                      ></path>
                                    </svg>
                                  </Tooltip>
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div className="d-flex justify-content-end w-100 footer-btn flex-wrap">
                    <button
                      onClick={closeTempModal}
                      type="button"
                      className="btn btn-outline rounded-0 modal-toggle me-2"
                      data-bs-dismiss="modal"
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      onClick={GotoProject}
                      className="btn btn-primary  me-2 rounded-0"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
      </>
      <CustomModalArray
        isOpen={ostValidationEnable}
        Closemodal={setOstValidationEnable}
        Content={ostValidation}
        Header="Validation"
        Buttonlabel="Ok"
        Buttonclick={setOstValidationEnable}
      />
      {/* <CustomModal isOpen={audioScriptValidationEnable} Closemodal={setAudioScriptValidationEnable} Content="Audio script is Required please  fill and continue" Header="Validation" Buttonlabel="Ok" Buttonclick={setAudioScriptValidationEnable} /> */}
      <NavigatorOnline onChange={status => showMessage(status)} />
      <StyleChooser openStyleChooser={styleChooser} CloseModal={() => setStyleChooser(false)} />
      <CustomModal
        isOpen={openUpdateModal}
        Closemodal={setopenUpdateModal}
        Content="Do you want to update Course?"
        Header="Are you sure?"
        Buttonlabel="Yes, Update it!"
        Buttonclick={submiModuleDetails}
      />
      <CustomModal
        isOpen={openDeletemodal}
        Closemodal={setopenDeletemodal}
        Content="Do you want to delete the module?"
        Header="Are you sure?"
        Buttonlabel="Yes, delete it!"
        Buttonclick={Removemodule}
        del_id={deleteModuleId}
      />
      <SaveChangeModal
        isOpen={openSaveChangesModal}
        Closemodal={setopenSaveChangesModal}
        Content="Do you want to save this?"
        Header="You made some changes"
        Buttonlabel1="Save it!"
        Buttonclick1={submiModuleDetails}
        params1={'goBack'}
        Buttonlabel2="No!"
        Buttonclick2={() => history.goBack()}
      />
      <CustomModal
        isOpen={openDeletemodal}
        Closemodal={setopenDeletemodal}
        Content="Do you want to delete the module?"
        Header="Are you sure"
        Buttonlabel="Yes, delete it!"
        Buttonclick={Removemodule}
        del_id={deleteModuleId}
      />
    </div>
  );
}

export default AddProject;
