import React, { useEffect, useState } from 'react';

import { checkAdmin } from '../../../services/helper';
import { secondsToHrMinS } from '../../../lib/time-conversion';
import { allProjectAnalytics } from '../../../services/apiServices';

const UsageTrackingHeader = () => {
  const { token, client } = checkAdmin();
  const [projectAnalytics, setProjectAnalytics] = useState({});

  useEffect(() => {
    allProjectAnalytics({ token, client }).then(res => {
      if (res && res.data) {
        setProjectAnalytics(res?.data?.data);
      }
    });
  }, []);
  return (
    <div className="usage-tracking-header d-flex ">
      <div className="usage-tracking-header__item assets-status-box">
        <span className="usage-tracking-header__item-title">Total Videos</span>
        <span className="usage-tracking-header__item-value"> {projectAnalytics?.totalVideos ?? 0}</span>
      </div>
      <div className="usage-tracking-header__item assets-status-box">
        <span className="usage-tracking-header__item-title">Total Video Duration</span>
        <span className="usage-tracking-header__item-value">
          {' '}
          {secondsToHrMinS(projectAnalytics?.totalVideoDuration)}
        </span>
      </div>
      <div className="usage-tracking-header__item assets-status-box">
        <span className="usage-tracking-header__item-title">Total Renders</span>
        <span className="usage-tracking-header__item-value">
          {' '}
          {projectAnalytics?.totalProjectRender ?? 0}
        </span>
      </div>
      <div className="usage-tracking-header__item assets-status-box">
        <span className="usage-tracking-header__item-title">Total Render Duration</span>
        <span className="usage-tracking-header__item-value">
          {' '}
          {secondsToHrMinS(projectAnalytics?.totalRenderDuration)}
        </span>
      </div>
      <div className="usage-tracking-header__item assets-status-box">
        <span className="usage-tracking-header__item-title">Total Size</span>
        <span className="usage-tracking-header__item-value">
          {' '}
          { projectAnalytics?.totalSize ? (projectAnalytics?.totalSize / 1024 / 1024).toFixed(2) : 0} MB
        </span>
      </div>
    </div>
  );
};

export default UsageTrackingHeader;
