import React, { useEffect, useState } from 'react';

// Icons
import {
  documentIcon,
  imagesIcon,
  illustratorIcon,
  videosIcon,
  audioIcon,
  noProjectIcon,
} from '../../../constants/icon';

// import DataNotFound from '../../../components/dataNotFound';

import Sidebar from '../../../components/sidebar';
import AssetsStatusBox from './components/assetsStatusBox';
import HeaderNavigation from '../../../components/headerNavigation';
import DocumentComponent from './components/documentComponent';
import ImageComponent from './components/imageComponent';
import VideoComponent from './components/videoComponent';
import IllustratorComponent from './components/illustratorComponent';
import AudioComponent from './components/audioComponent';
import SecondaryAssetsHeader from './components/secondaryAssetsHeader';
import PagePagination from '../../../components/pagination/pagePagination';
import PaginationComponent from '../../../components/pagination';
import { getAssetsV1 } from '../../../services/api.service';
import DataNotFound from '../../../components/dataNotFound';
import PageLoader from '../../../services/pageLoader';
import { showToaster } from '../../../services/helper';

function Assets() {
  const [activeComponent, setActiveComponent] = useState('IMAGE');
  const [pageNo, setPageNo] = useState(1);
  const [endPageNo, setEndPageNo] = useState(100);
  const [keyWord, setKeyWord] = useState('');
  const [privateRepo, setPrivateRepo] = useState(true);
  const [posts, setPosts] = useState([]);

  const handleDoumentCLick = () => {
    // setActiveComponent('DOCUMENT');
  };

  const handleImageCLick = () => {
    setActiveComponent('IMAGE');
    setKeyWord('');
  };
  const handleVideoCLick = () => {
    setActiveComponent('VIDEO');
    setKeyWord('');
  };
  const handleIllustratorCLick = () => {
    // setActiveComponent('ILLUSTRATOR');
  };

  const handleAudioCLick = () => {
    // setActiveComponent('AUDIO');
  };

  // Pagination Start
  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  useEffect(() => {
    getAssetRepo();
  }, [keyWord, endPageNo, pageNo, privateRepo, activeComponent]);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleLastPage = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(totalPages);
    }
  };

  // Pagination End

  const getAssetRepo = () => {
    setPosts([]);
    if (!privateRepo && (!keyWord.length || (keyWord && !keyWord.trim()))) {
      showToaster('Please enter search to show public items.', 'warning');
      return;
    } else {
      const searchArray = keyWord.split(',').filter(k => k && k.trim());
      const request = {
        start: pageNo,
        end: endPageNo,
        media_type: activeComponent,
        search_term: searchArray,
        private: privateRepo ? 1 : 0,
      };
      setLoading(true);
      getAssetsV1(request)
        .then(response => {
          if (response.data?.length) {
            setPosts(response.data);
            setLoading(false);
          } else {
            setPosts([]);
            setLoading(false);
          }
        })
        .catch(error => {
          console.log({ getAssetsError: error });
          setLoading(false);
          setPosts([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const showAssetDeleteOption =
    window.location.hostname.split('.')[0] === 'olive1' ||
    window.location.hostname.split('.')[0] === 'koju2';

  return (
    <>
      <PageLoader showLoader={loading} />
      <Sidebar />
      <main className="main-content-wrapper">
        <div className="content-wrapper">
          <HeaderNavigation />
          <SecondaryAssetsHeader
            mediaType={activeComponent.toLocaleLowerCase()}
            keyWord={keyWord}
            setKeyWord={setKeyWord}
            setPrivateRepo={setPrivateRepo}
            privateRepo={privateRepo}
          />
          {/* <DataNotFound icon={noProjectIcon} title="No image found" /> */}

          <div className="assets-wrapper">
            <div className="assets-status-box-holder">
              {/* <AssetsStatusBox
                name="Document"
                icon={documentIcon}
                // size="935"
                // type="789 MB"
                // onClick={handleDoumentCLick}
                className={activeComponent === 'DOCUMENT' ? 'active' : ''}
              /> */}
              <AssetsStatusBox
                name="Image"
                icon={imagesIcon}
                // size={posts?.length}
                // type="789 MB"
                onClick={handleImageCLick}
                className={activeComponent === 'IMAGE' ? 'active' : ''}
              />
              <AssetsStatusBox
                name="Video"
                icon={videosIcon}
                // size={posts?.length}
                // type="789 MB"
                onClick={handleVideoCLick}
                className={activeComponent === 'VIDEO' ? 'active' : ''}
              />
              {/* <AssetsStatusBox
                name="Illustrator"
                icon={illustratorIcon}
                // size="935"
                // type="789 MB"
                // onClick={handleIllustratorCLick}
                className={activeComponent === 'ILLUSTRATOR' ? 'active' : ''}
              />
              <AssetsStatusBox
                name="Audio"
                icon={audioIcon}
                // size="935"
                // type="789 MB"
                // onClick={handleAudioCLick}
                className={activeComponent === 'AUDIO' ? 'active' : ''}
              /> */}
            </div>
            {keyWord.length && !posts.length ? (
              <DataNotFound
                icon={noProjectIcon}
                title={`No ${activeComponent.toLocaleLowerCase()} found`}
                className="assets-data-not-found"
              />
            ) : (
              <>
                {activeComponent === 'DOCUMENT' && <DocumentComponent />}
                {activeComponent === 'IMAGE' && (
                  <ImageComponent
                    posts={posts}
                    activeComponent={activeComponent}
                    setPrivateRepo={setPrivateRepo}
                    privateRepo={privateRepo}
                    getAssetRepo={getAssetRepo}
                    showAssetDeleteOption={showAssetDeleteOption}
                  />
                )}
                {activeComponent === 'VIDEO' && (
                  <VideoComponent
                    posts={posts}
                    activeComponent={activeComponent}
                    setPrivateRepo={setPrivateRepo}
                    privateRepo={privateRepo}
                    getAssetRepo={getAssetRepo}
                    showAssetDeleteOption={showAssetDeleteOption}
                  />
                )}
                {activeComponent === 'ILLUSTRATOR' && <IllustratorComponent />}
                {activeComponent === 'AUDIO' && <AudioComponent />}
              </>
            )}
          </div>
          <div className="pagination-wrapper">
            <PagePagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
            />
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              onFirst={handleFirstPage}
              onPrevious={handlePreviousPage}
              onNext={handleNextPage}
              onLast={handleLastPage}
            />
          </div>
        </div>
      </main>
    </>
  );
}
export default Assets;
