import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function AdminRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('_user') &&
        localStorage.getItem('_olive_user') &&
        localStorage.getItem('_s_admin_user') ? (
          <Component {...props} />
        ) : (
          <Redirect to="/old-login" />
        )
      }
    />
  );
}
