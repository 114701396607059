function DataNotFound(props) {
  const { title, icon, className } = props;
  return (
    <div className={`data-not-found-wrapper ${props.className || ''}`}>
        <div className={`data-not-found ${props.className || ''}`}>
          {icon && <div className="icon-wrap">{icon}</div>}
          {title &&<div className="title-wrap">{title}</div>}
        </div>
    </div>
  );
}
export default DataNotFound;
