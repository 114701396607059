import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import {
  helpIcon,
  mailIcon,
  memberPlusIcon,
  orgGroupIcon,
  plusIcon,
  renderIcon,
  searchIcon,
  uploadIcon,
  userIcon,
} from '../../../../constants/icon';
import UserThumbnail from '../../../../assets/img/user-thumbnail.png';
import UserProfileImage from '../../../../assets/img/user-image.png';

import { decodeEncodedItem, getUserItem, isAdmin, showToaster } from '../../../../services/helper';
import CustomSwitchSlider from '../../../../components/form/customSwitchSlider';
import FormFloatingInput from '../../../../components/form/formFloatingInput';
import ProjectTable from './projectsTable';
import ProfileList from './profileList';
import ProfileListItem from './profileListItem';
import AddNewUserModal from './addNewUserModal';
import {
  getUserDetails,
  getUserProjectRolesDetails,
  projectLists,
  saveUserRole,
  updateUserRole,
} from '../../../../services/apiServices';
import PageLoader from '../../../../services/pageLoader';
import useDebounce from '../../../../hooks/debounceHook';
import { permissions } from '../../../../services/permissionLIst';

function UserDetail() {
  const { debounce, debouncedValue } = useDebounce();
  const params = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [keyWord, setKeyWord] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [userRoles, setUserRoles] = useState({});
  const [loadUsers, setLoadUsers] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectList, setProjectList] = useState([]);
  const [roleDetails, setRoleDetails] = useState([]);
  const [saving, setSaving] = useState(false);
  const [totalProject, setTotalProjects] = useState()
  const [tempSearchName, setTempSearchName] = useState('');
  const currentUserRoles = getUserItem('userRole');
  const isCurrentUserAdmin = isAdmin();

  useEffect(() => {
    if (debouncedValue !== null) {
      setKeyWord(debouncedValue);
    }
  }, [debouncedValue]);

  const handleSearch = e => {
    const { value } = e.target;
    debounce(value, 1000);
    setTempSearchName(value);
  };

  let userSessionData = decodeEncodedItem(localStorage.getItem('_olive_user'));

  const fetchUserDetails = async () => {
    setLoadUsers(true);
    const userDetails = await getUserDetails(params.id);
    setUserDetails(userDetails.result);
    setUserRoles(userDetails.role);
    setLoadUsers(false);
  };
  useEffect(() => {
    fetchUserDetails();
  }, []);

  const getProjects = async (search = '', limit = null, pageNo = null) => {
    setLoading(true);
    const body = {
      keyword: search.length ? search.trim : keyWord.trim(),
      deleted: 0,
      limit: limit ?? itemsPerPage,
      page_no: pageNo ?? currentPage,
    };
    const projectListsResponse = await projectLists(body);
    const projectRolesResponse = await getUserProjectRolesDetails(params.id);
    let roleDetails = projectRolesResponse?.data?.projectRoles.map((item, _) =>{
      return (
        {
          userId: item.userId,
          projectId: item.projectId,
          editProject: item.editProject,
          viewProject: item.viewProject,
          deleteProject: item.deleteProject,  
        }
      )
    })
    setRoleDetails(roleDetails);
    if (projectListsResponse.status === 200) {
      if(projectList.length){
        setProjectList([...projectList, ...projectListsResponse.data]);

      } else{

        setProjectList(projectListsResponse.data);
      }
      setTotalProjects(projectListsResponse.total_count)
    } else {
      showToaster(projectListsResponse.message, 'warning');
    }
    setLoading(false);
  };

  useEffect(() => {
    getProjects();
  }, [keyWord, currentPage]);

  const handleRoleChange = async (value, name) => {
    let roleBody = { userId: params.id };
    roleBody[name] = value;
    const roles = await updateUserRole(roleBody);
    setUserRoles(roles.result);
  };

  const saveUserAssign = async () => {
    let body = {
      userId: params?.id,
      projectRoles: roleDetails,
    };
    setSaving(true);
    const response = await saveUserRole(body);
    setSaving(false);
    // const projectRolesResponse = await getUserProjectRolesDetails(params.id);
    // setRoleDetails(projectRolesResponse?.data?.projectRoles);
    //  setRoleDetails(response?.result)
  };

  return (
    <>
      <PageLoader showLoader={loadUsers} />
      <AddNewUserModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="secondary-header secondary-sticky-header">
        <FormFloatingInput
          leftIcon={searchIcon}
          type="search"
          id="list"
          placeholder=""
          label="Search user by name or email"
          className="custom-search-form"
          onChange={e => handleSearch(e)}
        />
        <div className="common-dropdown-filter-wrap">
          {/* <Button variant="default">{renderIcon}Export</Button> */}
          <button role="button" className="outline-primary-btn" onClick={() => setModalShow(true)}>
            {plusIcon}
            Add new user
          </button>
        </div>
      </div>
      <div className="user-management-wrapper">
        <div className="user-sidebar">
          <div className="user-thumbnail">
            <div className="user-img-holder">
              <div className="user-img-wrap">
                <img src={UserThumbnail} alt="user thumnail" />
              </div>
              <div className="user-circle">
                <img src={UserProfileImage} alt="profile image" />
              </div>
            </div>
            <div className="user-info">
              <h4>
                {userDetails?.firstName} {userDetails?.lastName}
              </h4>
              <Link to={`mailto:${userDetails?.email}`}>{userDetails?.email}</Link>
            </div>
            <Button variant="default">Reset Password</Button>
          </div>

          <div className="profile-block">
            <h2>Profile</h2>

            <h4>About</h4>
            <ProfileList>
              <ProfileListItem
                icon={userIcon}
                name={`${userDetails?.firstName} ${userDetails?.lastName}`}
              />
              <ProfileListItem icon={memberPlusIcon} name={`Role: ${userDetails?.role}`} />
              <ProfileListItem icon={orgGroupIcon} name={`Org: ${userSessionData?.clientName}`} />
            </ProfileList>

            <h4>Contacts</h4>
            <ProfileList>
              <ProfileListItem icon={mailIcon} name={userDetails?.email} />
              <ProfileListItem icon={helpIcon} name={userDetails?.phonenumber} />
            </ProfileList>

            {/* <h4>Teams</h4>
            <ProfileList>
              <ProfileListItem icon={memberPlusIcon} name="Member of 5 teams" />
              <ProfileListItem icon={stackPlayIcon} name="Working on 4 projects" />
            </ProfileList> */}
          </div>
        </div>
        <div className="table-container">
          <div className="switch-card-wrapper">
            {permissions.map((permission, index) => {
              if (isCurrentUserAdmin || currentUserRoles[permission.name]) {
                return (
                  <div className="switch-card" key={index}>
                    <div className="switch-card-info">
                      <h4>{permission.title}</h4>
                      <span>{permission.desc}</span>
                    </div>
                    <CustomSwitchSlider
                      isChecked={userRoles[permission.name]}
                      onChange={handleRoleChange}
                      inputName={permission.name}
                    />
                  </div>
                );
              }
            })}
          </div>

          <div className="common-box">
            <div className="common-box-header">
              <h4>Projects</h4>
              <button
                role="button"
                className="outline-primary-btn"
                onClick={() => saveUserAssign()}
              >
                {saving ? 'Saving ...' : 'Save'}
                {uploadIcon}
              </button>
            </div>
            <div className="common-box-search">
              <FormFloatingInput
                leftIcon={searchIcon}
                type="search"
                id="searchProject"
                placeholder=""
                label="Search projects"
                className="custom-search-form"
                inputValue={tempSearchName}
                onChange={handleSearch}
              />
            </div>
            <ProjectTable
              projectList={projectList}
              loading={loading}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              projectRoleDetails={roleDetails}
              setRoleDetails={setRoleDetails}
              totalProject={totalProject}
            />
          </div>

          {/* <div className="common-box">
            <div className="common-box-header">
              <h4>Worked on</h4>
            </div>
            <WorkedTable />
          </div> */}
        </div>
      </div>
    </>
  );
}
export default UserDetail;
