import { useEffect } from 'react';
import { Route, Switch, BrowserRouter, withRouter, useHistory, Redirect } from 'react-router-dom';

import Login from './modules/auth/login/login';
import LoginPage from './modules/login';
import './App.css';
import ForgotPassword from './modules/auth/forgot-password/forgot_password';
import ResetPassword from './modules/auth/reset-password/reset_password';
import CreateProject from './modules/container/create-project/create_project';
import projectList from './modules/container/projectlist/projectlist';
import RenderProjectList from './modules/container/projectlist/Renderprojectlist';
import projectRenderList from './modules/container/renderList/projectlist';
import Category from './modules/container/projectlist/Category';
import UsageTracking from './modules/container/projectlist/UsageTracking';
import UsageTrackingDetails from './modules/container/renderList/RenderTrackingDetails';
import UsageTrackingDetailsParent from './modules/container/projectlist/RenderTrackingDetails';
import DownloadVideo from './modules/container/renderList/DownloadModal';
import DownloadProjectlistVideo from './modules/container/projectlist/DownloadModal';
import projectDetails from './modules/dashboard/project-priview/projectDetails';
import PrivateRoute from '../src/lib/PrivateRoute';
import AddProject from './modules/container/addinput/AddProject';
import RollTable from './modules/container/role-table/userListing';
import OrgCreation from './modules/container/user-plan/orgCreation';
import RenderPaymentMethods from './modules/container/user-plan/renderPaymentMethod';
import RollAssignTable from './modules/container/role-table/roleAssign';
import Authorize from './services/Authorize';
import TemplateUpload from './modules/dashboard/Template/TemplateUpload';
// import AdvanceCrop from './modules/dashboard/project-priview/RightBar/CropImage/advanceCrop'
import OldTemplateList from './modules/dashboard/Template/TemplateList';
import TemplateAllList from './modules/dashboard/Template/TemplateAll';
import ClusterSettings from './modules/admin/listcluster';
import ListCluserForRender from './modules/admin/ListClusterForRender';
import ConfigSettings from './modules/admin/ConfigSettings';
import VisualAssets from './modules/dashboard/VisualAssets';
import AdminProjectListing from './modules/admin/ProjectListing';
import AdminUsageTracking from './modules/admin/UsageTracking';
import AdminUsageTrackingDetails from './modules/admin/RenderTrackingDetails';
import AdminDownloadVideo from './modules/admin/DownloadModal';
import OrganisationtListing from './modules/superadmin/organizationList';
import OrganisationtGlobalPlan from './modules/superadmin/templateRoleAssign';
import OrganisationPlan from './modules/admin/TemplateplanSettings';
// import OrgUsageTracking from './modules/orgDashboard/UsageTracking'
// import OrgUsageTrackingDetails from './modules/orgDashboard/RenderTrackingDetails'
// import OrgDownloadVideo from './modules/admin/DownloadModal'
import { socket, joinUserToRoom } from './services/socket.service';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from './store/index';
import { getUserItem } from './services/helper';
import './assets/scss/main.scss';
import ClusterRenderlist from './modules/container/renderList/ClusterRenderlist';
import AllRenderList from './modules/container/renderList/AllRenderList';
import Profile from './modules/auth/profile/Profile';
import VisualAssetsMultiple from './modules/dashboard/VisualAssets-UploadMultiFiles';
import Register from './modules/login/Register';

// New Ai Content Pro Pages
import EditProjects from './modules/projects/editProjects';
import CreateProjects from './modules/projects/createProjects';
import ChatGpt from './modules/projects/chatGpt';
import DetailProjects from './modules/projects/detailProjects';
import RenderDetail from './modules/projects/renderDetail';
import Assets from './modules/projects/assets';
import UserManagement from './modules/projects/settings/userManagement';
import Template from './modules/superadmin/template';
import DemoAlertComponent from './components/alertNotification';
import EditProfile from './modules/projects/settings/editProfile/';
import UsagesTracking from './modules/projects/usageTracking/UsageTracking';
import ClientList from './modules/superadmin/clientList';
import page404 from './modules/projects/404page' 
import AdminRoute from './lib/AdminRoute';
import TemplateList from './modules/superadmin/template/templateList';
import userDetails from './modules/projects/settings/userDetails';
import PreviewIcons from './modules/superadmin/previewIcons';

function App() {
  const dispatch = useDispatch();
  const { UpdateProjectScreenPreview } = bindActionCreators(actionCreator, dispatch);

  useEffect(() => {
    socket.on('connect', () => {
      joinUserToRoom();
    });
    socket.on('ScreenPreview', data => {
      try {
        const userId = getUserItem('id');
        if (userId === data.userId) {
          UpdateProjectScreenPreview(data);
        }
      } catch (error) {
        console.log({ sockerScreenPreviewError: error });
      }
    });
    return () => {
      socket.off('connect');
      socket.off('ScreenPreview');
    };
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <AdminRoute path="/icons" exact component={PreviewIcons} />
        <Route path="/assets" exact component={Assets} />
        <Route path="/login" exact component={LoginPage} />
        <Route path="/old-login" exact component={Login} />
        <Route path="/register" exact component={Register} />
        <Route path="/reset" exact component={ResetPassword} />
        <Route path="/forgot" exact component={ForgotPassword} />
        <Route path="/settings/user-management" exact component={UserManagement} />
        <Route path="/settings/edit-profile" exact component={EditProfile} />
        <Route path="/settings/:id/user-details" exact component={userDetails} />
        <Route path="/template" exact component={Template} />
        <Route path="/demo-components" exact component={DemoAlertComponent} />

        <PrivateRoute path="/usages-tracking" exact component={UsagesTracking} adminAccess />
        {/* <Route path="/client-list" exact component={ClientList} /> */}
        <AdminRoute path="/client-list" exact component={ClientList} />
        <AdminRoute path="/template-list" exact component={TemplateList} />
        {/* <PrivateRoute path="/create-projects" exact component={ChatGpt} /> */}
        <PrivateRoute path="/project/:projectId/generate-videos-inputs" exact component={ChatGpt} />
        <PrivateRoute path="/render-detail/:id" exact component={RenderDetail} />
        <PrivateRoute path="/project-details/:id" exact component={DetailProjects} />
        <PrivateRoute path="/edit-projects/:id" exact component={EditProjects} />
        <PrivateRoute path="/list-projects" exact component={CreateProjects} />
        <PrivateRoute path="/create-project" exact component={CreateProject} />
        <PrivateRoute path="/project-list" exact component={projectList} />
        <PrivateRoute path="/project-render-list" exact component={RenderProjectList} />
        <PrivateRoute path="/render-list" exact component={projectRenderList} />
        <PrivateRoute path="/category-list" exact component={Category} />
        <PrivateRoute path="/usage-tracking" exact component={UsageTracking} />
        <PrivateRoute path="/render-details" exact component={UsageTrackingDetails} />
        <PrivateRoute path="/render-project-details" exact component={UsageTrackingDetailsParent} />
        <PrivateRoute path="/render-list/download" exact component={DownloadVideo} />
        <PrivateRoute
          path="/admin/cluster/:clusterId/render-list"
          exact
          component={ClusterRenderlist}
        />
        <PrivateRoute path="/project-list/download" exact component={DownloadProjectlistVideo} />
        <PrivateRoute path="/download" exact component={DownloadVideo} />
        <PrivateRoute path="/project-preview/:id" exact component={projectDetails} />
        <PrivateRoute path="/edit-project/:id" exact component={AddProject} />
        <AdminRoute path="/template-upload" exact component={TemplateUpload} />
        <PrivateRoute path="/template-alllist" exact component={TemplateAllList} />
        <PrivateRoute path="/templatelist" exact component={OldTemplateList} />
        <PrivateRoute path="/user-roll" exact component={RollTable} />
        <PrivateRoute path="/payment-methods/:id" exact component={RenderPaymentMethods} />
        <PrivateRoute path="/rollassign" exact component={RollAssignTable} />
        <Route path="/authorize/:id/:projectName" exact component={Authorize} />
        {/* <PrivateRoute path="/advanceCrop" exact component={AdvanceCrop} /> */}
        <PrivateRoute path="/repository-management" exact component={VisualAssets} />
        <PrivateRoute
          path="/repository-management-upload-multiple-files"
          exact
          component={VisualAssetsMultiple}
        />
        <PrivateRoute path="/admin/cluster-settings" exact component={ClusterSettings} />
        <PrivateRoute
          path="/admin/project-rerender/:projectRenderId/cluster-selection"
          exact
          component={ListCluserForRender}
        />
        <PrivateRoute path="/admin/config-settings" exact component={ConfigSettings} />
        <PrivateRoute path="/admin/project-list" exact component={AdminProjectListing} />
        <PrivateRoute path="/admin/usage-tracking" exact component={AdminUsageTracking} />
        <PrivateRoute path="/admin/render-details" exact component={AdminUsageTrackingDetails} />
        <PrivateRoute path="/admin/project-list/download" exact component={AdminDownloadVideo} />
        <PrivateRoute path="/admin/org-template-plan" exact component={OrganisationPlan} />
        <PrivateRoute path="/admin/org-settings" exact component={OrganisationtListing} />
        <PrivateRoute path="/admin/org-global-plan" exact component={OrganisationtGlobalPlan} />
        <PrivateRoute path="/admin/org-global-plan" exact component={OrganisationtGlobalPlan} />
        <PrivateRoute path="/admin/render-list" exact component={AllRenderList} />
        <PrivateRoute path="/edit-profile" exact component={Profile} />
        <Route path="/create-organization" exact component={OrgCreation} />
        <Route path="/page-not-found" exact component={page404} />
        {/* <PrivateRoute path="/org/project-list" exact component={OrgProjectListing} />
        <PrivateRoute path="/org/usage-tracking" exact component={OrgUsageTracking} />
        <PrivateRoute path="/org/render-details" exact component={OrgUsageTrackingDetails} />
        <PrivateRoute path="/org/project-list/download" exact component={OrgDownloadVideo} /> */}
        <Route path="/">
          <Redirect to="/list-projects" />{' '}
        </Route>

        <PrivateRoute path="*" component={Login} />
        {/* <Route path="/create-organization" exact component={OrgCreation}/> */}
        {/* <Route path="/authorize" exact component={Authorize}/> */}
      </Switch>
    </BrowserRouter>
  );
}

export default withRouter(App);
