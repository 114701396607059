import { useCallback, useEffect, useState } from 'react';

import { getFonts } from '../services/api.service';
import { mergeFontFamilyAndStyle } from '../services/helper';

export default function useFonts() {
  const [fonts, setFonts] = useState();

  useEffect(() => {
    getFonts().then(data => {
      try {
        if (data) {
          generateFonts(data);
        }
      } catch (error) {
        console.log({ getFontsError: error });
      }
    });
  }, []);

  const generateFonts = useCallback(
    fontData => {
      if (fontData) {
        const sampleArray = fontData.map(font => mergeFontFamilyAndStyle(font));
        let fontOption = [];
        sampleArray.map(font => {
          fontOption.push({ name: font });
          return true;
        });
        setFonts(fontOption);
      }
    },
    [fonts]
  );

  return { fonts };
}
