import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useEffect, useMemo, useState } from 'react';

import { actionCreator } from '../../../store';
import TagInput from '../../../components/tagInput';
import { showToaster } from '../../../services/helper';
import CustomModal from '../../../services/CustomModal';
import FormTextarea from '../../../components/form/formTextarea';
import FormInputGroup from '../../login/components/FormInputGroup';
import CarouselItem from '../../../components/carousel/carouselItem';
import {
  backArrowIcon,
  plusIcon,
  searchIcon,
  crossIcon,
  eyeOpenIcon,
  editIcon,
} from '../../../constants/icon';
import { VIDEO_LIMIT } from '../../../constants';
import VideoPriview from '../../dashboard/project-priview/RightBar/VideoPriview';
import EditAssets from '../../../components/editProject/editAsset';
// import SampleImage from '../../../assets/img/image-01.png';
// import SampleImage2 from '../../../assets/img/image-02.png';
// import SampleImage3 from '../../../assets/img/image-03.png';
// import SampleImage4 from '../../../assets/img/image-04.png';
// import SampleImage5 from '../../../assets/img/image-05.png';
// import SampleImage6 from '../../../assets/img/image-06.png';
// import SampleImage7 from '../../../assets/img/image-07.png';
// import SampleImage8 from '../../../assets/img/image-08.png';
// import SampleImage9 from '../../../assets/img/image-09.png';
// import SampleImage10 from '../../../assets/img/image-10.png';
// import SampleImage11 from '../../../assets/img/image-11.png';
// import SampleImage12 from '../../../assets/img/image-12.png';
// import SampleImage13 from '../../../assets/img/image-13.png';
// import SampleImage14 from '../../../assets/img/image-14.png';
// import SampleImage15 from '../../../assets/img/image-15.png';

export default function VideoComponent({ goBack, videoList, manipulateTemplateArray }) {
  const dispatch = useDispatch();
  const { AddFeature, EditAsset, DeleteFeature, SelectVideo } = bindActionCreators(
    actionCreator,
    dispatch
  );

  const [openVideoView, setOpenVideoView] = useState('');
  const [openVideoEdit, setOpenVideoEdit] = useState('');
  const [deleteVideoData, setDeleteVideoData] = useState('');

  // const handleAddVideo = () => {
  //   if (videoList.length >= VIDEO_LIMIT) return;
  //   const lastVideoId = videoList[videoList.length - 1].videoId;
  //   const videoNumber = lastVideoId.replace('VD0', '');
  //   const videoNumberSplit = videoNumber.split('VD')[1];

  //   let data;
  //   if (videoNumberSplit) data = parseInt(videoNumberSplit) + 1;
  //   else data = parseInt(videoNumber) + 1;

  //   const newVideo = data < 10 ? 'VD0' + data : 'VD' + data;

  //   AddFeature({
  //     [newVideo]: {
  //       Contenttype: 'Video',
  //       Contentparams: {
  //         contenttag: '',
  //         sequenceid: '11',
  //         searchstring: '',
  //         contentdescription: '',
  //       },
  //     },
  //   });
  // };

  const onChangeVideoDescription = value => {
    manipulateTemplateArray();
    EditAsset({ searchstring: value }, 'video');
  };

  const handleTagChange = tags => {
    manipulateTemplateArray();
    EditAsset({ contenttag: tags.toString() }, 'video');
  };

  const removeVideo = () => {
    if (videoList.length > 1) {
      manipulateTemplateArray();
      DeleteFeature(deleteVideoData, 'VD');
      showToaster(`${deleteVideoData} Deleted Successfully`, 'success');
      setDeleteVideoData('');
    }
  };

  const handleVideoFocus = videoId => SelectVideo(videoId);
  const handleRemoveDescription = () => {
    manipulateTemplateArray();
    EditAsset({ contentdescription: '' }, 'video');
  };

  return (
    <>
      <div className="screen-sidebar-header screen-header-back">
        <div className="sticky-head">
          <span role="button" onClick={goBack}>
            {backArrowIcon} Video
          </span>
          <p>Upload, or add to your video</p>
        </div>
      </div>
      <div className="screen-sidebar-body screen-audio-wrap pe-2">
        {/* <FormInputGroup
          leftIcon={searchIcon}
          type="search"
          id="list"
          placeholder=""
          label="Search Video"
          className="custom-search-form"
        /> */}
        <>
          {videoList.map(video => (
            <div className="tag-input-content" key={video.videoId}>
              <h4>{video.label}</h4>
              <div className="tag-input-holder">
                <div className="tag-input-wrapper">
                  <FormTextarea
                    id="message"
                    // label="Write script for screen"
                    onChange={onChangeVideoDescription}
                    onFocus={() => handleVideoFocus(video.videoId)}
                    inputValue={video.contentParams.searchstring}
                    label="Add Description for Asset Generation/ Uploaded Asset"
                  />
                  <div>
                    <TagInput
                      label="Add Tag"
                      onChange={handleTagChange}
                      tags={video.contentParams.contenttag}
                      placeholder="Add new tag and press enter"
                      handleFocus={() => handleVideoFocus(video.videoId)}
                    />
                  </div>
                </div>
                {videoList.length > 1 && (
                  <span role="button" onClick={() => setDeleteVideoData(video.videoId)}>
                    {crossIcon}
                  </span>
                )}
              </div>
              {video.contentParams.contentdescription && (
                <CarouselItem
                  className="mb-4"
                  crossBtn={crossIcon}
                  handleRemove={handleRemoveDescription}
                  carouselVideo={video.contentParams.contentdescription}
                >
                  <div
                    className="carousel-icon-wrap"
                    onClick={() => handleVideoFocus(video.videoId)}
                  >
                    <span
                      role="button"
                      className="video-view"
                      onClick={() => setOpenVideoView(video.contentParams.contentdescription)}
                    >
                      {eyeOpenIcon}
                    </span>
                    <span
                      role="button"
                      className="video-edit"
                      onClick={() => setOpenVideoEdit(video.contentParams.contentdescription)}
                    >
                      {editIcon}
                    </span>
                  </div>
                </CarouselItem>
              )}
            </div>
          ))}
        </>

        {/* {VIDEO_LIMIT > videoList.length ? (
          <button className="triiger-btn tag-content-btn" role="button" onClick={handleAddVideo}>
            <span className="upload-icon">{plusIcon}</span>
            <span>Add New Video</span>
          </button>
        ) : (
          <div className="screen-thumbnail-items-wrap">
            <span>Max. video limit reached</span>
          </div>
        )} */}

        {/* <div className="add-media-assests">
          <button className="triiger-btn" role="button">
            <span className="upload-icon">{plusIcon}</span>
          </button>
          <CarouselItem carouselImage={SampleImage} />
          <CarouselItem carouselImage={SampleImage2} />
          <CarouselItem carouselImage={SampleImage3} />
          <CarouselItem carouselImage={SampleImage4} />
          <CarouselItem carouselImage={SampleImage5} />
          <CarouselItem carouselImage={SampleImage6} />
          <CarouselItem carouselImage={SampleImage7} />
          <CarouselItem carouselImage={SampleImage8} />
          <CarouselItem carouselImage={SampleImage9} />
          <CarouselItem carouselImage={SampleImage10} />
          <CarouselItem carouselImage={SampleImage11} />
          <CarouselItem carouselImage={SampleImage12} />
          <CarouselItem carouselImage={SampleImage13} />
          <CarouselItem carouselImage={SampleImage14} />
          <CarouselItem carouselImage={SampleImage15} />
          <CarouselItem carouselImage={SampleImage} />
          <CarouselItem carouselImage={SampleImage2} />
          <CarouselItem carouselImage={SampleImage3} />
          <CarouselItem carouselImage={SampleImage4} />
          <CarouselItem carouselImage={SampleImage5} />
          <CarouselItem carouselImage={SampleImage6} />
          <CarouselItem carouselImage={SampleImage7} />
          <CarouselItem carouselImage={SampleImage} />
          <CarouselItem carouselImage={SampleImage2} />
          <CarouselItem carouselImage={SampleImage3} />
          <CarouselItem carouselImage={SampleImage4} />
          <CarouselItem carouselImage={SampleImage5} />
          <CarouselItem carouselImage={SampleImage6} />
          <CarouselItem carouselImage={SampleImage7} />
          <CarouselItem carouselImage={SampleImage8} />
          <CarouselItem carouselImage={SampleImage9} />
          <CarouselItem carouselImage={SampleImage10} />
          <CarouselItem carouselImage={SampleImage11} />
          <CarouselItem carouselImage={SampleImage12} />
          <CarouselItem carouselImage={SampleImage13} />
          <CarouselItem carouselImage={SampleImage14} />
          <CarouselItem carouselImage={SampleImage15} />
        </div> */}
      </div>
      <CustomModal
        Header="Are you sure"
        Buttonclick={removeVideo}
        isOpen={!!deleteVideoData}
        Buttonlabel="Yes, Delete it!"
        Closemodal={setDeleteVideoData}
        Content={`Do you want to delete ${deleteVideoData}?`}
      />

      <VideoPriview
        lightTheme
        selectedVideo={openVideoView}
        openVideoPreview={!!openVideoView}
        closePreview={() => setOpenVideoView(false)}
      />

      <EditAssets
        type="video"
        asset={openVideoEdit}
        onHide={() => setOpenVideoEdit('')}
        manipulateTemplateArray={manipulateTemplateArray}
      />
    </>
  );
}
