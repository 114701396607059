import React, { useEffect, useState, useRef } from "react";
import { Modal, ModalBody } from "reactstrap";
import ReactPlayer from "react-player";
import { LinearProgress, Tooltip } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import Videoplayer from "../../components/Videoplayer/Videoplayer";
import { useSelector, useDispatch } from "react-redux";
import { getProjectPreview, renderPreview, createProjects, getClusterDetails } from "../../../services/api.service";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../../store";
import { CreatePreviewFromResponse, removeMediaUrlFilter } from "../../../services/DataHelper";
import { ShowAlertBox, showToaster, getUserItem } from "../../../services/helper";
import LinearWithValueLabel from "../../components/LinearProgressWithLabel";
import { useHistory } from "react-router-dom";
import { getProjectById, getUsersProjectRole, insertRenderProjectDetails } from "../../../services/authService";
export default function RenderProject(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const project = useSelector((state) => state.preview.previewProject);
  const projectId = useSelector((state) => state.preview.ProjectId);
  const [selectedVideo, setselectedVideo] = useState(undefined);
  const [videos, setVideos] = useState(undefined);
  const [slideCount, setSlideCount] = useState([]);
  const [startRender, setstartRender] = useState(false);
  const [renderStatus, setrenderStatus] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [renderPercetange, setRenderPercetange] = useState(undefined);
  const [statusMessage, setstatusMessage] = useState("");
  const [TemplateAvailable, setTemplateAvailable] = useState(true);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [pageLoaded, setPageLoaded] = useState(false);
  let { UpdatePreview } = bindActionCreators(actionCreator, dispatch);
  const timerRef = useRef(null);
  const mounted = useRef(true);
  let orgId = getUserItem("orgId");
  let userId = getUserItem("id");
  let AdminRole = getUserItem("role");
  useEffect(async () => {
    //getPreview(projectId,project)

    if (props.setVideos !== undefined && props.setVideos !== null && props.openRenderVideo && !startRender) {
      // setVideos(props.setVideos);
    }
    if (props.openRenderVideo && !startRender) {
      let projectDatafromDb = await getProjectById(props.projectId);
      var templateStatus = true;
      let arrayValue = [];
      let urlArray;
      console.log(projectDatafromDb.data.data[0], "listUserslistUsers");
      let projectDetails = projectDatafromDb.data.data[0];
      if (projectDetails.renderUrls !== undefined && projectDetails.renderUrls !== null && projectDetails.renderUrls !== "") {
        // renderUrls = projectDatafromDb.data.data[0].renderUrls;
        urlArray = projectDetails.renderUrls;
        if (urlArray !== null && urlArray !== undefined) {
          Object.keys(urlArray).map(function (key, index) {
            Object.keys(urlArray[key]).map(function (k, i) {
              if (key.startsWith("M") && k.startsWith("SL")) {
                if (urlArray[key][k].SlideRender == "1") {
                  arrayValue.push(key + "-" + k);
                  setSlideCount(arrayValue);
                }

                // if (k === slide && key === module) {
                //   slideRenderData[projectId].Modules[
                //     module
                //   ].slides[slide].SlideRender = "0";
                // } else {
                //   slideRenderData[projectId].Modules[
                //     module
                //   ].slides[slide].SlideRender = "1";
                // }
              }
            });
          });
        }
      }

      console.log(props);
      renderProject();
    }
    if (!props.openRenderVideo) {
      clearTimeout(timerRef.current);
    }
  }, [props]);

  useEffect(() => {
    return () => {
      mounted.current = false;
      clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    // console.log(props);
    // if (props != undefined && props != null && props != "") {
    //   if (
    //     props.priviewrenderUrl != undefined &&
    //     props.priviewrenderUrl != "" &&
    //     props.priviewrenderUrl != null
    //   ) {
    //     setselectedVideo(props.priviewrenderUrl[0].url);
    //     console.log(props);
    //   }
    // }
  }, [props]);
  const goToDashboard = () => {
    history.push("/render-list");
  };
  const renderProject = async () => {
    setstartRender(true);
    //setrenderProject(project);

    let ddd = localStorage.getItem("_cluster-details");
    let userDetails = JSON.parse(ddd);
    let projectDatafromDb = await getProjectById(props.projectId);
    console.log(props.renderId, props.projectId, projectDatafromDb, "listUserslistUsers");

    let clusterArray = [];
    let clusterArray1 = [];
    let clusterDetails;
    let random;
    if (props.selectedCluster) {
      clusterDetails = props.selectedCluster;
    } else {
      await getClusterDetails("number_of_render_inprogress").then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            // clusterDetails = response.data.result[0]

            if (response.data.result[0].status === false) {
            }

            let clusterorgId = AdminRole == 0 ? userId : orgId;
            response.data.result.map((details) => {
              if (details.isDelete == 0 && details.status !== "Deleting") {
                if (details.orgId == clusterorgId) {
                  clusterArray.push(details);
                } else if (details.orgId == "All") {
                  clusterArray1.push(details);
                }
              }
            });
            if (clusterArray.length > 0) {
              //  random = Math.floor(Math.random() * clusterArray.length);
              //  clusterDetails=clusterArray[random]
              clusterDetails = clusterArray[0];
            } else if (clusterArray1.length > 0) {
              // random = Math.floor(Math.random() * clusterArray1.length);
              // clusterDetails=clusterArray1[random]
              clusterDetails = clusterArray1[0];
            }

            console.log(response, "responsecluster");
          } else {
            setPageLoaded(false);
            showToaster("Something went wrong. Please Try Again5", "warning");
          }
        } catch (error) {
          console.log(error, "error");
          setPageLoaded(false);
          showToaster("Something went wrong. Please Try Again6", "warning");
        }
      });
    }
    // let verifyTemplateAvailable = await props.checkTemplateAvailable(
    //   JSON.parse(projectDatafromDb.ProjectData),
    //   props.renderId
    // );

    // console.log(modules,"modules");
    // this.projectID = this.props.activeProject.ProjectID

    // setSlideCount(arrayValue)

    // setVideos(props.setVideos);
    let renderFilter = {
      project_id: props.renderId,
      projectName: projectDatafromDb.data.data[0].ProjectName,
      cluster_name: clusterDetails.cluster_name,
      render_cluster_name: clusterDetails.rendercluster_name,
      parallel_process_count: 4
    };
    await renderPreview(renderFilter).then((response) => {
      console.log(response, "renderresponse");
      if (response.status !== undefined && response.status !== null && response.status !== "") {
        // timerRef.current = setTimeout(() => {
        //   getPreview(projectId,project);
        // }, 5000);
        setTemplateAvailable(true);
        getPreview(props.renderId, projectDatafromDb.data.data[0].ProjectName, projectDatafromDb);

        console.log(projectDatafromDb, "projectDatafromDb");
        if (projectDatafromDb.data.data[0].Is_Valid == 1) {
          projectDatafromDb.data.data[0].rendercluster_name = clusterDetails.rendercluster_name;
          let renderOutsideOrg = false;
          if (props.renderOutsideOrg) {
            renderOutsideOrg = true;
          }
          insertRenderProjectDetails(projectDatafromDb.data.data[0], props.renderId, renderOutsideOrg).then((response) => {
            console.log(response, "renderresponse solankii");
            if (response.status !== undefined && response.status !== null && response.status !== "") {
              // timerRef.current = setTimeout(() => {
              //   getPreview(projectId,project);
              // }, 5000);
              console.log(response, "responseresponse");
            } else if (response.message !== undefined && response.message !== null && response.message !== "") {
              // setRenderMessage(response.message);
            } else {
              ShowAlertBox("Oops...", "Something went wrong. Please try again", "error");
            }
          });
        }
      } else if (response.message !== undefined && response.message !== null && response.message !== "") {
        // setRenderMessage(response.message);
      } else {
        ShowAlertBox("Oops...", "Something went wrong. Please try again", "error");
      }
    });
    // if (verifyTemplateAvailable === true) {

    // let updatedProjectDataforDb = JSON.stringify(project)
    // console.log(updatedProjectDataforDb);

    // let projectDataforAPI = await removeMediaUrlFilter(JSON.parse(updatedProjectDataforDb), projectId)
    // console.log(projectDataforAPI);

    let templateValidArray = [];
    // console.log(project.createdProject);
    // let projectDataReq = {
    //   dbdata: {
    //     [projectId]: project[projectId]
    //   },
    //   APIData: {
    //     [projectId]: projectDataforAPI[projectId]
    //   },
    //   type: "Update"
    // }
    // createProjects(JSON.stringify(projectDataReq)).then(async (response) => {
    //   try {
    //     if (response !== "" && response !== undefined && response !== null && response.data !== "" && response.data !== undefined && response.data !== null) {
    //       if (response.status === 200 && response.status !== null && response.status !== null) {
    //         setPageLoaded(false)
    //         showToaster('Project Updated successfully', 'success');
    //         // let getProjectData = await getProjetcDetailsById(projectId)
    //         let projectDatafromDb = await getProjectById(projectId)
    //         if (projectDatafromDb.data.data.length > 0) {
    //           UpdatePreview(JSON.parse(projectDatafromDb.data.data[0].ProjectData))
    //         }

    //       } else {
    //         setPageLoaded(false)

    //         showToaster(response.message, 'warning');
    //       }
    //     } else {
    //       setPageLoaded(false)

    //       showToaster('Something went wrong. Please Try Again', 'warning');
    //     }
    //   } catch (error) {
    //     setPageLoaded(false)
    //     showToaster('Something went wrong. Please Try Again', 'warning');
    //   }
    // })
    // } else {
    //   setTemplateAvailable(false);
    //   showToaster("There is no template input provided!!", "warning");
    // }
  };

  const getPreview = async (ProjectID, ProjectName, projectDatafromDb) => {
    let renderFilter = {
      project_id: ProjectID,
      projectName: ProjectName
    };
    // setPriviewPageLoad(true);
    // setGetRenderVideo(true);

    //setOpenRenderVideo(true);
    getProjectPreview(renderFilter)
      .then(async (response) => {
        if (!props.openRenderVideo) {
          if (mounted && mounted.current) {
            setstartRender(false);
          }
          return;
        }
        console.log(response, "apiStatus");
        if (
          response.data !== undefined &&
          response.data !== null &&
          response.data !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          (response.status === 200 || response.status === 500)
        ) {
          // if (response.apiStatus === false) {
          // }
          //console.log(response, "111111111111");
          if (response.data.is_valid === 0) {
            //alert("in valid");
            // close network
            setstartRender(false);
            props.closePreview();
            showToaster("There is no valid data for rendering the project!!", "warning");
          } else if (response.apiStatus === false) {
            // renderProject()
            setstartRender(false);
            props.closePreview();
            showToaster("Error, please try rendering video again", "warning");
          } else if (response.message == "Error, please try rendering video again") {
            setstartRender(false);
            props.closePreview();
            showToaster("Error, please try rendering video again", "warning");
          } else if (response.apiStatus === false) {
            setstartRender(false);
            props.closePreview();
            showToaster("Error, please try rendering video again", "warning");
            if (mounted && mounted.current) {
              setstartRender(false);
            }
            return;
          } else {
            if (
              response.data.mediaurl !== undefined &&
              response.data.mediaurl !== null &&
              response.data.mediaurl !== "" &&
              Object.keys(response.data.mediaurl).length !== 0
            ) {
              setRenderPercetange(response.data.renderstatus);
              if (response.data.mediaurl.status == "server_error, please try again!") {
                setServerError(true);
                setrenderStatus(false);
                if (mounted && mounted.current) {
                  setstartRender(false);
                }
                return;
              }
              if (response.data.mediaurl.status == "Server error! Please check your quota limits.") {
                setServerError(true);
                setrenderStatus(false);
                if (mounted && mounted.current) {
                  setstartRender(false);
                }
                return;
              }
              if (response.data.mediaurl.status === "Rendering completed") {
                setrenderStatus(true);
                // setrenderStatus(
                //   Number(
                //     response.data.renderstatus.substring(
                //       0,
                //       response.data.renderstatus.length - 1
                //     )
                //   )
                // );
                //setPriviewPageLoad(false);
                //setGetRenderVideo(false);
                var arrayValue = [];
                let mediaurl = response.data.mediaurl;
                let urlArray;
                let projectDatafromDb = await getProjectById(props.projectId);
                let projectDetails = projectDatafromDb.data.data[0];
                if (projectDetails.renderUrls !== undefined && projectDetails.renderUrls !== null && projectDetails.renderUrls !== "") {
                  // renderUrls = projectDatafromDb.data.data[0].renderUrls;
                  urlArray = projectDetails.renderUrls;
                  if (urlArray !== null && urlArray !== undefined) {
                    Object.keys(urlArray).map(function (key, index) {
                      Object.keys(urlArray[key]).map(function (k, i) {
                        if (key.startsWith("M") && k.startsWith("SL")) {
                          if (urlArray[key][k].SlideRender == "1") {
                            console.log(urlArray[key][k], "listUserslistUsers");
                            mediaurl[key][k] = urlArray[key][k];
                          }
                        }
                      });
                    });
                  }
                }

                console.log(mediaurl, "listUserslistUsers");
                Object.keys(mediaurl).map(function (key, index) {
                  Object.keys(mediaurl[key]).map(function (k, i) {
                    if (mediaurl[key][k].SlideRender === "1") {
                      arrayValue.push(mediaurl[key][k]);
                    }
                  });
                });

                if (arrayValue.length > 0) {
                  if (arrayValue !== undefined && arrayValue !== null && arrayValue !== "") {
                    console.log(arrayValue);
                    setVideos(arrayValue);
                  }
                }
              } else {
                timerRef.current = setTimeout(() => {
                  getPreview(ProjectID, project);
                }, 5000);
              }
            } else if (response.message !== undefined && response.message !== null && response.message !== "") {
              timerRef.current = setTimeout(() => {
                getPreview(ProjectID, project);
              }, 5000);
            } else {
              if (response.message === "string index out of range") {
                // setPriviewPageLoad(false);
                // this.setState({ templateErrorMsg: 'You have provided Insufficient data to generate Preview. Please add sufficient data'})
              }
              // this.setState({
              //     openRenderModal: false
              // }, () => {
              //     this.setState({ openTemplateError: true })
              // })
            }
          }
        }
      })
      .catch(console.log);
  };
  const closeModal = () => {
    setServerError(false);
    setstartRender(false);
    props.closePreview();
    props.refreshPage();

    setrenderStatus(false);
  };
  return (
    <Modal
      isOpen={props.openRenderVideo}
      fade={true}
      className={
        DisplayMode
          ? "modal-dialog  modal-dialog-centered custom-modal preview-modal dark"
          : "modal-dialog  modal-dialog-centered custom-modal preview-modal"
      }
      // className="modal-dialog  modal-dialog-centered custom-modal preview-modal"
      centered
    >
      <ModalBody>
        {/* <div className="modal fade" id="preview-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered custom-modal preview-modal">
                        <div className="modal-content border-0">
                            <div className="modal-body"> */}

        <div
          className="preview-modal-close"
          data-bs-dismiss="modal"
          onClick={() => {
            setVideos(undefined);
            setRenderPercetange(undefined);
            closeModal();
          }}
        >
          <Tooltip title="Close" arrow>
            <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g data-name="Layer 2">
                <g data-name="close">
                  <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
                  <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                </g>
              </g>
            </svg>
          </Tooltip>
        </div>
        <div>
          {/* {TemplateAvailable ? ( */}
          <div>
            <>
              {videos != undefined && videos[0].url !== undefined && videos.length > 0 ? (
                <Videoplayer thumbnailUrl="" videoUrl={selectedVideo !== undefined ? selectedVideo : videos[0].url} showVolume={true} />
              ) : (
                <>
                  {/* <CircularProgress
                    color="inherit"
                    size="2rem"
                    style={{ position: "absolute", left: "50%", top: "50%",color:'aliceblue' }}
                  /> */}
                  <Videoplayer thumbnailUrl="../../../assets/img/loader.gif" videoUrl={""} showVolume={true} disabled={true} />
                </>
              )}
            </>
            {/* <ReactPlayer
                url={selectedVideo}
                controls
                width="100%"
                muted={true}
                playing={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "menu",
                    },
                  },
                }}
              /> */}
            {/* {videos != undefined && videos.length > 0 ? (
              <div className="video-control">
                <div className="slide-wrap">
                  <ul className="slide-inner">
                    {videos.map((video, i) => {
                      console.log(video);
                      return (
                        <li
                          className="active"
                          onClick={() => setselectedVideo(video.url)}
                        >
                          <div className="img-wrap">
                            <ReactPlayer
                              url={video.url}
                              controls={false}
                              width="100%"
                              height=""
                              muted={true}
                              playing={false}
                            />
                            <h3>
                              SL{Number(i + 1) <= 9 ? "0" + (i + 1) : i + 1}
                            </h3>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) :  */}
            {pageLoaded ? (
              <div className="loader-wrapper video-loader">
                <div className="loader-inner">
                  <img src="../../../assets/img/loader.gif"></img>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="video-control">
              <div className="slide-wrap">
                <ul className="slide-inner">
                  {slideCount !== undefined &&
                    slideCount.length > 0 &&
                    slideCount.map((video, i) => {
                      return (
                        <li className={videos !== undefined && videos[i] !== undefined ? selectedVideo == videos[i].url && "active" : "not-active"}>
                          {videos !== undefined && videos[i] !== undefined && <h3 className="mb-2">{video}</h3>}
                          <div
                            className="img-wrap"
                            onClick={() => {
                              videos[i].url !== "" && setselectedVideo(videos[i].url);
                            }}
                          >
                            {videos !== undefined && videos[i] !== undefined && videos[i].url !== "" ? (
                              <>
                                <ReactPlayer
                                  url={videos !== undefined && videos[i] !== undefined ? videos[i].url : ""}
                                  controls={false}
                                  width="100%"
                                  height=""
                                  muted={true}
                                  playing={false}
                                />
                                {/* <h3>{video}</h3> */}
                              </>
                            ) : (
                              <>
                                {videos !== undefined && videos[i] !== undefined && videos[i].url == "" ? (
                                  <>
                                    <span className="hover-text">Rendering crashed</span>
                                    <Videoplayer
                                      videoUrl="../../../assets/img/videobutton.png"
                                      // thumbnailUrl={props.thumbnailUrl[i]}
                                    />
                                    <h3>{video} </h3>
                                  </>
                                ) : (
                                  <>
                                    <span className="hover-text">Rendering in queue</span>
                                    <Videoplayer
                                      videoUrl="../../../assets/img/videobutton.png"
                                      //  thumbnailUrl={props.thumbnailUrl[i]}
                                    />
                                    <h3>{video} </h3>
                                  </>
                                )}
                                {/* {props.moduleCount.map((module, i) => {
                                      console.log(module);
                                      return (
                                        <h3>
                                          {module} 
                                        </h3>)
                                    })
                                    } */}
                                {/* <CircularProgress
                                    size="2rem"
                                    color="inherit"
                                  /> */}
                              </>
                            )}
                          </div>
                        </li>
                      );
                    })}
                </ul>
                <div className="d-flex align-items-center rendernotify">
                  <span>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dumm
                  </span>
                  {renderPercetange !== undefined && !serverError ? (
                    <button
                      type="button"
                      className="btn btn-info rounded-0 me-3"
                      // onClick={goToDashboard}
                    >
                      {renderPercetange}
                    </button>
                  ) : (
                    serverError == true && (
                      <Tooltip title="Server_error, please try again!" arrow>
                        <button
                          type="button"
                          className="btn btn-secondary rounded-0 me-3"
                          // onClick={goToDashboard}
                        >
                          <span className="warning-btns uncheck-btn-sm me-2">
                            <svg
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 106.031 106.031"
                              space="preserve"
                            >
                              <g>
                                <path
                                  d="M53.015,76.703c-5.815,0-10.528-4.712-10.528-10.528V10.528C42.487,4.714,47.201,0,53.015,0
                                                              c5.813,0,10.528,4.714,10.528,10.528v55.647C63.544,71.991,58.83,76.703,53.015,76.703z"
                                />
                                <circle cx="52.34" cy="97.007" r="9.024" />
                              </g>
                            </svg>
                          </span>{" "}
                          Error
                        </button>
                      </Tooltip>
                    )
                  )}
                  <button
                    type="button"
                    className="btn btn-primary rounded-0"
                    onClick={() => {
                      // setstartRender(false);
                      goToDashboard();
                      setVideos([]);
                      setrenderStatus(false);
                      setRenderPercetange(undefined);
                      setServerError(false);
                    }}
                  >
                    Dashboard
                  </button>
                </div>
                {!renderStatus && <LinearProgress style={{ marginTop: "10px" }} />}
              </div>
            </div>
            {/* } */}
            {/* <LinearWithValueLabel progress={renderStatus} /> */}
          </div>
          {/* ) : (
              <span className="invalid-msg blink">
                There are no templates matching input provided
              </span>
            )} */}
          {/* ) : (
              <p>Video URL is not available now! Please render again.</p>
            )} */}
        </div>
        {/* <div className="video-control">
                                    <div className="rSlider">
                                        <span className="slide"></span>
                                        <input id="range" type="range" min="0" max="50000" />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <a href="" className="play-btn me-3">
                                                <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 0V14L11 7L0 0Z" fill="" />
                                                </svg>
                                            </a>
                                            <a href="" className="play-btn me-3">
                                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.5 8C13.5 6.23 12.48 4.71 11 3.97V12.02C12.48 11.29 13.5 9.77 13.5 8ZM0 5V11H4L9 16V0L4 5H0Z" fill="" />
                                                </svg>
                                            </a>
                                            <div className="time-duration">
                                                <span>0:00 </span>/<span> 4:00</span>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}

        {/* </div>
                        </div>
                    </div>
                </div> */}
      </ModalBody>
    </Modal>
  );
}
