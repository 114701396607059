function AssetsStatusBox(props) {
  const { name, icon, size, type, onClick } = props;
  return (
    <button role="button" className={`assets-status-box ${props.className || ''}`} onClick={onClick}>
      <div className="files-wrap">
        {icon}
        {name && <span className="file-name">{name}</span>}
      </div>
      <div className="detail-wrap">
        {size && <span className="files-size">{size}</span>}
        {/* {type && <span className="files-type">{type}</span>} */}
      </div>
    </button>
  );
}
export default AssetsStatusBox;
