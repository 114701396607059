import { useRef, useState } from 'react';

export default function useDebounce() {
  let timeout = useRef();
  const [debouncedValue, setDebouncedValue] = useState(null); /* Keep it null explicitely */

  const debounce = (value, delay = 500) => {
    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
  };

  return { debounce, debouncedValue };
}
