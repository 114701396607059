import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import jwt from "jwt-simple";
import PhoneInput from "react-phone-input-2";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { showToaster, getUserItem, decodeEncodedItem } from "../../../services/helper";

import Header from "../../components/header/header";
import timezones from "timezones-list";
import { getOrgUsersById, updateOrgnaizationUsers } from "../../../services/authService";

const Profile = () => {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [listtimezone, setListtimezone] = useState({
    value: "TimeZone",
    label: "TimeZone"
  });
  const [timeZoneOptions, setTimeZoneOptions] = useStateWithCallbackLazy();
  const [userDetails, setUserDetails] = useState();
  const [loadUpdate, setLoadUpdate] = useState(false)
  let userId = getUserItem("id");
  let AdminRole = getUserItem("role");

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const getUserDetails = () => {
    getOrgUsersById(userId).then((response) => {
      try {
        if (response.data) {
          if (response.data.result !== undefined) {
            let details = response.data.result.data;
            setUserDetails(details);
          }
        } else {
          showToaster("Something went wrong. Please Try Again1", "warning");
        }
      } catch (error) {
        console.log(error);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const [phone, setPhone] = useState(userDetails?.countrycode ? `${userDetails?.countrycode}` : "+91");
  
  useEffect(() => {
    if(userDetails?.countrycode) {
      setPhone(`${userDetails?.countrycode}`);
    }
  }, [userDetails?.countrycode]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userDetails?.email,
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      phonenumber: userDetails?.phonenumber,
      Address: userDetails?.Address,
      orgName:userDetails?.orgName ?? userDetails?.email,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().trim().email("Email is invalid").min(2, "Too Short!").max(50, "Too Long!").required("Email is required"),
      firstName: Yup.string()
        .trim()
        .max(20, "Must be exactly 20 characters")
        .min(1, "Minimum 1 characters are required")
        .required("Name is required"),
      lastName: Yup.string()
        .trim()
        .max(20, "Must be exactly 20 characters")
        .min(1, "Minimum 1 characters are required")
        .required("Last Name is required"),
      phonenumber: Yup.string().trim().required("Phone Number is required").matches(phoneRegExp, "Phone number is not valid"),
      Address: Yup.string()
        .trim()
        .max(100, "Must be exactly 100 characters")
        .min(1, "Minimum 1 characters are required")
        .required("Address is required"),
      orgName: Yup.string()
        .trim()
        .max(20, "Must be exactly 20 characters")
        .min(1, "Minimum 1 characters are required")
        .required("Organisation name is required"),
    }),
    onSubmit: (values) => {
      values.countrycode = phone;
      values.timeZone = listtimezone.value;
      try {
        setLoadUpdate(true)
        updateOrgnaizationUsers(userId, { userdata: values, type: "user" }).then((response) => {
          if (response.data.result) {
            let userSessionData = decodeEncodedItem(localStorage.getItem("_olive_user"));
            let loggedinUserDetails = jwt.encode({ ...userSessionData, ...response.data.result }, process.env.REACT_APP_JWT_SECRET, "HS512");
            window.localStorage.setItem("_olive_user", JSON.stringify(loggedinUserDetails));
            showToaster("Profile updated successfully", "success");
            setLoadUpdate(false)
          } else {
            showToaster(response.message, "warning");
            setLoadUpdate(false)
          }
        })
      } catch (e) {
        showToaster("Something went wrong. Please Try Again", "warning");
        setLoadUpdate(false)
      } finally {
        setLoadUpdate(false)
      }

    }
  });

  useEffect(() => {
    let ddd;
    timezones.map((data) => {
      ddd = data;
    });
    setListtimezone({
      value: ddd.tzCode,
      label: ddd.label
    });
  }, []);

  useEffect(() => {
    generateTimeZones();
  }, [listtimezone]);

  const generateTimeZones = () => {
    if (listtimezone !== undefined) {
      let timeZoneOption = [];
      timezones.map((timeZone) => {
        timeZoneOption.push({
          value: timeZone.tzCode,
          label: timeZone.label
        });
        return true;
      });
      setTimeZoneOptions(timeZoneOption);
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "gray",
      padding: 7
    }),
    control: () => ({
      display: "flex",
      backgroundColor: DisplayMode ? "#2e2e2e" : "#f0efef",
      color: "#fff",
      height: "32px"
    }),
    singleValue: () => ({
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "12px",
      lineHeight: "18px"
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      color: "#fff"
    }),
    indicatorSeparator: () => ({
      border: "none"
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px"
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff"
    })
  };

  const handleChange = async (e) => {
    setListtimezone(e);
  };

  const handleCodeChange = (phone) => {
    setPhone(phone);
  };
console.log(phone, 'phone');

  return (
    <div className={DisplayMode ? "theme dark" : "theme"}>
      <div className="main-wrapper">
        <Header />

        <form onSubmit={formik.handleSubmit} id="login-form" className="pb-wrapper">
          <div className="form-body">
            <h2 className="text-center mb-3">Edit Profile</h2>
            <div className="mb-3">
              <label for="" className="form-label">
                Select TimeZone
              </label>
              <Select
                className="custom-input rolluser w-100 custom-selects "
                styles={customStyles}
                value={listtimezone ?? userDetails?.timeZone}
                onChange={(e) => handleChange(e)}
                options={timeZoneOptions}
              />
            </div>

            <div className="mb-3 d-flex flex-column">
              <label for="" className="form-label">
                First Name
              </label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName ?? userDetails?.firstName ?? userDetails?.name}
                className="custom-input"
                placeholder="Enter your first name"
              />{" "}
              {formik.touched.firstName && formik.errors.firstName ? <div className="input-error-msg">{formik.errors.firstName}</div> : null}
            </div>
            <div className="mb-3 d-flex flex-column">
              <label for="" className="form-label">
                Last Name
              </label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName ?? userDetails?.lastName}
                className="custom-input"
                placeholder="Enter your Last Name"
              />{" "}
              {formik.touched.lastName && formik.errors.lastName ? <div className="input-error-msg">{formik.errors.lastName}</div> : null}
            </div>

            <div className="mb-3 d-flex flex-column">
              <label for="" className="form-label">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email ?? userDetails?.email}
                className="custom-input"
                placeholder="Enter your Email"
                disabled={true}
              />
            </div>
            {formik.touched.email && formik.errors.email ? <div className="input-error-msg">{formik.errors.email}</div> : null}

            <div className="custom-input-holder">
              <div className="custom-label">Phone Number</div>

              <div className="custom-input-container position-relative">
                <div className="d-flex">
                  <PhoneInput enableSearch={true} value={phone ?? userDetails?.countrycode} onChange={(phone) => handleCodeChange(phone)} />
                  <input
                    id="phonenumber"
                    name="phonenumber"
                    type="phoneNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phonenumber ?? userDetails?.phonenumber}
                    className="custom-input"
                    placeholder="Enter your Phone Number"
                  />
                </div>
              </div>
              {formik.touched.phonenumber && formik.errors.phonenumber ? <div className="input-error-msg">{formik.errors.phonenumber}</div> : null}
            </div>
            <div className="mb-3 d-flex flex-column">
              <label for="" className="form-label">
                Address
              </label>
              <input
                id="Address"
                name="Address"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Address ?? userDetails?.Address}
                className="custom-input"
                placeholder="Enter your address"
              />{" "}
              {formik.touched.Address && formik.errors.Address ? <div className="input-error-msg">{formik.errors.Address}</div> : null}
            </div>
            {
            AdminRole === 0 ?
            <div className="mb-3 d-flex flex-column">
              <label for="" className="form-label">
                Organisation Name
              </label>
              <input
                id="orgName"
                name="orgName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.orgName ?? userDetails?.orgName}
                className="custom-input"
                placeholder="Enter your orgnaisation name"
              />{" "}
              {formik.touched.orgName && formik.errors.orgName ? <div className="input-error-msg">{formik.errors.orgName}</div> : null}
            </div> : null
            }
            <div className="d-flex justify-content-end w-100 footer-btn flex-wrap mt-4">
              <button
                type="button"
                onClick={() => {
                  {
                    formik.values.email = "";
                  }
                  {
                    formik.errors.email = false;
                  }
                  {
                    formik.touched.email = false;
                  }
                  {
                    formik.values.firstName = "";
                  }
                  {
                    formik.errors.firstName = false;
                  }
                  {
                    formik.touched.firstName = false;
                  }
                  {
                    formik.values.lastName = "";
                  }
                  {
                    formik.errors.lastName = false;
                  }
                  {
                    formik.touched.lastName = false;
                  }
                }}
                className="btn btn-outline rounded-0 me-2"
                data-bs-dismiss="modal">
                CANCEL
              </button>
              <button type="submit" className="btn btn-primary  rounded-0" disabled={loadUpdate}>
               {loadUpdate ? 'Updating...' : 'Update Profile'} 
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
