import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { getRenderErrorDetails } from "../../../services/authService";
import { ShowAlertBox } from "../../../services/helper";
import PageLoader from "../../../services/pageLoader";
import { useSelector } from "react-redux";

export default function Errormodal(props) {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [pageLoad, setPageLoad] = useState(false);
  const [errorData, setErrorData] = useState(undefined);
  const getRenderErrorData = () => {
    getRenderErrorDetails(props.renderProjectId).then((response) => {
      try {
        setPageLoad(true);
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          setPageLoad(false);
          setErrorData(response.data.result);
        } else {
          setPageLoad(false);
          ShowAlertBox(
            "Oops...",
            "Something went wrong. Please Try Again1",
            "error"
          );
        }
      } catch (error) {
        console.log(error);
        setPageLoad(false);
        ShowAlertBox(
          "Oops...",
          "Something went wrong. Please Try Again2",
          "error"
        );
      }
    });
  };
  useEffect(() => {
    getRenderErrorData();
  }, [props.show]);
  return (
    <React.Fragment>
      <PageLoader showLoader={pageLoad} />
      <Modal
        isOpen={props.show}
        fade={true}
        centered
        className={
          DisplayMode
            ? "modal-dialog  modal-dialog-centered custom-modal dark render-error-modal modal-lg dark"
            : "modal-dialog  modal-dialog-centered custom-modal dark render-error-modal modal-lg"
        }
      >
        <ModalBody>
          <div className="text-center mb-4">
            <h3 className="text-uppercase font-weight-bold">
              Render Error Details
            </h3>
          </div>

          <div className="table-wraper render-error">
            <div className="pb-tbl-blk">
              <div className="pb-tbl-wrapper sticky-head  mb-3">
                <div className="pb-head-label"> Date&Time</div>
                <div className="pb-head-label ">Render Error Logs</div>
              </div>
              {errorData !== undefined &&
                errorData.map((error) => {
                  let sortedCreatedDate;
                  if (
                    error.renderErrorDate !== undefined
                  ) {
                    let Created = error.renderErrorDate;
                    sortedCreatedDate = Created.split("T")[0];
                    let sortedCreatedTime = Created.split("T")[0];
                  } else {
                    sortedCreatedDate = "no date found";
                  }

                  return (
                    <div className="pb-tbl-wrapper pb-data-tbl">
                      <div className="pb-data-label">
                        {sortedCreatedDate}/
                        {error.errorTime !== undefined
                          ? error.errorTime
                          : "no render time"}
                      </div>
                      <div className="pb-data-label">{error.errorLogs}</div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="d-flex justify-content-center w-100 footer-btn flex-wrap mt-4">
            <button
              type="button"
              onClick={() => props.closemodal(false)}
              className="btn btn-outline rounded-0 me-2"
              data-bs-dismiss="modal"
            >
              CLOSE
            </button>

            {/* </div>
          </div>
        </div>
      </div> */}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
