import React, { useState } from 'react';

function CustomSwitchSlider(props) {

  const toggleSwitch = () => {
    props.onChange(!props.isChecked, props.inputName);
  };

  return (
    <>
      <label className="custom-switch-label">
        <input type="checkbox" checked={props.isChecked} onChange={toggleSwitch} />
        <span className={`custom-switch-slider ${props.isChecked ? 'checked' : ''}`}>
          <span className="circle" />
          <span className="label-text">
            <span className="on">ON</span>
            <span className="off">OFF</span>
          </span>
        </span>
      </label>
    </>
  );
}

export default CustomSwitchSlider;
