import React from "react";
import ReactImageFallback from "react-image-fallback";
import { Modal, ModalBody } from "reactstrap";
import Tooltip from "@mui/material/Tooltip";
export default function ImagePriview(props) {
  return (
    <Modal
      isOpen={props.openImagePreview}
      fade={true}
      centered
      className="modal-dialog  modal-dialog-centered custom-modal view-modal dark"
    >
      <ModalBody>
        {/* <div className="modal fade" id="view-img" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}

        <div
          id="view-img"
          tabindex="-1"
          //   className="modal-dialog  modal-dialog-centered custom-modal view-modal"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-content border-0">
            <div className="modal-body">
              <div
                className="close-priview"
                onClick={() => props.closePreview()}
              >  <Tooltip title="Close" arrow>
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="close">
                      <rect
                        width="24"
                        height="24"
                        transform="rotate(180 12 12)"
                        opacity="0"
                      />
                      <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                    </g>
                  </g>
                </svg></Tooltip>
              </div>
              {props.selectedImage !== undefined &&
              props.selectedImage !== null &&
              props.selectedImage !== "" ? (
                <div className="img-block">
                  {/* <img className="object-cover" src={props.selectedImage} /> */}
                  <ReactImageFallback
                    src={props.selectedImage}
                    fallbackImage="../../../assets/img/fallbackImage.jpg"
                    initialImage="../../../assets/img/fallbackImage.jpg"
                    alt="templates"
                    // className="img-block"
                  />
                </div>
              ) : (
                <React.Fragment>
                  <div className="img-block"><p>No image preview available</p></div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
      </ModalBody>
    </Modal>
  );
}
