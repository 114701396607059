import {
  BgColor,
  BULLETFONT,
  FONT_SIZE_H,
  FONT_SIZE_P,
  FONTFAMILY,
  HEADERFONT,
  INIT_OST_FONT,
  PARAGRAPHFONT,
  TextColor,
} from '../constants';

export function CreateProjectModel(
  projectId,
  projectName,
  CategoryId,
  NotificationURL,
  init_font = INIT_OST_FONT,
  fontH = FONT_SIZE_H,
  fontP = FONT_SIZE_P
) {
  return new Promise((resolve, reject) => {
    resolve({
      [projectId]: {
        ProjectName: projectName,
        CategoryID: CategoryId,
        NotificationURL: NotificationURL,
        Modules: {
          M01: {
            ModuleName: 'Module 1',
            slides: {
              SL01: {
                SlideName: 'Video 1',
                SlideAudioVO: 'Null',
                SlideRender: '1',
                Screens: {
                  'SC01.01': {
                    ScreenName: 'Screen 01',
                    BG01: {
                      Contenttype: 'Background',
                      Contentparams: {
                        sequenceid: '1',
                        contentdescription: '#2066a2',
                        contenttag: 'Null',
                      },
                    },
                    AS01: {
                      Contenttype: 'AudioScript',
                      Contentparams: {
                        sequenceid: '2',
                        contentdescription: '',
                        contenttag: '',
                      },
                    },
                    IM01: {
                      Contenttype: 'Image',
                      Contentparams: {
                        sequenceid: '3',
                        contentdescription: '',
                        searchstring: '',
                        contenttag: '',
                      },
                    },
                    // OS01: {
                    //   Contenttype: 'OST',
                    //   Contentparams: {
                    //     sequenceid: '4',
                    //     contentdescription: '',
                    //     contenttag: '',
                    //     FontFamily: init_font,
                    //     FontSizeH: fontH,
                    //     FontSizeP: fontP,
                    //     FontColour: TextColor,
                    //     fontTag: HEADERFONT,
                    //   },
                    // },
                    IL01: {
                      Contenttype: 'Illustration',
                      Contentparams: {
                        sequenceid: '5',
                        contentdescription: '',
                        searchstring: '',
                        contenttag: '',
                      },
                    },
                    TM01: {
                      Contenttype: 'TemplateID',
                      Contentparams: {
                        contenttag: 'Null',
                        sequenceid: '16',
                        TemplateType: 'motion_graphics',
                        contentdescription: 'no template',
                        sourcescreen: '',
                        useTemplateStatus: false,
                      },
                    },
                    CS01: {
                      Contenttype: 'ColourScheme',
                      Contentparams: {
                        sequenceid: '8',
                        contentdescription: BgColor,
                        contenttag: 'Null',
                      },
                    },
                    CS02: {
                      Contenttype: 'ColourScheme',
                      Contentparams: {
                        sequenceid: '9',
                        contentdescription: '#808080',
                        contenttag: 'Null',
                      },
                    },
                    SD01: {
                      Contenttype: 'ScreenDuration',
                      Contentparams: {
                        contenttag: 'Null',
                        sequenceid: '10',
                        contentdescription: '7',
                      },
                    },
                    VD01: {
                      Contenttype: 'Video',
                      Contentparams: {
                        contenttag: '',
                        sequenceid: '11',
                        contentdescription: '',
                        searchstring: '',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    });
  });
}

export function CreateProjectModelforChatGPT(
  projectId,
  projectName,
  CategoryId,
  NotificationURL,
  moduleId = 'M01',
  slideId = 'SL01'
) {
  return new Promise((resolve, reject) => {
    resolve({
      [projectId]: {
        ProjectName: projectName,
        CategoryID: CategoryId,
        NotificationURL: NotificationURL,
        Modules: {
          [moduleId]: {
            ModuleName: `Module ${moduleId.split('M0')[1]}`,
            slides: {
              [slideId]: {
                SlideName: `Video ${slideId.split('SL0')[1]}`,
                SlideAudioVO: 'Null',
                SlideRender: '1',
                Screens: {},
              },
            },
          },
        },
      },
    });
  });
}

export function addScreenforChatGPT(
  projectId,
  projectData,
  screenId,
  screenName,
  ost,
  videoTags,
  moduleId = 'M01',
  slideId = 'SL01'
) {
  return new Promise((resolve, reject) => {
    let screens = projectData[projectId].Modules[moduleId].slides[slideId].Screens;
    screens = {
      ...screens,
      [screenId]: {
        ScreenName: screenName,
        AS01: {
          Contenttype: 'AudioScript',
          Contentparams: {
            sequenceid: '1',
            contentdescription: ost,
            contenttag: 'Null',
          },
        },
        BG01: {
          Contenttype: 'Background',
          Contentparams: {
            sequenceid: '2',
            contentdescription: '#2066a2',
            contenttag: 'Null',
          },
        },
        CS01: {
          Contenttype: 'ColourScheme',
          Contentparams: {
            sequenceid: '3',
            contentdescription: BgColor,
            contenttag: 'Null',
          },
        },
        CS02: {
          Contenttype: 'ColourScheme',
          Contentparams: {
            sequenceid: '4',
            contentdescription: '#808080',
            contenttag: 'Null',
          },
        },
        OS01: {
          Contenttype: 'OST',
          Contentparams: {
            sequenceid: '5',
            contentdescription: ost + '[P]',
            contenttag: '',
            FontFamily: INIT_OST_FONT,
            FontSizeH: FONT_SIZE_H,
            FontSizeP: FONT_SIZE_P,
            FontColour: TextColor,
          },
        },
        SD01: {
          Contenttype: 'ScreenDuration',
          Contentparams: {
            contenttag: 'Null',
            sequenceid: '6',
            contentdescription: '7',
          },
        },

        VD01: {
          Contenttype: 'Video',
          Contentparams: {
            contenttag: videoTags,
            sequenceid: '7',
            contentdescription: '',
            searchstring: '',
          },
        },
        TM01: {
          Contenttype: 'TemplateID',
          Contentparams: {
            contenttag: 'Null',
            sequenceid: '8',
            TemplateType: 'motion_graphics',
            contentdescription: 'no template',
            sourcescreen: '',
          },
        },
      },
    };
    projectData[projectId].Modules[moduleId].slides[slideId].Screens = screens;
    resolve(projectData);
  });
}

export const CreateModule = (
  moduleId,
  moduleName,
  FontUrl,
  FontHSize,
  FontPSize,
  TextColor,
  BgColor
) => {
  return new Promise((resolve, reject) => {
    resolve({
      [moduleId]: {
        ModuleName: 'Module ' + moduleName,
        slides: {
          SL01: {
            SlideName: 'Video 1',
            SlideAudioVO: 'Null',
            SlideRender: '1',
            Screens: {
              'SC01.01': {
                ScreenName: 'Screen 01',
                BG01: {
                  Contenttype: 'Background',
                  Contentparams: {
                    sequenceid: '1',
                    contentdescription: '#2066a2',
                    contenttag: 'Null',
                  },
                },
                AS01: {
                  Contenttype: 'AudioScript',
                  Contentparams: {
                    sequenceid: '2',
                    contentdescription: '',
                    contenttag: '',
                  },
                },
                IM01: {
                  Contenttype: 'Image',
                  Contentparams: {
                    sequenceid: '3',
                    contentdescription: '',
                    searchstring: '',
                    contenttag: '',
                  },
                },
                // OS01: {
                //   Contenttype: 'OST',
                //   Contentparams: {
                //     sequenceid: '4',
                //     contentdescription: '',
                //     contenttag: '',
                //     FontFamily: FontUrl,
                //     FontSizeH: FontHSize,
                //     FontSizeP: FontPSize,
                //     FontColour: TextColor,
                //     fontTag: HEADERFONT,
                //   },
                // },
                IL01: {
                  Contenttype: 'Illustration',
                  Contentparams: {
                    sequenceid: '5',
                    contentdescription: '',
                    contenttag: '',
                    searchstring: '',
                  },
                },
                TM01: {
                  Contenttype: 'TemplateID',
                  Contentparams: {
                    contenttag: 'Null',
                    sequenceid: '16',
                    TemplateType: 'motion_graphics',
                    contentdescription: 'no template',
                    useTemplateStatus: false,
                    sourcescreen: '',
                  },
                },
                CS01: {
                  Contenttype: 'ColourScheme',
                  Contentparams: {
                    sequenceid: '8',
                    contentdescription: BgColor,
                    contenttag: 'Null',
                  },
                },
                CS02: {
                  Contenttype: 'ColourScheme',
                  Contentparams: {
                    sequenceid: '9',
                    contentdescription: '#808080',
                    contenttag: 'Null',
                  },
                },
                SD01: {
                  Contenttype: 'ScreenDuration',
                  Contentparams: {
                    contenttag: 'Null',
                    sequenceid: '10',
                    contentdescription: '',
                  },
                },
                VD01: {
                  Contenttype: 'Video',
                  Contentparams: {
                    contenttag: '',
                    sequenceid: '11',
                    contentdescription: '',
                    searchstring: '',
                  },
                },
              },
            },
          },
        },
      },
    });
  });
};

export const CreateSilde = (
  SlideId,
  SlideName,
  ScreenName,
  FontUrl,
  FontHSize,
  FontPSize,
  TextColor,
  BgColor
) => {
  return new Promise((resolve, reject) => {
    resolve({
      [SlideId]: {
        SlideName: 'Video ' + SlideName,
        SlideAudioVO: 'Null',
        SlideRender: '1',
        Screens: {
          [`SC${ScreenName}.01`]: {
            ScreenName: 'Screen 01',
            BG01: {
              Contenttype: 'Background',
              Contentparams: {
                sequenceid: '1',
                contentdescription: '#2066a2',
                contenttag: 'Null',
              },
            },
            AS01: {
              Contenttype: 'AudioScript',
              Contentparams: {
                sequenceid: '2',
                contentdescription: '',
                contenttag: '',
              },
            },
            IM01: {
              Contenttype: 'Image',
              Contentparams: {
                sequenceid: '3',
                contentdescription: '',
                searchstring: '',
                contenttag: '',
              },
            },
            // OS01: {
            //   Contenttype: 'OST',
            //   Contentparams: {
            //     sequenceid: '4',
            //     contentdescription: '',
            //     contenttag: '',
            //     FontFamily: FontUrl,
            //     FontSizeH: FontHSize,
            //     FontSizeP: FontPSize,
            //     FontColour: TextColor,
            //     fontTag: HEADERFONT,
            //   },
            // },
            IL01: {
              Contenttype: 'Illustration',
              Contentparams: {
                sequenceid: '5',
                contentdescription: '',
                contenttag: '',
                searchstring: '',
              },
            },
            TM01: {
              Contenttype: 'TemplateID',
              Contentparams: {
                contenttag: 'Null',
                sequenceid: '16',
                TemplateType: 'motion_graphics',
                contentdescription: 'no template',
                useTemplateStatus: false,
                sourcescreen: '',
              },
            },
            CS01: {
              Contenttype: 'ColourScheme',
              Contentparams: {
                sequenceid: '8',
                contentdescription: BgColor,
                contenttag: 'Null',
              },
            },
            CS02: {
              Contenttype: 'ColourScheme',
              Contentparams: {
                sequenceid: '9',
                contentdescription: '#808080',
                contenttag: 'Null',
              },
            },
            SD01: {
              Contenttype: 'ScreenDuration',
              Contentparams: {
                contenttag: 'Null',
                sequenceid: '10',
                contentdescription: '',
              },
            },
            VD01: {
              Contenttype: 'Video',
              Contentparams: {
                contenttag: '',
                sequenceid: '11',
                contentdescription: '',
                searchstring: '',
              },
            },
          },
        },
      },
    });
  });
};

export const CreateScreen = (
  newScreenId,
  screenName,
  FontUrl,
  FontHSize,
  FontPSize,
  TextColor,
  BgColor
) => {
  return new Promise((resolve, reject) => {
    resolve({
      [newScreenId]: {
        ScreenName: 'Screen ' + screenName,
        BG01: {
          Contenttype: 'Background',
          Contentparams: {
            sequenceid: '1',
            contentdescription: '#2066a2',
            contenttag: 'Null',
          },
        },
        AS01: {
          Contenttype: 'AudioScript',
          Contentparams: {
            sequenceid: '2',
            contentdescription: '',
            contenttag: '',
          },
        },
        IM01: {
          Contenttype: 'Image',
          Contentparams: {
            sequenceid: '3',
            contentdescription: '',
            searchstring: '',
            contenttag: '',
          },
        },
        // OS01: {
        //   Contenttype: 'OST',
        //   Contentparams: {
        //     sequenceid: '4',
        //     contentdescription: '',
        //     contenttag: '',
        //     FontFamily: FontUrl,
        //     FontSizeH: FontHSize,
        //     FontSizeP: FontPSize,
        //     FontColour: TextColor,
        //     fontTag: HEADERFONT,
        //   },
        // },
        IL01: {
          Contenttype: 'Illustration',
          Contentparams: {
            sequenceid: '5',
            contentdescription: '',
            contenttag: '',
            searchstring: '',
          },
        },
        TM01: {
          Contenttype: 'TemplateID',
          Contentparams: {
            contenttag: 'Null',
            sequenceid: '16',
            TemplateType: 'motion_graphics',
            contentdescription: 'no template',
            useTemplateStatus: false,
            sourcescreen: '',
          },
        },
        CS01: {
          Contenttype: 'ColourScheme',
          Contentparams: {
            sequenceid: '8',
            contentdescription: BgColor,
            contenttag: 'Null',
          },
        },
        CS02: {
          Contenttype: 'ColourScheme',
          Contentparams: {
            sequenceid: '9',
            contentdescription: '#808080',
            contenttag: 'Null',
          },
        },
        SD01: {
          Contenttype: 'ScreenDuration',
          Contentparams: {
            contenttag: 'Null',
            sequenceid: '10',
            contentdescription: '7',
          },
        },
        VD01: {
          Contenttype: 'Video',
          Contentparams: {
            contenttag: '',
            sequenceid: '11',
            contentdescription: '',
            searchstring: '',
          },
        },
      },
    });
  });
};

export function CreateProjectModelManual(
  projectId,
  projectName,
  CategoryId,
  NotificationURL,
  init_font = INIT_OST_FONT,
  fontH = FONT_SIZE_H,
  fontP = FONT_SIZE_P
) {
  return new Promise((resolve, reject) => {
    resolve({
      [projectId]: {
        ProjectName: projectName,
        CategoryID: CategoryId,
        NotificationURL: NotificationURL,
        Modules: {
          M01: {
            ModuleName: 'Module 1',
            slides: {
              SL01: {
                SlideName: 'Video 1',
                SlideAudioVO: 'Null',
                SlideRender: '1',
                Screens: {
                  'SC01.01': {
                    ScreenName: 'Screen 01',
                    BG01: {
                      Contenttype: 'Background',
                      Contentparams: {
                        sequenceid: '1',
                        contentdescription: '#2066a2',
                        contenttag: 'Null',
                      },
                    },
                    AS01: {
                      Contenttype: 'AudioScript',
                      Contentparams: {
                        sequenceid: '2',
                        contentdescription: '',
                        contenttag: '',
                      },
                    },
                    IM01: {
                      Contenttype: 'Image',
                      Contentparams: {
                        sequenceid: '3',
                        contentdescription: '',
                        searchstring: '',
                        contenttag: '',
                      },
                    },
                    // OS01: {
                    //   Contenttype: 'OST',
                    //   Contentparams: {
                    //     sequenceid: '4',
                    //     contentdescription: '',
                    //     contenttag: '',
                    //     FontFamily: init_font,
                    //     FontSizeH: fontH,
                    //     FontSizeP: fontP,
                    //     FontColour: TextColor,
                    //     fontTag: HEADERFONT,
                    //   },
                    // },
                    IL01: {
                      Contenttype: 'Illustration',
                      Contentparams: {
                        sequenceid: '5',
                        contentdescription: '',
                        searchstring: '',
                        contenttag: '',
                      },
                    },
                    TM01: {
                      Contenttype: 'TemplateID',
                      Contentparams: {
                        contenttag: 'Null',
                        sequenceid: '16',
                        TemplateType: 'motion_graphics',
                        contentdescription: 'no template',
                        sourcescreen: '',
                        useTemplateStatus: false,
                      },
                    },
                    CS01: {
                      Contenttype: 'ColourScheme',
                      Contentparams: {
                        sequenceid: '8',
                        contentdescription: BgColor,
                        contenttag: 'Null',
                      },
                    },
                    CS02: {
                      Contenttype: 'ColourScheme',
                      Contentparams: {
                        sequenceid: '9',
                        contentdescription: '#808080',
                        contenttag: 'Null',
                      },
                    },
                    SD01: {
                      Contenttype: 'ScreenDuration',
                      Contentparams: {
                        contenttag: 'Null',
                        sequenceid: '10',
                        contentdescription: '7',
                      },
                    },
                    VD01: {
                      Contenttype: 'Video',
                      Contentparams: {
                        contenttag: '',
                        sequenceid: '11',
                        contentdescription: '',
                        searchstring: '',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    });
  });
}

export const CreateModuleManual = (
  moduleId,
  moduleName,
  FontUrl,
  FontHSize,
  FontPSize,
  TextColor,
  BgColor
) => {
  return new Promise((resolve, reject) => {
    resolve({
      [moduleId]: {
        ModuleName: 'Module ' + moduleName,
        slides: {
          SL01: {
            SlideName: 'Video 1',
            SlideAudioVO: 'Null',
            SlideRender: '1',
            Screens: {
              'SC01.01': {
                ScreenName: 'Screen 01',
                BG01: {
                  Contenttype: 'Background',
                  Contentparams: {
                    sequenceid: '1',
                    contentdescription: '#2066a2',
                    contenttag: 'Null',
                  },
                },
                AS01: {
                  Contenttype: 'AudioScript',
                  Contentparams: {
                    sequenceid: '2',
                    contentdescription: '',
                    contenttag: '',
                  },
                },
                IM01: {
                  Contenttype: 'Image',
                  Contentparams: {
                    sequenceid: '3',
                    contentdescription: '',
                    searchstring: '',
                    contenttag: '',
                  },
                },
                // OS01: {
                //   Contenttype: 'OST',
                //   Contentparams: {
                //     sequenceid: '4',
                //     contentdescription: '',
                //     contenttag: '',
                //     FontFamily: FontUrl,
                //     FontSizeH: FontHSize,
                //     FontSizeP: FontPSize,
                //     FontColour: TextColor,
                //     fontTag: HEADERFONT,
                //   },
                // },
                IL01: {
                  Contenttype: 'Illustration',
                  Contentparams: {
                    sequenceid: '5',
                    contentdescription: '',
                    contenttag: '',
                    searchstring: '',
                  },
                },
                TM01: {
                  Contenttype: 'TemplateID',
                  Contentparams: {
                    contenttag: 'Null',
                    sequenceid: '16',
                    TemplateType: 'motion_graphics',
                    contentdescription: 'no template',
                    useTemplateStatus: false,
                    sourcescreen: '',
                  },
                },
                CS01: {
                  Contenttype: 'ColourScheme',
                  Contentparams: {
                    sequenceid: '8',
                    contentdescription: BgColor,
                    contenttag: 'Null',
                  },
                },
                CS02: {
                  Contenttype: 'ColourScheme',
                  Contentparams: {
                    sequenceid: '9',
                    contentdescription: '#808080',
                    contenttag: 'Null',
                  },
                },
                SD01: {
                  Contenttype: 'ScreenDuration',
                  Contentparams: {
                    contenttag: 'Null',
                    sequenceid: '10',
                    contentdescription: '',
                  },
                },
                VD01: {
                  Contenttype: 'Video',
                  Contentparams: {
                    contenttag: '',
                    sequenceid: '11',
                    contentdescription: '',
                    searchstring: '',
                  },
                },
              },
            },
          },
        },
      },
    });
  });
};

export const CreateSildeManual = (
  SlideId,
  SlideName,
  ScreenName,
  FontUrl,
  FontHSize,
  FontPSize,
  TextColor,
  BgColor
) => {
  return new Promise((resolve, reject) => {
    resolve({
      [SlideId]: {
        SlideName: 'Video ' + SlideName,
        SlideAudioVO: 'Null',
        SlideRender: '1',
        Screens: {
          [`SC${ScreenName}.01`]: {
            ScreenName: 'Screen 01',
            BG01: {
              Contenttype: 'Background',
              Contentparams: {
                sequenceid: '1',
                contentdescription: '#2066a2',
                contenttag: 'Null',
              },
            },
            AS01: {
              Contenttype: 'AudioScript',
              Contentparams: {
                sequenceid: '2',
                contentdescription: '',
                contenttag: '',
              },
            },
            IM01: {
              Contenttype: 'Image',
              Contentparams: {
                sequenceid: '3',
                contentdescription: '',
                searchstring: '',
                contenttag: '',
              },
            },
            // OS01: {
            //   Contenttype: 'OST',
            //   Contentparams: {
            //     sequenceid: '4',
            //     contentdescription: '',
            //     contenttag: '',
            //     FontFamily: FontUrl,
            //     FontSizeH: FontHSize,
            //     FontSizeP: FontPSize,
            //     FontColour: TextColor,
            //     fontTag: HEADERFONT,
            //   },
            // },
            IL01: {
              Contenttype: 'Illustration',
              Contentparams: {
                sequenceid: '5',
                contentdescription: '',
                contenttag: '',
                searchstring: '',
              },
            },
            TM01: {
              Contenttype: 'TemplateID',
              Contentparams: {
                contenttag: 'Null',
                sequenceid: '16',
                TemplateType: 'motion_graphics',
                contentdescription: 'no template',
                useTemplateStatus: false,
                sourcescreen: '',
              },
            },
            CS01: {
              Contenttype: 'ColourScheme',
              Contentparams: {
                sequenceid: '8',
                contentdescription: BgColor,
                contenttag: 'Null',
              },
            },
            CS02: {
              Contenttype: 'ColourScheme',
              Contentparams: {
                sequenceid: '9',
                contentdescription: '#808080',
                contenttag: 'Null',
              },
            },
            SD01: {
              Contenttype: 'ScreenDuration',
              Contentparams: {
                contenttag: 'Null',
                sequenceid: '10',
                contentdescription: '',
              },
            },
            VD01: {
              Contenttype: 'Video',
              Contentparams: {
                contenttag: '',
                sequenceid: '11',
                contentdescription: '',
                searchstring: '',
              },
            },
          },
        },
      },
    });
  });
};

export const CreateScreenManual = (
  newScreenId,
  screenName,
  FontUrl,
  FontHSize,
  FontPSize,
  TextColor,
  BgColor
) => {
  return new Promise((resolve, reject) => {
    resolve({
      [newScreenId]: {
        ScreenName: 'Screen ' + screenName,
        BG01: {
          Contenttype: 'Background',
          Contentparams: {
            sequenceid: '1',
            contentdescription: '#2066a2',
            contenttag: 'Null',
          },
        },
        AS01: {
          Contenttype: 'AudioScript',
          Contentparams: {
            sequenceid: '2',
            contentdescription: '',
            contenttag: '',
          },
        },
        IM01: {
          Contenttype: 'Image',
          Contentparams: {
            sequenceid: '3',
            contentdescription: '',
            searchstring: '',
            contenttag: '',
          },
        },
        // OS01: {
        //   Contenttype: 'OST',
        //   Contentparams: {
        //     sequenceid: '4',
        //     contentdescription: '',
        //     contenttag: '',
        //     FontFamily: FontUrl,
        //     FontSizeH: FontHSize,
        //     FontSizeP: FontPSize,
        //     FontColour: TextColor,
        //     fontTag: HEADERFONT,
        //   },
        // },
        IL01: {
          Contenttype: 'Illustration',
          Contentparams: {
            sequenceid: '5',
            contentdescription: '',
            contenttag: '',
            searchstring: '',
          },
        },
        TM01: {
          Contenttype: 'TemplateID',
          Contentparams: {
            contenttag: 'Null',
            sequenceid: '16',
            TemplateType: 'motion_graphics',
            contentdescription: 'no template',
            useTemplateStatus: false,
            sourcescreen: '',
          },
        },
        CS01: {
          Contenttype: 'ColourScheme',
          Contentparams: {
            sequenceid: '8',
            contentdescription: BgColor,
            contenttag: 'Null',
          },
        },
        CS02: {
          Contenttype: 'ColourScheme',
          Contentparams: {
            sequenceid: '9',
            contentdescription: '#808080',
            contenttag: 'Null',
          },
        },
        SD01: {
          Contenttype: 'ScreenDuration',
          Contentparams: {
            contenttag: 'Null',
            sequenceid: '10',
            contentdescription: '7',
          },
        },
        VD01: {
          Contenttype: 'Video',
          Contentparams: {
            contenttag: '',
            sequenceid: '11',
            contentdescription: '',
            searchstring: '',
          },
        },
      },
    });
  });
};
export const CreateProjectFromResponse = data => {
  return new Promise((resolve, reject) => {
    let projectData = JSON.parse(data.ProjectData);
    let projectId = Object.keys(projectData);
    let activeModule,
      activeSlide,
      activeScreen,
      activeOst,
      activeImage,
      activeIllustration,
      activeVideo;
    activeModule = Object.keys(projectData[projectId].Modules)[0];
    Object.values(projectData[projectId].Modules).forEach(module => {
      activeSlide = Object.keys(projectData[projectId].Modules[activeModule].slides)[0];
      Object.values(module.slides).forEach(slide => {
        activeScreen = Object.keys(
          projectData[projectId].Modules[activeModule].slides[activeSlide].Screens
        )[0];
        Object.values(slide.Screens).forEach((screen, i) => {
          let _f = Object.keys(screen);
          if (_f.filter(name => name.includes('IM')).length != 0) {
            let imageIds = _f.filter(name => name.includes('IM'));
            if (imageIds.length != 0) {
              activeImage = _f.filter(name => name.includes('IM'))[0];
            } else {
              activeImage = 'IM01';
            }
          } else {
            screen['IM01'] = {
              Contenttype: 'Image',
              Contentparams: {
                sequenceid: '17',
                contentdescription: '',
                searchstring: '',
                contenttag: '',
              },
            };
            // activeImage = "IM01"
          }
          if (_f.filter(name => name.includes('IL')).length != 0) {
            let illuIds = _f.filter(name => name.includes('IL'));
            if (illuIds.length != 0) {
              activeIllustration = _f.filter(name => name.includes('IL'))[0];
            } else {
              activeIllustration = 'IL01';
            }
          } else {
            screen['IL01'] = {
              Contenttype: 'Illustration',
              Contentparams: {
                sequenceid: '17',
                contentdescription: '',
                searchstring: '',
                contenttag: '',
              },
            };
          }
          if (_f.filter(name => name.includes('VD')).length != 0) {
            let videoIds = _f.filter(name => name.includes('VD'));
            if (videoIds.length != 0) {
              activeVideo = _f.filter(name => name.includes('VD'))[0];
            } else {
              activeVideo = 'VD01';
            }
          } else {
            screen['VD01'] = {
              Contenttype: 'Video',
              Contentparams: {
                sequenceid: '17',
                contentdescription: '',
                searchstring: '',
                contenttag: '',
              },
            };
          }
          if (_f.filter(name => name.includes('OS'))) {
            activeOst = _f.filter(name => name.includes('OS'))[0];
          } else {
            // screen['OS01'] = {
            //   Contenttype: 'OST',
            //   Contentparams: {
            //     sequenceid: '11',
            //     contentdescription: '',
            //     contenttag: '',
            //     FontFamily: 'Corsiva',
            //     FontSizeH: '',
            //     FontSizeP: '',
            //     FontColour: '#a45176',
            //     fontTag: HEADERFONT,
            //   },
            // };
            // activeOst = 'OS01';
          }
          Object.values(screen).forEach(feature => {
            if (feature.Contenttype === 'OST') {
              let lastFive;
              if (
                feature['Contentparams']['contentdescription'].includes('[H]') ||
                feature['Contentparams']['contentdescription'].includes('[P]') ||
                feature['Contentparams']['contentdescription'].includes('[B]')
              ) {
                lastFive = feature['Contentparams']['contentdescription'].substr(
                  feature['Contentparams']['contentdescription'].length - 3
                );
                if (lastFive === '[H]') {
                  feature['Contentparams']['fontTag'] = HEADERFONT;
                } else if (lastFive === '[P]') {
                  feature['Contentparams']['fontTag'] = PARAGRAPHFONT;
                } else if (lastFive === '[B]') {
                  feature['Contentparams']['fontTag'] = BULLETFONT;
                }
                let description = feature['Contentparams']['contentdescription'].split(lastFive)[0];

                if (feature['Contentparams']['contentdescription'] !== 'Null') {
                  feature['Contentparams']['contentdescription'] = description;
                }
              }
            }
            if (feature.Contenttype === 'Image') {
              if (feature['Contentparams']['contenttag'] === 'Null') {
                feature['Contentparams']['contenttag'] = '';
              }
            }
            if (feature.Contenttype === 'Illustration') {
              if (feature['Contentparams']['contenttag'] === 'Null') {
                feature['Contentparams']['contenttag'] = '';
              }
            }
            if (feature.Contenttype === 'Video') {
              if (feature['Contentparams']['contenttag'] === 'Null') {
                feature['Contentparams']['contenttag'] = '';
              }
            }
          });
        });
      });
    });
    resolve({
      createdProject: projectData,
      ProjectId: projectId[0],
      ActiveModule: activeModule,
      ActiveSlide: activeSlide,
      ActiveOst: activeOst,
      ActiveScreen: activeScreen,
      ActiveImage: activeImage === undefined ? 'IM01' : activeImage,
      ActiveIllustration: activeIllustration === undefined ? 'IL01' : activeIllustration,
      ActiveVideo: activeVideo === undefined ? 'VD01' : activeVideo,
    });
  });
};
export const CreatePreviewFromResponse = data => {
  return new Promise((resolve, reject) => {
    let projectData = JSON.parse(data.ProjectData);
    let projectId = Object.keys(projectData);
    let activeModule, moduleName, activeSlide, slideName, activeScreen, screenName;
    activeModule = Object.keys(projectData[projectId].Modules)[0];
    moduleName = projectData[projectId].Modules[activeModule].ModuleName;
    Object.values(projectData[projectId].Modules).forEach(module => {
      activeSlide = Object.keys(projectData[projectId].Modules[activeModule].slides)[0];
      slideName = module.slides[activeSlide].SlideName;
      Object.values(module.slides).forEach(slide => {
        activeScreen = Object.keys(
          projectData[projectId].Modules[activeModule].slides[activeSlide].Screens
        )[0];
        screenName =
          projectData[projectId].Modules[activeModule].slides[activeSlide].Screens[activeScreen]
            .ScreenName;
        Object.values(slide.Screens).forEach(screen => {
          // let _f = Object.keys(screen);
          // if (_f.filter(name => name.includes("IM"))) {
          //   let imageIds = _f.filter(name => name.includes("IM"));
          //   if (imageIds.length != 0)
          //     activeImage = _f.filter(name => name.includes("IM"))[0];
          // } else {
          //   screen['IM01'] = {
          //     Contenttype: "Image",
          //     Contentparams: {
          //       sequenceid: "17",
          //       contentdescription: "",
          //       searchstring: "",
          //       contenttag: ""
          //     },
          //   }
          //   activeImage = "IM01"
          // }
          // if (_f.filter(name => name.includes("IL"))) {
          //   let illuIds = _f.filter(name => name.includes("IL"));
          //   if (illuIds.length != 0)
          //     activeIllustration = _f.filter(name => name.includes("IL"))[0];
          // } else {
          //   screen['IL01'] = {
          //     Contenttype: "Illustration",
          //     Contentparams: {
          //       sequenceid: "17",
          //       contentdescription: "",
          //       searchstring: "",
          //       contenttag: ""
          //     },
          //   }
          //   activeIllustration = "IL01"
          // }
          // if (_f.filter(name => name.includes("VD"))) {
          //   let videoIds = _f.filter(name => name.includes("VD"));
          //   if (videoIds.length != 0) {
          //     activeVideo = _f.filter(name => name.includes("VD"))[0];
          //   }
          // } else {
          //   screen['VD01'] = {
          //     Contenttype: "Video",
          //     Contentparams: {
          //       sequenceid: "17",
          //       contentdescription: "",
          //       searchstring: "",
          //       contenttag: ""
          //     },
          //   }
          //   activeVideo = "VD01"
          // }
          // if (_f.filter(name => name.includes("OS"))) {
          //   activeOst = _f.filter(name => name.includes("OS"))[0];
          // } else {
          //   screen['OS01'] = {
          //     Contenttype: "OST",
          //     Contentparams: {
          //       sequenceid: "11",
          //       contentdescription: "",
          //       contenttag: "",
          //       FontFamily: "Corsiva",
          //       FontSizeH: "",
          //       FontSizeP: "",
          //       FontColour: "#a45176",
          //       fontTag: HEADERFONT
          //     }
          //   }
          //   activeOst = "OS01"
          // }
          // Object.values(screen).forEach((feature) => {
          //   if (feature.Contenttype === "OST") {
          //     let lastFive;
          //     if (feature['Contentparams']['contentdescription'].includes("[H]") || feature['Contentparams']['contentdescription'].includes("[P]") || feature['Contentparams']['contentdescription'].includes("[B]")) {
          //       lastFive = feature['Contentparams']['contentdescription'].substr(feature['Contentparams']['contentdescription'].length - 3);
          //       if (lastFive === "[H]") {
          //         feature['Contentparams']['fontTag'] = HEADERFONT
          //       } else if (lastFive === "[P]") {
          //         feature['Contentparams']['fontTag'] = PARAGRAPHFONT
          //       } else if (lastFive === "[B]") {
          //         feature['Contentparams']['fontTag'] = BULLETFONT
          //       }
          //       let description = feature['Contentparams']['contentdescription'].split(lastFive)[0];
          //       if (feature['Contentparams']['contentdescription'] !== "Null") {
          //         feature['Contentparams']['contentdescription'] = description
          //       }
          //     }
          //   }
          // })
        });
      });
    });

    resolve({
      previewProject: projectData,
      ProjectId: projectId[0],
      ActiveModule: activeModule,
      ActiveSlide: activeSlide,
      ActiveScreen: activeScreen,
      ModuleName: moduleName,
      SlideName: slideName,
      ScreenName: screenName,
    });
  });
};

export function CreateProjectTemplateModel(projectId, projectName, screendata) {
  return new Promise((resolve, reject) => {
    resolve({
      [projectId]: {
        ProjectName: projectName,
        Modules: {
          M01: {
            ModuleName: 'Module 1',
            slides: {
              SL01: {
                SlideName: 'Slide 1',
                SlideAudioVO: 'Null',
                SlideRender: '1',
                Screens: {
                  'SC01.01': screendata,
                },
              },
            },
          },
        },
      },
    });
  });
}

export const removeMediaUrlFilter = (projectData, projectId) => {
  return new Promise((resolve, reject) => {
    Object.entries(projectData[projectId].Modules).map((moduleData, moduleIndex) => {
      let mData = moduleData[1];
      let slides = mData.slides;
      Object.entries(slides).map((slideData, moduleIndex) => {
        let slide = slideData[1];
        let screens = slide.Screens;
        Object.entries(screens).map((screenData, moduleIndex) => {
          let slide = screenData[1];
          Object.entries(slide).map((item, key) => {
            item.map((element, index) => {
              if (item[0] == 'mediaurl') {
                delete projectData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[
                  screenData[0]
                ]['mediaurl'];
              }
            });
          });
        });
      });
    });
    resolve(projectData);
  });
};
