import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import HeaderNavigation from '../../../components/headerNavigation';
import Sidebar from '../../../components/sidebar';
import UsageTable from './UsageTable';
import UsageTrackingHeader from './UsageTrackingHeader';
import { useEffect } from 'react';

const UsageTracking = () => {
  const location = useLocation();
  const [fromUsage, setFromUsage] = useState(false);
  useEffect(() => {
    location.search.includes('token') ? setFromUsage(true) : setFromUsage(false);
  }, []);

  return (
    <>
      <Sidebar fromUsage={fromUsage} />
      <main className="main-content-wrapper">
        <div className="content-wrapper">
          <HeaderNavigation />
          <UsageTrackingHeader />
          <UsageTable />
        </div>
      </main>
    </>
  );
};

export default UsageTracking;
