import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../../store";

function UsePreviousTemplate() {
  let project = useSelector((state) => state.project);
  let EditMode = useSelector((state) => state.project.EditMode);
  let ActiveModule = useSelector((state) => state.project.ActiveModule);
  let ActiveSlide = useSelector((state) => state.project.ActiveSlide);
  let ActiveScreen = useSelector((state) => state.project.ActiveScreen);
  let projectID = useSelector((state) => state.project.ProjectId);
  const dispatch = useDispatch();

  let { EditFeature } = bindActionCreators(actionCreator, dispatch);

  const [projectId, setProjectId] = useState("");
  const [checked, setChecked] = useState(false);
  const [firstItem, setFirstItem] = useState(undefined);
  const [projectLoaded, setProjectLoaded] = useState(false);
  const [projects, setProjects] = useState("");

  useEffect(() => {
    setProjectId(projectID);
    setProjects(project);
    if (project.createdProject[projectID] != undefined) {
      setProjectLoaded(true);
      getFirstModuleSlideScreen();
      //check checkbox
      isTemplateApplied();
    } else {
      setProjectLoaded(false);
    }
  }, [project, projectID, ActiveSlide]);

  useEffect(() => {
    //setChecked(false);
    isTemplateApplied();
  }, [ActiveScreen, ActiveSlide, ActiveModule]);

  useEffect(() => {
    EditTemplate(checked);
  }, [checked]);

  const isTemplateApplied = () => {
    if (projectLoaded) {
      if (
        project.createdProject[projectID].Modules[ActiveModule] !== undefined
      ) {
        if (
          project.createdProject[projectID].Modules[ActiveModule].slides[
            ActiveSlide
          ] !== undefined
        ) {
          if (
            project.createdProject[projectID].Modules[ActiveModule].slides[
              ActiveSlide
            ].Screens !== undefined
          ) {
            if (
              project.createdProject[projectID].Modules[ActiveModule].slides[
                ActiveSlide
              ].Screens[ActiveScreen] !== undefined
            ) {
              if (
                project.createdProject[projectID].Modules[ActiveModule].slides[
                  ActiveSlide
                ].Screens[ActiveScreen].TM01["Contentparams"][
                  "sourcescreen"
                ] !== ""
              ) {
                setChecked(true);
              } else {
                Object.entries(
                  project.createdProject[projectID].Modules[ActiveModule]
                    .slides[ActiveSlide].Screens[ActiveScreen]
                ).map((item, key) => {
                  //console.log(item);
                  if (item[1].Contenttype == "TemplateID") {
                    let data = item[1].Contentparams.useTemplateStatus;
                    if (data !== undefined) {
                      setChecked(data);
                    } else {
                      setChecked(false);
                    }
                  }
                });
              }
            }
          }
        }
      }
    }
  };

  const EditTemplate = (status) => {
    if (projectLoaded) {
      if (project.createdProject[projectId].Modules != undefined) {
        if (
          project.createdProject[projectId].Modules[ActiveModule] != undefined
        ) {
          if (
            project.createdProject[projectId].Modules[ActiveModule].slides[
              ActiveSlide
            ] != undefined
          ) {
            if (
              project.createdProject[projectId].Modules[ActiveModule].slides[
                ActiveSlide
              ].Screens[ActiveScreen] != undefined
            ) {
              Object.entries(
                project.createdProject[projectID].Modules[ActiveModule].slides[
                  ActiveSlide
                ].Screens[ActiveScreen]
              ).map((item, key) => {
                if (item[1].Contenttype == "TemplateID") {
                  if (status == true) {
                    item[1].Contentparams.useTemplateStatus = true;
                  } else {
                    item[1].Contentparams.sourcescreen = "";
                    item[1].Contentparams.useTemplateStatus = false;
                  }
                  EditFeature({
                    [item[0]]: item[1],
                  });
                }
              });
            }
          }
        }
      }
    }
  };

  const setUsetemplateMotion = (event) => {
    if (projectLoaded) {
      setChecked(event.target.checked);
    }

    //ChangeUseTemplateStatus(event.target.checked)
  };
  const getFirstModuleSlideScreen = () => {
    let elem = "";
    if (project.createdProject[projectID] != undefined) {
      if (project.createdProject[projectID].Modules != undefined) {
        if (
          project.createdProject[projectID].Modules[ActiveModule] !== undefined
        ) {
          if (
            project.createdProject[projectID].Modules[ActiveModule].slides[
              ActiveSlide
            ] !== undefined
          ) {
            if (
              project.createdProject[projectID].Modules[ActiveModule].slides[
                ActiveSlide
              ].Screens !== undefined
            ) {
              if (
                project.createdProject[projectID].Modules[ActiveModule].slides[
                  ActiveSlide
                ].Screens[ActiveScreen] !== undefined
              ) {
                let module = "";
                let slide = "";
                let screen = "";
                Object.keys(project.createdProject[projectID].Modules).map(
                  (item, i) => {
                    if (i == 0) {
                      module = item;
                      elem += item;
                    }
                  }
                );
                Object.keys(
                  project.createdProject[projectID].Modules[module].slides
                ).map((item, i) => {
                  if (i == 0) {
                    slide = item;
                    elem += item;
                  }
                });
                Object.keys(
                  project.createdProject[projectID].Modules[module].slides[
                    slide
                  ].Screens
                ).map((item, i) => {
                  if (i == 0) {
                    screen = item;
                    elem += item;
                  }
                });
              }
            }
          }
        }
      }
    }
    setFirstItem(elem);
    //return elem;
  };

  return (
    <div className="d-flex align-items-center pro-temp-container mr-4">
      {ActiveModule + ActiveSlide + ActiveScreen == firstItem ? (
        // <Checkbox
        //   disabled
        //   inputProps={{ "aria-label": "disabled checked checkbox" }}
        // />
        ""
      ) : (
        <Checkbox
          checked={checked}
          onChange={(e) => setUsetemplateMotion(e)}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      )}
        {ActiveModule + ActiveSlide + ActiveScreen !== firstItem && (
      <div className="cm-header .cm-subhead">
        Use template from previous screen
      </div>)}
    </div>
  );
}

export default UsePreviousTemplate;
