import React, { useEffect, useState } from 'react';
import { editIcon, tickIcon, crossIcon } from '../../constants/icon';

export default function EditableContent(props) {
  const { className, initialContent, editable, onClick, isActiveData } = props;

  const [isActive, setIsActive] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [content, setContent] = useState(initialContent);
  const [showEditIcon, setShowEditIcon] = useState(false);

  useEffect(() => {
    setContent(initialContent);
  }, [initialContent]);

  useEffect(() => {
    setIsActive(isActiveData);
  }, [isActiveData]);

  const handleComponentClick = () => {
    setIsActive(!isActive);
  };

  const handleEditClick = () => {
    if (editable) {
      setIsEditing(true);
      setShowEditIcon(false);
    }
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    handleSave();
  };

  const handleSave = () => {
    const data = onClick(content);

    if (data?.revert) {
      setContent(initialContent);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setContent(initialContent);
  };

  const handleInputChange = event => {
    setContent(event.target.value);
  };

  return (
    <div
      className={`editable-content ${editable ? 'editable' : ''} ${className ? className : ''} ${
        isActive ? 'active' : ''
      }`}
      onClick={handleComponentClick}
      onMouseEnter={() => {
        setIsHovered(true);
        if (!isEditing) setShowEditIcon(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        setShowEditIcon(false);
      }}
    >
      <div className={`editable-title ${isEditing ? 'editing' : ''}`}>
        {isEditing ? (
          <>
            <input
              autoFocus
              type="text"
              value={content}
              className="editable-input"
              onChange={handleInputChange}
            />
            <button className="editable-btn" onClick={handleSaveClick}>
              {tickIcon}
            </button>
            <button className="editable-btn" onClick={handleCancelClick}>
              {crossIcon}
            </button>
          </>
        ) : (
          <>
            {content}
            {editable && showEditIcon && (
              <span
                className={`edit-icon ${isHovered ? 'fadeIn' : 'fadeOut'}`}
                onClick={handleEditClick}
              >
                {editIcon}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
}
