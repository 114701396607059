import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadMediaFile } from "../../../../services/api.service";
import ReactImageFallback from "react-image-fallback";
import { getUserItem, showToaster } from "../../../../services/helper";
import SimpleBackdrop from "../../../container/pageLoader";
import PageLoader from "../../../../services/pageLoader";
// import { showToaster } from "../../../../services/helper";
import { capitalizeFirstLetter } from "../../../../services/helper";
import { actionCreator } from "../../../../store";
import { bindActionCreators } from "redux";
import Tooltip from '@mui/material/Tooltip';
import pageLoader from "../../../../services/pageLoader";
export default function Assetupload(props) {
  const [pageNo, setPageNo] = useState(1);
  // const [pageNo, setPageNo] = useState(1);
  const [imageFile, setImageFile] = useState("");
  const [uploadOption, setUploadOption] = useState();
  const [uploadedFile, setUploadedFile] = useState("");
  const [uploadedAsset, setUploadedAsset] = useState("");
  const [uploadErroMessage, setUploadErroMessage] = useState("");
  const [pageLoad, setPageLoad] = useState(false);
  const [IllustrationOption, setIllustrationOption] = useState(false);
  const [updateJsonMsg, setUpdateJsonMsg] = useState("");
  const [illuUploadOption, setIlluUploadOption] = useState("");
  const [publicPrivateOption, setPublicPrivateOption] = useState(1)
  const [videoOption, setVideoOption] = useState();
  const [templateOption, setTemplateOption] = useState();
  const [fontOption, setFontOption] = useState();
  const [fontName, setFontName] = useState();
  const dispatch = useDispatch();
  let DisplayMode = useSelector(state => state.project.DisplayMode)
  let { UpdatePreviewFeature } = bindActionCreators(actionCreator, dispatch);
  useEffect(() => {
    //console.log(props);
    if (props.type === "image") {
      setUploadOption(2);
    } else if (props.type === "illustration") {
      setIllustrationOption(true);
    } else if (props.type === "video") {
      setVideoOption(1);
    } else if (props.type === "template") {
      setTemplateOption(1);
    } else if (props.type === "audio") {
      setFontOption(1);
    }
  });
  useEffect(() => {
    if (props.type === "illustration") {
      setIlluUploadOption(1);
    }
  }, [IllustrationOption]);
  const assestUpload = () => {
    setUploadErroMessage("");
    try {
      let imageFile = document.getElementById("assestUpload");
      var types = ["image/png", "image/jpeg", "image/jpg"];
      if (illuUploadOption === 1) {
        types = ["image/vnd.microsoft.icon"];
      }
      if (uploadOption === 4) {
        types = ["application/x-font-ttf"];
      }
      if (videoOption === 1) {
        types = ["video/mp4", "video/x-m4v", "video/*"];
      }
      if (
        imageFile !== undefined &&
        imageFile !== null &&
        imageFile.files[0] !== undefined &&
        imageFile.files[0] !== null
      ) {
        // console.log(this.state.uploadedAsset,types.every(type => imageFile.files[0]['type']))
        if (
          illuUploadOption === 1 &&
          imageFile.files[0].name.split(".").pop() !== "ico"
        ) {
          //console.log("uploadOption", imageFile);
          setUploadErroMessage("Please upload a icon");
          clearError();
          return false;
        } else if (
          uploadOption === 2 &&
          types.every((type) => imageFile.files[0]["type"] !== type)
        ) {
          setUploadErroMessage("Please upload a image");
          clearError();
          return false;
        } else if (
          templateOption === 1 &&
          imageFile.files[0].name.split(".").pop() !== "ntp"
        ) {
          setUploadErroMessage("Please upload a ntp file");
          clearError();
          return false;
        } else if (
          fontOption === 1 &&
          imageFile.files[0].name.split(".").pop() !== "ttf"
        ) {
          setUploadErroMessage("Please upload a ttf file");
          clearError();
          return false;
        } else if (
          illuUploadOption === 2 &&
          types.every((type) => imageFile.files[0]["type"] !== type)
        ) {
          setUploadErroMessage("Please upload a logo");
          clearError();
          return false;
        } else if (
          videoOption === 1 &&
          types.every((type) => imageFile.files[0]["type"] !== type)
        ) {
          setUploadErroMessage("Please upload a video");
          clearError();
          return false;
        } else if (imageFile.files.length !== 1) {
          setUploadErroMessage("Supports only single file upload");
          clearError();
          return false;
        } else if (imageFile.files[0]["size"] > 26214400) {
          setUploadErroMessage("Uploaded file should not exceed 25 mb");
          clearError();
          return false;
        } else {
          if (uploadOption === 2) {
            setImageFile(imageFile.files[0]);
            setUploadedAsset("../../../assets/img/fallbackImage.jpg");
            getBase64(imageFile.files[0]);
            // setUploadedFile(imageFile.files[0])
          } else if (videoOption === 1) {
            setImageFile(imageFile.files[0]);
            setUploadedAsset("../../../assets/img/videobutton.png");
          } else if (fontOption === 1) {
            setImageFile(imageFile.files[0]);
            setUploadedAsset("../../../assets/img/font.png");

            setFontName(imageFile.files[0].name);
          } else {
            setImageFile(imageFile.files[0]);
            setUploadedAsset("../../../assets/img/fallbackImage.jpg");
            getBase64(imageFile.files[0]);
          }
        }
      } else {
        setUploadErroMessage("Please upload a image");
        clearError();
      }
    } catch (error) {
      setUploadErroMessage("Something went wrong. Please try again");
      clearError();
    }
  };
  useEffect(() => {
    setUploadedFile(imageFile);
  }, [uploadedAsset]);
  const clearError = () => {
    setTimeout(() => {
      setUploadErroMessage("");
      setUpdateJsonMsg("");
    }, 10000);
  };
  const getBase64 = (file) => {
    //console.log(file);

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result !== null) {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          setUploadedAsset(reader.result);
          setImageFile(file);
          // this.setState({ uploadedAsset: reader.result }, () => {
          //     this.uploadedFile = file
          // });
          //console.log(uploadedAsset, "uploadedAsset");
        };
      } else {
      }
    };
    reader.onerror = function (error) { };
  };
  const uploadToRepo = () => {
    if (illuUploadOption === 1) {
      let icon_Flag = document.getElementById("icon_Flag").value;
      if (icon_Flag !== undefined && icon_Flag !== null && icon_Flag !== "") {
        if (uploadedFile !== "") {
          setPageLoad(true);
          try {
            let data = new FormData();
            const config = {
              timeout: 1000 * 50000,
              headers: {
                "content-type": "multipart/form-data",
                media_type: "ICON",
                tag_names: icon_Flag,
                user_id: getUserItem("id"),
                file_name: Date.now() + ".ico",
                private: publicPrivateOption === 1 ? 1 : 0
              },
            };
            data.append("file", uploadedFile);
            uploadMediaFile(data, config).then((response) => {
              try {
                if (
                  response !== "" &&
                  response !== undefined &&
                  response !== null &&
                  response.data !== "" &&
                  response.data !== undefined &&
                  response.data !== null
                ) {
                  document.getElementById("icon_Flag").value = "";
                  setPageLoad(false);
                  showToaster("Icon successfully uploaded", "success");
                  setUpdateJsonMsg("Icon successfully uploaded");
                  setUploadedAsset("");
                  //props.closePreview();
                  SelectAsset(response.data);
                  setUploadErroMessage("");
                } else if (
                  response !== "" &&
                  response !== undefined &&
                  response !== null &&
                  response.status !== "" &&
                  response.status !== undefined &&
                  response.status !== null &&
                  response.message !== undefined &&
                  response.message !== null &&
                  response.message !== ""
                ) {
                  setUploadErroMessage(response.message);
                  setPageLoad(false);
                } else {
                  setUploadErroMessage(
                    "Something went wrong. Please Try Again"
                  );
                  setPageLoad(false);
                }
                clearError();
              } catch (error) {
                setUploadErroMessage("Something went wrong. Please Try Again");
                setPageLoad(false);
                clearError();
              }
            });
          } catch (error) {
            setUploadErroMessage("Something went wrong. Please Try Again");
            setPageLoad(false);
            clearError();
          }
        }
      } else {
        document.getElementById("icon_Flag").focus();
        setUploadErroMessage("Please enter icon tag name");
        return false;
      }
    } else if (fontOption === 1) {
      let font_family = document.getElementById("font_family").value;
      if (
        font_family !== undefined &&
        font_family !== null &&
        font_family !== ""
      ) {
        if (uploadedFile !== "") {
          setPageLoad(true);
          try {
            let data = new FormData();
            const config = {
              timeout: 1000 * 50000,
              headers: {
                "content-type": "multipart/form-data",
                media_type: "FONT",
                user_id: getUserItem("id"),
                file_name: uploadedFile.name,
                font_family: font_family,
              },
            };
            data.append("file", uploadedFile);
            uploadMediaFile(data, config).then((response) => {
              try {
                if (
                  response !== "" &&
                  response !== undefined &&
                  response !== null &&
                  response.data !== "" &&
                  response.data !== undefined &&
                  response.data !== null
                ) {
                  // this.setState({ uploadedLogo: response.data, pageLoad: false, uploadedAsset: "" }, () => {
                  //     this.updateJson('icon', response.data);
                  //     this.openAssetModal("3")
                  // })
                  // this.getAssetRepo();
                  // this.getTemplates()

                  // this.props.getFontStatus(true);
                  document.getElementById("font_family").value = "";
                  setPageLoad(false);
                  showToaster("Font successfully uploaded", "success");
                  setUpdateJsonMsg("Font successfully uploaded");
                  // setUploadedAsset("")
                  props.closePreview();
                } else if (
                  response !== "" &&
                  response !== undefined &&
                  response !== null &&
                  response.status !== "" &&
                  response.status !== undefined &&
                  response.status !== null &&
                  response.message !== undefined &&
                  response.message !== null &&
                  response.message !== ""
                ) {
                  setUploadErroMessage(response.message);
                  setPageLoad(false);
                } else {
                  setUploadErroMessage(
                    "Something went wrong. Please Try Again"
                  );
                  setPageLoad(false);
                  clearError();
                }
              } catch (error) {
                setUploadErroMessage("Something went wrong. Please Try Again");
                setPageLoad(false);
                clearError();
              }
            });
          } catch (error) {
            setUploadErroMessage("Something went wrong. Please Try Again");
            setPageLoad(false);
            clearError();
          }
        }
      } else {
        document.getElementById("font_family").focus();
        setUploadErroMessage("Please enter tag name");
        return false;
      }
    } else if (illuUploadOption === 2) {
      let logo_Flag = document.getElementById("logo_Flag").value;
      if (logo_Flag !== undefined && logo_Flag !== null && logo_Flag !== "") {
        if (uploadedFile !== "") {
          setPageLoad(true);
          try {
            let data = new FormData();
            const config = {
              timeout: 1000 * 50000,
              headers: {
                "content-type": "multipart/form-data",
                media_type: "LOGO",
                user_id: getUserItem("id"),
                file_name: uploadedFile.name,
                tag_names: logo_Flag,
                private: publicPrivateOption === 1 ? 1 : 0
              },
            };
            data.append("file", uploadedFile);
            uploadMediaFile(data, config).then((response) => {
              try {
                if (
                  response !== "" &&
                  response !== undefined &&
                  response !== null &&
                  response.data !== "" &&
                  response.data !== undefined &&
                  response.data !== null
                ) {
                  document.getElementById("logo_Flag").value = "";
                  setPageLoad(false);
                  showToaster("Logo successfully uploaded", "success");
                  setUpdateJsonMsg("Logo successfully uploaded");
                  setUploadedAsset("");
                  setUploadErroMessage("");
                  props.closePreview();
                  SelectAsset(response.data);
                } else if (
                  response !== "" &&
                  response !== undefined &&
                  response !== null &&
                  response.status !== "" &&
                  response.status !== undefined &&
                  response.status !== null &&
                  response.message !== undefined &&
                  response.message !== null &&
                  response.message !== ""
                ) {
                  setPageLoad(false);
                  setUploadErroMessage(response.message);
                } else {
                  setPageLoad(false);
                  setUploadErroMessage("Something went wrong. Please Try Again");
                }
                clearError();
              } catch (error) {
                setPageLoad(false);
                setUploadErroMessage("Something went wrong. Please Try Again");
                clearError();
              }
            });
          } catch (error) {
            setPageLoad(false);
            setUploadErroMessage("Something went wrong. Please Try Again");
            clearError();
          }
        }
      } else {
        document.getElementById("logo_Flag").focus();
        setUploadErroMessage("Please enter tag name");
        return false;
      }
    } else if (templateOption === 1) {
      if (uploadedFile !== "") {
        var header = "";
        header = {
          "content-type": "multipart/form-data",
          media_type: "NKTEMPLATE",
          user_id: getUserItem("id"),
          template_type: "motion_graphics",
          file_name: uploadedFile.name,
        };
        setPageLoad(true);
        try {
          let data = new FormData();
          const config = {
            timeout: 1000 * 50000,
            headers: header,
          };
          //console.log(uploadedFile);
          data.append("file", uploadedFile);
          uploadMediaFile(data, config).then((response) => {
            try {
              if (
                response !== "" &&
                response !== undefined &&
                response !== null &&
                response.data !== "" &&
                response.data !== undefined &&
                response.data !== null
              ) {
                console.log(response);
                document.getElementById("logo_Flag").value = "";
                setPageLoad(false);
                showToaster("Template successfully uploaded", "success");
                setUpdateJsonMsg("Template successfully uploaded");
                setUploadedAsset("");
                setUploadErroMessage("");
                props.closePreview();
                SelectAsset(response.data);
              } else if (
                response !== "" &&
                response !== undefined &&
                response !== null &&
                response.status !== "" &&
                response.status !== undefined &&
                response.status !== null &&
                response.message !== undefined &&
                response.message !== null &&
                response.message !== ""
              ) {
                setPageLoad(false);
                setUploadErroMessage(response.message);
              } else {
                setPageLoad(false);
                setUploadErroMessage("Something went wrong. Please Try Again");
              }
              clearError();
            } catch (error) {
              setPageLoad(false);
              setUploadErroMessage("Something went wrong. Please Try Again");
              clearError();
            }
          });
        } catch (error) {
          this.setState({
            pageLoad: false,
            uploadErroMessage: "Something went wrong. Please Try Again",
          });
          this.clearError();
        }
      }
    } else if (videoOption === 1) {
      let video_Flag = document.getElementById("video_Flag").value;
      if (
        video_Flag !== undefined &&
        video_Flag !== null &&
        video_Flag !== ""
      ) {
        if (uploadedFile !== "") {
          setPageLoad(true);
          try {
            let data = new FormData();
            const config = {
              timeout: 1000 * 50000,
              headers: {
                "content-type": "multipart/form-data",
                media_type: "VIDEO",
                user_id: getUserItem("id"),
                file_name: uploadedFile.name,
                tag_names: video_Flag,
                private: publicPrivateOption === 1 ? 1 : 0
              },
            };
            data.append("file", uploadedFile);
            uploadMediaFile(data, config).then((response) => {
              try {
                if (
                  response !== "" &&
                  response !== undefined &&
                  response !== null &&
                  response.data !== "" &&
                  response.data !== undefined &&
                  response.data !== null
                ) {
                  console.log(response);
                  
                  document.getElementById("video_Flag").value = "";
                  setPageLoad(false);
                  if(response.status==400){
                    showToaster("Video successfully uploaded "+ response.message, "warning");
                  }else{
                    showToaster("Video successfully uploaded", "success");
                  }
                  setUpdateJsonMsg("Video successfully uploaded");
                  setUploadedAsset("");
                  props.closePreview();
                  SelectAsset(response.data);
                } else if (
                  response !== "" &&
                  response !== undefined &&
                  response !== null &&
                  response.status !== "" &&
                  response.status !== undefined &&
                  response.status !== null &&
                  response.message !== undefined &&
                  response.message !== null &&
                  response.message !== ""
                ) {
                  setPageLoad(false);
                  setUploadErroMessage(response.message);
                } else {
                  setPageLoad(false);
                  setUploadErroMessage(
                    "Something went wrong. Please Try Again"
                  );
                }
                clearError();
              } catch (error) {
                setPageLoad(false);
                setUploadErroMessage("Something went wrong. Please Try Again");
                clearError();
              }
            });
          } catch (error) {
            setPageLoad(false);
            setUploadErroMessage("Something went wrong. Please Try Again");
            clearError();
          }
        }
      } else {
        document.getElementById("video_Flag").focus();
        setUploadErroMessage("Please enter tag name");
        return false;
      }
    } else {
      if (uploadedFile !== "") {
        var header = "";
        if (uploadOption === 2) {
          header = {
            "content-type": "multipart/form-data",
            media_type: "IMAGE",
            user_id: getUserItem("id"),
            file_name: uploadedFile.name,
            private: publicPrivateOption === 1 ? 1 : 0
          };
        }
        setPageLoad(true);
        try {
          let data = new FormData();
          const config = {
            timeout: 1000 * 50000,
            headers: header,
          };
          //console.log(uploadedFile);
          data.append("file", uploadedFile);
          uploadMediaFile(data, config).then((response) => {
            try {
              if (
                response !== "" &&
                response !== undefined &&
                response !== null &&
                response.data !== "" &&
                response.data !== undefined &&
                response.data !== null
              ) {
                if (uploadOption === 2) {
                  if(response.status==400){
                    showToaster("Image successfully uploaded "+ response.message, "warning");
                  }else{
                    showToaster("Image successfully uploaded", "success");
                  }
                  setPageLoad(false);
                  // showToaster("Image successfully uploaded", "success");
                  setUpdateJsonMsg("Image successfully uploaded");
                  setUploadedAsset("");
                  props.closePreview();
                  SelectAsset(response.data);
                }
              } else if (
                response !== "" &&
                response !== undefined &&
                response !== null &&
                response.status !== "" &&
                response.status !== undefined &&
                response.status !== null &&
                response.message !== undefined &&
                response.message !== null &&
                response.message !== ""
              ) {
                setPageLoad(false);
                setUploadErroMessage(response.message);
              } else {
                setPageLoad(false);
                setUploadErroMessage("Something went wrong. Please Try Again");
              }
              clearError();
            } catch (error) {
              setPageLoad(false);
              setUploadErroMessage("Something went wrong. Please Try Again");
              clearError();
            }
          });
        } catch (error) {
          setPageLoad(false);
          setUploadErroMessage("Something went wrong. Please Try Again");
          clearError();
        }
      }
    }
  };
  const clearErrorMessage = () => {
    setUploadErroMessage("");
  };
  const SelectAsset = (medialUrl) => {
    // console.log(props);
    // let asset = props.item[1];
    // asset.Contentparams.contentdescription = medialUrl;
    // let data = {
    //   [props.item[0]]: asset,
    // };
    //console.log(data);
    props.setdisplayImage(medialUrl)
    //UpdatePreviewFeature(data);
    props.closePreview();
  };
  return (
    <React.Fragment>
      <PageLoader showLoader={pageLoad} />
      {/* <SimpleBackdrop showBackDrop={pageLoad} /> */}
      <div
        className="tab-pane show active"
        id="cm-upload"
        role="tabpanel"
        aria-labelledby="heading-tab"
      >
        <div className="text-right">
          {fontOption === 1 && (
            <Tooltip title="Close" arrow>
              <div
                className="font-close"
                onClick={() => props.closePreview(false)}

              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="close">
                      <rect
                        width="24"
                        height="24"
                        transform="rotate(180 12 12)"
                        opacity="0"
                      />
                      <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                    </g>
                  </g>
                </svg>
              </div></Tooltip>
          )}
        </div>
       
        {IllustrationOption === true ? (
          <div className="stok-selct">
            <label className="templt-radio-holder">
              <label className="custom-radio">
                <input
                  type="radio"
                  name="q1"
                  onClick={() => {
                    setUploadedAsset("");
                    setUploadErroMessage("");
                  }}
                  onChange={() => setIlluUploadOption(1)}
                  checked={illuUploadOption === 1 ? true : false}
                />
                <span className="checkmark"></span>
              </label>
              <span className="templt-label">Icons</span>
            </label>
            <label className="templt-radio-holder">
              <label className="custom-radio">
                <input
                  type="radio"
                  name="q1"
                  onClick={() => {
                    setUploadedAsset("");
                    setUploadErroMessage("");
                  }}
                  onChange={() => setIlluUploadOption(2)}
                  checked={illuUploadOption === 2 ? true : false}
                />
                <span className="checkmark"></span>
              </label>
              <span className="templt-label">Logos</span>
            </label>
          </div>
        ) : (
            ""
          )}
        {illuUploadOption === 1 ? (
          <div className="d-flex align-items-center justify-content-center defut-enter-blk">
            <div className="defut-label-holder">
              <label>Tag Name :</label>
            </div>
            <div className="defut-input-holder">
              <input
                id="icon_Flag"
                type="text"
                onChange={() => clearErrorMessage()}
                placeholder="Enter Icon tag name"
              />
            </div>
          </div>

        ) : illuUploadOption === 2 ? (
          <div className="d-flex align-items-center justify-content-center defut-enter-blk">
            <div className="defut-label-holder">
              <label>Tag Name :</label>
            </div>
            <div className="defut-input-holder">
              <input
                id="logo_Flag"
                type="text"
                onChange={() => clearErrorMessage()}
                placeholder="Enter Logo tag name"
              />
            </div>
          </div>
        ) : (
              ""
            )}
        {videoOption === 1 ? (
          <div className="d-flex align-items-center justify-content-center defut-enter-blk mt-2">
            <div className="defut-label-holder">
              <label>Tag Name :</label>
            </div>
            <div className="defut-input-holder">
              <input
                id="video_Flag"
                type="text"
                onChange={() => clearErrorMessage()}
                placeholder="Enter Video tag name"
              />
            </div>
          </div>
        ) : (
            ""
          )}
            
        {fontOption === 1 ? (
          <div className="d-flex align-items-center justify-content-center defut-enter-blk">
            <div className="defut-label-holder">
              <label>Tag Name :</label>
            </div>
            <div className="defut-input-holder">
              <input
                id="font_family"
                type="text"
                onChange={() => clearErrorMessage()}
                placeholder="Enter Font tag name"
              />
            </div>
          </div>
        ) : (
            ""
          )}
           {props.type !== "audio"&&
          <div className="stok-selct">
            <label className="templt-radio-holder">
              <label className="custom-radio">
                <input
                  type="radio"
                  name="q11"
                  onChange={() => setPublicPrivateOption(1)}

                  checked={publicPrivateOption === 1 ? true : false}
                />
                <span className="checkmark"></span>
              </label>
              <span className="templt-label">Private</span>
            </label>
            <label className="templt-radio-holder">
              <label className="custom-radio">
                <input
                  type="radio"
                  name="q11"
                  onChange={() => setPublicPrivateOption(2)}

                  checked={publicPrivateOption === 2 ? true : false}
                />
                <span className="checkmark"></span>
              </label>
              <span className="templt-label">Public</span>
            </label>
          </div>
        }
        <div className="aspt-blk">
          <div
            className={
              fontOption ? "aspt-upload-holder w-100" : "aspt-upload-holder"
            }
          >
            <div className="aspt-container">
              {uploadedAsset === "" ? (
                <div className="cc-csv-btn">
                  <label className="btn btn-primary b-logo-up">
                    {uploadOption === 2
                      ? "Upload your image"
                      : illuUploadOption === 1
                        ? "Upload your icon"
                        : illuUploadOption === 2
                          ? "Upload your logo"
                          : videoOption === 1
                            ? "Upload your video"
                            : templateOption === 1
                              ? "Upload your template"
                              : fontOption === 1
                                ? "Upload your Font"
                                : ""}
                    <input
                      type="file"
                      className="d-none"
                      accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG,.ico,.ICO,.ntp,.NTP,.ttf,.mp4,.MP4"
                      onChange={() => assestUpload()}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      id="assestUpload"
                    />
                  </label>
                </div>
              ) : (
                  <label className="cover-image">
                    <img
                      src={uploadedAsset}
                      alt="uploadedImag"
                      className="object-cover"
                    />
                  </label>
                )}

              {fontOption === 1 ? (
                <div className="img-file-format">{fontName}</div>
              ) : (
                  ""
                )}
            </div>
          </div>
          {uploadedAsset !== "" && (
            <div className="upload-controls d-flex text-center my-2 align-items-center justify-content-center">
              <button
                type="button"
                onClick={() => uploadToRepo()}
                className="btn btn-primary btn-sm me-2 pro-submt-btn border-0

"
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => {
                  setUploadedAsset("");
                  setFontName("");
                }}
                className="btn btn-danger me-2 btn-sm pro-submt-btn"
              >
                Close
              </button>
            </div>
          )}

          <div className="img-file-spec">
            <div className="mt-3 text-center red-text error-msg">
              {uploadErroMessage}
            </div>
            {illuUploadOption === 1 ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be ico and File size is less than 25 mb
              </div>
            ) : uploadOption === 3 ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be ntp and File size is less than 25 mb
              </div>
            ) : fontOption === 1 ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be ttf and File size is less than 25 mb
              </div>
            ) : uploadOption === 2 ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be jpeg, jpg, png and File size is less than
                25 mb
              </div>
            ) : videoOption === 1 ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be mp4 and File size is less than 25 mb
              </div>
            ) : (
                        <div className="cm-style-spec mt-3 text-center">
                          File format should be jpeg, jpg, png and File size is less than
                          25 mb
                        </div>
                      )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
