import Modal from 'react-bootstrap/Modal';
import { bindActionCreators } from 'redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  BgColor,
  TextColor,
  FONTSTYLE,
  FONTFAMILY,
  FONT_SIZE_H,
  FONT_SIZE_P,
} from '../../../constants';
import {
  getProjectStyles,
  saveProjectImage,
  saveProjectStyles,
} from '../../../services/api.service';
import { actionCreator } from '../../../store';
import FormInputColor from '../../../components/form/formInputColor';
import DropdownCommon from '../../../components/form/dropdownCommon';
import CustomFileUpload from '../../../components/form/customFileUpload';
import { longRightArrowIcon, uploadCloudIcon } from '../../../constants/icon';
import { showToaster, splitFontFamilyAndStyle } from '../../../services/helper';

export default function BrandSettingModal(props) {
  const {
    fonts,
    imageUrl,
    fontArray,
    setImageUrl,
    singleScreenSettings,
    settingForSingleScreen,
    clientSetting = null,
    reload = () => {},
    cacheProject = null,
    ...rest
  } = props;

  const params = useParams();
  const dispatch = useDispatch();
  let { SetOstFont, SetStylesData } = bindActionCreators(actionCreator, dispatch);

  const projectID = useSelector(state => state.project.ProjectId);
  const stylesData = useSelector(state => state.project.stylesData);

  const [fontStyle, setFontStyle] = useState();
  const [fontSizeH, setFontSizeH] = useState(30);
  const [fontSizeP, setFontSizeP] = useState(25);
  const [fontFamily, setFontFamily] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [includeLogo, setIncludeLogo] = useState(true);
  const [bgColor, setBgColor] = useState('#FF9A9A');
  const [textColor, setTextColor] = useState('#000000');
  const [tempImageUrl, setTempImageUrl] = useState(imageUrl);

  let fontValue = fontFamily?.value;
  if (fontStyle?.value) {
    fontValue = fontFamily?.value + ' : ' + fontStyle.value;
  }

  useEffect(() => {
    async function brandingData() {
      let data;

      if (stylesData) {
        data = stylesData;
      } else {
        const response = await getProjectStyles(projectID);
        data = response?.data;
      }

      if (data) {
        setFontFamily({
          value: data.fontFamily,
          label: data.fontFamily,
        });
        setFontStyle({
          value: data.fontStyle,
          label: data.fontStyle,
        });
        setFontSizeH(data.headingSize);
        setFontSizeP(data.paragraphSize);
        setTextColor(data.textColor);
        setBgColor(data.bgColor);
      } else {
        setFontFamily({
          value: FONTFAMILY,
          label: FONTFAMILY,
        });
        setFontStyle({
          value: FONTSTYLE,
          label: FONTSTYLE,
        });
        setFontSizeH(FONT_SIZE_H);
        setFontSizeP(FONT_SIZE_P);
        setTextColor(TextColor);
        setBgColor(BgColor);
      }

      setIsLoading(false);
    }

    if (props.show) {
      brandingData();
    }
  }, [props.show]);

  const handleBackgroundColorChange = color => {
    setBgColor(color);
  };

  const handleFontColorChange = color => {
    setTextColor(color);
  };

  const handleChangeFontStyle = font => {
    const { fontFamily, fontStyle } = splitFontFamilyAndStyle(font);

    setFontFamily({ value: fontFamily, label: fontFamily });
    setFontStyle({ value: fontStyle, label: fontStyle });
  };

  const onClickNext = async () => {
    try {
      if (clientSetting && includeLogo && !tempImageUrl) {
        showToaster('Please Upload Project Image or uncheck Include Logo option.', 'error');
      } else {
        const payload = {
          bgColor,
          textColor,
          project_id: projectID,
          fontStyle: fontStyle.value,
          fontFamily: fontFamily.value,
        };

        if (settingForSingleScreen) {
          singleScreenSettings(payload);
        } else {
          SetOstFont(fontFamily.value, fontStyle.value, fontSizeH, fontSizeP, textColor, bgColor);

          SetStylesData({ ...stylesData, ...payload });
          saveProjectStyles(payload);
        }

        if (
          (clientSetting &&
            (clientSetting.showIncludeLogoOption ||
              !clientSetting.hasOwnProperty('showIncludeLogoOption'))) ||
          tempImageUrl
        ) {
          await handleImageUrl();
          if (!cacheProject.ProjectData[cacheProject.ProjectID].Modules) {
            reload(false);
          }
        }

        props.onHide();
      }
    } catch (error) {
      console.log({ saveProjectStylesError: error.message || error });

      showToaster('Error on saving project styles', 'error');
    }
  };

  const handleImageUrl = async () => {
    try {
      await saveProjectImage({
        project_id: params.id || params.projectId,
        imageUrl: tempImageUrl,
        includeLogoImage: includeLogo,
      });
      setImageUrl(tempImageUrl);
    } catch (error) {
      console.log({ handleImageUrlError: error });
    }
  };

  const onClickSkip = () => {
    if (settingForSingleScreen) {
      const payload = {
        bgColor,
        textColor,
        project_id: projectID,
        fontStyle: fontStyle.value,
        fontFamily: fontFamily.value,
      };
      singleScreenSettings(payload);
    }

    props.onHide();
  };

  const tempSaveImageUrl = (imageUrl = '') => setTempImageUrl(imageUrl);

  return (
    <Modal
      {...rest}
      className="brand-setting-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="border-line-bottom">
          <h2>Brand setting</h2>
          <p>
            Upload logo, choose background color, select font-style and font-color to your video.
          </p>
          <CustomFileUpload
            icon={uploadCloudIcon}
            defaultFile={imageUrl}
            handleUpload={tempSaveImageUrl}
            handleRemove={tempSaveImageUrl}
            fileName="Select .png file to upload"
            description="File size max 12mb, orientation 72px x 72px"
          />
        </div>

        <div className="border-line-bottom">
          <h4>Background</h4>
          <FormInputColor
            type="color"
            id="colorInput"
            inputValue={bgColor}
            label="Background-color"
            onColorChange={handleBackgroundColorChange}
          />
        </div>
        <div className="font-style-wrap">
          <h4>Font-style</h4>
          <Row>
            <Col xs={6} md={8}>
              <DropdownCommon
                items={fonts}
                name="customStyles"
                label={'Font-family'}
                defaultValue={fontValue}
                onSelectItem={handleChangeFontStyle}
              />
            </Col>
            <Col xs={6} md={4}>
              <FormInputColor
                type="color"
                id="colorInput"
                label="Font-color"
                inputValue={textColor}
                onColorChange={handleFontColorChange}
              />
            </Col>
          </Row>
        </div>
        {!cacheProject?.ProjectData[cacheProject.ProjectID].Modules &&
          clientSetting &&
          (clientSetting.showIncludeLogoOption ||
            !clientSetting.hasOwnProperty('showIncludeLogoOption')) && (
            <div className="border-line-top">
              <Form>
                <Form.Check // prettier-ignore
                  type="checkbox"
                  id="include-logo-in-the-videos"
                  label="Include Logo in the videos"
                  defaultChecked={includeLogo}
                  onChange={() => {
                    setIncludeLogo(!includeLogo);
                  }}
                />
              </Form>
            </div>
          )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="bg-btn-primary" onClick={onClickNext}>
          Next
        </Button>
        <Button variant="default" onClick={onClickSkip}>
          Skip {longRightArrowIcon}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
