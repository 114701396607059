import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import ReactImageFallback from 'react-image-fallback';

import CropImage from './cropImage';
import CanvasImage from './canvasImage';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../store';
import ImageAssetRepo from './imageAssetRepo';
import { showToaster } from '../../services/helper';
import { cancelCropIcon, checkIcon, cropIcon, plainImageIcon } from '../../constants/icon';
import Videoplayer from '../../modules/components/Videoplayer/Videoplayer';
import VideoAssetRepo from './videoAssetRepo';

const trackStyle = {
  display: 'none',
};

const railStyle = {
  top: '4px',
  width: '100%',
  height: '6px',
  display: 'block',
  position: 'absolute',
  borderRadius: '100px',
  background: 'linear-gradient(270deg, #9CA3AF 0%, #E5E7EB 100%)',
};

const handleStyle = {
  top: '0px',
  zIndex: 9,
  width: '10px',
  height: '14px',
  cursor: 'pointer',
  appearance: 'none',
  background: '#fff',
  position: 'relative',
  border: '2px solid #DFDFDF',
  WebkitTransform: 'rotate(360deg)',
  borderRadius: '50% 50% 50% 50% / 60% 60% 40% 40%',
};

export default function EditAssets({ asset, onHide, type, manipulateTemplateArray = () => {} }) {
  const dispatch = useDispatch();
  const { EditAsset } = bindActionCreators(actionCreator, dispatch);

  const [opacity, setopacity] = useState(100);
  const [newImage, setnewImage] = useState('');
  const [exposure, setexposure] = useState(100);
  const [contrast, setcontrast] = useState(100);
  const [cropMode, setcropMode] = useState(false);
  const [assetRepo, setAssetRepo] = useState(false);
  const [saturation, setsaturation] = useState(100);
  const [showLoader, setshowLoader] = useState(false);
  const [displayImage, setdisplayImage] = useState('');
  const [uploadImage, setuploadImage] = useState(false);
  const [cropImageReq, setcropImageReq] = useState(false);
  const [changedOpacity, setchangedOpacity] = useState(100);
  const [changedContrast, setchangedContrast] = useState(100);
  const [changedExposure, setchangedExposure] = useState(100);
  const [processEditImg, setprocessEditImg] = useState(false);
  const [changedSaturation, setchangedSaturation] = useState(100);

  useEffect(() => {
    setdisplayImage(asset);
  }, [asset]);

  const uploadData = () => {
    if (type === 'video') {
      console.log({ displayImage });
      EditAsset({ contentdescription: displayImage }, 'video');
      showToaster('Video saved successfully', 'success');
      onHide();
    } else if (type === 'illustration') {
      // let asset = props.item[1];
      // asset.Contentparams.contentdescription = displayImage;
      // let data = {
      //   [props.item[0]]: asset,
      // };
      // showToaster('Illustration saved successfully', 'success');
      // UpdatePreviewFeature(data);
      // props.closePreview();
    } else if (type === 'image') {
      setshowLoader(true);
      setprocessEditImg(true);
      setuploadImage(true);
    }
  };

  const handleCropClick = () => {
    setcropMode(true);
    setchangedOpacity(100);
    setprocessEditImg(true);
    setchangedContrast(100);
    setchangedExposure(100);
    setchangedSaturation(100);
  };

  const closeModal = () => {
    onHide();
    setcropMode(false);
    setcropImageReq(false);
  };

  const setVideoData = video => {
    setdisplayImage(video);
    manipulateTemplateArray();
  };

  return (
    <>
      <Modal show={!!asset} centered size="lg" backdrop="static">
        <Modal.Body>
          {showLoader && (
            <div className="loader-wrapper video-loader">
              <div className="loader-inner">
                <img src="../../../assets/img/loader.gif" alt="loader"></img>
              </div>
            </div>
          )}
          <div className="edit-modal d-flex align-items-center flex-lg-row flex-column">
            <div className={cropMode ? 'img-block crop-image' : 'img-block'}>
              {cropMode && !processEditImg ? (
                <CropImage
                  img={newImage}
                  className="img-fluid"
                  closePreview={onHide}
                  setcropMode={setcropMode}
                  cropImageReq={cropImageReq}
                  setcropImageReq={setcropImageReq}
                  setdisplayImage={setdisplayImage}
                />
              ) : type === 'video' ? (
                <Videoplayer thumbnailUrl="" videoUrl={displayImage} showVolume={false} />
              ) : (
                <>
                  {type === 'image' ? (
                    <CanvasImage
                      src={displayImage}
                      closePreview={onHide}
                      setopacity={setopacity}
                      opacity={changedOpacity}
                      uploadImage={uploadImage}
                      setnewImage={setnewImage}
                      setexposure={setexposure}
                      setcontrast={setcontrast}
                      contrast={changedContrast}
                      exposure={changedExposure}
                      setshowLoader={setshowLoader}
                      setsaturation={setsaturation}
                      saturation={changedSaturation}
                      processEditImg={processEditImg}
                      setuploadImage={setuploadImage}
                      setprocessEditImg={setprocessEditImg}
                    />
                  ) : (
                    <ReactImageFallback
                      alt="templates"
                      src={displayImage}
                      className="img-fluid"
                      fallbackImage="../../../assets/img/fallbackImage.jpg"
                      initialImage="../../../assets/img/fallbackImage.jpg"
                    />
                  )}
                </>
              )}
            </div>
            <div className="img-more">
              <div className="d-flex  align-items-center">
                <div className="label-block">
                  <p className="edit-label">Appearance</p>
                </div>
                <div className="field-block d-flex ps-4">
                  <div
                    className={
                      cropMode
                        ? 'd-flex flex-column justify-content-center align-items-center replace-btn not-allowed'
                        : 'd-flex flex-column justify-content-center align-items-center replace-btn'
                    }
                  >
                    <button
                      type="button"
                      disabled={cropMode}
                      onClick={() => setAssetRepo(type)}
                      className="btn editor-btn br-2 mb-1 px-0"
                    >
                      {plainImageIcon}
                    </button>
                    <small>Replace</small>
                  </div>
                  {cropMode ? (
                    <>
                      <div className="d-flex flex-column justify-content-center align-items-center crop-btn active">
                        <button
                          type="button"
                          className="btn editor-btn br-2 mb-1 px-0"
                          onClick={() => setcropMode(false)}
                        >
                          {cancelCropIcon}
                        </button>
                        <small>Cancel Crop</small>
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center crop-btn active">
                        <button
                          type="button"
                          className="btn editor-btn br-2 mb-1 px-0"
                          onClick={() => setcropImageReq(true)}
                        >
                          {checkIcon}
                        </button>
                        <small>Apply</small>
                      </div>
                    </>
                  ) : (
                    type !== 'video' &&
                    type !== 'illustration' && (
                      <div className="d-flex flex-column justify-content-center align-items-center crop-btn">
                        <button
                          type="button"
                          onClick={handleCropClick}
                          className="btn editor-btn br-2 mb-1 px-0"
                        >
                          {cropIcon}
                        </button>

                        <small>Crop</small>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="divider"></div>
              <div
                className={
                  cropMode || type === 'video' || type === 'illustration' ? 'custom-disabled' : ''
                }
              >
                <div className="d-flex  align-items-center mb-3">
                  <div className="label-block">
                    <p className="edit-label">Exposure</p>
                  </div>
                  <div className="field-block d-flex ps-4">
                    <div className="custom-range-1 d-flex flex-fill align-items-center">
                      <div className="flex-fill me-2 position-relative">
                        <Slider
                          min={1}
                          max={200}
                          value={exposure}
                          className="range"
                          railStyle={railStyle}
                          defaultValue={exposure}
                          trackStyle={trackStyle}
                          handleStyle={handleStyle}
                          onChange={e => setexposure(e)}
                          onAfterChange={e => setchangedExposure(e)}
                        />
                      </div>
                      <output className="output border-0" id="">
                        {exposure}%
                      </output>
                    </div>
                  </div>
                </div>
                <div className="d-flex  align-items-center mb-3">
                  <div className="label-block">
                    <p className="edit-label">Contrast</p>
                  </div>
                  <div className="field-block d-flex ps-4">
                    <div className="custom-range-1 d-flex flex-fill align-items-center">
                      <div className="flex-fill me-2 position-relative">
                        <Slider
                          min={1}
                          max={200}
                          value={contrast}
                          className="range"
                          railStyle={railStyle}
                          trackStyle={trackStyle}
                          handleStyle={handleStyle}
                          onChange={e => setcontrast(e)}
                          onAfterChange={e => setchangedContrast(e)}
                        />
                      </div>
                      <output className="output border-0" id="">
                        {contrast}%
                      </output>
                    </div>
                  </div>
                </div>
                <div className="d-flex  align-items-center">
                  <div className="label-block">
                    <p className="edit-label">Saturation</p>
                  </div>
                  <div className="field-block d-flex ps-4">
                    <div className="custom-range-1 d-flex flex-fill align-items-center">
                      <div className="flex-fill me-2 position-relative">
                        <Slider
                          min={1}
                          max={200}
                          className="range"
                          value={saturation}
                          railStyle={railStyle}
                          trackStyle={trackStyle}
                          handleStyle={handleStyle}
                          onChange={e => setsaturation(e)}
                          onAfterChange={e => setchangedSaturation(e)}
                        />
                      </div>
                      <output className="output border-0" id="">
                        {saturation}%
                      </output>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="d-flex  align-items-center mb-4">
                  <div className="label-block">
                    <p className="edit-label">Opacity</p>
                  </div>
                  <div className="field-block d-flex ps-4">
                    <div className="custom-range d-flex align-items-center flex-fill">
                      <div className="flex-fill me-2 position-relative">
                        <Slider
                          min={1}
                          max={200}
                          value={opacity}
                          className="range"
                          railStyle={{
                            ...railStyle,
                            background:
                              'linear-gradient(90deg, rgba(229, 231, 235, 0) 0%, rgba(229, 231, 235, 0.68) 35.94%, #56C4F5 68.75%, #C156C2 100%)',
                          }}
                          handleStyle={{
                            ...handleStyle,
                            appearance: 'none',
                            boxSizing: 'border-box',
                            WebkitAppearance: 'none',
                          }}
                          trackStyle={trackStyle}
                          onChange={e => setopacity(e)}
                          onAfterChange={e => setchangedOpacity(e)}
                        />
                      </div>
                      <output className="output border-0" id="">
                        {opacity}%
                      </output>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end footer-btn">
                <button
                  type="button"
                  className="btn btn-outline me-2  rounded-0"
                  data-bs-dismiss="modal"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={cropMode}
                  onClick={uploadData}
                  className="btn btn-primary  rounded-0"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ImageAssetRepo
        open={assetRepo === 'image'}
        onHide={() => setAssetRepo(false)}
        selectImage={value => setdisplayImage(value)}
      />

      <VideoAssetRepo
        type={type}
        setdisplayImage={setVideoData}
        openAssetRpo={assetRepo === 'video'}
        closePreview={() => setAssetRepo(false)}
      />
    </>
  );
}
