import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  helpIcon,
  mailIcon,
  memberPlusIcon,
  orgGroupIcon,
  renderIcon,
  stackPlayIcon,
  uploadIcon,
  userIcon,
} from '../../../../constants/icon';
import UserThumbnail from '../../../../assets/img/user-thumbnail.png';
import UserProfileImage from '../../../../assets/img/user-image.png';
import ProfileList from '../user/profileList';
import ProfileListItem from '../user/profileListItem';
import WorkedTable from '../user/workedTable';
import EditProfileForm from './editProfileForm';

function EditProfileContent() {
  return (
    <>
      <div className="secondary-header secondary-sticky-header justify-content-end">
        <Button variant="default">{renderIcon}Export</Button>
      </div>

      <div className="user-management-wrapper">
        <div className="user-sidebar">
          <div className="user-thumbnail">
            <div className="user-img-holder">
              <div className="user-img-wrap">
                <img src={UserThumbnail} alt="user thumnail" />
              </div>
              <div className="user-circle">
                <img src={UserProfileImage} alt="profile image" />
              </div>
            </div>
            <div className="user-info">
              <h4>Ganesh Shrestha</h4>
              <Link to="mailto:ganesh.shrestha@olivegroup.io">ganesh.shrestha@olivegroup.io</Link>
            </div>
            <Button variant="default">Reset Password</Button>
          </div>

          <div className="profile-block">
            <h2>Profile</h2>

            <h4>About</h4>
            <ProfileList>
              <ProfileListItem icon={userIcon} name="Ganesh Shrestha" />
              <ProfileListItem icon={memberPlusIcon} name="Role: Admin" />
              <ProfileListItem icon={orgGroupIcon} name="Org: Olive Group" />
            </ProfileList>

            <h4>Contacts</h4>
            <ProfileList>
              <ProfileListItem icon={mailIcon} name="ganesh.shrestha@olivegroup.io" />
              <ProfileListItem icon={helpIcon} name="+91 - 1234567890" />
            </ProfileList>

            <h4>Teams</h4>
            <ProfileList>
              <ProfileListItem icon={memberPlusIcon} name="Member of 5 teams" />
              <ProfileListItem icon={stackPlayIcon} name="Working on 4 projects" />
            </ProfileList>
          </div>
        </div>

        <div className="table-container">
          <div className="common-box">
            <div className="common-box-header">
              <h4>Edit Your Profile</h4>
              <button role="button" className="outline-primary-btn">
                Save
                {uploadIcon}
              </button>
            </div>
            <EditProfileForm />
          </div>
          <div className="common-box">
            <div className="common-box-header">
              <h4>Worked on</h4>
            </div>
            <WorkedTable />
          </div>
        </div>
      </div>
    </>
  );
}
export default EditProfileContent;
