import { useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { FONT_URL } from '../../../constants';
import { actionCreator } from '../../../store';
import CustomModal from '../../../services/CustomModal';
import VideoPreview from '../../../components/videoPreview';
import Carousel from '../../../components/carousel/carousel';
import { plusIcon, crossIcon } from '../../../constants/icon';
import { getProjectStyles } from '../../../services/api.service';
import EditableContent from '../../../components/EditableContent';
import { showToaster, templateName } from '../../../services/helper';
import { CreateScreen, CreateScreenManual } from '../../../services/DataHelper';

export default function AddScreen(props) {
  const dispatch = useDispatch();
  const { Addscreen, SelectScreen, RemoveScreen, RenameScreenName } = bindActionCreators(
    actionCreator,
    dispatch
  );

  const project = useSelector(state => state.project);

  const [deleteScreenId, setDeleteScreenId] = useState('');
  const [activeMediaUrl, setActiveMediaUrl] = useState('');

  const screens = useMemo(() => {
    // let dateTime = new Date().getTime();
    // if (project.previewGeneratedOf !== cachePreviewGeneratedOf) {
    //   setCachePreviewGeneratedOf(project.previewGeneratedOf);

    //   if (project.previewGeneratedOf) dateTime = new Date().getTime();
    // }

    const screensData =
      project?.createdProject?.[project.ProjectId]?.Modules?.[project.ActiveModule]?.slides?.[
        project.ActiveSlide
      ]?.Screens;
    if (screensData) {
      return Object.entries(screensData).map(([screenKey, screenValue]) => {
        if (screenKey === project.ActiveScreen) {
          setActiveMediaUrl(screenValue.mediaurl?.PreviewUrl);
        }
        return {
          timer: {
            key: `${project.ActiveModule}-${project.ActiveSlide}-${screenKey}`,
            timeout:
              props.showRefreshIcon?.[
                `${project.ActiveModule}-${project.ActiveSlide}-${screenKey}`
              ],
            setRefreshTimeOut: props.setRefreshTimeOut,
          },
          uniqueKey: screenKey,
          title: screenValue.ScreenName,
          image: screenValue.mediaurl?.ThumbnailUrl,
          hasError: props.hasTemplateError(screenKey)?.isInValid,
          crashed: screenValue.mediaurl && screenValue?.mediaurl?.PreviewUrl ? false : true,
          // screenText: screenValue['TM01']?.Contentparams.contentdescription,
        };
      });
    }
  }, [project, props.showRefreshIcon]);

  const showRemoveIcon = screens?.length > 1 ? crossIcon : false;
  const screenLimitReached = props.clientSetting.screenLimit <= screens?.length;
  const screenName = screens?.find(screen => screen.uniqueKey === project.ActiveScreen)?.title;

  const handleScreenClick = uniqueKey => {
    // const isPreviewGenerating = props.hasTemplateError(uniqueKey)?.isPreviewGenerating;

    // if (isPreviewGenerating) {
    //   return showToaster(`Preview is generating for ${uniqueKey} `, 'info', 5000);
    // }
    SelectScreen(uniqueKey);
  };

  const onChangeScreenName = name => {
    RenameScreenName(name);
  };

  const addScreen = async () => {
    if (screenLimitReached) return;

    /* get active slide number */
    const slideNo = project.ActiveSlide.replace('SL', '');

    const projectScreens =
      project.createdProject[project.ProjectId].Modules?.[project.ActiveModule]?.slides?.[
        project.ActiveSlide
      ].Screens;

    const projectScreenKeys = Object.keys(projectScreens);
    const lastScreen = projectScreenKeys[projectScreenKeys.length - 1];

    const screenNumber = lastScreen.replace(`SC${slideNo}.0`, '');
    const screenNumberSplit = screenNumber.split(`SC${slideNo}.`)[1];

    let data;
    if (screenNumberSplit) data = parseInt(screenNumberSplit) + 1;
    else data = parseInt(screenNumber) + 1;

    const newScreenId = data < 10 ? `SC${slideNo}.0${data}` : `SC${slideNo}.${data}`;
    const screenName = data < 10 ? '0' + data : data;

    let newScreen;
    if (props.clientSetting.manualTemplate) {
      if (project.EditMode) {
        let style;
        if (project.stylesData) {
          style = project.stylesData;
        } else {
          const styleResponse = await getProjectStyles(project.ProjectId);
          style = styleResponse.data;
        }

        const fontUrl = FONT_URL + style.fontFamily + '/' + style.fontStyle;

        newScreen = await CreateScreenManual(
          newScreenId,
          screenName,
          fontUrl,
          style.headingSize,
          style.paragraphSize,
          style.textColor,
          style.bgColor
        );
      } else {
        newScreen = await CreateScreenManual(
          newScreenId,
          screenName,
          project.FontUrl,
          project.FontHSize,
          project.FontPSize,
          project.TextColor,
          project.BgColor
        );
      }
    } else {
      if (project.EditMode) {
        let style;
        if (project.stylesData) {
          style = project.stylesData;
        } else {
          const styleResponse = await getProjectStyles(project.ProjectId);
          style = styleResponse.data;
        }

        const fontUrl = FONT_URL + style.fontFamily + '/' + style.fontStyle;

        newScreen = await CreateScreen(
          newScreenId,
          screenName,
          fontUrl,
          style.headingSize,
          style.paragraphSize,
          style.textColor,
          style.bgColor
        );
      } else {
        newScreen = await CreateScreen(
          newScreenId,
          screenName,
          project.FontUrl,
          project.FontHSize,
          project.FontPSize,
          project.TextColor,
          project.BgColor
        );
      }
    }

    if (props.cacheProject?.includeLogoImage) {
      newScreen[newScreenId]['IM01']['Contentparams']['contentdescription'] =
        props.cacheProject.imageUrl;
      newScreen[newScreenId]['IM01']['Contentparams']['contenttag'] = 'logo';
    }
    Addscreen(newScreen);
  };

  const handleRemoveScreen = () => {
    if (showRemoveIcon) {
      RemoveScreen(deleteScreenId);
      setDeleteScreenId('');
      showToaster(`${project.ActiveScreen} Screen Deleted Successfully`, 'success');
    }
  };

  return (
    <>
      <div className="screen-body">
        <div className="screen-title-wrap">
          <EditableContent
            editable={true}
            initialContent={screenName}
            onClick={onChangeScreenName}
            className="editable-dark-text"
          />
        </div>
        <VideoPreview
          alt="video preview"
          activeMediaUrl={activeMediaUrl}
          src="../../assets/img/video-preview.png"
        />
      </div>
      <div className="screen-footer">
        <div className="add-new-section-wrap">
          <button onClick={addScreen} className="add-new-screen-btn" disabled={screenLimitReached}>
            {plusIcon} <span>Add New Screen</span>
          </button>
          {screenLimitReached && (
            <div className="screen-thumbnail-items-wrap">
              <span>Max. screen limit reached</span>
            </div>
          )}
        </div>
        <div className="slider-main-wrapper">
          <Carousel
            items={screens}
            screenText={templateName}
            crossBtn={showRemoveIcon}
            handleClick={handleScreenClick}
            handleRemove={setDeleteScreenId}
            activeItem={project.ActiveScreen}
            generateRefreshIcon={props.generateRefreshIcon}
            slidesToShow={2.8}
            variableWidth={true}
          />
        </div>
      </div>
      <CustomModal
        Header="Are you sure"
        isOpen={!!deleteScreenId}
        Buttonlabel="Yes, Delete it!"
        Closemodal={setDeleteScreenId}
        Buttonclick={handleRemoveScreen}
        Content={`Do you want to delete Screen ${deleteScreenId}?`}
      />
    </>
  );
}
