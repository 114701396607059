import React from 'react';

function RadioButton({ title, description, checked, onChange }) {
  return (
    <label className={`radio-label ${checked ? 'checked' : ''}`}>
      <input type="radio" name="visibleRadio" checked={checked} onChange={onChange} />
      <div className="radio-circle">
        <div className="radio-inner"></div>
      </div>
      <div className="radio-info">
        <h4>{title}</h4>
        <span>{description}</span>
      </div>
    </label>
  );
}

export default RadioButton;
