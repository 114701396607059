import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import InnerSidebar from '../../../../components/sidebar/innerSidebar';
import InnerSidebarHeader from '../../../../components/sidebar/innerSidebarHeader';
import EditableContent from '../../../../components/EditableContent';
import AccordionItem from './accordionItem';

import { refreshIcon, videoIcon } from '../../../../constants/icon';
import CustomToggle from '../../../../components/accordion/CustomToggle';
import { secondsToHrMinS } from '../../../../lib/time-conversion';
import { useEffect } from 'react';
import { getProject } from '../../../../services/api.service';
import { getProjectStatus } from '../../../../services/apiServices';
import { showToaster } from '../../../../services/helper';

function DetailInnerSidebar(props) {
  const {
    title,
    renderList,
    totalRender,
    currentPage,
    setActiveSize,
    setActiveDuration,
    setActiveVideoURL,
    setActiveRender,
    setActiveModule,
    setActiveSlide,
    setCurrentPage,
    setRenderList,
    setFetching,
    activeRender,
    activeSlide,
    fetching,
    id,
  } = props;
  // Accordion Start
  const [activeKeys, setActiveKeys] = useState([]);
  const [isHeaderActive, setIsHeaderActive] = useState({});
  const [projectName, setProjectName] = useState();

  const handleAccordionClick = eventKey => {
    if (activeKeys.includes(eventKey)) {
      setActiveRender(eventKey);
      setActiveKeys(activeKeys.filter(key => key !== eventKey));
      setIsHeaderActive({ ...isHeaderActive, [eventKey]: false });
    } else {
      setActiveKeys([...activeKeys, eventKey]);
      setIsHeaderActive({ ...isHeaderActive, [eventKey]: true });
      setActiveRender(eventKey);
    }
  };
  const handleModuleClick = (eventKey, moduleId, slideKeys) => {
    if (activeKeys.includes(eventKey)) {
      setActiveModule(moduleId);
      setActiveKeys(activeKeys.filter(key => key !== eventKey));
      setIsHeaderActive({ ...isHeaderActive, [eventKey]: false });
      setActiveSlide(slideKeys);
    } else {
      setActiveModule(moduleId);
      setActiveKeys([...activeKeys, eventKey]);
      setIsHeaderActive({ ...isHeaderActive, [eventKey]: true });
    }
  };

  const onVideoClick = (size, url, duration, slideId) => {
    setActiveSlide(slideId);
    setActiveDuration(duration);
    setActiveSize(size);
    setActiveVideoURL(url);
  };

  const countSlidesWithUrl = renderUrls => {
    let slideCount = 0;

    for (const moduleKey in renderUrls) {
      const module = renderUrls[moduleKey];
      for (const slideKey in module) {
        const slide = module[slideKey];
        if (slide && slide?.url) {
          slideCount++;
        }
      }
    }

    return slideCount;
  };

  const countSlides = moduleData => {
    let totalSlideCount = 0;

    for (const slideKey in moduleData) {
      if (Object.hasOwnProperty.call(moduleData, slideKey) && slideKey.startsWith('SL')) {
        totalSlideCount++;
      }
    }

    return totalSlideCount;
  };

  // Accordion end

  useEffect(() => {
    async function init() {
      try {
        const projectResponse = await getProject(id);
        if (projectResponse) {
          setProjectName(projectResponse.ProjectName);
        }
      } catch (error) {
        console.log({ error });
      }
    }

    init();
  }, []);

  const refreshProjectStats = async projectId => {
    let renderFilter = {
      project_id: projectId,
      projectName: projectName,
    };

    getProjectStatus(renderFilter).then(async response => {
      if (response.data) {
        if (response.data.is_valid === 0) {
          showToaster('There is no valid data for rendering the project!!', 'warning');
        } else if (response.apiStatus === false) {
          showToaster('Error, please try rendering video again', 'warning');
        } else if (response.message == 'Error, please try rendering video again') {
          showToaster('Error, please try rendering video again', 'warning');
        } else {
          if (response.data.mediaurl && Object.keys(response.data.mediaurl).length !== 0) {

            if (response.data.mediaurl.status == 'Server error! Please check your quota limits.') {
              showToaster('Server error! Please check your quota limits.', 'warning');
              return;
            }
            const updatedRenderList = renderList.map(render => {
                
              if (render.RenderID === response.data.project_id) {
                // Update RenderStatus with the value from response
                return { ...render, RenderStatus: response.data.renderstatus };
              }
              return render;
            });
            setRenderList(updatedRenderList);
            if (response.data.mediaurl.status === 'Rendering completed') {
              showToaster('Rendering Complete', 'success');
            }
          }
        }
      }
    });
  };

  const handleRenderClick = index => {
    setActiveRender(index); 
  };

  const moveToNextPage = () => {
    setFetching(true);
    setCurrentPage(currentPage + 1);
  };
  console.log(renderList, 'renderList');

  return (
    <InnerSidebar>
      <InnerSidebarHeader
        title={title}
        subTitle={`Total Video: ${renderList.length}`}
        icon={videoIcon}
      />
      <Accordion
        className="custom-accordion detail-inner-accordion"
        activeKey={activeKeys}
        onSelect={handleAccordionClick}
      >
        {renderList?.length
          ? renderList?.map((item, index) => {
              if (item.renderUrls) {
                return (
                  <Accordion.Item
                    eventKey={index}
                    className={activeRender === index ? 'active-render' : ''}
                  >
                    <div
                      className={`accordion-header ${isHeaderActive ? 'active' : ''} ${
                        activeRender === index ? 'header-active' : ''
                      }`}
                      onClick={() => handleRenderClick(index)}
                    >
                      <div className="accordion-sub-header">
                        <EditableContent editable={true} initialContent={item?.renderName} />
                        <div className="accordion-sub-info">
                          <span>Total videos: {countSlidesWithUrl(item?.renderUrls)}</span>
                          <span>
                            {item?.renderError === 0
                              ? 'Crashed'
                              : item?.renderError === 2
                              ? 'Aborted'
                              : item?.renderError === 3
                              ? 'Time Out'
                              : ''}
                          </span>
                          <span>{item?.RenderStatus}</span>
                          {item?.isRendering && item?.renderError === 1 ? (
                            <div
                              className="refresh-icon"
                              onClick={() => refreshProjectStats(item?.RenderID)}
                            >
                              {refreshIcon}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <CustomToggle
                        eventKey={index}
                        isActive={activeKeys.includes(index)}
                        onClick={
                          countSlidesWithUrl(item?.renderUrls)
                            ? () => handleAccordionClick(index)
                            : null
                        }
                        isHeaderActive={isHeaderActive}
                      />
                    </div>
                    <Accordion.Body>
                      {item?.renderUrls &&
                        Object.keys(item?.renderUrls)?.map((moduleId, mIndex) => {
                          let slideKeys;
                          Object.keys(item?.renderUrls?.[moduleId]).map(slideKey => {
                            if (slideKey !== 'ModuleName') {
                              slideKeys = slideKey;
                            }
                          });
                          if (
                            moduleId !== 'status' &&
                            item?.renderUrls?.[moduleId]?.[slideKeys]?.['screens']
                          ) {
                            return (
                              <AccordionItem
                                key={mIndex + index}
                                eventKey={(mIndex + index).toString()}
                                isActive={activeKeys.includes((mIndex + index).toString())}
                                modalTitle={item?.renderUrls?.[moduleId]?.ModuleName}
                                totalVideo={`Total videos: ${countSlides(
                                  item?.renderUrls?.[moduleId]
                                )}`}
                                isHeaderActive={
                                  isHeaderActive[(mIndex + index).toString()] &&
                                  activeRender === index
                                }
                                onHeaderClick={() =>
                                  handleModuleClick(
                                    (mIndex + index).toString(),
                                    moduleId,
                                    slideKeys
                                  )
                                }
                              >
                                <>
                                  <ul className="accordion-inner-sidebar-list">
                                    {Object.keys(item?.renderUrls?.[moduleId]).map(
                                      (screenId, subIndex) =>
                                        screenId !== 'ModuleName' ? (
                                          <li
                                            key={subIndex}
                                            onClick={() =>
                                              onVideoClick(
                                                (
                                                  item?.renderUrls?.[moduleId]?.[screenId]?.size /
                                                  1024 /
                                                  1024
                                                )?.toFixed(2),
                                                item?.renderUrls?.[moduleId]?.[screenId]?.url,
                                                secondsToHrMinS(
                                                  item?.renderUrls?.[moduleId]?.[screenId]?.duration
                                                ),
                                                screenId
                                              )
                                            }
                                            className={
                                              isHeaderActive[(mIndex + index).toString()] &&
                                              activeRender === index &&
                                              activeSlide === screenId
                                                ? 'slide-active'
                                                : ''
                                            }
                                          >
                                            <h4>
                                              {item?.renderUrls?.[moduleId]?.[screenId]?.SlideName}{' '}
                                            </h4>
                                            <span>{`${
                                              (
                                                item?.renderUrls?.[moduleId]?.[screenId]?.size /
                                                1024 /
                                                1024
                                              )?.toFixed(2) ?? 0
                                            }MB |
                                         ${
                                           secondsToHrMinS(
                                             item?.renderUrls?.[moduleId]?.[screenId]?.duration
                                           ) ?? '0 Sec'
                                         }`}</span>
                                          </li>
                                        ) : null
                                    )}
                                  </ul>
                                </>
                              </AccordionItem>
                            );
                          }
                        })}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              }
            })
          : null}
      </Accordion>
      {totalRender > 7 && renderList.length > 7 && totalRender !== renderList.length ? (
        <button
          className="btn btn-outline-primary text-nowrap sm-btn load-more "
          onClick={() => moveToNextPage()}
        >
          {fetching ? 'Loading...' : ' Load More'}
        </button>
      ) : null}
    </InnerSidebar>
  );
}
export default DetailInnerSidebar;
