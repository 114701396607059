import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../../store";
import {
  createProjectsPreview,
  getProjectPreview,
  getProjectID,
  getClusterDetails,
  listClusterDetails,
  updateClusterDetails
} from "../../../services/api.service";
import Tooltip from "@mui/material/Tooltip";
import { showToaster, getUserItem } from "../../../services/helper";
import { projectScreenPreview } from "../../../services/api.service";
import PreviewModal from "./RightBar/PreviewModal";
import RenderVideo from "./RenderProject";
import RenderSerialVideo from "./RenderSerialProject";
import CustomModal from "../../../services/CustomModal";
import VideoplayerWithPreview from "../../components/Videoplayer/VideoplayerWithPreview";
import { CreatePreviewFromResponse, removeMediaUrlFilter } from "../../../services/DataHelper";
import { BULLETFONT, HEADERFONT, PARAGRAPHFONT } from "../../../constants";
import { getProjectById, getTemplatePlanByOrg, getUsersProjectRole, updateuserProjectRole } from "../../../services/authService";
import { Checkbox } from "@mui/material";
import { Modal, ModalBody } from "reactstrap";
import { customAlphabet } from "nanoid/async";
import { createdProject, updateProjectName } from "../../../store/action";
import PageLoader from "../../../services/pageLoader";

function PreviewVideo(props) {
  const nanoid = customAlphabet("abcdefghijklmnopqrstuvzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890", 24);
  const project = useSelector((state) => state.preview.previewProject);
  const projectId = useSelector((state) => state.preview.ProjectId);
  const ActiveModule = useSelector((state) => state.preview.ActiveModuleId);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const ActiveSlide = useSelector((state) => state.preview.ActiveSlideId);
  const ActiveScreen = useSelector((state) => state.preview.ActiveScreenId);
  const ActiveSlideName = useSelector((state) => state.preview.ActiveSlideName);
  const ActiveScreenName = useSelector((state) => state.preview.ActiveScreenName);
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [showVideo, setshowVideo] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [previewLoader, setPreviewLoader] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const dispatch = useDispatch();
  const [madeChange, setMadeChange] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(false);
  const [renderCount, setrenderCount] = useState(1);
  const [OpenBacktoEdit, setOpenBacktoEdit] = useState(false);
  const [retry, setretry] = useState(false);
  const [openPreviewModals, setOpenPreviewModals] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState(undefined);
  const [templateAvailable, setTemplateAvailable] = useState(false);
  const [priviewPageLoad, setPriviewPageLoad] = useState(false);
  const [openRenderVideo, setOpenRenderVideo] = useState(false);
  const [openRenderVideoSerial, setOpenRenderVideoSerial] = useState(false);
  const [getRenderVideo, setGetRenderVideo] = useState(false);
  const [priviewrenderUrl, setPriviewrenderUrl] = useState("");
  const [confirmmsg, setconfirmmsg] = useState("");
  const [renderProjectId, setrenderProject] = useState(undefined);
  const [confirmbtnlabel, setconfirmbtnlabel] = useState("");
  const [openRenderModal, setopenRenderModal] = useState(false);
  const [openRenderModalSerial, setopenRenderModalSerial] = useState(false);
  const [openRenderModal1, setopenRenderModal1] = useState(false);
  const [openRenderModal2, setopenRenderModal2] = useState(false);
  const [slideCount, setSlideCount] = useState([]);
  const [videos, setVideos] = useState([]);
  const [moduleCount, setModuleCount] = useState([]);
  const [slideRenderCheckArray, setSlideRenderCheckArray] = useState([]);
  let { UpdateThumbnail, InitilizePreview, UpdatePreview } = bindActionCreators(actionCreator, dispatch);
  const [cacheProjectData, setcacheProjectData] = useState(undefined);
  let COUNT = 0;
  const [projectRoleEdit, setProjectRoleEdit] = useState(false);
  const [renderValidate, setrenderValidate] = useState(0);
  const [pageLoad, setPageLoad] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [slideRenderName, setSlideRenderName] = useState(undefined);
  const [renderId, setRenderId] = useState(undefined);
  const [clusterName, setClusterName] = useState(undefined);
  const [slideRenderDatas, setSlideRenderData] = useState(undefined);
  const [slideChangeStatus, setslideChangeStatus] = useState(false);
  const [renderName, setRenderName] = useState();

  let orgId = getUserItem("orgId");
  let userId = getUserItem("id");
  let AdminRole = getUserItem("role");
  // const VideoElement = ({ previewUrl, backGroundColor }) => {
  //     const [loading, setLoading] = useState(false)
  //     const video = useRef(null)
  //     const _onLoadedMetadata = (ev) => {
  //         video.current.onloadeddata = (ev) => {
  //             const { target } = ev
  //             target.currentTime = target.duration / 3
  //             setLoading(false)
  //         }
  //     }
  //     useEffect(() => {
  //         setLoading(true)
  //     }, [previewUrl])
  //     useEffect(() => {
  //         setMadeChange(false)
  //     }, [])
  //     const _video = useMemo(() => {
  //         if (previewUrl !== "") {
  //             return (
  //                 <>
  //                     <video id="video"
  //                         controls="controls"
  //                         width="100%"
  //                         autoPlay
  //                         preload="auto" ref={video} onLoadedMetadata={_onLoadedMetadata} src={previewUrl}>
  //                         Your browser does not support the video tag.
  //                     </video>
  //                 </>
  //             )
  //         }
  //         else return null
  //     }, [previewUrl])
  //     return (
  //         <>
  //             {_video}
  //             {loading && (
  //                 <div className="masking-loader-wrapper" style={{ backgroundColor: backGroundColor ? backGroundColor : "" }}>
  //                     {previewUrl !== "" && (
  //                         <div >Preview is Loading... Please wait</div>
  //                     )}
  //                 </div>
  //             )}
  //         </>
  //     )
  // }
  const checkProjectRole = async () => {
    if (AdminRole === 1) {
      await getUsersProjectRole(userId).then((response) => {
        try {
          setPageLoaded(true);
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            // setCategoryList(ddd)
            setPageLoaded(false);
            let tempArray = [];
            let projectRole = response.data.result;
            projectRole.map((projectrole) => {
              if (projectrole.projectId === projectId && projectrole.editProject === false) {
                setProjectRoleEdit(true);
              } else {
              }
              return projectrole;
            });
            //   setProjectRoleProjectId(tempArray)
          } else {
            setPageLoaded(false);
            showToaster("Something went wrong. Please Try Again1", "warning");
          }
        } catch (error) {
          setPageLoaded(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      });
    }
  };
  const refreshProjectData = () => {
    window.location.reload();
  };
  useEffect(async () => {
    setrenderValidate(undefined);
    if (project[projectId] != null) {
      if (project[projectId].Modules != undefined) {
        checkProjectRole();
        setLoaded(true);
        setThumbnailUrl(undefined);
        setPreviewUrl(undefined);
        if (project != undefined && ActiveModule != undefined && ActiveSlide != undefined && ActiveScreen != undefined) {
          let mediaUrls = await setMedialUrls(ActiveScreen, ActiveSlide, ActiveModule);
          if (mediaUrls != undefined) {
            if (mediaUrls["ThumbnailUrl"] != undefined && mediaUrls["ThumbnailUrl"] != null && mediaUrls["ThumbnailUrl"] != "") {
              setThumbnailUrl(mediaUrls["ThumbnailUrl"] + "?time=" + Date.now());
            }
            if (mediaUrls["PreviewUrl"] != undefined && mediaUrls["PreviewUrl"] != null && mediaUrls["PreviewUrl"] != "") {
              setPreviewUrl(mediaUrls["PreviewUrl"] + "?time=" + Date.now());
            }
          }
        }
      }
    } else {
      setLoaded(false);
    }
    setrenderCount(renderCount + 1);

    if (renderCount > 2) {
      setMadeChange(true);
    }

    if (renderCount == 2) {
    }

    let slideRenderData = project;
    if (project != undefined) {
      if (slideRenderData[projectId] != null) {
        if (slideRenderData[projectId].Modules != undefined) {
          let c = 0;
          let template;
          Object.keys(slideRenderData[projectId].Modules).map((module) => {
            Object.keys(slideRenderData[projectId].Modules[module].slides).map((slide) => {
              Object.keys(slideRenderData[projectId].Modules[module].slides[slide].Screens).map(async (item, i) => {
                template = slideRenderData[projectId].Modules[module].slides[slide].Screens[item]["TM01"];
              });
              if (
                slideRenderData[projectId].Modules[module].slides[slide].SlideRender !== "0" &&
                template.Contentparams.contentdescription !== "no template"
              ) {
                c++;
                //   setrenderValidate(true)
              }
            });
          });
          setrenderValidate(c);
          return slideRenderData;
          // setthumbnailRequested(true)
        }
      }
    }

    // if (renderCount != 0) {
    //     if (loaded) {
    //         setMadeChange(true)
    //     }
    // } else {
    //     if (loaded) {
    //         setMadeChange(false)
    //         setrenderCount(1)
    //         setPreviewLoader(false)
    //         setThumbnailUrl(undefined)
    //         getSlidePreview()
    //     }
    // }
  }, [project]);

  const slideRender = () => {
    let slideValidArray = [];
    let slideRenderData = project;
    if (project != undefined) {
      if (slideRenderData[projectId] != null) {
        if (slideRenderData[projectId].Modules != undefined) {
          let template;
          Object.keys(slideRenderData[projectId].Modules).map((module) => {
            Object.keys(slideRenderData[projectId].Modules[module].slides).map((slide) => {
              Object.keys(slideRenderData[projectId].Modules[module].slides[slide].Screens).map(async (item, i) => {
                template = slideRenderData[projectId].Modules[module].slides[slide].Screens[item]["TM01"];
              });
              if (
                slideRenderData[projectId].Modules[module].slides[slide].SlideRender !== "0" &&
                template.Contentparams.contentdescription !== undefined &&
                template.Contentparams.contentdescription !== null &&
                template.Contentparams.contentdescription !== "no template"
              ) {
                let templateValid = {
                  isValid: slideRenderData[projectId].Modules[module].slides[slide].SlideRender !== "0" ? true : false,

                  screenName: module + "-" + slide
                };
                slideValidArray.push(templateValid);
                //   setrenderValidate(true)
              }
            });
          });
          setSlideRenderCheckArray(slideValidArray);
          return slideRenderData;
          // setthumbnailRequested(true)
        }
      }
    }
  };

  useEffect(() => {
    if (props.mediaUrls != undefined) {
      setPreviewLoader(true);
      setshowVideo(false);
      if (props.mediaUrls["ThumbnailUrl"] != undefined && props.mediaUrls["ThumbnailUrl"] != null && props.mediaUrls["ThumbnailUrl"] != "") {
        setThumbnailUrl(props.mediaUrls["ThumbnailUrl"] + "?time=" + Date.now());
      }
      if (props.mediaUrls["PreviewUrl"] != undefined && props.mediaUrls["PreviewUrl"] != null && props.mediaUrls["PreviewUrl"] != "") {
        setPreviewUrl(props.mediaUrls["PreviewUrl"] + "?time=" + Date.now());
      }
    }
  }, [props.mediaUrls]);

  useEffect(async () => {
    setPageLoaded(true);
    setTimeout(() => {
      setPageLoaded(false);
    }, 2000);
    setThumbnailUrl(undefined);
    setPreviewUrl(undefined);
    if (project != undefined && ActiveModule != undefined && ActiveSlide != undefined && ActiveScreen != undefined) {
      let mediaUrls = await setMedialUrls(ActiveScreen, ActiveSlide, ActiveModule);
      if (mediaUrls != undefined) {
        if (mediaUrls["ThumbnailUrl"] != undefined && mediaUrls["ThumbnailUrl"] != null && mediaUrls["ThumbnailUrl"] != "") {
          setThumbnailUrl(mediaUrls["ThumbnailUrl"] + "?time=" + Date.now());
        }
        if (mediaUrls["PreviewUrl"] != undefined && mediaUrls["PreviewUrl"] != null && mediaUrls["PreviewUrl"] != "") {
          setPreviewUrl(mediaUrls["PreviewUrl"] + "?time=" + Date.now());
        }
      }
    }
    if (renderCount > 2) {
      setLoaded(true);
      // setPreviewLoader(false)
      // setThumbnailUrl(undefined)
      // getSlidePreview()
    }
    // if(renderCount > 0){
    //     setLoaded(true)
    //     setPreviewLoader(false)
    //     setThumbnailUrl(undefined)
    //     getSlidePreview()
    // }
    //setshowVideo(true)
  }, [ActiveScreen, ActiveSlide, ActiveModule]);

  const setMedialUrls = (activeScreen, activeSlide, activeModule) => {
    return new Promise((resolve, reject) => {
      let mediaUrl = project[projectId].Modules[activeModule].slides[activeSlide].Screens[activeScreen]["mediaurl"];
      resolve(mediaUrl);
    });
  };

  const backToDashboard = () => {
    history.push({
      pathname: "/edit-project/" + projectId,
      state: {
        path: "preview-project",
        ActiveModule: ActiveModule,
        ActiveSlide: ActiveSlide,
        ActiveScreen: ActiveScreen
      }
    });
  };
  const backArrow = () => {
    history.push("/project-list");
  };
  const countPlusOne = () => {
    // let  count
    COUNT += 1;
    let result = COUNT.toString();
    return result;
  };
  useEffect(() => {
    if (props.TemplateUpdate) {
      setMadeChange(true);
    }
  }, [props.TemplateUpdate]);
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const onResetClick = async () => {
    try {
      setPageLoad(true);
      closeModal();
      let projectDatafromDb = await getProjectById(projectId);
      if (projectDatafromDb.data.data.length > 0) {
        const oldProjectData = JSON.parse(projectDatafromDb.data.data[0].ProjectData);
        const newProjectId = await nanoid();
        const newProjectData = {
          [newProjectId]: JSON.parse(JSON.stringify(oldProjectData[projectId]))
        };
        const modulesKeyArray = Object.keys(newProjectData[newProjectId].Modules);
        for (const mkey of modulesKeyArray) {
          const slidesKeyArray = Object.keys(newProjectData[newProjectId].Modules[mkey].slides);
          for (const slkey of slidesKeyArray) {
            const screenKeyArray = Object.keys(newProjectData[newProjectId].Modules[mkey].slides[slkey].Screens);
            for (const sckey of screenKeyArray) {
              const screen = newProjectData[newProjectId].Modules[mkey].slides[slkey].Screens[sckey];
              if (screen.TM01) {
                screen.TM01.Contentparams.contentdescription = "no template";
              }
              delete screen.mediaurl;
            }
          }
        }
        const projNameArray = newProjectData[newProjectId].ProjectName.split(" - ");
        if (projNameArray.length > 1) {
          projNameArray.pop();
        }
        newProjectData[newProjectId].ProjectName = `${projNameArray.join(" - ")} - ${new Date()
          .toISOString()
          .split("T")
          .join(" ")
          .split(".")
          .shift()}`;

        let jsondata = {
          APIData: { ...newProjectData },
          type: "create"
        };
        const response = await createProjectsPreview(JSON.stringify(jsondata));
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          if (response.status === 200 && response.status !== null && response.status !== null) {
            setPageLoad(false);
            showToaster("Project Created successfully", "success");
            dispatch(updateProjectName(newProjectData[newProjectId].ProjectName));
            dispatch(
              createdProject({
                projectID: newProjectId,
                projectDetails: jsondata
              })
            );
            let roleArray = [
              {
                editProject: true,
                viewProject: true,
                deleteProject: true,
                projectId: newProjectId,
                action: "createUser"
              }
            ];
            await updateuserProjectRole(userId, orgId, roleArray);
            localStorage.setItem("iscloned", "1");
            history.push(`/edit-project/${newProjectId}`);
            return;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    setPageLoad(false);
    showToaster("Something went wrong. Please Try Again", "warning");
  };
  const SaveandApply = async () => {
    setPageLoaded(true);
    props.setchanges(false);
    let imageIds = [];
    try {
      // let activeScreenDetails = project[projectId]['Modules'][ActiveModule]['slides'][ActiveSlide]['Screens'][ActiveScreen]
      // Object.entries(activeScreenDetails).map((item, key) => {
      //     let activeImgIds = item.filter(function (data) {
      //         if (data.Contenttype === "Image") {
      //             if(data.Contentparams.imageid != undefined && data.Contentparams.imageid != "")
      //                 imageIds.push(data.Contentparams.imageid)
      //             //return data.Contentparams.imageid != "";
      //         }
      //     })
      // });
      // let request = {
      //     imageid: ['1937609533'],
      // }
      // publicShutterStockSearch(request).then((response) => {
      //     if (response !== "" && response !== undefined && response !== null && response.status !== "" && response.status !== undefined && response.status !== null && response.status === 200 && response.data !== "" && response.data !== undefined && response.data !== null) {
      //     } else {
      //     }
      // }).catch((error) => {
      //     console.log(error);
      // })
      let projectDatafromDb = await getProjectById(projectId);
      if (projectDatafromDb.data.data.length > 0) {
        setcacheProjectData(JSON.parse(projectDatafromDb.data.data[0].ProjectData));
      }
      let cachedData = JSON.parse(projectDatafromDb.data.data[0].ProjectData);
      let projectData = project[projectId].Modules;
      let slideChanges = [];
      Object.entries(projectData).map((moduleData, moduleIndex) => {
        let mData = moduleData[1];
        let slides = mData.slides;
        Object.entries(slides).map((slideData, moduleIndex) => {
          let slide = slideData[1];
          let screens = slide.Screens;
          Object.entries(screens).map((screenData, moduleIndex) => {
            let _arr = [];
            let slide = screenData[1];
            let screenEntries = Object.entries(slide);
            screenEntries.sort((a, b) => {
              const isTa = /^TM/.test(a[0]);
              const isTb = /^TM/.test(b[0]);
              if (isTa && !isTb) {
                return 1;
              }
              if (isTb && !isTa) {
                return -1;
              }
              return 0;
            });
            screenEntries.map((item, key) => {
              let oldScreendata = undefined;
              if (cachedData[projectId].Modules != undefined) {
                if (cachedData[projectId].Modules[moduleData[0]] != undefined) {
                  if (cachedData[projectId].Modules[moduleData[0]].slides[slideData[0]] != undefined) {
                    oldScreendata = cachedData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[screenData[0]];
                  }
                }
              }
              item.map((element, index) => {
                if (element.Contenttype === "OST") {
                  if (element["Contentparams"]["FontColour"].length === 8) {
                    element["Contentparams"]["FontColour"] = element["Contentparams"]["FontColour"].split("*")[0];
                  }
                  if (element["Contentparams"]["contentdescription"].includes("[H]")) {
                    element["Contentparams"]["contentdescription"] = element["Contentparams"]["contentdescription"].split("[H]")[0];
                    element["Contentparams"]["fontTag"] = HEADERFONT;
                  }
                  if (element["Contentparams"]["contentdescription"].includes("[P]")) {
                    element["Contentparams"]["contentdescription"] = element["Contentparams"]["contentdescription"].split("[P]")[0];
                    element["Contentparams"]["fontTag"] = PARAGRAPHFONT;
                  }
                  if (element["Contentparams"]["contentdescription"].includes("[B]")) {
                    element["Contentparams"]["contentdescription"] = element["Contentparams"]["contentdescription"].split("[B]")[0];
                    element["Contentparams"]["fontTag"] = BULLETFONT;
                  }
                  if (element["Contentparams"]["fontTag"] === HEADERFONT || element["Contentparams"]["fontTag"] === undefined) {
                    element["Contentparams"]["contentdescription"] = element["Contentparams"]["contentdescription"] + "[H]";
                  } else if (element["Contentparams"]["fontTag"] === PARAGRAPHFONT) {
                    element["Contentparams"]["contentdescription"] = element["Contentparams"]["contentdescription"] + "[P]";
                  } else if (element["Contentparams"]["fontTag"] === BULLETFONT) {
                    element["Contentparams"]["contentdescription"] = element["Contentparams"]["contentdescription"] + "[B]";
                  }
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  // if (element['Contentparams']['contentdescription'] === "") {
                  //     delete slide[item[0]]
                  // }
                  if (element["Contentparams"]["fontTag"] !== "") {
                    delete element["Contentparams"]["fontTag"];
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]]) {
                      if (element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription) {
                        _arr.push(1);
                      }
                      if (element["Contentparams"]["FontColour"] != oldScreendata[item[0]].Contentparams.FontColour) {
                        _arr.push(1);
                      }
                      if (element["Contentparams"]["FontFamily"] != oldScreendata[item[0]].Contentparams.FontFamily) {
                        _arr.push(1);
                      }
                      if (element["Contentparams"]["FontSizeH"] != oldScreendata[item[0]].Contentparams.FontSizeH) {
                        _arr.push(1);
                      }
                      if (element["Contentparams"]["FontSizeP"] != oldScreendata[item[0]].Contentparams.FontSizeP) {
                        _arr.push(1);
                      }
                    }
                  }
                  if (element["Contentparams"]["contenttag"] === "") {
                    element["Contentparams"]["contenttag"] = "Null";
                    return element;
                  }
                  // this.setState({ projectInputs: projectInputs })
                  return element;
                }
                if (element.Contenttype === "Background") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  return element;
                }
                if (element.Contenttype === "AudioScript") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  // if (element['Contentparams']['contentdescription'] === "") {
                  //     delete slide["AS01"]
                  // }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]]) {
                      if (element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription) {
                        _arr.push(1);
                      }
                    }
                  }
                  if (element["Contentparams"]["contenttag"] === "") {
                    element["Contentparams"]["contenttag"] = "Null";
                    return element;
                  }
                  return element;
                }
                if (element.Contenttype === "Image") {
                  if (element["Contentparams"]["searchstring"]) {
                    element["Contentparams"]["searchstring"] = element["Contentparams"]["searchstring"].trim();
                  }
                  if (element["Contentparams"]["contenttag"]) {
                    element["Contentparams"]["contenttag"] = element["Contentparams"]["contenttag"].trim();
                  }
                  //element["Contentparams"]["contentdescription"] = "";
                  if (element["Contentparams"]["contenttag"] === "" && element["Contentparams"]["searchstring"] === "") {
                    delete slide[item[0]];
                  } else {
                    element["Contentparams"]["sequenceid"] = countPlusOne();
                  }
                  if (element["Contentparams"]["contenttag"] === "" || element["Contentparams"]["contenttag"] === []) {
                    element["Contentparams"]["contenttag"] = "Null";
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (
                        element["Contentparams"]["searchstring"] != oldScreendata[item[0]].Contentparams.searchstring ||
                        element["Contentparams"]["contenttag"] != oldScreendata[item[0]].Contentparams.contenttag ||
                        (element["Contentparams"]["contentdescription"] &&
                          element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription)
                      ) {
                        _arr.push(1);
                      }
                    } else if (element["Contentparams"]["searchstring"] || element["Contentparams"]["contenttag"] !== "Null") {
                      _arr.push(1);
                    }
                  }
                  return element;
                }
                if (element.Contenttype === "Video") {
                  if (element["Contentparams"]["searchstring"]) {
                    element["Contentparams"]["searchstring"] = element["Contentparams"]["searchstring"].trim();
                  }
                  if (element["Contentparams"]["contenttag"]) {
                    element["Contentparams"]["contenttag"] = element["Contentparams"]["contenttag"].trim();
                  }
                  //element["Contentparams"]["contentdescription"] = "";
                  if (element["Contentparams"]["contenttag"] === "" && element["Contentparams"]["searchstring"] === "") {
                    delete slide[item[0]];
                  } else {
                    element["Contentparams"]["sequenceid"] = countPlusOne();
                  }
                  if (element["Contentparams"]["contenttag"] === "" || element["Contentparams"]["contenttag"] === []) {
                    element["Contentparams"]["contenttag"] = "Null";
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (
                        element["Contentparams"]["searchstring"] != oldScreendata[item[0]].Contentparams.searchstring ||
                        element["Contentparams"]["contenttag"] != oldScreendata[item[0]].Contentparams.contenttag ||
                        (element["Contentparams"]["contentdescription"] &&
                          oldScreendata[item[0]].Contentparams.contentdescription &&
                          element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription)
                      ) {
                        _arr.push(1);
                      }
                    } else if (element["Contentparams"]["searchstring"] || element["Contentparams"]["contenttag"] !== "Null") {
                      _arr.push(1);
                    }
                  }
                  return element;
                }
                if (element.Contenttype === "TemplateID") {
                  //element["Contentparams"]["contentdescription"] = "";
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (
                        element["Contentparams"]["contentdescription"] &&
                        oldScreendata[item[0]].Contentparams.contentdescription &&
                        element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription
                      ) {
                        _arr.push(1);
                      }
                    }
                  }
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  if (element["Contentparams"]["useTemplateStatus"] !== "") {
                    delete element["Contentparams"]["useTemplateStatus"];
                    return element;
                  }
                  return element;
                }
                if (element.Contenttype === "ColourScheme") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  if (element["Contentparams"]["contentdescription"].length === 8) {
                    element["Contentparams"]["contentdescription"] = element["Contentparams"]["contentdescription"].split("*")[0];
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription) {
                        _arr.push(1);
                      }
                    }
                  }
                  return element;
                }
                if (element.Contenttype === "Illustration") {
                  if (element["Contentparams"]["searchstring"]) {
                    element["Contentparams"]["searchstring"] = element["Contentparams"]["searchstring"].trim();
                  }
                  if (element["Contentparams"]["contenttag"]) {
                    element["Contentparams"]["contenttag"] = element["Contentparams"]["contenttag"].trim();
                  }
                  //element["Contentparams"]["contentdescription"] = "";
                  if (element["Contentparams"]["contenttag"] === "" && element["Contentparams"]["searchstring"] === "") {
                    delete slide[item[0]];
                    // return element;
                  } else {
                    element["Contentparams"]["sequenceid"] = countPlusOne();
                  }
                  if (element["Contentparams"]["contenttag"] === "" || element["Contentparams"]["contenttag"] === []) {
                    element["Contentparams"]["contenttag"] = "Null";
                    // return element;
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (
                        element["Contentparams"]["searchstring"] != oldScreendata[item[0]].Contentparams.searchstring ||
                        element["Contentparams"]["contenttag"] != oldScreendata[item[0]].Contentparams.contenttag ||
                        (element["Contentparams"]["contentdescription"] &&
                          oldScreendata[item[0]].Contentparams.contentdescription &&
                          element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription)
                      ) {
                        _arr.push(1);
                      }
                    } else if (element["Contentparams"]["searchstring"] || element["Contentparams"]["contenttag"] !== "Null") {
                      _arr.push(1);
                    }
                  }
                  return element;
                }
                if (element.Contenttype === "ScreenDuration") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  if (element["Contentparams"]["contenttag"] === "") {
                    element["Contentparams"]["contenttag"] = "Null";
                    // return element;
                  }
                  if (element["Contentparams"]["contentdescription"] === "") {
                    element["Contentparams"]["contentdescription"] = "7";
                    // return element;
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription) {
                        _arr.push(1);
                      }
                    }
                  }
                  return element;
                }
                return element;
              });
              if (slide["mediaurl"]) {
                if (_arr.length > 0) {
                  delete projectData[moduleData[0]].slides[slideData[0]].Screens[screenData[0]]["mediaurl"];
                }
              }
              if (_arr.length > 0) {
                slideChanges.push(moduleData[0] + "-" + slideData[0]);
                slideData[1].SlideRender = "1";
              }
              return item;
            });
            return screenData;
          });
          return slideData;
        });
        return moduleData;
      });
      // let projectDatafromDb = await getProjectById(projectId)
      let renderUrls = "";
      let urlArray;
      if (projectDatafromDb.data.data.length > 0) {
        renderUrls = projectDatafromDb.data.data[0].renderUrls;
        urlArray = projectDatafromDb.data.data[0].renderUrls;
        if (urlArray !== null && urlArray !== undefined) {
          Object.keys(urlArray).map(function (key, index) {
            Object.keys(urlArray[key]).map(function (k, i) {
              slideChanges.map((slidechange) => {
                if (slidechange == key + "-" + k) {
                  delete urlArray[key][k];
                }
                return urlArray;
              });
            });
          });
        }
      }
      if (slideChanges.length > 0) {
        window.localStorage.setItem("slide-status", true);
        // setslideChangeStatus(true)
      } else {
        window.localStorage.setItem("slide-status", false);
        // setslideChangeStatus(false)
      }
      let orgid = AdminRole == 0 ? userId : orgId;
      await getTemplatePlanByOrg(orgid).then(async (response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            let planData = response.data.result[0];
            // setTemplateSelectionType(response.data.result[0])
            let templateSelectionType = response.data.result[0];
            let updatedProjectDataforDb = JSON.stringify(project);

            let projectDataforAPI = await removeMediaUrlFilter(JSON.parse(updatedProjectDataforDb), projectId);

            let templateValidArray = [];
            let projectDataReq = {
              dbdata: {
                [projectId]: project[projectId]
              },
              APIData: {
                [projectId]: projectDataforAPI[projectId]
              },
              RenderUrl: {
                renderUrls: urlArray
              },
              type: "Update"
              // mode: templateSelectionType.manualTemplate == true ? "manual" : "automatic",
              // orgId: AdminRole == 0 ? userId : orgId
            };
            createProjectsPreview(JSON.stringify(projectDataReq)).then(async (response) => {
              try {
                if (
                  response !== "" &&
                  response !== undefined &&
                  response !== null &&
                  response.data !== "" &&
                  response.data !== undefined &&
                  response.data !== null
                ) {
                  setUploadProgress(false);
                  if (response.status === 200 && response.status !== null && response.status !== null) {
                    setPageLoaded(false);
                    showToaster("Project Updated successfully", "success");
                    setMadeChange(false);
                    props.setTemplateUpdate(false);
                    // let getProjectData = await getProjetcDetailsById(projectId)
                    let projectDatafromDb = await getProjectById(projectId);
                    if (projectDatafromDb.data.data.length > 0) {
                      UpdatePreview(JSON.parse(projectDatafromDb.data.data[0].ProjectData));
                    }
                    props.setReload(true);
                    setThumbnailUrl(undefined);
                    setPreviewUrl(undefined);
                    if (project != undefined && ActiveModule != undefined && ActiveSlide != undefined && ActiveScreen != undefined) {
                      let mediaUrls = await setMedialUrls(ActiveScreen, ActiveSlide, ActiveModule);
                      if (mediaUrls != undefined) {
                        if (mediaUrls["ThumbnailUrl"] != undefined && mediaUrls["ThumbnailUrl"] != null && mediaUrls["ThumbnailUrl"] != "") {
                          setThumbnailUrl(mediaUrls["ThumbnailUrl"] + "?time=" + Date.now());
                        }
                        if (mediaUrls["PreviewUrl"] != undefined && mediaUrls["PreviewUrl"] != null && mediaUrls["PreviewUrl"] != "") {
                          setPreviewUrl(mediaUrls["PreviewUrl"] + "?time=" + Date.now());
                        }
                      }
                    }
                    // SlidePreviewUpdate(getProjectData.previewProject)
                    // getSlidePreview()
                    // this.setState({ openInputModal: true })
                    setMadeChange(false);
                  } else {
                    setPageLoaded(false);
                    setUploadProgress(false);
                    showToaster(response.message, "warning");
                  }
                } else {
                  setPageLoaded(false);
                  setUploadProgress(false);
                  showToaster("Something went wrong. Please Try Again", "warning");
                }
              } catch (error) {
                setPageLoaded(false);
                setUploadProgress(false);
                showToaster("Something went wrong. Please Try Again", "warning");
              }
            });
          }
        } catch (error) {
          //this.setState({ uploadButtonProgress: false })
        }
      });
    } catch (e) {
      console.log(e);
      setUploadProgress(false);
      showToaster("Something went wrong. Please Try Again", "warning");
    }
  };
  const getProjetcDetailsById = (projectId) => {
    return new Promise((resolve, reject) => {
      try {
        let filter = {
          project_id: projectId
        };
        // EmptyPreview()
        getProjectID(filter).then(async (response) => {
          try {
            if (
              response !== "" &&
              response !== undefined &&
              response !== null &&
              response.status !== "" &&
              response.status !== undefined &&
              response.status !== null &&
              response.status === 200 &&
              response.data !== "" &&
              response.data !== undefined &&
              response.data !== null
            ) {
              let projectData = JSON.parse(response.data[0].ProjectData);
              let preview = await CreatePreviewFromResponse(response.data[0]);
              InitilizePreview(preview);
              resolve(preview);
            }
          } catch (error) {
            reject(null);
          }
        });
      } catch (error) {
        reject(null);
      }
    });
  };

  const SlidePreviewUpdate = (previewProjectData) => {
    setretry(false);
    setshowVideo(false);
    setPreviewLoader(false);
    setTemplateAvailable(false);
    setThumbnailUrl(undefined);
    //AssignScreenThumbnail("")
    let activeScreenDetails = previewProjectData[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["Screens"][ActiveScreen];
    Object.entries(activeScreenDetails).map((item, key) => {
      let activeTemplate = item.filter(function (data) {
        return data.Contenttype === "TemplateID";
      });
      if (activeTemplate.length > 0) {
        if (
          activeTemplate[0].Contentparams.contentdescription != "" &&
          activeTemplate[0].Contentparams.contentdescription != null &&
          activeTemplate[0].Contentparams.contentdescription != undefined
        ) {
          setTemplateAvailable(true);

          try {
            setPreviewUrl("");
            let modules = previewProjectData;
            let projectName = modules[projectId].ProjectName;
            let activeScreen = modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["Screens"][ActiveScreen];
            let activeScreenDetails = {
              ScreenName: modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["Screens"][ActiveScreen]["ScreenName"],
              ...activeScreen
            };

            let SlideAudioVO =
              modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["SlideAudioVO"] !== undefined
                ? modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["SlideAudioVO"]
                : "Null";
            let screenContent = {
              [projectId]: {
                ProjectName: projectName,
                Modules: {
                  [ActiveModule]: {
                    ModuleName: modules[projectId]["Modules"][ActiveModule]["ModuleName"],
                    slides: {
                      [ActiveSlide]: {
                        SlideName: modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["SlideName"],
                        SlideAudioVO: SlideAudioVO,
                        Screens: {
                          [ActiveScreen]: activeScreenDetails
                        }
                      }
                    }
                  }
                }
              }
            };

            projectScreenPreview(JSON.stringify(screenContent)).then((response) => {
              if (response.status !== 200) {
                setretry(true);
              }
              if (
                response !== "" &&
                response !== undefined &&
                response !== null &&
                response.data !== "" &&
                response.data !== undefined &&
                response.data !== null
              ) {
                if (response.data.mediaurl !== undefined && response.data.mediaurl !== null && response.data.mediaurl !== "") {
                  if (response.data.mediaurl["PreviewUrl"]) {
                    if (response.data.mediaurl["ThumbnailUrl"]) {
                      setThumbnailUrl(response.data.mediaurl["ThumbnailUrl"]);
                    }
                    UpdateThumbnail(ActiveModule, ActiveSlide, ActiveScreen, response.data.mediaurl["ThumbnailUrl"]);
                    //AssignScreenThumbnail(response.data.mediaurl)
                    props.setthumbnailUrl(response.data.mediaurl);
                    setshowVideo(true);
                    setPreviewUrl(response.data.mediaurl["PreviewUrl"] + "?time=" + Date.now());
                    setPreviewLoader(true);
                  } else {
                    if (response.data["ThumbnailUrl"]) {
                      setThumbnailUrl(response.data["ThumbnailUrl"]);
                    }
                    setshowVideo(true);
                    UpdateThumbnail(ActiveModule, ActiveSlide, ActiveScreen, response.data.mediaurl["ThumbnailUrl"]);
                    //let vid = document.getElementById("video")
                    //vid.src = response.data.mediaurl
                    props.setthumbnailUrl(response.data);
                    //AssignScreenThumbnail(response.data.mediaurl)
                    setPreviewUrl(response.data["PreviewUrl"] + "?time=" + Date.now());
                    //props.setthumbnailUrl(previewUrl)
                    setPreviewLoader(true);
                  }
                } else {
                  setshowVideo(false);
                  setPreviewLoader(false);
                }
              } else {
                // ShowAlertBox("Temporarily Unavailable...", "This feature is temporarily unavailable.Please try again", 'error');
                setPreviewLoader(false);
                setshowVideo(false);
              }
            });
          } catch (error) {
            setPreviewLoader(false);
          }
        } else {
          setPreviewLoader(false);
          //setTemplateAvailable(false)
          setshowVideo(false);
        }
      } else {
        setPreviewLoader(false);
        //setTemplateAvailable(false)
        setshowVideo(false);
      }
    });
  };
  const getSlidePreview = (type) => {
    if (loaded) {
      setretry(false);
      setshowVideo(false);
      setPreviewLoader(false);
      setTemplateAvailable(false);
      setThumbnailUrl(undefined);
      //AssignScreenThumbnail("")
      let activeScreenDetails = project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["Screens"][ActiveScreen];
      Object.entries(activeScreenDetails).map((item, key) => {
        let activeTemplate = item.filter(function (data) {
          return data.Contenttype === "TemplateID";
        });
        if (activeTemplate.length > 0) {
          if (
            activeTemplate[0].Contentparams.contentdescription != "" &&
            activeTemplate[0].Contentparams.contentdescription != null &&
            activeTemplate[0].Contentparams.contentdescription != undefined
          ) {
            setTemplateAvailable(true);
            try {
              setPreviewUrl("");
              let modules = project;
              let projectName = modules[projectId].ProjectName;
              let activeScreen = modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["Screens"][ActiveScreen];

              let activeScreenDetails = {
                ScreenName: modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["Screens"][ActiveScreen]["ScreenName"],
                ...activeScreen
              };
              let SlideAudioVO =
                modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["SlideAudioVO"] !== undefined
                  ? modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["SlideAudioVO"]
                  : "Null";
              let screenContent = {
                [projectId]: {
                  ProjectName: projectName,
                  Modules: {
                    [ActiveModule]: {
                      ModuleName: modules[projectId]["Modules"][ActiveModule]["ModuleName"],
                      slides: {
                        [ActiveSlide]: {
                          SlideName: modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["SlideName"],
                          SlideAudioVO: SlideAudioVO,
                          SlideRender: modules[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["SlideRender"],
                          Screens: {
                            [ActiveScreen]: activeScreenDetails
                          }
                        }
                      }
                    }
                  }
                }
              };
              // if (this.props.projectDetails.list !== "") {
              projectScreenPreview(JSON.stringify(screenContent)).then((response) => {
                if (response.status !== 200) {
                  setretry(true);
                }
                if (
                  response !== "" &&
                  response !== undefined &&
                  response !== null &&
                  response.data !== "" &&
                  response.data !== undefined &&
                  response.data !== null
                ) {
                  if (response.data.mediaurl !== undefined && response.data.mediaurl !== null && response.data.mediaurl !== "") {
                    if (response.data.mediaurl["PreviewUrl"]) {
                      if (response.data.mediaurl["ThumbnailUrl"]) {
                        setThumbnailUrl(response.data.mediaurl["ThumbnailUrl"]);
                      }
                      UpdateThumbnail(ActiveModule, ActiveSlide, ActiveScreen, response.data.mediaurl["ThumbnailUrl"]);
                      //AssignScreenThumbnail(response.data.mediaurl)
                      props.setthumbnailUrl(response.data.mediaurl);
                      setshowVideo(true);
                      setPreviewUrl(response.data.mediaurl["PreviewUrl"] + "?time=" + Date.now());
                      setPreviewLoader(true);
                    } else {
                      if (response.data["ThumbnailUrl"]) {
                        setThumbnailUrl(response.data["ThumbnailUrl"]);
                      }
                      setshowVideo(true);
                      UpdateThumbnail(ActiveModule, ActiveSlide, ActiveScreen, response.data.mediaurl["ThumbnailUrl"]);
                      props.setthumbnailUrl(response.data);
                      setPreviewUrl(response.data["PreviewUrl"] + "?time=" + Date.now());
                      setPreviewLoader(true);
                    }
                  } else {
                    setshowVideo(false);
                    setPreviewLoader(false);
                  }
                } else {
                  // ShowAlertBox("Temporarily Unavailable...", "This feature is temporarily unavailable.Please try again", 'error');
                  setPreviewLoader(false);
                  setshowVideo(false);
                }
              });
            } catch (error) {
              setPreviewLoader(false);
            }
          } else {
            setPreviewLoader(false);
            //setTemplateAvailable(false)
            setshowVideo(false);
          }
        } else {
          setPreviewLoader(false);
          //setTemplateAvailable(false)
          setshowVideo(false);
        }
      });
    }
  };

  const getPreview = (ProjectID, project) => {
    let renderFilter = {
      project_id: ProjectID,
      projectName: project[projectId].ProjectName
    };
    // setPriviewPageLoad(true);
    setPriviewrenderUrl("");
    // setGetRenderVideo(true);
    // setOpenRenderVideo(true);
    getProjectPreview(renderFilter).then((response) => {
      if (
        response.data !== undefined &&
        response.data !== null &&
        response.data !== "" &&
        response.status !== undefined &&
        response.status !== null &&
        response.status === 200
      ) {
        if (
          response.data.mediaurl !== undefined &&
          response.data.mediaurl !== null &&
          response.data.mediaurl !== "" &&
          Object.keys(response.data.mediaurl).length !== 0
        ) {
          if (response.data.renderstatus !== "0%") {
            setPriviewPageLoad(false);
            setGetRenderVideo(false);
            var arrayValue = [];
            let mediaurl = response.data.mediaurl;
            Object.keys(mediaurl).map(function (key, index) {
              Object.keys(mediaurl[key]).map(function (k, i) {
                arrayValue.push(mediaurl[key][k]);
              });
              if (arrayValue.length > 0) {
                if (arrayValue !== undefined && arrayValue !== null && arrayValue !== "") {
                  setPriviewrenderUrl(arrayValue);
                }
              }
              if (response.data.renderstatus !== "100%") {
                setTimeout(() => {
                  getPreview(ProjectID, project);
                }, 5000);
              }
            });
          } else {
            setTimeout(() => {
              getPreview(ProjectID, project);
            }, 5000);
          }
        } else if (response.message !== undefined && response.message !== null && response.message !== "") {
          setTimeout(() => {
            getPreview(ProjectID, project);
          }, 5000);
        } else {
          if (response.message === "string index out of range") {
          }
        }
      }
    });
  };

  const changePermission = (slideId, moduleId, e) => {
    let slideRenderData = project;
    let slideRenderArray = [];
    if (project != undefined) {
      if (slideRenderData[projectId] != null) {
        if (slideRenderData[projectId].Modules != undefined) {
          Object.keys(slideRenderData[projectId].Modules).map((module) => {
            Object.keys(slideRenderData[projectId].Modules[module].slides).map((slide) => {
              if (slideId === slide && moduleId === module) {
                if (e.target.checked === true) {
                  slideRenderData[projectId].Modules[module].slides[slide].SlideRender = "1";
                  let slideModuleDta = {
                    slideId: slide,
                    moduleId: module
                  };
                  slideRenderArray.push(slideModuleDta);
                  props?.setCheckedSlides([...props?.checkedSlides, { moduleName: module, slideName: slide }]);
                } else {
                  slideRenderData[projectId].Modules[module].slides[slide].SlideRender = "0";
                  props?.setCheckedSlides(props?.checkedSlides.filter((slides) => slides?.moduleName !== module || slides?.slideName !== slide));
                }
              }
            });
          });

          // let token=jwt.sign({slideRenderArray},secretkey);

          let projectData1 = JSON.stringify(slideRenderData);
          //   setProjectData(JSON.parse(projectData1))
          UpdatePreview(JSON.parse(projectData1));
          props.setProjectData(JSON.parse(projectData1));
          return slideRenderData;
          // setthumbnailRequested(true)
        }
      }
    }
  };
  const saveRenderSlide = async () => {
    setopenRenderModal();
    try {
      setPageLoaded(true);
      let projectDatafromDb = await getProjectById(projectId);
      // let renderUrls = ""
      let urlArray;
      let slideRenderArray = [];
      let slideSelectedArray = [];
      let slideChangeStatus1 = false;
      let slideRenderData = project;
      if (project != undefined) {
        if (slideRenderData[projectId] != null) {
          if (slideRenderData[projectId].Modules != undefined) {
            let c = 0;
            Object.keys(slideRenderData[projectId].Modules).map((module) => {
              Object.keys(slideRenderData[projectId].Modules[module].slides).map((slide) => {
                if (slideRenderData[projectId].Modules[module].slides[slide].SlideRender == "1") {
                  let slideModuleDta = {
                    slideId: slide,
                    moduleId: module
                  };
                  slideRenderArray.push(slideModuleDta);
                }
                if (projectDatafromDb.data.data.length > 0) {
                  if (
                    projectDatafromDb.data.data[0].renderUrls !== undefined &&
                    projectDatafromDb.data.data[0].renderUrls !== null &&
                    projectDatafromDb.data.data[0].renderUrls !== ""
                  ) {
                    // renderUrls = projectDatafromDb.data.data[0].renderUrls;
                    urlArray = projectDatafromDb.data.data[0].renderUrls;
                    if (urlArray !== null && urlArray !== undefined) {
                      Object.keys(urlArray).map(function (key, index) {
                        Object.keys(urlArray[key]).map(function (k, i) {
                          if (slideRenderData[projectId].Modules[module].slides[slide].SlideRender == "1") {
                            // let slideModuleDta = {
                            //     slideId: slide,
                            //     moduleId: module
                            // }
                            // slideRenderArray.push(slideModuleDta)
                            if (key.startsWith("M") && k.startsWith("SL")) {
                              if (k === slide && key === module && urlArray[key][k].url !== "") {
                                slideChangeStatus1 = true;
                                slideRenderData[projectId].Modules[module].slides[slide].SlideRender = "0";
                              } else {
                                slideChangeStatus1 = false;
                                slideRenderData[projectId].Modules[module].slides[slide].SlideRender = "1";
                              }
                            }
                          }
                        });
                      });
                    }
                  }
                }
              });
            });
          }
          window.localStorage.setItem("slide-details", JSON.stringify(slideRenderArray));
          setSlideRenderData(slideRenderArray);
          setslideChangeStatus(slideChangeStatus1);
        }
      }

      let updatedProjectDataforDb = JSON.stringify(project);

      let projectDataforAPI = await removeMediaUrlFilter(JSON.parse(updatedProjectDataforDb), projectId);
      let renderUrls = projectDatafromDb.data.data[0].renderUrls;
      const newProjectId = await nanoid();
      let projectDataReq = {
        dbdata: {
          [projectId]: project[projectId]
        },
        APIData: {
          [projectId]: projectDataforAPI[projectId]
        },
        RenderUrl: {
          renderUrls: renderUrls
        },
        RenderData: {
          [newProjectId]: projectDataforAPI[projectId]
        },
        slideModuleData: {
          slideRenderDatas: slideRenderArray
        },
        type: "sliderender"
      };

      createProjectsPreview(JSON.stringify(projectDataReq)).then(async (response) => {
        try {
          // setPageLoaded(true)
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            if (response.status === 200 && response.status !== null && response.status !== null) {
              setPageLoaded(false);
              showToaster("Project Updated successfully", "success");
              // let getProjectData = await getProjetcDetailsById(projectId)
              // let projectDatafromDb = await getProjectById(projectId)
              let clusterDetails;
              // if (projectDatafromDb.data.data.length > 0) {
              //     UpdatePreview(JSON.parse(projectDatafromDb.data.data[0].ProjectData))
              // }
              setRenderId(response.data.ProjectID);
              let clusterArray = [];
              let clusterArray1 = [];
              await getClusterDetails("number_of_render_inprogress").then((response) => {
                try {
                  if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null &&
                    response.data !== "" &&
                    response.data !== undefined &&
                    response.data !== null
                  ) {
                    clusterDetails = response.data.result[0];

                    if (response.data.result[0].status === false) {
                      let filter = {
                        resource_group: "Content-Pro",
                        cluster_name: response.data.result[0].cluster_name
                      };
                      listClusterDetails(filter).then((response1) => {
                        try {
                          if (
                            response1 !== "" &&
                            response1 !== undefined &&
                            response1 !== null &&
                            response1.data !== "" &&
                            response1.data !== undefined &&
                            response1.data !== null
                          ) {
                            if (response1.data.Body[clusterDetails.rendercluster_name].provisioning_state === "Succeeded") {
                              let statusFilter = {
                                status: "Created"
                              };
                              updateClusterDetails(clusterDetails._id, statusFilter).then((response1) => {
                                try {
                                  if (
                                    response1 !== "" &&
                                    response1 !== undefined &&
                                    response1 !== null &&
                                    response1.data !== "" &&
                                    response1.data !== undefined &&
                                    response1.data !== null
                                  ) {
                                  } else {
                                    setPageLoaded(false);
                                    showToaster("Something went wrong. Please Try Again1", "warning");
                                  }
                                } catch (error) {
                                  setPageLoaded(false);
                                  showToaster("Something went wrong. Please Try Again2", "warning");
                                }
                              });
                            }
                          } else {
                            setPageLoaded(false);
                            showToaster("Something went wrong. Please Try Again3", "warning");
                          }
                        } catch (error) {
                          console.log(error);
                          setPageLoaded(false);
                          showToaster("Something went wrong. Please Try Again4", "warning");
                        }
                      });
                    }

                    let clusterorgId = orgId;
                    response.data.result.map((details) => {
                      if (details.isDelete == 0 && details.status !== "Deleting") {
                        if (details.orgId == clusterorgId) {
                          clusterArray.push(details);
                        } else if (details.orgId == "All") {
                          clusterArray1.push(details);
                        }
                      }
                    });
                    if (clusterArray.length > 0) {
                      // const random = Math.floor(Math.random() * clusterArray.length);
                      // window.localStorage.setItem(
                      //     '_cluster-details',
                      //     JSON.stringify(clusterArray[random])
                      // );
                      // setClusterName(clusterArray[random].rendercluster_name)
                      window.localStorage.setItem("_cluster-details", JSON.stringify(clusterArray[0]));
                      setClusterName(clusterArray[0].rendercluster_name);
                    } else if (clusterArray1.length > 0) {
                      // const random = Math.floor(Math.random() * clusterArray1.length);
                      // window.localStorage.setItem(
                      //     '_cluster-details',
                      //     JSON.stringify(clusterArray1[random])
                      // );
                      // setClusterName(clusterArray1[random].rendercluster_name)
                      window.localStorage.setItem("_cluster-details", JSON.stringify(clusterArray1[0]));
                      setClusterName(clusterArray1[0].rendercluster_name);
                    }
                  } else {
                    setPageLoaded(false);
                    showToaster("Something went wrong. Please Try Again5", "warning");
                  }
                } catch (error) {
                  console.log(error);
                  setPageLoaded(false);
                  showToaster("Something went wrong. Please Try Again6", "warning");
                }
              });
              setopenRenderModal1(true);
              // setopenRenderModal2(true);
            } else {
              setPageLoaded(false);
              showToaster(response.message, "warning");
            }
          } else {
            setPageLoaded(false);
            showToaster("Something went wrong. Please Try Again", "warning");
          }
        } catch (error) {
          console.log(error);
          setPageLoaded(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      });
    } catch (error) {
      console.log(error);
      setPageLoaded(false);
      showToaster("Something went wrong. Please Try Again6", "warning");
    }
  };

  const saveRenderSlideSerial = async () => {
    setopenRenderModalSerial();
    setPageLoaded(true);
    let updatedProjectDataforDb = JSON.stringify(project);

    let projectDataforAPI = await removeMediaUrlFilter(JSON.parse(updatedProjectDataforDb), projectId);
    let projectDataReq = {
      dbdata: {
        [projectId]: project[projectId]
      },
      APIData: {
        [projectId]: projectDataforAPI[projectId]
      },
      type: "Update"
    };
    createProjectsPreview(JSON.stringify(projectDataReq)).then(async (response) => {
      try {
        // setPageLoaded(true)
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          if (response.status === 200 && response.status !== null && response.status !== null) {
            setPageLoaded(false);
            showToaster("Project Updated successfully", "success");
            // let getProjectData = await getProjetcDetailsById(projectId)
            let projectDatafromDb = await getProjectById(projectId);
            if (projectDatafromDb.data.data.length > 0) {
              UpdatePreview(JSON.parse(projectDatafromDb.data.data[0].ProjectData));
            }
            setopenRenderModal2(true);
          } else {
            setPageLoaded(false);
            showToaster(response.message, "warning");
          }
        } else {
          setPageLoaded(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      } catch (error) {
        setPageLoaded(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };
  const checkTemplateAvailable = async (modules, ProjectID) => {
    // this.projectID = this.props.activeProject.ProjectID
    let projectDatafromDb = await getProjectById(projectId);
    // let renderUrls = ""
    let urlArray;
    var arrayValue = [];
    let arrayVideos = [];

    let slideRenderData = project;
    var templateStatus = true;
    if (modules !== undefined && modules !== null && modules[ProjectID] !== undefined) {
      var activeProjectData = modules[ProjectID]["Modules"];

      var moduleArrayValue = [];
      await Object.entries(activeProjectData).map((modules, moduleIndex) => {
        moduleArrayValue.push(modules[0]);
        // setModuleCount(moduleArrayValue)
        if (templateStatus === true) {
          let moduleData = modules[1];
          if (moduleData.slides !== undefined && moduleData.slides !== null && moduleData.slides !== "") {
            Object.entries(moduleData.slides).map((slideData, slideIndex) => {
              slideRenderDatas.map((renderSlide) => {
                if (renderSlide.moduleId == modules[0] && renderSlide.slideId == slideData[0]) {
                  arrayValue.push(modules[0] + "-" + slideData[0]);
                  // arrayVideos.push(slideData[0])
                }
              });
              if (projectDatafromDb.data.data.length > 0) {
                if (
                  projectDatafromDb.data.data[0].renderUrls !== undefined &&
                  projectDatafromDb.data.data[0].renderUrls !== null &&
                  projectDatafromDb.data.data[0].renderUrls !== ""
                ) {
                  // renderUrls = projectDatafromDb.data.data[0].renderUrls;
                  urlArray = projectDatafromDb.data.data[0].renderUrls;
                  if (urlArray !== null && urlArray !== undefined) {
                    Object.keys(urlArray).map(function (key, index) {
                      Object.keys(urlArray[key]).map(function (k, i) {
                        if (key.startsWith("M") && k.startsWith("SL")) {
                          slideRenderDatas.map((renderSlide) => {
                            if (renderSlide.moduleId == modules[0] && renderSlide.slideId == slideData[0]) {
                              // arrayValue.push(slideData[0]);

                              arrayVideos.push(urlArray);
                            }
                          });
                        }
                      });
                    });
                  }
                }
              }

              if (templateStatus === true) {
                if (typeof slideData[1] === "object") {
                  let screens = slideData[1].Screens;
                  if (screens !== undefined && screens !== null && screens !== "" && typeof screens === "object") {
                    if (templateStatus === true) {
                      Object.entries(screens).map((screen, index) => {
                        let itemsKey = screen[0];
                        Object.entries(screens[itemsKey]).map((item, index) => {
                          if (item[1] !== undefined && item[1].Contenttype !== undefined && item[1].Contenttype === "TemplateID") {
                            if (item[1] !== undefined && item[1].Contentparams !== undefined) {
                              let Contentparams = item[1].Contentparams;
                              if (
                                Contentparams.contentdescription !== undefined &&
                                Contentparams.contentdescription !== null &&
                                Contentparams.contentdescription !== "" &&
                                Contentparams.contentdescription !== "Null"
                              ) {
                              } else {
                                templateStatus = false;
                              }
                            }
                          }
                          return true;
                        });
                        return true;
                      });
                    }
                  }
                }
              }
              return true;
            });
          }
        }
        return true;
      });
    }
    setVideos(arrayVideos);
    setSlideCount(arrayValue);
    return templateStatus;
  };
  // const renderProject = async () => {
  //     setrenderProject(project);
  //     let verifyTemplateAvailable = await checkTemplateAvailable(
  //         project,
  //         projectId
  //     );
  //     let renderFilter = { project_id: projectId };
  //     if (verifyTemplateAvailable === true) {
  //         setPriviewPageLoad(true);
  //         renderPreview(renderFilter).then((response) => {
  //             if (
  //                 response.status !== undefined &&
  //                 response.status !== null &&
  //                 response.status === 200
  //             ) {
  //                 // setTimeout(() => {
  //                 //   getPreview(projectId,project);
  //                 // }, 5000);
  //                 getPreview(projectId, project);
  //                 setPriviewPageLoad(false);
  //             } else if (
  //                 response.message !== undefined &&
  //                 response.message !== null &&
  //                 response.message !== ""
  //             ) {
  //                 setRenderMessage(response.message);
  //             } else {
  //                 ShowAlertBox(
  //                     "Oops...",
  //                     "Something went wrong. Please try again",
  //                     "error"
  //                 );
  //                 setPriviewPageLoad(false);
  //             }
  //         });
  //     } else {
  //         showToaster("There is no template input provided!!", "warning");
  //     }
  // };
  const updateSlideRenderName = (value) => {
    if (value !== undefined && value !== "" && value !== null) {
      setSlideRenderName(value);
    } else {
      setSlideRenderName(project[projectId].ProjectName);
    }
  };

  const customSort = (a, b) => {
    if (a.moduleName !== b.moduleName) {
      return a.moduleName.localeCompare(b.moduleName);
    } else {
      return a.slideName.localeCompare(b.slideName);
    }
  };

  let defaultRenderName = props?.checkedSlides
    .sort(customSort)
    .map((slide) => `${slide.moduleName} - ${slide.slideName}`)
    .join(" / ");

  const onRenderNameChange = (e) => {
    setRenderName(e.target.value);
  };

  return (
    <>
      {pageLoaded ? (
        <div className="loader-wrapper video-loader">
          <div className="loader-inner">
            <img src="../../../assets/img/loader.gif"></img>
            <h1>updating....</h1>
          </div>
        </div>
      ) : (
        ""
      )}

      {getRenderVideo ? (
        <div className="loader-wrapper video-loader">
          <div className="loader-inner">
            <img src="../../../assets/img/loader.gif"></img>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="video-sec">
        <div className="preview-sec d-flex justify-content-end">
          <button onClick={openModal} type="button" className="btn btn-outline me-2 px-3">
            RESET
          </button>
          {!projectRoleEdit && (
            <button onClick={() => setOpenBacktoEdit(true)} type="submit" className="btn btn-outline me-2 px-3">
              EDIT
            </button>
          )}
          {showVideo && !retry && (
            <button
              onClick={() => {
                getSlidePreview();
                setOpenPreviewModals(true);
              }}
              type="submit"
              className="btn btn-outline me-2 px-3"
            >
              PREVIEW
            </button>
          )}

          {/* <button
                        type="button"
                        className="btn btn-primary px-3"
                        value="submit"
                        onClick={() => { history.push("/project-list") }}
                    >
                        PUBLISH
                    </button> */}
          <button
            disabled={renderValidate > 0 ? false : true}
            type="button"
            className="btn btn-primary px-3 me-3"
            value="submit"
            onClick={() => {
              // if (madeChange) {
              //     setopenRenderModal(false);

              //     showToaster('You have unsaved changes.Please save and continue', 'warning');
              // } else {

              let message = "Kindly Wait. You will be also notified via email once rendering is complete  " + " ?";
              let confirmBtn = "Yes";
              setconfirmmsg(message);
              setconfirmbtnlabel(confirmBtn);
              setopenRenderModal(true);
              // slideRender()
              // }
            }}
          >
            RENDER
            {renderValidate > 0 && <span className="count-number ms-2"> {renderValidate > 0 && renderValidate}</span>}
            {/* { renderCount} */}
          </button>
          {/* <button
                        disabled={renderValidate > 0 ? false : true}
                        type="button"
                        className="btn btn-primary px-3"
                        value="submit"
                        onClick={() => {

                            // if (madeChange) {
                            //     setopenRenderModal(false);

                            //     showToaster('You have unsaved changes.Please save and continue', 'warning');
                            // } else {


                            let message =
                                "Kindly Wait. You will be also notified via email once rendering is complete  " +

                                " ?";
                            let confirmBtn = "Yes";
                            setconfirmmsg(message);
                            setconfirmbtnlabel(confirmBtn);
                            setopenRenderModalSerial(true);
                            // slideRender()
                            // }
                        }}
                    >
                        RENDER PARALLEL
                        {renderValidate > 0 &&
                            <span className="count-number ms-2"> {renderValidate > 0 && renderValidate}</span>
                        }
                    </button> */}
        </div>
        <div className="save-apply blink">
          {props.changes && (
            // (madeChange && !props.madeChange) &&
            <p>
              Document have pending changes <span onClick={() => SaveandApply()}>Save and Apply </span> Now
            </p>
          )}
        </div>

        <div className="video-inner">
          <div className="video-head">
            <h4>
              {ActiveSlideName} / {ActiveScreenName}
            </h4>
          </div>
          <div></div>
          <VideoplayerWithPreview loading={false} thumbnailUrl={thumbnailUrl} videoUrl={previewUrl} showVolume={false} />
          {/* {
                        templateAvailable ?
                            (previewLoader ?
                                <VideoplayerWithPreview loading={false} thumbnailUrl={thumbnailUrl} videoUrl={previewUrl} showVolume={false} />
                                // <ReactPlayer light={thumbnailUrl} url={previewUrl} controls width="100%" muted={true} playing={true} />
                                : (
                                    (!showVideo && retry) ? <span className="invalid-msg" onClick={() => { getSlidePreview() }}>
                                        <span className="retry-link">Retry</span></span> :
                                        <div>

                                            <VideoplayerWithPreview controls width="100%" loading={true} muted={true} />
                                        </div>
                                ))
                            : <span className="invalid-msg blink">There are no templates matching input provided</span>
                    } */}
          {/* {!previewLoader ?
                        <div className="video-wrap">
                            {
                                (!showVideo && !retry) ? <span className="invalid-msg blink">There are no templates matching input provided</span>
                                    : <ReactPlayer light={thumbnailUrl} url={previewUrl} controls width="100%" muted={true} playing={true} />}
                        </div> : (

                            <div className="video-wrap">
                                <div className="loader-wrapper video-loader">
                                    <div className="loader-inner">
                                        <img src="../../../assets/img/loader.gif"></img>
                                    </div>
                                </div>
                                <ReactPlayer controls width="100%" muted={true} />
                            </div>
                        )} */}
          {}
          {showVideo && !retry && (
            <>
              {/* <Player style={{ width: '500px' }}>
                            <source src={previewUrl} />
                        </Player> */}
              {/* <VideoElement previewUrl={previewUrl} /> */}
              {/* <div className="no-priview">Preview Video has not been generated yet</div> */}
              {/* <div className="video-control">
                            <div className="rSlider">
                                <span className="slide"></span>
                                <input id="range" type="range" min="0" max="50000" />
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <a href="" className="play-btn">
                                        <img src="../../../assets/img/play-icon.svg" />
                                    </a>
                                    <div className="time-duration ms-3">
                                        <span>0:00 </span>/<span> 4:00</span>
                                    </div>
                                </div>
                                <a href="" className="play-btn">
                                    <img src="../../../assets/img/full-screen.svg" />
                                </a>
                            </div>
                        </div> */}
            </>
          )}
        </div>
        <CustomModal
          isOpen={OpenBacktoEdit}
          Closemodal={setOpenBacktoEdit}
          Header="Are you sure ?"
          Content="You want to edit this project?"
          Buttonlabel="Yes"
          Buttonclick={backToDashboard}
        />
        <PreviewModal
          closePreview={() => setOpenPreviewModals(false)}
          openPreviewModals={openPreviewModals}
          previewUrl={previewUrl}
          thumbnailUrl={thumbnailUrl}
        />
        <CustomModal
          isOpen={openRenderModal1}
          Closemodal={setopenRenderModal1}
          Content={confirmmsg}
          Header="Rendering in Process."
          Buttonlabel={confirmbtnlabel}
          // Buttonclick={renderProject}
          Buttonclick={() => setOpenRenderVideo(true)}
          del_id={renderProjectId}
        />

        <CustomModal
          isOpen={openRenderModal2}
          Closemodal={setopenRenderModal2}
          Content={confirmmsg}
          Header="Rendering in Process."
          Buttonlabel={confirmbtnlabel}
          // Buttonclick={renderProject}
          Buttonclick={() => setOpenRenderVideoSerial(true)}
          del_id={renderProjectId}
        />

        <Modal
          isOpen={openRenderModalSerial}
          fade={true}
          centered
          className={
            DisplayMode
              ? "modal-dialog  modal-dialog-centered custom-modal dark template-valid"
              : "modal-dialog  modal-dialog-centered custom-modal dark template-valid"
          }
        >
          <ModalBody>
            {/* <div className="modal-dialog  modal-dialog-centered custom-modal dark template-valid"> */}
            <div className="modal-content border-0 rounded-0">
              <div className="modal-body">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h3>Slide Render Table</h3>
                  <div className="close-priview" data-bs-dismiss="modal">
                    <Tooltip
                      title="Close"
                      onClick={() => {
                        setopenRenderModalSerial();
                      }}
                      arrow
                    >
                      <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g data-name="Layer 2">
                          <g data-name="close">
                            <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
                            <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                          </g>
                        </g>
                      </svg>
                    </Tooltip>
                  </div>
                </div>
                {/* <div className="rSlider">
                                <input  type="text"
                                                id="lname"
                                                name="lname"
                                                s
                                                // value={module.ModuleName}
                                                onChange={(e) => {
                                                    updateSlideRenderName(e.target.value)
                                                }}/>
                            </div> */}
                <div className="template-table">
                  <div className="template-table-wrap template-thead">
                    <div className="template-block"> Slide Name</div>
                    <div className="template-block">Check Slides </div>
                  </div>

                  {
                    project !== undefined &&
                      project[projectId] !== undefined &&
                      project[projectId].Modules !== undefined &&
                      Object.keys(project[projectId].Modules).map((module) => {
                        let template;

                        // if (project[projectId].Modules[module].slides[slide].SlideRender !== "0"
                        // &&template.Contentparams.contentdescription !== undefined && template.Contentparams.contentdescription !== null && template.Contentparams.contentdescription !== ""
                        //  ) {

                        return Object.keys(project[projectId].Modules[module].slides).map((slide) => {
                          Object.keys(project[projectId].Modules[module].slides[slide].Screens).map(async (item, i) => {
                            template = project[projectId].Modules[module].slides[slide].Screens[item]["TM01"];
                          });

                          let slideValidation = false;
                          if (props.templateCheckArray !== undefined) {
                            props.templateCheckArray.map((tempArray) => {
                              if (module === tempArray.templateKeys && slide === tempArray.templateSlidekeys) {
                                if (tempArray.isValid === false) {
                                  slideValidation = true;
                                }
                              }
                            });
                          }

                          return (
                            <div className="template-table-wrap" key={module + "-" + slide} id={module + "-" + slide}>
                              <div className="template-block">
                                {module}-{slide}
                              </div>
                              <div className="template-block  text-center">
                                {/* <div className="slide-select"> */}
                                <Checkbox
                                  disabled={slideValidation ? true : false}
                                  // disabled={template.Contentparams.contentdescription !== "" ? true : false}
                                  checked={project[projectId].Modules[module].slides[slide].SlideRender === "1" ? true : false}
                                  onChange={(e) => changePermission(slide, module, e)}
                                  // color="primary"
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "secondary checkbox"
                                  }}
                                />
                                {/* <label for="html1"></label> */}
                                {/* </div> */}
                              </div>
                            </div>
                          );
                        });
                        // }
                      })

                    // setthumbnailRequested(true)
                  }
                </div>

                <div className="d-flex justify-content-end w-100 footer-btn flex-wrap">
                  <button
                    onClick={() => {
                      setopenRenderModalSerial();
                    }}
                    type="button"
                    className="btn btn-outline rounded-0 modal-toggle me-2"
                    data-bs-dismiss="modal"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      saveRenderSlideSerial();
                    }}
                    className="btn btn-primary  me-2 rounded-0"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={openRenderModal}
          fade={true}
          centered
          className={
            DisplayMode
              ? "modal-dialog  modal-dialog-centered custom-modal dark template-valid"
              : "modal-dialog  modal-dialog-centered custom-modal dark template-valid"
          }
        >
          <ModalBody>
            {/* <div className="modal-dialog  modal-dialog-centered custom-modal dark template-valid"> */}
            <div className="modal-content border-0 rounded-0">
              <div className="modal-body">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h3>Slide Render</h3>
                  <div className="close-priview" data-bs-dismiss="modal">
                    <Tooltip
                      title="Close"
                      onClick={() => {
                        setopenRenderModal();
                      }}
                      arrow
                    >
                      <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g data-name="Layer 2">
                          <g data-name="close">
                            <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
                            <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                          </g>
                        </g>
                      </svg>
                    </Tooltip>
                  </div>
                </div>
                {/* <div className="rSlider">
                                <input  type="text"
                                                id="lname"
                                                name="lname"
                                                s
                                                // value={module.ModuleName}
                                                onChange={(e) => {
                                                    updateSlideRenderName(e.target.value)
                                                }}/>
                            </div> */}
                <div className="template-table">
                  <div className="template-table-wrap template-thead">
                    <div className="template-block"> Slide Name</div>
                    <div className="template-block">Check Slides </div>
                  </div>

                  {
                    project !== undefined &&
                      project[projectId] !== undefined &&
                      project[projectId].Modules !== undefined &&
                      Object.keys(project[projectId].Modules).map((module) => {
                        let template;

                        // if (project[projectId].Modules[module].slides[slide].SlideRender !== "0"
                        // &&template.Contentparams.contentdescription !== undefined && template.Contentparams.contentdescription !== null && template.Contentparams.contentdescription !== ""
                        //  ) {

                        return Object.keys(project[projectId].Modules[module].slides).map((slide) => {
                          Object.keys(project[projectId].Modules[module].slides[slide].Screens).map(async (item, i) => {
                            template = project[projectId].Modules[module].slides[slide].Screens[item]["TM01"];
                          });

                          let slideValidation = false;
                          if (props.templateCheckArray !== undefined) {
                            props.templateCheckArray.map((tempArray) => {
                              if (module === tempArray.templateKeys && slide === tempArray.templateSlidekeys) {
                                if (tempArray.isValid === false) {
                                  slideValidation = true;
                                }
                              }
                            });
                          }
                          return (
                            <div className="template-table-wrap ">
                              <div className="template-block">
                                {module}-{slide}
                              </div>
                              <div className="template-block  text-center">
                                {/* <div className="slide-select"> */}
                                <Checkbox
                                  disabled={slideValidation ? true : false}
                                  // disabled={template.Contentparams.contentdescription !== "" ? true : false}
                                  checked={project[projectId].Modules[module].slides[slide].SlideRender === "1" ? true : false}
                                  onChange={(e) => changePermission(slide, module, e)}
                                  // color="primary"
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "secondary checkbox"
                                  }}
                                />
                                {/* <label for="html1"></label> */}
                                {/* </div> */}
                              </div>
                            </div>
                          );
                        });
                        // }
                      })

                    // setthumbnailRequested(true)
                  }
                </div>
                <div className="d-flex justify-content-between w-100 align-items-end" style={{ gap: 10 }}>
                  <form className="w-100 mr-10">
                    <h3 for="renderName" className="mb-4">
                      Render Name
                    </h3>
                    <input name="renderName" value={renderName ?? defaultRenderName} onChange={(e) => onRenderNameChange(e)} className="render-btn" />
                  </form>
                  <div className="d-flex justify-content-end  footer-btn">
                    <button
                      onClick={() => {
                        setopenRenderModal();
                      }}
                      type="button"
                      className="btn btn-outline rounded-0 modal-toggle me-2 render-btn "
                      data-bs-dismiss="modal"
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        saveRenderSlide();
                      }}
                      className="btn btn-primary rounded-0 render-btn"
                      disabled={renderValidate > 0 ? false : true}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <RenderVideo
          closePreview={() => setOpenRenderVideo(false)}
          refreshProject={refreshProjectData}
          openRenderVideo={openRenderVideo}
          // priviewrenderUrl={priviewrenderUrl}
          thumbnailUrl={props.RenderThumnails}
          setSlideCount={slideCount}
          setVideos={videos}
          moduleCount={moduleCount}
          checkTemplateAvailable={checkTemplateAvailable}
          renderId={renderId}
          renderClusterName={clusterName}
          slideChangeStatus={slideChangeStatus}
          renderName={renderName || defaultRenderName}
        />
        <RenderSerialVideo
          closePreviewSerial={() => setOpenRenderVideoSerial(false)}
          openRenderVideoSerial={openRenderVideoSerial}
          // priviewrenderUrl={priviewrenderUrl}
          thumbnailUrl={props.RenderThumnails}
          setSlideCount={slideCount}
          moduleCount={moduleCount}
          checkTemplateAvailable={checkTemplateAvailable}
        />
      </div>
      <PageLoader showLoader={pageLoad} />
      <Modal
        isOpen={modalOpen}
        fade={true}
        centered
        className={
          DisplayMode
            ? "modal-dialog delete-modal modal-dialog-centered styles-modal custom-modal dark"
            : "modal-dialog delete-modal modal-dialog-centered styles-modal custom-modal"
        }
        // className="modal-dialog  modal-dialog-centered styles-modal custom-modal dark"
      >
        <ModalBody>
          <div className="modal-content border-0 rounded-0 ">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <Tooltip title="Close" arrow>
                <div className="close-priview" onClick={closeModal} data-bs-dismiss="modal">
                  <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g data-name="Layer 2">
                      <g data-name="close">
                        <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
                        <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                      </g>
                    </g>
                  </svg>
                </div>
              </Tooltip>
            </div>
            <div className="mb-3">
              <h3 className="mb-3 h3">Are you sure?</h3>
              <p>A new project with the same content as the current project will be created.</p>
            </div>
            <div className="d-flex justify-content-end w-100 footer-btn flex-wrap">
              <button type="button" onClick={onResetClick} className="btn btn-primary  me-2 rounded-0">
                Continue
              </button>
              <button type="button" onClick={closeModal} className="btn btn-outline rounded-0 modal-toggle" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default PreviewVideo;
