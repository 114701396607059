import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Col, Row, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { customAlphabet } from 'nanoid/async';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

import DropdownCommon from '../../../components/form/dropdownCommon/categoryDropdown';
import FormFloatingInput from '../../../components/form/formFloatingInput';
import virtualImage from '../../../assets/img/virtual-image.png';
import { longRightArrowIcon } from '../../../constants/icon';
import { createCategory, createNewProjects, newGetCategory } from '../../../services/apiServices';
import { getUserItem, showToaster } from '../../../services/helper';
import Loading from '../../../components/Loading';

function CreateNewProjectModal(props) {
  const nanoid = customAlphabet('abcdefghijklmnopqrstuvzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890', 24);
  const [showNewCategory, setShowNewCategory] = useState(false);
  const [selectCategory, setSelectCategory] = useState([]);
  const [listCategory, setListCategory] = useState({});
  const [newCategory, setNewCategory] = useState('');
  const [categoryList, setCategoryList] = useStateWithCallbackLazy([]);
  const [load, setLoad] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [saving, setSaving] = useState(false)

  const history = useHistory();

  const handleAddNewCategory = () => {
    setShowNewCategory(true);
  };
  const handleSaveCategory = () => {
    setShowNewCategory(false);
  };

  const handleCancelCategory = () => {
    setShowNewCategory(false);
  };

  const GetCategoryList = () => {
    newGetCategory().then(response => {
      try {
        if (response.data.list) {
          let categoryList = response.data.list;
          setSelectCategory(categoryList);
          if (categoryList.length) {
            setListCategory({
              value: response.data.list[0].category,
              name: response.data.list[0].category,
              id: response.data.list[0].id,
            });
          }
        }
      } catch (error) {
        console.log(error);
        showToaster('Something went wrong. Please Try Again', 'warning');
      }
    });
  };

  useEffect(() => {
    GetCategoryList();
  }, []);

  useEffect(() => {
    generateCategories();
  }, [selectCategory]);

  const generateCategories = () => {
    if (selectCategory !== undefined) {
      let categoryOption = [];
      selectCategory.map(category => {
        categoryOption.push({ value: category.category, name: category.category, id: category.id });
        return true;
      });
      setCategoryList(categoryOption);
    }
  };

  const formik = useFormik({
    initialValues: {
      projectName: '',
    },

    validationSchema: Yup.object().shape({
      projectName: Yup.string()
        .trim()
        .required('Project Name is required')
        .max(50, 'Must be exactly 50 or less characters')
        .min(5, 'Minimum 5 characters are required'),
    }),
    onSubmit: async values => {
      try {
        setLoad(true);
        var projectId = await nanoid();
        let jsondata = {
          APIData: {
            [projectId]: {
              ProjectName: values.projectName,
              CategoryID: listCategory.id,
            },
          },
          CategoryValue: listCategory.value,
          type: 'create',
        };
        await createNewProjects(JSON.stringify(jsondata))
          .then(response => {
            try {
              if (response) {
                if (response.status === 200) {
                  setLoad(false);
                  showToaster('Project Created successfully', 'success');
                  history.push(`/project/${response.data._id}/generate-videos-inputs`);
                } else {
                  showToaster(response.message, 'warning');
                  setLoad(false);
                }
              } else {
                showToaster('Something went wrong. Please Try Again', 'warning');
                setLoad(false);
              }
            } catch (error) {
              showToaster('Something went wrong. Please Try Again', 'warning');
              setLoad(false);
            }
            setLoad(false);
          })
          .catch(e => {
            console.log(e, 'error');
            setLoad(false);
          })
          .finally(() => {
            setLoad(false);
          });
      } catch (e) {
        showToaster('Something went wrong. Please Try Again', 'warning');
        setLoad(false);
      }
    },
  });

  const handleChange = e => {
    setListCategory(e);
  };

  const createCategories = () => {
    let body = { category_name: newCategory };
    setSaving(true)
    createCategory(body)
      .then(response => {
        try {
          if (response?.data) {
            setListCategory({
              id: response.data.category_id,
              name: response.data.category_name,
              value: response.data.category_name,
            });
            setCategoryList([
              ...categoryList,
              {
                id: response.data.category_id,
                name: response.data.category_name,
                value: response.data.category_name,
              },
            ]);
            handleSaveCategory();
            showToaster('Category Added successfully', 'success');
            setSaving(false)
          } else {
            showToaster(response.message, 'warning');
            handleSaveCategory();
            setSaving(false)          }
        } catch (e) {
          showToaster(e.message, 'warning');
          handleSaveCategory();
          setSaving(false)
        }
      })
      .finally(() => {
        setErrorMessage('');
        setSaving(false)
      });
  };
  
  const addNewCategory = (e) => {
    const newCategory = e.target.value;
    if (newCategory.length >= 3 && newCategory.length <= 50) {
      setNewCategory(newCategory);
      setErrorMessage("");
    } else if (newCategory.length < 3) {
      setNewCategory("");
      setErrorMessage("Minimum Character limit is 3");
    } else {
      setNewCategory(newCategory.slice(0, 50)); // Truncate to 50 characters
      setErrorMessage("Character limit exceeded (50 characters max)");
    }
  };

  const handleBlur = () => {
    if (newCategory.length > 50) {
      setNewCategory(newCategory.slice(0, 50));
      setErrorMessage('');
    }
  };
  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [props.show]);
  const isInvalid = newCategory.length > 50 || newCategory.length < 3;
  const isButtonDisabled = saving||isInvalid
  return (
    <Modal
      className="create-new-project-modal"
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Row>
          <Col lg={6}>
            <div className="create-new-project-info">
              <h4>Create amazing AI Video Project</h4>
              <p>
                Easily create professional high quality videos at speed using AI enabled video
                generation by filling the below information.
              </p>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <FormFloatingInput
                type="text"
                id="projectName"
                placeholder=""
                label="Project Name"
                asterisk
                className="custom-form-none-icon mb-4"
                onChange={formik.handleChange}
                inputValue={formik.values.projectName}
              />
              {formik.touched.projectName && formik.errors.projectName ? (
                <div className="input-error-msg mb-2">{formik.errors.projectName}</div>
              ) : null}
              <div className="create-new-category-holder mb-4">
                {!showNewCategory ? (
                  <>
                    <DropdownCommon
                      name="status"
                      items={categoryList}
                      label={'Select Category'}
                      onChange={data => handleChange(data)}
                      selected={listCategory}
                      setSelected={setListCategory}
                    />
                    <span className="separater">or</span>
                    <Button className="bg-btn-primary" onClick={handleAddNewCategory}>
                      Add new category
                    </Button>
                  </>
                ) : (
                  <>
                    <div>
                      <FormFloatingInput
                        type="text"
                        id="projectName"
                        placeholder=""
                        label="Category name"
                        asterisk
                        className="custom-form-none-icon"
                        value={newCategory}
                        onChange={e => addNewCategory(e)}
                        onBlur={handleBlur}
                        autoComplete
                      />
                      {errorMessage.length ? (
                        <div className="input-error-msg mb-3">{errorMessage}</div>
                      ) : null}
                    </div>
                    <Button
                      className="bg-btn-primary"
                      onClick={createCategories}
                      disabled={isButtonDisabled}
                    >
                      {saving ? 'Saving...':"Save"}
                    </Button>
                    <button role="button" className="primary-btn" onClick={handleCancelCategory}>
                      Cancel
                    </button>
                  </>
                )}
              </div>
              <div className="fw-btn mb-4">
                <Button className="bg-btn-primary" type="submit" disabled={showNewCategory || load }>
                  {load ? 'Creating...' : 'Create now'}
                </Button>
              </div>
              <div className="skip-btn-wrap">
                <Button
                  variant="default"
                  onClick={() => props?.onHide()}
                >
                  Skip {longRightArrowIcon}
                </Button>
              </div>
            </form>
          </Col>
          <Col lg={6}>
            <div className="virtual-img-wrap">
              <img src={virtualImage} className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
export default CreateNewProjectModal;
