import React, { useState } from 'react';

import { crossIcon } from '../../constants/icon';
import { showToaster } from '../../services/helper';

export default function TagInput(props) {
  let { placeholder, label, tags, handleFocus = () => {}, disabled = false } = props;

  if (!tags) tags = [];
  else tags = tags.split(',');

  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };

  const setTags = tagValues => {
    props.onChange(tagValues);
  };

  const handleInputKeyPress = e => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      console.log('check');
      const splitInputValue = inputValue.split(',');
      if (splitInputValue.find(value => value.length < 2)) {
        return showToaster('Tag value must be more than one character', 'error');
      }
      setTags([...tags, inputValue]);
      setInputValue('');
    }
  };

  const handleTagRemove = tag => {
    const updatedTags = tags.filter(t => t !== tag);
    setTags(updatedTags);
  };

  const onFocus = () => {
    setIsFocused(true);
    handleFocus();
  };

  return (
    <div className={`tag-input-container ${isFocused || tags.length > 0 ? 'tag-focused' : ''}`}>
      <label className={`floating-tag-label ${isFocused || tags.length > 0 ? 'tag-focused' : ''}`}>
        {label}
      </label>
      <div className="tag-input">
        {tags.map((tag, index) => (
          <div key={index} className="tag">
            {tag}
            <span className="tag-remove" onClick={() => (disabled ? '' : handleTagRemove(tag))}>
              {crossIcon}
            </span>
          </div>
        ))}
        <input
          type="text"
          onFocus={onFocus}
          value={inputValue}
          placeholder={placeholder}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyPress}
          onBlur={() => setIsFocused(false)}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
