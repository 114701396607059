import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {Axioslib} from '../../../lib/axioslib'
import { showToaster } from '../../../services/helper'
import { useSelector } from 'react-redux';
export default function Resetpassword(props) {
  const [uploadProgress, setUploadProgress] = useState(false);
  let history = useHistory();
  const [token, setToken] = useState();
  let DisplayMode = useSelector(state => state.project.DisplayMode)
  useEffect(() => {
    const queryString = props.location.search;
    const query = new URLSearchParams(queryString);
    const validtoken = query.get('token');
    // this.setState({ token: validtoken })
    setToken(validtoken);
  });
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        // .min(6, 'Password must be at least 6 characters')
        .required('Password is required')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),

    onSubmit: async (values) => {
      setUploadProgress(true);
      let data = {
        password: values.confirmPassword,
      };
      try {
        const response = await Axioslib.put(`users/forgot/` + token, data);
        if (
          response !== '' &&
          response !== undefined &&
          response !== null &&
          response.data !== '' &&
          response.data !== undefined &&
          response.data !== null
        ) {
          showToaster(' Reset your password successfully', 'success');
          history.push('/');
          setUploadProgress(false);
        } else {
          showToaster('Something went wrong. Please Try Again', 'warning');
          setUploadProgress(false);
        }
      } catch (e) {
        setUploadProgress(false);
      }
    },
  });

  return (
    // <form onSubmit={formik.handleSubmit}>
    <div className={DisplayMode ? "theme dark" : "theme"}>
      <div className="d-flex login-wrapper h-100">
        <div className="login-left-part">
          <div className="login-logo">
            <img src="assets/img/Logo.svg" className="img-fluid" />
          </div>
          <div className="login-infog"></div>
        </div>
        <div className="d-flex align-items-center justify-content-center login-right-part">
          <div className="login-container w-100">
            <div className="login-cap">Reset your Password</div>
            <div className="login-para fs-20">
              Welcome to production automation
            </div>
            <form onSubmit={formik.handleSubmit} id="login-form">
              <div className="login-inp-wrapper">
                <div className="custom-input-holder">
                  <div className="custom-label">Enter new password</div>
                  <div className="custom-input-container position-relative">
                    <span className="user-avatar-inpt">
                      <img
                        src="assets/img/user-icon.png"
                        className="img-fluid"
                      />
                    </span>
                    <input
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.newPassword}
                      className="custom-input"
                      placeholder="Enter new Password"
                    />
                  </div>
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="input-error-msg">
                      {formik.errors.newPassword}
                    </div>
                  ) : null}
                  {/* <div className="input-error-msg">Invalid username</div> */}
                </div>
                <div className="custom-input-holder">
                  <div className="custom-label">Enter confirm password</div>
                  <div className="custom-input-container position-relative">
                    <span className="user-avatar-inpt">
                      <img
                        src="assets/img/user-icon.png"
                        className="img-fluid"
                      />
                    </span>
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                      className="custom-input"
                      placeholder="Enter confirm Password"
                    />
                  </div>
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                      <div className="input-error-msg">
                        {formik.errors.confirmPassword}
                      </div>
                    ) : null}
                  {/* <div className="input-error-msg">Invalid username</div> */}
                </div>

                <div className="login-btn-holder mt-2">
                  <button type="submit" className="btn btn-primary login-btn">
                    Submit
                    <span className="login-arrow">
                      <svg
                        enable-background="new 0 0 96 96"
                        height="96px"
                        id="arrow_right"
                        version="1.1"
                        viewBox="0 0 96 96"
                        width="96px"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                      >
                        <path d="M12,52h62.344L52.888,73.456c-1.562,1.562-1.562,4.095-0.001,5.656c1.562,1.562,4.096,1.562,5.658,0l28.283-28.284l0,0  c0.186-0.186,0.352-0.391,0.498-0.609c0.067-0.101,0.114-0.21,0.172-0.315c0.066-0.124,0.142-0.242,0.195-0.373  c0.057-0.135,0.089-0.275,0.129-0.415c0.033-0.111,0.076-0.217,0.099-0.331C87.973,48.525,88,48.263,88,48l0,0  c0-0.003-0.001-0.006-0.001-0.009c-0.001-0.259-0.027-0.519-0.078-0.774c-0.024-0.12-0.069-0.231-0.104-0.349  c-0.039-0.133-0.069-0.268-0.123-0.397c-0.058-0.139-0.136-0.265-0.208-0.396c-0.054-0.098-0.097-0.198-0.159-0.292  c-0.146-0.221-0.314-0.427-0.501-0.614L58.544,16.888c-1.562-1.562-4.095-1.562-5.657-0.001c-1.562,1.562-1.562,4.095,0,5.658  L74.343,44L12,44c-2.209,0-4,1.791-4,4C8,50.209,9.791,52,12,52z" />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    // </form>
  );
}

// import "./styles.css";

// Login.propTypes = {

// };
