import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../../store";
import Tooltip from "@mui/material/Tooltip";
import Videoplayer from "../../components/Videoplayer/Videoplayer";
import {
  getProjectById,
  getTemplatePlanByOrg,
  UpdateDbProjectDatabyId,
} from "../../../services/authService";
import { projectScreenPreview } from "../../../services/api.service";
export default function TemplatePriview(props) {
  const [TemplateId, setTemplateId] = useState("");

  const project = useSelector((state) => state.preview.previewProject);
  const projectId = useSelector((state) => state.preview.ProjectId);
  const ActiveModule = useSelector((state) => state.preview.ActiveModuleId);
  const ActiveSlide = useSelector((state) => state.preview.ActiveSlideId);
  const ActiveScreen = useSelector((state) => state.preview.ActiveScreenId);
  const dispatch = useDispatch();
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [templateStatus, settemplateStatus] = useState(false);
  let { UpdatePreviewFeature, UpdatePreview, UpdateTemplateUrlPreview } =
    bindActionCreators(actionCreator, dispatch);

  useEffect(() => {
    console.log(props.dropDownSelection);
    if (props.selectedVideo.mediaUrl !== undefined) {
      console.log(props);
      let url = props.selectedVideo.mediaUrl;
      let lastIndex = url.lastIndexOf("/");
      let templateFile = url.substring(lastIndex + 1);
      let templateId = templateFile.split(".");
      setTemplateId(templateId[0]);
    }
  }, [props]);
  useEffect(() => {
    console.log("caleeeddddd", project);
    if (templateStatus) {
      getTemplatePreview();
    }
  }, [templateStatus]);

  const SubmitTemplate = async () => {
    UpdateTemplateUrlPreview(
      props.ActiveModule,
      props.ActiveSlide,
      props.ActiveScreen,

      {
        Contentparams: {
          contenttag: "Null",
          sequenceid: "16",
          TemplateType: "motion_graphics",
          contentdescription: props.selectedVideo.mediaUrl,
          useTemplateStatus: false,
          sourcescreen: "",
        },
      },
      projectId
    );
    // props.setProjectData(project)
    // props.updatedtemplateURldata(project)
    settemplateStatus(true);
    props.nextScreen();
    let data = props.dropDownSelection.status;
    let count = data + 1;
    console.log(count, "count");
    props.setNextSelectionIndex(count);
    props.closePreview();
  };
  const getTemplatePreview = async () => {
    console.log(project, projectId, "111111111");
    let modules = project;
    let projectName = modules[projectId].ProjectName;
    //console.log(projectName);
    let activeScreen =
      modules[projectId]["Modules"][props.ActiveModule]["slides"][
        props.ActiveSlide
      ]["Screens"][props.ActiveScreen];
     console.log(activeScreen);
    let activeScreenDetails = {
      ScreenName:
        modules[projectId]["Modules"][props.ActiveModule]["slides"][
          props.ActiveSlide
        ]["Screens"][props.ActiveScreen]["ScreenName"],
      ...activeScreen,
    };
    let SlideAudioVO =
      modules[projectId]["Modules"][props.ActiveModule]["slides"][
        props.ActiveSlide
      ]["SlideAudioVO"] !== undefined
        ? modules[projectId]["Modules"][props.ActiveModule]["slides"][
            props.ActiveSlide
          ]["SlideAudioVO"]
        : "Null";
    //console.log('response.data.mediaurl');
    let screenContent = {
      [projectId]: {
        ProjectName: projectName,
        Modules: {
          [props.ActiveModule]: {
            ModuleName:
              modules[projectId]["Modules"][props.ActiveModule]["ModuleName"],
            slides: {
              [props.ActiveSlide]: {
                SlideName:
                  modules[projectId]["Modules"][props.ActiveModule]["slides"][
                    props.ActiveSlide
                  ]["SlideName"],
                SlideAudioVO: SlideAudioVO,
                Screens: {
                  [props.ActiveScreen]: activeScreenDetails,
                },
              },
            },
          },
        },
      },
    };
  
    // timer2Ref.current = setTimeout(async () => {
    // if (activeScreenDetails.mediaurl) {
    //   // if (response.data !== "" && response.data !== undefined && response.data !== null) {
    //     // if (response.data.mediaurl !== undefined && response.data.mediaurl !== null && response.data.mediaurl !== "") {
    //       // console.log(response.data);
    //       if (activeScreenDetails.mediaurl["ThumbnailUrl"]) {

    //         let updatedprojectData = await UpdateProjectDatabyId(
    //           modules,
    //           props.ActiveModule,
    //           props.ActiveSlide,
    //           props.ActiveScreen, activeScreenDetails.mediaurl)
    //         console.log(updatedprojectData);
    //         if (updatedprojectData != undefined && updatedprojectData != null && updatedprojectData != "") {
    //           // let updatedDb = await UpdateDbProjectDatabyId(id, updatedprojectData)
    //           // console.log(updatedDb);

    //           // if (updatedDb.data.data != undefined && updatedDb.data.data.projectData !== undefined) {
    //           //   UpdatePreview(JSON.parse(updatedDb.data.data.projectData))
    //           //   setProjectData(JSON.parse(updatedDb.data.data.ProjectData))
    //           // }
    //           UpdatePreview(updatedprojectData)
    //           props.setProjectData(updatedprojectData)
    //         }
    //         // UpdateThumbnail(screen.moduleName, screen.slideName, screen.screenName, response.data.mediaurl["ThumbnailUrl"])

    //       }
    //     // }
    //   // }

    // }else{
      let type;
      let updatedprojectData = await DeleteProjectDatabyId(
        modules,
        props.ActiveModule,
        props.ActiveSlide,
        props.ActiveScreen,
        (type = "DeleteUrl")
      );
      console.log(props.ActiveScreen);
      if (
        updatedprojectData != undefined &&
        updatedprojectData != null &&
        updatedprojectData != ""
      ) {
        let updatedDb = await UpdateDbProjectDatabyId(
          projectId,
          updatedprojectData
        );
        console.log(updatedDb.data.data);
        console.log(updatedDb.data.data.ProjectData);
        if (updatedDb) {
          if (updatedDb.data.data != undefined) {
            console.log(JSON.parse(updatedDb.data.data.ProjectData));
            UpdatePreview(JSON.parse(updatedDb.data.data.ProjectData));
            props.setProjectData(JSON.parse(updatedDb.data.data.ProjectData));
          }
        }
      }
    let response = await projectScreenPreview(JSON.stringify(screenContent));
    if (
      response !== "" &&
      response !== undefined &&
      response !== null &&
      response.data !== "" &&
      response.data !== undefined &&
      response.data !== null
    ) {
      // count++;
      if (
        response.data.mediaurl !== undefined &&
        response.data.mediaurl !== null &&
        response.data.mediaurl !== ""
      ) {
        console.log(response.data, "mediaurl");
        if (response.data.mediaurl["ThumbnailUrl"]) {
          // if (index === 0) {
          //   props.setRenderThumnails(arr => [...arr, response.data.mediaurl['ThumbnailUrl']])
          // }
          let updatedprojectData = await UpdateProjectDatabyId(
            modules,
            props.ActiveModule,
            props.ActiveSlide,
            props.ActiveScreen,
            response.data.mediaurl
          );
          console.log(updatedprojectData);
          if (
            updatedprojectData != undefined &&
            updatedprojectData != null &&
            updatedprojectData != ""
          ) {
            let updatedDb = await UpdateDbProjectDatabyId(
              projectId,
              updatedprojectData
            );
            console.log(updatedDb.data.data);
            console.log(updatedDb.data.data.ProjectData);
            if (updatedDb) {
              if (updatedDb.data.data != undefined) {
                console.log(JSON.parse(updatedDb.data.data.ProjectData));
                UpdatePreview(JSON.parse(updatedDb.data.data.ProjectData));
                props.setProjectData(
                  JSON.parse(updatedDb.data.data.ProjectData)
                );
              }
            }
          }
          // UpdateThumbnail(screen.moduleName, screen.slideName, screen.screenName, response.data.mediaurl["ThumbnailUrl"])
        }
        // }
      }
    }
    // if (_screens.length == count) {
    //   resolve(true)
    // }
    // }, index * 10000);
    console.log(screenContent, "screenContent");
    settemplateStatus(false);
  };
  const DeleteProjectDatabyId = (
    projectdata,
    modulename,
    slidename,
    screenname,
    type
  ) => {
    console.log(projectdata,
      modulename,
      slidename,
      screenname,
      type,"calleddddddddddddd");
    return new Promise((resolve, reject) => {
      console.log(projectdata);
      if (projectdata != undefined) {
        console.log("called", projectdata);
        if (projectdata[projectId] != null) {
          if (projectdata[projectId].Modules != undefined) {
            Object.keys(projectdata[projectId].Modules).map((module) => {
              console.log(module);
              if (module == modulename) {
                Object.keys(projectdata[projectId].Modules[module].slides).map(
                  (slide) => {
                    if (slide == slidename) {
                      Object.keys(
                        projectdata[projectId].Modules[module].slides[slide]
                          .Screens
                      ).map(async (item, i) => {
                        if (item == screenname && type == "DeleteUrl") {
                          console.log(
                            projectdata[projectId].Modules[module].slides[
                              slide
                            ].Screens[item],
                            item
                          );
                          delete projectdata[projectId].Modules[module].slides[
                            slide
                          ].Screens[item].mediaurl;
                        }
                      });
                    }
                  }
                );
              }
            });
            // setthumbnailRequested(true)
          }
        }
      }
      resolve(projectdata);
    });
  };
  const UpdateProjectDatabyId = (
    projectdata,
    modulename,
    slidename,
    screenname,
    mediaurl
  ) => {
    console.log("calleddddddddddddd");
    return new Promise((resolve, reject) => {
      console.log(projectdata);
      if (projectdata != undefined) {
        console.log("called", projectdata);
        if (projectdata[projectId] != null) {
          if (projectdata[projectId].Modules != undefined) {
            Object.keys(projectdata[projectId].Modules).map((module) => {
              console.log(module);
              if (module == modulename) {
                Object.keys(projectdata[projectId].Modules[module].slides).map(
                  (slide) => {
                    if (slide == slidename) {
                      Object.keys(
                        projectdata[projectId].Modules[module].slides[slide]
                          .Screens
                      ).map(async (item, i) => {
                        if (item == screenname) {
                          console.log(
                            projectdata[projectId].Modules[module].slides[slide]
                              .Screens,
                            item
                          );
                          projectdata[projectId].Modules[module].slides[
                            slide
                          ].Screens[item].mediaurl = mediaurl;
                        }
                      });
                    }
                  }
                );
              }
            });
            // setthumbnailRequested(true)
          }
        }
      }
      resolve(projectdata);
    });
  };
  return (
    <Modal
      isOpen={props.openTemplatePreview}
      fade={true}
      centered
      className={
        DisplayMode
          ? "modal-dialog  modal-dialog-centered temp-modal custom-modal dark"
          : "modal-dialog  modal-dialog-centered temp-modal custom-modal"
      }
      // className="modal-dialog  modal-dialog-centered temp-modal custom-modal dark"
    >
      <ModalBody>
        <Tooltip title="Close" arrow>
          <div className="close-priview" onClick={() => props.closePreview()}>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g data-name="Layer 2">
                <g data-name="close">
                  <rect
                    width="24"
                    height="24"
                    transform="rotate(180 12 12)"
                    opacity="0"
                  />
                  <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                </g>
              </g>
            </svg>
          </div>
        </Tooltip>
        <div className="temp-part">
          <div className="temp-left-part">
            <div className="video-inner">
              {/* <video
                autoPlay
                className="object-cover"
                width="100%"
                controls
                disablePictureInPicture
                controlsList="nodownload"
              >
                <source src={props.selectedVideo.preview_url} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video> */}

              <Videoplayer
                thumbnailUrl=""
                videoUrl={props.selectedVideo.previewUrl}
                showVolume={false}
              />
            </div>
          </div>
          <div className="temp-right-part ps-3">
            <div className="d-flex flex-column justify-content-between h-100">
              <div className="temp-rht-top">
                <div className="temp-cap">Template id : {TemplateId}</div>
                {/* <div className="temp-subcap">
                  Virtual Reality, or VR, is the use of computer technology to
                  create a simulated environment which can be explored in 360
                  degrees. Unlike traditional interfaces, VR places the user
                  inside the virtual environment to give an immersive experience
                </div> */}
              </div>
              <div className="temp-rht-btm">
                {/* <div className="dev-spec-wrapper">
                  <div className="dev-spec-container active">
                    <div className="dev-spec-icon"></div>
                    <div className="dev-spec-label">1366x768</div>
                  </div>
                  <div className="dev-spec-container">
                    <div className="dev-spec-icon"></div>
                    <div className="dev-spec-label">1920x1080</div>
                  </div>
                  <div className="dev-spec-container">
                    <div className="dev-spec-icon"></div>
                    <div className="dev-spec-label">1080x1920</div>
                  </div>
                </div> */}
                <div className="my-1">
                  <button
                    type="submit"
                    className="btn btn-primary login-btn"
                    onClick={() => {
                      SubmitTemplate();
                    }}
                    value="submit"
                  >
                    Use Template
                    <span className="login-arrow">
                      <svg
                        enable-background="new 0 0 96 96"
                        height="96px"
                        id="arrow_right"
                        version="1.1"
                        viewBox="0 0 96 96"
                        width="96px"
                        space="preserve"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                      >
                        <path d="M12,52h62.344L52.888,73.456c-1.562,1.562-1.562,4.095-0.001,5.656c1.562,1.562,4.096,1.562,5.658,0l28.283-28.284l0,0  c0.186-0.186,0.352-0.391,0.498-0.609c0.067-0.101,0.114-0.21,0.172-0.315c0.066-0.124,0.142-0.242,0.195-0.373  c0.057-0.135,0.089-0.275,0.129-0.415c0.033-0.111,0.076-0.217,0.099-0.331C87.973,48.525,88,48.263,88,48l0,0  c0-0.003-0.001-0.006-0.001-0.009c-0.001-0.259-0.027-0.519-0.078-0.774c-0.024-0.12-0.069-0.231-0.104-0.349  c-0.039-0.133-0.069-0.268-0.123-0.397c-0.058-0.139-0.136-0.265-0.208-0.396c-0.054-0.098-0.097-0.198-0.159-0.292  c-0.146-0.221-0.314-0.427-0.501-0.614L58.544,16.888c-1.562-1.562-4.095-1.562-5.657-0.001c-1.562,1.562-1.562,4.095,0,5.658  L74.343,44L12,44c-2.209,0-4,1.791-4,4C8,50.209,9.791,52,12,52z" />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
