import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    padding:"2px"
  },
}));

export default function SimpleBackdrop(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={props.showBackDrop}>
        {props.message !== undefined && props.message !== null && props.message !== "" && props.message }
        <CircularProgress color="inherit" size='2rem'/>
      </Backdrop>
    </React.Fragment>
  );
}
