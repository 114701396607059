import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useEffect, useState } from 'react';

import { actionCreator } from '../../store';
import PageLoader from '../../services/pageLoader';
import { uploadMediaFile } from '../../services/api.service';
import { getUserItem, showToaster } from '../../services/helper';

export default function CanvasImage(props) {
  const dispatch = useDispatch();
  const { EditAsset } = bindActionCreators(actionCreator, dispatch);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const currentCanvas = document.createElement('canvas');

      props.setexposure(100);
      props.setcontrast(100);
      props.setsaturation(100);
      props.setopacity(100);
      let imgLoaded = false;
      let ctx = currentCanvas.getContext('2d');
      ctx.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
      ctx.font = '24px sans-serif';
      ctx.textAlign = 'center';
      ctx.fillStyle = '#468BE8';
      ctx.fillText('Loading...', 250, 250);
      ctx.rect(0, 0, currentCanvas.width, currentCanvas.height);
      let img = document.createElement('img');

      img.crossOrigin = 'anonymous';
      img.onload = function () {
        ctx.canvas.width = img.width;
        ctx.canvas.height = img.height;
        ctx.clearRect(0, 0, img.width, img.height);
        ctx.drawImage(img, 0, 0, img.width, img.height);
        imgLoaded = true;
      };
      if (props.src != '' && props.src != undefined && props.src != null) {
        img.src = props.src + `?not-from-cache-please=${Date.now()}`;
      }
      setTimeout(async () => {
        if (imgLoaded == false) {
          ctx.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
          ctx.font = '24px sans-serif';
          ctx.textAlign = 'center';
          ctx.fillStyle = '#468BE8';
          ctx.fillText('Loading...', 250, 250);
          ctx.rect(0, 0, currentCanvas.width, currentCanvas.height);
          let img = document.createElement('img');

          img.crossOrigin = 'anonymous';
          img.onload = function () {
            ctx.canvas.width = img.width;
            ctx.canvas.height = img.height;
            ctx.clearRect(0, 0, img.width, img.height);
            ctx.drawImage(img, 0, 0, img.width, img.height);
            imgLoaded = true;
          };
          if (props.src != '' && props.src != undefined && props.src != null) {
            img.src = props.src + `?not-from-cache-please=${Date.now()}`;
          }
        }
      }, 5000);
    } catch (error) {
      props.setexposure(100);
      props.setcontrast(100);
      props.setsaturation(100);
      props.setopacity(100);
      const currentCanvas = document.createElement('canvas');
      let ctx = currentCanvas.getContext('2d');
      ctx.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
      ctx.font = '24px sans-serif';
      ctx.textAlign = 'center';
      ctx.fillStyle = '#468BE8';
      ctx.fillText('Loading...', 250, 250);
      ctx.rect(0, 0, currentCanvas.width, currentCanvas.height);
      let img = document.createElement('img');
      img.crossOrigin = 'anonymous';
      img.onload = function () {
        ctx.canvas.width = img.width;
        ctx.canvas.height = img.height;
        ctx.clearRect(0, 0, img.width, img.height);
        ctx.drawImage(img, 0, 0, img.width, img.height);
      };
      img.src = props.src + `?not-from-cache-please=${Date.now()}`;
    }
  }, []);

  useEffect(() => {
    async function loadAndUploadImage() {
      setLoading(true);
      const processedImg = await getImage();
      props.setnewImage(processedImg);
      if (props.uploadImage) {
        uploadCroppedImage(processedImg);
      }
      props.setprocessEditImg(false);
      setLoading(false);
    }

    if (props.processEditImg) {
      loadAndUploadImage();
    }
  }, [props.processEditImg]);

  useEffect(() => {
    if (props.src) {
      setLoading(true);
    }

    props.setopacity(100);
    props.setexposure(100);
    props.setcontrast(100);
    props.setsaturation(100);

    const currentCanvas = document.createElement('canvas');
    let ctx = currentCanvas.getContext('2d');
    ctx.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
    ctx.font = '24px sans-serif';
    ctx.textAlign = 'center';
    ctx.fillStyle = '#468BE8';
    ctx.fillText('Loading...', 250, 250);
    ctx.rect(0, 0, currentCanvas.width, currentCanvas.height);
    let img = document.createElement('img');
    img.crossOrigin = 'anonymous';

    img.onload = function () {
      ctx.canvas.width = img.width;
      ctx.canvas.height = img.height;
      ctx.clearRect(0, 0, img.width, img.height);
      ctx.filter =
        'contrast(' +
        props.contrast +
        '%) brightness(' +
        props.exposure +
        '%) saturate(' +
        props.saturation +
        '%) opacity(' +
        props.opacity +
        '%)';
      ctx.drawImage(img, 0, 0, img.width, img.height);
      //let imgDone = currentCanvas.toDataURL("image/jpeg");
    };
    img.src = props.src + `?not-from-cache-please=${Date.now()}`;
  }, [props.src]);

  useEffect(() => {
    const currentCanvas = document.createElement('canvas');
    let ctx = currentCanvas.getContext('2d');
    ctx.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
    ctx.font = '24px sans-serif';
    ctx.textAlign = 'center';
    ctx.fillStyle = '#468BE8';
    ctx.fillText('Updating...', 250, 250);
    ctx.rect(0, 0, currentCanvas.width, currentCanvas.height);
    let img = document.createElement('img');
    currentCanvas.width = 480;
    currentCanvas.height = 270;
    img.crossOrigin = 'anonymous';
    img.onload = function () {
      ctx.canvas.width = img.width;
      ctx.canvas.height = img.height;
      ctx.clearRect(0, 0, img.width, img.height);
      ctx.filter =
        'contrast(' +
        props.contrast +
        '%) brightness(' +
        props.exposure +
        '%) saturate(' +
        props.saturation +
        '%) opacity(' +
        props.opacity +
        '%)';
      ctx.drawImage(img, 0, 0, img.width, img.height);
    };
    img.src = props.src + `?not-from-cache-please=${Date.now()}`;
  }, [props.contrast, props.exposure, props.saturation, props.opacity]);

  const getImage = () => {
    return new Promise((resolve, reject) => {
      const currentCanvas = document.createElement('canvas');
      let ctx = currentCanvas.getContext('2d');
      ctx.clearRect(0, 0, currentCanvas.width, currentCanvas.height);

      let img = document.createElement('img');
      currentCanvas.width = 480;
      currentCanvas.height = 270;
      img.crossOrigin = 'anonymous';
      img.onload = function () {
        ctx.canvas.width = img.width;
        ctx.canvas.height = img.height;
        ctx.filter = `contrast(${props.contrast}%) brightness(${props.exposure}%) saturate(${props.saturation}%) opacity(${props.opacity}%)`;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        let imgDone = currentCanvas.toDataURL('image/jpeg');
        resolve(imgDone);
      };
      img.src = props.src + `?not-from-cache-please=${Date.now()}`;
    });
  };

  async function uploadCroppedImage(croppedImage) {
    try {
      let filename = 'IMG_' + Math.floor(Math.random() * 1000000);
      let file = await urltoFile(croppedImage, filename + '.jpg', '');

      let data = new FormData();
      const config = {
        headers: {
          private: 1,
          media_type: 'IMAGE',
          user_id: getUserItem('id'),
          file_name: filename + '.jpeg',
          'content-type': 'multipart/form-data',
        },
        timeout: 1000 * 50000,
      };
      data.append('file', file);

      uploadMediaFile(data, config).then(response => {
        if (response.data) {
          EditAsset({ contentdescription: response.data }, 'image');

          setLoading(false);
          props.closePreview();
          props.setshowLoader(false);
          props.setuploadImage(false);
          props.setprocessEditImg(false);
          showToaster('Image saved successfully', 'success');
        } else {
          setLoading(false);
          props.setshowLoader(false);
          props.setuploadImage(false);
          props.setprocessEditImg(false);
          showToaster(
            "We couldn't Recognize the image Updated. Please try again with another image",
            'warning'
          );
        }
      });
    } catch (error) {
      console.log({ uploadCroppedImageError: error });
      setLoading(false);
      props.setshowLoader(false);
      props.setuploadImage(false);
      props.setprocessEditImg(false);
      showToaster(
        "We couldn't Recognize the image Updated. Please try again with another image",
        'warning'
      );
    }
  }

  const urltoFile = (url, filename) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: 'image/jpeg' });
      });
  };

  return (
    <>
      {props.src ? (
        <img
          alt="image_edit"
          onLoad={() => setLoading(false)}
          style={{ width: '100%', height: '100%' }}
          src={`${props.src}?time=${new Date().getTime()}`}
        />
      ) : (
        <div className="label-block">
          <p className="edit-label">No Image</p>
        </div>
      )}

      <PageLoader showLoader={loading} />
    </>
  );
}
