import truncateWords from "../../services/truncate";

function InnerSidebarHeader(props) {
  const { title, subTitle, icon } = props;
  return (
    <a href="#" className={`inner-sidebar-header ${props.className || ""}`}>
      {icon && <div className="icon-wrap">{icon}</div>}
      <div className="info-wrap">
        {title && <h4>{truncateWords(title, 25)}</h4>}
        {subTitle && <span>{truncateWords(subTitle, 25)}</span>}
      </div>
    </a>
  );
}
export default InnerSidebarHeader;
