import React, { useEffect, useState, useRef } from "react";
import { Modal, ModalBody } from "reactstrap";
import ReactPlayer from "react-player";
import { LinearProgress, Tooltip } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import Videoplayer from "../../components/Videoplayer/Videoplayer";
import { useSelector, useDispatch } from "react-redux";
import {
  getProjectPreview,
  renderPreview,
  createProjects,
  getProjectID,
  listClusterDetails,
  getClusterDetails,
  updateClusterDetails,
} from "../../../services/api.service";
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../store';
import { CreatePreviewFromResponse, removeMediaUrlFilter } from "../../../services/DataHelper";
import { ShowAlertBox, showToaster } from "../../../services/helper";
import LinearWithValueLabel from "../../components/LinearProgressWithLabel";
import { useHistory } from "react-router-dom";
import { createTemplateProject, getProjectById, getTemplateRenderStatus, getUsersProjectRole, renderTemplate } from "../../../services/authService";
export default function RenderTemplate(props) {
  const [selectedVideo, setselectedVideo] = useState(undefined);
  const [videos, setVideos] = useState(undefined);
  const [startRender, setstartRender] = useState(false);
  const [renderStatus, setrenderStatus] = useState(0);
  const [statusMessage, setstatusMessage] = useState("");
  let DisplayMode = useSelector(state => state.project.DisplayMode)
  const timerRef = useRef(null);
  const mounted = useRef(true);
  useEffect(() => {

    if (!startRender && props.openRenderVideo && props.template && !props.template.isRendering) {
      setVideos([]);
      renderProject();
    }
    if(!props.openRenderVideo) {
      clearTimeout(timerRef.current)
    }
  }, [startRender, props.openRenderVideo, props.template]);
  
  useEffect(() => {
    return () => {
      mounted.current = false;
      clearTimeout(timerRef.current)
    }
  }, [])

  const renderProject = async () => {
    try {
      setstartRender(true);
      const projectId = props.template.projectDetails.ProjectID;
      const projectName = props.template.projectDetails.ProjectName;
      // const projectData = JSON.parse(props.template.projectDetails.ProjectData);
      // if(!projectData[projectId].Modules.M01.slides.SL01.SlideRender) {
      //   projectData[projectId].Modules.M01.slides.SL01.SlideRender = "1";
      //   await createTemplateProject({ ...projectData, tId: props.template.tid });
      //   const projectResp = await getProjectID({ project_id: projectId });
      //   if(Array.isArray(projectResp.data) && projectResp.data.length > 0) {
      //     if(projectResp.data[0].Is_Valid === 0) {
      //       props.closePreview();
      //       setstartRender(false);
      //       props.updateRenderStatus(props.template, { isRendering: false, projectDetails: {...projectResp.data[0]} })
      //       showToaster(
      //         "There is no valid data for rendering the project!!",
      //         "warning"
      //       );
      //       return;
      //     } else {
      //       props.updateRenderStatus(props.template, { projectDetails: {...projectResp.data[0]} })
      //     }
      //   }
      // }
      
      // let renderFilter = {
      //   project_id: projectId,
      //   projectName: projectName,
      //   tId: props.template.tid
      // };
      let renderFilter
      let ddd = localStorage.getItem("_cluster-details");
        if(ddd!==undefined&&ddd!==null&&ddd!==""){
          let userDetails = JSON.parse(ddd);
           renderFilter = {
            project_id: projectId,
            projectName: projectName,
            tId: props.template.tid,
            cluster_name: userDetails.cluster_name,
            render_cluster_name: userDetails.rendercluster_name,
            parallel_process_count: 4,
          };
        }else{
          let clusterDetails;
          await getClusterDetails().then((response) => {
            try {
                if (response !== "" && response !== undefined && response !== null && response.data !== "" && response.data !== undefined && response.data !== null) {
                    clusterDetails = response.data.result[0]
                    if (response.data.result[0].status === false) {
                        let filter = {
                            resource_group: "Content-Pro",
                            cluster_name: response.data.result[0].cluster_name
                        }

                        listClusterDetails(filter).then((response1) => {
                            console.log(response1,response,"response");
                            try {
                                if (response1 !== "" && response1 !== undefined && response1 !== null && response1.data !== "" && response1.data !== undefined && response1.data !== null) {
                                    console.log(response1.data.Body[clusterDetails.rendercluster_name].provisioning_state, "haridasan");
                                    if(response1.data.Body[clusterDetails.rendercluster_name].provisioning_state==="Succeeded"){
                                        let statusFilter={
                                         status:"Created"
                                        }
                                        updateClusterDetails(clusterDetails._id,statusFilter).then((response1) => {
                                            try {
                                                if (response1 !== "" && response1 !== undefined && response1 !== null && response1.data !== "" && response1.data !== undefined && response1.data !== null) {
                                                    // console.log(response1.data[clusterDetails.rendercluster_name], response1, "haridasan");
                                               
                                                    
                                                } else {
                                                  
                                                    showToaster('Something went wrong. Please Try Again1', 'warning');
                                                }
                                            } catch (error) {
                                              
                                                showToaster('Something went wrong. Please Try Again2', 'warning');
                                            }

                                        })
                                    }
                                    
                                } else {
                                  
                                    showToaster('Something went wrong. Please Try Again3', 'warning');
                                }
                            } catch (error) {
                                console.log(error,"error");
                              
                                showToaster('Something went wrong. Please Try Again4', 'warning');
                            }

                        })
                    }
                    window.localStorage.setItem(
                        '_cluster-details',
                        JSON.stringify(response.data.result[0])
                    );
                    console.log(response, "responsecluster");
                } else {
                  
                    showToaster('Something went wrong. Please Try Again5', 'warning');
                }
            } catch (error) {
              
                showToaster('Something went wrong. Please Try Again6', 'warning');
            }

        })
        let ddd = localStorage.getItem("_cluster-details");
        if(ddd!==undefined&&ddd!==null&&ddd!==""){
          let userDetails = JSON.parse(ddd);
           renderFilter = {
            project_id: projectId,
            projectName: projectName,
            tId: props.template.tid,
            cluster_name: userDetails.cluster_name,
            render_cluster_name: userDetails.rendercluster_name,
            parallel_process_count: 4,
          };
        }
        
        }
     
      
    //   let ddd = localStorage.getItem("_cluster-details");
    
    // let userDetails = JSON.parse(ddd);
    // console.log(userDetails, "listUserslistUsers");
    // let renderFilter = {
    //   project_id: projectId,
    //   projectName: projectName,
    //   tId: props.template.tid,
    //   cluster_name:userDetails.cluster_name,
    //   render_cluster_name:userDetails.rendercluster_name,
    //   parallel_process_count:4
    // };
      const response = await renderTemplate(renderFilter)
      if (
        response.status !== undefined &&
        response.status !== null &&
        response.status === 200
      ) {
        console.log('$$$$$$',response, renderFilter)
        props.updateRenderStatus(props.template, { isRendering: true })
        getPreview();
      } else if (
        response.message !== undefined &&
        response.message !== null &&
        response.message !== ""
      ) {
        // setRenderMessage(response.message);
      } else {
        ShowAlertBox(
          "Oops...",
          "Something went wrong. Please try again",
          "error"
        );
      }
    } catch(e) {
      ShowAlertBox(
        "Oops...",
        "Something went wrong. Please try again",
        "error"
      );
    }
  };

  const getPreview = async () => {
    try {
      let renderFilter = {
        project_id: props.template.projectDetails.ProjectID,
        projectName: props.template.projectDetails.ProjectName,
        tId: props.template.tid
      };
      const response = await getTemplateRenderStatus(renderFilter);
      console.log(response, "apiStatus");
      if(!props.openRenderVideo) {
        if(mounted && mounted.current) {
          setstartRender(false);
        }
        return;
      }
      if (
        response.data !== undefined &&
        response.data !== null &&
        response.data !== "" &&
        response.status !== undefined &&
        response.status !== null &&
        (response.status === 200 || response.status === 500)
      ) {
        
        if (response.data.is_valid === 0) {
          props.closePreview();
          setstartRender(false);
          props.updateRenderStatus(props.template, { isRendering: false })
          showToaster(
            "There is no valid data for rendering the project!!",
            "warning"
          );
        } else if (response.apiStatus === false) {
          renderProject()
        } else if (response.message == "Error, please try rendering video again") {
          props.closePreview();
          setstartRender(false);
          props.updateRenderStatus(props.template, { isRendering: false })
          showToaster(
            "Error, please try rendering video again",
            "warning"
          );
        } else {
          if (
            response.data.mediaurl !== undefined &&
            response.data.mediaurl !== null &&
            response.data.mediaurl !== "" &&
            Object.keys(response.data.mediaurl).length !== 0
          ) {
            if (response.data.renderstatus === "100%") {
              setrenderStatus(100)
              props.updateRenderStatus(props.template, { isRendering: false, renderDetails: {...response.data}, projectDetails: { ...props.template.projectDetails, RenderStatus: "100%" } })
              var arrayValue = [];
              let mediaurl = response.data.mediaurl;
              Object.keys(mediaurl).map(function (key, index) {
                Object.keys(mediaurl[key]).map(function (k, i) {

                  if (mediaurl[key][k].SlideRender === "1") {
                    arrayValue.push(mediaurl[key][k]);
                  }
                });
              });
              if (arrayValue.length > 0) {
                if (
                  arrayValue !== undefined &&
                  arrayValue !== null &&
                  arrayValue !== ""
                ) {
                  console.log(arrayValue);
                  setVideos(arrayValue);
                }
              }
            } else {
              timerRef.current = setTimeout(() => {
                getPreview();
              }, 5000);
            }
          } else if (
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ""
          ) {
            timerRef.current = setTimeout(() => {
              getPreview();
            }, 5000);
          } else {
          }
        }
      }
    } catch(e) {
      console.log(e)
    }
  };

  return (

    <Modal
      isOpen={props.openRenderVideo}
      fade={true}
      className={DisplayMode ? "modal-dialog  modal-dialog-centered custom-modal preview-modal dark" : "modal-dialog  modal-dialog-centered custom-modal preview-modal"}
      centered
    >

      <ModalBody>

        <div
          className="preview-modal-close"
          data-bs-dismiss="modal"
          onClick={() => {
            props.closePreview();
            setstartRender(false);
          }}
        >
          <Tooltip title="Close" arrow>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g data-name="Layer 2">
                <g data-name="close">
                  <rect
                    width="24"
                    height="24"
                    transform="rotate(180 12 12)"
                    opacity="0"
                  />
                  <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                </g>
              </g>
            </svg>
          </Tooltip>
        </div>
        <div>
          <div>
            <>
              {videos != undefined && videos.length > 0 ? (
                <Videoplayer
                  thumbnailUrl=""
                  videoUrl={videos[0].url}
                  showVolume={true}
                />
              ) : (
                  <>
                    <span className="hover-text">Rendering in queue</span>
                    <Videoplayer
                      thumbnailUrl={"../../../assets/img/loader.gif"}
                      videoUrl="../../../assets/img/videobutton.png"
                      showVolume={true}
                      disabled={true}
                    />
                  </>
                )}
            </>
            {renderStatus !== 100 && <LinearProgress style={{ marginTop: "10px" }} />}
          </div>
          
        </div>
      </ModalBody>
    </Modal>
  );
}
