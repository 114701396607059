import React from 'react';

import CarouselItem from './carouselItem';
import CarouselSlider from './carouselSlider';

export default function Carousel(props) {
  const { className, items, onButtonClick, fromRender, carouselVideo, isPlaying, slidesToShow, variableWidth } = props;

  const handleClick = uniqueKey => {
    if (props.handleClick) props.handleClick(uniqueKey);
  };

  const screenText = text => {
    if (props.screenText) {
      return props.screenText(text);
    }
    return '';
  };

  return (
    <CarouselSlider className={className} adaptiveHeight={false} variableWidth={false} slidesToShow={slidesToShow}>
      {items.map(item => (
        <div
          key={item.title}
          onClick={
            fromRender
              ? () => onButtonClick(item?.url, item.title, item.uniqueKey)
              : () => handleClick(item.uniqueKey)
          }
        >
          <CarouselItem
            {...props}
            timer={item.timer}
            hasError={item.hasError}
            uniqueKey={item.uniqueKey}
            carouselTitle={item.title}
            carouselImage={item.image}
            carouselVideo={carouselVideo ? item?.url : ''}
            screenText={screenText(item.screenText)}
            isActive={props.activeItem === item.uniqueKey}
            crashed={item?.crashed}
            isPlaying={isPlaying}
            fromRender={fromRender}
          />
         
        </div>
      ))}
    </CarouselSlider>
  );
}
