import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import CustomToggle from '../../../../components/accordion/CustomToggle';

function AccordionItem(props) {
  const {
    eventKey,
    isActive,
    isHeaderActive,
    onHeaderClick,
    modalTitle,
    totalVideo,
    children
  } = props;
  
  return (
    <Accordion.Item eventKey={eventKey}>
      <div className={`accordion-header ${isHeaderActive ? 'active' : ''}`}>
        <div className="accordion-detail-title-wrap">
          {modalTitle && <h4 className="detail-project-title">{modalTitle}</h4>}
          {totalVideo && <span className="detail-project-sub-title">{totalVideo}</span>}
        </div>
        <CustomToggle
          eventKey={eventKey}
          isActive={isActive}
          onClick={() => onHeaderClick(eventKey)}
          isHeaderActive={isHeaderActive}
        />
      </div>
      <Accordion.Body>
        {children}
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default AccordionItem;
