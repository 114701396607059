export const ACTIONTYPES = {
    UPDATE_ACTIVE_PROJECT: 'UPDATE_ACTIVE_PROJECT',
    UPDATE_ACTIVE_RECORD: 'UPDATE_ACTIVE_RECORD',
    UPDATE_ACTIVE_SCREEN: 'UPDATE_ACTIVE_SCREEN',
    UPDATE_PROJECT_LIST: 'UPDATE_PROJECT_LIST',
    PROJECT_NAME_UPDATE: 'PROJECT_NAME_UPDATE',
    PROJECT_PREVIEW_URL: 'PROJECT_PREVIEW_URL',
    UPDATE_CSV_ERROR: 'UPDATE_CSV_ERROR',
    NEW_PROJECT: 'NEW_PROJECT',
    NEW_BRANDS: 'NEW_BRANDS',
    GET_PROJECT: 'GET_PROJECT',
    PAGE_NO: 'PAGE_NO',
    LOGO: 'LOGO',
    TEMPLATE_STATUS: 'TEMPLATE_STATUS',
    UPDATE_JSON: 'UPDATE_JSON',
    UPDATE_DASHBOARD: 'UPDATE_DASHBOARD',
    UPDATE_SUBMIT_STATUS: 'UPDATE_SUBMIT_STATUS',
    UPDATE_GETFONT_STATUS: 'UPDATE_GETFONT_STATUS',
    UPDATE_NEW_PROJECT_STATUS: 'UPDATE_NEW_PROJECT_STATUS',
    FORM_VALIDATION: 'FORM_VALIDATION',
    SHUTTER_STOCK:'SHUTTER_STOCK',
    EDIT_PROJECT:"EDIT_PROJECT",
    UPDATE_SUBMIT_SCREEN_STATUS:"UPDATE_SUBMIT_SCREEN_STATUS",
    CHECK_CREATEPROJECT:"CHECK_CREATEPROJECT",
    ADD_PROJECT_DETAILS: 'ADD_PROJECT_DETAILS',
    SELECTED_PROJECT:'SELECTED_PROJECT',
    EDIT_STATUS:'EDIT_STATUS',
    PROJECT_NAME_STATUS:'PROJECT_NAME_STATUS'
}
export const createdProject = (data) => { return { type: ACTIONTYPES.NEW_PROJECT, data: data } };
export const createBrands = (data) => { return { type: ACTIONTYPES.NEW_BRANDS, data: data } };
export const updateActiveProject = (data) => { return { type: ACTIONTYPES.UPDATE_ACTIVE_PROJECT, data: data } };
export const updateActiveRecord = (data) => { return { type: ACTIONTYPES.UPDATE_ACTIVE_RECORD, data: data } };
export const updateActiveScreen = (data) => { return { type: ACTIONTYPES.UPDATE_ACTIVE_SCREEN, data: data } };
export const updateProjectName = (data) => { return { type: ACTIONTYPES.PROJECT_NAME_UPDATE, data: data } };
export const updateProjectList = (data) => { return { type: ACTIONTYPES.UPDATE_PROJECT_LIST, data: data } };
export const updatePreviewUrl = (data) => { return { type: ACTIONTYPES.PROJECT_PREVIEW_URL, data: data } };
export const updateCSVUploadError = (data) => { return { type: ACTIONTYPES.UPDATE_CSV_ERROR, data: data } };
export const updateSeletedProject = (data) => { return { type: ACTIONTYPES.GET_PROJECT, data: data } };
export const updatePageNo = (data) => { return { type: ACTIONTYPES.PAGE_NO, data: data } };
export const updateLogo = (data) => { return { type: ACTIONTYPES.LOGO, data: data } };
export const templateStatus = (data) => { return { type: ACTIONTYPES.TEMPLATE_STATUS, data: data } };
export const updateJson = (data) => { return { type: ACTIONTYPES.UPDATE_JSON, data: data } };
export const setDashboard = (data) => { return { type: ACTIONTYPES.UPDATE_DASHBOARD, data: data } };
export const submitStatus = (data) => { return { type: ACTIONTYPES.UPDATE_SUBMIT_STATUS, data: data } };
export const submitScreenStatus = (data) => { return { type: ACTIONTYPES.UPDATE_SUBMIT_SCREEN_STATUS, data: data } };
export const getFontStatus = (data) => { return { type: ACTIONTYPES.UPDATE_GETFONT_STATUS, data: data } };
export const getNewProjectStatus = (data) => { return { type: ACTIONTYPES.UPDATE_NEW_PROJECT_STATUS, data: data } };
export const formValidation = (data) => { return { type: ACTIONTYPES.FORM_VALIDATION, data: data } };
export const shutterStockID = (data) => { return { type: ACTIONTYPES.SHUTTER_STOCK, data: data } };
export const editFullProject = (data) => { return { type: ACTIONTYPES.EDIT_PROJECT, data: data } };
export const checkCreateProject = (data) => { return { type: ACTIONTYPES.CHECK_CREATEPROJECT, data: data } };
export const addProjectDetails = (data) => { return { type: ACTIONTYPES.ADD_PROJECT_DETAILS, data: data } };
export const selectedProject = (data) => { return { type: ACTIONTYPES.SELECTED_PROJECT, data: data } };
export const editStatus = (data) => { return { type: ACTIONTYPES.EDIT_STATUS, data: data } };
export const updateProjectNameStatus = (data) => { return { type: ACTIONTYPES.PROJECT_NAME_STATUS, data: data } };