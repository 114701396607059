import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import ReactImageFallback from 'react-image-fallback';
import { pauseControlIcon, playControlIcon } from '../../constants/icon';

export default function CarouselItem(props) {
  const {
    timer,
    isActive,
    crossBtn,
    uniqueKey,
    className,
    screenText,
    carouselImage,
    carouselTitle,
    onButtonClick,
    carouselVideo,
    hasError = false,
    generateRefreshIcon,
    handleRemove = () => {},
    crashed = false,
    isPlaying = 0,
    fromRender
  } = props;
  let refreshIcon = null;

  if (uniqueKey && generateRefreshIcon) refreshIcon = generateRefreshIcon(uniqueKey);
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    setShowIcon(false);
    if (!timer) return setShowIcon(true);

    const timerId = setTimeout(() => {
      setShowIcon(true);
      timer.setRefreshTimeOut({ [timer.key]: 0 });
    }, timer.timeout);

    return () => {
      clearTimeout(timerId);
    };
  }, [timer?.timeout]);
  return (
    <div
      onClick={onButtonClick}
      className={`${className || ''} carousel-block ${crashed && 'not-allowed'}`}
    >
      <div className="screen-thumbnail-items-wrap">
        <Tooltip title={carouselTitle} arrow>
          <div
            className={`screen-thumbnail-items ${isActive ? 'active' : ''} ${
              hasError ? 'border-danger' : ''
            }`}
          >
            {showIcon && refreshIcon}

            {screenText && <span className="screen-text">{screenText}</span>}
            {crashed && fromRender && (
              <div className={`${crashed && 'not-allowed'}`}>
                <img src="../../assets/img/video-preview.png" alt="crashed video" />{' '}
                <span className="screen-text justify-content-center">crashed</span>
              </div>
            )}
            {crashed ? null : isActive ? (
              <span className="video-play-btn">
                <>{pauseControlIcon}</>
              </span>
            ) : (
              <span className="video-play-btn">
                <>{playControlIcon}</>
              </span>
            )}
            {carouselImage ? (
              <ReactImageFallback
                alt="project_image"
                src={carouselImage}
                initialImage="../../../assets/img/fallbackImage.jpg"
                fallbackImage="../../../assets/img/fallbackImage.jpg"
              />
            ) : (
              carouselVideo && <video className="video-width" src={carouselVideo} />
            )}

            {crossBtn && (
              <span className="close-btn" role="button" onClick={() => handleRemove(uniqueKey)}>
                {crossBtn}
              </span>
            )}

            {props.children}
          </div>
        </Tooltip>
        {carouselTitle && <span title={carouselTitle}>{carouselTitle}</span>}
      </div>
    </div>
  );
}
