import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  projectDeleteOrRestore,
  renderPreview,
  getProjectPreview,
  getProjectsRendered,
  getUsageProjectChildDetails,
  createProjectsPreview,
  deleteRenderProject
} from "../../../services/api.service";
import { getUsersProjectRole, deleteUsersProjectRole, getUsersGlobalRole, getProjectById } from "../../../services/authService";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import PageLoader from "../../../services/pageLoader";
import Tooltip from "@mui/material/Tooltip";
import Header from "../../components/header/header";
import ProgressBar from "../../container/projectlist/ProgressBar";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { debounce } from "debounce";
import SaveChangeModal from "../../../services/SaveChangeModal";
import { showToaster, ShowAlertBox, getUserItem, convertDateToUserTimeZone } from "../../../services/helper";
import { editStatus, selectedProject } from "../../../store/action";
import RenderVideo from "./RenderProject";
import NavigatorOnline from "react-navigator-online";
import CustomModal from "../../../services/CustomModal";
import WelcomeModal from "./WelcomeModal";
import ErrorModal from "./Errormodal";
import { actionCreator } from "../../../store";
import { bindActionCreators } from "redux";
import { customAlphabet } from "nanoid/async";
import { removeMediaUrlFilter } from "../../../services/DataHelper";

export default function RenderProjectList(props) {
  const nanoid = customAlphabet("abcdefghijklmnopqrstuvzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890", 24);
  const dispatch = useDispatch();
  const { EmptyPreview } = bindActionCreators(actionCreator, dispatch);
  const history = useHistory();
  const [projectList, setprojectList] = useState([]);
  const [keyWord, setKeyWord] = useState("");
  const [includeDelete, setIncludeDelete] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [limit, setLimit] = useState(7);
  const [loadMoreFetching, setLoadMoreFetching] = useState(false);
  const [loadMoreEnable, setLoadMoreEnable] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [SearchBoxErrorMsg, setSearchBoxErrorMsg] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [EditProject, setEditProject] = useState(false);
  const [SelectedProject, setSelectedProject] = useState();
  const [renderMessage, setRenderMessage] = useState();
  const [priviewPageLoad, setPriviewPageLoad] = useState(false);
  const [openRenderVideo, setOpenRenderVideo] = useState(false);
  const [getRenderVideo, setGetRenderVideo] = useState(false);
  const [priviewrenderUrl, setPriviewrenderUrl] = useState("");
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [openRenderErrorModal, setOpenRenderErrorModal] = useState(false);
  const [showWelcomeModal, setshowWelcomeModal] = useState(false);
  const [renderId, setRenderId] = useState(undefined);
  const [projectId, setprojectId] = useState(undefined);
  // const [openRenderModal, setopenRenderModal] = useState(false);
  const [deletedProjects, setdeletedProjects] = useState([]);
  const [trashedProject, settrashedProject] = useState(false);
  const [openUpdateModal, setopenUpdateModal] = useState(false);
  const [openRenderModal, setopenRenderModal] = useState(false);
  const [deleteProjectid, setdeleteProjectid] = useState(undefined);
  const [renderProjectId, setrenderProject] = useState(undefined);
  const [confirmmsg, setconfirmmsg] = useState("");
  const [confirmbtnlabel, setconfirmbtnlabel] = useState("");
  const [downloadModal, setDownloadModal] = useState(false);
  const [downloadList, setdownloadList] = useState(undefined);
  let getEnableWelcomeMsg = getUserItem("welcomemessage");
  const [downloadID, setdownloadID] = useState(undefined);
  const [slideCount, setSlideCount] = useState([]);
  const [videos, setVideos] = useState([]);
  const [listCategory, setListCategory] = useState({
    value: "All Category",
    label: "All Category",
    id: 0
  });
  const [category, setCategory] = useState([]);
  const [fonts, setfonts] = useStateWithCallbackLazy();
  const [listFilterStatus, setListFilterStatus] = useState([]);
  const [listStatus, setListStatus] = useState({
    value: "All",
    status: 0
  });
  const [projectRoleProjectId, setProjectRoleProjectId] = useState(undefined);
  const [statusOption, setStatusOption] = useStateWithCallbackLazy();
  const [createProjecteEnable, setCreateProjecteEnable] = useState(false);
  const [adduserEnable, setAdduserEnable] = useState(false);
  const [refreshEnable, setRefreshEnable] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  let userId = getUserItem("id");
  let AdminRole = getUserItem("role");

  useEffect(async () => {
    settrashedProject(false);
    if (AdminRole === 1) {
      await getuserProjectRoles();
      getuserGlobelRoles();
    }
    getAllProjects(1, 0, "", listCategory, listStatus);
    setshowWelcomeModal(true);
  }, []);
  const refreshProject = () => {
    getAllProjects(1, 0, "", listCategory, listStatus);
  };

  const getuserProjectRoles = () => {
    getUsersProjectRole(userId).then((response) => {
      try {
        setPageLoad(true);
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          // setCategoryList(ddd)
          setPageLoad(false);
          let tempArray = [];
          let projectRole = response.data.result;
          projectRole.map((projectrole) => {
            tempArray.push(projectrole);
          });
          setProjectRoleProjectId(tempArray);
        } else {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again1", "warning");
        }
      } catch (error) {
        setPageLoad(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };
  const getuserGlobelRoles = () => {
    getUsersGlobalRole(userId).then((response) => {
      try {
        setPageLoad(true);
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          // setCategoryList(ddd)
          setPageLoad(false);

          // setTimeout(() => {
          //     setprojectList(projectData2)
          // }, 1000);
          setCreateProjecteEnable(response.data.result[0].addProject);
          setAdduserEnable(response.data.result[0].addUser);
        } else {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again1", "warning");
        }
      } catch (error) {
        console.log(error);

        setPageLoad(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };

  const openDetailModal = (ProjectId, projectName, render_status) => {
    let fullDatas = {
      project_name: projectName,
      render_status: render_status
    };
    let filter = {
      project_id: ProjectId
    };
    // setPageLoad(true);
    try {
      getUsageProjectChildDetails(filter).then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            let projectData = response.data;

            // let projectList1 = [...projectList, ...projectData];
            // let projectList = projectData;
            // setprojectList(projectData);
            if (projectData.length > 0) {
              history.push(`/render-project-details`, {
                projectId: ProjectId,
                projectName: projectName,
                type: "dashboard",
                fullData: fullDatas
              });
            } else {
              showToaster("No Render data is available", "warning");
            }

            setPageLoad(false);
          } else {
            showToaster("No response from the server", "warning");
            // setprojectList([]);
            setPageLoad(false);
          }
        } catch (error) {
          showToaster("Something went wrong1. Please Try Again", "warning");
          //   setprojectList([]);
          setPageLoad(false);
        }
      });
    } catch (error) {
      showToaster("Something went wrong2. Please Try Again", "warning");
      //   setprojectList([]);
      setPageLoad(false);
    }
    // setProjectID(projectId)
    // setOpenProjectPreview(true)
    // setfullData(data)
  };

  const downloadPath = (ProjectId, projectName, renderUrls) => {
    history.push(`/render-list/download/`, {
      projectId: ProjectId,
      projectName: projectName,
      renderUrls: renderUrls
    });
    setdownloadID(ProjectId);
  };
  const goToProjectList = () => {
    history.push(`/project-list`);
  };
  const getAllProjects = async (flag, PageNo) => {
    let filter;
    setPageLoad(true);
    try {
      settrashedProject(false);
      // setLoadMoreEnable(false);
      // useDispatch(updatePageNo(pageNo))

      filter = {
        page_no: PageNo, //page_no is working as start in fetching data
        limit: limit,
        project_id: props.location.state.projectId
      };
      filter.projectRole = AdminRole;
      await getProjectsRendered(filter).then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            let projectData = response.data;
            setLoadMoreEnable(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
            if (projectData.length > 0) {
              if (projectData.length < limit) {
                setLoadMoreEnable(false);
              } else {
                setLoadMoreEnable(true);
              }
              if (PageNo === 0) {
                let projectList1 = projectData;
                let sortedList = projectList1.sort((a, b) => (a.Deleted > b.Deleted ? 1 : b.Deleted > a.Deleted ? -1 : 0));
                setprojectList(sortedList);
                setTotalCount(response.total_count);
              } else {
                let projectList1 = [...projectList, ...projectData];
                // let projectList = projectData;
                let sortedList = projectList1.sort((a, b) => (a.Deleted > b.Deleted ? 1 : b.Deleted > a.Deleted ? -1 : 0));
                setprojectList(sortedList);
                setTotalCount(response.total_count);
              }
              setFetching(false);

              //setLoadMoreEnable(true);
            } else {
              if (pageNo === 0) {
                setprojectList([]);
                setTotalCount(0);
                setFetching(false);
                setLoadMoreEnable(false);
              }
            }
            setPageLoad(false);
          } else if (response.message !== undefined && response.message !== null && response.message !== "") {
            showToaster(response.message, "warning");
            setprojectList([]);
            setTotalCount(0);
            setFetching(false);
            setLoadMoreEnable(false);
            setPageLoad(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
          } else {
            showToaster("No response from the server", "warning");
            setprojectList([]);
            setTotalCount(0);
            setFetching(false);
            setLoadMoreEnable(false);
            setPageLoad(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
          }
        } catch (error) {
          showToaster("Something went wrong. Please Try Again", "warning");
          setprojectList([]);
          setTotalCount(0);
          setFetching(false);
          setLoadMoreEnable(false);
          setLoadMoreFetching(false);
          setPageLoad(false);
        }
      });
    } catch (error) {
      showToaster("Something went wrong. Please Try Again", "warning");
      setprojectList([]);
      setTotalCount(0);
      setFetching(false);
      setLoadMoreEnable(false);
      setLoadMoreFetching(false);
      setPageLoad(false);
    }
  };
  const moveToNextPage = () => {
    setFetching(true);
    setLoadMoreFetching(true);

    getAllProjects(1, pageNo + 1, keyWord, listCategory, listStatus);

    setPageNo(pageNo + 1);
  };
  const deleteOrRestoreProject = async (render_id) => {
    let successMsg = "Deleted";
    setPageLoad(true);
    await deleteRenderProject(render_id);
    showToaster(successMsg + " successfully", "success");
    getInitialProjectList("");
  };
  // useEffect(() => {
  //   getInitialProjectList();
  // }, [includeDelete]);
  const changeDeleteOption = (event) => {
    setIncludeDelete(event.target.checked === true ? 1 : 0);
  };

  const __serachbox = (val) => {
    try {
      setSearchBoxErrorMsg(false);
      //setKeyWord(val.target.value);

      if (keyWord !== val.target.value.trim()) {
        setKeyWord(val.target.value);
        // getInitialProjectList(val.target.value.trim());;
        getSearchBoxResult(val.target.value);
      } else {
        if (keyWord.trim() !== "") {
          setKeyWord(val.target.value);
        } else {
          setKeyWord("");
        }
      }
    } catch (error) {
      showToaster("Something went wrong. Please Refresh page and Try Again", "warning");
    }
  };
  const getSearchBoxResult = useCallback(
    debounce((value) => {
      try {
        //setKeyWordNew(keyWord.trim());
        // KeyWordNew = keyWord.trim()
        getInitialProjectList(value.trim());
      } catch (error) {
        showToaster("Something went wrong. Please Refresh page and Try Again", "warning");
      }
    }, 1000),
    [keyWord]
  );

  const __searchByClick = () => {
    setSearchBoxErrorMsg(false);
    if (keyWord.trim() !== "") {
      getInitialProjectList(keyWord.trim());
    } else {
      setSearchBoxErrorMsg(true, () => {
        setTimeout(() => {
          setSearchBoxErrorMsg(false);
        }, 10000);
      });
    }
  };

  const getInitialProjectList = (keyword) => {
    setprojectList([]);
    setFetching(true);
    setPageNo(0);
    setLimit(7);
    setSearchBoxErrorMsg(false);

    getAllProjects(0, 0, keyword, listCategory, listStatus);

    // this.props.updateJson({})
  };

  useEffect(() => {
    dispatch(editStatus(true));
  }, [EditProject]);

  const editProject = (project) => {
    EmptyPreview();
    setEditProject(true);
    setSelectedProject(project);
    dispatch(selectedProject(project));
    let id = project.ProjectID;
    history.push(`/edit-project/${id}`);
  };
  const previewProject = (project) => {
    EmptyPreview();
    setEditProject(true);
    setSelectedProject(project);
    dispatch(selectedProject(project));
    let id = project.ProjectID;
    history.push(`/project-preview/${id}`);
  };
  // useEffect(()=>{
  //   renderProject()
  // },[])
  const showMessage = (status) => {
    if (status) {
      showToaster('"now! you have an internet connection.', "success");
    } else {
      showToaster("now! you have no internet connection", "warning");
    }
  };
  const renderProject = async (project) => {
    let verifyTemplateAvailable = await checkTemplateAvailable(JSON.parse(project.ProjectData), project.ProjectID);
    let renderFilter = { project_id: project.ProjectID };
    if (verifyTemplateAvailable === true) {
      setPriviewPageLoad(true);
      renderPreview(renderFilter).then((response) => {
        if (response.status !== undefined && response.status !== null && response.status === 200) {
          // setTimeout(() => {
          //   getPreview(project.ProjectID,project);
          // }, 5000);
          getPreview(project.ProjectID);
          setPriviewPageLoad(false);
        } else if (response.message !== undefined && response.message !== null && response.message !== "") {
          setRenderMessage(response.message);
        } else {
          ShowAlertBox("Oops...", "Something went wrong. Please try again", "error");
          setPriviewPageLoad(false);
        }
      });
    } else {
      showToaster("There is no template input provided!!", "warning");
    }
  };

  const notDownloadVideos = () => {
    showToaster("Render is not completed!!", "warning");
  };

  const checkTemplateAvailable = async (modules, ProjectID) => {
    // this.projectID = this.props.activeProject.ProjectID
    var templateStatus = true;
    let arrayValue = [];
    if (modules !== undefined && modules !== null && modules[ProjectID] !== undefined) {
      var activeProjectData = modules[ProjectID]["Modules"];
      await Object.entries(activeProjectData).map((modules, moduleIndex) => {
        if (templateStatus === true) {
          let moduleData = modules[1];
          if (moduleData.slides !== undefined && moduleData.slides !== null && moduleData.slides !== "") {
            Object.entries(moduleData.slides).map((slideData, slideIndex) => {
              if (slideData[1].SlideRender === "1") {
                arrayValue.push(slideData[0]);
                setSlideCount(arrayValue);
              }
              if (templateStatus === true) {
                if (typeof slideData[1] === "object") {
                  let screens = slideData[1].Screens;
                  if (screens !== undefined && screens !== null && screens !== "" && typeof screens === "object") {
                    if (templateStatus === true) {
                      Object.entries(screens).map((screen, index) => {
                        let itemsKey = screen[0];
                        Object.entries(screens[itemsKey]).map((item, index) => {
                          if (item[1] !== undefined && item[1].Contenttype !== undefined && item[1].Contenttype === "TemplateID") {
                            if (item[1] !== undefined && item[1].Contentparams !== undefined) {
                              let Contentparams = item[1].Contentparams;
                              if (
                                Contentparams.contentdescription !== undefined &&
                                Contentparams.contentdescription !== null &&
                                Contentparams.contentdescription !== "" &&
                                Contentparams.contentdescription !== "Null"
                              ) {
                              } else {
                                templateStatus = false;
                              }
                            }
                          }
                          return true;
                        });
                        return true;
                      });
                    }
                  }
                }
              }
              return true;
            });
          }
        }
        return true;
      });
    }
    // setSlideCount(arrayValue)
    return templateStatus;
  };
  const getPreview = (ProjectID) => {
    let renderFilter = { project_id: ProjectID };
    // setPriviewPageLoad(true);
    setPriviewrenderUrl("");
    setGetRenderVideo(true);
    getProjectPreview(renderFilter).then((response) => {
      if (
        response.data !== undefined &&
        response.data !== null &&
        response.data !== "" &&
        response.status !== undefined &&
        response.status !== null &&
        response.status === 200
      ) {
        if (
          response.data.mediaurl !== undefined &&
          response.data.mediaurl !== null &&
          response.data.mediaurl !== "" &&
          Object.keys(response.data.mediaurl).length !== 0
        ) {
          if (response.data.renderstatus !== "0%") {
            var data = projectList;
            let index = data.findIndex((obj) => obj.ProjectID == response.data.project_id);
            //var index = data.findIndex((obj) => obj.id === id);
            data[index].RenderStatus = response.data.renderstatus;
            setprojectList(data);
            setPriviewPageLoad(false);
            setOpenRenderVideo(true);
            setGetRenderVideo(false);
            var arrayValue = [];
            let mediaurl = response.data.mediaurl;
            Object.keys(mediaurl).map(function (key, index) {
              Object.keys(mediaurl[key]).map(function (k, i) {
                arrayValue.push(mediaurl[key][k]);
              });
              if (arrayValue.length > 0) {
                if (arrayValue !== undefined && arrayValue !== null && arrayValue !== "") {
                  setPriviewrenderUrl(arrayValue);
                }
              }
            });
          } else {
            setTimeout(() => {
              getPreview(ProjectID);
            }, 5000);
          }
        } else if (response.message !== undefined && response.message !== null && response.message !== "") {
          setTimeout(() => {
            getPreview(ProjectID);
          }, 5000);
        } else {
          if (response.message === "string index out of range") {
            // setPriviewPageLoad(false);
            // this.setState({ templateErrorMsg: 'You have provided Insufficient data to generate Preview. Please add sufficient data'})
          }
          // this.setState({
          //     openRenderModal: false
          // }, () => {
          //     this.setState({ openTemplateError: true })
          // })
        }
      }
    });
  };

  const DownloadVideos = (projectId) => {
    setPageLoad(true);
    let renderFilter = { project_id: projectId };
    getProjectPreview(renderFilter).then((response) => {
      if (
        response.data !== undefined &&
        response.data !== null &&
        response.data !== "" &&
        response.status !== undefined &&
        response.status !== null &&
        response.status === 200
      ) {
        if (
          response.data.mediaurl !== undefined &&
          response.data.mediaurl !== null &&
          response.data.mediaurl !== "" &&
          Object.keys(response.data.mediaurl).length !== 0
        ) {
          if (response.data.renderstatus !== "0%") {
            var arrayValue = [];
            let mediaurl = response.data.mediaurl;
            Object.keys(mediaurl).map(function (key, index) {
              Object.keys(mediaurl[key]).map(function (k, i) {
                arrayValue.push(mediaurl[key][k]);
              });
              if (arrayValue.length > 0) {
                if (arrayValue !== undefined && arrayValue !== null && arrayValue !== "") {
                  setDownloadModal(true);
                  setdownloadList(arrayValue);
                  setPageLoad(false);
                }
              }
            });
          } else {
            setPageLoad(false);
          }
        }
      }
    });
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "gray",
      padding: 7
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      height: "26px"
    }),
    singleValue: () => ({
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "10px",
      lineHeight: "15px"
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff"
    }),
    indicatorSeparator: () => ({
      border: "none"
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px"
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff"
    })
  };

  const handleChangeStatus = (e) => {
    // alert()
    setListStatus(e);
  };
  useEffect(() => {
    getAllProjects(0, 0, keyWord, listCategory, listStatus);
  }, [listStatus]);

  const generateStatus = () => {
    if (listFilterStatus != undefined) {
      let statusOption = [];
      listFilterStatus.map((filter) => {
        statusOption.push({
          value: filter.value,
          label: filter.value,
          status: filter.status
        });
        return true;
      });
      setStatusOption(statusOption);
      // handleChange(fontOption[0])
      // setfonts(fontOption);
    }
  };

  useEffect(() => {
    generateStatus();
    setListStatus({
      value: "All",
      label: "All",
      status: 0
    });
  }, [listFilterStatus]);

  const handleChange = (e) => {
    // alert()
    setListCategory(e);
  };
  useEffect(() => {
    getAllProjects(0, 0, keyWord, listCategory, listStatus);
  }, [listCategory]);

  useEffect(() => {
    generateFonts();
  }, [category]);
  const openRoleTable = () => {
    history.push("/user-roll");
  };
  const generateFonts = () => {
    if (category != undefined) {
      let fontOption = [];
      category.map((font) => {
        fontOption.push({
          value: font.category,
          label: font.category,
          id: font.id
        });
        return true;
      });
      setfonts(fontOption);
      // handleChange(fontOption[0])
      // setfonts(fontOption);
    }
  };
  const RenderData = () => {
    history.push(`/usage-tracking`);
  };
  const CategoryList = () => {
    history.push(`/category-list`);
  };

  const saveRenderSlide = async (projectDetails) => {
    setopenRenderModal();
    let urlArray;
    let slideRenderArray = [];
    try {
      setPageLoad(true);
      // let projectDatafromDb = await getProjectById(projectId)
      // let renderUrls = ""
      let updatedProjectDataforDb = projectDetails.ProjectData;
      let projectId = projectDetails.ProjectID;
      let slideRenderData = JSON.parse(updatedProjectDataforDb);
      if (slideRenderData[projectId] != null) {
        if (slideRenderData[projectId].Modules != undefined) {
          let c = 0;
          Object.keys(slideRenderData[projectId].Modules).map((module) => {
            Object.keys(slideRenderData[projectId].Modules[module].slides).map((slide) => {
              if (slideRenderData[projectId].Modules[module].slides[slide].SlideRender == "1") {
                let slideModuleDta = {
                  slideId: slide,
                  moduleId: module
                };
                slideRenderArray.push(slideModuleDta);
              }
              if (projectDetails.renderUrls !== undefined && projectDetails.renderUrls !== null && projectDetails.renderUrls !== "") {
                // renderUrls = projectDatafromDb.data.data[0].renderUrls;
                urlArray = projectDetails.renderUrls;
                if (urlArray !== null && urlArray !== undefined) {
                  Object.keys(urlArray).map(function (key, index) {
                    Object.keys(urlArray[key]).map(function (k, i) {
                      if (key.startsWith("M") && k.startsWith("SL")) {
                        if (urlArray[key][k].url == "" && urlArray[key][k].SlideRender == "1") {
                          slideRenderData[projectId].Modules[key].slides[k].SlideRender = "1";
                        } else {
                          slideRenderData[projectId].Modules[key].slides[k].SlideRender = "0";
                        }
                        // if (k === slide && key === module) {
                        //   slideRenderData[projectId].Modules[
                        //     module
                        //   ].slides[slide].SlideRender = "0";
                        // } else {
                        //   slideRenderData[projectId].Modules[
                        //     module
                        //   ].slides[slide].SlideRender = "1";
                        // }
                      }
                    });
                  });
                }
              }
            });
          });
        }
        // setSlideRenderData(slideRenderArray);
      }
      let projectDataforAPI = await removeMediaUrlFilter(slideRenderData, projectDetails.ProjectID);
      setprojectId(projectDetails.ProjectID);
      let renderUrls = projectDetails.renderUrls;
      const newProjectId = await nanoid();
      let templateValidArray = [];
      // let projectId = projectDetails.ProjectID;
      let dbdata = slideRenderData[projectId];
      let projectDataReq = {
        dbdata: {
          [projectId]: dbdata
        },
        APIData: {
          [projectId]: projectDataforAPI[projectId]
        },
        RenderUrl: {
          renderUrls: renderUrls
        },
        slideModuleData: {
          slideRenderDatas: slideRenderArray
        },
        RenderData: {
          [newProjectId]: projectDataforAPI[projectId]
        },
        type: "sliderender"
      };

      createProjectsPreview(JSON.stringify(projectDataReq)).then(async (response) => {
        try {
          // setPageLoad(true)
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            if (response.status === 200 && response.status !== null && response.status !== null) {
              setPageLoad(false);
              showToaster("Project Updated successfully", "success");
              // let getProjectData = await getProjetcDetailsById(projectId)
              let projectDatafromDb = await getProjectById(projectId);
              let clusterDetails;
              // if (projectDatafromDb.data.data.length > 0) {
              //     UpdatePreview(JSON.parse(projectDatafromDb.data.data[0].ProjectData))
              // }
              setRenderId(response.data.ProjectID);

              setOpenRenderVideo(true);
              // setopenRenderModal2(true);
            } else {
              setPageLoad(false);

              showToaster(response.message, "warning");
            }
          } else {
            setPageLoad(false);

            showToaster("Something went wrong. Please Try Again", "warning");
          }
        } catch (error) {
          console.log(error);
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      });
    } catch (error) {
      console.log(error);
      setPageLoad(false);
      showToaster("Something went wrong. Please Try Again6", "warning");
    }
  };

  const refreshProjectStats = async (projectDetails, type) => {
    setPageLoad(true);
    let renderFilter = { type: type, render_id: projectDetails.RenderID };
    await getProjectPreview(renderFilter).then((response) => {
      if (
        response.data !== undefined &&
        response.data !== null &&
        response.data !== "" &&
        response.status !== undefined &&
        response.status !== null &&
        response.status === 200
      ) {
        if (response.data.mediaurl.status == "server_error, please try again!") {
          showToaster("Server_error, please try again!", "warning");
        }
        if (response.data.mediaurl.status == "Server error! Please check your quota limits.") {
          showToaster("Server error! Please check your quota limits.", "warning");
        }
        // setRefreshStatus(response.data.renderstatus);
        getAllProjects(0, 0, keyWord, listCategory, listStatus);

        let renderStatusChange = projectList;
        if (response.apiStatus == "false") {
          setRefreshEnable(true);
          var data = projectList;
          let index = data.findIndex((obj) => obj.RenderID == response.data.project_id);

          data[index].refreshStatus = true;
          setprojectList(data);
          if (type == "deatils") {
            setrenderProject(response.data.project_id);
            setRefreshStatus(true);
            setconfirmbtnlabel("OK");
            setconfirmmsg(response.message);
          }

          renderStatusChange.map((project, index) => {
            if (project.RenderID === projectDetails.RenderID) {
              project.RenderStatus = "0%";
            }
          });
          setPageLoad(false);
          return renderStatusChange;
        }
        if (response.apiStatus !== "false") {
          setRefreshEnable(false);
          // setRefreshEnable(true);
          var data = projectList;
          let index = data.findIndex((obj) => obj.RenderID == response.data.project_id);
          data[index].refreshStatus = false;
          renderStatusChange.map((project, index) => {
            if (project.RenderID === projectDetails.RenderID) {
              project.RenderStatus = response.data.renderstatus;
            }
          });
          setPageLoad(false);
          // setprojectList(renderStatusChange)
          return renderStatusChange;
        }
        setprojectList(renderStatusChange);
      }
    });
  };

  const ListProject = () => {
    let data = [];
    if (trashedProject) {
      data = deletedProjects;
    } else {
      data = projectList;
    }
    return data.map((project, index) => {
      let RenderStatus = project.RenderStatus;
      let projectName = project.ProjectName;
      // let Created = project.CreatedOn;
      let sortedCreatedDate;
      let Modified = project.UpdatedOn;
      let sorteModifieddDate = Modified.split(" ")[0];
      let projectProgress = project.RenderStatus;
      if (projectProgress == null) {
        projectProgress = "0%";
      }
      // let sortedCars1 = project.sort((a, b) =>
      if (project.renderDate !== undefined) {
        let Created = project.renderDate;
        sortedCreatedDate = Created.split("T")[0];
      } else {
        let Created = project.CreatedOn;
        sortedCreatedDate = Created.split(" ")[0];
      }
      //   a.sortedCreatedDate.split('-').reverse().join().localeCompare(b.sortedCreatedDate.split('-').reverse().join()));
      let ddd = JSON.parse(project.ProjectData);
      let Modules = ddd[project.ProjectID].Modules;
      if (projectName !== undefined && projectName !== null && projectName.trim() !== "") {
      } else {
        projectName = "No project name provided";
      }
      let renderTime = convertDateToUserTimeZone(project.renderDate || project.CreatedOn);
      let showDownloadButton = false;
      let mediaurl = project.renderUrls;
      if (mediaurl) {
        Object.keys(mediaurl).map(function (key, index) {
          Object.keys(mediaurl[key]).map(function (k, i) {
            if (key.startsWith("M") && k.startsWith("SL")) {
              if (mediaurl[key][k]?.url && mediaurl[key][k]?.SlideRender == "1") {
                showDownloadButton = true;
              }
            }
          });
        });
      }

      // projectRoleProjectId!==undefined&&projectRoleProjectId!==null&&

      return (
        <div className="pb-tbl-wrapper pb-data-tbl render-table render-list-table">
          {project.Deleted === 0 && project.isRendering == false ? (
            <div
              onClick={() =>
                project.renderUrls !== undefined &&
                project.renderUrls !== null &&
                project.renderUrls !== "" &&
                openDetailModal(project.RenderID, project.ProjectName, project.RenderStatus)
              }
              className={
                project.renderUrls !== undefined && project.renderUrls !== null && project.renderUrls !== ""
                  ? "pb-data-label ps-4 cursor-pointer"
                  : "pb-data-label ps-4 "
              }
              title={projectName}

              //
            >
              {projectName}{" "}
              {project?.parentRender?.length && project?.parentRender[0]?.renderName
                ? project?.parentRender[0]?.renderName
                : project?.parentRender?.length && project?.parentRender[0]?.renderDate
                ? convertDateToUserTimeZone(project?.parentRender[0]?.renderDate)
                : ""}
            </div>
          ) : (
            <div className="pb-data-label ps-4 " title={projectName}>
              {projectName}{" "}
              {project?.parentRender?.length && project?.parentRender[0]?.renderName
                ? project?.parentRender[0]?.renderName
                : project?.parentRender?.length && project?.parentRender[0]?.renderDate
                ? convertDateToUserTimeZone(project?.parentRender[0]?.renderDate)
                : ""}
            </div>
          )}
          {/* <div className="pb-data-label ps-4">{projectName}</div> */}

          <div className={project.renderError == 0 ? "pb-data-label text-center" : "pb-data-label text-center"}>
            {project?.renderName ? project.renderName : project?.renderData?.length ? project?.renderData[0].slideRenderName : "--"}
          </div>
          <div className={project.renderError == 0 ? "pb-data-label text-center" : "pb-data-label text-center"}>{project.createdUserName}</div>
          <div className={project.renderError == 0 ? "pb-data-label text-center" : "pb-data-label text-center"}>{project.category_name}</div>
          <div className={project.renderError == 0 ? "pb-data-label text-center" : "pb-data-label text-center"}>
            {project.RenderStatus === "100%" && project.duration !== undefined ? project.duration + " sec" : "--/--"}
          </div>
          <div
            className={project.renderError == 0 ? "pb-data-label ps-3 pe-0 text-center" : "pb-data-label ps-3 pe-0 text-center"}
            title={renderTime}
          >
            {/* {sortedCreatedDate}{" "}
            {project.renderTime !== undefined ? "/" + project.renderTime : ""} */}
            {renderTime}
          </div>
          <div className="pb-data-label pe-0 ps-0">
            <div className="d-flex align-items-center justify-content-between">
              {trashedProject ? (
                <div className="d-flex align-items-center pb-status-indicator">
                  <div className="progress custom-progress pb-progress-bar failed">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "100%" }}
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div className="pb-st-count">
                    <div className="d-flex align-items-center">
                      <div className="pb-failed-icon">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.1875 3.9375L2.8125 3.93751" stroke="#FF4343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M7.3125 7.3125V11.8125" stroke="#FF4343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M10.6875 7.3125V11.8125" stroke="#FF4343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M14.0625 3.9375V14.625C14.0625 14.7742 14.0032 14.9173 13.8977 15.0227C13.7923 15.1282 13.6492 15.1875 13.5 15.1875H4.5C4.35082 15.1875 4.20774 15.1282 4.10225 15.0227C3.99676 14.9173 3.9375 14.7742 3.9375 14.625V3.9375"
                            stroke="#FF4343"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.8125 3.9375V2.8125C11.8125 2.51413 11.694 2.22798 11.483 2.017C11.272 1.80603 10.9859 1.6875 10.6875 1.6875H7.3125C7.01413 1.6875 6.72798 1.80603 6.51701 2.017C6.30603 2.22798 6.1875 2.51413 6.1875 2.8125V3.9375"
                            stroke="#FF4343"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="failed-status">Trashed</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center pb-status-indicator">
                  {/* <div className="progress custom-progress pb-progress-bar"> */}
                  <ProgressBar completed={projectProgress.substring(0, projectProgress.length - 1)} />
                  {/* <div className="progress-bar" role="progressbar" style={{ width: "57%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div> */}
                  {/* </div> */}
                  {project.renderError !== 0 && project.renderError !== 2 && (project.isRendering == true || projectProgress !== "0%") ? (
                    <>
                      <div className="progress custom-progress pb-progress-bar">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${projectProgress}` }}
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>

                      <div className={project.renderError == 0 ? "pb-st-count me-2" : "pb-st-count me-2"}>{projectProgress}</div>
                    </>
                  ) : (
                    ""
                  )}

                  {project.renderError == 0 ? (
                    <>
                      <div className="progress custom-progress pb-progress-bar">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${projectProgress}` }}
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>

                      <div className={project.renderError == 0 ? "pb-st-count me-2" : "pb-st-count me-2"}>{projectProgress}</div>
                      <div className="failed-status">Crashed</div>
                    </>
                  ) : (
                    ""
                  )}
                  {project.renderError == 2 ? (
                    <>
                      <div className="progress custom-progress pb-progress-bar">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${projectProgress}` }}
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>

                      <div className={project.renderError == 2 ? "pb-st-count me-2" : "pb-st-count me-2"}>{projectProgress}</div>
                      <div className="failed-status">Aborted</div>
                    </>
                  ) : (
                    ""
                  )}
                  {project.renderError == 3 ? (
                    <>
                      <div className="progress custom-progress pb-progress-bar">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${projectProgress}` }}
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>

                      <div className={project.renderError == 2 ? "pb-st-count me-2" : "pb-st-count me-2"}>{projectProgress}</div>
                      <div className="failed-status">Timeout</div>
                    </>
                  ) : (
                    ""
                  )}
                  {/* {refreshEnable&&
                    <div className="pb-st-count">{projectProgress}</div>
                    } */}

                  {/* <button onClick={() => refreshProjectStats(project)}>
                    refresh
                  </button> */}
                </div>
              )}
              <div
                className="d-flex justify-content-end pb-status-set"
                // title="Restore"
              >
                {Modules !== undefined &&
                  Modules !== "" &&
                  Modules !== null &&
                  !trashedProject &&
                  projectProgress !== "100%" &&
                  project.isRendering &&
                  project.renderError == 1 && (
                    <Tooltip title="Refresh" arrow>
                      <div className="pb-stat-icon" onClick={() => refreshProjectStats(project, "refresh")}>
                        <svg
                          className="me-2"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="18"
                          height="21"
                          viewBox="0 0 592.99 592.99"
                          style={{
                            enableBackground: "new 0 0 592.99 592.99"
                          }}
                          space="preserve"
                        >
                          <g>
                            <g>
                              <path
                                d="M274.292,21.879C122.868,21.879,0,145.072,0,296.496C0,447.92,122.262,571.111,275.262,571.111v-91.799
                      c-100.98,0-183.462-82.012-183.462-182.816c0-100.806,81.362-182.817,182.168-182.817c98.753,0,179.413,78.718,182.661,176.696
                      h-45.236l90.799,127.541l90.799-127.541h-44.486C545.248,141.767,423.67,21.879,274.292,21.879z"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </Tooltip>
                  )}
                {trashedProject === true ? (
                  <Tooltip title="Restore" arrow>
                    <div
                      className="pb-stat-icon"
                      onClick={() => {
                        let message = "You want to delete " + project.ProjectName + " ?";
                        let confirmBtn = "Delete";
                        if (project.Deleted === 1) {
                          message = "You want to restore " + project.ProjectName + " ?";
                          confirmBtn = "Restore";
                        }
                        setconfirmmsg(message);
                        setconfirmbtnlabel(confirmBtn);
                        setopenUpdateModal(true);
                        setdeleteProjectid(project);
                        //deleteOrRestoreProject(project)
                      }}

                      // onClick={() => deleteOrRestoreProject(project)}
                    >
                      <svg
                        className="me-2"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width=""
                        height=""
                        viewBox="0 0 592.99 592.99"
                        style={{
                          enableBackground: "new 0 0 592.99 592.99"
                        }}
                        space="preserve"
                      >
                        <g>
                          <g>
                            <path
                              d="M274.292,21.879C122.868,21.879,0,145.072,0,296.496C0,447.92,122.262,571.111,275.262,571.111v-91.799
                      c-100.98,0-183.462-82.012-183.462-182.816c0-100.806,81.362-182.817,182.168-182.817c98.753,0,179.413,78.718,182.661,176.696
                      h-45.236l90.799,127.541l90.799-127.541h-44.486C545.248,141.767,423.67,21.879,274.292,21.879z"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </Tooltip>
                ) : getRenderVideo === true ? (
                  // <label className="pro-btn-txt">
                  //   {renderProjectId.ProjectID === project.ProjectID ? (
                  //     <CircularProgress size="1rem" color="inherit" />
                  //   ) : (
                  //     <Tooltip title="Render Project" arrow>
                  //       <div
                  //         className="pb-stat-icon"
                  //         // title="Render Project"
                  //         onClick={() => {
                  //           showToaster(
                  //             "One render process is on progress",
                  //             "warning"
                  //           );
                  //           // if (RenderStatus == "100%") {
                  //           //   setrenderProject(project);
                  //           //   renderProject(project);
                  //           // } else {
                  //           //   let message =
                  //           //     "You want to render  " +
                  //           //     project.ProjectName +
                  //           //     " ?";
                  //           //   let confirmBtn = "Yes";
                  //           //   setconfirmmsg(message);
                  //           //   setconfirmbtnlabel(confirmBtn);
                  //           //   setopenRenderModal(true);
                  //           //   setrenderProject(project);
                  //           // }
                  //         }}

                  //         // onClick={() => renderProject(project)}
                  //       >
                  //         <svg
                  //           width="11"
                  //           height="14"
                  //           viewBox="0 0 11 14"
                  //           fill="none"
                  //           xmlns="http://www.w3.org/2000/svg"
                  //         >
                  //           <path d="M0 0V14L11 7L0 0Z" />
                  //         </svg>
                  //       </div>
                  //     </Tooltip>
                  //   )}
                  // </label>
                  <></>
                ) : (
                  // <Tooltip title="Render Project" arrow>
                  //   <div
                  //     className="pb-stat-icon"
                  //     // title="Render Project"
                  //     onClick={() => {
                  //       if (RenderStatus == "100%") {
                  //         //setopenRenderModal(true);
                  //         setrenderProject(project);
                  //         renderProject(project);
                  //       } else {
                  //         let message =
                  //           "you want to render  " + project.ProjectName + " ?";
                  //         let confirmBtn = "Yes";
                  //         setconfirmmsg(message);
                  //         setconfirmbtnlabel(confirmBtn);
                  //         setopenRenderModal(true);
                  //         setrenderProject(project);
                  //       }
                  //     }}

                  //     // onClick={() => renderProject(project)}
                  //   >
                  //     <svg
                  //       width="11"
                  //       height="14"
                  //       viewBox="0 0 11 14"
                  //       fill="none"
                  //       xmlns="http://www.w3.org/2000/svg"
                  //     >
                  //       <path d="M0 0V14L11 7L0 0Z" />
                  //     </svg>
                  //   </div>
                  // </Tooltip>
                  <></>
                )}
                {trashedProject === true ? (
                  <Tooltip title="Delete Permanently" arrow>
                    <div
                      className="pb-stat-icon download"
                      // title="Permanent delete"
                      onClick={() => {
                        let message = "You want to permanently delete " + project.ProjectName + " ?";
                        let confirmBtn = "Delete";

                        setconfirmmsg(message);
                        setconfirmbtnlabel(confirmBtn);
                        setopenDeleteModal(true);
                        setdeleteProjectid(project);
                        setKeyWord("");
                        //deleteOrRestoreProject(project)
                      }}
                    >
                      <svg
                        className="me-1"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 512 512"
                        space="preserve"
                      >
                        <g>
                          <polygon points="190.551,495.523 117.226,495.523 87.886,113.866 190.551,113.866 	" />
                          <polygon points="394.773,495.523 321.448,495.523 321.448,113.866 424.112,113.866 	" />
                        </g>
                        <g>
                          <path
                            d="M468.321,97.389h-44.208H321.446H190.551H87.888h-44.21c-9.1,0-16.477,7.378-16.477,16.477
                          s7.377,16.477,16.477,16.477h28.95l28.168,366.444c0.661,8.585,7.818,15.213,16.429,15.213h73.325h51.333
                          c9.1,0,16.477-7.378,16.477-16.477s-7.377-16.477-16.477-16.477H207.03V130.343h97.941v365.18c0,9.099,7.378,16.477,16.477,16.477
                          h73.327c8.611,0,15.769-6.629,16.429-15.213l28.169-366.444h28.949c9.099,0,16.477-7.378,16.477-16.477
                          S477.419,97.389,468.321,97.389z M174.074,479.046h-41.589L105.68,130.343h68.394V479.046L174.074,479.046z M379.513,479.046
                          h-41.59V130.343h68.397L379.513,479.046z"
                          />
                          <path
                            d="M332.693,75.578c-9.099,0-16.477-7.379-16.477-16.477V32.954H201.899V59.1
                          c0,9.099-7.377,16.477-16.477,16.477s-16.477-7.379-16.477-16.477V16.477C168.944,7.378,176.321,0,185.421,0h147.272
                          c9.099,0,16.477,7.378,16.477,16.477V59.1C349.17,68.201,341.794,75.578,332.693,75.578z"
                          />
                        </g>
                      </svg>
                    </div>
                  </Tooltip>
                ) : (
                  <>
                    {priviewrenderUrl.length > 0 || showDownloadButton ? (
                      <div
                        className="pb-stat-icon download"
                        onClick={() => {
                          //setDownloadModal(true);
                          downloadPath(project.ProjectID, project.ProjectName, project.renderUrls);
                        }}
                      >
                        <Tooltip title="Download" arrow>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 4V16" stroke="black" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6 11L12 16L18 11" stroke="black" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5 19H19" stroke="black" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </Tooltip>
                      </div>
                    ) : (
                      <div
                        className="pb-stat-icon download"
                        // onClick={() => {
                        //setDownloadModal(true);
                        //   notDownloadVideos();
                        // }}
                      >
                        {/* <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 4V16"
                            stroke="black"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 11L12 16L18 11"
                            stroke="black"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5 19H19"
                            stroke="black"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg> */}
                      </div>
                    )}
                  </>
                )}
                {!trashedProject && (
                  <div className="pb-stat-icon dropdown">
                    <a className="p-2 pointer" type="" id="" data-bs-toggle="dropdown" aria-expanded="false">
                      <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle r="1.5" transform="matrix(-1 0 0 1 1.5 1.5)" />
                        <circle r="1.5" transform="matrix(-1 0 0 1 1.5 7.5)" />
                        <circle r="1.5" transform="matrix(-1 0 0 1 1.5 13.5)" />
                      </svg>
                    </a>

                    <div className="dropdown-menu " aria-labelledby="dropdownMenuButton1">
                      {AdminRole === 1 ? (
                        projectRoleProjectId !== undefined &&
                        projectRoleProjectId.map((projectRole) => {
                          return (
                            <ul>
                              {Modules !== undefined && Modules !== "" && Modules !== null
                                ? projectRole.projectId === project.ProjectID &&
                                  projectRole.viewProject == true && (
                                    <li onClick={() => previewProject(project)}>
                                      <a className="dropdown-item">
                                        <svg
                                          className="me-2"
                                          version="1.1"
                                          id="Capa_1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xlink="http://www.w3.org/1999/xlink"
                                          x="0px"
                                          y="0px"
                                          viewBox="0 0 488.85 488.85"
                                          style={{
                                            EnableBackground: "new 0 0 488.85 488.85"
                                          }}
                                          space="preserve"
                                        >
                                          <g>
                                            <path
                                              d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2
                                  s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025
                                  c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3
                                  C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7
                                  c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"
                                            />
                                          </g>
                                        </svg>
                                        <span>Preview</span>
                                      </a>
                                    </li>
                                  )
                                : projectRole.projectId === project.ProjectID &&
                                  projectRole.viewProject == true && (
                                    <li className="disabled">
                                      <Tooltip title="No preview data" arrow>
                                        <a className="dropdown-item">
                                          <svg
                                            className="me-2"
                                            version="1.1"
                                            id="Capa_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 488.85 488.85"
                                            style={{
                                              EnableBackground: "new 0 0 488.85 488.85"
                                            }}
                                            space="preserve"
                                          >
                                            <g>
                                              <path
                                                d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2
                             s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025
                             c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3
                             C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7
                             c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"
                                              />
                                            </g>
                                          </svg>
                                          <span>Preview</span>
                                        </a>
                                      </Tooltip>
                                    </li>
                                  )}
                              {projectRole.projectId === project.ProjectID && projectRole.editProject == true && (
                                <li onClick={() => editProject(project)}>
                                  <a className="dropdown-item">
                                    <svg
                                      className="me-2"
                                      version="1.1"
                                      id="Capa_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xlink="http://www.w3.org/1999/xlink"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 59.985 59.985"
                                      style={{
                                        enableBackground: "new 0 0 59.985 59.985"
                                      }}
                                      space="preserve"
                                    >
                                      <g>
                                        <path d="M5.243,44.844L42.378,7.708l9.899,9.899L15.141,54.742L5.243,44.844z" />
                                        <path
                                          d="M56.521,13.364l1.414-1.414c1.322-1.322,2.05-3.079,2.05-4.949s-0.728-3.627-2.05-4.949S54.855,0,52.985,0
                        s-3.627,0.729-4.95,2.051l-1.414,1.414L56.521,13.364z"
                                        />
                                        <path
                                          d="M4.099,46.527L0.051,58.669c-0.12,0.359-0.026,0.756,0.242,1.023c0.19,0.19,0.446,0.293,0.707,0.293
                        c0.106,0,0.212-0.017,0.316-0.052l12.141-4.047L4.099,46.527z"
                                        />
                                        <path d="M43.793,6.294l1.415-1.415l9.899,9.899l-1.415,1.415L43.793,6.294z" />
                                      </g>
                                    </svg>
                                    <span>Edit</span>
                                  </a>
                                </li>
                              )}

                              {project.renderError == 0 && projectRole.projectId === project.ProjectID && projectRole.editProject == true && (
                                <li onClick={() => saveRenderSlide(project)}>
                                  <Tooltip title="Render" arrow>
                                    <a className="dropdown-item ">
                                      <svg
                                        className="me-2"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xlink="http://www.w3.org/1999/xlink"
                                        // width="48px"
                                        // height="48px"
                                        viewBox="0 0 48 48"
                                        enable-background="new 0 0 48 48"
                                        id="Layer_3"
                                        version="1.1"
                                      >
                                        <g>
                                          <path d="M26.22,13.234c0.572,0,1.097-0.202,1.57-0.606c0.469-0.404,0.757-0.894,0.86-1.466   c0.098-0.576-0.024-1.062-0.38-1.466c-0.354-0.408-0.818-0.61-1.392-0.61c-0.576,0-1.1,0.202-1.569,0.61   c-0.474,0.403-0.763,0.89-0.86,1.466c-0.071,0.573,0.066,1.062,0.402,1.466C25.19,13.032,25.648,13.234,26.22,13.234z" />
                                          <path d="M30.622,27.4c-0.053,0.075-0.104,0.14-0.156,0.216c-0.312,0.396-1.475,1.795-2.984,2.632   c-0.01,0.004-0.019,0.008-0.024,0.014c-0.132,0.07-0.267,0.123-0.401,0.185c-0.398,0.143-0.771,0.179-0.891,0.188   c-0.339-0.044-0.522-0.281-0.522-0.73c0-0.371,0.108-1.104,0.328-2.205l0.254-1.111l1.035-4.968   c0.168-0.846,0.258-1.316,0.278-1.409l0.226-1.162c0.133-0.719,0.201-1.194,0.201-1.426c0-0.444-0.11-0.781-0.287-1.055   c-0.016-0.04-0.037-0.08-0.066-0.122c-0.026-0.032-0.054-0.06-0.082-0.089c-0.031-0.034-0.046-0.063-0.088-0.098   c-0.441-0.36-0.853-0.464-1.137-0.487l0.005-0.008c0,0-1.867-0.141-4.634,1.682c-0.039,0.026-0.063,0.045-0.1,0.07   c-0.75,0.474-1.348,0.949-1.764,1.332c-0.318,0.272-0.562,0.525-0.744,0.762l-0.009,0.011l0.001-0.001   c-0.235,0.311-0.359,0.593-0.359,0.843c0,0.197,0.159,0.394,0.476,0.591c0,0,1.188-1.534,3.185-2.532   c0.15-0.065,0.534-0.224,0.871-0.312c0.158-0.036,0.455-0.064,0.646,0.093c0.136,0.142,0.226,0.339,0.226,0.646   c0,0.279-0.041,0.618-0.127,1.023l-0.201,0.957l-0.252,1.196l-0.986,4.728c-0.504,2.442-0.757,3.903-0.757,4.379   c0,1.123,0.615,1.685,1.843,1.685c0.557,0,1.13-0.101,1.717-0.281c0.004,0,0.006,0.002,0.01,0.004   c0.04-0.014,0.076-0.029,0.115-0.043c0.098-0.031,0.195-0.068,0.293-0.105c2.771-1.031,4.595-3.108,5.146-3.804   c0.049-0.059,0.091-0.114,0.13-0.167c0.031-0.043,0.051-0.072,0.051-0.072h-0.002c0.14-0.206,0.217-0.377,0.217-0.505   C31.299,27.766,31.068,27.587,30.622,27.4z" />
                                          <path d="M18.732,31.873l1.435-1.436l-2.604-2.606l-1.435,1.438c-0.836-0.554-1.768-0.967-2.764-1.226v-2.147H8.74   v2.147c-1.019,0.264-1.973,0.688-2.822,1.26l-1.378-1.378l-2.605,2.603l1.401,1.402c-0.536,0.82-0.938,1.734-1.19,2.708H0v4.62   h2.146c0.258,0.997,0.671,1.932,1.225,2.768l-1.436,1.437l2.605,2.603l1.437-1.436c0.837,0.555,1.773,0.97,2.773,1.228V48h4.605   v-2.143c0.976-0.252,1.892-0.656,2.715-1.192l1.493,1.493l2.604-2.604l-1.47-1.472c0.574-0.854,1-1.814,1.263-2.838h2.143v-4.591   H19.96C19.703,33.652,19.289,32.713,18.732,31.873z M11.051,40.737c-2.092,0-3.788-1.696-3.788-3.788s1.696-3.789,3.788-3.789   s3.789,1.697,3.789,3.789S13.143,40.737,11.051,40.737z" />
                                          <path d="M24,0.125C10.814,0.125,0.125,10.814,0.125,24c0,1.757,0.195,3.468,0.555,5.116   c0.968-1.265,2.16-2.344,3.521-3.182C4.14,25.298,4.104,24.653,4.104,24C4.104,13.012,13.012,4.104,24,4.104   S43.896,13.012,43.896,24S34.988,43.896,24,43.896c-0.653,0-1.297-0.036-1.935-0.098c-0.838,1.361-1.917,2.554-3.182,3.521   c1.649,0.36,3.359,0.556,5.116,0.556c13.186,0,23.875-10.689,23.875-23.875S37.186,0.125,24,0.125z" />
                                        </g>
                                      </svg>
                                      <span className=" mb-2">Render</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              )}
                              {projectRole.projectId === project.ProjectID && project.createdBy == userId && (
                                <React.Fragment>
                                  <li
                                    onClick={() => {
                                      let message = project.renderName
                                        ? "You want to delete " + project.ProjectName + " " + project.renderName + " ?"
                                        : "You want to delete " + project.ProjectName + " -- ?";
                                      let confirmBtn = "Delete";
                                      setconfirmmsg(message);
                                      setconfirmbtnlabel(confirmBtn);
                                      setopenUpdateModal(true);
                                      setdeleteProjectid(project._id);
                                    }}
                                  >
                                    <a className="dropdown-item ">
                                      <svg
                                        className="me-2"
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 512 512"
                                        space="preserve"
                                      >
                                        <g>
                                          <polygon points="190.551,495.523 117.226,495.523 87.886,113.866 190.551,113.866 	" />
                                          <polygon points="394.773,495.523 321.448,495.523 321.448,113.866 424.112,113.866 	" />
                                        </g>
                                        <g>
                                          <path
                                            d="M468.321,97.389h-44.208H321.446H190.551H87.888h-44.21c-9.1,0-16.477,7.378-16.477,16.477
                          s7.377,16.477,16.477,16.477h28.95l28.168,366.444c0.661,8.585,7.818,15.213,16.429,15.213h73.325h51.333
                          c9.1,0,16.477-7.378,16.477-16.477s-7.377-16.477-16.477-16.477H207.03V130.343h97.941v365.18c0,9.099,7.378,16.477,16.477,16.477
                          h73.327c8.611,0,15.769-6.629,16.429-15.213l28.169-366.444h28.949c9.099,0,16.477-7.378,16.477-16.477
                          S477.419,97.389,468.321,97.389z M174.074,479.046h-41.589L105.68,130.343h68.394V479.046L174.074,479.046z M379.513,479.046
                          h-41.59V130.343h68.397L379.513,479.046z"
                                          />
                                          <path
                                            d="M332.693,75.578c-9.099,0-16.477-7.379-16.477-16.477V32.954H201.899V59.1
                          c0,9.099-7.377,16.477-16.477,16.477s-16.477-7.379-16.477-16.477V16.477C168.944,7.378,176.321,0,185.421,0h147.272
                          c9.099,0,16.477,7.378,16.477,16.477V59.1C349.17,68.201,341.794,75.578,332.693,75.578z"
                                          />
                                        </g>
                                      </svg>
                                      <span className=" mb-2">Delete</span>
                                    </a>
                                  </li>
                                </React.Fragment>
                              )}
                            </ul>
                          );
                        })
                      ) : (
                        <ul>
                          {Modules !== undefined && Modules !== "" && Modules !== null && (
                            <li onClick={() => previewProject(project)}>
                              <a className="dropdown-item">
                                <svg
                                  className="me-2"
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 488.85 488.85"
                                  style={{
                                    EnableBackground: "new 0 0 488.85 488.85"
                                  }}
                                  space="preserve"
                                >
                                  <g>
                                    <path
                                      d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2
                             s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025
                             c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3
                             C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7
                             c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"
                                    />
                                  </g>
                                </svg>
                                <span>Preview</span>
                              </a>
                            </li>
                          )}

                          <li onClick={() => editProject(project)}>
                            <a className="dropdown-item">
                              <svg
                                className="me-2"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 59.985 59.985"
                                style={{
                                  enableBackground: "new 0 0 59.985 59.985"
                                }}
                                space="preserve"
                              >
                                <g>
                                  <path d="M5.243,44.844L42.378,7.708l9.899,9.899L15.141,54.742L5.243,44.844z" />
                                  <path
                                    d="M56.521,13.364l1.414-1.414c1.322-1.322,2.05-3.079,2.05-4.949s-0.728-3.627-2.05-4.949S54.855,0,52.985,0
                   s-3.627,0.729-4.95,2.051l-1.414,1.414L56.521,13.364z"
                                  />
                                  <path
                                    d="M4.099,46.527L0.051,58.669c-0.12,0.359-0.026,0.756,0.242,1.023c0.19,0.19,0.446,0.293,0.707,0.293
                   c0.106,0,0.212-0.017,0.316-0.052l12.141-4.047L4.099,46.527z"
                                  />
                                  <path d="M43.793,6.294l1.415-1.415l9.899,9.899l-1.415,1.415L43.793,6.294z" />
                                </g>
                              </svg>
                              <span>Edit</span>
                            </a>
                          </li>
                          {project.renderError == 0 && (
                            <li onClick={() => saveRenderSlide(project)}>
                              <Tooltip title="Render" arrow>
                                <a className="dropdown-item ">
                                  <svg
                                    className="me-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xlink="http://www.w3.org/1999/xlink"
                                    // width="48px"
                                    // height="48px"
                                    viewBox="0 0 48 48"
                                    enable-background="new 0 0 48 48"
                                    id="Layer_3"
                                    version="1.1"
                                  >
                                    <g>
                                      <path d="M26.22,13.234c0.572,0,1.097-0.202,1.57-0.606c0.469-0.404,0.757-0.894,0.86-1.466   c0.098-0.576-0.024-1.062-0.38-1.466c-0.354-0.408-0.818-0.61-1.392-0.61c-0.576,0-1.1,0.202-1.569,0.61   c-0.474,0.403-0.763,0.89-0.86,1.466c-0.071,0.573,0.066,1.062,0.402,1.466C25.19,13.032,25.648,13.234,26.22,13.234z" />
                                      <path d="M30.622,27.4c-0.053,0.075-0.104,0.14-0.156,0.216c-0.312,0.396-1.475,1.795-2.984,2.632   c-0.01,0.004-0.019,0.008-0.024,0.014c-0.132,0.07-0.267,0.123-0.401,0.185c-0.398,0.143-0.771,0.179-0.891,0.188   c-0.339-0.044-0.522-0.281-0.522-0.73c0-0.371,0.108-1.104,0.328-2.205l0.254-1.111l1.035-4.968   c0.168-0.846,0.258-1.316,0.278-1.409l0.226-1.162c0.133-0.719,0.201-1.194,0.201-1.426c0-0.444-0.11-0.781-0.287-1.055   c-0.016-0.04-0.037-0.08-0.066-0.122c-0.026-0.032-0.054-0.06-0.082-0.089c-0.031-0.034-0.046-0.063-0.088-0.098   c-0.441-0.36-0.853-0.464-1.137-0.487l0.005-0.008c0,0-1.867-0.141-4.634,1.682c-0.039,0.026-0.063,0.045-0.1,0.07   c-0.75,0.474-1.348,0.949-1.764,1.332c-0.318,0.272-0.562,0.525-0.744,0.762l-0.009,0.011l0.001-0.001   c-0.235,0.311-0.359,0.593-0.359,0.843c0,0.197,0.159,0.394,0.476,0.591c0,0,1.188-1.534,3.185-2.532   c0.15-0.065,0.534-0.224,0.871-0.312c0.158-0.036,0.455-0.064,0.646,0.093c0.136,0.142,0.226,0.339,0.226,0.646   c0,0.279-0.041,0.618-0.127,1.023l-0.201,0.957l-0.252,1.196l-0.986,4.728c-0.504,2.442-0.757,3.903-0.757,4.379   c0,1.123,0.615,1.685,1.843,1.685c0.557,0,1.13-0.101,1.717-0.281c0.004,0,0.006,0.002,0.01,0.004   c0.04-0.014,0.076-0.029,0.115-0.043c0.098-0.031,0.195-0.068,0.293-0.105c2.771-1.031,4.595-3.108,5.146-3.804   c0.049-0.059,0.091-0.114,0.13-0.167c0.031-0.043,0.051-0.072,0.051-0.072h-0.002c0.14-0.206,0.217-0.377,0.217-0.505   C31.299,27.766,31.068,27.587,30.622,27.4z" />
                                      <path d="M18.732,31.873l1.435-1.436l-2.604-2.606l-1.435,1.438c-0.836-0.554-1.768-0.967-2.764-1.226v-2.147H8.74   v2.147c-1.019,0.264-1.973,0.688-2.822,1.26l-1.378-1.378l-2.605,2.603l1.401,1.402c-0.536,0.82-0.938,1.734-1.19,2.708H0v4.62   h2.146c0.258,0.997,0.671,1.932,1.225,2.768l-1.436,1.437l2.605,2.603l1.437-1.436c0.837,0.555,1.773,0.97,2.773,1.228V48h4.605   v-2.143c0.976-0.252,1.892-0.656,2.715-1.192l1.493,1.493l2.604-2.604l-1.47-1.472c0.574-0.854,1-1.814,1.263-2.838h2.143v-4.591   H19.96C19.703,33.652,19.289,32.713,18.732,31.873z M11.051,40.737c-2.092,0-3.788-1.696-3.788-3.788s1.696-3.789,3.788-3.789   s3.789,1.697,3.789,3.789S13.143,40.737,11.051,40.737z" />
                                      <path d="M24,0.125C10.814,0.125,0.125,10.814,0.125,24c0,1.757,0.195,3.468,0.555,5.116   c0.968-1.265,2.16-2.344,3.521-3.182C4.14,25.298,4.104,24.653,4.104,24C4.104,13.012,13.012,4.104,24,4.104   S43.896,13.012,43.896,24S34.988,43.896,24,43.896c-0.653,0-1.297-0.036-1.935-0.098c-0.838,1.361-1.917,2.554-3.182,3.521   c1.649,0.36,3.359,0.556,5.116,0.556c13.186,0,23.875-10.689,23.875-23.875S37.186,0.125,24,0.125z" />
                                    </g>
                                  </svg>
                                  <span className=" mb-2">Render</span>
                                </a>
                              </Tooltip>
                            </li>
                          )}
                          <React.Fragment>
                            <li
                              onClick={() => {
                                let message = project.renderName
                                  ? "You want to delete " + project.ProjectName + " " + project.renderName + " ?"
                                  : "You want to delete " + project.ProjectName + " -- ?";
                                let confirmBtn = "Delete";
                                setconfirmmsg(message);
                                setconfirmbtnlabel(confirmBtn);
                                setopenUpdateModal(true);
                                setdeleteProjectid(project._id);
                              }}
                            >
                              <a className="dropdown-item ">
                                <svg
                                  className="me-2"
                                  version="1.1"
                                  id="Layer_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  space="preserve"
                                >
                                  <g>
                                    <polygon points="190.551,495.523 117.226,495.523 87.886,113.866 190.551,113.866 	" />
                                    <polygon points="394.773,495.523 321.448,495.523 321.448,113.866 424.112,113.866 	" />
                                  </g>
                                  <g>
                                    <path
                                      d="M468.321,97.389h-44.208H321.446H190.551H87.888h-44.21c-9.1,0-16.477,7.378-16.477,16.477
                          s7.377,16.477,16.477,16.477h28.95l28.168,366.444c0.661,8.585,7.818,15.213,16.429,15.213h73.325h51.333
                          c9.1,0,16.477-7.378,16.477-16.477s-7.377-16.477-16.477-16.477H207.03V130.343h97.941v365.18c0,9.099,7.378,16.477,16.477,16.477
                          h73.327c8.611,0,15.769-6.629,16.429-15.213l28.169-366.444h28.949c9.099,0,16.477-7.378,16.477-16.477
                          S477.419,97.389,468.321,97.389z M174.074,479.046h-41.589L105.68,130.343h68.394V479.046L174.074,479.046z M379.513,479.046
                          h-41.59V130.343h68.397L379.513,479.046z"
                                    />
                                    <path
                                      d="M332.693,75.578c-9.099,0-16.477-7.379-16.477-16.477V32.954H201.899V59.1
                          c0,9.099-7.377,16.477-16.477,16.477s-16.477-7.379-16.477-16.477V16.477C168.944,7.378,176.321,0,185.421,0h147.272
                          c9.099,0,16.477,7.378,16.477,16.477V59.1C349.17,68.201,341.794,75.578,332.693,75.578z"
                                    />
                                  </g>
                                </svg>
                                <span className=" mb-2">Delete</span>
                              </a>
                            </li>
                          </React.Fragment>
                        </ul>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <PageLoader showLoader={pageLoad} />
      <PageLoader showLoader={priviewPageLoad} />
      <div className={DisplayMode ? "theme dark" : "theme"}>
        <div className="main-wrapper">
          <Header />
          <div className="pb-wrapper">
            <div className="row pb-header mb-4">
              <div className="col-md-7 col-lg-8 col-xl-7">
                <div className={trashedProject ? "d-flex flex-wrap justify-content-end" : "d-flex flex-wrap"}>
                  {trashedProject ? (
                    <button
                      type="button"
                      className="btn btn-outline-primary me-2"
                      onClick={() => {
                        setIncludeDelete(0);
                        setPageNo(0);
                        setKeyWord("");
                        getAllProjects(0, 0, "", listCategory, listStatus);
                      }}
                    >
                      BACK
                    </button>
                  ) : (
                    AdminRole == 0 && (
                      <button
                        type="button"
                        className="btn btn-outline-primary text-nowrap me-2

                        "
                        onClick={() => {
                          goToProjectList();
                          // setIncludeDelete(1);
                          // setPageNo(0);
                          // setKeyWord("");
                          // ViewTrashedProject(1, 0, "", listCategory);
                        }}
                      >
                        PROJECT LIST
                      </button>
                    )
                  )}

                  <button
                    type="button"
                    className="btn btn-outline-primary me-2 "
                    onClick={() => {
                      RenderData();
                    }}
                  >
                    USAGE TRACKING
                  </button>
                </div>
              </div>
            </div>

            <div className="table-wraper">
              <div className={trashedProject ? "pb-tbl-blk trashed-table" : "pb-tbl-blk"}>
                <div className="pb-tbl-wrapper mb-3 sticky-head mt-0 render-table render-list-table">
                  <div className="pb-head-label ps-4">Project Name</div>
                  <div className="pb-head-label ps-4">Render Name</div>
                  <div className="pb-head-label text-center">Render By</div>
                  <div className="pb-head-label text-center">Category</div>
                  <div className="d-flex pb-head-label justify-content-center">
                    Video Duration
                    <div className="arrow">
                      <div className="arrow-up"></div>
                      <div className="arrow-down"></div>
                    </div>
                  </div>
                  <div className="d-flex pb-head-label justify-content-center ps-3 pe-0">
                    Render Time
                    <div className="arrow">
                      <div className="arrow-up"></div>
                      <div className="arrow-down"></div>
                    </div>
                  </div>
                  <div className="pb-head-label justify-content-center ps-3 pe-0">Rendering Status</div>
                </div>
                {ListProject()}

                {trashedProject
                  ? trashedProject && deletedProjects.length > 0
                    ? ""
                    : fetching === false && (
                        <React.Fragment>
                          <div className="no-project-selected blink">No project available</div>
                        </React.Fragment>
                      )
                  : !trashedProject && projectList.length > 0
                  ? ""
                  : fetching === false && (
                      <React.Fragment>
                        <div className="no-project-selected blink">No project available</div>
                      </React.Fragment>
                    )}
                <span className="no-project-selected">Total records found : {totalCount}</span>
              </div>
              {loadMoreEnable && (
                <div className="load-more-btn-holder text-end mt-4">
                  <button type="button" style={{ display: "inline-block" }} className="btn btn-primary cm-btn" onClick={() => moveToNextPage()}>
                    {fetching ? "Loading..." : "Load More"} {fetching && <CircularProgress size="1rem" color="inherit" />}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {getEnableWelcomeMsg && <WelcomeModal show={showWelcomeModal} closemodal={setshowWelcomeModal} />}
      <NavigatorOnline onChange={(status) => showMessage(status)} />
      <RenderVideo
        closePreview={() => setOpenRenderVideo(false)}
        openRenderVideo={openRenderVideo}
        refreshPage={refreshProject}
        // priviewrenderUrl={priviewrenderUrl}
        thumbnailUrl={props.RenderThumnails}
        setSlideCount={slideCount}
        setVideos={videos}
        // moduleCount={moduleCount}
        checkTemplateAvailable={checkTemplateAvailable}
        renderId={renderId}
        projectId={projectId}
      />
      <CustomModal
        isOpen={openUpdateModal}
        Closemodal={setopenUpdateModal}
        Content={confirmmsg}
        Header="Are you sure"
        Buttonlabel={confirmbtnlabel}
        Buttonclick={deleteOrRestoreProject}
        del_id={deleteProjectid}
      />

      <CustomModal
        isOpen={openRenderModal}
        Closemodal={setopenRenderModal}
        Content={confirmmsg}
        Header="Are you sure"
        Buttonlabel={confirmbtnlabel}
        Buttonclick={renderProject}
        del_id={renderProjectId}
      />
      <SaveChangeModal
        isOpen={refreshStatus}
        Closemodal={setRefreshStatus}
        Content={confirmmsg}
        Header="Render Error Details"
        Buttonlabel1="Full Details"
        Buttonclick1={setRefreshStatus}
        Buttonlabel2="No!"
        Buttonclick2={setOpenRenderErrorModal}
      />

      <ErrorModal
        show={refreshStatus}
        // closemodal={setOpenRenderErrorModal}
        closemodal={setRefreshStatus}
        renderProjectId={renderProjectId}
      />
    </React.Fragment>
  );
}
