import axios from 'axios';
import axiosRetry from 'axios-retry';

export const Axioslib = axios.create({

    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});
export const AxioslibUser = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});
axiosRetry(Axioslib, {
    retries: 3,
    retryDelay: (retryCount) => {
        return retryCount * 5000
    },
    retryCondition: (error) => {
        return error.response.status === 500
    }
})


// export default { Axioslib, AxioslibUser};