export const TEMPLATEIMAGES = [
    "https://cdn.pixabay.com/photo/2020/10/21/18/07/laptop-5673901_960_720.jpg",
    "https://cdn.pixabay.com/photo/2014/09/07/22/34/car-race-438467_960_720.jpg",
    "https://cdn.pixabay.com/photo/2015/04/19/08/33/flower-729512_960_720.jpg",
    "https://cdn.pixabay.com/photo/2019/12/02/08/04/city-4667143_960_720.jpg",
    "https://cdn.pixabay.com/photo/2015/01/08/18/26/man-593333_960_720.jpg",
    "https://cdn.pixabay.com/photo/2022/04/18/02/24/architecture-7139263_960_720.jpg"
]

export const TEMPLATEILLUSTRATIONS = [
    'https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Icons/car.png',
    'https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Icons/Man%20talking%20on%20phone.png',
    'https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Icons/secure%20laptop.png',
    'https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Icons/help.png',
    'https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Icons/computer.png',
    'https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Icons/eating_16072021042911302843.png'
]

export const TEMPLATEVIDEOS = [
    'https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Videos/Closeup_Of_Office_Phone_Dials_Person_Calling_By_Placing_Number_29032022062653638096.mp4',
    'https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Videos/A%20female%20using%20phone_15092021155030182704.mp4',
    'https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Videos/social-media-app-options-on-mobile-smart-phone-device-surfing-with-fingers.mp4',
    'https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Videos/Asian_Woman_Working_at_home_with_Online_Business._Asian_woman_is_working_with_financial_documents_at_home_office_with_smile_face._11042022205655803668.mp4',
    'https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Videos/SampleVideo_1280x720_1mb_23092021035324584137.mp4',
    'https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Videos/multiscreen-on-people-using-smartphone-in-everyday-life-nomophobia-people-using-the-mobile-phone-no-mobile-phone-phobia.mp4'
]

export const TEMPLATEHEADINGSIZE = 28;