import React from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';

const UserTable = ({ headers, userData }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {headers.map(row => {
                            return (
                                <TableCell style={{ "fontWeight": "bold",
                             }}>{row.label}</TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userData.map(row => (
                        <TableRow
                            key={row.id}
                        >
                            <TableCell>{row.tid}</TableCell>
                            <TableCell>{row.status}</TableCell>
                            <TableCell>{row.mediaUrl}</TableCell>
                            <TableCell>{row.no_of_ost_h}</TableCell>
                            <TableCell>{row.no_of_ost_p}</TableCell>
                            <TableCell>{row.no_of_ost_b}</TableCell>
                            <TableCell>{row.no_of_images}</TableCell>
                            <TableCell>{row.no_of_illustrations}</TableCell>
                            <TableCell>{row.no_of_videos}</TableCell>
                            <TableCell>{row.previewUrl}</TableCell>
                            <TableCell>{row.addedDate}</TableCell>
                            <TableCell>{row.emailid}</TableCell>
                            <TableCell>{row.previewDate}</TableCell>
                            <TableCell>{row.previewby}</TableCell>
                            <TableCell>{row.renderBy}</TableCell>
                            <TableCell>{row.renderDate}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default UserTable;