import { plusIcon } from '../../constants/icon';

export default function InnerSidebar(props) {
  const {
    children,
    onClickCreate,
    showCreateButton = true,
    showButton = false,
    hiddenCreateButtonMessage,
  } = props;
  return (
    <div className={`inner-sidebar ${props.className || ''}`}>
      <div className="inner-sidebar-holder">{children}</div>
      {showButton && (
        <div className="inner-sidebar-footer">
          {showCreateButton ? (
            <button className="outline-primary-btn" type="button" onClick={onClickCreate}>
              Create New Module {plusIcon}
            </button>
          ) : (
            <div className="screen-thumbnail-items-wrap">
              <span>{hiddenCreateButtonMessage}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
