import Cropper from 'react-easy-crop';
import { useEffect, useState } from 'react';

import PageLoader from '../../services/pageLoader';
import { uploadMediaFile } from '../../services/api.service';
import { getUserItem, showToaster } from '../../services/helper';
import getCroppedImg from '../../modules/dashboard/project-priview/RightBar/CropImage/CropFunctions';

export default function CropImage(props) {
  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();

  useEffect(() => {
    setZoom(1);
  }, [props.img]);

  useEffect(() => {
    if (props.cropImageReq) handleImageCrop();
  }, [props.cropImageReq]);

  async function uploadCroppedImage(croppedImage) {
    try {
      setLoading(true);

      let filename = 'IMG_' + Math.floor(Math.random() * 1000000);
      let file = await urltoFile(croppedImage, filename + '.jpg', '');

      let data = new FormData();
      const config = {
        headers: {
          private: 1,
          media_type: 'IMAGE',
          user_id: getUserItem('id'),
          file_name: filename + '.jpeg',
          'content-type': 'multipart/form-data',
        },
        timeout: 1000 * 50000,
      };
      data.append('file', file);

      uploadMediaFile(data, config).then(response => {
        if (response.data) {
          setLoading(false);
          props.setcropMode(false);
          props.setcropImageReq(false);
          props.setdisplayImage(response.data);
        } else {
          setLoading(false);
          showToaster(
            "We couldn't Recognize the image Updated. Please try again with another image",
            'warning'
          );
        }
      });
    } catch (error) {
      console.log({ uploadCroppedImageError: error });
      setLoading(false);
      props.setcropMode(false);
      props.setcropMode(false);
      props.setcropImageReq(false);
      props.setcropImageReq(false);
      showToaster(
        "We couldn't Recognize the image Updated. Please try again with another image",
        'warning'
      );
    }
  }

  const onCropComplete = (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleImageCrop = async () => {
    const croppedImage = await getCroppedImg(props.img, croppedAreaPixels);

    uploadCroppedImage(croppedImage);
  };

  const urltoFile = (url, filename) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: 'image/jpeg' });
      });
  };

  return (
    <>
      <Cropper
        crop={crop}
        zoom={zoom}
        aspect={16 / 9}
        image={props.img}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={onCropComplete}
      />

      <PageLoader showLoader={loading} />
    </>
  );
}
