import React, { useState } from "react";
import { useSelector, } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import jwt from 'jwt-simple';
import {
  updateEnableWelcomeMsg
} from "../../../services/authService";
import { joinUserToRoom } from "../../../services/socket.service";
function WelcomeModal(props) {
  //    const DisplayMode = useSelector(state => state.project.DisplayMode)
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [checkboxvalue, setCheckBoxValue] = useState(false);
  const HandleDontShow = (e) => {
    setCheckBoxValue(e.target.checked)
  };
  const CloseOrSkip = () => {
    if (checkboxvalue) {
      try {
        updateEnableWelcomeMsg().then((response) => {
          if(response.status===200){
            let loggedinUserDetails = jwt.encode(
              response.data.result,
              process.env.REACT_APP_JWT_SECRET,
              'HS512'
            );
            window.localStorage.setItem(
              '_olive_user',
              JSON.stringify(loggedinUserDetails)
            );
            joinUserToRoom();
          }
         

        }).catch((err) => {
        })
      } catch (error) {
      }

    }
    props.closemodal(false)



  };
  return (
    <Modal
      isOpen={props.show}
      fade={true}
      centered
      className={
        DisplayMode
          ? "modal-dialog  modal-dialog-centered styles-modal custom-modal dark"
          : "modal-dialog  modal-dialog-centered styles-modal custom-modal"
      }
    // className="modal-dialog  modal-dialog-centered styles-modal custom-modal dark"
    >
      <ModalBody>
        <div className="d-flex justify-content-end">
          <div
            className="modal-close"
            data-bs-dismiss="modal"
            onClick={() => CloseOrSkip()}
          >
            &times;
          </div>
        </div>
        <div className="modal-caption text-center">
          Let's make something amazing
        </div>
        <div className="modal-subcaption text-center">
          You can create your video either use a template or create video from
          scratch{" "}
        </div>
        <div className="d-flex align-items-center justify-content-center cp-wrapper">
          <div className="cp-container w-50 mt-5">
            <div className="cp-img-holder"></div>
            <div className="cp-heading text-center mb-2">Create New Course</div>
            <div className="cp-subheading text-center">
              Use one of our professionally designed video as a starting point.{" "}
            </div>
            <div className="theme-btn-holder mt-3">
              <button
                type="button"
                className="btn btn-primary theme-btnblue w-100 "
                data-bs-dismiss="modal"
              >
                create now
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3 mt-5">
          <label className="d-flex align-items-center custom-checkbox">
            <input
              type="checkbox"
              onChange={(e) => {
                HandleDontShow(e);
              }}
            />
            <span className="checkmark"></span>
            <div className="check-label">Don't show me again</div>
          </label>
          <button
            type="button"
            className="cancel-btn"
            data-bs-dismiss="modal"
            onClick={() => CloseOrSkip()}
          >
            Skip Now
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default WelcomeModal;
