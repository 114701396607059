import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';

// Icons
import { arrowDownIcon, renderIcon, searchIcon } from '../../../constants/icon';

import FormFloatingInput from '../../../components/form/formFloatingInput';
import { showToaster } from '../../../services/helper';
import CustomSwitchSlider from '../../../components/form/customSwitchSlider';

function SecondaryTemplateHeader() {
  const [keyWord, setKeyWord] = useState('');
  const handleSearch = val => {
    try {
      if (keyWord !== val.target.value.trim()) {
        setKeyWord(val.target.value);
      } else {
        if (keyWord.trim() !== '') {
          setKeyWord(val.target.value);
        } else {
          setKeyWord('');
        }
      }
    } catch (error) {
      showToaster('Something went wrong. Please Refresh page and Try Again', 'warning');
    }
  };

  const CustomToggleFilter = React.forwardRef(({ children, onClick }, ref) => (
    <div
      role="button"
      className="common-dropdown-filter"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
    >
      {children}
    </div>
  ));
  return (
    <>
      <div className="secondary-header secondary-sticky-header secondary-header-spacing">
        <FormFloatingInput
          leftIcon={searchIcon}
          type="search"
          id="list"
          placeholder=""
          label="Search templates"
          className="custom-search-form"
          onChange={e => handleSearch(e)}
        />
        <div className="common-dropdown-filter-wrap">
          <Dropdown align={{ lg: 'end' }}>
            <Dropdown.Toggle as={CustomToggleFilter} id="dropdown-custom-components">
              <span className="dropdown-icon dropdown-max-width-sm">
                Active template
                {arrowDownIcon}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="common-dropdown-menu">
              <Dropdown.Item eventKey="1">Item - 1</Dropdown.Item>
              <Dropdown.Item eventKey="2">Item - 2</Dropdown.Item>
              <Dropdown.Item eventKey="3">Item - 3</Dropdown.Item>
              <Dropdown.Item eventKey="4">Item - 4</Dropdown.Item>
              <Dropdown.Item eventKey="5">Item - 5</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown align={{ lg: 'end' }}>
            <Dropdown.Toggle as={CustomToggleFilter} id="dropdown-custom-components">
              <span className="dropdown-icon dropdown-max-width-sm">
                Preview template
                {arrowDownIcon}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="common-dropdown-menu">
              <Dropdown.Item eventKey="1">Item - 1</Dropdown.Item>
              <Dropdown.Item eventKey="2">Item - 2</Dropdown.Item>
              <Dropdown.Item eventKey="3">Item - 3</Dropdown.Item>
              <Dropdown.Item eventKey="4">Item - 4</Dropdown.Item>
              <Dropdown.Item eventKey="5">Item - 5</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown align={{ lg: 'end' }}>
            <Dropdown.Toggle as={CustomToggleFilter} id="dropdown-custom-components">
              <span className="dropdown-icon dropdown-max-width-sm">
                Render template
                {arrowDownIcon}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="common-dropdown-menu">
              <Dropdown.Item eventKey="1">Item - 1</Dropdown.Item>
              <Dropdown.Item eventKey="2">Item - 2</Dropdown.Item>
              <Dropdown.Item eventKey="3">Item - 3</Dropdown.Item>
              <Dropdown.Item eventKey="4">Item - 4</Dropdown.Item>
              <Dropdown.Item eventKey="5">Item - 5</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className="switch-card switch-card-sm">
            <div className="switch-card-info">
              <span>Advanced Filter</span>
            </div>
            <CustomSwitchSlider />
          </div>
          <span className="total-number-count">
            Total Template count: <strong>935</strong>
          </span>
          <Button variant="default">{renderIcon}Export</Button>
        </div>
      </div>
    </>
  );
}
export default SecondaryTemplateHeader;
