import React from "react";

// Icons
import { arrowUpIcon, arrowDownIcon } from "../../constants/icon";

const CustomToggle = (props) => {
  const { children, isActive, onClick } = props;
  return (
    <button className="accordion-arrow-btn" type="button" onClick={onClick}>
      {children}
      <span
        className={`arrow-icon ${isActive ? "up" : "down"}`}
        aria-hidden="true"
      >
        {isActive ? <>{arrowUpIcon}</> : <>{arrowDownIcon}</>}
      </span>
    </button>
  );
};

export default CustomToggle;
