import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import { deletePromptIcon, dissmissIcon } from '../constants/icon';

// className = bg-succes, bg-info, bg-warning, bg-danger
// icons { checkIcon, helpIcon, exclamationIcon, deletePromptIcon }

export default function CustomModal(props) {
  let DisplayMode = useSelector(state => state.project.DisplayMode);
  return (
    <Modal
      isOpen={props.isOpen}
      fade={true}
      centered
      className={
        DisplayMode
          ? 'modal-dialog custom-confirm-modal  modal-dialog-centered custom-modal modal-md'
          : 'modal-dialog  modal-dialog-centered custom-modal delete-modal modal-md'
      }
      // className="modal-dialog  modal-dialog-centered custom-modal delete-modal dark modal-md"
    >
      <ModalBody>
        <div
          className="modal-close text-right"
          title="Close"
          data-bs-dismiss="modal"
          onClick={() => {
            props.Closemodal(false);
          }}
        >
          {dissmissIcon}
        </div>
        <div className="modal-icon bg-danger">{deletePromptIcon}</div>
        <h3>{props.Header}</h3>
        <p>{props.Content !== '' ? props.Content : ''}</p>
        <div className="modal-button-wrap">
          <button
            type="button"
            className="primary-btn"
            onClick={() => {
              props.Closemodal(false);
            }}
          >
            No, Cancel
          </button>
          <button
            type="button"
            className="bg-primary-btn disabled-button"
            disabled={!!props.disableButton}
            onClick={() => {
              if (props.del_id !== undefined) {
                props.Buttonclick(props.del_id);
                props.Closemodal(false);
              } else {
                props.Buttonclick();
                props.Closemodal(false);
              }
            }}
          >
            {props.Buttonlabel}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}
