import { Tooltip, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  getAllTemplate,
  getAllTemplateByUserid,
  getTemplateRenderStatus,
  UpdateTemplatebyId,
  UpdateTemplateActivebyId,
  getOrganisation,
  getUsersGlobalRole,
  deleteTemplateById,
} from "../../../services/authService";
import Switch from "@mui/material/Switch";
// import Switch from "react-switch";
import { showToaster, getUserItem } from "../../../services/helper";
import Header from "../../components/header/header";
import PreviewModal from "../project-priview/RightBar/PreviewModal";
import RenderTemplate from "./RenderTemplate";
import PriviewTemplate from "./PriviewTemplate";
import Select from "react-select";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import ExportCsv from "./ExportCsv";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { CSVLink } from "react-csv";
import { Button } from "@material-ui/core";
import CustomModal from "../../../services/CustomModal";
import SaveChangeModal from "../../../services/SaveChangeModal";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBContainer,
} from "mdb-react-ui-kit";
function TemplateList() {
  const history = useHistory();
  const location = useLocation();
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [loading, setloading] = useState(false);
  const [checkedCreateProject, setCheckedCreateProject] = useState(false);
  const [openRenderTemplate, setOpenRenderTemplate] = useState(false);
  const [openPriviewTemplate, setOpenPriviewTemplate] = useState(false);
  const [templateList, settemplateList] = useState([]);
  const [openPreviewModals, setopenPreviewModals] = useState(false);
  const [previewUrl, setpreviewUrl] = useState(undefined);
  const [thumbnailUrl, setthumbnailUrl] = useState(undefined);
  const [listPriviewTemplate, setlistPriviewTemplate] = useState(false);
  const [listAllTemplate, setlistAllTemplate] = useState(false);
  const [listAllPriviewTemplate, setlistAllPriviewTemplate] = useState(false);
  const [searchfield, setsearchfield] = useState(undefined);
  const [searchData, setsearchData] = useState(undefined);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateCount, setTemplateCount] = useState(0);
  const [confirmmsg, setconfirmmsg] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const searchRef = useRef("");
  const allRef = useRef(0);
  const [activeValidStatus, setactiveValidStatus] = useState(undefined);
  const [activeArray, setActiveArray] = useState(undefined);
  const [deleteArray, setDeleteArray] = useState(undefined);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [fonts, setfonts] = useStateWithCallbackLazy();
  const [listFilterStatus, setListFilterStatus] = useState([]);
  const [listFilterPublicStatus, setListFilterPublicStatus] = useState([]);
  const [advanceFilterEnable, setAdvanceFilterEnable] = useState(false);
  const [listStatus, setListStatus] = useState({
    value: "All",
    status: 0,
  });
  const [listFilterActiveStatus, setListActiveFilterStatus] = useState([]);
  const [listActiveStatus, setListActiveStatus] = useState({
    value: "All",
    status: 0,
  });
  const [listPublicStatus, setListPublicStatus] = useState({
    value: "All",
    status: 0,
  });
  const [listOrgStatus, setListOrgStatus] = useState();
  const [listUploadedStatus, setListUploadedStatus] = useState({
    value: "All",
    label: "All",
    userId: 0,
  });
  const [uploadedOption, setUploadedOption] = useStateWithCallbackLazy();
  const [listUploadedUsers, setListUploadedUsers] = useState([]);
  const [orgOption, setOrgOption] = useStateWithCallbackLazy();
  const [listOrgUsers, setListOrgUsers] = useState([]);
  const [activeStatus, setActiveStatus] = useState(false);
  const [statusOption, setStatusOption] = useStateWithCallbackLazy();
  const [statusPublicOption, setStatusPublicOption] =
    useStateWithCallbackLazy();
  const [statusActiveOption, setStatusActiveOption] =
    useStateWithCallbackLazy();
  const [listFilterValidStatus, setListFilterValidStatus] = useState([]);
  const [listValidStatus, setListValidStatus] = useState({
    value: "All",
    status: 0,
  });
  let OrganiId = getUserItem("orgId");
  let userId = getUserItem("id");
  let AdminRole = getUserItem("role");

  
  let TemplateFeature = getUserItem("templateFeature");
  const [statusValidOption, setStatusvalidOption] = useStateWithCallbackLazy();
  let yourDate = new Date();
  let date = yourDate.toISOString().split("T")[0];
  const fileName = "template-details" + date + ".csv";
  const [userData, setUserData] = useState([]);
  const [checkUploadedDropdown, setCheckUploadedDropdown] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [privateTemDropdown, setPrivateTemDropdown] = useState(false);
  const headers = [
    { label: "Tid", key: "tid" },
    { label: "Status", key: "isActive" },
    { label: "Template Name", key: "mediaUrl" },
    { label: "No of ost H", key: "no_of_ost_h" },
    { label: "No of ost P", key: "no_of_ost_p" },
    { label: "No of ost B", key: "no_of_ost_b" },
    { label: "No of images", key: "no_of_images" },
    { label: "No of Illustrations", key: "no_of_illustrations" },
    { label: "No of videos", key: "no_of_videos" },
    { label: "Preview", key: "previewUrl" },
    { label: "Uploaded date", key: "addedDate" },
    { label: "Uploaded by", key: "emailid" },
    { label: "Preview date", key: "previewDate" },
    { label: "Preview by", key: "previewby" },
    { label: "Render by", key: "renderBy" },
    { label: "Render Date", key: "renderDate" },
  ];
  // let header = ["Tid", "Template Name","No of ost H","No of ost P","No of ost B","No of images",
  // "No of Illustrations","No of videos","Preview","Render","Uploaded date",
  // "Uploaded by","Preview date","Preview by","Render by","Render Date"

  // ];
  // useEffect(() => {
  //   getUserData();
  //   console.log(userData,"user");
  // }, []);
  const backToDashboard = () => {
    history.push("/template-alllist");
  };

  const getuserGlobelRoles = () => {
    getUsersGlobalRole(userId).then((response) => {
      console.log(response, "response");
      try {
        setloading(true);
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          console.log(response, "response");
          // setCategoryList(ddd)
          setloading(false);

          // setTimeout(() => {
          //     setprojectList(projectData2)
          // }, 1000);
          // setCreateProjecteEnable(response.data.result[0].addProject)
          // setAdduserEnable(response.data.result[0].addUser)
          // setTemplateListEnable(response.data.result[0].templateList)
          // setTemplateUploadEnable(response.data.result[0].templateUpload)
          console.log(response.data.result, "datatatatat");
        } else {
          setloading(false);
          showToaster("Something went wrong. Please Try Again1", "warning");
        }
      } catch (error) {
        console.log(error);

        setloading(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };
  useEffect(() => {
    if (listAllTemplate == false && listOrgUsers.length > 0) {
      console.log(listOrgUsers, "listOrgUsers123");
      setListOrgStatus({
        value: listOrgUsers[0].emailid,
        label: listOrgUsers[0].emailid,
        orgId: listOrgUsers[0].userid,
      });
      // setListUploadedStatus({
      //   value: "All",
      //   label: "All",
      //   userId: 0,
      // });
    }
  }, [listAllTemplate]);
  const getuserGlobelRolesAdmin = () => {
    let RoleUserId = AdminRole == 0 ? userId : OrganiId;
    getUsersGlobalRole(RoleUserId).then((response) => {
      console.log(response, "response");
      try {
        setloading(true);
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null &&
          response.data.result !== undefined &&
          response.data.result !== "" &&
          response.data.result !== null &&
          response.data.result[0] !== undefined &&
          response.data.result[0] !== "" &&
          response.data.result[0] !== null
        ) {
          console.log(response, "response");
          // setCategoryList(ddd)
          setloading(false);

          // setTimeout(() => {
          //     setprojectList(projectData2)
          // }, 1000);
          // setCreateProjecteEnable(response.data.result[0].addProject)
          // setAdduserEnable(response.data.result[0].addUser)
          if (response.data.result[0].templateList !== undefined) {
            if (response.data.result[0].templateList == false) {
              history.push("/project-list");
            }
          }
        } else {
          setloading(false);
          // showToaster("Something went wrong. Please Try Again1", "warning");
        }
      } catch (error) {
        console.log(error);

        setloading(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };
  const getUniqueUserData = async () => {
    setloading(true);

    const listAll = listAllTemplate ? 1 : 0;
    let filter = {
      all: listAll || allRef.current,
      search: search || searchRef.current,
      pageNo: 1,
      limit: 100000,
    };

    await getAllTemplate(filter).then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          let ddd = response.data.result;
          ddd.map((values) => {
            let data = values.mediaUrl.split("/")[5];
            let FileName = data.split(".")[0];
            values.mediaUrl = FileName;
            if (values.isActive == true) {
              values.isActive = "Active";
            } else {
              values.isActive = "Inactive";
            }
          });
          let ddd1 = response.data.result;
          const unique = [...new Map(ddd1.map((m) => [m.userid, m])).values()];
          console.log(unique, "unique");
          console.log(ddd, "FileName");
          let projectData = {
            emailid: "All",
            userId: 0,
          };
          let orglist = [projectData, ...unique];
          setListUploadedUsers(orglist);

          let projectData1 = {
            emailid: "All",
            orgId: 0,
          };
          let orglist1;
          if (listAllTemplate) {
            orglist1 = [projectData1, ...unique];
          } else {
            orglist1 = unique;
          }

          console.log(orglist1);
          setListOrgUsers(orglist1);
          setloading(false);
        } else {
          setloading(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      } catch (error) {
        console.log(error);
        setloading(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };

  const getUserData = async () => {
    setloading(true);
    // const response = await getAllTemplate({
    //   all: 1,
    //   search: search || searchRef.current,
    //   pageNo: currentPage,
    //   limit: 100000,
    //   preview: listStatus.status,
    //   isvalid: listValidStatus.status,
    // });
    const listAll = listAllTemplate ? 1 : 0;
    let filter = {
      all: listAll || allRef.current,
      search: search || searchRef.current,
      pageNo: 1,
      limit: 100000,
      preview: listStatus.status,
      isvalid: listValidStatus.status,
      isActive: listActiveStatus.status,
      orgId:
        checkedCreateProject == true &&
        listOrgStatus !== undefined &&
        listOrgStatus.orgId !== undefined &&
        listPublicStatus.status === 3
          ? listOrgStatus.orgId
          : "",
      uploadBy:
        checkedCreateProject == true && listUploadedStatus.value !== "All"
          ? listUploadedStatus.userId
          : "",
      public: checkedCreateProject == true ? listPublicStatus.status : "",
    };

    await getAllTemplate(filter).then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          let ddd = response.data.result;
          ddd.map((values) => {
            let data = values.mediaUrl.split("/")[5];
            let FileName = data.split(".")[0];
            values.mediaUrl = FileName;
            if (values.isActive == true) {
              values.isActive = "Active";
            } else {
              values.isActive = "Inactive";
            }
          });
          let ddd1 = response.data.result;
          const unique = [...new Map(ddd1.map((m) => [m.userid, m])).values()];
          console.log(unique, "unique");
          ddd1.map((values) => {
            //  if(values.userid)
          });
          console.log(ddd, "FileName");
          setUserData(ddd);
          // setListUploadedUsers(unique);
          setloading(false);
        } else {
          setloading(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      } catch (error) {
        console.log(error);
        setloading(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };
  useEffect(() => {
    setpreviewUrl(undefined);
    setthumbnailUrl(undefined);
    // getTemplates();
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      let searchVal = searchParams.get("search");
      let allVal = searchParams.get("all");
      let allPreview = searchParams.get("allpriview");
      searchVal = searchVal ? searchVal.trim() : "";
      if (searchVal) {
        setSearch(searchVal);
        searchRef.current = searchVal;
      }
      if (allVal) {
        setlistAllTemplate(true);
        allRef.current = allVal;
      }
      // if(allPreview) {
      //   setlistAllTemplate(true);
      //   allRef.current = allPreview;
      // }
    }
    if (listAllTemplate == false && listOrgUsers.length > 0) {
      console.log(listOrgUsers, "listOrgUsers123");
      setListOrgStatus({
        value: listOrgUsers[0].emailid,
        label: listOrgUsers[0].emailid,
        orgId: listOrgUsers[0].userid,
      });
      // setListUploadedStatus({
      //   value: "All",
      //   label: "All",
      //   userId: 0,
      // });
    }

    getuserGlobelRoles();
    getUniqueUserData();
    FilterByStatus();
    FilterByValidStatus();
    FilterByActiveStatus();
    FilterByPublicStatus();
    getOrganisationList();
    getuserGlobelRolesAdmin();
  }, []);

  // useEffect(() => {
  //   if (searchfield != undefined && searchfield != "") {
  //     if (templateList != undefined) {
  //       console.log(templateList);
  //       let result = templateList.result.data.filter((item) => item);
  //       console.log(result);
  //     }
  //   } else {
  //     setsearchData(undefined);
  //   }
  // }, [searchfield]);
  useEffect(() => {
    const getTemplates = async () => {
      try {
        setloading(true);
        const listAll = listAllTemplate ? 1 : 0;
        const priviewAll = listAllPriviewTemplate ? 1 : 0;
        const response = await getAllTemplate({
          all: listAll || allRef.current,
          search: search || searchRef.current,
          pageNo: currentPage,
          limit: perPage,
          preview: listStatus.status,
          isvalid: listValidStatus.status,
          isActive: listActiveStatus.status,
          // orgId:
          //   checkedCreateProject == true && listPublicStatus.status !== 2
          //     ? listOrgStatus.orgId
          //     : "",
          // public: checkedCreateProject == true&&listPublicStatus.status==2 ? listPublicStatus.status : "",
        });
        if (response.data && response.data.result) {
          settemplateList(response.data.result);
          setTemplateCount(response.data.totalCount);
        }
      } catch (error) {
        console.log(error);
      }
      searchRef.current = "";
      allRef.current = 0;
      setloading(false);
    };
    getTemplates();
    getUserData();
  }, [listPriviewTemplate]);
  useEffect(() => {
    const getTemplates = async () => {
      try {
        setloading(true);
        const listAll = listAllTemplate ? 1 : 0;
        const priviewAll = listAllPriviewTemplate ? 0 : 1;
        const response = await getAllTemplate({
          all: listAll || allRef.current,
          search: search || searchRef.current,
          pageNo: currentPage,
          limit: perPage,
          preview: listStatus.status,
          isvalid: listValidStatus.status,
          isActive: listActiveStatus.status,
          orgId:
            checkedCreateProject == true && listPublicStatus.status == 3
              ? listOrgStatus.orgId
              : undefined,

          public:
            checkedCreateProject == true ? listPublicStatus.status : undefined,
          uploadBy:
            checkedCreateProject == true && listUploadedStatus.value !== "All"
              ? listUploadedStatus.userId
              : "",
        });
        if (response.data && response.data.result) {
          settemplateList(response.data.result);
          let ddd = response.data.result;
          // ddd.map((values) => {
          //   let data = values.mediaUrl.split("/")[5];
          //   let FileName = data.split(".")[0];
          //   values.mediaUrl = FileName;
          //   if (values.isActive == true) {
          //     values.isActive = "Active";
          //   } else {
          //     values.isActive = "Inactive";
          //   }
          // });
          let ddd1 = response.data.result;
          const unique = [...new Map(ddd1.map((m) => [m.userid, m])).values()];
          console.log(unique, "unique");

          console.log(ddd, "FileName");
          // setUserData(ddd);
          // setListUploadedUsers(unique);
          setTemplateCount(response.data.totalCount);
        }
      } catch (error) {
        console.log(error);
      }
      searchRef.current = "";
      allRef.current = 0;
      setloading(false);
    };
    getTemplates();
    getUserData();
    getUniqueUserData();
  }, [
    listAllTemplate,
    listStatus,
    listValidStatus,
    currentPage,
    isSearch,
    perPage,
    listActiveStatus,
    // listOrgStatus,
    // listPublicStatus,
  ]);

  useEffect(() => {
    console.log(checkedCreateProject, "checkedCreateProject");
    if (checkedCreateProject && listPublicStatus.status == 2) {
      const getTemplates = async () => {
        try {
          setloading(true);
          const listAll = listAllTemplate ? 1 : 0;
          const priviewAll = listAllPriviewTemplate ? 0 : 1;
          const response = await getAllTemplate({
            all: listAll || allRef.current,
            search: search || searchRef.current,
            pageNo: currentPage,
            limit: perPage,
            preview: listStatus.status,
            isvalid: listValidStatus.status,
            isActive: listActiveStatus.status,

            public:
              checkedCreateProject == true && listPublicStatus.status == 2
                ? listPublicStatus.status
                : "",
            uploadBy:
              checkedCreateProject == true && listUploadedStatus.value !== "All"
                ? listUploadedStatus.userId
                : "",
            // allOrg: checkedCreateProject == true &&listPublicStatus.status==2&&listOrgStatus.value=="All"?0:""
          });
          if (response.data && response.data.result) {
            settemplateList(response.data.result);
            setTemplateCount(response.data.totalCount);
          }
        } catch (error) {
          console.log(error);
        }
        searchRef.current = "";
        allRef.current = 0;
        setloading(false);
      };
      getTemplates();
      getUserData();
    }
  }, [listPublicStatus]);
  useEffect(() => {
    console.log(checkedCreateProject, "checkedCreateProject");
    if (checkedCreateProject && listPublicStatus.status == 0) {
      const getTemplates = async () => {
        try {
          setloading(true);
          const listAll = listAllTemplate ? 1 : 0;
          const priviewAll = listAllPriviewTemplate ? 0 : 1;
          const response = await getAllTemplate({
            all: listAll || allRef.current,
            search: search || searchRef.current,
            pageNo: currentPage,
            limit: perPage,
            preview: listStatus.status,
            isvalid: listValidStatus.status,
            isActive: listActiveStatus.status,
            uploadBy:
              checkedCreateProject == true && listUploadedStatus.value !== "All"
                ? listUploadedStatus.userId
                : "",
            // public: checkedCreateProject == true&&listPublicStatus.status==2 ? listPublicStatus.status : "",
            // allOrg: checkedCreateProject == true &&listPublicStatus.status==2&&listOrgStatus.value=="All"?0:""
          });
          if (response.data && response.data.result) {
            settemplateList(response.data.result);
            setTemplateCount(response.data.totalCount);
          }
        } catch (error) {
          console.log(error);
        }
        searchRef.current = "";
        allRef.current = 0;
        setloading(false);
      };
      getTemplates();
      getUserData();
    }
  }, [listPublicStatus]);

  // useEffect(() => {
  //   console.log(checkedCreateProject, "checkedCreateProject");
  //   if (checkedCreateProject ) {
  //     if (
  //       listUploadedUsers !== undefined &&
  //       listUploadedUsers[0] !== undefined &&
  //       listUploadedUsers[0].emailid !== undefined &&
  //       listUploadedUsers[0].userid !== undefined
  //     ) {
  //       console.log(listUploadedUsers[0]);
  //       setListUploadedStatus({
  //         value: listUploadedUsers[0].emailid,
  //         label: listUploadedUsers[0].emailid,
  //         userId: listUploadedUsers[0].userid,
  //       });
  //     }
  //   }
  // }, [listPublicStatus]);
  // useEffect(() => {
  //   console.log(listPublicStatus, "listPublicStatus");
  //   if (checkedCreateProject && listPublicStatus.status === 4) {
  //     setListOrgStatus({
  //       value: "All",
  //       label: "All",
  //       orgId: 0,
  //     });
  //   }
  // }, [listPublicStatus]);
  useEffect(() => {
    console.log(checkedCreateProject, "checkedCreateProject");
    if (checkedCreateProject && listPublicStatus.status == 3) {
      const getTemplates = async () => {
        try {
          setloading(true);
          const listAll = listAllTemplate ? 1 : 0;
          const priviewAll = listAllPriviewTemplate ? 0 : 1;
          const response = await getAllTemplate({
            all: listAll || allRef.current,
            search: search || searchRef.current,
            pageNo: currentPage,
            limit: perPage,
            preview: listStatus.status,
            isvalid: listValidStatus.status,
            isActive: listActiveStatus.status,
            uploadBy:
              checkedCreateProject == true && listUploadedStatus.value !== "All"
                ? listUploadedStatus.userId
                : "",
            public: checkedCreateProject == true ? listPublicStatus.status : "",
            allOrg: 0,
          });
          if (response.data && response.data.result) {
            settemplateList(response.data.result);
            setTemplateCount(response.data.totalCount);
          }
        } catch (error) {
          console.log(error);
        }
        searchRef.current = "";
        allRef.current = 0;
        setloading(false);
      };
      getTemplates();
      getUserData();
    }
  }, [listPublicStatus]);

  useEffect(() => {
    console.log(checkedCreateProject, "checkedCreateProject");
    if (checkedCreateProject) {
      const getTemplates = async () => {
        try {
          setloading(true);
          const listAll = listAllTemplate ? 1 : 0;
          const priviewAll = listAllPriviewTemplate ? 0 : 1;
          const response = await getAllTemplate({
            all: listAll || allRef.current,
            search: search || searchRef.current,
            pageNo: currentPage,
            limit: perPage,
            preview: listStatus.status,
            isvalid: listValidStatus.status,
            isActive: listActiveStatus.status,
            uploadBy:
              checkedCreateProject == true && listUploadedStatus.value !== "All"
                ? listUploadedStatus.userId
                : "",
            public: checkedCreateProject == true ? listPublicStatus.status : "",
            // allOrg:0
          });
          if (response.data && response.data.result) {
            settemplateList(response.data.result);
            setTemplateCount(response.data.totalCount);
          }
        } catch (error) {
          console.log(error);
        }
        searchRef.current = "";
        allRef.current = 0;
        setloading(false);
      };
      getTemplates();
      getUserData();
    }
  }, [listUploadedStatus]);
  useEffect(() => {
    if (checkedCreateProject) {
      const getTemplates = async () => {
        try {
          setloading(true);
          const listAll = listAllTemplate ? 1 : 0;
          const priviewAll = listAllPriviewTemplate ? 0 : 1;
          const response = await getAllTemplate({
            all: listAll || allRef.current,
            search: search || searchRef.current,
            pageNo: currentPage,
            limit: perPage,
            preview: listStatus.status,
            isvalid: listValidStatus.status,
            isActive: listActiveStatus.status,
            orgId:
              checkedCreateProject == true && listPublicStatus.status !== 2
                ? listOrgStatus.orgId
                : "",
            public: checkedCreateProject == true ? listPublicStatus.status : "",
            allOrg:
              checkedCreateProject == true && listOrgStatus.value == "All"
                ? 0
                : "",
            // uploadBy: checkedCreateProject == true &&listUploadedStatus.value!=="All"?  listUploadedStatus.userId:"",
          });
          if (response.data && response.data.result) {
            settemplateList(response.data.result);
            setTemplateCount(response.data.totalCount);
          }
        } catch (error) {
          console.log(error);
        }
        searchRef.current = "";
        allRef.current = 0;
        setloading(false);
      };
      getTemplates();
      getUserData();
    }
  }, [listOrgStatus]);
  // const listAllTemplates = () => {
  //   setloading(true);
  //   if (listAllTemplate) {
  //     getAllTemplateByUserid().then((response) => {
  //       try {
  //         if (
  //           response !== "" &&
  //           response !== undefined &&
  //           response !== null &&
  //           response.status !== "" &&
  //           response.status !== undefined &&
  //           response.status !== null &&
  //           response.status === 200 &&
  //           response.data !== "" &&
  //           response.data !== undefined &&
  //           response.data !== null
  //         ) {
  //           // this.fontArray = response.data
  //           console.log(response.data);
  //           settemplateList(response.data);
  //           setloading(false);
  //           //generateFonts();
  //         }
  //       } catch (error) {
  //         setloading(false);
  //       }
  //     });
  //   }
  // };

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader-inner">
          <img src="../../../assets/img/loader.gif"></img>
        </div>
      </div>
    );
  };

  const paginate = (e, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onRenderClick = (template, e) => {
    setSelectedTemplate(template);
    setOpenRenderTemplate(true);
  };

  const updateRenderStatus = (template, data) => {
    settemplateList((list) => {
      const newList = [...list];
      const idx = newList.findIndex((n) => n.tid === template.tid);
      if (idx > -1) {
        newList[idx] = { ...newList[idx], ...data };
      }
      return [...newList];
    });
  };

  const onRefreshClick = async (template, e) => {
    try {
      setloading(true);
      let renderFilter = {
        project_id: template.projectDetails.ProjectID,
        projectName: template.projectDetails.ProjectName,
        tId: template.tid,
      };
      const response = await getTemplateRenderStatus(renderFilter);
      console.log(response, "apiStatus");
      if (
        response.data !== undefined &&
        response.data !== null &&
        response.data !== "" &&
        response.status !== undefined &&
        response.status !== null &&
        (response.status === 200 || response.status === 500)
      ) {
        if (response.data.is_valid === 0) {
          updateRenderStatus(template, { isRendering: false });
          showToaster(
            "There is no valid data for rendering the project!!",
            "warning"
          );
        } else if (response.apiStatus === false) {
          updateRenderStatus(template, { isRendering: false });
        } else if (
          response.message == "Error, please try rendering video again"
        ) {
          updateRenderStatus(template, { isRendering: false });
          showToaster("Error, please try rendering video again", "warning");
        } else {
          if (
            response.data.mediaurl !== undefined &&
            response.data.mediaurl !== null &&
            response.data.mediaurl !== "" &&
            Object.keys(response.data.mediaurl).length !== 0
          ) {
            if (response.data.renderstatus === "100%") {
              updateRenderStatus(template, {
                isRendering: false,
                renderDetails: { ...response.data },
                projectDetails: {
                  ...template.projectDetails,
                  RenderStatus: "100%",
                },
              });
            } else {
              updateRenderStatus(template, {
                projectDetails: {
                  ...template.projectDetails,
                  RenderStatus: response.data.renderstatus,
                },
              });
            }
          }
        }
      }
    } catch (error) {
      showToaster("Error, please try again", "warning");
    }
    setloading(false);
  };

  const showRender = (template) => {
    if (template.projectDetails) {
      if (template.isRendering) {
        return (
          <>
            <span>{template.projectDetails.RenderStatus}</span>&nbsp;
            <Tooltip title="Refresh" arrow>
              <div
                className="pb-stat-icon"
                onClick={onRefreshClick.bind(null, template)}
              >
                <svg
                  className="me-2"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="18"
                  height="21"
                  viewBox="0 0 592.99 592.99"
                  style={{
                    enableBackground: "new 0 0 592.99 592.99;",
                  }}
                  space="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M274.292,21.879C122.868,21.879,0,145.072,0,296.496C0,447.92,122.262,571.111,275.262,571.111v-91.799
              c-100.98,0-183.462-82.012-183.462-182.816c0-100.806,81.362-182.817,182.168-182.817c98.753,0,179.413,78.718,182.661,176.696
              h-45.236l90.799,127.541l90.799-127.541h-44.486C545.248,141.767,423.67,21.879,274.292,21.879z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </Tooltip>
          </>
        );
      } else {
        if (
          template.projectDetails.RenderStatus === "0%" &&
          template.projectDetails.Is_Valid === 1 &&
          template.previewUrl !== undefined &&
          template.previewUrl !== "" &&
          template.previewUrl !== null
        ) {
          return (
            <button
              className="btn btn-sm btn-secondary d-inline-block m-auto"
              onClick={onRenderClick.bind(null, template)}
              type="button"
            >
              Render
            </button>
          );
        }
        if (template.previewUrl === null) {
          return (
            <Tooltip
              title="After preview generation render function is available"
              arrow
            >
              <span>NA</span>
            </Tooltip>
          );
        }
        if (template.projectDetails.Is_Valid === 0) {
          return <span>Invalid</span>;
        }
        if (
          template.projectDetails.RenderStatus === "100%" &&
          template.renderDetails &&
          template.renderDetails.is_valid.toString() === "1"
        ) {
          const slideRender =
            template.renderDetails?.mediaurl?.M01?.SL01?.SlideRender;
          if (slideRender === "1") {
            const videoUrl = template.renderDetails?.mediaurl?.M01?.SL01?.url;
            const thumbnailUrl =
              template.renderDetails?.mediaurl?.M01?.SL01?.thumbnail_url;
            return (
              <div className="list-image">
                <img src={thumbnailUrl} />{" "}
                <span
                  onClick={() => {
                    setthumbnailUrl(thumbnailUrl);
                    setpreviewUrl(videoUrl);
                    setopenPreviewModals(true);
                  }}
                  title="Play video"
                >
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 485 485"
                  >
                    <g>
                      {" "}
                      <path
                        d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5
        s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026
        C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5
        S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z"
                      />{" "}
                      <polygon points="181.062,336.575 343.938,242.5 181.062,148.425  " />
                    </g>
                  </svg>
                </span>
              </div>
            );
          }
        }
        return null;
      }
    } else {
      return (
        <Tooltip
          title="After preview generation render function is available"
          arrow
        >
          <span>NA</span>
        </Tooltip>
      );
    }
  };
  const changeActiveStatus = async (template, type) => {
    if (template.previewUrl == null || template.renderDetails == null) {
      setActiveStatus(true);
      console.log(activeArray, "haridasank");
    } else {
      let Status = type == "active" ? false : true;

      await UpdateTemplateActivebyId(template.tid, Status).then((response) => {
        try {
          setloading(true);
          console.log(response, "FileName");
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null &&
            response.data.result !== undefined &&
            response.data.result !== "" &&
            response.data.result !== null
          ) {
            let ddd = response.data.result;
            const getTemplates = async () => {
              try {
                setloading(true);
                const listAll = listAllTemplate ? 1 : 0;
                const priviewAll = listAllPriviewTemplate ? 1 : 0;
                const response = await getAllTemplate({
                  all: listAll || allRef.current,
                  search: search || searchRef.current,
                  pageNo: currentPage,
                  limit: perPage,
                  preview: listStatus.status,
                  isvalid: listValidStatus.status,
                  isActive: listActiveStatus.status,
                  orgId:
                    checkedCreateProject == true &&
                    listPublicStatus.status !== 2
                      ? listOrgStatus.orgId
                      : "",
                  public:
                    checkedCreateProject == true ? listPublicStatus.status : "",
                });
                if (response.data && response.data.result) {
                  settemplateList(response.data.result);
                  setTemplateCount(response.data.totalCount);
                }
              } catch (error) {
                console.log(error);
              }
              searchRef.current = "";
              allRef.current = 0;
            };
            getTemplates();
            getUserData();
            setActiveStatus(false);
            console.log(response.data.result.data, "FileName");

            setloading(false);
          } else {
            setloading(false);
            showToaster("Something went wrong. Please Try Again", "warning");
          }
        } catch (error) {
          console.log(error);
          setloading(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      });
    }
  };
  const deleteTemplate = async () => {
    await deleteTemplateById(deleteArray.tid).then((response) => {
      try {
        setloading(true);
        console.log(response, "FileName");
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null &&
          response.data.result !== undefined &&
          response.data.result !== "" &&
          response.data.result !== null
        ) {
          let ddd = response.data.result;
          showToaster("Template Deleted Successfully", "success");
          const getTemplates = async () => {
            try {
              // setloading(true);
              const listAll = listAllTemplate ? 1 : 0;
              const priviewAll = listAllPriviewTemplate ? 1 : 0;
              const response = await getAllTemplate({
                all: listAll || allRef.current,
                search: search || searchRef.current,
                pageNo: currentPage,
                limit: perPage,
                // preview: listStatus.status,
                // isvalid: listValidStatus.status,
                // isActive: listActiveStatus.status,
                // orgId:
                //   checkedCreateProject == true && listPublicStatus.status !== 2
                //     ? listOrgStatus.orgId
                //     : "",
                // public:
                //   checkedCreateProject == true ? listPublicStatus.status : "",
              });
              if (response.data && response.data.result) {
                settemplateList(response.data.result);
                setTemplateCount(response.data.totalCount);
              }
            } catch (error) {
              console.log(error);
            }
            searchRef.current = "";
            allRef.current = 0;
          };
          getTemplates();
          getUserData();
          setDeleteStatus(false);
          console.log(response.data.result.data, "FileName");

          setloading(false);
        } else {
          setloading(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      } catch (error) {
        console.log(error);
        setloading(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };

  const setCreateProjectEnable = (event) => {
    setCheckedCreateProject(event.target.checked);
  };
  useEffect(() => {
    if (checkedCreateProject === true) {
      setAdvanceFilterEnable(true);
    } else {
      setAdvanceFilterEnable(false);
      const getTemplates = async () => {
        try {
          setloading(true);
          const listAll = listAllTemplate ? 1 : 0;
          const priviewAll = listAllPriviewTemplate ? 1 : 0;
          const response = await getAllTemplate({
            all: listAll || allRef.current,
            search: search || searchRef.current,
            pageNo: currentPage,
            limit: perPage,
            preview: listStatus.status,
            isvalid: listValidStatus.status,
            isActive: listActiveStatus.status,
            orgId:
              checkedCreateProject == true && listPublicStatus.status !== 2
                ? listOrgStatus.orgId
                : "",
            public: checkedCreateProject == true ? listPublicStatus.status : "",
          });

          if (response.data && response.data.result) {
            settemplateList(response.data.result);
            setTemplateCount(response.data.totalCount);
            let ddd = response.data.result;
            // ddd.map((values) => {
            //   let data = values.mediaUrl.split("/")[5];
            //   let FileName = data.split(".")[0];
            //   values.mediaUrl = FileName;
            //   if (values.isActive == true) {
            //     values.isActive = "Active";
            //   } else {
            //     values.isActive = "Inactive";
            //   }
            // });
            let ddd1 = response.data.result;
            const unique = [
              ...new Map(ddd1.map((m) => [m.userid, m])).values(),
            ];
            console.log(unique, "unique");

            console.log(ddd, "FileName");
            // setUserData(ddd);
            // setListUploadedUsers(unique);
          }
        } catch (error) {
          console.log(error);
        }
        searchRef.current = "";
        allRef.current = 0;
      };
      getTemplates();
      getUserData();
      setListPublicStatus({
        value: "All",
        label: "All",
        status: 0,
      });
      if (checkedCreateProject == false) {
        setListOrgStatus({
          value: "All",
          label: "All",
          orgId: 0,
        });
      } else {
        if (listOrgUsers.length > 0) {
          setListOrgStatus({
            value: listOrgUsers[0].emailid,
            label: listOrgUsers[0].emailid,
            orgId: listOrgUsers[0].userid,
          });
          // setListUploadedStatus({
          //   value: "All",
          //   label: "All",
          //   userId: 0,
          // });
        }
      }
      console.log(listOrgUsers, "listOrgUsers123");
      setListUploadedStatus({
        value: "All",
        label: "All",
        userId: 0,
      });
    }
  }, [checkedCreateProject]);

  const changeActiveTemplate = async () => {
    console.log(activeArray, "activeArray");
    let Status = activeValidStatus == "active" ? false : true;

    await UpdateTemplateActivebyId(activeArray.tid, Status).then((response) => {
      try {
        setloading(true);
        console.log(response, "FileName");
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null &&
          response.data.result !== undefined &&
          response.data.result !== "" &&
          response.data.result !== null
        ) {
          let ddd = response.data.result;
          const getTemplates = async () => {
            try {
              setloading(true);
              const listAll = listAllTemplate ? 1 : 0;
              const priviewAll = listAllPriviewTemplate ? 1 : 0;
              const response = await getAllTemplate({
                all: listAll || allRef.current,
                search: search || searchRef.current,
                pageNo: currentPage,
                limit: perPage,
                // preview: listStatus.status,
                // isvalid: listValidStatus.status,
                // isActive: listActiveStatus.status,
                // orgId:
                //   checkedCreateProject == true && listPublicStatus.status !== 2
                //     ? listOrgStatus.orgId
                //     : "",
                // public:
                //   checkedCreateProject == true ? listPublicStatus.status : "",
              });
              if (response.data && response.data.result) {
                settemplateList(response.data.result);
                setTemplateCount(response.data.totalCount);
              }
            } catch (error) {
              console.log(error);
            }
            searchRef.current = "";
            allRef.current = 0;
            setloading(false);
          };
          getTemplates();
          getUserData();
          setActiveStatus(false);
          console.log(response.data.result.data, "FileName");

          setloading(false);
        } else {
          setloading(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      } catch (error) {
        console.log(error);
        setloading(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };

  const onPrviewClick = (template) => {
    setOpenPriviewTemplate(true);
    setSelectedTemplate(template);
  };
  const ListAllTemplates = (data) => {
    return (
      <div className="table-wraper ">
        <div className="usage-list-warper template-listing total-template-list">
          <div className="usage-block mb-3 sticky-head mt-0">
            <div className="usage-head-label ">Tid</div>
            <div className="usage-head-label text-center">Status</div>
            <div className="usage-head-label text-center">Delete</div>
            <div className="usage-head-label text-center">Template Name </div>
            <div className="usage-head-label text-center">No of ost H</div>
            <div className="usage-head-label text-center">No of ost P</div>
            <div className="usage-head-label text-center">No of ost B</div>
            <div className="usage-head-label text-center">No of images</div>
            <div className="usage-head-label text-center">
              No of Illustrations
            </div>
            <div className="usage-head-label text-center">No of videos</div>
            <div className="usage-head-label text-center">Preview</div>
            <div className="usage-head-label text-center">Render</div>
            <div className="usage-head-label text-center">Uploaded date</div>
            <div className="usage-head-label text-center">Uploaded by</div>
            <div className="usage-head-label text-center">Preview date</div>
            <div className="usage-head-label text-center">Preview by</div>
            <div className="usage-head-label text-center">Render by</div>
            <div className="usage-head-label text-center">Render date</div>
            <div className="usage-head-label text-center">Status By</div>
          </div>
          {data != undefined &&
            data.map((template, i) => {
              let Name = template.mediaUrl.split("/")[5];
              let FileName = Name.split(".")[0];
              console.log(FileName, "zipFileName");
              return (
                <div key={i} className="usage-block usg-sub-block mb-3">
                  <div className="usage-head-label ">{template.tid}</div>
                  <div className="usage-head-label text-center">
                    {template.isActive == true ? (
                      <div
                        className="usage-head-label text-center"
                        onClick={() => {
                          changeActiveStatus(template, "active");
                          setactiveValidStatus("active");
                          setActiveArray(template);
                        }}
                      >
                        <span className="status-info active">Active</span>
                      </div>
                    ) : (
                      <div
                        className="usage-head-label text-center"
                        onClick={() => {
                          changeActiveStatus(template, "inactive");
                          setactiveValidStatus("inactive");
                          setActiveArray(template);
                        }}
                      >
                        <span className="status-info inactive">Inactive</span>
                      </div>
                    )}
                  </div>
                  {template.orgId !== undefined &&
                  template.orgId !== null &&
                  template.orgId !== "" ? (
                    template.orgId == userId || OrganiId == "" ? (
                      <>
                        <div className="usage-head-label text-center">
                          <div
                            className="usage-head-label text-center"
                            onClick={() => {
                              let message =
                                "you want to delete " + template.tid + " ?";
                              setconfirmmsg(message);
                              setDeleteStatus(true);
                              // deleteTemplate(template);
                              setDeleteArray(template);
                            }}
                          >
                            <span className="btn btn-sm btn-danger d-inline-block m-auto">
                              Delete
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="usage-head-label text-center">
                        <div
                          className="usage-head-label text-center"
                          // onClick={() => {
                          //   let message =
                          //     "you want to delete " + template.tid + " ?";
                          //   setconfirmmsg(message);
                          //   setDeleteStatus(true);
                          //   // deleteTemplate(template);
                          //   setDeleteArray(template);
                          // }}
                        >
                          <span className="status-info  custom-disabled">
                            Delete
                          </span>
                        </div>
                      </div>
                    )
                  ) : (
                    <>
                      <div className="usage-head-label text-center">
                        <div
                          className="usage-head-label text-center"
                          onClick={() => {
                            let message =
                              "you want to delete " + template.tid + " ?";
                            setconfirmmsg(message);
                            setDeleteStatus(true);
                            // deleteTemplate(template);
                            setDeleteArray(template);
                          }}
                        >
                          <span className="btn btn-sm btn-danger d-inline-block m-auto">
                            Delete
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  <div
                    className="usage-head-label text-center templatenamecut"
                    title={template.mediaUrl}
                  >
                    {template.orgId !== undefined &&
                      template.orgId !== null &&
                      template.orgId !== "" && (
                        <Tooltip title="Private Template" arrow>
                          <span className="locktemplatesvg">
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 330 330"
                              space="preserve"
                            >
                              <g id="XMLID_486_">
                                <path
                                  id="XMLID_487_"
                                  d="M165,330c63.411,0,115-51.589,115-115c0-29.771-11.373-56.936-30-77.379V85c0-46.869-38.131-85-85-85
                                        S80.001,38.131,80.001,85v52.619C61.373,158.064,50,185.229,50,215C50,278.411,101.589,330,165,330z M180,219.986V240
                                        c0,8.284-6.716,15-15,15s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
                                        C190,208.162,186.068,215.421,180,219.986z M110.001,85c0-30.327,24.673-55,54.999-55c30.327,0,55,24.673,55,55v29.029
                                        C203.652,105.088,184.91,100,165,100c-19.909,0-38.651,5.088-54.999,14.028V85z"
                                />
                              </g>
                            </svg>
                          </span>
                        </Tooltip>
                      )}
                    {FileName}
                  </div>

                  <div className="usage-head-label text-center">
                    {template.no_of_ost_h}
                  </div>
                  <div className="usage-head-label text-center">
                    {template.no_of_ost_p}
                  </div>
                  <div className="usage-head-label text-center">
                    {template.no_of_ost_b}
                  </div>
                  <div className="usage-head-label text-center">
                    {template.no_of_images}
                  </div>
                  <div className="usage-head-label text-center">
                    {template.no_of_illustrations}
                  </div>
                  <div className="usage-head-label text-center">
                    {template.no_of_videos}
                  </div>

                  <div className="usage-head-label list-image-label">
                    {template.previewUrl != null &&
                    template.thumbnailUrl != null &&
                    template.previewUrl != "" &&
                    template.thumbnailUrl != "" ? (
                      <>
                        <div className="list-image">
                          <img src={template.thumbnailUrl} />{" "}
                          <span
                            onClick={() => {
                              setthumbnailUrl(template.thumbnailUrl);
                              setpreviewUrl(template.previewUrl);
                              setopenPreviewModals(true);
                            }}
                            title="Play video"
                          >
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 485 485"
                            >
                              <g>
                                {" "}
                                <path
                                  d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5
        s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026
        C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5
        S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z"
                                />{" "}
                                <polygon points="181.062,336.575 343.938,242.5 181.062,148.425  " />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </>
                    ) : (
                      <button
                        className="btn btn-sm btn-secondary d-inline-block m-auto"
                        onClick={onPrviewClick.bind(null, template)}
                        type="button"
                      >
                        Preview
                      </button>
                    )}
                  </div>
                  <div className="usage-head-label list-image-label">
                    {showRender(template)}
                  </div>
                  <div className="usage-head-label text-center">
                    {new Date(template.addedDate).getDate() +
                      "-" +
                      (new Date(template.addedDate).getMonth() + 1) +
                      "-" +
                      new Date(template.addedDate).getFullYear()}
                  </div>
                  <div
                    className="usage-head-label text-center url-info"
                    title={template.emailid}
                  >
                    {template.emailid}
                  </div>
                  <div className="usage-head-label text-center">
                    {template.previewDate == null
                      ? "-"
                      : new Date(template.previewDate).getDate() +
                        "-" +
                        (new Date(template.previewDate).getMonth() + 1) +
                        "-" +
                        new Date(template.previewDate).getFullYear()}
                  </div>
                  <div
                    className="usage-head-label text-center url-info"
                    title={
                      template.previewby === null ? "-" : template.previewby
                    }
                  >
                    {template.previewby === null ? "-" : template.previewby}
                  </div>
                  <div
                    className="usage-head-label text-center url-info"
                    title={template.renderBy === null ? "-" : template.renderBy}
                  >
                    {template.renderBy === null ? "-" : template.renderBy}
                  </div>
                  <div className="usage-head-label text-center">
                    {template.renderDate == null
                      ? "-"
                      : new Date(template.renderDate).getDate() +
                        "-" +
                        (new Date(template.renderDate).getMonth() + 1) +
                        "-" +
                        new Date(template.renderDate).getFullYear()}
                  </div>
                  <div
                    className="usage-head-label text-center url-info"
                    title={template.statusBy === "" ? "-" : template.statusBy}
                  >
                    {template.statusBy === "" ? "-" : template.statusBy}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };
  const ListTemplates = (data) => {
    return (
      <div className="table-wraper">
        <div className="usage-list-warper template-listing">
          <div className="usage-block mb-3 sticky-head mt-0">
            <div className="usage-head-label ">Tid</div>
            <div className="usage-head-label text-center">Status</div>
            <div className="usage-head-label text-center">Delete</div>
            <div className="usage-head-label text-center">Template Name</div>
            <div className="usage-head-label text-center">No of ost H</div>
            <div className="usage-head-label text-center">No of ost P</div>
            <div className="usage-head-label text-center">No of ost B</div>
            <div className="usage-head-label text-center">No of images</div>
            <div className="usage-head-label text-center">
              No of Illustrations
            </div>
            <div className="usage-head-label text-center">No of videos</div>
            <div className="usage-head-label text-center">Preview</div>
            <div className="usage-head-label text-center">Render</div>
            <div className="usage-head-label text-center">Added date</div>
            <div className="usage-head-label text-center">Status By</div>
          </div>
          {data != undefined &&
            data.map((template, i) => {
              let Name = template.mediaUrl.split("/")[5];
              let FileName = Name.split(".")[0];
              return (
                <div key={i} className="usage-block usg-sub-block mb-3">
                  <div className="usage-head-label ">{template.tid}</div>
                  <div className="usage-head-label text-center">
                    {template.isActive == true ? (
                      <div
                        className=""
                        onClick={() => {
                          changeActiveStatus(template, "active");
                          setactiveValidStatus("active");
                          setActiveArray(template);
                        }}
                      >
                        <span className="status-info active">Active</span>
                      </div>
                    ) : (
                      <div
                        className=""
                        onClick={() => {
                          changeActiveStatus(template, "inactive");
                          setactiveValidStatus("inactive");
                          setActiveArray(template);
                        }}
                      >
                        <span className="status-info inactive">Inactive</span>
                      </div>
                    )}
                  </div>
                  {template.orgId !== undefined &&
                  template.orgId !== null &&
                  template.orgId !== "" ? (
                    template.orgId == userId || OrganiId == "" ? (
                      <>
                        <div className="usage-head-label text-center">
                          <div
                            className="usage-head-label text-center"
                            onClick={() => {
                              let message =
                                "you want to delete " + template.tid + " ?";
                              setconfirmmsg(message);
                              setDeleteStatus(true);
                              // deleteTemplate(template);
                              setDeleteArray(template);
                            }}
                          >
                            <span className="btn btn-sm btn-danger d-inline-block m-auto">
                              Delete
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="usage-head-label text-center">
                        <div
                          className="usage-head-label text-center"
                          // onClick={() => {
                          //   let message =
                          //     "you want to delete " + template.tid + " ?";
                          //   setconfirmmsg(message);
                          //   setDeleteStatus(true);
                          //   // deleteTemplate(template);
                          //   setDeleteArray(template);
                          // }}
                        >
                          <span className="status-info  custom-disabled">
                            Delete
                          </span>
                        </div>
                      </div>
                    )
                  ) : (
                    <>
                      <div className="usage-head-label text-center">
                        <div
                          className="usage-head-label text-center"
                          onClick={() => {
                            let message =
                              "you want to delete " + template.tid + " ?";
                            setconfirmmsg(message);
                            setDeleteStatus(true);
                            // deleteTemplate(template);
                            setDeleteArray(template);
                          }}
                        >
                          <span className="btn btn-sm btn-danger d-inline-block m-auto">
                            Delete
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    className="usage-head-label text-center templatenamecut"
                    title={template.mediaUrl}
                  >
                    {template.orgId !== undefined &&
                      template.orgId !== null &&
                      template.orgId !== "" && (
                        <Tooltip title="Private Template" arrow>
                          <span className="locktemplatesvg">
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 330 330"
                              space="preserve"
                            >
                              <g id="XMLID_486_">
                                <path
                                  id="XMLID_487_"
                                  d="M165,330c63.411,0,115-51.589,115-115c0-29.771-11.373-56.936-30-77.379V85c0-46.869-38.131-85-85-85
                                        S80.001,38.131,80.001,85v52.619C61.373,158.064,50,185.229,50,215C50,278.411,101.589,330,165,330z M180,219.986V240
                                        c0,8.284-6.716,15-15,15s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
                                        C190,208.162,186.068,215.421,180,219.986z M110.001,85c0-30.327,24.673-55,54.999-55c30.327,0,55,24.673,55,55v29.029
                                        C203.652,105.088,184.91,100,165,100c-19.909,0-38.651,5.088-54.999,14.028V85z"
                                />
                              </g>
                            </svg>
                          </span>
                        </Tooltip>
                      )}
                    {FileName}
                  </div>

                  <div className="usage-head-label text-center">
                    {template.no_of_ost_h}
                  </div>
                  <div className="usage-head-label text-center">
                    {template.no_of_ost_p}
                  </div>
                  <div className="usage-head-label text-center">
                    {template.no_of_ost_b}
                  </div>
                  <div className="usage-head-label text-center">
                    {template.no_of_images}
                  </div>
                  <div className="usage-head-label text-center">
                    {template.no_of_illustrations}
                  </div>
                  <div className="usage-head-label text-center">
                    {template.no_of_videos}
                  </div>
                  <div className="usage-head-label list-image-label">
                    {template.previewUrl != null &&
                    template.thumbnailUrl != null &&
                    template.previewUrl != "" &&
                    template.thumbnailUrl != "" ? (
                      <>
                        <div className="list-image">
                          <img src={template.thumbnailUrl} />{" "}
                          <span
                            onClick={() => {
                              setthumbnailUrl(template.thumbnailUrl);
                              setpreviewUrl(template.previewUrl);
                              setopenPreviewModals(true);
                            }}
                            title="Play video"
                          >
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 485 485"
                            >
                              <g>
                                {" "}
                                <path
                                  d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5
        s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026
        C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5
        S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z"
                                />{" "}
                                <polygon points="181.062,336.575 343.938,242.5 181.062,148.425  " />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </>
                    ) : (
                      <button
                        className="btn btn-sm btn-secondary d-inline-block m-auto"
                        onClick={onPrviewClick.bind(null, template)}
                        type="button"
                      >
                        Preview
                      </button>
                    )}
                  </div>
                  <div className="usage-head-label list-image-label">
                    {showRender(template)}
                  </div>
                  <div className="usage-head-label text-center">
                    {new Date(template.addedDate).getDate() +
                      "-" +
                      (new Date(template.addedDate).getMonth() + 1) +
                      "-" +
                      new Date(template.addedDate).getFullYear()}
                  </div>
                  <div
                    className="usage-head-label text-center url-info"
                    title={template.statusBy === "" ? "-" : template.statusBy}
                  >
                    {template.statusBy === "" ? "-" : template.statusBy}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const onSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    if (search && search.trim()) {
      setCurrentPage(1);
      setIsSearch((is) => !is);
      const params = new URLSearchParams(location.search);
      params.set("search", search.trim());
      history.push({
        pathname: "/templatelist",
        search: params.toString(),
      });
    }
  };

  const onSearchReset = (e) => {
    e.preventDefault();
    setSearch("");
    setCurrentPage(1);
    setIsSearch((is) => !is);
    const params = new URLSearchParams(location.search);
    params.delete("search");
    history.push({
      pathname: "/templatelist",
      search: params.toString(),
    });
  };

  const previewAll = (e) => {
    setlistAllPriviewTemplate(e.target.checked);
    setCurrentPage(1);
    const params = new URLSearchParams(location.search);
    if (e.target.checked) {
      params.set("allpriview", 1);
    } else {
      params.delete("allpriview");
    }
    history.push({
      pathname: "/templatelist",
      search: params.toString(),
    });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "gray",
      padding: 7,
    }),
    control: () => ({
      display: "flex",
      backgroundColor: DisplayMode ? "#2e2e2e" : "#f0efef",
      color: "#fff",
      height: "32px",
    }),
    singleValue: () => ({
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "12px",
      lineHeight: "18px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      zIndex: "999",
      color: "#fff",
    }),
    indicatorSeparator: () => ({
      border: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
  };

  const handleChangeStatus = (e) => {
    // alert()
    setListStatus(e);
    console.log(e, "listtt");
  };
  const handleActiveChangeStatus = (e) => {
    // alert()
    setListActiveStatus(e);
    console.log(e, "listtt");
  };
  useEffect(() => {
    // setlistAllPriviewTemplate(e.target.checked);
    setCurrentPage(1);
    const params = new URLSearchParams(location.search);
    // if (e.target.checked) {
    //   params.set("allpriview", 1);
    // } else {
    //   params.delete("allpriview");
    // }
    history.push({
      pathname: "/templatelist",
      search: params.toString(),
    });
  }, [listStatus]);
  useEffect(() => {
    // setlistAllPriviewTemplate(e.target.checked);
    setCurrentPage(1);
    const params = new URLSearchParams(location.search);
    // if (e.target.checked) {
    //   params.set("allpriview", 1);
    // } else {
    //   params.delete("allpriview");
    // }
    history.push({
      pathname: "/templatelist",
      search: params.toString(),
    });
  }, [listActiveStatus]);
  const generateActiveStatus = () => {
    if (listFilterActiveStatus != undefined) {
      let statusOption = [];
      listFilterActiveStatus.map((filter) => {
        console.log(filter);
        statusOption.push({
          value: filter.value,
          label: filter.value,
          status: filter.status,
        });
        return true;
      });
      setStatusActiveOption(statusOption);
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };
  useEffect(() => {
    generateActiveStatus();
    setListActiveStatus({
      value: "All",
      label: "All",
      status: 0,
    });
  }, [listFilterActiveStatus]);

  const FilterByActiveStatus = () => {
    let filerStatus = [
      {
        value: "All",
        status: 0,
      },
      {
        value: "Active template",
        status: 1,
      },
      {
        value: "InActive Template",
        status: 2,
      },
    ];

    setListActiveFilterStatus(filerStatus);
  };
  const generateStatus = () => {
    if (listFilterStatus != undefined) {
      let statusOption = [];
      listFilterStatus.map((filter) => {
        console.log(filter);
        statusOption.push({
          value: filter.value,
          label: filter.value,
          status: filter.status,
        });
        return true;
      });
      setStatusOption(statusOption);
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };

  useEffect(() => {
    generateStatus();
    setListStatus({
      value: "All",
      label: "All",
      status: 0,
    });
  }, [listFilterStatus]);

  const FilterByStatus = () => {
    let filerStatus = [
      {
        value: "All",
        status: 0,
      },
      {
        value: "Preview template",
        status: 1,
      },
      {
        value: "No Preview Template",
        status: 2,
      },
    ];

    setListFilterStatus(filerStatus);
  };
  const FilterByPublicStatus = () => {
    let filerStatus = [
      {
        value: "All",
        status: 0,
      },

      {
        value: "Public Template",
        status: 2,
      },
      {
        value: "Private Template",
        status: 3,
      },
    ];

    setListFilterPublicStatus(filerStatus);
  };
  const generatePublicStatus = () => {
    if (listFilterPublicStatus != undefined) {
      let statusOption = [];
      listFilterPublicStatus.map((filter) => {
        console.log(filter);
        statusOption.push({
          value: filter.value,
          label: filter.value,
          status: filter.status,
        });
        return true;
      });
      setStatusPublicOption(statusOption);
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };

  useEffect(() => {
    generatePublicStatus();
    setListPublicStatus({
      value: "All",
      label: "All",
      status: 0,
    });
  }, [listFilterPublicStatus]);
  const handleChangepublicStatus = (e) => {
    // alert()
    setListPublicStatus(e);
    console.log(e, "listtt");
    // setListUploadedStatus();
  };
  const handleChangeOrgStatus = (e) => {
    // alert()
    setListOrgStatus(e);
    console.log(e, "listtt");
  };
  const generateOrgStatus = () => {
    if (listOrgUsers != undefined) {
      let statusOption = [];
      listOrgUsers.map((filter) => {
        console.log(filter);
        statusOption.push({
          value: filter.emailid,
          label: filter.emailid,
          orgId: filter.userid,
        });
        return true;
      });
      setOrgOption(statusOption);
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };

  useEffect(() => {
    generateOrgStatus();
    // setListOrgStatus({
    //   value: "All",
    //   label: "All",
    //   orgId: 0,
    // });
    console.log(listOrgUsers, "listOrgUsers");
  }, [listOrgUsers]);

  const generateUploadedStatus = () => {
    console.log(listUploadedUsers, "listUploadedUsers");
    if (listUploadedUsers != undefined) {
      let statusOption = [];
      listUploadedUsers.map((filter) => {
        console.log(filter);
        statusOption.push({
          value: filter.emailid,
          label: filter.emailid,
          userId: filter.userid,
        });
        return true;
      });
      setUploadedOption(statusOption);
      // handleChange(fontOption[0])
      console.log(statusOption);
      // setfonts(fontOption);
    }
  };
  const handleChangeUploadedStatus = (e) => {
    // alert()
    setListUploadedStatus(e);
    // setListOrgStatus();
    console.log(e, "listtt");
  };
  useEffect(() => {
    generateUploadedStatus();
  }, [listUploadedUsers]);
  useEffect(() => {
    if (listPublicStatus.value == "Uploaded By") {
      setCheckUploadedDropdown(true);
      setPrivateTemDropdown(false);
    } else if (listPublicStatus.value === "Private Template") {
      setPrivateTemDropdown(true);
      setCheckUploadedDropdown(false);
    } else {
      setPrivateTemDropdown(false);
      setCheckUploadedDropdown(false);
    }
  }, [listPublicStatus]);
  const getOrganisationList = () => {
    let filter = {
      params: 0,
      keyword: "",
      sortCreateAt: "",
      sortUpdatedAt: "",
    };
    getOrganisation(filter).then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200 &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          // this.fontArray = response.data
          console.log(response.data);
          // let projectData = {
          //   orgName: "All",
          //   orgId: 0,
          // };
          // let orglist = [projectData, ...response.data.result];
          // console.log(orglist);
          // setListOrgUsers(orglist);
          //generateFonts();
        }
      } catch (error) {}
    });
  };
  const handleChangeValidStatus = (e) => {
    // alert()
    setListValidStatus(e);
    console.log(e, "listtt");
  };
  useEffect(() => {
    // setlistAllPriviewTemplate(e.target.checked);
    setCurrentPage(1);
    const params = new URLSearchParams(location.search);
    // if (e.target.checked) {
    //   params.set("allpriview", 1);
    // } else {
    //   params.delete("allpriview");
    // }
    history.push({
      pathname: "/templatelist",
      search: params.toString(),
    });
  }, [listValidStatus]);

  const generateValidStatus = () => {
    if (listFilterValidStatus != undefined) {
      let statusOption = [];
      listFilterValidStatus.map((filter) => {
        console.log(filter);
        statusOption.push({
          value: filter.value,
          label: filter.value,
          status: filter.status,
        });
        return true;
      });
      setStatusvalidOption(statusOption);
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };

  useEffect(() => {
    generateValidStatus();
    setListValidStatus({
      value: "All",
      label: "All",
      status: 0,
    });
  }, [listFilterValidStatus]);

  const FilterByValidStatus = () => {
    let filerStatus = [
      {
        value: "All",
        status: 0,
      },
      {
        value: "Rendered",
        status: 1,
      },
      {
        value: "Not Render",
        status: 3,
      },
      {
        value: "Invalid Template",
        status: 2,
      },
    ];

    setListFilterValidStatus(filerStatus);
  };

  const onListAll = (e) => {
    setlistAllTemplate(e.target.checked);
    setCurrentPage(1);
    const params = new URLSearchParams(location.search);
    if (e.target.checked) {
      params.set("all", 1);
    } else {
      params.delete("all");
    }
    history.push({
      pathname: "/templatelist",
      search: params.toString(),
    });
  };

  // const searchBar = () => {
  //   return (
  //     <div>
  //       <input
  //         type="text"
  //         onKeyUp={(e) => {
  //           console.log(e.target.value);
  //           setsearchfield(e.target.value);
  //         }}
  //       />
  //     </div>
  //   );
  // };

  return (
    <>
      <div className={DisplayMode ? "theme dark" : "theme"}>
        {loading && <Loader />}
        <div className="main-wrapper">
          <Header />
          <PreviewModal
            closePreview={() => setopenPreviewModals(false)}
            openPreviewModals={openPreviewModals}
            previewUrl={previewUrl}
            thumbnailUrl={thumbnailUrl}
          />

          <div className="template-wrapper pb-wrapper">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="d-flex align-items-center">
                  {/* {TemplateFeature == true && ( */}
                  {(AdminRole == 1 || AdminRole == 0) &&
                    OrganiId == "621c84953a7e2941763a1f35" && (
                      <div className="p-2 flex-fill view-all">
                        <input
                          type="checkbox"
                          checked={listAllTemplate}
                          onChange={onListAll}
                        />{" "}
                        View all template
                      </div>
                    )}
                  {AdminRole == 0 && OrganiId == "" && (
                    <div className="p-2 flex-fill view-all">
                      <input
                        type="checkbox"
                        checked={listAllTemplate}
                        onChange={onListAll}
                      />{" "}
                      View all template
                    </div>
                  )}

                  <div className="px-2 flex-fill">
                    <Select
                      className="custom-input rolluser w-100 "
                      styles={customStyles}
                      value={listActiveStatus}
                      onChange={(e) => handleActiveChangeStatus(e)}
                      options={statusActiveOption}
                      isSearchable={false}
                    />
                  </div>
                  <div className="px-2 flex-fill">
                    <Select
                      className="custom-input rolluser w-100 "
                      styles={customStyles}
                      value={listStatus}
                      onChange={(e) => handleChangeStatus(e)}
                      options={statusOption}
                      isSearchable={false}
                    />
                  </div>
                  <div className="px-2 flex-fill">
                    <Select
                      className="custom-input rolluser w-100 "
                      styles={customStyles}
                      value={listValidStatus}
                      onChange={(e) => handleChangeValidStatus(e)}
                      options={statusValidOption}
                      isSearchable={false}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="d-flex align-items-center justify-content-end">
                  <div className="pb-srch-customiser  me-2 mb-0 me-md-3">
                    <div className="custom-search srch-holder w-100 h-100">
                      <div className="srch-icon">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M21.9243 21.925L27.9994 28.0001"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="srch-inpt-holder h-100">
                        <form
                          onSubmit={onSearchSubmit}
                          onReset={onSearchReset}
                          className="h-100"
                        >
                          <input
                            type="text"
                            name="focus"
                            value={search}
                            className="pe-0 form-control srch-input h-100 "
                            placeholder="Press enter to search by template name or id"
                            onChange={onSearch}
                          />
                          <button className="search-close" type="reset">
                            <svg
                              version="1.1"
                              id="Layer_1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                            >
                              <g>
                                <g>
                                  <path
                                    d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M327.115,365.904
                          L256,294.789l-71.115,71.115l-38.789-38.789L217.211,256l-71.115-71.115l38.789-38.789L256,217.211l71.115-71.115l38.789,38.789
                          L294.789,256l71.115,71.115L327.115,365.904z"
                                  />
                                </g>
                              </g>
                            </svg>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="p-2 flex-fill text-right total-template-count fs-14 me-2">
                      Total Template Count : {templateCount}
                    </div>

                    <button
                      className="btn btn-sm btn-secondary d-inline-block m-auto"
                      type="button"
                    >
                      <CSVLink
                        headers={headers}
                        data={userData}
                        filename={fileName}
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        Export
                      </CSVLink>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-2 flex-fill view-all fs-14">
              Advanced Filter
              <Switch
                //  className="react-switch"
                // disabled={listUsers.id == 0 ? true : false}
                checked={checkedCreateProject ? true : false}
                onChange={(e) => setCreateProjectEnable(e)}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
            <div className="row mb-2">
              {advanceFilterEnable && (
                // <div className="p-2 flex-fill view-all">
                <div className="col-4">
                  <Select
                    className="custom-input rolluser w-100 "
                    styles={customStyles}
                    value={listPublicStatus}
                    onChange={(e) => handleChangepublicStatus(e)}
                    options={statusPublicOption}
                    isSearchable={false}
                  />
                </div>
                // </div>
              )}
              {privateTemDropdown && advanceFilterEnable && (
                <div className="col-4">
                  <Select
                    className="custom-input rolluser w-100 "
                    styles={customStyles}
                    value={listOrgStatus}
                    onChange={(e) => handleChangeOrgStatus(e)}
                    options={orgOption}
                    isSearchable={listAllTemplate ? true : false}
                  />
                </div>
              )}
              {advanceFilterEnable &&
                !privateTemDropdown &&
                listPublicStatus.status == 2 && (
                  <div className="col-4">
                    <Select
                      className="custom-input rolluser w-100 "
                      styles={customStyles}
                      value={listUploadedStatus}
                      onChange={(e) => handleChangeUploadedStatus(e)}
                      options={uploadedOption}
                      isSearchable={listAllTemplate ? true : false}
                    />
                  </div>
                )}
            </div>

            {templateList.length > 0 ? (
              listAllTemplate ? (
                <>{ListAllTemplates(templateList)}</>
              ) : (
                <>{ListTemplates(templateList)}</>
              )
            ) : (
              <div className="no-data">
                <p>No template available</p>
              </div>
            )}
            <div className="row pagination-holder mb-0 justify-content-center">
              <div className="col-auto">
                <Pagination
                  onChange={paginate}
                  page={currentPage}
                  shape="rounded"
                  variant="outlined"
                  color="primary"
                  count={Math.ceil(templateCount / perPage)}
                />
              </div>
            </div>
          </div>
          {/* {templateList != undefined ? (
            
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Tid</th>
                  <th scope="col">MediaUrl</th>
                  <th scope="col">no_of_ost_h</th>
                  <th scope="col">no_of_ost_p</th>
                </tr>
              </thead>
              <tbody>
                {templateList.result.data.length > 0 &&
                  templateList.result.data.map((template) => {
                    return (
                      <tr>
                        <th scope="row">{template.tid}</th>
                        <td>{template.mediaUrl}</td>
                        <td>{template.no_of_ost_h}</td>
                        <td>{template.no_of_ost_p}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <p>No data</p>
          )} */}
        </div>
      </div>
      <RenderTemplate
        closePreview={() => setOpenRenderTemplate(false)}
        openRenderVideo={openRenderTemplate}
        template={selectedTemplate}
        updateRenderStatus={updateRenderStatus}
      />

      <PriviewTemplate
        closePreview={() => setOpenPriviewTemplate(false)}
        openPriviewVideo={openPriviewTemplate}
        template={selectedTemplate}
        setlistAllTemplates={() => setlistPriviewTemplate(true)}
        // updateRenderStatus={updateRenderStatus}
      />
      {/* <CustomModal
        isOpen={activeStatus}
        Closemodal={setActiveStatus}
        Header="Template validation"
        Content="Preview or Render is not generated for this template"
        Buttonlabel1="Ok"
        Buttonlabel2="Cancel"
        Buttonclick={setActiveStatus}
      /> */}
      <SaveChangeModal
        isOpen={activeStatus}
        Closemodal={() => setActiveStatus(false)}
        Header="Template validation"
        Content="Preview or Render is not generated for this template"
        Buttonlabel1="Cancel"
        Buttonclick1={() => setActiveStatus(false)}
        Buttonlabel2="Ok"
        Buttonclick2={() => changeActiveTemplate()}
      />

      <SaveChangeModal
        isOpen={deleteStatus}
        Closemodal={() => setDeleteStatus(false)}
        Header="Delete Template"
        Content={confirmmsg}
        Buttonlabel1="Cancel"
        Buttonclick1={() => setDeleteStatus(false)}
        Buttonlabel2="Delete"
        Buttonclick2={() => deleteTemplate()}
      />
      {/* <ExportCsv headers={headers} userData={userData} /> */}
    </>
  );
}

export default TemplateList;
