import { Form, Dropdown, FloatingLabel } from 'react-bootstrap';
import React, { useState, forwardRef, Children, useEffect } from 'react';

import { arrowDownIcon } from '../../../constants/icon';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    tabIndex={0}
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = forwardRef(
  ({ children, style, className, value, 'aria-labelledby': labeledBy }, ref) => {
    // const [value, setValue] = useState('');

    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <ul className="list-unstyled mb-0">
          {Children.toArray(children).filter(
            child => !value || child.props.children.toLowerCase().includes(value)
          )}
        </ul>
      </div>
    );
  }
);

const sampleItems = [];

export default function DropdownCommon({
  label,
  className,
  name = '',
  title = '',
  defaultValue = '',
  items = sampleItems,
  onSelectItem = () => {},
}) {
  const listRef = React.useRef(null);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState('');
  const [menuValue, setMenuValue] = useState('');
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  const onSelect = (_, e) => {
    setSelected(e.target.text);
    onSelectItem(e.target.text);
  };
  const onToggle = show => {
    setShow(show);
    if (!show) {
      setMenuValue('');
    }
  };
  const onInputChange = e => {
    const val = e.target.value ? e.target.value.trim().toLowerCase() : '';
    setSelected(val);
    setMenuValue(val);
    setShow(true);
  };

  return (
    <Dropdown
      show={show}
      onSelect={onSelect}
      onToggle={onToggle}
      // onKeyDown={handleKeyDown}
      className={`dropdown-select custom-select-dropdown ${className || ''}`}
    >
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <FloatingLabel
          className="w-100"
          controlId="floatingSelection"
          label={label ? label : 'Choose Option'}
        >
          <Form.Control
            type="text"
            value={selected}
            autoComplete="off"
            placeholder="Events"
            name={name || title}
            onChange={onInputChange}
          />
          <div className="caret-icon">{arrowDownIcon}</div>
        </FloatingLabel>
      </Dropdown.Toggle>
      <Dropdown.Menu value={menuValue} as={CustomMenu} ref={listRef}>
        {items &&
          items.map((item, i) => (
            <Dropdown.Item key={i} eventKey={i} active={selected === item.name} title={item.label || item.value  || item.name || item.title}>
              {item.label || item.value  || item.name || item.title}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}