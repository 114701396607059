import React from 'react';

export const CustomDropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    className="common-dropdown-filter"
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
    ref={ref}
  >
    {children}
  </div>
));
