
const truncateWords = (text, maxCharacters) => {
  if (text.length <= maxCharacters) {
    return text;
  } else {
    return text.slice(0, maxCharacters - 3) + '...';
  }
};

export default truncateWords;
