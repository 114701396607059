import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AudioUplaod from "../RightBar/AudioUpload";
import { actionCreator } from "../../../../store";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import Wavesurfer from "react-wavesurfer";
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress } from "@mui/material";
import CustomModal from "../../../../services/CustomModal";
import Waveform from "./Waveform";
import {
  createProjectsPreview,
} from "../../../../services/api.service";
import {
  getUserItem,
  showToaster,
  ShowAlertBox,
} from "../../../../services/helper";
import SaveDataWarningModal from "./SaveDataWarningModal";
import { getProjectById } from "../../../../services/authService";
import { BULLETFONT, HEADERFONT, PARAGRAPHFONT } from "../../../../constants";
import { removeMediaUrlFilter } from "../../../../services/DataHelper";

export default function AudioScript(props) {
  const [pageLoaded, setPageLoaded] = useState(false);
  const project = useSelector((state) => state.preview.previewProject);
  const projectId = useSelector((state) => state.preview.ProjectId);
  const ActiveModule = useSelector((state) => state.preview.ActiveModuleId);
  const ActiveSlide = useSelector((state) => state.preview.ActiveSlideId);
  const ActiveScreen = useSelector((state) => state.preview.ActiveScreenId);
  const [audioScriptValue, setAudioScriptValue] = useState("");
  const [audioScriptValidation, setAudioScriptValidation] = useState(false);
  const [audioScriptError, setAudioScriptError] = useState("");
  const [openAudioUpload, setAudioUpload] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [audiError, setAudiError] = useState(false);
  const [pos, setPos] = useState(0);
  const [audioFile, setAudioFile] = useState("");
  const dispatch = useDispatch();
  const [audioDuration, setAudioDuration] = useState("");
  const [audioReady, setaudioReady] = useState(false);
  let { UpdatePreviewFeature, UpdateAudioScriptUpload } = bindActionCreators(actionCreator, dispatch)
  const [confirmmsg, setconfirmmsg] = useState("");
  const [confirmbtnlabel, setconfirmbtnlabel] = useState("");
  const [openAudioModal, setopenAudioModal] = useState(false);
  const [toggleAcc, setToggleAcc] = useState(false)
  const [readOnlyField, setreadOnlyField] = useState(true);
  const [prevOstValue, setprevOstValue] = useState(undefined);
  const [cacheProjectData, setcacheProjectData] = useState(undefined)

  let COUNT = 0;

  const countPlusOne = () => {
    // let  count
    COUNT += 1;
    let result = COUNT.toString();
    return result;
  };

  useEffect(() => {
    updateState()
  }, [])
  useEffect(() => {
    updateState()
  }, [ActiveModule, ActiveSlide, ActiveScreen])

  useEffect(() => {
    if (audioScriptValidation) {
      setTimeout(() => {
        setAudioScriptValidation(false)
      }, 3000);
    }
  }, [audioScriptValidation])
  const viewImagePriview = () => {
    setPlaying(false)
    setAudioUpload(true);
  };
  const updateState = () => {
    let activeScreenDetails = project[projectId]['Modules'][ActiveModule]['slides'][ActiveSlide]['Screens'][ActiveScreen]
    Object.entries(activeScreenDetails).map((item, key) => {
      let activeAudio = item.filter(function (data) { return data.Contenttype === "AudioScript"; })
      //console.log(activeAudio, "activeAudio");
      if (activeAudio.length > 0) {
        let audioTemp = activeAudio[0]['Contentparams']['contentdescription'];
        // audioValueArray.push(audioTemp)
        // this.setState({ audioScriptValue: audioTemp })
        setAudioScriptValue(audioTemp)
        //console.log(audioTemp, "audioScriptValue");
      } return item
    })
  }
  useEffect(() => {
    if (
      project[projectId]?.["Modules"]?.[ActiveModule]?.["slides"]?.[ActiveSlide]?.[
      "SlideAudioVO"
      ] !== "Null"
    ) {
      setPlaying(false);
      setAudioFile(
        project[projectId]?.["Modules"]?.[ActiveModule]?.["slides"]?.[ActiveSlide]?.[
        "SlideAudioVO"
        ]
      );
    }

  }, [project, toggleAcc]);

  const audioScriptChange = (value) => {
    //console.log(props);
    // let value = e.target.value
    const regMatch = /^[\.a-zA-Z,' ]*$/.test(value);
    if (regMatch === false) {
      props.setASValidation(true)
      setAudioScriptValidation(true)
    } else {
      props.setASValidation(false)
      setAudioScriptValidation(false)
      UpdatePreviewFeature({
        AS01: {
          Contenttype: "AudioScript",
          Contentparams: {
            sequenceid: "2",
            contentdescription: value,
            contenttag: ""
          }
        }
      })
      setAudioScriptValue(value);
    }


    //console.log(audioScriptValue);

    // this.setState({
    //     audioScriptValue: e.target.value
    // }, () => this.updateJson('audio'));

  }
  useEffect(() => {
    updateJson('audio')
  }, [audioScriptValue])
  const updateJson = (type, i) => {
    // this.setState({ colorCodeError: "" })
    // this.setState({ colorCodeError1: "" })
    let activeScreenDetails = project[projectId]['Modules'][ActiveModule]['slides'][ActiveSlide]['Screens'][ActiveScreen]
    let audioStatus = false;
    let nextSequenceID = 1
    let audioValid = false;
    let a;
    Object.entries(activeScreenDetails).map((item, key) => {
      let index = item[0]
      if (item[0] === 'AS01') {
        audioStatus = true
        var audiovalidStatus = true
        if (audioScriptValidation) {
          audiovalidStatus = false
        }
        if (audiovalidStatus === true) {
          audioValid = true
          setAudioScriptError("")
          if (activeScreenDetails[index]['Contentparams']['contentdescription'] !== audioScriptValue) {
            activeScreenDetails[index]['Contentparams']['contentdescription'] = audioScriptValue

          }
        } else {
          audioValid = false
          if (type === 'audio') {
            setAudioScriptError("Accepts only alphabetic characters")
          }

        }
      }
      project[projectId]['Modules'][ActiveModule]['slides'][ActiveSlide]['Screens'][ActiveScreen] = activeScreenDetails
      // this.props.updateJson(modules)
      //console.log(project, "project");

      return true;
    })
  }

  const handleTogglePlay = () => {
    setPlaying(!playing);
  };

  const handlePosChange = (e) => {
    setPos(e.originalArgs[0]);
  };
  const handleReady = (args) => {
    setaudioReady(true)
    let time = args.wavesurfer.getDuration();
    var hrs = Math.floor(time / 3600);
    var mins = Math.floor((time % 3600) / 60);
    var secs = Math.floor(time % 60);
    var ret = "";
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    // this.setState({ audioDuration: ret })
    setAudioDuration(ret);
  };

  const errorAudioFile = () => {
    // setShowMicOptions(false);
    setAudiError(false);
    setPlaying(false);
    setPos(0);
  };
  const removeAudio = async () => {
    if (project !== undefined) {
      let allModule = project; 
      allModule[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide][
        "SlideAudioVO"
      ] = "Null";
      setPageLoaded(true);

      let projectDatafromDb = await getProjectById(projectId);
      if (projectDatafromDb.data.data.length > 0) {
        setcacheProjectData(
          JSON.parse(projectDatafromDb.data.data[0].ProjectData)
        );
      }
      let cachedData = JSON.parse(projectDatafromDb.data.data[0].ProjectData);
      let projectData = allModule[projectId]["Modules"];
      Object.entries(projectData).map((moduleData, moduleIndex) => {
        let mData = moduleData[1];
        let slides = mData.slides;
        //console.log(mData);
        Object.entries(slides).map((slideData, moduleIndex) => {
          let slide = slideData[1];
          let screens = slide.Screens;
          Object.entries(screens).map((screenData, moduleIndex) => {
            console.log(screenData);
            let _arr = [];
            let slide = screenData[1];
            let screenEntries = Object.entries(slide);
            screenEntries.sort((a, b) => {
              const isTa = /^TM/.test(a[0]);
              const isTb = /^TM/.test(b[0]);
              
              if(isTa && !isTb) {
                return 1;
              }
              if(isTb && !isTa) {
                return -1;
              }
              return 0;
            })
            screenEntries.map((item, key) => {
              //console.log(cachedData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[screenData[0]]);
              let oldScreendata = undefined;
              if (cachedData[projectId].Modules != undefined) {
                if (cachedData[projectId].Modules[moduleData[0]] != undefined) {
                  console.log(
                    cachedData[projectId].Modules[moduleData[0]].slides[
                      slideData[0]
                    ],
                    "slides"
                  );
                  if (
                    cachedData[projectId].Modules[moduleData[0]].slides[
                      slideData[0]
                    ] != undefined
                  ) {
                    oldScreendata =
                      cachedData[projectId].Modules[moduleData[0]].slides[
                        slideData[0]
                      ].Screens[screenData[0]];
                  }
                }
              }
              console.log(oldScreendata);

              item.map((element, index) => {
                if (element.Contenttype === "OST") {
                  if (element["Contentparams"]["FontColour"].length === 8) {
                    element["Contentparams"]["FontColour"] =
                      element["Contentparams"]["FontColour"].split("*")[0];
                  }
                  console.log(
                    element["Contentparams"]["contentdescription"],
                    "popopopopo"
                  );
                  if (
                    element["Contentparams"]["contentdescription"].includes(
                      "[H]"
                    )
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"].split(
                        "[H]"
                      )[0];
                    element["Contentparams"]["fontTag"] = HEADERFONT;
                  }

                  if (
                    element["Contentparams"]["contentdescription"].includes(
                      "[P]"
                    )
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"].split(
                        "[P]"
                      )[0];
                    element["Contentparams"]["fontTag"] = PARAGRAPHFONT;
                  }
                  if (
                    element["Contentparams"]["contentdescription"].includes(
                      "[B]"
                    )
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"].split(
                        "[B]"
                      )[0];
                    element["Contentparams"]["fontTag"] = BULLETFONT;                      
                  }

                  if (
                    element["Contentparams"]["fontTag"] === HEADERFONT ||
                    element["Contentparams"]["fontTag"] === undefined
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"] + "[H]";
                  } else if (
                    element["Contentparams"]["fontTag"] === PARAGRAPHFONT
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"] + "[P]";
                  } else if (
                    element["Contentparams"]["fontTag"] === BULLETFONT
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"] + "[B]";
                  }
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  // if (element['Contentparams']['contentdescription'] === "") {
                  //     delete slide[item[0]]
                  // console.log(element['Contentparams']["fontTag"]);

                  // }
                  if (element["Contentparams"]["fontTag"] !== "") {
                    delete element["Contentparams"]["fontTag"];
                  }
                  //console.log(element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription);
                  console.log(oldScreendata);
                  if (oldScreendata) {
                    console.log("11111");
                    if (oldScreendata[item[0]]) {
                      console.log("22222");
                      if (
                        element["Contentparams"]["contentdescription"] !=
                        oldScreendata[item[0]].Contentparams.contentdescription
                      ) {
                        _arr.push(1);
                      }
                      if (
                        element["Contentparams"]["FontColour"] !=
                        oldScreendata[item[0]].Contentparams.FontColour
                      ) {
                        _arr.push(1);
                      }
                      if (
                        element["Contentparams"]["FontFamily"] !=
                        oldScreendata[item[0]].Contentparams.FontFamily
                      ) {
                        _arr.push(1);
                      }
                      if (
                        element["Contentparams"]["FontSizeH"] !=
                        oldScreendata[item[0]].Contentparams.FontSizeH
                      ) {
                        _arr.push(1);
                      }
                      if (
                        element["Contentparams"]["FontSizeP"] !=
                        oldScreendata[item[0]].Contentparams.FontSizeP
                      ) {
                        _arr.push(1);
                      }
                    }
                  }
                  if (element["Contentparams"]["contenttag"] === "") {
                    element["Contentparams"]["contenttag"] = "Null";
                    return element;
                  }

                  // this.setState({ projectInputs: projectInputs })
                  return element;
                }
                if (element.Contenttype === "Background") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  return element;
                }
                // console.log(oldScreendata[item[0]]);
                if (element.Contenttype === "AudioScript") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  // if (element['Contentparams']['contentdescription'] === "") {
                  //     delete slide["AS01"]

                  // }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]]) {
                      if (
                        element["Contentparams"]["contentdescription"] !=
                        oldScreendata[item[0]].Contentparams.contentdescription
                      ) {
                        _arr.push(1);
                      }
                    }
                  }
                  if (element["Contentparams"]["contenttag"] === "") {
                    element["Contentparams"]["contenttag"] = "Null";
                    return element;
                  }
                  return element;
                }

                if (element.Contenttype === "Image") {
                  if(element["Contentparams"]["searchstring"]) {
                    element["Contentparams"]["searchstring"] = element["Contentparams"]["searchstring"].trim();
                  }
                  if(element["Contentparams"]["contenttag"]) {
                    element["Contentparams"]["contenttag"] = element["Contentparams"]["contenttag"].trim();
                  }
                  // console.log(slide, "slide");
                  //element["Contentparams"]["contentdescription"] = "";
                  if (
                    element["Contentparams"]["contenttag"] === "" &&
                    element["Contentparams"]["searchstring"] === ""
                  ) {
                    delete slide[item[0]];
                  } else {
                    element["Contentparams"]["sequenceid"] = countPlusOne();
                  }
                  if (
                    element["Contentparams"]["contenttag"] === "" ||
                    element["Contentparams"]["contenttag"] === []
                  ) {
                    element["Contentparams"]["contenttag"] = "Null";
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                        if ((element["Contentparams"]["searchstring"] != oldScreendata[item[0]].Contentparams.searchstring) ||
                        (element["Contentparams"]["contenttag"] != oldScreendata[item[0]].Contentparams.contenttag) ||
                        (element["Contentparams"]["contentdescription"] && oldScreendata[item[0]].Contentparams.contentdescription && element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription)
                    ) {
                            _arr.push(1)
                        }
                    } else if(element["Contentparams"]["searchstring"] || element["Contentparams"]["contenttag"] !== "Null") {
                        _arr.push(1)
                    }
                  }
                  return element;
                }
                if (element.Contenttype === "Video") {
                  if(element["Contentparams"]["searchstring"]) {
                    element["Contentparams"]["searchstring"] = element["Contentparams"]["searchstring"].trim();
                  }
                  if(element["Contentparams"]["contenttag"]) {
                    element["Contentparams"]["contenttag"] = element["Contentparams"]["contenttag"].trim();
                  }
                  // console.log(slide, "slide");
                  //element["Contentparams"]["contentdescription"] = "";
                  // console.log(oldScreendata[item[0]]);
                  if (
                    element["Contentparams"]["contenttag"] === "" &&
                    element["Contentparams"]["searchstring"] === ""
                  ) {
                    delete slide[item[0]];
                  } else {
                    element["Contentparams"]["sequenceid"] = countPlusOne();
                  }
                  if (
                    element["Contentparams"]["contenttag"] === "" ||
                    element["Contentparams"]["contenttag"] === []
                  ) {
                    element["Contentparams"]["contenttag"] = "Null";
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                        if ((element["Contentparams"]["searchstring"] != oldScreendata[item[0]].Contentparams.searchstring) ||
                        (element["Contentparams"]["contenttag"] != oldScreendata[item[0]].Contentparams.contenttag) ||
                        (element["Contentparams"]["contentdescription"] && oldScreendata[item[0]].Contentparams.contentdescription && element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription)
                    ) {
                            _arr.push(1)
                        }
                    } else if(element["Contentparams"]["searchstring"] || element["Contentparams"]["contenttag"] !== "Null") {
                        _arr.push(1)
                    }
                  }
                  return element;
                }
                if (element.Contenttype === "TemplateID") {
                  // console.log(element['Contentparams']['useTemplateStatus'],"hariiiiiiiii");
                  //console.log(element["Contentparams"]["contentdescription"]);
                  //element["Contentparams"]["contentdescription"] = "";
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                        if (
                        (element["Contentparams"]["contentdescription"] && oldScreendata[item[0]].Contentparams.contentdescription && element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription)
                        ) {
                            _arr.push(1)
                        }
                    }
                  }
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  if (element["Contentparams"]["useTemplateStatus"] !== "") {
                    delete element["Contentparams"]["useTemplateStatus"];
                    return element;
                  }
                  return element;
                }
                if (element.Contenttype === "ColourScheme") {
                  
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  if (
                    element["Contentparams"]["contentdescription"].length === 8
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"].split(
                        "*"
                      )[0];
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (
                        element["Contentparams"]["contentdescription"] !=
                        oldScreendata[item[0]].Contentparams.contentdescription
                      ) {
                        _arr.push(1);
                      }
                    }
                  }
                  return element;
                }
                if (element.Contenttype === "Illustration") {
                  if(element["Contentparams"]["searchstring"]) {
                    element["Contentparams"]["searchstring"] = element["Contentparams"]["searchstring"].trim();
                  }
                  if(element["Contentparams"]["contenttag"]) {
                    element["Contentparams"]["contenttag"] = element["Contentparams"]["contenttag"].trim();
                  }
                  //element["Contentparams"]["contentdescription"] = "";
                  if (
                    element["Contentparams"]["contenttag"] === "" &&
                    element["Contentparams"]["searchstring"] === ""
                  ) {
                    delete slide[item[0]];
                    // return element;
                  } else {
                    element["Contentparams"]["sequenceid"] = countPlusOne();
                  }

                  if (
                    element["Contentparams"]["contenttag"] === "" ||
                    element["Contentparams"]["contenttag"] === []
                  ) {
                    element["Contentparams"]["contenttag"] = "Null";
                    // return element;
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                        if ((element["Contentparams"]["searchstring"] != oldScreendata[item[0]].Contentparams.searchstring) ||
                        (element["Contentparams"]["contenttag"] != oldScreendata[item[0]].Contentparams.contenttag) ||
                        (element["Contentparams"]["contentdescription"] && oldScreendata[item[0]].Contentparams.contentdescription && element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription)
                    ) {
                            _arr.push(1)
                        }
                    } else if(element["Contentparams"]["searchstring"] || element["Contentparams"]["contenttag"] !== "Null") {
                        _arr.push(1)
                    }
                  }
                  return element;
                }
                //console.log(element, "elemetttsssss");
                if (element.Contenttype === "ScreenDuration") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  if (element["Contentparams"]["contenttag"] === "") {
                    element["Contentparams"]["contenttag"] = "Null";
                    // return element;
                  }
                  if (element["Contentparams"]["contentdescription"] === "") {
                    element["Contentparams"]["contentdescription"] = "7";
                    // return element;
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (
                        element["Contentparams"]["contentdescription"] !=
                        oldScreendata[item[0]].Contentparams.contentdescription
                      ) {
                        _arr.push(1);
                      }
                    }
                  }
                  return element;
                }

                console.log(element);
                return element;
              });
              if (slide["mediaurl"]) {
                console.log("errr");
                console.log(
                  projectData[moduleData[0]].slides[slideData[0]].Screens[
                    screenData[0]
                  ]
                );
                if (_arr.length > 0) {
                  delete projectData[moduleData[0]].slides[slideData[0]]
                    .Screens[screenData[0]]["mediaurl"];
                }
              }
              console.log(_arr);
              return item;
            });

            return screenData;
          });
          return slideData;
        });
        return moduleData;
      });

      console.log(project[projectId], "111111111");
      let updatedProjectDataforDb = JSON.stringify(project);
      console.log(updatedProjectDataforDb);

      let projectDataforAPI = await removeMediaUrlFilter(
        JSON.parse(updatedProjectDataforDb),
        projectId
      );
      console.log(projectDataforAPI);
      let renderUrls = ""
      let urlArray
      if (projectDatafromDb.data.data.length > 0) {
          renderUrls = projectDatafromDb.data.data[0].renderUrls;
          urlArray = projectDatafromDb.data.data[0].renderUrls
          if (urlArray !== null && urlArray !== undefined) {
              Object.keys(urlArray).map(function (key, index) {
                  Object.keys(urlArray[key]).map(function (k, i) {
                
                          if (ActiveModule +"-"+ActiveSlide == key + "-" + k) {
                              delete urlArray[key][k]
                              // console.log(urlArray[key][k], key, k, slidechange, "renderUrls");
                          }
                          return urlArray
                      
                  });
              });
          }

      }
      let templateValidArray = [];
      // console.log(project.createdProject);
      let projectDataReq = {
        dbdata: {
          [projectId]: project[projectId],
        },
        APIData: {
          [projectId]: projectDataforAPI[projectId],
        },
        RenderUrl: {
          renderUrls: urlArray
      },
        type: "Update",
      };
      console.log(projectDataReq);
      createProjectsPreview(JSON.stringify(projectDataReq)).then(async (response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            setPageLoaded(false)
            UpdateAudioScriptUpload("Null")
            setAudioFile("")
            showToaster("Audio file is deleted", "success");
            // ShowAlertBox("SUCCESS", "Audio file is deleted", "success");
          }
        } catch (error) { }
      });
    }
  }

  const EditField = (oldValue) => {
    props.setpreviewEditMode(true)
    setreadOnlyField(false);
    setprevOstValue(JSON.stringify(oldValue));
  };

  const SaveUpdate = () => {
    setreadOnlyField(true);
    showToaster("Audioscript updated successfully", "success");
    setprevOstValue(undefined);
    props.setchanges(true)
    props.setpreviewEditMode(false)
    props.setshowWarning(false)
  };

  const CancelUpdate = () => {
    let prevOstValues = JSON.parse(prevOstValue);
    console.log(prevOstValues);
    audioScriptChange(prevOstValues)
    setreadOnlyField(true);
    setprevOstValue(undefined);
    props.setpreviewEditMode(false)
    props.setshowWarning(false)
  };

  return (

    <React.Fragment>

      
      {pageLoaded ?
        <div className="loader-wrapper video-loader">
          <div className="loader-inner">
            <img src="../../../assets/img/loader.gif"></img>
          </div>
        </div> : ""}
      <div className="card">
        <div className="card-header" id="heading-5">
          <h5 className="mb-0">
            <a
              className="btn btn-link collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-5"
              aria-expanded="false"
              aria-controls="collapse-5"
              onClick={() => setToggleAcc(!toggleAcc)}
            >
              {" "}
              Audio Script{" "}
            </a>
          </h5>
        </div>
        <div
          id="collapse-5"
          className="collapse"
          aria-labelledby="heading-5"
          data-bs-parent="#accordion-1"
        >
          {toggleAcc &&
            <div className="card-body" id="child-1">
              {readOnlyField ? (
                // <button
                //   className="btn btn-primary"
                //   onClick={() => {
                //     EditField(item, `ostTextarea${key}`);
                //   }}
                // >
                //   Edit
                // </button>
                <span
                  className="action-btn ms-1"
                  onClick={() => {
                    EditField(audioScriptValue);
                  }}
                >
                  <svg
                    width="16px"
                    height="16px"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    enable-background="new 0 0 512 512"
                  >
                    <g>
                      <g>
                        <path d="m455.1,137.9l-32.4,32.4-81-81.1 32.4-32.4c6.6-6.6 18.1-6.6 24.7,0l56.3,56.4c6.8,6.8 6.8,17.9 0,24.7zm-270.7,271l-81-81.1 209.4-209.7 81,81.1-209.4,209.7zm-99.7-42l60.6,60.7-84.4,23.8 23.8-84.5zm399.3-282.6l-56.3-56.4c-11-11-50.7-31.8-82.4,0l-285.3,285.5c-2.5,2.5-4.3,5.5-5.2,8.9l-43,153.1c-2,7.1 0.1,14.7 5.2,20 5.2,5.3 15.6,6.2 20,5.2l153-43.1c3.4-0.9 6.4-2.7 8.9-5.2l285.1-285.5c22.7-22.7 22.7-59.7 0-82.5z"></path>
                      </g>
                    </g>
                  </svg>
                </span>
              ) : (
                <>
                  {/* <button
                    className="btn btn-primary"
                    onClick={() => {
                      SaveUpdate();
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      CancelUpdate();
                    }}
                  >
                    Cancel
                  </button> */}
                  <span className="action-btn me-1 ms-1">
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="#31d131"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        SaveUpdate();
                      }}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z"
                        fill=""
                      ></path>
                    </svg>
                  </span>
                  <span className="action-btn" onClick={() => {
                      CancelUpdate();
                    }}>
                    <svg
                      width="16px"
                      height="16px"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="red"
                    >
                      <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"></path>
                    </svg>
                  </span>
                </>
              )}
              <div className="card-inner">
                <div className="form-group">
                  <textarea readOnly={readOnlyField}  className="form-control" placeholder="Description" type="text" id="22" onChange={(e) => audioScriptChange(e.target.value)} value={audioScriptValue}>
                  </textarea>
                  {audioScriptValidation ? <div name="courseName" component="div" className="input-error-msg">Accepts only alphabetic characters</div> : null}
                  {/* <div className="input-error-msg" style={{ visibility: audioScriptError !== "" ? "visible" : "hidden" }} >{audioScriptError}</div> */}
                </div>
                {audioFile === "" ? (
                  <p>
                    You can upload it manually
                    <a className="modal-toggle" onClick={() => {
                      viewImagePriview();
                    }}>
                      Click Here
                </a>
                  </p>) : ""

                }
                <React.Fragment>
                  <div className="audio-wrap">
                    {
                      console.log(audioFile)
                    }
                    {audioFile !== "" && (
                      <div style={{ position: 'relative' }}>

                        {audiError === false && (
                          <Waveform url={audioFile} />
                          // <Wavesurfer
                          //   audioFile={audioFile}
                          //   pos={pos}
                          //   onReady={(e) => handleReady(e)}
                          //   onPosChange={handlePosChange}
                          //   playing={playing}
                          //   backend="MediaElement"
                          //   onError={(e) => errorAudioFile(e)}
                          //   muted={true}
                          // />
                        )}
                      </div>

                    )}
                    <div className="time-duration text-end mt-2">
                      <span> {audioDuration}</span>
                    </div>
                  </div>
                  {audioFile !== "" && (
                    <p>
                      <a className="modal-toggle"
                        onClick={() => {
                          let message =
                            "You want to remove this audio?"
                          let confirmBtn = "Yes";
                          setconfirmmsg(message);
                          setconfirmbtnlabel(confirmBtn);
                          setopenAudioModal(true);
                        }}
                      >
                        Remove
                </a>
                Audio script
                    </p>
                  )}
                </React.Fragment>
              </div>
            </div>}
        </div>

      </div>
      <AudioUplaod
        closePreview={() => setAudioUpload(false)}
        openAudioUpload={openAudioUpload}
      />
      <CustomModal
        isOpen={openAudioModal}
        Closemodal={setopenAudioModal}
        Header="Are you sure ?"
        Content="You want to remove this audio?"
        Buttonlabel="Yes"
        Buttonclick={removeAudio}
      />
      <SaveDataWarningModal show={props.showWarning} cancel={() => CancelUpdate()} save={() => SaveUpdate()}/>

    </React.Fragment>
  )
}
