import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { getSingleRenderProjectDetails, getClusterList, createProjectsPreview } from "../../services/api.service";
import CreateCluster from "./CreateCluster";
import VmDetails from "./vmdetails";
import ClusterUsage from "./ClusterUsage";
import { showToaster } from "../../services/helper";
import Header from "../components/header/header";
import RenderVideo from "../container/projectlist/RenderProject";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Checkbox } from "@mui/material";
import { removeMediaUrlFilter } from "../../services/DataHelper";
import { nanoid } from "nanoid/async";

function ListClusterForRender(props) {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [loading, setloading] = useState(false);
  const [clusterList, setClusterList] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [newUser, setnewUser] = useState(undefined);
  const [openCreateCluster, setOpenCreateCluster] = useState(false);
  const [openRenderVideo, setOpenRenderVideo] = useState(false);
  const [openClusterUsage, setOpenClusterUsage] = useState(false);
  const [slideCount, setSlideCount] = useState([]);
  const [videos, setVideos] = useState([]);
  const [openvmDetails, setOpenVmDetails] = useState(false);
  const [renderProject, setRenderProject] = useState(null);
  const [projectId, setprojectId] = useState(null);
  const [renderId, setRenderId] = useState(null);
  const [organisationId, setOrganisationId] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState(null);
  const searchRef = useRef("");

  const { projectRenderId } = useParams();

  useEffect(() => {
    getSingleRenderProjectDetails(projectRenderId).then((response) => {
      try {
        if (response.status === 200) {
          setRenderProject(response.data.renderProjectDetails);
          setOrganisationId(response.data.renderProjectDetails.userId);
          getClusters(response.data.renderProjectDetails.userId);
        }
      } catch (e) {
        console.log(e);
      }
    });
  }, [projectRenderId]);
  const getClusters = async (orgId = null) => {
    try {
      setloading(true);
      orgId = orgId || (showAll ? "All" : organisationId);
      if (orgId) {
        const body = {
          organisation: orgId
        };
        const response = await getClusterList(body);
        if (response.data && response.data.clusterList) {
          setClusterList(response.data.clusterList);
        }
      }
    } catch (error) {
      console.log(error);
    }
    searchRef.current = "";
    setloading(false);
  };

  useEffect(() => {
    getClusters();
  }, [currentPage, isSearch, perPage, showAll]);


  const checkTemplateAvailable = async (modules, ProjectID) => {
    var templateStatus = true;
    if (modules !== undefined && modules !== null && modules[ProjectID] !== undefined) {
      var activeProjectData = modules[ProjectID]["Modules"];
      Object.entries(activeProjectData).map((modules, moduleIndex) => {
        if (templateStatus === true) {
          let moduleData = modules[1];
          if (moduleData.slides !== undefined && moduleData.slides !== null && moduleData.slides !== "") {
            Object.entries(moduleData.slides).map((slideData, slideIndex) => {
              if (templateStatus === true) {
                if (typeof slideData[1] === "object") {
                  let screens = slideData[1].Screens;
                  if (screens !== undefined && screens !== null && screens !== "" && typeof screens === "object") {
                    if (templateStatus === true) {
                      Object.entries(screens).map((screen, index) => {
                        let itemsKey = screen[0];
                        Object.entries(screens[itemsKey]).map((item, index) => {
                          if (item[1] !== undefined && item[1].Contenttype !== undefined && item[1].Contenttype === "TemplateID") {
                            if (item[1] !== undefined && item[1].Contentparams !== undefined) {
                              let Contentparams = item[1].Contentparams;
                              if (
                                Contentparams.contentdescription !== undefined &&
                                Contentparams.contentdescription !== null &&
                                Contentparams.contentdescription !== "" &&
                                Contentparams.contentdescription !== "Null"
                              ) {
                              } else {
                                templateStatus = false;
                              }
                            }
                          }
                          return true;
                        });
                        return true;
                      });
                    }
                  }
                }
              }
              return true;
            });
          }
        }
        return true;
      });
    }
    return templateStatus;
  };

  const saveRender = async (selectedCluster) => {
    setSelectedCluster(selectedCluster);
    let projectDetails = renderProject
    // setopenRenderModal();
    let urlArray;
    let slideRenderArray = [];
    try {
      // setPageLoad(true);
      let updatedProjectDataforDb = projectDetails.ProjectData;
      let projectId = projectDetails.ProjectID;
      let slideRenderData = JSON.parse(updatedProjectDataforDb);
      if (slideRenderData[projectId] != null) {
        if (slideRenderData[projectId].Modules != undefined) {
          let c = 0;
          Object.keys(slideRenderData[projectId].Modules).map((module) => {
            Object.keys(slideRenderData[projectId].Modules[module].slides).map((slide) => {
              if (slideRenderData[projectId].Modules[module].slides[slide].SlideRender == "1") {
                let slideModuleDta = {
                  slideId: slide,
                  moduleId: module
                };
                slideRenderArray.push(slideModuleDta);
              }
              if (projectDetails.renderUrls !== undefined && projectDetails.renderUrls !== null && projectDetails.renderUrls !== "") {
                urlArray = projectDetails.renderUrls;
                if (urlArray !== null && urlArray !== undefined) {
                  Object.keys(urlArray).map(function (key, index) {
                    Object.keys(urlArray[key]).map(function (k, i) {
                      if (key.startsWith("M") && k.startsWith("SL")) {
                        if (urlArray[key][k].url == "" && urlArray[key][k].SlideRender == "1") {
                          slideRenderData[projectId].Modules[key].slides[k].SlideRender = "1";
                        } else {
                          slideRenderData[projectId].Modules[key].slides[k].SlideRender = "0";
                        }
                      }
                    });
                  });
                }
              }
            });
          });
        }
      }
      let projectDataforAPI = await removeMediaUrlFilter(slideRenderData, projectDetails.ProjectID);
      setprojectId(projectDetails.ProjectID);
      let renderUrls = projectDetails.renderUrls;
      const newProjectId = await nanoid();
      let templateValidArray = [];
      let dbdata = slideRenderData[projectId];
      let projectDataReq = {
        dbdata: {
          [projectId]: dbdata
        },
        APIData: {
          [projectId]: projectDataforAPI[projectId]
        },
        RenderUrl: {
          renderUrls: renderUrls
        },
        slideModuleData: {
          slideRenderDatas: slideRenderArray
        },
        RenderData: {
          [newProjectId]: projectDataforAPI[projectId]
        },
        type: "sliderender"
      };

      createProjectsPreview(JSON.stringify(projectDataReq)).then(async (response) => {
        try {
          // setPageLoad(true)
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            if (response.status === 200 && response.status !== null && response.status !== null) {
              // setPageLoad(false);
              showToaster("Project Updated successfully", "success");
              setRenderId(response.data.ProjectID);
              setOpenRenderVideo(true);
            } else {
              // setPageLoad(false);

              showToaster(response.message, "warning");
            }
          } else {
            // setPageLoad(false);

            showToaster("Something went wrong. Please Try Again", "warning");
          }
        } catch (error) {
          console.log(error);
          // setPageLoad(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      });
    } catch (error) {
      console.log(error);
      // setPageLoad(false);
      showToaster("Something went wrong. Please Try Again6", "warning");
    }
  };

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader-inner">
          <img src="../../../assets/img/loader.gif"></img>
        </div>
      </div>
    );
  };

  const listClusterData = (data) => {
    return (
      <div className="table-wraper">
        <div className="usage-list-warper template-listing cluster-listing">
          <div className="usage-block mb-3">
            <div className="usage-head-label text-center">Render Cluster Name</div>

            <div className="usage-head-label text-center">Organization Name</div>
            <div className="usage-head-label text-center">Resource Group</div>
            <div className="usage-head-label text-center">Cluster Name</div>
            <div className="usage-head-label text-center">Projects Rendering</div>
            <div className="usage-head-label text-center">Vm Size</div>
            <div className="usage-head-label text-center">Vm Count</div>
            <div className="usage-head-label text-center">Min VM Count</div>
            <div className="usage-head-label text-center">Max VM Count</div>
            <div className="usage-head-label text-center">Max Unit</div>
            <div className="usage-head-label text-center">Status</div>
            <div className="usage-head-label text-center">Render</div>
          </div>
          {data != undefined &&
            data.map((configData, i) => {
              if (configData.isDelete == 0) {
                console.log(configData, "configData ===>");
                return (
                  <div key={i} className="usage-block usg-sub-block mb-3">
                    <div className="usage-head-label text-center">{configData.rendercluster_name}</div>
                    <div className="usage-head-label text-center">{configData.organisationName}</div>
                    <div className="usage-head-label text-center">{configData.resource_group}</div>
                    <div className="usage-head-label text-center">{configData.cluster_name}</div>
                    <div className="usage-head-label text-center">
                      {/* {configData.number_of_render_inprogress > 0 ? ( */}
                      <Link to={`/admin/cluster/${configData._id}/render-list`}>{configData.number_of_render_inprogress}</Link>
                      {/* ) : (
                        <>{configData.number_of_render_inprogress}</>
                      )} */}
                    </div>
                    <div className="usage-head-label text-center">{configData.vm_size}</div>
                    <div className="usage-head-label text-center">{configData.vm_count}</div>
                    <div className="usage-head-label text-center">{configData.min_scale_vm_count}</div>
                    <div className="usage-head-label text-center">{configData.max_scale_vm_count}</div>
                    <div className="usage-head-label text-center">{configData.max_units}</div>
                    <div className="usage-head-label text-center">{configData.status}</div>
                    <div className="usage-head-label text-center">
                      <button
                        className="btn btn-sm btn-success d-inline-block m-auto"
                        styles={{
                          cursor: "pointer" 
                        }}
                        type="button"
                        onClick={() => {
                          saveRender(configData);
                        }}
                      >
                        Render
                      </button>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    );
  };

  const viewClusterUsage = (image) => {
    setOpenClusterUsage(true);
  };
  const viewVmDetails = () => {
    setOpenVmDetails(true);
  };

  const handleShowAll = (e) => {
    setShowAll(e.target.checked);
  };

  useEffect(() => {}, [showAll]);
  return (
    <div className={DisplayMode ? "theme dark" : "theme"}>
      {loading && <Loader />}

      <div className="main-wrapper">
        <Header />
        <div className="template-wrapper pb-wrapper">
          <div className="d-flex">
            <div className="p-2 flex-fill total-template-count">
              <h2 className="h4">Cluster Selection</h2>
            </div>
            <div className="row pb-header ">
              <div className="col-md-9 col-lg-10 col-xl-9 d-flex flex-wrap justify-content-between">
                <div className="d-flex align-items-center pb-filter-holder flex-fill me-2">
                  <div className="pb-filter-label pe-3">Show All</div>
                  <div className="srch-ft-selector w-100 p-0 drop-custom">
                    <Checkbox
                      checked={showAll}
                      onChange={handleShowAll}
                      // inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() => {
                viewVmDetails();
              }}
              type="submit"
              className="btn btn-outline-primary text-nowrap add-user-btn mb-2 me-3"
              value="submit"
              data-bs-toggle="modal"
              data-bs-target="#add-user"
            >
              View VM Details
            </button>
            <button
              onClick={() => {
                viewClusterUsage();
              }}
              type="submit"
              className="btn btn-outline-primary text-nowrap add-user-btn mb-2 me-3"
              value="submit"
              data-bs-toggle="modal"
              data-bs-target="#add-user"
            >
              <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-label="Start Date"
                className=""
                data-mui-internal-clone-element="true"
              >
                <path d="M1.09277 8.40445H18.9167" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M14.442 12.3088H14.4512" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M10.0045 12.3088H10.0137" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5.55769 12.3088H5.56695" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M14.442 16.1955H14.4512" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M10.0045 16.1955H10.0137" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5.55769 16.1955H5.56695" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M14.0438 1V4.29078" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5.96564 1V4.29078" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.2383 2.58008H5.77096C2.83427 2.58008 1 4.21601 1 7.2231V16.2727C1 19.3271 2.83427 21.0009 5.77096 21.0009H14.229C17.175 21.0009 19 19.3555 19 16.3484V7.2231C19.0092 4.21601 17.1842 2.58008 14.2383 2.58008Z"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              View Cluster Usage
            </button>

            {/* <div className="mb-2 flex-fill text-center">
                <div className="pb-srch-customiser w-100 me-2 mb-0 me-md-3">
                  <div className="custom-search srch-holder w-100 h-100">
                    <div className="srch-icon">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21.9243 21.925L27.9994 28.0001"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="srch-inpt-holder h-100">
                      <form onSubmit={onSearchSubmit} onReset={onSearchReset} className="h-100">
                        <input
                          type="text"
                          name="focus"
                          value={search}
                          className="pe-0 form-control srch-input h-100 "
                          placeholder="Press enter to search by template name or id"
                          onChange={onSearch}
                        />
                        <button className="search-close" type="reset">
                          <svg
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                          >
                            <g>
                              <g>
                                <path
                                  d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M327.115,365.904
                          L256,294.789l-71.115,71.115l-38.789-38.789L217.211,256l-71.115-71.115l38.789-38.789L256,217.211l71.115-71.115l38.789,38.789
                          L294.789,256l71.115,71.115L327.115,365.904z"
                                />
                              </g>
                            </g>
                          </svg>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div> 
              <div className="p-2 flex-fill text-right total-template-count">
                Total Items Count : {configsCount}
              </div>*/}
          </div>

          {/* <EditSettings selectedConfigs={selectedConfigData} 
            updateConfigData={updateConfigData} clearSelectedConfig={clearSelectedConfigData} /> */}
          {clusterList.length > 0 ? (
            listClusterData(clusterList)
          ) : (
            <div className="no-data">
              <p>No data available</p>
            </div>
          )}
          {/* <div  className="row pagination-holder mb-0 justify-content-center">
              <div  className="col-auto">
                <Pagination
                  onChange={paginate}
                  page={currentPage}
                  shape='rounded'
                  variant="outlined"
                  color="primary"
                  count={Math.ceil(clusterCount/perPage)}
                />
              </div>
            </div> */}
        </div>
      </div>
      <RenderVideo
        closePreview={() => setOpenRenderVideo(false)}
        openRenderVideo={openRenderVideo}
        refreshPage={getClusters}
        // priviewrenderUrl={priviewrenderUrl}
        thumbnailUrl={props.RenderThumnails}
        setSlideCount={slideCount}
        setVideos={videos}
        selectedCluster={selectedCluster}
        // moduleCount={moduleCount}
        checkTemplateAvailable={checkTemplateAvailable}
        renderId={renderId}
        projectId={projectId}
        renderOutsideOrg={true}
      />
      <CreateCluster
        closePreview={() => setOpenCreateCluster(false)}
        createUserModal={openCreateCluster}
        setnewUsers={setnewUser}
        // selectedImage={selectedImageFile}
      />
      <ClusterUsage
        closePreview={() => setOpenClusterUsage(false)}
        createUserModal={openClusterUsage}

        // selectedImage={selectedImageFile}
      />
      <VmDetails
        closePreview={() => setOpenVmDetails(false)}
        createUserModal={openvmDetails}

        // selectedImage={selectedImageFile}
      />
    </div>
  );
}

export default ListClusterForRender;
