import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { bindActionCreators } from 'redux';
import { Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { FONT_URL } from '../../../constants';
import { actionCreator } from '../../../store';
import { videoIcon } from '../../../constants/icon';
import { getProjectStyles } from '../../../services/api.service';
import InnerSidebar from '../../../components/sidebar/innerSidebar';
import AccordionItem from '../../../components/accordion/AccordionItem';
import { getProjectQueryData, showToaster } from '../../../services/helper';
import { CreateModule, CreateModuleManual } from '../../../services/DataHelper';
import InnerSidebarHeader from '../../../components/sidebar/innerSidebarHeader';

export default function ProjectModuleSidebar(props) {
  const {
    category,
    projectName,
    cacheProject,
    clientSetting,
    updateSlideName,
    removeSlideData,
    updateModuleName,
    removeModuleData,
  } = props;

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { AddModule } = bindActionCreators(actionCreator, dispatch);

  const project = useSelector(state => state.project);

  const [activeKeys, setActiveKeys] = useState([]);
  const [isHeaderActive, setIsHeaderActive] = useState({});

  const projectModulesData = project?.createdProject?.[project.ProjectId]?.Modules;
  const projectModules = Object.entries(projectModulesData);

  useEffect(() => {
    try {
      setActiveKeys([project.ActiveModule]);
      setIsHeaderActive(projectModules[0][0]);
      // setIsHeaderActive({...isHeaderActive, [projectModules[0][0]]: true})
      const { from, module, action } = getProjectQueryData();

      if (from === 'generate-videos-inputs' && action === 'add-module') {
        createModule({ storeData: true });
        setActiveKeys([module]);
        history.push(`/edit-projects/${params.id}`);
      }
    } catch (error) {
      console.log({ moduleSidebarUseEffectError: error });
      window.location.replace(window.location.pathname);
    }
  }, []);

  const handleAccordionClick = eventKey => {
    if (activeKeys.includes(eventKey)) {
      setActiveKeys(activeKeys.filter(key => key !== eventKey));
      setIsHeaderActive(eventKey);
    } else {
      setActiveKeys([...activeKeys, eventKey]);
      setIsHeaderActive(eventKey);
    }
  };

  const createModule = async argsData => {
    if (clientSetting.moduleLimit > projectModules?.length) {
      const lastModuleId = projectModules[projectModules.length - 1]?.[0];

      const lastModuleNumber = lastModuleId.replace('M0', '');
      const lastModuleNumberSplit = lastModuleNumber.split('M')[1];

      let data;
      if (lastModuleNumberSplit) data = parseInt(lastModuleNumberSplit) + 1;
      else data = parseInt(lastModuleNumber) + 1;

      const newModuleId = data < 10 ? 'M0' + data : 'M' + data;

      let newModule;
      if (clientSetting.manualTemplate) {
        if (project.EditMode) {
          let style;
          if (project.stylesData) {
            style = project.stylesData;
          } else {
            const styleResponse = await getProjectStyles(project.ProjectId);
            style = styleResponse.data;
          }
          const fontUrl = FONT_URL + style.fontFamily + '/' + style.fontStyle;
          newModule = await CreateModuleManual(
            newModuleId,
            data,
            fontUrl,
            style.headingSize,
            style.paragraphSize,
            style.textColor,
            style.bgColor
          );
        } else {
          newModule = await CreateModuleManual(
            newModuleId,
            data,
            project.FontUrl,
            project.FontHSize,
            project.FontPSize,
            project.TextColor,
            project.BgColor
          );
        }
      } else {
        if (project.EditMode) {
          let style;
          if (project.stylesData) {
            style = project.stylesData;
          } else {
            const styleResponse = await getProjectStyles(project.ProjectId);
            style = styleResponse.data;
          }
          const fontUrl = FONT_URL + style.fontFamily + '/' + style.fontStyle;
          newModule = await CreateModule(
            newModuleId,
            data,
            fontUrl,
            style.headingSize,
            style.paragraphSize,
            style.textColor,
            style.bgColor
          );
        } else {
          newModule = await CreateModule(
            newModuleId,
            data,
            project.FontUrl,
            project.FontHSize,
            project.FontPSize,
            project.TextColor,
            project.BgColor
          );
        }
      }

      const projectModulesData = project.createdProject[project.ProjectId].Modules;
      const projectModuleKeys = Object.keys(projectModulesData);
      const cacheModules = cacheProject.ProjectData[cacheProject.ProjectID].Modules;
      const cacheModuleKeys = Object.keys(cacheModules || {});

      if (!cacheModules || projectModuleKeys.length !== cacheModuleKeys.length) {
        const lastModuleId = projectModuleKeys[projectModuleKeys.length - 1];
        const moduleName = projectModulesData[lastModuleId].ModuleName;
        showToaster(`Please delete/save ${moduleName} before creating a new module`, 'error', 5000);
        return false;
      } else {
        //   const projectSlidesData =
        //   project.createdProject[project.ProjectId].Modules[project.ActiveModule].slides;
        // const projectSlideKeys = Object.keys(projectSlidesData);
        // const cacheSlides =
        // cacheProject.ProjectData[cacheProject.ProjectID].Modules[project.ActiveModule]?.slides;
        // const cacheSlideKeys = Object.keys(cacheSlides);
        // projectModuleKeys.map(moduleKey => {
        //   if(project)
        // })
      }

      if (argsData?.storeData) {
        if (cacheProject?.includeLogoImage) {
          newModule[newModuleId].slides['SLO1'].Screens[`SC01.01`]['IM01']['Contentparams'][
            'contentdescription'
          ] = cacheProject.imageUrl;
          newModule[newModuleId].slides['SLO1'].Screens[`SC01.01`]['IM01']['Contentparams'][
            'contenttag'
          ] = 'logo';
        }
        AddModule(newModule);
      }
      return newModuleId;
    }

    return null;
  };

  const handleCreateModule = async () => {
    const newModuleId = await createModule();

    if (newModuleId) {
      routeToChatGPT(newModuleId, 'add-module');
    }
  };

  const routeToChatGPT = (module, action, slide = 'SL01', screen = '01') => {
    history.push(
      `/project/${params.id}/generate-videos-inputs?from=edit-projects&action=${action}&module=${module}&slide=${slide}&screen=${screen}`
    );
  };

  return (
    <InnerSidebar
      showButton
      onClickCreate={handleCreateModule}
      showCreateButton={clientSetting.moduleLimit > projectModules?.length}
      hiddenCreateButtonMessage="Max. module limit reached"
    >
      <InnerSidebarHeader title={projectName} subTitle={category} icon={videoIcon} />
      <Accordion
        activeKey={activeKeys}
        className="custom-accordion"
        onSelect={handleAccordionClick}
      >
        {projectModules?.map(([moduleId, moduleValue]) => (
          <AccordionItem
            key={moduleId}
            moduleId={moduleId}
            module={moduleValue}
            cacheProject={cacheProject}
            setActiveKeys={setActiveKeys}
            clientSetting={clientSetting}
            routeToChatGPT={routeToChatGPT}
            updateSlideName={updateSlideName}
            removeSlideData={removeSlideData}
            removeModuleData={removeModuleData}
            updateModuleName={updateModuleName}
            onHeaderClick={handleAccordionClick}
            isActive={activeKeys.includes(moduleId)}
            isHeaderActive={isHeaderActive === moduleId}
            showModuleRemoveIcon={projectModules?.length > 1}
            setIsHeaderActive={setIsHeaderActive}
          />
        ))}
      </Accordion>
    </InnerSidebar>
  );
}
