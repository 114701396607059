function CircleIcon(props) {
  const { title, icon, onClick } = props;
  return (
    <div className="circle-icon-wrap">
      {icon && (
        <button role="button" className="circle-icon" onClick={onClick}>
          {icon}
        </button>
      )}
      {title && <span className="circle-icon-text">{title}</span>}
    </div>
  );
}
export default CircleIcon;
