import { useEffect, useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

import DropdownCommon from '../../../../components/form/dropdownCommon';
import CustomFileUpload from '../../../../components/form/customFileUpload';
import RadioButton from '../../../../components/form/radioButton';
import LoadingProgressbar from './loadingProgressbar';

// Icons
import { crossIcon, uploadCloudIcon } from '../../../../constants/icon';
import { getUserItem, showToaster } from '../../../../services/helper';
import { uploadMediaFile } from '../../../../services/api.service';
import TagInput from '../../../../components/tagInput';
import { EditAsset } from '../../../../store/Actions';

function AddFilesModal(props) {
  const { fromVideo, fromImage, activeComponent, setPrivateRepo, setModalShow,getAssetRepo, ...rest } = props;
  const [isChecked, setIsChecked] = useState(true);
  const [mediaUrls, setMediaUrls] = useState('');
  const [mediaTypes, setMediaTypes] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingData, setUploadingData] = useState({});
  const [files, setFiles] = useState([]);
  const [errorStatus, setErrorStatus] = useState({});
  const [isPrivate, setIsPrivate] = useState(1);
  const abortController = useRef(null);
  const isClosedRef = useRef(false);
  const assetTypes = ['IMAGE', 'VIDEO', 'ICON', 'LOGO'];
  const [fileType, setFileType] = useState('VIDEO');
  const [selectedTags, setSelectedTags] = useState('');

  let userId = getUserItem('clientId');
  const handleRadioChange = privateRepo => {
    setIsChecked(!isChecked);
    setIsPrivate(privateRepo);
  };

  useEffect(() => {
    setFileType(activeComponent);
  }, [activeComponent]);

  const [showProgress, setShowProgress] = useState(false);

  const handleUploadClick = () => {
    setShowProgress(true);
  };

  const handleCloseBtn = () => {
    setShowProgress(false);
  };

  const handleUpload = (mediaUrl = '') => {
    setMediaUrls(mediaUrl);
  };

  const checkErrorStatus = () => {
    const errStatus = { ...errorStatus };
    if(fileType === 'VIDEO' && !selectedTags.length)  {
      errStatus['tags'] = true;
    }
    if (assetTypes.indexOf(fileType) === -1) {
      errStatus['filetype'] = true;
    }
    if ([1, 0].indexOf(isPrivate) === -1) {
      errStatus['isprivate'] = true;
    }
    setErrorStatus(errStatus);
    return errStatus;
  };

  const listSelectedFiles = () => {
    if (!files.length || isUploading) {
      return null;
    }
    const filesList = [];
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      filesList.push(
        <div key={i} className="alert-list">
          <span>{f.name}</span>
          {/* <button type="button" onClick={() =>onRemoveSelected(null, i)} className="close" data-dismiss="alert" aria-label="Close">
            <img src="../../assets/img/close-1.png" />
          </button> */}
        </div>
      );
    }
    return (
      <div className="add-new-inner">
        <h4>Selected files</h4>
        <div className="add-new-list">{filesList}</div>
      </div>
    );
  };

  const onSubmit = async e => {
    try {
      e.preventDefault();
      const errorList = Object.keys(checkErrorStatus());
      if (errorList.length > 0) {
        return;
      }
      setIsUploading(true);
      for (let i = 0; i < files.length; i++) {
        if (isClosedRef.current) {
          break;
        }
        const file = files[i];
        setUploadingData(data => {
          const newData = { ...data };
          newData[i] = { file: file.name, progress: 0, isAborted: false };
          return { ...newData };
        });
        let data = new FormData();
        abortController.current = axios.CancelToken.source();
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            media_type: activeComponent,
            user_id: userId,
            file_name: file.name,
            tag_names: selectedTags,
            private: isPrivate,
          },
          cancelToken: abortController.current.token,
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadingData(data => {
              const newData = { ...data };
              newData[i].progress = percentCompleted;
              return { ...newData };
            });
          },
        };
        data.append('file', file);
        const response = await uploadMediaFile(data, config);
        if (response.status === 101) {
          showToaster(`${file.name}: ${response.message}`, 'warning');
        } else if (response.status === 200) {
          showToaster(`${file.name}: Uploaded Successfully`, 'success');
          setModalShow(false);
          setSelectedTags('');
          getAssetRepo()
        } else {
          showToaster(`${file.name}: ${response.message}`, 'warning');
        }
      }
      setIsUploading(false);
      setFiles([]);
      
    } catch (err) {
      setIsUploading(false);
      setFiles([]);
      showToaster('Something went wrong. Please Try Again', 'warning');
    }
  };

  const handleTagChange = tags => {
    setSelectedTags(tags.toString());
    if(tags.toString().length){
      let errors = {...errorStatus}
      delete errors.tags
      setErrorStatus(errors)
    }
  };

  const uploadDisabled = !files.length || isUploading || Object.keys(errorStatus).length;
  const uploadDisabledStyle = uploadDisabled ? { cursor: 'not-allowed', opacity: '0.5' } : {};

  const onCancelHide = () =>{
    props?.setModalShow(false)
    setFiles([])
    setSelectedTags('')
  }

  return (
    <Modal
      {...rest}
      className="add-files-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div
          className="modal-close-btn"
          title="Close"
          data-bs-dismiss="modal"
          onClick={props.onHide}
        >
          {crossIcon}
        </div>
        <h2>Add Files</h2>
        <div className="dropdown-select custom-select-dropdown mb-30 active-component">
          {activeComponent.charAt(0).toUpperCase() + activeComponent.slice(1).toLowerCase()}
        </div>
        {listSelectedFiles()}
        <CustomFileUpload
          accept={fromVideo ? '.mp4' : fromImage ? '.jpg, .jpeg, .png' : null}
          fileType={fromVideo ? 'video' : fromImage? 'image': null}
          className="mb-2"
          icon={uploadCloudIcon}
          fileName={
            fromVideo ? 'Select .mp4 file to upload' : `Select .png, .jpeg, .jpg file to upload`
          }
          description="File size max 25mb, orientation 72px x 72px"
          handleUpload={handleUpload}
          fromVideo={fromVideo || fromImage ? true : false}
          files={files}
          setFiles={setFiles}
        />

        <LoadingProgressbar
          title="An_introduction_to_IT (280 MB)"
          showProgress={showProgress}
          onClick={handleCloseBtn}
        />
        {fromVideo && (
          <>
          <TagInput
            label="Add Tag"
            onChange={handleTagChange}
            placeholder="Add new tag and press enter"
            tags={selectedTags}
          />

          {errorStatus.tags && (
            <div className="input-error-msg">Tags are required</div>
          )}
          </>
        )}
        <div className="radio-button-wrap mt-4">
          <RadioButton
            title="Private"
            description="Not visible to anyone"
            checked={isChecked}
            onChange={() => handleRadioChange(1)}
          />
          <RadioButton
            title="Public"
            description="Visible to everyone"
            checked={!isChecked}
            onChange={() => handleRadioChange(0)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="outline-primary-btn" onClick={() =>onCancelHide()}>
          Cancel
        </Button>
        <Button
          className="bg-btn-primary"
          style={uploadDisabledStyle}
          onClick={fromVideo || fromImage ? onSubmit : handleUploadClick}
          disable={uploadDisabled}
        >
          {isUploading ? 'Uploading' : 'Upload'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default AddFilesModal;
