import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { Button } from '@material-ui/core';
import ExportCsv from './ExportCsv';
import {
    getAllTemplate,
    getAllTemplateByUserid,
    getTemplateRenderStatus,
  } from "../../../services/authService";
const axios = require('axios');

const ExportCSV = () => {
    const fileName = "template-details.csv";
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);

    const headers = [
        { label: "Tid", key: "tid" },
        { label: "Template Name", key: "mediaUrl" },
        { label: "No of ost H", key: "no_of_ost_h" },
        { label: "No of ost P", key: "no_of_ost_p" },
        { label: "No of ost B", key: "no_of_ost_b" },
        { label: "No of images", key: "no_of_images" },
        { label: "No of Illustrations", key: "no_of_illustrations" },
        { label: "No of videos", key: "no_of_videos" },
        { label: "Preview", key: "previewUrl" },
        { label: "Uploaded date", key: "addedDate" },
        { label: "Uploaded by", key: "emailid" },
        { label: "Preview date", key: "previewDate" },
        { label: "Preview by", key: "previewby" },
        { label: "Render by", key: "renderBy" },
        { label: "Render Date", key: "renderDate" },
  
    ];
    // let header = ["Tid", "Template Name","No of ost H","No of ost P","No of ost B","No of images",
    // "No of Illustrations","No of videos","Preview","Render","Uploaded date",
    // "Uploaded by","Preview date","Preview by","Render by","Render Date"
    
    
    // ];
    useEffect(() => {
        getUserData();
    }, []);
  
    const getUserData =async () => {
        // setLoading(true);
        const response = await getAllTemplate({
          all:1,
          search: "",
          pageNo: 1,
          limit: 100000,
         
        });
        setUserData(response.data.result);
        // axios.get('https://jsonplaceholder.typicode.com/users')
        //     .then((res) => {
        //         setUserData(res.data);
        //         setLoading(false);
        //     })
        //     .catch((err) => {
        //         console.log("Error: ", err);
        //         setLoading(false);
        //     })
    }

    return (
        <div className='container'>
            <Button
                variant="contained"
                color="primary"
                className='export-btn'
            >
                <CSVLink
                    headers={headers}
                    data={userData}
                    filename={fileName}
                    style={{ "textDecoration": "none", "color": "#fff" }}
                >
                    {loading ? 'Loading csv...' : 'Export Data'}
                </CSVLink>
            </Button>

            <ExportCsv headers={headers} userData={userData} />
        </div>
    );
}

export default ExportCSV;