//  import React from 'react'
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ImagePreview from "../RightBar/ImagePriview";
import ImageEdit from "../RightBar/EditAssetImage";
import Styles from "../RightBar/Style";
import AudioScript from "../RightBar/AudioScript";
import OstData from "../RightBar/OstData";
import TemplateRepo from "../RightBar/TemplateRepo";
import { useHistory } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";
import Tooltip from "@mui/material/Tooltip";
import VideoPreview from "../RightBar/VideoPriview";
import { getUsersProjectRole } from "../../../../services/authService";
import { showToaster, getUserItem } from "../../../../services/helper";

export default function RightScreenDetails(props) {
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [openAssetModals, setOpenAssetModals] = useState(false);
  const [activeStyle, setActiveStyle] = useState(true);
  const [activeText, setActiveText] = useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState(false);
  const [activeAsset, setActiveAsset] = useState(false);
  const [activeAudio, setActiveAudio] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState(false);
  const [AssetType, setAssetType] = useState("");
  const [activeImage, setActiveImage] = useState("");
  const [imageRepo, setImageRepo] = useState([]);
  const [activeIllu, setActiveIllu] = useState("");
  const [illueRepo, setIlluRepo] = useState([]);
  const [activeVideo, setActiveVideo] = useState("");
  const [videoRepo, setVideoRepo] = useState([]);
  const [openVideoPreview, setOpenVideoPreview] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const project = useSelector((state) => state.preview.previewProject);
  const projectId = useSelector((state) => state.preview.ProjectId);
  const ActiveModule = useSelector((state) => state.preview.ActiveModuleId);
  const ActiveSlide = useSelector((state) => state.preview.ActiveSlideId);
  const ActiveScreen = useSelector((state) => state.preview.ActiveScreenId);
  const [selectedVideoFile, setSelectedVideoFile] = useState(false);
  let Toggleright = useSelector((state) => state.project.togglerightscreen);
  const [pageLoad, setPageLoad] = useState(false);
  const [projectRoleEdit, setProjectRoleEdit] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  let userId = getUserItem("id");
  let AdminRole = getUserItem("role");
  const history = useHistory();
  const viewImagePriview = (image) => {
    setOpenImagePreview(true);
    setSelectedImageFile(image);
  };
  const viewVideoPreview = (video) => {
    setOpenVideoPreview(true);
    setSelectedVideoFile(video);
  };
  const openAssetImage = (type, item) => {
    setAssetType(type);
    setSelectedItem(item);
    setOpenAssetModals(true);
  };
  const activeTab = (type) => {
    if (props.previewEditMode) {
      props.setshowWarning(true);
    } else {
      props.setASValidation(false);
      props.setOstValidation(false);
      // props.setOstValidation1(false);
      if (type === "style") {
        setActiveStyle(true);
        setActiveText(false);
        setActiveAsset(false);
        setActiveAudio(false);
        setActiveTemplate(false);
      } else if (type === "text") {
        setActiveStyle(false);
        setActiveText(true);
        setActiveAsset(false);
        setActiveAudio(false);
        setActiveTemplate(false);
      } else if (type === "asset") {
        setActiveStyle(false);
        setActiveText(false);
        setActiveAsset(true);
        setActiveAudio(false);
        setActiveTemplate(false);
      } else if (type === "audio") {
        setActiveStyle(false);
        setActiveText(false);
        setActiveAsset(false);
        setActiveAudio(true);
        setActiveTemplate(false);
      } else if (type === "template") {
        setActiveStyle(false);
        setActiveText(false);
        setActiveAsset(false);
        setActiveAudio(false);
        setActiveTemplate(true);
      }
    }
  };
  const checkProjectRole = async () => {
    if (AdminRole === 1) {
      await getUsersProjectRole(userId).then((response) => {
        try {
          setPageLoad(true);
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            // setCategoryList(ddd)
            setPageLoad(false);
            let tempArray = [];
            let projectRole = response.data.result;
            projectRole.map((projectrole) => {
              if (
                projectrole.projectId === projectId &&
                projectrole.editProject === false
              ) {
                setProjectRoleEdit(true);
              }
              if (
                projectrole.projectId === projectId &&
                projectrole.viewProject === false
              ) {
                history.push("/project-list");
              }
              return projectrole;
            });
            //   setProjectRoleProjectId(tempArray)
          } else {
            setPageLoad(false);
            showToaster("Something went wrong. Please Try Again1", "warning");
          }
        } catch (error) {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      });
    }
  };
  useEffect(() => {
    checkProjectRole();
    updateState();
  }, [activeAsset]);
  useEffect(() => {
    checkProjectRole();
    updateState();
  }, [ActiveModule, ActiveSlide, ActiveScreen]);

  useEffect(() => {
    if (activeTemplate && !props.templateMode) {
      setActiveStyle(true);
      setActiveText(false);
      setActiveAsset(false);
      setActiveAudio(false);
      setActiveTemplate(false);
    }
  }, [props.templateMode]);

  useEffect(() => {
    if (
      project &&
      project[projectId] &&
      project[projectId].Modules &&
      project[projectId].Modules[ActiveModule] &&
      project[projectId].Modules[ActiveModule].slides &&
      project[projectId].Modules[ActiveModule].slides[ActiveSlide] &&
      project[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens &&
      project[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[
        ActiveScreen
      ] &&
      !project[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[
        ActiveScreen
      ].mediaurl
    ) {
      setDisableEdit(true);
      setActiveStyle(false);
      setActiveText(false);
      setActiveAsset(false);
      setActiveAudio(false);
      props.setTemplateMode(false);
    } else {
      setDisableEdit(false);
    }
  }, [project, ActiveScreen]);

  useEffect(() => {
    checkProjectRole();
    props.setASValidation(false);
    props.setOstValidation(false);
    // props.setOstValidation1(false);
    if (project[projectId] != null) {
      if (project[projectId].Modules != undefined) {
        setLoaded(true);
      }
    } else {
      setLoaded(false);
    }
  }, [project]);
  const updateState = () => {
    let imagevalueArray = [];
    let illustarionValueArray = [];
    let videoValueArray = [];
    let selecetsimageonSave = [];
    let selectedIllustonSAve = [];
    let selectedVideoSAve = [];
    let selecetdostOnSAve = [];
    if (loaded) {
      let activeScreenDetails =
        project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide][
          "Screens"
        ][ActiveScreen];
      Object.entries(activeScreenDetails).map((item, key) => {
        let activeimage = item.filter(function (data) {
          return data.Contenttype === "Image";
        });

        let a = item[0];
        if (a.substring(0, 2) === "IM") {
          selecetsimageonSave.push(a);
        }
        if (activeimage.length > 0) {
          let imgtemp = activeimage[0]["Contentparams"]["contentdescription"];
          imagevalueArray.push(imgtemp);
        }
        return item;
      });
      setActiveImage(selecetsimageonSave);
      setImageRepo(imagevalueArray);
      Object.entries(activeScreenDetails).map((item, key) => {
        let activeIllustarion = item.filter(function (data) {
          return data.Contenttype === "Illustration";
        });

        let b = item[0];
        if (b.substring(0, 2) === "IL") {
          selectedIllustonSAve.push(b);
        }

        if (activeIllustarion.length > 0) {
          let illutrationTemp =
            activeIllustarion[0]["Contentparams"]["contentdescription"];
          illustarionValueArray.push(illutrationTemp);
        }
        return item;
      });
      setActiveIllu(selectedIllustonSAve);
      setIlluRepo(illustarionValueArray);
      Object.entries(activeScreenDetails).map((item, key) => {
        let activeVideo = item.filter(function (data) {
          return data.Contenttype === "Video";
        });
        let b = item[0];
        if (b.substring(0, 2) === "VD") {
          selectedVideoSAve.push(b);
        }
        if (activeVideo.length > 0) {
          let videoTemp = activeVideo[0]["Contentparams"]["contentdescription"];
          videoValueArray.push(videoTemp);
        }
        return item;
      });

      setVideoRepo(videoValueArray);
      setActiveVideo(selectedVideoSAve);
    }
  };

  const ListAssets = (assetType) => {
    let ImageList = [];
    let VideoList = [];
    let IlluList = [];
    if (loaded) {
      let activeScreenDetails =
        project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide][
          "Screens"
        ][ActiveScreen];
      // if (Object.entries(activeScreenDetails).length > 0) {
      Object.entries(activeScreenDetails).map((item, key) => {
        if (item[1].Contenttype === "Image") {
          ImageList.push(
            <div className="grid-list">
              <div className="icon-wrap">
                <span
                  className="img-view"
                  onClick={() => {
                    viewImagePriview(item[1].Contentparams.contentdescription);
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#view-img"
                >
                  <Tooltip arrow>
                    <svg
                      width="20"
                      height="17"
                      viewBox="0 0 20 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.99889 5.64136C8.66889 5.64136 7.58789 6.72336 7.58789 8.05336C7.58789 9.38236 8.66889 10.4634 9.99889 10.4634C11.3289 10.4634 12.4109 9.38236 12.4109 8.05336C12.4109 6.72336 11.3289 5.64136 9.99889 5.64136ZM9.99889 11.9634C7.84189 11.9634 6.08789 10.2094 6.08789 8.05336C6.08789 5.89636 7.84189 4.14136 9.99889 4.14136C12.1559 4.14136 13.9109 5.89636 13.9109 8.05336C13.9109 10.2094 12.1559 11.9634 9.99889 11.9634Z"
                        fill=""
                      />
                      <mask
                        id="mask0_2688_3325"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="17"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 0.000244141H19.9998V16.1052H0V0.000244141Z"
                          fill=""
                        />
                      </mask>
                      <g mask="url(#mask0_2688_3325)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.56975 8.05251C3.42975 12.1615 6.56275 14.6045 9.99975 14.6055C13.4368 14.6045 16.5698 12.1615 18.4298 8.05251C16.5698 3.94451 13.4368 1.50151 9.99975 1.50051C6.56375 1.50151 3.42975 3.94451 1.56975 8.05251V8.05251ZM10.0017 16.1055H9.99775H9.99675C5.86075 16.1025 2.14675 13.2035 0.06075 8.34851C-0.02025 8.15951 -0.02025 7.94551 0.06075 7.75651C2.14675 2.90251 5.86175 0.00350586 9.99675 0.000505859C9.99875 -0.000494141 9.99875 -0.000494141 9.99975 0.000505859C10.0018 -0.000494141 10.0018 -0.000494141 10.0028 0.000505859C14.1388 0.00350586 17.8527 2.90251 19.9387 7.75651C20.0207 7.94551 20.0207 8.15951 19.9387 8.34851C17.8537 13.2035 14.1388 16.1025 10.0028 16.1055H10.0017Z"
                          fill=""
                        />
                      </g>
                    </svg>
                  </Tooltip>
                </span>

                <span
                  onClick={() => {
                    openAssetImage("image", item);
                  }}
                  className="img-edit"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-img"
                >
                  {" "}
                  <Tooltip   arrow>
                    <svg
                      width="20"
                      height="19"
                      viewB
                      ox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.751 18.9397H11.498C11.084 18.9397 10.748 18.6037 10.748 18.1897C10.748 17.7757 11.084 17.4397 11.498 17.4397H18.751C19.165 17.4397 19.501 17.7757 19.501 18.1897C19.501 18.6037 19.165 18.9397 18.751 18.9397Z"
                        fill="white"
                      />
                      <mask
                        id="mask0_2688_3316"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="18"
                        height="19"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 0.000244141H17.1806V18.9398H0V0.000244141Z"
                          fill=""
                        />
                      </mask>
                      <g mask="url(#mask0_2688_3316)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.1103 2.01689L1.69527 13.7919C1.52427 14.0059 1.46127 14.2819 1.52427 14.5469L2.20527 17.4319L5.24427 17.3939C5.53327 17.3909 5.80027 17.2619 5.97727 17.0419C9.19427 13.0169 15.3273 5.34289 15.5013 5.11789C15.6653 4.85189 15.7293 4.47589 15.6433 4.11389C15.5553 3.74289 15.3243 3.42789 14.9913 3.22689C14.9203 3.17789 13.2353 1.86989 13.1833 1.82889C12.5493 1.32089 11.6243 1.40889 11.1103 2.01689ZM1.61327 18.9399C1.26627 18.9399 0.964274 18.7019 0.883274 18.3629L0.0642743 14.8919C-0.104726 14.1729 0.0632743 13.4309 0.524274 12.8549L9.94427 1.07289C9.94827 1.06889 9.95127 1.06389 9.95527 1.05989C10.9883 -0.175113 12.8563 -0.357113 14.1163 0.653887C14.1663 0.692887 15.8393 1.99289 15.8393 1.99289C16.4473 2.35489 16.9223 3.00189 17.1023 3.76789C17.2813 4.52589 17.1513 5.30789 16.7343 5.96889C16.7033 6.01789 16.6763 6.05989 7.14827 17.9799C6.68927 18.5519 6.00127 18.8849 5.26227 18.8939L1.62327 18.9399H1.61327Z"
                          fill=""
                        />
                      </g>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.2234 8.68487C14.0634 8.68487 13.9034 8.63387 13.7664 8.52987L8.31442 4.34187C7.98642 4.08987 7.92442 3.61987 8.17642 3.28987C8.42942 2.96187 8.89942 2.90087 9.22842 3.15287L14.6814 7.33987C15.0094 7.59187 15.0714 8.06287 14.8184 8.39187C14.6714 8.58387 14.4484 8.68487 14.2234 8.68487Z"
                        fill=""
                      />
                    </svg>
                  </Tooltip>
                </span>
              </div>
              <ReactImageFallback
                src={item[1].Contentparams.contentdescription}
                fallbackImage="../../../assets/img/fallbackImage.jpg"
                initialImage="../../../assets/img/fallbackImage.jpg"
                alt="Logo"
              />
            </div>
          );
        } else if (item[1].Contenttype === "Illustration") {
          IlluList.push(
            <div className="grid-list">
              <div className="icon-wrap">
                <span
                  className="img-view"
                  onClick={() => {
                    viewImagePriview(item[1].Contentparams.contentdescription);
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#view-img"
                >
                  {" "}
                  <Tooltip arrow>
                    <svg
                      width="20"
                      height="17"
                      viewBox="0 0 20 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.99889 5.64136C8.66889 5.64136 7.58789 6.72336 7.58789 8.05336C7.58789 9.38236 8.66889 10.4634 9.99889 10.4634C11.3289 10.4634 12.4109 9.38236 12.4109 8.05336C12.4109 6.72336 11.3289 5.64136 9.99889 5.64136ZM9.99889 11.9634C7.84189 11.9634 6.08789 10.2094 6.08789 8.05336C6.08789 5.89636 7.84189 4.14136 9.99889 4.14136C12.1559 4.14136 13.9109 5.89636 13.9109 8.05336C13.9109 10.2094 12.1559 11.9634 9.99889 11.9634Z"
                        fill=""
                      />
                      <mask
                        id="mask0_2688_3325"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="17"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 0.000244141H19.9998V16.1052H0V0.000244141Z"
                          fill=""
                        />
                      </mask>
                      <g mask="url(#mask0_2688_3325)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.56975 8.05251C3.42975 12.1615 6.56275 14.6045 9.99975 14.6055C13.4368 14.6045 16.5698 12.1615 18.4298 8.05251C16.5698 3.94451 13.4368 1.50151 9.99975 1.50051C6.56375 1.50151 3.42975 3.94451 1.56975 8.05251V8.05251ZM10.0017 16.1055H9.99775H9.99675C5.86075 16.1025 2.14675 13.2035 0.06075 8.34851C-0.02025 8.15951 -0.02025 7.94551 0.06075 7.75651C2.14675 2.90251 5.86175 0.00350586 9.99675 0.000505859C9.99875 -0.000494141 9.99875 -0.000494141 9.99975 0.000505859C10.0018 -0.000494141 10.0018 -0.000494141 10.0028 0.000505859C14.1388 0.00350586 17.8527 2.90251 19.9387 7.75651C20.0207 7.94551 20.0207 8.15951 19.9387 8.34851C17.8537 13.2035 14.1388 16.1025 10.0028 16.1055H10.0017Z"
                          fill=""
                        />
                      </g>
                    </svg>
                  </Tooltip>
                </span>

                <span
                  onClick={() => {
                    openAssetImage("illustration", item);
                  }}
                  className="img-edit"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-img"
                >
                  {" "}
                  <Tooltip   arrow>
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.751 18.9397H11.498C11.084 18.9397 10.748 18.6037 10.748 18.1897C10.748 17.7757 11.084 17.4397 11.498 17.4397H18.751C19.165 17.4397 19.501 17.7757 19.501 18.1897C19.501 18.6037 19.165 18.9397 18.751 18.9397Z"
                        fill="white"
                      />
                      <mask
                        id="mask0_2688_3316"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="18"
                        height="19"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 0.000244141H17.1806V18.9398H0V0.000244141Z"
                          fill=""
                        />
                      </mask>
                      <g mask="url(#mask0_2688_3316)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.1103 2.01689L1.69527 13.7919C1.52427 14.0059 1.46127 14.2819 1.52427 14.5469L2.20527 17.4319L5.24427 17.3939C5.53327 17.3909 5.80027 17.2619 5.97727 17.0419C9.19427 13.0169 15.3273 5.34289 15.5013 5.11789C15.6653 4.85189 15.7293 4.47589 15.6433 4.11389C15.5553 3.74289 15.3243 3.42789 14.9913 3.22689C14.9203 3.17789 13.2353 1.86989 13.1833 1.82889C12.5493 1.32089 11.6243 1.40889 11.1103 2.01689ZM1.61327 18.9399C1.26627 18.9399 0.964274 18.7019 0.883274 18.3629L0.0642743 14.8919C-0.104726 14.1729 0.0632743 13.4309 0.524274 12.8549L9.94427 1.07289C9.94827 1.06889 9.95127 1.06389 9.95527 1.05989C10.9883 -0.175113 12.8563 -0.357113 14.1163 0.653887C14.1663 0.692887 15.8393 1.99289 15.8393 1.99289C16.4473 2.35489 16.9223 3.00189 17.1023 3.76789C17.2813 4.52589 17.1513 5.30789 16.7343 5.96889C16.7033 6.01789 16.6763 6.05989 7.14827 17.9799C6.68927 18.5519 6.00127 18.8849 5.26227 18.8939L1.62327 18.9399H1.61327Z"
                          fill=""
                        />
                      </g>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.2234 8.68487C14.0634 8.68487 13.9034 8.63387 13.7664 8.52987L8.31442 4.34187C7.98642 4.08987 7.92442 3.61987 8.17642 3.28987C8.42942 2.96187 8.89942 2.90087 9.22842 3.15287L14.6814 7.33987C15.0094 7.59187 15.0714 8.06287 14.8184 8.39187C14.6714 8.58387 14.4484 8.68487 14.2234 8.68487Z"
                        fill=""
                      />
                    </svg>
                  </Tooltip>
                </span>
              </div>
              <ReactImageFallback
                src={item[1].Contentparams.contentdescription}
                fallbackImage="../../../assets/img/fallbackImage.jpg"
                initialImage="../../../assets/img/fallbackImage.jpg"
                alt="Logo"
              />
            </div>
          );
        } else if (item[1].Contenttype === "Video") {
          VideoList.push(
            <div className="grid-list">
              <div className="icon-wrap">
                <span
                  className="img-view"
                  onClick={() => {
                    viewVideoPreview(item[1].Contentparams.contentdescription);
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#view-img"
                >
                  {" "}
                  <Tooltip arrow>
                    <svg
                      width="20"
                      height="17"
                      viewBox="0 0 20 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.99889 5.64136C8.66889 5.64136 7.58789 6.72336 7.58789 8.05336C7.58789 9.38236 8.66889 10.4634 9.99889 10.4634C11.3289 10.4634 12.4109 9.38236 12.4109 8.05336C12.4109 6.72336 11.3289 5.64136 9.99889 5.64136ZM9.99889 11.9634C7.84189 11.9634 6.08789 10.2094 6.08789 8.05336C6.08789 5.89636 7.84189 4.14136 9.99889 4.14136C12.1559 4.14136 13.9109 5.89636 13.9109 8.05336C13.9109 10.2094 12.1559 11.9634 9.99889 11.9634Z"
                        fill=""
                      />
                      <mask
                        id="mask0_2688_3325"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="17"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 0.000244141H19.9998V16.1052H0V0.000244141Z"
                          fill=""
                        />
                      </mask>
                      <g mask="url(#mask0_2688_3325)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.56975 8.05251C3.42975 12.1615 6.56275 14.6045 9.99975 14.6055C13.4368 14.6045 16.5698 12.1615 18.4298 8.05251C16.5698 3.94451 13.4368 1.50151 9.99975 1.50051C6.56375 1.50151 3.42975 3.94451 1.56975 8.05251V8.05251ZM10.0017 16.1055H9.99775H9.99675C5.86075 16.1025 2.14675 13.2035 0.06075 8.34851C-0.02025 8.15951 -0.02025 7.94551 0.06075 7.75651C2.14675 2.90251 5.86175 0.00350586 9.99675 0.000505859C9.99875 -0.000494141 9.99875 -0.000494141 9.99975 0.000505859C10.0018 -0.000494141 10.0018 -0.000494141 10.0028 0.000505859C14.1388 0.00350586 17.8527 2.90251 19.9387 7.75651C20.0207 7.94551 20.0207 8.15951 19.9387 8.34851C17.8537 13.2035 14.1388 16.1025 10.0028 16.1055H10.0017Z"
                          fill=""
                        />
                      </g>
                    </svg>
                  </Tooltip>
                </span>

                <span
                  onClick={() => {
                    openAssetImage("video", item);
                  }}
                  className="img-edit"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-img"
                >
                  {" "}
                  <Tooltip   arrow>
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.751 18.9397H11.498C11.084 18.9397 10.748 18.6037 10.748 18.1897C10.748 17.7757 11.084 17.4397 11.498 17.4397H18.751C19.165 17.4397 19.501 17.7757 19.501 18.1897C19.501 18.6037 19.165 18.9397 18.751 18.9397Z"
                        fill="white"
                      />
                      <mask
                        id="mask0_2688_3316"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="18"
                        height="19"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 0.000244141H17.1806V18.9398H0V0.000244141Z"
                          fill=""
                        />
                      </mask>
                      <g mask="url(#mask0_2688_3316)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.1103 2.01689L1.69527 13.7919C1.52427 14.0059 1.46127 14.2819 1.52427 14.5469L2.20527 17.4319L5.24427 17.3939C5.53327 17.3909 5.80027 17.2619 5.97727 17.0419C9.19427 13.0169 15.3273 5.34289 15.5013 5.11789C15.6653 4.85189 15.7293 4.47589 15.6433 4.11389C15.5553 3.74289 15.3243 3.42789 14.9913 3.22689C14.9203 3.17789 13.2353 1.86989 13.1833 1.82889C12.5493 1.32089 11.6243 1.40889 11.1103 2.01689ZM1.61327 18.9399C1.26627 18.9399 0.964274 18.7019 0.883274 18.3629L0.0642743 14.8919C-0.104726 14.1729 0.0632743 13.4309 0.524274 12.8549L9.94427 1.07289C9.94827 1.06889 9.95127 1.06389 9.95527 1.05989C10.9883 -0.175113 12.8563 -0.357113 14.1163 0.653887C14.1663 0.692887 15.8393 1.99289 15.8393 1.99289C16.4473 2.35489 16.9223 3.00189 17.1023 3.76789C17.2813 4.52589 17.1513 5.30789 16.7343 5.96889C16.7033 6.01789 16.6763 6.05989 7.14827 17.9799C6.68927 18.5519 6.00127 18.8849 5.26227 18.8939L1.62327 18.9399H1.61327Z"
                          fill=""
                        />
                      </g>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.2234 8.68487C14.0634 8.68487 13.9034 8.63387 13.7664 8.52987L8.31442 4.34187C7.98642 4.08987 7.92442 3.61987 8.17642 3.28987C8.42942 2.96187 8.89942 2.90087 9.22842 3.15287L14.6814 7.33987C15.0094 7.59187 15.0714 8.06287 14.8184 8.39187C14.6714 8.58387 14.4484 8.68487 14.2234 8.68487Z"
                        fill=""
                      />
                    </svg>
                  </Tooltip>
                </span>
              </div>

              {item[1].Contentparams.contentdescription !== undefined &&
              item[1].Contentparams.contentdescription !== "" ? (
                <video
                  className="video-width"
                  src={item[1].Contentparams.contentdescription}
                />
              ) : (
                <ReactImageFallback
                  src={item[1].Contentparams.contentdescription}
                  fallbackImage="../../../assets/img/videobutton.png"
                  initialImage="../../../assets/img/videobutton.png"
                  alt="Video"
                  className="object-cover"
                />
              )}
            </div>
          );
        }
      });
      if (assetType === "IM") {
        return ImageList;
      } else if (assetType === "IL") {
        return IlluList;
      } else if (assetType === "VD") {
        return VideoList;
      }
    }
  };

  return (
    <React.Fragment>
      {activeTemplate === true && (
        <TemplateRepo setchanges={props.setchanges} />
      )}

      {/* {activeTemplate === true && <TemplateRepo />} */}
      {/* {!projectRoleEdit&& */}

      <div className={!Toggleright ? "right-sec" : "right-sec is-visible"}>
        <div className=" tab-view d-flex">
          <div className="tab-content">
            {AdminRole === 1 && projectRoleEdit ? (
              ""
            ) : (
              <div id="accordion-1" className="accordion">
                {activeStyle === true && (
                  <Styles
                    setmadeChange={props.setmadeChange}
                    setchanges={props.setchanges}
                    showWarning={props.showWarning}
                    setshowWarning={props.setshowWarning}
                    previewEditMode={props.previewEditMode}
                    setpreviewEditMode={props.setpreviewEditMode}
                  />
                )}
                {activeText === true && (
                  <OstData
                    // setOstValidation1={props.setOstValidation1}
                    setOstValidation={props.setOstValidation}
                    setchanges={props.setchanges}
                    showWarning={props.showWarning}
                    setshowWarning={props.setshowWarning}
                    previewEditMode={props.previewEditMode}
                    setpreviewEditMode={props.setpreviewEditMode}
                  />
                )}
                {activeAsset === true && (
                  <React.Fragment>
                    <div className="card">
                      <div className="card-header" id="heading-3">
                        <h5 className="mb-0">
                          <a
                            className="btn btn-link collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-3"
                            aria-expanded="false"
                            aria-controls="collapse-3"
                          >
                            {" "}
                            Images{" "}
                          </a>
                        </h5>
                      </div>

                      <div
                        id="collapse-3"
                        className="collapse"
                        aria-labelledby="heading-3"
                        data-bs-parent="#accordion-1"
                      >
                        <div className="card-body" id="child-1">
                          <div className="card-inner  custom-height">
                            <div className="grid-box">
                              {ListAssets("IM")}
                              {imageRepo.length > 0 ? (
                                imageRepo.map((image, i) => {
                                  let mediaurl = "";

                                  if (
                                    image.mediaurl !== undefined &&
                                    image.mediaurl !== null &&
                                    image.mediaurl !== ""
                                  ) {
                                    mediaurl = image.mediaurl;
                                  } else {
                                    mediaurl = image;
                                  }
                                })
                              ) : (
                                // <p ></p>

                                <p className="text-center blink">
                                  No images found
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="heading-4">
                        <h5 className="mb-0">
                          <a
                            className="btn btn-link collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-4"
                            aria-expanded="false"
                            aria-controls="collapse-4"
                          >
                            {" "}
                            Illustration{" "}
                          </a>
                        </h5>
                      </div>

                      <div
                        id="collapse-4"
                        className="collapse"
                        aria-labelledby="heading-4"
                        data-bs-parent="#accordion-1"
                      >
                        <div className="card-body" id="child-1">
                          <div className="card-inner  custom-height">
                            <div className="grid-box">
                              {ListAssets("IL")}
                              {illueRepo.length > 0 ? (
                                illueRepo.map((image, i) => {
                                  let mediaurl = "";

                                  if (
                                    image.mediaurl !== undefined &&
                                    image.mediaurl !== null &&
                                    image.mediaurl !== ""
                                  ) {
                                    mediaurl = image.mediaurl;
                                  } else {
                                    mediaurl = image;
                                  }
                                })
                              ) : (
                                <p className="text-center blink">
                                  No Illustrations found
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading-4">
                        <h5 className="mb-0">
                          <a
                            className="btn btn-link collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-6"
                            aria-expanded="false"
                            aria-controls="collapse-6"
                          >
                            {" "}
                            Videos{" "}
                          </a>
                        </h5>
                      </div>

                      <div
                        id="collapse-6"
                        className="collapse"
                        aria-labelledby="heading-4"
                        data-bs-parent="#accordion-1"
                      >
                        <div className="card-body" id="child-1">
                          <div className="card-inner  custom-height">
                            <div className="grid-box">
                              {ListAssets("VD")}
                              {videoRepo.length > 0 ? (
                                videoRepo.map((video, i) => {

                                  let mediaurl = "";
                                  if (
                                    video.mediaurl !== undefined &&
                                    video.mediaurl !== null &&
                                    video.mediaurl !== ""
                                  ) {
                                    mediaurl = video.mediaurl;
                                  } else {
                                    mediaurl = video;
                                  }
                                })
                              ) : (
                                <p className="text-center blink">
                                  No Videos found
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}

                {activeAudio === true && (
                  <AudioScript
                    setASValidation={props.setASValidation}
                    setchanges={props.setchanges}
                    showWarning={props.showWarning}
                    setshowWarning={props.setshowWarning}
                    previewEditMode={props.previewEditMode}
                    setpreviewEditMode={props.setpreviewEditMode}
                  />
                )}
                {/* {activeTemplate === true && <TemplateList />} */}
              </div>
            )}
          </div>
          {AdminRole === 1 && projectRoleEdit ? (
            ""
          ) : (
            <div className="tab-head">
              <ul>
                <li
                  className={activeStyle ? "active" : ""}
                  onClick={() => {
                    if (!disableEdit) {
                      activeTab("style");
                    }
                  }}
                  style={{ cursor: !disableEdit ? "pointer" : "not-allowed" }}
                >
                  <a>
                    <span>
                      <img src="../../../assets/img/styles-icon.svg" />
                    </span>
                    <small>Style</small>
                  </a>
                </li>
                <li
                  className={activeText ? "active" : ""}
                  onClick={() => {
                    if (!disableEdit) {
                      activeTab("text");
                    }
                  }}
                  style={{ cursor: !disableEdit ? "pointer" : "not-allowed" }}
                >
                  <a>
                    <span>
                      <img src="../../../assets/img/text-icon.svg" />
                    </span>
                    <small>Text</small>
                  </a>
                </li>
                <li
                  className={activeAsset ? "active" : ""}
                  onClick={() => {
                    if (!disableEdit) {
                      activeTab("asset");
                    }
                  }}
                  style={{ cursor: !disableEdit ? "pointer" : "not-allowed" }}
                >
                  <a>
                    <span>
                      <img src="../../../assets/img/Assets-icon.svg" />
                    </span>
                    <small>Visual Asset</small>
                  </a>
                </li>
                <li
                  className={activeAudio ? "active" : ""}
                  onClick={() => {
                    if (!disableEdit) {
                      activeTab("audio");
                    }
                  }}
                  style={{ cursor: !disableEdit ? "pointer" : "not-allowed" }}
                >
                  <a>
                    <span>
                      <img src="../../../assets/img/Audio-icon.svg" />
                    </span>
                    <small>Audio</small>
                  </a>
                </li>
                <li
                  className={activeTemplate ? "active" : ""}
                  onClick={() => {
                    if (!disableEdit) {
                      props.setTemplateMode(true);
                      activeTab("template");
                    }
                  }}
                  style={{ cursor: !disableEdit ? "pointer" : "not-allowed" }}
                >
                  <a>
                    <span>
                      <img src="../../../assets/img/Template-icon.svg" />
                    </span>
                    <small>Template</small>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <ImagePreview
        closePreview={() => setOpenImagePreview(false)}
        openImagePreview={openImagePreview}
        selectedImage={selectedImageFile}
      />
      <ImageEdit
        item={selectedItem}
        type={AssetType}
        closePreview={() => setOpenAssetModals(false)}
        openAssetModals={openAssetModals}
        setchanges={props.setchanges}
      />
      <VideoPreview
        closePreview={() => setOpenVideoPreview(false)}
        openVideoPreview={openVideoPreview}
        selectedVideo={selectedVideoFile}
      />
    </React.Fragment>
  );
}
