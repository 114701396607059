import { useHistory } from 'react-router';
import Tooltip from '@mui/material/Tooltip';
import fileDownload from 'js-file-download';
import { Badge, Dropdown, Spinner, Table } from 'react-bootstrap';
import React, { useEffect, useState, lazy, Suspense } from 'react';

import {
  checkIcon,
  resetIcon,
  reportIcon,
  searchIcon,
  deleteIcon,
  ellipseIcon,
  aiExtendIcon,
  settingsIcon,
  arrowDownIcon,
  noProjectIcon,
  privateLockIcon,
} from '../../../constants/icon';
import PageLoader from '../../../services/pageLoader';
import useDebounce from '../../../hooks/debounceHook';
import CustomModal from '../../../services/CustomModal';
import { activeOrg } from '../../../services/apiServices';
import { secToHrMinS } from '../../../lib/time-conversion';
import DataNotFound from '../../../components/dataNotFound';
import PaginationComponent from '../../../components/pagination';
import HeaderNavigation from '../../../components/headerNavigation';
import { exportClients, getClients } from '../../../services/api.service';
import { convertDateFormat, showToaster } from '../../../services/helper';
import FormFloatingInput from '../../../components/form/formFloatingInput';
import PagePagination from '../../../components/pagination/pagePagination';

const ClientSettings = lazy(() => import('./clientSettings'));

export default function ClientList() {
  const history = useHistory();
  const { debounce, debouncedValue } = useDebounce();

  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [clients, setClients] = useState([]);
  const [searchBy, setSearchBy] = useState('name');
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeStatus, setActiveStatus] = useState();
  const [totalClients, setTotalClients] = useState(0);
  const [activeLoad, setActiveLoad] = useState(false);
  const [clientIdState, setClientIdState] = useState();
  const [clientSetting, setClientSetting] = useState('');
  const [isExporting, setIsExporting] = useState(false);
  const [searchByTemp, setSearchByTemp] = useState('name');
  const [tempSearchName, setTempSearchName] = useState('');
  const [openActiveModal, setOpenActiveModal] = useState(false);

  const totalPages = Math.ceil(totalClients / limit);

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);

    async function getClientsData() {
      try {
        let query = `pageNumber=${currentPage}&limit=${limit}`;
        if (search) query += '&searchBy=' + searchBy + '&searchQuery=' + search;

        const clients = await getClients(query);

        if (isMounted) {
          setIsLoading(false);
          setClients(clients.data);
          setTotalClients(clients.total);
        }
      } catch (error) {
        const err = JSON.parse(JSON.stringify(error.response));
        console.log({ getClientsError: err });

        if (err?.data?.error?.includes('Invalid auth')) {
          return history.replace('/old-login');
        }

        setIsLoading(false);
        showToaster('Something went wrong. Pleast try again later', 'error');
      }
    }

    getClientsData();
    return () => (isMounted = false);
  }, [limit, currentPage, search, searchBy]);

  useEffect(() => {
    if (debouncedValue !== null) {
      setSearch(debouncedValue);
      setSearchBy(searchByTemp);
    }
  }, [debouncedValue]);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const exportHandler = async () => {
    setIsExporting(true);
    const file = await exportClients({});
    fileDownload(file, 'clientList.csv');
    setIsExporting(false);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
  };

  const handleLastPage = () => {
    if (currentPage !== totalPages) setCurrentPage(totalPages);
  };

  const handleSearch = e => {
    const { value } = e.target;
    debounce(value, 1000);
    setTempSearchName(value);
  };

  const openActiveModalBox = (status, clientId) => {
    setOpenActiveModal(true);
    setActiveStatus(status);
    setClientIdState(clientId);
  };

  const onSelectSearchBy = value => {
    if (search) {
      setSearchBy(value);
      return setSearchByTemp(value);
    }
    setSearchByTemp(value);
  };

  const CustomToggleFilter = React.forwardRef(({ children, onClick }, ref) => (
    <div
      role="button"
      className="common-dropdown-filter"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
    >
      {children}
    </div>
  ));

  const activateDeactivateHandler = async () => {
    setActiveLoad(true);
    const response = await activeOrg(clientIdState, { isActive: activeStatus });
    if (response) {
      setActiveLoad(false);
      setOpenActiveModal(false);
    }
    if (response?.status === 200) {
      setIsLoading(true);
      let query = `pageNumber=${currentPage}&limit=${limit}`;
      if (search) query += '&searchBy=' + searchBy + '&searchQuery=' + search;
      const clientResponse = await getClients(query);
      setClients(clientResponse.data);
      setIsLoading(false);
    }
  };

  const handleReportIconClick = (subdomain, clientId) => {
    const { hostname } = window.location;

    const token = JSON.parse(localStorage.getItem('_user'));

    let routeTo = `https://${subdomain}.app.vidayopro.com/usages-tracking?token=${token}&client=${clientId}`;
    if (hostname.includes('.dev.')) {
      routeTo = `https://${subdomain}.dev.contentpro.ai/usages-tracking?token=${token}&client=${clientId}`;
    } else if (hostname.includes('.uat.')) {
      routeTo = `https://${subdomain}.uat.contentpro.ai/usages-tracking?token=${token}&client=${clientId}`;
    } else if (hostname.includes('localhost')) {
      routeTo = `http://${subdomain}.localhost:3000/usages-tracking?token=${token}&client=${clientId}`;
    }

    window.open(routeTo, '_blank');
  };

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <ClientSettings onHide={() => setClientSetting('')} client={clientSetting} />
      </Suspense>
      <PageLoader showLoader={isLoading} />
      <HeaderNavigation />
      <div className="secondary-header secondary-sticky-header secondary-header-spacing">
        <div className="common-dropdown-filter-wrap">
          <Dropdown onSelect={onSelectSearchBy}>
            <Dropdown.Toggle as={CustomToggleFilter} id="dropdown-custom-components">
              <span className="dropdown-icon dropdown-max-width-sm">
                Search By
                {arrowDownIcon}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="common-dropdown-menu">
              <Dropdown.Item eventKey="name" active={searchByTemp === 'name'}>
                Name
              </Dropdown.Item>
              <Dropdown.Item eventKey="subdomain" active={searchByTemp === 'subdomain'}>
                Subdomain
              </Dropdown.Item>
              <Dropdown.Item eventKey="email" active={searchByTemp === 'email'}>
                Email
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <FormFloatingInput
          id="list"
          type="search"
          placeholder=""
          leftIcon={searchIcon}
          onChange={handleSearch}
          inputValue={tempSearchName}
          className="custom-search-form"
          label={`Search by ${searchByTemp}`}
        />
        <div>
          <button
            className="bg-btn-primary btn btn-primary"
            disabled={isExporting}
            onClick={exportHandler}
          >
            {isExporting ? (
              <>
                Exporting &nbsp;&nbsp;
                <Spinner animation="border" style={{ color: '#ffffff' }} size="sm" />
              </>
            ) : (
              <>Export to CSV</>
            )}
          </button>
        </div>
      </div>
      {clients.length ? (
        <>
          <div className="common-border-table template-table-list">
            <Table responsive className="common-table usages-table">
              <thead>
                <tr>
                  <th className="text-start">Name</th>
                  <th className="text-start ">Subdomain</th>
                  <th className="text-center col-lg-2">Email</th>
                  <th className="text-center  col-lg-1">Phone Number</th>
                  <th className="text-center">Total Videos</th>
                  <th className="text-center col-lg-1">Video Duration</th>
                  <th className="text-center col-lg-1">Total Size</th>
                  <th className="text-center col-lg-1">Rendered Duration</th>
                  <th className="text-center">Total Renders</th>
                  <th className="text-center  col-lg-1">Status</th>
                  <th className="text-center">Deleted</th>
                  <th className="text-center col-lg-1">Created At</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {clients.map(client => (
                  <tr key={client._id}>
                    <td style={{ height: 40 }}>
                      <Tooltip title={client?.name} arrow>
                        <span>{client.name}</span>
                      </Tooltip>
                    </td>
                    <td style={{ height: 40 }}>
                      <Tooltip title={client?.subdomain} arrow>
                        <span>{client.subdomain}</span>
                      </Tooltip>
                    </td>
                    <td style={{ height: 40 }}>
                      <Tooltip title={client?.email} arrow>
                        <span>{client.email}</span>
                      </Tooltip>
                    </td>
                    <td style={{ height: 40 }}>
                      <span>{client.phonenumber}</span>
                    </td>

                    <td style={{ height: 40 }}>
                      <span>{client?.projectAnalytics?.totalVideos ?? 0}</span>
                    </td>
                    <td style={{ height: 40 }}>
                      <span>{secToHrMinS(client?.projectAnalytics?.totalVideoDuration) ?? 0}</span>
                    </td>

                    <td style={{ height: 40 }}>
                      <span>
                        {client?.projectAnalytics?.totalSize
                          ? (client?.projectAnalytics?.totalSize / 1024 / 1024).toFixed(2)
                          : 0}{' '}
                        MB
                      </span>
                    </td>
                    <td style={{ height: 40 }}>
                      <span>{secToHrMinS(client?.projectAnalytics?.totalRenderDuration) ?? 0}</span>
                    </td>
                    <td style={{ height: 40 }}>
                      <span>{client?.projectAnalytics?.totalProjectRender ?? 0}</span>
                    </td>
                    <td className="status" style={{ height: 40 }}>
                      {client.isActive ? (
                        <Badge bg="success">Active</Badge>
                      ) : (
                        <Badge bg="secondary">Inactive</Badge>
                      )}
                    </td>
                    <td className="delete-box" style={{ height: 40 }}>
                      {client.isDeleted ? (
                        <Badge bg="danger">Yes</Badge>
                      ) : (
                        <Badge bg="secondary">No</Badge>
                      )}
                    </td>
                    <td style={{ height: 40 }}>
                      <span>{convertDateFormat(client.createdAt)}</span>
                    </td>
                    <td>
                      <div className="common-dropdown-wrap">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggleFilter} id="dropdown-custom-components">
                            <span className="ellipse-btn" role="button">
                              {ellipseIcon}
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="common-dropdown-menu script-dropdown-menu">
                            <Dropdown.Item eventKey="1">{deleteIcon} Delete</Dropdown.Item>
                            <Dropdown.Item
                              eventKey="2"
                              onClick={() =>
                                openActiveModalBox(client?.isActive ? 0 : 1, client._id)
                              }
                            >
                              {client.isActive ? (
                                <>{resetIcon} Deactivate</>
                              ) : (
                                <>{checkIcon} Activate</>
                              )}
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="3"
                              onClick={() => handleReportIconClick(client.subdomain, client._id)}
                            >
                              {reportIcon} Manage Reports
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="4"
                              onClick={() =>
                                history.push(
                                  `/template-list?client=${client.subdomain}&id=${client._id}`
                                )
                              }
                            >
                              {aiExtendIcon} Manage Templates
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="5" onClick={() => setClientSetting(client)}>
                              {settingsIcon} Settings
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="6">
                              {privateLockIcon} Reset Password
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="pagination-wrapper">
            <PagePagination
              itemsPerPage={limit}
              totalItems={totalClients}
              currentPage={currentPage}
              setItemsPerPage={value => setLimit(value)}
            />
            <PaginationComponent
              totalPages={totalPages}
              onNext={handleNextPage}
              onLast={handleLastPage}
              currentPage={currentPage}
              onFirst={handleFirstPage}
              onPageChange={handlePageChange}
              onPrevious={handlePreviousPage}
            />
          </div>
        </>
      ) : (
        <DataNotFound icon={noProjectIcon} title="No client found" />
      )}
      <CustomModal
        isOpen={openActiveModal}
        Closemodal={setOpenActiveModal}
        Header={`Are you sure you want to ${activeStatus ? 'Activate' : 'Deactivate'}?`}
        Buttonlabel={activeLoad ? (activeStatus ? 'Activating' : 'Deactivating') : 'Yes'}
        Buttonclick={activateDeactivateHandler}
      />
    </>
  );
}
