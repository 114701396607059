import React, { useState } from 'react';
// Icons
import { settingsIcon } from '../../../constants/icon';

import Sidebar from '../../../components/sidebar';
import InnerSidebar from '../../../components/sidebar/innerSidebar';
import HeaderNavigation from '../../../components/headerNavigation';
import InnerSidebarHeader from '../../../components/sidebar/innerSidebarHeader';
import InnerSidebarList from '../../../components/sidebar/innerSidebarList';
import UserDetail from './user/userDetail';

function UserDetails() {
  const [isInnerSidebarActive, setIsInnerSidebarActive] = useState(false);

  const hideSidebar = () => {
    setIsInnerSidebarActive(!isInnerSidebarActive);
  };
  return (
    <>
      <Sidebar />
      <main className="main-content-wrapper">
        <div className={`content-wrapper ${isInnerSidebarActive ? 'active' : ''}`}>
          <div className="content-block relative">
            <InnerSidebar className={isInnerSidebarActive ? 'show-sidebar' : 'hide-sidebar'}>
              {isInnerSidebarActive && (
                <>
                  <InnerSidebarHeader
                    title="Settings"
                    subTitle="Manage all setting"
                    icon={settingsIcon}
                  />
                  <InnerSidebarList />
                </>
              )}
            </InnerSidebar>
            <span className="show-hide-sidebar" onClick={hideSidebar}>
              {isInnerSidebarActive ? '<' : '>'}
            </span>
            <HeaderNavigation />
            <UserDetail />
          </div>
        </div>
      </main>
    </>
  );
}
export default UserDetails;
