import { Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { Modal, Nav, Spinner } from 'react-bootstrap';
import ReactImageFallback from 'react-image-fallback';

import {
  assetIcon,
  checkIcon,
  closeIcon,
  resetIcon,
  eyeOpenIcon,
  stockImageIcon,
  uploadFileIcon,
  plainSearchIcon,
  privateLockIcon,
} from '../../constants/icon';
import ViewModal from './viewModal';
import UploadAsset from './uploadAsset';
import StockImages from './stockImages';
import useDebounce from '../../hooks/debounceHook';
import { getAssetsV1 } from '../../services/api.service';
import { capitalizeFirstLetter } from '../../services/helper';
import Pagination from '../../modules/dashboard/project-priview/RightBar/pagination';

const ASSET = 'ASSET';
const STOCK = 'STOCK';
const UPLOAD = 'UPLOAD';

export default function ImageAssetRepo({ open, onHide, selectImage }) {
  const { debounce, debouncedValue } = useDebounce();

  const [posts, setPosts] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [keyWord, setKeyWord] = useState('');
  const [endPageNo, setEndPageNo] = useState(100);
  const [imagesRepo, setImagesRepo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tempKeyWord, setTempKeyWord] = useState('');
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [privateRepo, setPrivateRepo] = useState(false);
  const [imageErrorMsg, setImageErrorMsg] = useState('');
  const [imageRepoLoader, setImageRepoLoader] = useState(true);
  const [openImagePreview, setOpenImagePreview] = useState('');
  const [activeImageType, setActiveImageType] = useState(ASSET);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts?.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    if (open) {
      setPosts([]);
      setKeyWord('');
      setImagesRepo([]);
      setTempKeyWord('');
      setPrivateRepo(false);
      setActiveImageType(ASSET);
    }
  }, [open]);

  useEffect(() => {
    function getAssetRepo() {
      setImageRepoLoader(true);

      const request = {
        start: pageNo,
        end: endPageNo,
        media_type: 'IMAGE',
        search_term: keyWord,
        private: privateRepo ? 1 : 0,
      };

      getAssetsV1(request)
        .then(response => {
          if (response.data?.length) {
            setPosts(response.data);
            setImagesRepo(response.data);
            setImageRepoLoader(false);
          } else {
            setPosts([]);
            setImagesRepo([]);
            setImageRepoLoader(false);
            setImageErrorMsg('No images available');
          }
        })
        .catch(error => {
          console.log({ getAssetsError: error });

          setPosts([]);
          setImagesRepo([]);
          setImageRepoLoader(false);
          setImageErrorMsg('No images available');
        });
    }

    if (open && activeImageType === ASSET) {
      getAssetRepo();
    }
  }, [open, keyWord, endPageNo, pageNo, privateRepo, activeImageType]);

  useEffect(() => {
    if (debouncedValue !== null) {
      setKeyWord(debouncedValue);
    }
  }, [debouncedValue]);

  const selectAsset = medialUrl => {
    selectImage(medialUrl);
    onHide();
  };

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const onChangeSearchBox = e => {
    const value = e.target.value;
    setInputValue(value);
  };

  const setInputValue = value => {
    setTempKeyWord(value);
    debounce(value);
  };

  return (
    <>
      <Modal show={open} centered size="xl" backdrop="static">
        <Modal.Body className="replace-modal replace-of-replace-modal">
          <div onClick={onHide} className="close-priview">
            {closeIcon}
          </div>
          <div className="navtab-selector tab-assets">
            <Nav className="nav nav-tabs" role="tablist">
              <Nav.Item className="nav-item" onClick={() => setActiveImageType(ASSET)}>
                <button className={activeImageType === ASSET ? 'nav-link active' : 'nav-link'}>
                  <div className="cm-vs-container">
                    <div className="d-flex align-items-center">
                      <div className="cm-icon assets">{assetIcon}</div>
                      <div className="cm-asset-name">Assets</div>
                    </div>
                  </div>
                </button>
              </Nav.Item>
              <Nav.Item className="nav-item" onClick={() => setActiveImageType(STOCK)}>
                <button className={activeImageType === STOCK ? 'nav-link active' : 'nav-link'}>
                  <div className="cm-vs-container">
                    <div className="d-flex align-items-center">
                      <div className="cm-icon stock">{stockImageIcon}</div>
                      <div className="cm-asset-name">Stock</div>
                    </div>
                  </div>
                </button>
              </Nav.Item>
              <Nav.Item className="nav-item" onClick={() => setActiveImageType(UPLOAD)}>
                <button className={activeImageType === UPLOAD ? 'nav-link active' : 'nav-link'}>
                  <div className="cm-vs-container">
                    <div className="d-flex align-items-center">
                      <div className="cm-icon upload">{uploadFileIcon}</div>
                      <div className="cm-asset-name">Upload</div>
                    </div>
                  </div>
                </button>
              </Nav.Item>
            </Nav>
          </div>
          <div className="srch-temp-blk">
            {activeImageType === ASSET ? (
              <div className="d-flex align-items-center position-relative flex-wrap">
                <div className="d-flex align-items-center pro-temp-container mr-4">
                  <div className="cm-header .cm-subhead">Private Repo</div>
                  <Checkbox
                    color="primary"
                    checked={privateRepo}
                    onChange={e => setPrivateRepo(e.target.checked)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </div>

                <div className="srch-temp-wrapper">
                  <div className="srch-holder">
                    <div className="srch-icon">{plainSearchIcon}</div>

                    <div className="srch-inpt-holder">
                      <input
                        required
                        type="text"
                        name="focus"
                        id="searchBtn1"
                        value={tempKeyWord}
                        onChange={onChangeSearchBox}
                        placeholder="Search asset images"
                        className="form-control srch-input"
                      />

                      <button
                        type="reset"
                        className="search-close"
                        onClick={() => setInputValue('')}
                      >
                        {resetIcon}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : activeImageType === STOCK ? (
              <StockImages selectImage={selectAsset} />
            ) : (
              activeImageType === UPLOAD && <UploadAsset selectAsset={selectAsset} type="image" />
            )}
            {activeImageType === ASSET ? (
              <div className="cm-st-gallery my-3">
                {!imageRepoLoader ? (
                  imagesRepo.length ? (
                    <div className="cm-gallery-wrapper">
                      {currentPosts.map(image => {
                        let mediaurl = '';

                        if (image.thumbnailurl) {
                          mediaurl = image.thumbnailurl;
                        } else if (image.thumbnailurl === null) {
                          mediaurl = image.mediaurl;
                        } else {
                          mediaurl = image;
                        }

                        return (
                          <div className="cm-gallery-container replace-img" key={mediaurl}>
                            <div className="img-info">
                              {image.private === 1 && <span>{privateLockIcon}</span>}
                            </div>
                            <div className="icon-wrap">
                              <span
                                data-bs-toggle="modal"
                                data-bs-target="#view-img"
                                className="img-view text-white"
                                onClick={() => setOpenImagePreview(image.mediaurl)}
                              >
                                {eyeOpenIcon}
                              </span>

                              <span
                                data-bs-toggle="modal"
                                data-bs-target="#edit-img"
                                className="img-edit text-white"
                                onClick={() => selectAsset(image.mediaurl)}
                              >
                                {checkIcon}
                              </span>
                            </div>
                            <ReactImageFallback
                              src={mediaurl}
                              alt="templates"
                              className="img-fluid"
                              initialImage="../../../assets/img/fallbackImage.jpg"
                              fallbackImage="../../../assets/img/fallbackImage.jpg"
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="v-gal-empty-blk">
                      <div className="no-txt-selected blink">
                        {capitalizeFirstLetter(imageErrorMsg)}
                      </div>
                    </div>
                  )
                ) : (
                  <Spinner
                    variant="primary"
                    animation="border"
                    className="position-absolute position-center"
                  />
                )}
              </div>
            ) : null}
            {activeImageType === ASSET && imagesRepo.length > 0 && (
              <Pagination
                paginate={paginate}
                postsPerPage={postsPerPage}
                totalPosts={posts.length}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>

      <ViewModal size="lg" open={!!openImagePreview} onHide={() => setOpenImagePreview('')}>
        <img src={openImagePreview} alt="image_view" className="w-100" />
      </ViewModal>
    </>
  );
}
