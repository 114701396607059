import { bindActionCreators } from 'redux';
import Accordion from 'react-bootstrap/Accordion';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import CustomToggle from './CustomToggle';
import { FONT_URL } from '../../constants';
import { actionCreator } from '../../store';
import EditableContent from '../EditableContent';
import CustomModal from '../../services/CustomModal';
import { crossIcon, plusIcon } from '../../constants/icon';
import { getProjectStyles } from '../../services/api.service';
import { getProjectQueryData, showToaster } from '../../services/helper';
import { CreateSilde, CreateSildeManual } from '../../services/DataHelper';

export default function AccordionItem(props) {
  const {
    module,
    moduleId,
    isActive,
    cacheProject,
    setActiveKeys,
    onHeaderClick,
    clientSetting,
    routeToChatGPT,
    isHeaderActive,
    updateSlideName,
    removeSlideData,
    removeModuleData,
    updateModuleName,
    showModuleRemoveIcon,
    setIsHeaderActive,
  } = props;

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { AddSlide, SelectSlide, RemoveSlide, RemoveModule, SelectModule } = bindActionCreators(
    actionCreator,
    dispatch
  );

  const project = useSelector(state => state.project);

  const [deleteModuleId, setDeleteModuleId] = useState('');
  const [deleteSlideId, setDeleteSlideId] = useState('');

  const slides = Object.entries(module.slides).map(([slideId, slideValue]) => ({
    slideId,
    name: slideValue.SlideName,
  }));

  useEffect(() => {
    async function init() {
      try {
        const { from, module, action } = getProjectQueryData();

        if (from === 'generate-videos-inputs' && action === 'add-video' && moduleId === module) {
          SelectModule({ moduleId: module });
          await createSlide({ storeData: true });
          setActiveKeys([module]);
          history.push(`/edit-projects/${params.id}`);
        }
      } catch (error) {
        console.log({ AccordionItemUseEffectError: error });
        window.location.replace(window.location.pathname);
      }
    }

    init();
  }, []);

  const onChangeSlideName = name => updateSlideName(name);
  const onChangeModuleName = name => updateModuleName(name);

  const handleSlideClick = slideId => {
    handleModuleClick(moduleId);
    SelectSlide(slideId);
    setIsHeaderActive(moduleId);
  };

  const handleModuleClick = moduleId => {
    if (project.ActiveModule !== moduleId) {
      SelectModule({ moduleId });
    }
  };

  const removeModule = async () => {
    if (showModuleRemoveIcon) {
      const data = await removeModuleData(deleteModuleId);

      if (data?.ok) {
        RemoveModule(deleteModuleId);
        showToaster(`Module ${deleteModuleId} Deleted Successfully`, 'success');
        setDeleteModuleId('');
      }
    }
  };

  const createSlide = async argsData => {
    if (clientSetting.slideLimit > slides.length) {
      const lastSlide = slides[slides.length - 1].slideId;

      const slideNumber = lastSlide.replace('SL0', '');
      const slideNumberSplit = slideNumber.split('SL')[1];

      let data;
      if (slideNumberSplit) data = parseInt(slideNumberSplit) + 1;
      else data = parseInt(slideNumber) + 1;

      const newSlideId = data < 10 ? 'SL0' + data : 'SL' + data;
      const newSlideNumber = newSlideId.replace('SL', '');

      let newSlide;

      if (clientSetting.manualTemplate) {
        if (project.EditMode) {
          let style;
          if (project.stylesData) {
            style = project.stylesData;
          } else {
            const styleResponse = await getProjectStyles(project.ProjectId);
            style = styleResponse.data;
          }

          const fontUrl = FONT_URL + style.fontFamily + '/' + style.fontStyle;
          newSlide = await CreateSildeManual(
            newSlideId,
            data,
            newSlideNumber,
            fontUrl,
            style.headingSize,
            style.paragraphSize,
            style.textColor,
            style.bgColor
          );
        } else {
          newSlide = await CreateSildeManual(
            newSlideId,
            data,
            newSlideNumber,
            project.FontUrl,
            project.FontHSize,
            project.FontPSize,
            project.TextColor,
            project.BgColor
          );
        }
      } else {
        if (project.EditMode) {
          let style;
          if (project.stylesData) {
            style = project.stylesData;
          } else {
            const styleResponse = await getProjectStyles(project.ProjectId);
            style = styleResponse.data;
          }

          const fontUrl = FONT_URL + style.fontFamily + '/' + style.fontStyle;
          newSlide = await CreateSilde(
            newSlideId,
            data,
            newSlideNumber,
            fontUrl,
            style.headingSize,
            style.paragraphSize,
            style.textColor,
            style.bgColor
          );
        } else {
          newSlide = await CreateSilde(
            newSlideId,
            data,
            newSlideNumber,
            project.FontUrl,
            project.FontHSize,
            project.FontPSize,
            project.TextColor,
            project.BgColor
          );
        }
      }

      const projectSlidesData =
        project.createdProject[project.ProjectId].Modules[project.ActiveModule].slides;
      const projectSlideKeys = Object.keys(projectSlidesData);
      const cacheSlides =
        cacheProject.ProjectData[cacheProject.ProjectID]?.Modules?.[project.ActiveModule]?.slides;

      if (!cacheSlides) {
        // showToaster('Please delete/save this module before creating a new video', 'error', 5000);
        showToaster(
          'Please complete one screen in one video before adding the Videos',
          'error',
          5000
        );
        return false;
      } else {
        const cacheSlideKeys = Object.keys(cacheSlides);
        if (projectSlideKeys.length !== cacheSlideKeys.length) {
          const lastSlideId = projectSlideKeys[projectSlideKeys.length - 1];
          const slideName = projectSlidesData[lastSlideId].SlideName;
          showToaster(`Please delete/save ${slideName} before creating a new video`, 'error', 5000);
          return false;
        }
      }

      if (argsData?.storeData) {
        if (cacheProject?.includeLogoImage) {
          newSlide[newSlideId].Screens[`SC${newSlideNumber}.01`]['IM01']['Contentparams'][
            'contentdescription'
          ] = cacheProject.imageUrl;
          newSlide[newSlideId].Screens[`SC${newSlideNumber}.01`]['IM01']['Contentparams'][
            'contenttag'
          ] = 'logo';
        }
        AddSlide(newSlide);
      }
      return { newSlideId, newSlideNumber };
    }

    return null;
  };

  const handleAddSlide = async () => {
    const data = await createSlide();
    if (data) {
      routeToChatGPT(project.ActiveModule, 'add-video', data.newSlideId, data.newSlideNumber);
    }
  };

  const removeSlide = async () => {
    if (slides.length > 1) {
      const data = await removeSlideData(deleteSlideId.slideId);

      if (data?.ok) {
        RemoveSlide(deleteSlideId.slideId);
        showToaster(`${deleteSlideId.slideName} Deleted Successfully`, 'success');
        setDeleteSlideId('');
      }
    }
  };

  return (
    <Accordion.Item eventKey={moduleId}>
      <div
        className={`accordion-header pointer show-on-hover-parent  ${
          isHeaderActive ? 'header-active' : ''
        }`}
        onClick={() => handleModuleClick(moduleId)}
      >
        <EditableContent
          editable={true}
          onClick={onChangeModuleName}
          initialContent={module.ModuleName}
        />

        {showModuleRemoveIcon ? (
          <span
            role="button"
            className="clone-btn show-on-hover"
            onClick={() => setDeleteModuleId(moduleId)}
          >
            {crossIcon}
          </span>
        ) : null}

        <CustomToggle
          eventKey={moduleId}
          isActive={isActive}
          onClick={() => onHeaderClick(moduleId)}
        />
      </div>
      <Accordion.Body>
        {slides.map(slide => (
          <div
            key={slide.slideId}
            className="d-flex justify-content-between align-items-center show-on-hover-parent"
          >
            <span onClick={() => handleSlideClick(slide.slideId)}>
              <EditableContent
                editable={true}
                onClick={onChangeSlideName}
                initialContent={slide.name}
                className="editable-border-line"
                isActiveData={
                  project.ActiveModule === moduleId && project.ActiveSlide === slide.slideId
                }
              />
            </span>
            {slides.length > 1 ? (
              <span
                role="button"
                className="clone-btn show-on-hover"
                onClick={() => setDeleteSlideId({ slideId: slide.slideId, slideName: slide.name })}
              >
                {crossIcon}
              </span>
            ) : null}
          </div>
        ))}
        {clientSetting.slideLimit > slides.length ? (
          <div className="btn-wrap" onClick={() => handleModuleClick(moduleId)}>
            <button type="button" onClick={handleAddSlide} className="outline-primary-btn">
              Add new video {plusIcon}
            </button>
          </div>
        ) : (
          <div className="screen-thumbnail-items-wrap">
            <span>Max. video limit reached"</span>
          </div>
        )}
      </Accordion.Body>
      <CustomModal
        Header="Are you sure"
        isOpen={!!deleteModuleId}
        Buttonclick={removeModule}
        Buttonlabel="Yes, Delete it!"
        Closemodal={setDeleteModuleId}
        Content={`Do you want to delete Module ${deleteModuleId}?`}
      />
      <CustomModal
        Header="Are you sure"
        isOpen={!!deleteSlideId}
        Buttonclick={removeSlide}
        Buttonlabel="Yes, Delete it!"
        Closemodal={setDeleteSlideId}
        Content={`Do you want to delete ${deleteSlideId.slideName}?`}
      />
    </Accordion.Item>
  );
}
