import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { debounce } from "debounce";
import { getAssets } from "../../../../services/api.service";
import ReactImageFallback from "react-image-fallback";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImagePreview from "../RightBar/ImagePriview";
import StockPreview from "../RightBar/StockRepo";
import AssetUpload from "../RightBar/Assetupload";
import { TabContent, TabPane, Nav, NavItem } from "reactstrap";
import Posts from "./posts";
import Pagination from "./pagination";
import {
  capitalizeFirstLetter,
  showToaster,
} from "../../../../services/helper";
import { actionCreator } from "../../../../store";
import { bindActionCreators } from "redux";
import Tooltip from "@mui/material/Tooltip";
import { Checkbox } from "@mui/material";
export default function AssetRepo(props) {
  const [pageNo, setPageNo] = useState(1);
  // const [pageNo, setPageNo] = useState(1);
  const [endPageNo, setEndPageNo] = useState(100);
  const [imagesRepo, setImagesRepo] = useState([]);
  const [SearchBoxErrorMsg, setSearchBoxErrorMsg] = useState(false);
  const [keyWord, setKeyWord] = useState("");
  const [imageRepoLoader, setImageRepoLoader] = useState(true);
  const [imageErrorMsg, setImageErrorMsg] = useState("");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState(false);
  const [openVideoPreview, setOpenVideoPreview] = useState(false);
  const [selectedVideoFile, setSelectedVideoFile] = useState(false);
  const [assetActive, setAssetActive] = useState(true);
  const [stockActive, setStockActive] = useState(false);
  const [uploadActive, setUploadActive] = useState(false);
  const indexOfLastPost = currentPage * postsPerPage;
  // console.log("indexOfLastPost: ", indexOfLastPost);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [checked, setChecked] = useState(false);
  // console.log("indexOfFirstPost: ", indexOfFirstPost);

  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const dispatch = useDispatch();
  let { UpdatePreviewFeature } = bindActionCreators(actionCreator, dispatch);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  //console.log("currentPosts: ", currentPosts);
  useEffect(() => {
    //console.log(props);
    setKeyWord("");
    getAssetRepo(keyWord);
    setAssetActive(true);
    if (props.type === "image") {
      setPosts(imagesRepo);
    }
    setStockActive(false);
    setUploadActive(false);
  }, [props.openAssetRpo]);

  // useEffect(() => {
  //   getSearchBoxResult();
  // }, [keyWord]);
  useEffect(() => {
    getAssetRepo(keyWord);
    setKeyWord("");
  }, [assetActive]);
  const __serachbox = (val) => {
    val.preventDefault();
    try {
      let tags;

      tags =
        val.target.value !== null &&
          val.target.value !== "Null" &&
          val.target.value !== ""
          ? val.target.value.split(",")
          : "";
      // console.log(tags,"tagstags");
      setSearchBoxErrorMsg(false);
      if (keyWord !== tags) {
        setKeyWord(tags);
        getSearchBoxResult(tags);
      } else {
        if (tags !== "") {
          setKeyWord(tags);
        } else {
          setKeyWord("");
        }
      }
    } catch (error) { }
  };

  const getSearchBoxResult = useCallback(
    debounce((value) => {
      try {
        // this.keyWord = this.state.keyWord

        if (props.type === "image") {
          //console.log("ghjhgjghjghjhgj");
          setImageRepoLoader(true);
          setImagesRepo([]);
          getAssetRepo(value);
        }
      } catch (error) {
        showToaster(
          "Something went wrong. Please Refresh page and Try Again",
          "warning"
        );
      }
    }, 1000),
    [imageRepoLoader]
  );
  useEffect(() => {
    setTimeout(() => {
      setSearchBoxErrorMsg(false);
    }, 10000);
  }, [SearchBoxErrorMsg]);

  const __searchByClick = () => {
    // let searchValue = document.getElementById("searchBtn1").value;
    // console.log(serachValue);
    let searchValue
    searchValue =
       document.getElementById("searchBtn1").value !== null &&
         document.getElementById("searchBtn1").value !== "Null" &&
         document.getElementById("searchBtn1").value !== ""
          ?document.getElementById("searchBtn1").value.split(",")
          : "";
    if (searchValue === "") {
      setSearchBoxErrorMsg(true);
    } else {
      setSearchBoxErrorMsg(false);
      if (searchValue !== "") {
        setImageRepoLoader(true);
        setImagesRepo([]);
        getAssetRepo(searchValue);
        // this.setState({ imageRepoLoader: true, iconRepoLoader: true, videoRepoLoader: true, logoRepoLoader: true, iconRepo: [], imagesRepo: [], logoRepo: [], videoRepo: [], motionGraphics: [], motionGraphicsLoader: true })
      } else {
        setSearchBoxErrorMsg(true);
      }
    }
  };

  const getAssetRepo = (searchWord) => {
    setImageRepoLoader(true);
    if (props.type === "image") {
      let request = {
        media_type: "IMAGE",
        search_term: searchWord,
        start: pageNo,
        end: endPageNo,
        private: !checked ? 0 : 1
      };
      getAssets(request)
        .then((response) => {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            if (Object.entries(response.data).length > 0) {
              let projectData = response.data;
              // if(Object.entries(response.data).length <=12){
              setPosts(projectData);
              setLoading(false);
              // let projectList = [...imagesRepo, ...projectData];
              setImagesRepo(projectData);
              // setPageLoader(false)
              setImageRepoLoader(false);
              // setImagesRepo([])
              // this.setState({ imagesRepo: projectList, imageRepoLoader: false ,progressLoader:false})
              //console.log(projectData, "imgggg");
            } else {
              setImageRepoLoader(false);
              setImagesRepo([]);
              setImageErrorMsg(response.message);
              // this.setState({ imageRepoLoader: false,progressLoader:false, imagesRepo: [], imageErrorMsg: response.message })
            }
          } else if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.message !== "" &&
            response.message !== undefined &&
            response.message !== null
          ) {
            setImageRepoLoader(false);
            setImagesRepo([]);
            setImageErrorMsg("No images available");
          } else {
            setImageRepoLoader(false);
            setImageErrorMsg("No images available");
          }
        })
        .catch((error) => {
          setImageRepoLoader(false);
          setImageErrorMsg("No images available");
        });
    }
  };
  const viewImagePreview = (image) => {
    console.log(image, "imageee");
    setOpenImagePreview(true);
    setSelectedImageFile(image);
  };

  const changeAssetSection = (type) => {
    if (type === "1") {
      //console.log(assetActive, "zsdsxzsdfdsfsdf");
      setAssetActive(true);
      setStockActive(false);
      setUploadActive(false);
    } else if (type === "2") {
      setAssetActive(false);
      setStockActive(true);
      setUploadActive(false);
    } else if (type === "3") {
      setAssetActive(false);
      setStockActive(false);
      setUploadActive(true);
    }
  };
  const closePreview = () => {
    props.closePreview();
    setAssetActive(false);
    setStockActive(false);
    setUploadActive(false);
  };
  const Resetkeyword = () => {
    console.log("reset");

    setKeyWord("");
    getAssetRepo("");
  }
  const SelectAsset = (medialUrl) => {
    //console.log(props.item);
    //console.log(medialUrl);
    // let asset = props.item[1]
    // asset.Contentparams.contentdescription = medialUrl;
    // let data = {
    //   [props.item[0]]: asset
    // }
    //console.log(data);
    props.selectImage(medialUrl);
    //props.setdisplayImage(medialUrl)
    //UpdatePreviewFeature(data)
    props.closePreview();
  };
  const viewPrivateRepo = (event) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    // setPrivateValue(1)
    getAssetRepo(keyWord)
  }, [checked])
  return (
    <React.Fragment>
      <Modal
        isOpen={props.openAssetRpo}
        fade={true}
        className={
          DisplayMode
            ? "modal-dialog  modal-dialog-centered custom-modal replace-modal dark"
            : "modal-dialog  modal-dialog-centered custom-modal replace-modal"
        }
        // className="modal-dialog  modal-dialog-centered custom-modal replace-modal dark "
        centered
      >
        <ModalBody className="p-0">
          {/* <div className="modal fade" id="replace-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
          {/* <div className="modal-dialog  modal-dialog-centered custom-modal replace-modal dark"> */}
          {/* <div className="modal-content border-0">
            <div className="modal-body"> */}
          <Tooltip title="Close" arrow>
            <div onClick={() => closePreview()} className="close-priview">
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="close">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0"
                    />
                    <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                  </g>
                </g>
              </svg>
            </div>
          </Tooltip>

          <div className="navtab-selector tab-assets">
            <Nav className="nav nav-tabs" role="tablist">
              <NavItem
                className="nav-item"
                onClick={() => changeAssetSection("1")}
              >
                <button
                  className={
                    assetActive === true ? "nav-link active" : "nav-link"
                  }
                >
                  <div className="cm-vs-container">
                    <div className="d-flex align-items-center">
                      <div className="cm-icon assets">
                        <svg
                          width="1792"
                          height="1792"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 1792 1792"
                        >
                          <g>
                            <path
                              id="svg_1"
                              d="m512,1248l0,192q0,40 -28,68t-68,28l-320,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l320,0q40,0 68,28t28,68zm0,-512l0,192q0,40 -28,68t-68,28l-320,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l320,0q40,0 68,28t28,68zm1280,512l0,192q0,40 -28,68t-68,28l-960,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l960,0q40,0 68,28t28,68zm-1280,-1024l0,192q0,40 -28,68t-68,28l-320,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l320,0q40,0 68,28t28,68zm1280,512l0,192q0,40 -28,68t-68,28l-960,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l960,0q40,0 68,28t28,68zm0,-512l0,192q0,40 -28,68t-68,28l-960,0q-40,0 -68,-28t-28,-68l0,-192q0,-40 28,-68t68,-28l960,0q40,0 68,28t28,68z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div className="cm-asset-name">Assets</div>
                    </div>
                  </div>
                </button>
              </NavItem>
              <NavItem
                className="nav-item"
                onClick={() => changeAssetSection("2")}
              >
                <button
                  className={
                    stockActive === true ? "nav-link active" : "nav-link"
                  }
                >
                  <div className="cm-vs-container">
                    <div className="d-flex align-items-center">
                      <div className="cm-icon stock">
                        <svg
                          height="512px"
                          id="Layer_1"
                          style={{ enableBackground: "new 0 0 512 512" }}
                          version="1.1"
                          viewBox="0 0 512 512"
                          width="512px"
                          space="preserve"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                        >
                          <g>
                            <path d="M368,224c26.5,0,48-21.5,48-48c0-26.5-21.5-48-48-48c-26.5,0-48,21.5-48,48C320,202.5,341.5,224,368,224z" />
                            <path d="M452,64H60c-15.6,0-28,12.7-28,28.3v327.4c0,15.6,12.4,28.3,28,28.3h392c15.6,0,28-12.7,28-28.3V92.3   C480,76.7,467.6,64,452,64z M348.9,261.7c-3-3.5-7.6-6.2-12.8-6.2c-5.1,0-8.7,2.4-12.8,5.7l-18.7,15.8c-3.9,2.8-7,4.7-11.5,4.7   c-4.3,0-8.2-1.6-11-4.1c-1-0.9-2.8-2.6-4.3-4.1L224,215.3c-4-4.6-10-7.5-16.7-7.5c-6.7,0-12.9,3.3-16.8,7.8L64,368.2V107.7   c1-6.8,6.3-11.7,13.1-11.7h357.7c6.9,0,12.5,5.1,12.9,12l0.3,260.4L348.9,261.7z" />
                          </g>
                        </svg>
                      </div>
                      <div className="cm-asset-name">Stock</div>
                    </div>
                  </div>
                </button>
              </NavItem>
              <NavItem
                className="nav-item"
                onClick={() => changeAssetSection("3")}
              >
                <button
                  className={
                    uploadActive === true ? "nav-link active" : "nav-link"
                  }
                >
                  <div className="cm-vs-container">
                    <div className="d-flex align-items-center">
                      <div className="cm-icon upload">
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zm9-10v7h-2V9H6l6-6 6 6h-5z" />
                          </g>
                        </svg>
                      </div>
                      <div className="cm-asset-name">Upload</div>
                    </div>
                  </div>
                </button>
              </NavItem>
            </Nav>
          </div>
          <div className="srch-temp-blk">
            {assetActive === true ? (
              <>


                <div className="d-flex align-items-center position-relative flex-wrap">
                  <div className="d-flex align-items-center pro-temp-container mr-4">
                    <div className="cm-header .cm-subhead">
                      Private Repo
                   </div>
                    <Checkbox
                      checked={checked}
                      onChange={(e) => viewPrivateRepo(e)}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>

                  <div className="srch-temp-wrapper">
                    <div className="srch-holder">
                      <div
                        className="srch-icon"
                        onClick={() => __searchByClick()}
                      >
                        <Tooltip title="Search" arrow>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M21.9243 21.925L27.9994 28.0001"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Tooltip>
                      </div>

                      <div className="srch-inpt-holder">
                        <input
                          id="searchBtn1"
                          onChange={(e) => __serachbox(e)}
                          type="text"
                          name="focus"
                          required
                          className="form-control srch-input"
                          placeholder="Search asset images"
                          value={keyWord}
                        />

                        <button
                          className="search-close"
                          type="reset"
                          onClick={() => Resetkeyword()}
                        >
                          <Tooltip title="Reset" arrow>
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                              space="preserve"
                            >
                              <g>
                                <path
                                  d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M327.115,365.904
                                      L256,294.789l-71.115,71.115l-38.789-38.789L217.211,256l-71.115-71.115l38.789-38.789L256,217.211l71.115-71.115l38.789,38.789
                                      L294.789,256l71.115,71.115L327.115,365.904z"
                                />
                              </g>
                            </svg>
                          </Tooltip>
                        </button>
                      </div>
                    </div>
                  </div>
                  {SearchBoxErrorMsg === true ? (
                    <div className="mt-5 text-center red-text">
                      Please enter search term
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              </>
            ) : stockActive === true ? (
              <StockPreview
                item={props.item}
                type={props.type}
                setdisplayImage={props.setdisplayImage}
                closePreview={() => props.closePreview()}
              />
            ) : (
                  uploadActive === true && (
                    <AssetUpload
                      item={props.item}
                      type={props.type}
                      closePreview={() => props.closePreview()}
                      setdisplayImage={props.setdisplayImage}
                    />
                  )
                )}
            {assetActive === true ? (
              <div className="cm-st-gallery my-3">
                {/* {imagesRepo.length > 0 ? ( */}

                {props.type === "image" ? (
                  imageRepoLoader === false ? (
                    imagesRepo.length > 0 ? (
                      <div className="cm-gallery-wrapper">
                        {currentPosts.map((imagee, index) => {
                          let mediaurl = "";
                          console.log(imagee,"imagee");

                          if (
                            imagee.thumbnailurl !== undefined &&
                            imagee.thumbnailurl !== null &&
                            imagee.thumbnailurl !== ""
                          ) {
                            mediaurl = imagee.thumbnailurl;
                          } else if (imagee.thumbnailurl === null) {
                            mediaurl = imagee.mediaurl;
                          } else {
                            mediaurl = imagee;
                          }
                          console.log(mediaurl, "mediaurl");

                          return (
                            <div className="cm-gallery-container replace-img">

                              <div className="img-info">
                                {imagee.private===1&&
                                   <Tooltip title="Private Images" arrow>
                                <span>
                                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                      viewBox="0 0 330 330"  space="preserve">
                                    <g id="XMLID_486_">
                                    <path id="XMLID_487_" d="M165,330c63.411,0,115-51.589,115-115c0-29.771-11.373-56.936-30-77.379V85c0-46.869-38.131-85-85-85
                                        S80.001,38.131,80.001,85v52.619C61.373,158.064,50,185.229,50,215C50,278.411,101.589,330,165,330z M180,219.986V240
                                        c0,8.284-6.716,15-15,15s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
                                        C190,208.162,186.068,215.421,180,219.986z M110.001,85c0-30.327,24.673-55,54.999-55c30.327,0,55,24.673,55,55v29.029
                                        C203.652,105.088,184.91,100,165,100c-19.909,0-38.651,5.088-54.999,14.028V85z"/>
                                  </g>
                                    </svg>
                                </span></Tooltip>}
                              </div>
                      <div className="icon-wrap">
                        {imagee.thumbnailurl !== undefined &&
                          imagee.thumbnailurl !== null &&
                          imagee.thumbnailurl !== "" ? (
                            <span
                              onClick={() => {
                                viewImagePreview(imagee.mediaurl);
                              }}
                              className="img-view"
                              data-bs-toggle="modal"
                              data-bs-target="#view-img"
                            >
                              {" "}
                              <Tooltip arrow>
                                <svg
                                  width="20"
                                  height="17"
                                  viewBox="0 0 20 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.99889 5.64136C8.66889 5.64136 7.58789 6.72336 7.58789 8.05336C7.58789 9.38236 8.66889 10.4634 9.99889 10.4634C11.3289 10.4634 12.4109 9.38236 12.4109 8.05336C12.4109 6.72336 11.3289 5.64136 9.99889 5.64136ZM9.99889 11.9634C7.84189 11.9634 6.08789 10.2094 6.08789 8.05336C6.08789 5.89636 7.84189 4.14136 9.99889 4.14136C12.1559 4.14136 13.9109 5.89636 13.9109 8.05336C13.9109 10.2094 12.1559 11.9634 9.99889 11.9634Z"
                                    fill=""
                                  />
                                  <mask
                                    id="mask0_2688_3325"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="20"
                                    height="17"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0 0.000244141H19.9998V16.1052H0V0.000244141Z"
                                      fill=""
                                    />
                                  </mask>
                                  <g mask="url(#mask0_2688_3325)">
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M1.56975 8.05251C3.42975 12.1615 6.56275 14.6045 9.99975 14.6055C13.4368 14.6045 16.5698 12.1615 18.4298 8.05251C16.5698 3.94451 13.4368 1.50151 9.99975 1.50051C6.56375 1.50151 3.42975 3.94451 1.56975 8.05251V8.05251ZM10.0017 16.1055H9.99775H9.99675C5.86075 16.1025 2.14675 13.2035 0.06075 8.34851C-0.02025 8.15951 -0.02025 7.94551 0.06075 7.75651C2.14675 2.90251 5.86175 0.00350586 9.99675 0.000505859C9.99875 -0.000494141 9.99875 -0.000494141 9.99975 0.000505859C10.0018 -0.000494141 10.0018 -0.000494141 10.0028 0.000505859C14.1388 0.00350586 17.8527 2.90251 19.9387 7.75651C20.0207 7.94551 20.0207 8.15951 19.9387 8.34851C17.8537 13.2035 14.1388 16.1025 10.0028 16.1055H10.0017Z"
                                      fill=""
                                    />
                                  </g>
                                </svg>
                              </Tooltip>
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                viewImagePreview(imagee.mediaurl);
                              }}
                              className="img-view"
                              data-bs-toggle="modal"
                              data-bs-target="#view-img"
                            >
                              {" "}
                              <Tooltip arrow>
                                <svg
                                  width="20"
                                  height="17"
                                  viewBox="0 0 20 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.99889 5.64136C8.66889 5.64136 7.58789 6.72336 7.58789 8.05336C7.58789 9.38236 8.66889 10.4634 9.99889 10.4634C11.3289 10.4634 12.4109 9.38236 12.4109 8.05336C12.4109 6.72336 11.3289 5.64136 9.99889 5.64136ZM9.99889 11.9634C7.84189 11.9634 6.08789 10.2094 6.08789 8.05336C6.08789 5.89636 7.84189 4.14136 9.99889 4.14136C12.1559 4.14136 13.9109 5.89636 13.9109 8.05336C13.9109 10.2094 12.1559 11.9634 9.99889 11.9634Z"
                                    fill=""
                                  />
                                  <mask
                                    id="mask0_2688_3325"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="20"
                                    height="17"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0 0.000244141H19.9998V16.1052H0V0.000244141Z"
                                      fill=""
                                    />
                                  </mask>
                                  <g mask="url(#mask0_2688_3325)">
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M1.56975 8.05251C3.42975 12.1615 6.56275 14.6045 9.99975 14.6055C13.4368 14.6045 16.5698 12.1615 18.4298 8.05251C16.5698 3.94451 13.4368 1.50151 9.99975 1.50051C6.56375 1.50151 3.42975 3.94451 1.56975 8.05251V8.05251ZM10.0017 16.1055H9.99775H9.99675C5.86075 16.1025 2.14675 13.2035 0.06075 8.34851C-0.02025 8.15951 -0.02025 7.94551 0.06075 7.75651C2.14675 2.90251 5.86175 0.00350586 9.99675 0.000505859C9.99875 -0.000494141 9.99875 -0.000494141 9.99975 0.000505859C10.0018 -0.000494141 10.0018 -0.000494141 10.0028 0.000505859C14.1388 0.00350586 17.8527 2.90251 19.9387 7.75651C20.0207 7.94551 20.0207 8.15951 19.9387 8.34851C17.8537 13.2035 14.1388 16.1025 10.0028 16.1055H10.0017Z"
                                      fill=""
                                    />
                                  </g>
                                </svg>
                              </Tooltip>
                            </span>
                          )}

                        <span
                          className="img-edit"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-img"
                          onClick={() => SelectAsset(imagee.mediaurl)}
                        >
                          <Tooltip title="Save" arrow>
                            <svg
                              width="30px"
                              height="24px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z"
                                fill=""
                              />
                            </svg>
                          </Tooltip>
                        </span>
                      </div>
                      <ReactImageFallback
                        src={mediaurl}
                        fallbackImage="../../../assets/img/fallbackImage.jpg"
                        initialImage="../../../assets/img/fallbackImage.jpg"
                        alt="templates"
                        className="img-fluid"
                      />
                            </div>
            );
                        })}
                      </div>
                    ) : (
                        <div className="v-gal-empty-blk">
            <div className="no-txt-selected blink">
              {capitalizeFirstLetter(imageErrorMsg)}
            </div>
          </div>
                      )
                  ) : (
                      <div className="v-gal-empty-blk">
            {/* <CircularProgress /> */}
            <div className="loader-wrapper ">
              <div className="loader-inner">
                <img src="../../../assets/img/loader.gif"></img>
              </div>
            </div>
          </div>
                    )
                ) : (
                    <div className="v-gal-empty-blk">
            {/* <CircularProgress /> */}
            <div className="loader-wrapper ">
              <div className="loader-inner">
                <img src="../../../assets/img/loader.gif"></img>
              </div>
            </div>
          </div>
                  )}
              </div>
            ) : (
                ""
              )}
            {assetActive === true && imagesRepo.length > 0 && (
          <Pagination
            paginate={paginate}
            postsPerPage={postsPerPage}
            totalPosts={posts.length}
          />
        )}
          </div>
      {/* </div>
          </div> */}
      {/* </div>
                      </div> */}
        </ModalBody>
      </Modal >
    <ImagePreview
      closePreview={() => setOpenImagePreview(false)}
      openImagePreview={openImagePreview}
      selectedImage={selectedImageFile}
    />
    </React.Fragment >
  );
}
