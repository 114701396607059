import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import ReactPlayer from "react-player";
import axios from "axios";
import fileDownload from "js-file-download";
import { Tooltip } from "@mui/material";
import Videoplayer from "../components/Videoplayer/Videoplayer";
import {
  getProjectPreview,
  getProjectID,
  getAdminProjectStatus
} from "../../services/api.service";
import Header from "../components/header/header";
import { useParams, useHistory } from "react-router-dom";
import { showToaster } from "../../services/helper";
import { getProjectById } from "../../services/authService";
function DownloadModal(props) {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [loaded, setloaded] = useState(false);
  const [selectedVideo, setselectedVideo] = useState(undefined);
  const [downloadModal, setDownloadModal] = useState(false);
  const [downloadList, setdownloadList] = useState(undefined);
  const [pageLoad, setPageLoad] = useState(false);
  const params = useParams();
  const [slideCount, setSlideCount] = useState([])
  const history = useHistory();
  useEffect(() => {
    if (props.location.state != undefined) {
      if (props.location.state.projectId != undefined && props.location.state.projectName != undefined && props.location.state.projectId != "" && props.location.state.projectName != "" && props.location.state.projectId != null && props.location.state.projectName != null) {
        console.log(props);

      } else {
        //go back
        showToaster("Something went wrong!", "warning")
        history.goBack()
      }
    } else {
      //go back
      showToaster("Something went wrong!", "warning")
      history.goBack()
    }
    setloaded(false);
    if (props != undefined && props != null && props != "") {
      if (
        props.videos != undefined &&
        props.videos != null &&
        props.videos != ""
      ) {
        setloaded(true);
      }
    }
    if (
      props.priviewrenderUrl != undefined &&
      props.priviewrenderUrl != "" &&
      props.priviewrenderUrl != null
    ) {

    }
  }, [props]);


  useEffect(() => {
    console.log("login");

    DownloadVideos()
  }, [])

  useEffect(() => {

    if (downloadList !== undefined) {
      console.log(downloadList, downloadList.length, "params");
      setselectedVideo(downloadList[0].url);
    }

  }, [downloadList])

  const downloadFile = (url) => {
    let filename = url.split("Projects/");
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename[1]);
      });
  };
  const DownloadVideos = async (projectId) => {
    setPageLoad(true);
    let renderFilter = { project_id: props.location.state.projectId, projectName: props.location.state.projectName };
    if (props.location.state.projectId) {

      await getProjectById(props.location.state.projectId).then(async (response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            console.log('htduytfiy',response.data)
            if (response.data.status && response.data.data.length > 0) {
              const projectData = response.data.data[0];
              getAdminProjectStatus({ ...renderFilter, user_id: projectData.userId }).then((response) => {
                console.log(response, "  response.data");
                if (
                  response.data !== undefined &&
                  response.data !== null &&
                  response.data !== "" &&
                  response.status !== undefined &&
                  response.status !== null &&
                  response.status === 200
                ) {
                  //console.log(response, "111111111111");
                  if (
                    response.data.mediaurl !== undefined &&
                    response.data.mediaurl !== null &&
                    response.data.mediaurl !== "" &&
                    Object.keys(response.data.mediaurl).length !== 0
                  ) {
                    if (response.data.renderstatus === "100%") {
                      var arrayValue = [];
                      let mediaurl = response.data.mediaurl;
                      Object.keys(mediaurl).map(function (key, index) {
                        Object.keys(mediaurl[key]).map(function (k, i) {
                          console.log(k);
                          // if (mediaurl[key][k].SlideRender === "1") {
                            arrayValue.push(mediaurl[key][k]);
                            console.log(arrayValue, "arrayValue");
                            setdownloadList(arrayValue);
                          // }

                        });
                        if (arrayValue.length > 0) {
                          if (
                            arrayValue !== undefined &&
                            arrayValue !== null &&
                            arrayValue !== ""
                          ) {
                            console.log(arrayValue, "arrayValue");
                          }
                        }
                      });
                      setDownloadModal(true);
                      setPageLoad(false);
                    } else {
                      showToaster("Render not completed", "warning")
                      history.push("/")
                      setPageLoad(false);
                    }
                  }
                }
              }).catch(err => {
                showToaster("Unable to download now. Please try again", "warning")
              })
            } else {
              showToaster("Project is not available. Please try again", "warning")
              history.push("/admin/project-list");
            }
          }
        } catch (error) {
          //this.setState({ uploadButtonProgress: false })
        }
      });
    }


  };
  const closemodal = () => {
    history.push("/admin/project-list");
  }
  return (
    <div className={DisplayMode ? "theme dark" : "theme"}>
      <div className="main-wrapper">
        <Header />
        <div className="cm-wrapper">

          <Modal
            isOpen={downloadModal}
            fade={true}
            centered
            className={
              DisplayMode
                ? "modal-dialog  modal-dialog-centered custom-modal preview-modal dark"
                : "modal-dialog  modal-dialog-centered custom-modal preview-modal"
            }
          // className="modal-dialog  modal-dialog-centered styles-modal custom-modal dark"
          >
            <ModalBody>
              <div
                className="preview-modal-close"
                data-bs-dismiss="modal"
                onClick={() => { closemodal(); setselectedVideo(undefined) }}
              >
                <Tooltip title="Close" arrow>
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g data-name="Layer 2">
                      <g data-name="close">
                        <rect
                          width="24"
                          height="24"
                          transform="rotate(180 12 12)"
                          opacity="0"
                        />
                        <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                      </g>
                    </g>
                  </svg>
                </Tooltip>
              </div>
              <div>
                {
                  downloadList !== undefined ? (
                    <div>
                      <Videoplayer
                        thumbnailUrl=""
                        videoUrl={
                          selectedVideo !== undefined
                            ? selectedVideo
                            : downloadList[0].url
                        }
                        showVolume={true}
                      />
                      {/* <ReactPlayer
                url={selectedVideo}
                controls
                width="100%"
                muted={true}
                playing={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "menu",
                    },
                  },
                }}
              /> */}
                      <div  className="video-control">
                        <div  className="slide-wrap">
                          <ul  className="slide-inner">
                            {downloadList.map((video, i) => {
                              console.log(video);
                              if (video.url != undefined) {
                                return (
                                  <li
                                     className="active"
                                  >
                                    <div  className="img-wrap" onClick={() => setselectedVideo(video.url)}>
                                      <ReactPlayer
                                        url={video.url}
                                        controls={false}
                                        width="100%"
                                        height=""
                                        muted={true}
                                        playing={false}
                                      />
                                      <h3 >
                                        {video.SlideName}
                                      </h3>
                                    </div>
                                    <button type="submit" onClick={() => {
                                      downloadFile(video.url);
                                    }}  className="btn btn-outline" value="submit" >Download</button>
                                  </li>
                                  // <div  className="icon-wrap">
                                  //     <span  className="img-play">
                                  //     <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  //       <path d="M0 0V14L11 7L0 0Z" fill=""></path>
                                  //     </svg>
                                  //   </span>

                                  //     <span  className="img-download" onClick={() => {
                                  //       downloadFile(video.url);
                                  //     }}>
                                  //       <button type="submit"  className="btn btn-outline" value="submit" >Download</button>

                                  //     </span>

                                  //   </div>

                                );
                              }

                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : (
                      <p>Video URL is not available now! Please render again.</p>
                    )}
              </div>
              {/*         
        <div className="d-flex justify-content-end">
          <div
            className="modal-close"
            data-bs-dismiss="modal"
            onClick={() => props.closemodal(false)}
          >
            &times;
          </div>
        </div>
        <div  className="download-wraper">
          <div  className="list-head">
            <div  className="download-list">
              <div  className="download-item download-video">
                <h4> Video</h4>
              </div>
              <div  className="download-item slide-name">
                <h4> Slide</h4>
              </div>
              <div  className="download-item download-icon">
                <h4> Download</h4>
              </div>
            </div>
          </div>

          <div  className="list-wrap">
            {loaded &&
              props.videos.length > 0 &&
              props.videos.map((vid, i) => {
                return (
                  <div  className="download-list">
                    <div  className="download-item download-video">
                      <ReactPlayer
                        url={vid.url}
                        controls={false}
                        width="100%"
                        height=""
                        muted={true}
                        playing={false}
                      />
                    </div>
                    <div  className="download-item slide-name">SL{Number(i + 1) <= 9 ? "0" + (i + 1) : i + 1}</div>
                    <div  className="download-item download-icon">
                      <span onClick={() => {
                            downloadFile(vid.url);
                          }}>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 330 330"
                          space="preserve"
                        >
                          <g id="XMLID_23_">
                            <path
                              id="XMLID_24_"
                              d="M154.389,255.602c0.351,0.351,0.719,0.683,1.103,0.998c0.169,0.138,0.347,0.258,0.52,0.388
                           c0.218,0.164,0.432,0.333,0.659,0.484c0.212,0.142,0.432,0.265,0.649,0.395c0.202,0.121,0.4,0.248,0.608,0.359
                           c0.223,0.12,0.453,0.221,0.681,0.328c0.215,0.102,0.427,0.21,0.648,0.301c0.223,0.092,0.45,0.167,0.676,0.247
                           c0.236,0.085,0.468,0.175,0.709,0.248c0.226,0.068,0.456,0.119,0.684,0.176c0.246,0.062,0.489,0.131,0.739,0.181
                           c0.263,0.052,0.529,0.083,0.794,0.121c0.219,0.031,0.435,0.073,0.658,0.095c0.492,0.048,0.986,0.075,1.48,0.075
                           c0.494,0,0.988-0.026,1.479-0.075c0.226-0.022,0.444-0.064,0.667-0.096c0.262-0.037,0.524-0.068,0.784-0.12
                           c0.255-0.05,0.504-0.121,0.754-0.184c0.223-0.057,0.448-0.105,0.669-0.172c0.246-0.075,0.483-0.167,0.724-0.253
                           c0.221-0.08,0.444-0.152,0.662-0.242c0.225-0.093,0.44-0.202,0.659-0.306c0.225-0.106,0.452-0.206,0.672-0.324
                           c0.21-0.112,0.408-0.239,0.611-0.361c0.217-0.13,0.437-0.252,0.648-0.394c0.222-0.148,0.431-0.314,0.644-0.473
                           c0.179-0.134,0.362-0.258,0.536-0.4c0.365-0.3,0.714-0.617,1.049-0.949c0.016-0.016,0.034-0.028,0.049-0.044l70.002-69.998
                           c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.857-15.355-5.858-21.213-0.001l-44.396,44.393V25c0-8.284-6.716-15-15-15
                           c-8.284,0-15,6.716-15,15v183.785l-44.392-44.391c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213
                           L154.389,255.602z"
                            />
                            <path
                              id="XMLID_25_"
                              d="M315,160c-8.284,0-15,6.716-15,15v115H30V175c0-8.284-6.716-15-15-15c-8.284,0-15,6.716-15,15v130
                           c0,8.284,6.716,15,15,15h300c8.284,0,15-6.716,15-15V175C330,166.716,323.284,160,315,160z"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div> */}


            </ModalBody>
          </Modal>
        </div></div></div>
  );
}

export default DownloadModal;
