import React from 'react';
import { Link } from 'react-router-dom';

const index = () => {
  return (
    <div className="d-flex align-items-center justify-content-center text-center page-404">
        Inactive organization and contact&nbsp; <Link to="hello@vidayopro.com">hello@vidayopro.com</Link>
    </div>
  );
};

export default index;
