import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import ReactPlayer from "react-player";
import { Tooltip } from "@mui/material";
export default function RenderProject(props) {
  const [selectedVideo, setselectedVideo] = useState(undefined);
  useEffect(() => {
    if (props != undefined && props != null && props != "") {
      if (
        props.priviewrenderUrl != undefined &&
        props.priviewrenderUrl != "" &&
        props.priviewrenderUrl != null
      ) {
        setselectedVideo(props.priviewrenderUrl[0].url);
      }
    }
  }, [props]);

  return (
    <Modal
      isOpen={props.openRenderVideo}
      fade={true}
      // className={DisplayMode?"modal-dialog  modal-dialog-centered custom-modal replace-modal dark":"modal-dialog  modal-dialog-centered custom-modal replace-modal"}
      className="modal-dialog  modal-dialog-centered custom-modal preview-modal"
      centered
    >
      <ModalBody>
        {/* <div className="modal fade" id="preview-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered custom-modal preview-modal">
                        <div className="modal-content border-0">
                            <div className="modal-body"> */}

        <div
          className="preview-modal-close"
          data-bs-dismiss="modal"
          onClick={() => props.closePreview()}
        >
          <Tooltip title="Close" arrow>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g data-name="Layer 2">
                <g data-name="close">
                  <rect
                    width="24"
                    height="24"
                    transform="rotate(180 12 12)"
                    opacity="0"
                  />
                  <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                </g>
              </g>
            </svg>
          </Tooltip>
        </div>
        <div>
          {props.priviewrenderUrl.length > 0 ? (
            <div>
              <ReactPlayer
                url={selectedVideo}
                controls
                width="100%"
                muted={true}
                playing={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "menu",
                    },
                  },
                }}
              />
              <div className="video-control">
                <div className="slide-wrap">
                  <ul className="slide-inner">
                    {props.priviewrenderUrl.map((video, i) => {
                      return (
                        <li
                          className="active"
                          onClick={() => setselectedVideo(video.url)}
                        >
                          <div className="img-wrap">
                            <ReactPlayer
                              url={video.url}
                              controls={false}
                              width="100%"
                              height=""
                              muted={true}
                              playing={false}
                            />
                            <h3>
                              SL{Number(i + 1) <= 9 ? "0" + (i + 1) : i + 1}
                            </h3>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <p>Video URL is not available now! Please render again.</p>
          )}
        </div>
        {/* <div className="video-control">
                                    <div className="rSlider">
                                        <span className="slide"></span>
                                        <input id="range" type="range" min="0" max="50000" />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <a href="" className="play-btn me-3">
                                                <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 0V14L11 7L0 0Z" fill="" />
                                                </svg>
                                            </a>
                                            <a href="" className="play-btn me-3">
                                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.5 8C13.5 6.23 12.48 4.71 11 3.97V12.02C12.48 11.29 13.5 9.77 13.5 8ZM0 5V11H4L9 16V0L4 5H0Z" fill="" />
                                                </svg>
                                            </a>
                                            <div className="time-duration">
                                                <span>0:00 </span>/<span> 4:00</span>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}

        {/* </div>
                        </div>
                    </div>
                </div> */}
      </ModalBody>
    </Modal>
  );
}
