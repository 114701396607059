export default function DetailThumbnail(props) {
  let { count, thumbnail, title, onClick } = props;
  return (
    <>
      {thumbnail && (
        <div className={`project-detail-thumbnail ${props.className}`} onClick={onClick}>
          {thumbnail && <img src={thumbnail} alt="Online course"></img>}
          {count && <span className="count-item">{count}</span>}
          {title && (
            <div className="thumbnail-title">
              <p>{title}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}
