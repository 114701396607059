import { Button, Table } from 'react-bootstrap';

import projectImage1 from '../../../../assets/img/thumbnail/thumbnail-01.png';
import projectImage2 from '../../../../assets/img/thumbnail/thumbnail-02.png';
import projectImage3 from '../../../../assets/img/thumbnail/thumbnail-03.png';
import projectImage4 from '../../../../assets/img/thumbnail/thumbnail-04.png';
import projectImage5 from '../../../../assets/img/thumbnail/thumbnail-05.png';

import CircleDetailBox from './circleDetailBox';
import ProgressWithPercentage from '../../../../components/progressPercentage';

function WorkedTable() {
  return (
    <>
      <Table responsive className="common-table">
        <thead>
          <tr>
            <th className="col">Project</th>
            <th className="col-lg-5">Progress</th>
            <th className="col-lg-2 text-right">Hours Spent</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <CircleDetailBox
                src={projectImage1}
                name="UX/UI"
                desc="Updated 1 hours ago"
              />
            </td>
            <td>
              <ProgressWithPercentage
                className="reverse-progressbar"
                variant="primary"
                now={82}
                percentage={`${82}%`}
              />
            </td>
            <td>
              <span className="table-hour-spent">4:25</span>
            </td>
          </tr>
          <tr>
            <td>
              <CircleDetailBox
                src={projectImage2}
                name="UX/UI"
                desc="Updated 2 hours ago"
              />
            </td>
            <td>
              <ProgressWithPercentage
                className="reverse-progressbar"
                variant="success"
                now={100}
                percentage={`${100}%`}
              />
            </td>
            <td>
              <span className="table-hour-spent">24:12</span>
            </td>
          </tr>
          <tr>
            <td>
              <CircleDetailBox src={projectImage3} name="UX/UI" desc="Updated 3 hours ago" />
            </td>
            <td>
              <ProgressWithPercentage
                className="reverse-progressbar"
                variant="default"
                now={0}
                percentage={`${0}%`}
              />
            </td>
            <td>
              <span className="table-hour-spent">0:12</span>
            </td>
          </tr>
          <tr>
            <td>
              <CircleDetailBox
                src={projectImage4}
                name="UX/UI"
                desc="Updated 5 hours ago"
              />
            </td>
            <td>
              <ProgressWithPercentage
                className="reverse-progressbar"
                variant="primary"
                now={50}
                percentage={`${50}%`}
              />
            </td>
            <td>
              <span className="table-hour-spent">8:08</span>
            </td>
          </tr>
          <tr>
            <td>
              <CircleDetailBox src={projectImage5} name="UX/UI" desc="Updated 6 hours ago" />
            </td>
            <td>
              <ProgressWithPercentage
                className="reverse-progressbar"
                variant="primary"
                now={30}
                percentage={`${30}%`}
              />
            </td>
            <td>
              <span className="table-hour-spent">2:00</span>
            </td>
          </tr>
          <tr>
            <td>
              <CircleDetailBox src={projectImage5} name="UX/UI" desc="Updated 6 hours ago" />
            </td>
            <td>
              <ProgressWithPercentage
                className="reverse-progressbar"
                variant="primary"
                now={65}
                percentage={`${65}%`}
              />
            </td>
            <td>
              <span className="table-hour-spent">15:45</span>
            </td>
          </tr>
          <tr>
            <td>
              <CircleDetailBox src={projectImage5} name="UX/UI" desc="Updated 6 hours ago" />
            </td>
            <td>
              <ProgressWithPercentage
                className="reverse-progressbar"
                variant="primary"
                now={70}
                percentage={`${70}%`}
              />
            </td>
            <td>
              <span className="table-hour-spent">25:15</span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <Button variant="default">Show more</Button>
        </tfoot>
      </Table>
    </>
  );
}
export default WorkedTable;
