import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { Modal, ModalBody } from 'reactstrap';
import Videoplayer from '../../../components/Videoplayer/Videoplayer';

export default function VideoPriview(props) {
  let DisplayMode = useSelector(state => state.project.DisplayMode);

  if (props.lightTheme) DisplayMode = false;

  return (
    <Modal
      isOpen={props.openVideoPreview}
      fade={true}
      centered
      className={
        DisplayMode
          ? 'modal-dialog  modal-dialog-centered custom-modal view-modal dark'
          : 'modal-dialog  modal-dialog-centered custom-modal view-modal'
      }
    >
      <ModalBody>
        {/* <div className="modal fade" id="view-img" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
        <div
          id="view-img"
          tabindex="-1"
          //   className="modal-dialog  modal-dialog-centered custom-modal view-modal"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="d-flex view-header">
            <h3>Video Preview</h3>
            <Tooltip title="Close" arrow>
              <div className="close-priview" onClick={() => props.closePreview()}>
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="close">
                      <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
                      <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                    </g>
                  </g>
                </svg>
              </div>
            </Tooltip>
          </div>

          {/* <div className="img-block"> */}
          {/* <video autoPlay className="object-cover" controls disablePictureInPicture controlsList="nodownload">
                  <source src={props.selectedVideo} type="video/mp4" />
                                    Your browser does not support HTML5 video.
                                            </video> */}
          <Videoplayer thumbnailUrl="" videoUrl={props.selectedVideo} showVolume={false} />
          {/* </div> */}
        </div>

        {/* </div> */}
      </ModalBody>
    </Modal>
  );
}
