import React from 'react';

const SearchComponent = props => {
  const {
    id,
    type,
    label,
    onChange,
    leftIcon,
    inputValue,
    placeholder = '',
    onFocus = () => {},
  } = props;

  return (
    <div className={`custom-form-floating ${props.className || ''}`}>
      <span className="form-icon form-left-icon">{leftIcon}</span>
      <input
        id={id}
        type={type}
        onFocus={onFocus}
        value={inputValue}
        onChange={onChange}
        placeholder={placeholder}
      />
      <label htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default SearchComponent;
