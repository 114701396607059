// import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Field, Form, ErrorMessage } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
// import { SketchPicker } from "react-color";
import {
  createProjects,
  getAllFonts,
  publicSearchFont,
  getProjects,
} from "../../../services/api.service";
import React, { useState, useEffect } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useSelector, useDispatch } from "react-redux";
import { actionCreator } from "../../../store/index";
import { bindActionCreators } from "redux";

function Styles() {
  const history = useHistory();
  const newProject = useSelector((state) => state.newProject);
  const addProjectDetail = useSelector((state) => state.addProjectDetail);
  const [fontArray, setfontArray] = useState();
  const [fonts, setfonts] = useStateWithCallbackLazy();
  const [regular, setRegular] = useState();
  const [FontFamily, setFontFamily] = useState();
  const [FontStyle, setFontStyle] = useState();
  const [fontSizeValidate, setFontSizeValidate] = useState(false);
  const [erroMessage, setErroMessage] = useState("");
  const [FontSizeH, setFontSizeH] = useState(30);
  const [FontSizeP, setFontSizeP] = useState(25);
  const [projectLoaded, setProjectLoaded] = useState(false);
  // const [fontSizeValidate,setFontSizeValidate] = useState(false);
  let project = useSelector((state) => state.project);
  let ActiveModule = useSelector((state) => state.project.ActiveModule);
  let ActiveSlide = useSelector((state) => state.project.ActiveSlide);
  let ActiveScreen = useSelector((state) => state.project.ActiveScreen);
  let projectID = useSelector((state) => state.project.ProjectId);
  const dispatch = useDispatch();

  let { SetOstFont } = bindActionCreators(actionCreator, dispatch);

  useEffect(() => {
    // let projectInputs = JSON.parse(JSON.stringify(addProjectDetail));
    getFonts();
  }, []);

  useEffect(() => {
    if (project.createdProject[projectID] != null) {
      setProjectLoaded(true);
      if (
        project.createdProject[projectID].Modules[ActiveModule] != undefined
      ) {
        let features =
          project.createdProject[projectID].Modules[ActiveModule].slides[
            ActiveSlide
          ].Screens[ActiveScreen];
        Object.entries(features).map((i, item) => {
          if (i[1].Contenttype === "OST") {
            let _font = i[1].Contentparams.FontFamily;
            setFontFamily({
              value: _font.split("/")[4],
              label: _font.split("/")[4],
            });
            setFontStyle({
              value: _font.split("/")[5],
              label: _font.split("/")[5],
            });
            setFontSizeH(i[1].Contentparams.FontSizeH);
            setFontSizeP(i[1].Contentparams.FontSizeP);
          }
        });
      }
    } else {
    }
  }, [project, ActiveScreen]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#262626",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#877c7c" : "#877c7c",
      // Removes weird border around container
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#877c7c" : "#877c7c",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "red" : "blue",
      padding: 20,
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  // const customStyles = {
  //     background: "#023950",
  //     option: (provided, state) => ({
  //       ...provided,
  //       borderBottom: '1px dotted pink',
  //       color: state.isSelected ? 'red' : 'blue',
  //       padding: 20,
  //     }),
  //     // control: () => ({
  //     //   // none of react-select's styles are passed to <Control />
  //     //   width: 20,
  //     // }),
  //     singleValue: (provided, state) => {
  //       const opacity = state.isDisabled ? 0.5 : 1;
  //       const transition = 'opacity 300ms';

  //       return { ...provided, opacity, transition };
  //     }
  //   }
  const getFonts = () => {
    getAllFonts().then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200 &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          // this.fontArray = response.data
          setfontArray(response.data);
          //generateFonts();
        }
      } catch (error) {}
    });
  };

  useEffect(() => {
    generateFonts();
  }, [fontArray]);

  const generateFonts = () => {
    if (fontArray != undefined) {
      let sampleArray = fontArray.map((font) => font.split("/")[4]);
      // this.updateState();
      let uniqueArray = sampleArray.filter(
        (item, i, ar) => ar.indexOf(item) === i
      );
      let fontOption = [];
      uniqueArray.map((font) => {
        fontOption.push({ value: font, label: font });
        return true;
      });
      // setfonts(fontOption)
      // handleChange(fontOption[0])
      setfonts(fontOption);
    }
  };
  useEffect(() => {
    if (fonts != undefined) {
      //handleChange(fonts[0]);
    }
  }, [fonts]);

  const handleChange = (e) => {
    // alert()
    let regularArray = [];
    let regularOption = [];
    fontArray.map((font) =>
      font.split("/")[4] === e.value
        ? regularArray.push(font.split("/")[5])
        : ""
    );
    regularArray.map((font) => {
      regularOption.push({ value: font, label: font });
      return true;
    });
    setRegular(regularOption);
    setFontFamily(e);
    setFontStyle(regularOption[0]);
    SetOstFont(e.value, regularOption[0].value, FontSizeH, FontSizeP);
    // this.setState({ regular: regularOption, FontFamily: e, FontStyle: regularOption[0] }, () => {
    // this.updateJson()
    // })
  };
  const handleChangeRegular = (e) => {
    setFontStyle(e);
    SetOstFont(FontFamily.value, e.value, FontSizeH, FontSizeP);
    // this.setState({ FontStyle: e }, () => {
    //     // this.updateJson()
    // })
  };
  const fontSizeChange = (e, id) => {
    if (e.target.value.length === 0) {
      setFontSizeValidate(true);
    } else {
      setFontSizeValidate(false);
    }
    if (e.target.value > 99) {
        // setErroMessage("Font size should be  less than 100");
      setErroMessage("Font size should be  less than 100");
    //   this.clearError();
    } else if (isNaN(e.target.value)) {
      setErroMessage("Enter a number");
    } else {
      setErroMessage("");
      if (id === "FontSizeH") {
        setFontSizeH(e.target.value);
        SetOstFont(FontFamily, FontStyle, e.target.value, FontSizeP);
      } else if (id === "FontSizeP") {
        setFontSizeP(e.target.value);
        SetOstFont(FontFamily, FontStyle, FontSizeH, e.target.value);
      }
      // this.setState({
      //     erroMessage: "",
      //     [id]: document.getElementById(id).value
      // }, () => {
      // })
    }
  };
  const handleKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  return (
    // <form onSubmit={formik.handleSubmit}>

    <div className="cm-style-blk">
      <div className="cm-style-wrapper">
        <div className="d-flex align-items-center panel-label">
          Styles
          <div className="info-icon ps-2">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14ZM7.81375 5.7645L6.93875 9.88138C6.8775 10.1789 6.96413 10.3477 7.20475 10.3477C7.3745 10.3477 7.63088 10.2865 7.805 10.1325L7.728 10.4965C7.47687 10.7993 6.923 11.0197 6.44613 11.0197C5.831 11.0197 5.56938 10.6505 5.73913 9.86562L6.38487 6.83113C6.44088 6.57475 6.39013 6.482 6.13375 6.41988L5.73913 6.349L5.81087 6.01562L7.81462 5.7645H7.81375ZM7 4.8125C6.76794 4.8125 6.54538 4.72031 6.38128 4.55622C6.21719 4.39212 6.125 4.16956 6.125 3.9375C6.125 3.70544 6.21719 3.48288 6.38128 3.31878C6.54538 3.15469 6.76794 3.0625 7 3.0625C7.23206 3.0625 7.45462 3.15469 7.61872 3.31878C7.78281 3.48288 7.875 3.70544 7.875 3.9375C7.875 4.16956 7.78281 4.39212 7.61872 4.55622C7.45462 4.72031 7.23206 4.8125 7 4.8125Z"
                fill="#C0C2C4"
              />
            </svg>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-between">
          <div className="cm-style-left-part">
            <div className="cm-color-picker">
              <div className="d-flex flex-wrap align-items-center">
                <div className="color-picker label-block">
                  <span className="color-palette">
                    <i className="eye-drop">
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9.49944 1.66429C9.49704 1.43857 9.44904 1.21566 9.35832 1.00895C9.26761 0.802247 9.13605 0.616009 8.97155 0.461424C8.31445 -0.160631 7.25036 -0.137216 6.59956 0.513609L5.57073 1.54246C5.35677 1.34753 5.07599 1.24245 4.78662 1.24901C4.49726 1.25558 4.22153 1.37328 4.01662 1.5777L3.6871 1.90723C3.54666 2.04798 3.46779 2.23871 3.46779 2.43755C3.46779 2.63639 3.54666 2.82711 3.6871 2.96787L3.78362 3.06438L1.42376 5.42422C1.20805 5.6408 1.04833 5.90665 0.958401 6.1988C0.868474 6.49095 0.851049 6.8006 0.90763 7.10099L0.449066 8.1514C0.396728 8.26998 0.381601 8.40165 0.405683 8.52901C0.429766 8.65637 0.491925 8.77342 0.583946 8.86471C0.653202 8.93448 0.735565 8.98987 0.826301 9.02771C0.917036 9.06554 1.01436 9.08507 1.11266 9.08517C1.21651 9.08504 1.31922 9.06344 1.41433 9.02174L2.39866 8.59202C2.69905 8.64861 3.00871 8.63118 3.30085 8.54125C3.593 8.45132 3.85885 8.29159 4.07542 8.07587L6.43526 5.71603L6.53178 5.81254C6.67254 5.95299 6.86326 6.03186 7.0621 6.03186C7.26094 6.03186 7.45166 5.95299 7.59242 5.81254L7.92195 5.48302C8.12638 5.27813 8.24409 5.00239 8.25065 4.71303C8.25722 4.42366 8.15213 4.14287 7.9572 3.92892L9.00534 2.88077C9.16542 2.72176 9.29171 2.53204 9.37662 2.32299C9.46152 2.11394 9.5033 1.88989 9.49944 1.66429ZM3.5451 7.54555C3.40518 7.6849 3.23117 7.78513 3.04042 7.83624C2.84967 7.88735 2.64886 7.88756 2.45801 7.83684C2.37601 7.81497 2.28903 7.82154 2.21125 7.85548L1.20479 8.29486L1.64417 7.2884C1.67812 7.21062 1.68469 7.12363 1.66281 7.04164C1.6121 6.85079 1.61232 6.64998 1.66343 6.45923C1.71454 6.26849 1.81477 6.09447 1.9541 5.95454L4.31394 3.5947L5.90494 5.1857L3.5451 7.54555Z" />
                      </svg>
                    </i>
                  </span>
                  <p className="edit-label">Color</p>
                </div>
                <div className="field-block">
                  <div className=" mb-4 d-flex">
                    {/* <select className="form-select form-field"> */}
                    <Select
                      className="w-50 "
                    //   styles={customStyles}
                      value={FontFamily}
                      onChange={(e) => handleChange(e)}
                      options={fonts}
                    />
                    <div className=" Regular ms-3 w-50">
                      {/* <select className="form-select form-field">
                                                <option> Regular</option>
                                            </select> */}
                      <Select
                        className=""
                        // styles={customStyles}
                        value={FontStyle}
                        onChange={handleChangeRegular}
                        options={regular}
                      />
                    </div>
                  </div>

                  <div className="d-flex  w-100">
                    <div className="custom-number w-50 me-3 ">
                      {/* <select className="form-select form-field ">
                                                <option> 16</option>
                                            </select> */}
                      <input
                        className="form-select form-field "
                        type="number"
                        placeholder="Select Font Size"
                        onKeyDown={handleKeypress}
                        min="1"
                        step="1"
                        id="FontSizeH"
                        onChange={(e) => fontSizeChange(e, "FontSizeH")}
                        value={FontSizeH}
                      />
                    </div>
                    <div className="custom-number w-50">
                      <input
                        className="form-select form-field "
                        type="number"
                        placeholder="Select Font Size"
                        onKeyDown={handleKeypress}
                        min="1"
                        step="1"
                        id="FontSizeH"
                        onChange={(e) => fontSizeChange(e, "FontSizeP")}
                        value={FontSizeP}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cm-style-right-part">
            <div className="cm-style-upload-holder">
              <div className="cm-style-container">
                <div className="cm-style-spec py-3">
                  Upload your branding logo
                </div>
                <div className="cc-csv-btn">
                  <label className="btn btn-primary b-logo-up">
                    Upload your logo
                    <input type="file" className="d-none" />
                  </label>
                </div>
                <div className="cm-style-spec my-3">
                  File format allowed (Jpeg,Png,Svg)
                </div>
              </div>
              <div className="cm-brand position relative">
                <div className="cm-uploaded-logo">
                  <img
                    src="../../../assets/img/brand-logo.png"
                    className="img-fluid"
                  />
                </div>
                <div className="cm-brand-close">
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.6957 11.637C12.8096 11.75 12.8737 11.9039 12.8737 12.0644C12.8737 12.2249 12.8096 12.3787 12.6957 12.4918C12.5827 12.6057 12.4289 12.6698 12.2685 12.6698C12.108 12.6698 11.9542 12.6057 11.8412 12.4918L6.85286 7.49563L1.86449 12.4918C1.7515 12.6057 1.5977 12.6698 1.43725 12.6698C1.27681 12.6698 1.12301 12.6057 1.01002 12.4918C0.896107 12.3787 0.832031 12.2249 0.832031 12.0644C0.832031 11.9039 0.896107 11.75 1.01002 11.637L6.00442 6.64689L1.01002 1.6568C0.857388 1.50411 0.797777 1.28156 0.853646 1.07299C0.909514 0.864412 1.07237 0.701496 1.28088 0.645608C1.48938 0.589721 1.71185 0.649352 1.86449 0.80204L6.85286 5.79816L11.8412 0.80204C12.0772 0.566005 12.4598 0.566005 12.6957 0.80204C12.9317 1.03807 12.9317 1.42076 12.6957 1.6568L7.70131 6.64689L12.6957 11.637Z"
                      fill="white"
                    />
                  </svg>
                </div>

                <div className="cm-st-upload-edit">
                  <div
                    className="cm-st-edit-icon"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-brand"
                  >
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24.8021 25.3384H15.4336C14.8988 25.3384 14.4648 24.9044 14.4648 24.3696C14.4648 23.8349 14.8988 23.4009 15.4336 23.4009H24.8021C25.3368 23.4009 25.7708 23.8349 25.7708 24.3696C25.7708 24.9044 25.3368 25.3384 24.8021 25.3384Z"
                        fill="white"
                      />
                      <mask
                        id="mask0_2482_9790"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="23"
                        height="26"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.582031 0.875H22.7736V25.3386H0.582031V0.875Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_2482_9790)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.9328 3.47982L2.7718 18.6892C2.55092 18.9656 2.46955 19.3221 2.55092 19.6644L3.43055 23.3909L7.35592 23.3418C7.72922 23.3379 8.07409 23.1713 8.30271 22.8871C12.458 17.6882 20.3798 7.77591 20.6045 7.48528C20.8164 7.1417 20.899 6.65603 20.788 6.18845C20.6743 5.70924 20.3759 5.30237 19.9458 5.04274C19.8541 4.97945 17.6776 3.28995 17.6105 3.23699C16.7915 2.58082 15.5968 2.69449 14.9328 3.47982ZM2.66584 25.3387C2.21764 25.3387 1.82755 25.0313 1.72293 24.5934L0.665052 20.11C0.446761 19.1813 0.663761 18.2229 1.25922 17.4789L13.4267 2.2605C13.4319 2.25533 13.4358 2.24887 13.4409 2.24371C14.7752 0.648497 17.1881 0.413413 18.8156 1.71929C18.8801 1.76966 21.0411 3.44883 21.0411 3.44883C21.8264 3.91641 22.44 4.75212 22.6725 5.74154C22.9037 6.72062 22.7358 7.7307 22.1971 8.5845C22.1571 8.64779 22.1222 8.70204 9.81522 24.0987C9.22234 24.8375 8.33368 25.2677 7.37914 25.2793L2.67876 25.3387H2.66584Z"
                          fill="white"
                        />
                      </g>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.9522 12.0927C18.7456 12.0927 18.5389 12.0268 18.3619 11.8925L11.3198 6.48298C10.8961 6.15748 10.816 5.5504 11.1415 5.12415C11.4683 4.70048 12.0754 4.62169 12.5004 4.94719L19.5438 10.3554C19.9675 10.6809 20.0476 11.2893 19.7208 11.7142C19.5309 11.9622 19.2429 12.0927 18.9522 12.0927Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </form>
  );
}

export default Styles;

// import "./styles.css";

// Login.propTypes = {

// };
