import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { arrowDownIcon, searchIcon, uploadIcon } from '../../../../constants/icon';

import FormInputGroup from '../../../login/components/FormInputGroup';
import { showToaster } from '../../../../services/helper';
import UploadFileModal from './uploadFileModal';
import SearchComponent from '../../../components/SearchComponent';

function SecondaryAssetsHeader(props) {
  const {mediaType, keyWord, setKeyWord, setPrivateRepo, privateRepo} = props
  const [modalShow, setModalShow] = useState(false);

  // Dropdown CustomToggle
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      role="button"
      className="common-dropdown-filter"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
    >
      {children}
    </div>
  ));

  const handleSearch = val => {
    try {
      if (keyWord !== val.target.value.trim()) {
        setKeyWord(val.target.value);
      } else {
        if (keyWord.trim() !== '') {
          setKeyWord(val.target.value);
        } else {
          setKeyWord('');
        }
      }
    } catch (error) {
      showToaster('Something went wrong. Please Refresh page and Try Again', 'warning');
    }
  };
  return (
    <>
      <div className="secondary-header secondary-sticky-header">
        <SearchComponent
          leftIcon={searchIcon}
          type="search"
          id="searchMediaType"
          placeholder=""
          label={`Search ${mediaType}`}
          className="custom-search-form"
          inputValue={keyWord}
          onChange={e => handleSearch(e)}
        />

        <div className="common-dropdown-filter-wrap">
          <Dropdown align={{ lg: 'end' }}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <span className="dropdown-text">Privacy Filter</span>
              <span className="dropdown-icon">
                {privateRepo? "Private" : "Public"}
                {arrowDownIcon}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="common-dropdown-menu">
              <Dropdown.Item eventKey="1" onClick={() => setPrivateRepo(true)}>Private</Dropdown.Item>
              <Dropdown.Item eventKey="2" onClick={() => setPrivateRepo(false)}>Public</Dropdown.Item>
              
            </Dropdown.Menu>
          </Dropdown>
{/* 
          <Dropdown align={{ lg: 'end' }}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <span className="dropdown-text">Filter by</span>
              <span className="dropdown-icon">
                File type
                {arrowDownIcon}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="common-dropdown-menu">
              <Dropdown.Item eventKey="1">Item - 1</Dropdown.Item>
              <Dropdown.Item eventKey="2">Item - 2</Dropdown.Item>
              <Dropdown.Item eventKey="3">Item - 3</Dropdown.Item>
              <Dropdown.Item eventKey="4">Item - 4</Dropdown.Item>
              <Dropdown.Item eventKey="5">Item - 5</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown align={{ lg: 'end' }}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <span className="dropdown-text">Filter by</span>
              <span className="dropdown-icon">
                Created date
                {arrowDownIcon}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="common-dropdown-menu">
              <Dropdown.Item eventKey="1">Item - 1</Dropdown.Item>
              <Dropdown.Item eventKey="2">Item - 2</Dropdown.Item>
              <Dropdown.Item eventKey="3">Item - 3</Dropdown.Item>
              <Dropdown.Item eventKey="4">Item - 4</Dropdown.Item>
              <Dropdown.Item eventKey="5">Item - 5</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          {/* <button role="button" className="outline-primary-btn" onClick={() => setModalShow(true)}>
            Upload File
            {uploadIcon}
          </button> */}
        </div>
      </div>
      <UploadFileModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
export default SecondaryAssetsHeader;
