import AlertNotification from './alertNotification';

// icons
import {
  alertTickIcon,
  alertQuestionIcon,
  alertWarningIcon,
  alertDangerIcon,
  crossIcon,
} from '../../constants/icon';

// Class names are:
// - alert-success
// - alert-info
// - alert-warning
// - alert-danger

// Icon names are:
// - alertTickIcon
// - alertQuestionIcon
// - alertWarningIcon
// - alertDangerIcon
// For the close button icon, use crossIcon.

// class: "position-fixed top-0 start-0"
// Top left

// class: "position-fixed top-0 start-50 translate-middle-x"
// Top center

// class: "position-fixed top-0 end-0"
// Top right

// class: "position-fixed top-50 start-0 translate-middle-y"
// Middle left

// class: "position-fixed top-50 start-50 translate-middle"
// Middle center

// class: "position-fixed top-50 end-0 translate-middle-y"
// Middle right

// class: "position-fixed bottom-0 start-0"
// Bottom left

// class: "position-fixed bottom-0 start-50 translate-middle-x"
// Bottom center

// class: "position-fixed bottom-0 end-0"
// Bottom right

export default function DemoAlertComponent() {
  return (
    <>
      <div className="alert-wrapper position-fixed top-0 start-0">
        <AlertNotification
          className="alert-success"
          title="Congratulations!"
          desc="Your video has been updated to the latest version."
          icon={alertTickIcon}
          closeBtn={crossIcon}
        />
      </div>

      <div className="alert-wrapper position-fixed top-0 end-0">
        <AlertNotification
          className="alert-info"
          title="Did you know?"
          desc="You can switch your project between your team."
          icon={alertQuestionIcon}
          closeBtn={crossIcon}
        />
      </div>

      <div className="alert-wrapper position-fixed top-0 start-50 translate-middle-x">
        <AlertNotification
          className="alert-warning"
          title="Warning"
          desc="Your password strength is low."
          icon={alertWarningIcon}
          closeBtn={crossIcon}
        />
      </div>

      <div className="alert-wrapper position-fixed top-50 start-50  translate-middle">
        <AlertNotification
          className="alert-danger"
          title="CongratulationsSomething went wrong!"
          desc="The program has turned off unexpectedly."
          icon={alertDangerIcon}
          closeBtn={crossIcon}
        />
      </div>

      <div className="alert-wrapper position-fixed bottom-0 start-0">
        <AlertNotification
          className="alert-success"
          title="Congratulations!"
          desc="Your video has been updated to the latest version."
          icon={alertTickIcon}
          closeBtn={crossIcon}
        />
      </div>

      <div className="alert-wrapper position-fixed bottom-0 end-0">
        <AlertNotification
          className="alert-info"
          title="Did you know?"
          desc="You can switch your project between your team."
          icon={alertQuestionIcon}
          closeBtn={crossIcon}
        />
      </div>

      <div className="alert-wrapper position-fixed top-50 start-0 translate-middle-y">
        <AlertNotification
          className="alert-warning"
          title="Warning"
          desc="Your password strength is low."
          icon={alertWarningIcon}
          closeBtn={crossIcon}
        />
      </div>

      <div className="alert-wrapper position-fixed top-50 end-0 translate-middle-y">
        <AlertNotification
          className="alert-success"
          title="Congratulations!"
          desc="Your video has been updated to the latest version."
          icon={alertTickIcon}
          closeBtn={crossIcon}
        />
      </div>

      <div className="alert-wrapper position-fixed bottom-0 start-50 translate-middle-x">
        <AlertNotification
          className="alert-success"
          title="Congratulations!"
          desc="Your video has been updated to the latest version."
          icon={alertTickIcon}
          closeBtn={crossIcon}
        />
      </div>
    </>
  );
}
