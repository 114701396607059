import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
function InnerSidebarList() {
  const [activeItem, setActiveItem] = useState('user-management');
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path === '/setting/user-management') {
      setActiveItem('user-management');
    }
  }, [location.pathname]);
  return (
    <ul className="inner-sidebar-list">
      <li>
        <Link
          to="/settings/user-management"
          className={activeItem === 'user-management' ? 'active' : ' '}
        >
          User management
        </Link>
      </li>
      {/* <li>
        <Link to="#">Repository Management</Link>
      </li>
      <li>
        <Link to="#">Config settings</Link>
      </li>
      <li>
        <Link to="#">Organization setting </Link>
      </li>
      <li>
        <Link to="#">Clusters setting </Link>
      </li>
      <li>
        <Link to="#">Super admin dashboard </Link>
      </li> */}
    </ul>
  );
}
export default InnerSidebarList;
