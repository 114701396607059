import React from "react";

export default function Duration({ className, seconds }) {
  return (
    <time dateTime={`P${Math.round(seconds)}S`} className={className}>
      {format(seconds)}
    </time>
  );
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  //
  let hours = Math.floor((date % (1000 * 60 * 60 * 24))/(1000 * 60 * 60))
  let minutes = Math.floor((date % (1000 * 60 * 60)) / (1000 * 60));
  let second = Math.floor((date % (1000 * 60)) / 1000);
  let milliSeconds = Math.floor((date % 1000) / 10);
  // console.log(hours +":"+ minutes +":"+second +":"+milliSeconds);
  //
  if (hours != 0) {
    return `${hours}:${minutes}:${second}`;
  }
  return `${minutes}:${second}:${milliSeconds}`;
}

function pad(string) {
  return ("0" + string).slice(-2);
}
