function ProfileListItem(props) {
  const { name, icon } = props;
  return (
    <li>
      {name && <>{icon}</>}
      {name && <span>{name}</span>}
    </li>
  );
}
export default ProfileListItem;
