import { useState } from 'react';
import { Button } from 'react-bootstrap';

import { deleteIcon, plusIcon, renderIcon } from '../../../../constants/icon';
import image7 from '../../../../assets/img/Thumbnailv3.jpg';

import CustomSwitchSlider from '../../../../components/form/customSwitchSlider';
import AssetsCheckboxBlock from './assetsCheckboxBlock';
import PreviewModal from './previewModal';
import AddFilesModal from './addFilesModal';
import { showToaster } from '../../../../services/helper';
import CustomModal from '../../../../services/CustomModal';
import { deleteAssets } from '../../../../services/apiServices';

function VideoComponent(props) {
  const { posts, setPrivateRepo, activeComponent, getAssetRepo } = props;
  const [modalShow, setModalShow] = useState(false);
  const [previewModalShow, setPreviewModalShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [deleteUrls, setDeleteUrls] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOpenModal = (src, mediaUrl) => {
    setSelectedImage(src);
    setSelectedUrl(mediaUrl);
    setPreviewModalShow(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setPreviewModalShow(false);
  };

  const onClickAddNew = () => {
    setModalShow(true);
  };
  const deleteAssetsHandler = async () => {
    let body = {
      category: 'video',
      assetsUrls: deleteUrls,
    };
    try {
      setIsDeleting(true);
      const response = await deleteAssets(body);
      setIsDeleting(false);
      getAssetRepo();
      showToaster(' Video deleted successfully', 'success');
    } catch (e) {
      console.log({ getDeleteAssets: e });
    } finally {
      setOpenModal(false);
      setIsDeleting(false);
      setDeleteUrls([]);
    }
  };

  return (
    <>
      <PreviewModal
        selectedImage={selectedImage}
        show={previewModalShow}
        onHide={handleCloseModal}
        fromVideo
        videoUrl={selectedUrl}
      />
      <AddFilesModal
        show={modalShow}
        setModalShow={setModalShow}
        onHide={() => setModalShow(false)}
        fromVideo
        setPrivateRepo={setPrivateRepo}
        activeComponent={activeComponent}
        getAssetRepo={getAssetRepo}
      />

      <div className="assets-container">
        <div className="assets-content">
          <div className="w-100 h-100 d-flex align-items-center justify-content-between assets-header">
            <h2>All videos files</h2>
            {deleteUrls.length && !openModal && props.showAssetDeleteOption ? (
              <span className="delete-icon pointer" onClick={() => setOpenModal(true)}>
                {deleteIcon} Delete
              </span>
            ) : (
              <></>
            )}
          </div>
          <div className="assets-checkbox-holder">
            <Button className="add-new-file-btn" variant="default" onClick={onClickAddNew}>
              {plusIcon}
              Add new file
            </Button>
            {posts?.map((post, index) => {
              return (
                <AssetsCheckboxBlock
                  className="assets-checkbox-preview"
                  mediaContent
                  key={index}
                  src={post?.thumbnailurl ?? image7}
                  onClick={() => handleOpenModal(post?.thumbnailurl ?? image7, post?.mediaurl)}
                  mediaUrl={post?.mediaurl}
                  fileName={post?.name}
                  fileType="video"
                  tagName={post?.tag_names}
                  deleteUrls={deleteUrls}
                  setDeleteUrls={setDeleteUrls}
                  setOpenModal={setOpenModal}
                  openModal={openModal}
                  showAssetDeleteOption={props.showAssetDeleteOption}
                />
              );
            })}
          </div>
        </div>
        {/* <div className="assets-sidebar">
          <h2>Settings</h2>
          <div className="settung-sharing-wrap">
            <div className="info-wrap">
              <h4>File Size</h4>
              <span>154 Kbs</span>
            </div>
          </div>
          <div className="settung-sharing-wrap">
            <div className="info-wrap">
              <h4>File Sharing</h4>
              <p>Turn on to share your file changes and updates</p>
            </div>
            <CustomSwitchSlider />
          </div>
          <Button variant="default">{renderIcon} Download</Button>
          <Button variant="default">{deleteIcon} Delete</Button>
        </div> */}
        <CustomModal
          Header="Are you sure?"
          isOpen={openModal || isDeleting}
          Buttonclick={deleteAssetsHandler}
          Buttonlabel={isDeleting ? 'Deleting...' : 'Yes, Delete it!'}
          Closemodal={setOpenModal}
          // Content={`Do you want to delete Module ${deleteModuleId}?`}
        />
      </div>
    </>
  );
}
export default VideoComponent;
